import { get } from "lodash";
import defaultConfig from "./defaultConfig";
import dev from "./dev";
import uat from "./uat";
import prod from "./prod";
import research from "./research";
import international from "./international";
import international_dev from "./international-dev";

const configMapper = {
   dev,
   uat,
   prod,
   research,
   international,
   international_dev,
};

export default {
   ...defaultConfig,
   ...configMapper[get(process.env, "REACT_APP_ENV", "").trim()],
};
