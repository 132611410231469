import ActionTypes from "../action-types";

export function getSelectorReportIndexData(payload) {
   return {
      type: ActionTypes.GET_SELECTOR_REPORT_INDEX_DATA,
      payload,
   };
}

export function setSelectorReportIndexData(payload) {
   return {
      type: ActionTypes.SET_SELECTOR_REPORT_INDEX_DATA,
      payload,
   };
}
