import ActionTypes from "../action-types";
import actionTypes from "../action-types";

export function registerProfileData(payload, callback) {
   return {
      type: ActionTypes.REGISTER_PROFILE_REQUEST,
      payload,
      callback,
   };
}

export function registerProfileDataFetch(payload, callback) {
   return {
      type: ActionTypes.REGISTER_PROFILE_FETCH,
      payload,
      callback,
   };
}

export function registerProfileDataSuccess(payload) {
   return {
      type: ActionTypes.REGISTER_PROFILE_SUCCESS,
      payload,
   };
}

export function registerProfileDataFailure(payload) {
   return {
      type: ActionTypes.REGISTER_PROFILE_FAILURE,
      payload,
   };
}

export function setRegisterState(payload) {
   return {
      type: ActionTypes.SET_REGISTER_STATE,
      payload,
   };
}

export function register(payload, callback) {
   return {
      type: ActionTypes.REGISTER_REQUEST,
      payload,
      callback,
   };
}

export function registerGoogle(payload, callback) {
   return {
      type: ActionTypes.REGISTER_GOOGLE_REQUEST,
      payload,
      callback,
   };
}

export function registerGoogleFetch(payload) {
   return {
      type: ActionTypes.REGISTER_GOOGLE_FETCH,
      payload,
   };
}

export function registerApple(payload, callback) {
   return {
      type: ActionTypes.REGISTER_APPLE_REQUEST,
      payload,
      callback,
   };
}

export function successfulRegister(response) {
   return {
      type: ActionTypes.REGISTER_SUCCESS,
      response,
   };
}

export function successfulGoogleRegister(response) {
   return {
      type: ActionTypes.REGISTER_GOOGLE_SUCCESS,
      response,
   };
}

export function successfulAppleRegister(response) {
   return {
      type: ActionTypes.REGISTER_APPLE_SUCCESS,
      response,
   };
}

export function failedRegister(error) {
   return {
      type: ActionTypes.REGISTER_FAILURE,
      error,
   };
}

export function failedGoogleRegister(error) {
   return {
      type: ActionTypes.REGISTER_GOOGLE_FAILURE,
      error,
   };
}

export function failedAppleRegister(error) {
   return {
      type: ActionTypes.REGISTER_APPLE_FAILURE,
      error,
   };
}

export function widgetRegister(payload) {
   return {
      type: actionTypes.REGISTER_WIDGET,
      payload,
   };
}

export function setWidgetToken(payload) {
   console.log(payload);

   return {
      type: ActionTypes.SET_WIDGET_TOKEN,
      payload,
   };
}

export function failedWidget(error) {
   return {
      type: ActionTypes.WIDGET_FAILURE,
      error,
   };
}

export function pendingWidgetToken(payload) {
   return {
      type: ActionTypes.WIDGET_PENDING_TOKEN,
      payload,
   };
}

export const clearError = () => {
   return {
      type: ActionTypes.CLEAR_REGISTER_ERROR,
   };
};

export function getLinkToken(payload) {
   return {
      type: ActionTypes.GENERATE_LINK_TOKEN,
      payload,
   };
}

export function linkTokenError(error) {
   return {
      type: ActionTypes.LINK_TOKEN_ERROR,
      error,
   };
}

export function getUsersAccountDetails(payload) {
   return {
      type: ActionTypes.GET_USERS_BANK_ACCOUNTS,
      payload,
   };
}

export function createUserAccount(payload) {
   return {
      type: ActionTypes.CREATE_ACCOUNT,
      payload,
   };
}

// get all country list data
export function getAllCountryRequest(payload, callback) {
   return {
      type: ActionTypes.GET_ALL_COUNTRY_LIST_REQUEST,
      payload,
      callback,
   };
}

// OKTA AUTH REGISTER
export function oktaAuthRegisterRequest(payload, callback) {
   return {
      type: ActionTypes.OKTA_AUTH_REGISTER_REQUEST,
      payload,
      callback,
   };
}

export function getAllCountryFetch(payload) {
   return {
      type: ActionTypes.GET_ALL_COUNTRY_LIST_FETCH,
      payload,
   };
}

export function oktaAuthRegisterFetch(payload) {
   return {
      type: ActionTypes.OKTA_AUTH_REGISTER_FETCH,
      payload,
   };
}
export function getAllCountrySuccess(payload) {
   return {
      type: ActionTypes.GET_ALL_COUNTRY_LIST_SUCCESS,
      payload,
   };
}
export function oktaAuthRegisterSuccess(payload) {
   return {
      type: ActionTypes.OKTA_AUTH_REGISTER_SUCCESS,
      payload,
   };
}

// OKTA AUTH REGISTER
export function validateOktaUserRequest(payload, callback) {
   return {
      type: ActionTypes.VALIDATE_OKTA_USER_REQUEST,
      payload,
      callback,
   };
}

export function validateOktaUserFetch(payload) {
   return {
      type: ActionTypes.VALIDATE_OKTA_USER_FETCH,
      payload,
   };
}

export function validateOktaUserSuccess(payload) {
   return {
      type: ActionTypes.VALIDATE_OKTA_USER_SUCCESS,
      payload,
   };
}

// REGISTER_BY_OKTA_STEPS ('proceed')
export function registerByOktaStepsRequest(payload, callback) {
   return {
      type: ActionTypes.REGISTER_BY_OKTA_STEPS_REQUEST,
      payload,
      callback,
   };
}

export function registerByOktaStepsFetch(payload) {
   return {
      type: ActionTypes.REGISTER_BY_OKTA_STEPS_FETCH,
      payload,
   };
}

export function registerByOktaStepsSuccess(payload) {
   return {
      type: ActionTypes.REGISTER_BY_OKTA_STEPS_SUCCESS,
      payload,
   };
}
