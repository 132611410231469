import React from "react";

export const DownArrow = () => {
   return (
      <svg
         width="18"
         height="18"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M6 9L12 15L18 9"
            stroke="#797979"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
         />
      </svg>
   );
};
