/* eslint-disable array-callback-return */
import { createSelector } from "reselect";
import _ from "lodash";
import { RegionColors, SectorColors } from "data/Colors";
import { cn, SectorsAttrs } from "DataSet";
// import { uniqValues } from 'Utils';

export const getAllEnhancerState = state => state.enhancer;
export const getFinalizePortfolioFunds = enhancer => {
   // (enhancer.finalizeData && typeof enhancer.finalizeData.summary !== 'undefined' && typeof enhancer.finalizeData.summary.portfolioFunds !== 'undefined') ? enhancer.finalizeData.summary.portfolioFunds  : []
   let { finalizeData } = enhancer;
   if (finalizeData && finalizeData !== "undefined") {
      let { summary } = finalizeData;
      if (
         (summary &&
            typeof summary !== "undefined" &&
            summary.portfolioFunds &&
            typeof summary.portfolioFunds !== "undefined") ||
         (typeof summary !== "undefined" &&
            summary.invalidTickerFunds &&
            typeof summary.invalidTickerFunds !== "undefined") ||
         (typeof summary !== "undefined" &&
            summary.tickerNotSupported &&
            typeof summary.tickerNotSupported !== "undefined")
      ) {
         let {
            portfolioFunds = [],
            invalidTickerFunds = [],
            tickerNotSupported = [],
         } = summary;
         let collections = [
            ...portfolioFunds,
            ...invalidTickerFunds,
            ...tickerNotSupported,
         ];
         let allFunds = _.uniqBy([...collections], "ticker");
         // console.log('allFunds --> ', allFunds);
         if (allFunds && allFunds.length > 0) {
            return allFunds;
         } else {
            return portfolioFunds;
         }
      } else {
         return enhancer || [];
      }
   } else {
      return enhancer || [];
   }
};
export const getPortfolioFunds = enhancer => {
   let { summary } = enhancer;
   if (
      summary &&
      summary.portfolioFunds &&
      summary.invalidTickerFunds &&
      summary.tickerNotSupported
   ) {
      let { portfolioFunds, invalidTickerFunds, tickerNotSupported } = summary;
      let collections = [
         ...portfolioFunds,
         ...invalidTickerFunds,
         ...tickerNotSupported,
      ];
      let allFunds = _.uniqBy([...collections], "ticker");
      // console.log('allFunds --> ', allFunds);
      if (allFunds && allFunds.length > 0) {
         return allFunds;
      } else {
         return portfolioFunds;
      }
   } else {
      return [];
   }
};

const formatTree = data => {
   if (!data) return data;
   return data.map(e => {
      let out = { name: e.n, size: e.v };
      if (e.sub) out.children = formatTree(e.sub);
      return out;
   });
};

const sum = (arr, key) => {
   return arr.reduce((a, b) => a + (b[key] || 0), 0);
};

const sortFn = attr => (a, b) => {
   // sort descending
   if (a[attr] < b[attr]) return 1;
   else if (a[attr] > b[attr]) return -1;
   return 0;
};

export const getEnhancerState = createSelector(
   [getAllEnhancerState],
   enhancerStates => {
      return enhancerStates;
   }
);

export const getFinalizeSummaryFunds = createSelector(
   [getFinalizePortfolioFunds],
   data => {
      let out = {
         funds: [],
         positions: [],
         priceSectors: [],
         priceRegions: [],
         portfolioValue: 0,
         fundBalance: 0,
         cashBalance: 0,
         gainToday: 0,
         gainMonthly: 0,
         gainYearly: 0,
         allocationPriceSectors: [],
      };
      if (!data) return out;

      // out.funds = data;

      let fundsH = {},
         totalMarketValue = 0;
      // totalProfitLoss = 0;
      let allRegionData = [],
         uniqueRegions = [],
         allSectorData = [],
         uniqueSectors = [];

      data.forEach((kl, i) => {
         if (
            kl &&
            typeof kl.price !== "undefined" &&
            typeof kl.shares !== "undefined"
         ) {
            out.portfolioValue += kl.price * kl.shares;
         }
         // if (kl && typeof kl.value !== 'undefined') {
         //   out.portfolioValue += kl.value;
         // }
      });

      out.funds = data.filter(kl => !kl.invalid);

      for (const e of out.funds) {
         if (fundsH[e.script] === undefined)
            fundsH[e.script] = { ...e, _price: 0, symbol: e.script };

         totalMarketValue += e.market_value;
         // out.portfolioValue += (e.price * e.shares);
         // console.log('sid ankit', e.daily_nav, e.monthly_nav, e.yearly_nav, e.price);
         if (e.daily_nav !== null)
            out.gainToday += (e.price - e.daily_nav) * e.shares;
         if (e.monthly_nav !== null)
            out.gainMonthly += (e.price - e.monthly_nav) * e.shares;
         if (e.yearly_nav !== null)
            out.gainYearly += (e.price - e.yearly_nav) * e.shares;

         fundsH[e.script]._price += e.price;

         // Sectors
         if (e.sector && Object.keys(e.sector).length > 0) {
            let sec = SectorsAttrs.map(v => ({
               name: v,
               value: cn(e.sector, v)
                  ? parseFloat((cn(e.sector, v) * 1).toFixed(1))
                  : 0,
            }));
            sec = sec.map(item => {
               !uniqueSectors.includes(item.name) &&
                  uniqueSectors.push(item.name);
               const obj = {
                  name: item.name,
                  value: item.value * e.market_value,
                  pl:
                     (e.price - parseFloat(e.purchase_price)) *
                     e.shares *
                     (item.value / 100),
               };
               allSectorData.push(obj);
               return item;
            });
         }

         // Regions
         if (e.region && Object.keys(e.region).length > 0) {
            let reg = formatTree(cn(e, "Region"));
            reg = reg.map(item => {
               !uniqueRegions.includes(item.name) &&
                  uniqueRegions.push(item.name);
               const region = {
                  name: item.name,
                  size: sum(item.children, "size") * e.market_value,
                  pl:
                     (e.price - parseFloat(e.purchase_price)) *
                     e.shares *
                     (sum(item.children, "size") / 100),
               };
               return region;
            });
            allRegionData.push(reg);
         }
      }

      let priceSectors = [];
      if (uniqueSectors.length > 0) {
         priceSectors = uniqueSectors.reduce((acc, item, i) => {
            const value = allSectorData.reduce(
               (sum, data) => {
                  if (data.name === item) sum["sum"] = sum["sum"] + data.value;
                  if (data.name === item) sum["plSum"] = sum["plSum"] + data.pl;
                  return sum;
               },
               {
                  sum: 0,
                  plSum: 0,
               }
            );

            const obj = {
               name: item,
               value: value.sum / totalMarketValue,
               color: SectorColors[i % SectorColors.length],
               pl: value.plSum,
            };
            acc.push(obj);
            return acc;
         }, []);
      } else out.priceSectors = [];

      out.allocationPriceSectors = priceSectors;

      if (uniqueSectors.length > 0)
         out.priceSectors = priceSectors
            .sort((a, b) => b.pl - a.pl)
            .filter(
               (item, index) =>
                  (index <= 4 && item.pl > 0) ||
                  (index >= priceSectors.length - 5 && item.pl <= 0)
            );

      if (uniqueRegions.length > 0) {
         out.priceRegions = uniqueRegions.reduce((acc, item) => {
            const size = allRegionData.reduce(
               (sum, data) => {
                  const index = data.findIndex(x => x.name === item);
                  if (index >= 0) sum["sum"] = sum["sum"] + data[index].size;
                  if (index >= 0) sum["plSum"] = sum["plSum"] + data[index].pl;
                  return sum;
               },
               {
                  sum: 0,
                  plSum: 0,
               }
            );
            const abc = {
               name: item,
               value:
                  size.sum / totalMarketValue < 0
                     ? 0
                     : size.sum / totalMarketValue,
               color: RegionColors[item],
               pl: size.plSum,
            };
            acc.push(abc);
            return acc;
         }, []);
      } else out.priceRegions = [];

      out.funds.sort((a, b) => {
         return new Date(b.purchase_date) - new Date(a.purchase_date);
      });

      out.positions = Object.values(fundsH).sort(sortFn("_price"));

      out.tickers = out.positions.map(item => {
         return { ticker: item.symbol, shares: item.quantity };
      });

      return out;
   }
);

export const getSummaryFunds = createSelector([getPortfolioFunds], data => {
   let out = {
      funds: [],
      positions: [],
      priceSectors: [],
      priceRegions: [],
      portfolioValue: 0,
      fundBalance: 0,
      cashBalance: 0,
      gainToday: 0,
      gainMonthly: 0,
      gainYearly: 0,
      allocationPriceSectors: [],
   };
   if (!data) return out;

   // out.funds = data;

   let fundsH = {},
      totalMarketValue = 0;
   // totalProfitLoss = 0;
   let allRegionData = [],
      uniqueRegions = [],
      allSectorData = [],
      uniqueSectors = [];

   data.forEach((kl, i) => {
      if (
         kl &&
         typeof kl.price !== "undefined" &&
         typeof kl.shares !== "undefined"
      ) {
         // console.log(kl.ticker, kl.price, kl.shares, (kl.price * kl.shares));
         out.portfolioValue += kl.price * kl.shares;
      }
      // if (kl && typeof kl.value !== 'undefined') {
      //   out.portfolioValue += kl.value;
      // }
   });

   out.funds = data.filter(kl => !kl.invalid);

   for (const e of out.funds) {
      if (fundsH[e.script] === undefined)
         fundsH[e.script] = { ...e, _price: 0, symbol: e.script };

      totalMarketValue += e.market_value;
      // out.portfolioValue += (e.price * e.shares);
      // console.log('sid ankit', e.daily_nav, e.monthly_nav, e.yearly_nav, e.price);
      if (e.daily_nav !== null)
         out.gainToday += (e.price - e.daily_nav) * e.shares;
      if (e.monthly_nav !== null)
         out.gainMonthly += (e.price - e.monthly_nav) * e.shares;
      if (e.yearly_nav !== null)
         out.gainYearly += (e.price - e.yearly_nav) * e.shares;

      fundsH[e.script]._price += e.price;

      // Sectors
      if (e.sector && Object.keys(e.sector).length > 0) {
         let sec = SectorsAttrs.map(v => ({
            name: v,
            value: cn(e.sector, v)
               ? parseFloat((cn(e.sector, v) * 1).toFixed(1))
               : 0,
         }));
         sec = sec.map(item => {
            !uniqueSectors.includes(item.name) && uniqueSectors.push(item.name);
            const obj = {
               name: item.name,
               value: item.value * e.market_value,
               pl:
                  (e.price - parseFloat(e.purchase_price)) *
                  e.shares *
                  (item.value / 100),
            };
            allSectorData.push(obj);
         });
      }

      // Regions
      if (e.region && Object.keys(e.region).length > 0) {
         let reg = formatTree(cn(e, "Region"));
         reg = reg.map(item => {
            !uniqueRegions.includes(item.name) && uniqueRegions.push(item.name);
            const region = {
               name: item.name,
               size: sum(item.children, "size") * e.market_value,
               pl:
                  (e.price - parseFloat(e.purchase_price)) *
                  e.shares *
                  (sum(item.children, "size") / 100),
            };
            return region;
         });
         allRegionData.push(reg);
      }
   }

   let priceSectors = [];
   if (uniqueSectors.length > 0) {
      priceSectors = uniqueSectors.reduce((acc, item, i) => {
         const value = allSectorData.reduce(
            (sum, data) => {
               if (data.name === item) sum["sum"] = sum["sum"] + data.value;
               if (data.name === item) sum["plSum"] = sum["plSum"] + data.pl;
               return sum;
            },
            {
               sum: 0,
               plSum: 0,
            }
         );

         const obj = {
            name: item,
            value: value.sum / totalMarketValue,
            color: SectorColors[i % SectorColors.length],
            pl: value.plSum,
         };
         acc.push(obj);
         return acc;
      }, []);
   } else out.priceSectors = [];

   out.allocationPriceSectors = priceSectors;

   if (uniqueSectors.length > 0)
      out.priceSectors = priceSectors
         .sort((a, b) => b.pl - a.pl)
         .filter(
            (item, index) =>
               (index <= 4 && item.pl > 0) ||
               (index >= priceSectors.length - 5 && item.pl <= 0)
         );

   if (uniqueRegions.length > 0) {
      out.priceRegions = uniqueRegions.reduce((acc, item) => {
         const size = allRegionData.reduce(
            (sum, data) => {
               const index = data.findIndex(x => x.name === item);
               if (index >= 0) sum["sum"] = sum["sum"] + data[index].size;
               if (index >= 0) sum["plSum"] = sum["plSum"] + data[index].pl;
               return sum;
            },
            {
               sum: 0,
               plSum: 0,
            }
         );
         const abc = {
            name: item,
            value:
               size.sum / totalMarketValue < 0
                  ? 0
                  : size.sum / totalMarketValue,
            color: RegionColors[item],
            pl: size.plSum,
         };
         acc.push(abc);
         return acc;
      }, []);
   } else out.priceRegions = [];

   out.uniqueRegions = uniqueRegions || [];

   out.funds.sort((a, b) => {
      return new Date(b.purchase_date) - new Date(a.purchase_date);
   });

   out.positions = Object.values(fundsH).sort(sortFn("_price"));

   out.tickers = out.positions.map(item => {
      return { ticker: item.symbol, shares: item.quantity };
   });

   return out;
});
