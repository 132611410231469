import ActionTypes from "../action-types";

const initialState = {
   registeringIn: false,
   registerFailed: false,
   registerSuccess: false,
   googleRegisterSuccess: false,
   appleRegisterSuccess: false,
   error: {},
   token: "",
   email: "",
   pendingWidgetToken: false,
   registerStepStatus: null,
   registerGoogleAuthToken: null,
   registerAppleAuthToken: null,
   linkToken: "",
   linkTokenError: false,
   bankAccounts: {},
   countryList: [],
};

export function register(state = initialState, action) {
   switch (action.type) {
      case ActionTypes.REGISTER_PROFILE_REQUEST:
         return {
            ...state,
            ...action.payload,
         };
      case ActionTypes.REGISTER_PROFILE_FAILURE:
         return {
            ...state,
            registerSuccess: false,
            registerFailed: true,
            error: action.payload,
         };
      case ActionTypes.REGISTER_PROFILE_SUCCESS:
         return {
            ...state,
            registerSuccess: true,
            registerFailed: false,
         };
      case ActionTypes.SET_REGISTER_STATE:
         return {
            ...state,
            ...action.payload,
         };
      case ActionTypes.REGISTER_SUCCESS:
         return {
            ...state,
            registerStepStatus: 1,
         };
      case ActionTypes.REGISTER_REQUEST:
      case ActionTypes.REGISTER_GOOGLE_REQUEST:
         return {
            ...state,
            registeringIn: true,
            registerSuccess: false,
            registerFailed: false,
            error: {},
         };
      case ActionTypes.REGISTER_GOOGLE_SUCCESS:
         return {
            ...state,
            googleRegisterSuccess: true,
            registerFailed: false,
         };
      case ActionTypes.REGISTER_FAILURE:
      case ActionTypes.REGISTER_GOOGLE_FAILURE:
         return {
            ...state,
            registerSuccess: false,
            registerFailed: true,
            error: action.error,
         };

      case ActionTypes.REGISTER_APPLE_REQUEST:
         return {
            ...state,
            registeringIn: true,
            registerSuccess: false,
            registerFailed: false,
            error: {},
         };
      case ActionTypes.REGISTER_APPLE_SUCCESS:
         return {
            ...state,
            appleRegisterSuccess: true,
            registerFailed: false,
         };
      case ActionTypes.REGISTER_APPLE_FAILURE:
         return {
            ...state,
            registerSuccess: false,
            registerFailed: true,
            error: action.error,
         };

      case ActionTypes.REGISTER_WIDGET:
         return {
            ...state,
            error: {},
         };
      case ActionTypes.SET_WIDGET_TOKEN:
         const { payload } = action;
         return {
            ...state,
            token: payload.token,
            email: payload.email,
            error: {},
         };

      case ActionTypes.WIDGET_FAILURE:
         return {
            ...state,
            error: {
               registerApiError: action.error,
            },
         };

      case ActionTypes.WIDGET_PENDING_TOKEN:
         return {
            ...state,
            ...action.payload,
            error: {},
         };

      case ActionTypes.CLEAR_REGISTER_ERROR:
         return {
            ...state,
            error: {},
         };
      case ActionTypes.GENERATE_LINK_TOKEN:
         return {
            ...state,
            ...action.payload,
            error: {},
         };
      case ActionTypes.LINK_TOKEN_ERROR:
         return {
            ...state,
            linkTokenError: true,
            error: action.payload,
         };
      case ActionTypes.GET_USERS_BANK_ACCOUNTS:
         return {
            ...state,
            ...action.payload,
            error: {},
         };
      case ActionTypes.CREATE_ACCOUNT:
         return {
            ...state,
            ...action.payload,
            error: {},
         };
      case ActionTypes.GET_ALL_COUNTRY_LIST_SUCCESS:
         return {
            ...state,
            ...action.payload,
         };
      case ActionTypes.OKTA_AUTH_REGISTER_SUCCESS:
         return {
            ...state,
            ...action.payload,
            registerSuccess: true,
            registerFailed: false,
         };
      case ActionTypes.REGISTER_BY_OKTA_STEPS_SUCCESS:
         return {
            ...state,
            ...action.payload,
         };
      default:
         return state;
   }
}
