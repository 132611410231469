import { createAPIInstance } from "apis/api";

export function getAllTickersAPI(payload) {
   return createAPIInstance()
      .post("/get-all-tickers", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function getAllTickersAPI_GET(payload) {
   let universe = "etf,etn,mf,cef,cash"; // 'etf,etn,mf,cef';
   // if(process.env.REACT_APP_SUB_DOMAIN === "retail") removed because needed for both users
   // shares not needed for international
   // universe += ',share';
   return createAPIInstance()
      .get("/get-all-tickers?ticker_types=" + universe, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function getAllInternationalTickersApi(payload) {
   let universe = "etf,mf";
   return createAPIInstance()
      .get("/get-all-tickers?ticker_types=" + universe, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

// export function getEnhancedCardAPI(name) {
//   return createAPIInstance()
//     .get(`/enhancement-card-data?ticker=${encodeURIComponent(name.ticker)}&overlap=${encodeURIComponent(name.overlap)}`)
//     .then(res => res.data)
// }

export function getEnhancedCardAPI(payload) {
   return createAPIInstance()
      .post("enhancement-card-data", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res.data);
}

export function downloadEnhancerReportPDFAPI(payload) {
   return createAPIInstance()
      .post("enhancement-print-report", payload, {
         // .post('print-portfolio-recommendation', JSON.stringify(payload), {
         responseType: "blob",
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res);
}

export function downloadRecEnhancerReportPDFAPI(payload) {
   return createAPIInstance()
      .post("/print-rec-enhancer-report", payload, {
         responseType: "blob",
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res);
}

export function portfolioEnhanceTransAPI(name) {
   return createAPIInstance()
      .get(
         `/portfolio-enhancement-transactions?filename=${encodeURIComponent(
            name
         )}`
      )
      .then(res => res.data);
}
// ----- FINALIZE MODEL PORTFOLIO API ------

export function finalizeModelPortfolioAPI(payload) {
   return createAPIInstance()
      .post("/enhance-model-portfolio", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function getMPDetailsCustomReplaceAPI(payload) {
   let { portfolio_id, pf_type, target_portfolio } = payload;
   // console.log(portfolio_id, pf_type, target_portfolio);
   ///api/user/enhancement-portfolio-card-data?portfolio_id=18320&pf_type=up' \
   return createAPIInstance()
      .get(
         `/user/enhancement-portfolio-card-data?portfolio_id=${encodeURIComponent(
            portfolio_id
         )}&pf_type=${pf_type}&target_portfolio=${target_portfolio}`
      )
      .then(response => response.data);
}

// error on backend side, so commenting post api for custom-search
// export function getMPDetailsCustomReplaceAPI(payload) {
//   // let param = isSponsored?`mp`:`up`;
//   // `portfolio_id=${encodeURIComponent(id)}&pf_type=${param}&target_portfolio=${target_pf_id}`
//   return createAPIInstance()
//     .post(`/user/enhancement-portfolio-card-data`, payload, {
//       headers: { 'Content-Type': 'application/json' }
//     })
//     .then(response => response.data)
// }

export function addTotumAdvisorApi(payload) {
   return createAPIInstance()
      .post("/user/add/totum/advisors", {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

// curl --location --request POST 'http://localhost:8000/api/user/totum/client_sent_questionnaire' \
// --header 'Authorization: token 4b7f43c74e9226c0ecc227016e07a29624e23e7f' \
// --header 'Content-Type: application/json' \
// --data-raw '{
//     "client_id" : "50622",
//     "first_name" : "mohit",
//     "last_name" : "ramani",
//     "email" : "abcedf@magnifi.com"
// }'

export function sendEmailAdvisorApi(payload) {
   return createAPIInstance()
      .post("/user/totum/client_send_questionnaire", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function portfolioScoreApi(payload) {
   return createAPIInstance()
      .post("/user/totum/riskscore", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

// export function portfolioScoreApi(payload) {
//   return createAPIInstance()
//     .get(`/user/totum/riskscore?portfolio_id=${encodeURIComponent(payload)}`)
//     .then(response => response.data)
// }

export function getPortfolioStats(payload) {
   let { portfolio_name, portfolio_type } = payload;
   //  '/api/user/get-target-portfolio-data?portfolio_name=Enhanced_MyPortfolio_56_57&portfolio_type=custom_portfolio'
   return createAPIInstance()
      .get(
         `/user/get-target-portfolio-data?portfolio_name=${encodeURIComponent(
            portfolio_name
         )}&portfolio_type=${portfolio_type}`
      )
      .then(response => response.data);
}

// get performanceImpact stats for selected ticker (holdings wise)
export function getStatsForSelectedTicker(payload) {
   return createAPIInstance()
      .post("/user/get-custom-portfolio-data", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

// resend email -> api/user/send/totum/questionnaire
export function resendInviteEmail(payload) {
   return createAPIInstance()
      .post("/user/send/totum/questionnaire", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

// International Starter Tool Csv Upload
export function uploadPortfolioFile(payload) {
   return createAPIInstance()
      .post("/user/portfolio-upload", payload, {
         headers: { "Content-Type": "multipart/form-data" },
      })
      .then(res => res.data);
}

// old -> user/client-list
// new -> v1/user/client-list?skip_all_data=true
export function getClientList(payload) {
   // .get(`v1/user/client-list`, {
   //   params: payload,
   // })
   return createAPIInstance()
      .get(`v1/user/client-list?skip_all_data=true`)
      .then(res => res.data);
}

export function updateClientAccountName(payload) {
   return createAPIInstance()
      .patch("/user/upload-client-portfolios", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}
