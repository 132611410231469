import moment from "moment";
import { DefaultSortAttr, DateRange, ViewNames } from "../DataSet";
import { getDiscoverFundsList } from "layouts/utils";
import ActionTypes from "../action-types";
// import { discoverySeed } from 'layouts/Seeder';

const defaultRegion =
   window.sessionStorage.getItem("selectedGlobalMarket") || "USA";
const params = new URLSearchParams(window.location.search);
const defaultQuery = params.get("query");

const defaultUniverse = ["etf", "mf", "cef", "share"];
const internationalUniverse = ["etf", "mf"];
const initialState = {
   query: defaultQuery || "",
   suggestion_query: "",
   universe: defaultRegion === "USA" ? defaultUniverse : internationalUniverse,
   fundsResponse: "",
   showGoBackSection: false,
   isMoreStocksClicked: false,
   showGoBackSectionClicked: false,
   previousSearch: {
      universe: null,
   },
   data: [],
   catData: [],
   commData: {},
   sortAttr: DefaultSortAttr,
   filterAttrData: {},
   sortDesc: false,
   stocks_data: [],
   sponsored_data: [],
   filterAttrs: {},
   dateRange: { start: moment(DateRange.start), end: moment(DateRange.end) },
   dateRangeSelected: {},
   view: ViewNames[0].name,
   selectedCards: [],
   cardsCount: 0,
   loading: true,
   related: [],
   didYouMean: [],
   correctedQuery: "",
   messages: [],
   activeRegion: "",
   highlightText: {
      feesHighLightEnable: false,
      returnHighLightEnable: false,
      riskHighLightEnable: false,
   },
   firstPop: false,
   chartData: "",
   itemCardView: "",
   actions: false,
   ticks: "",
   logId: "",
   updated_at: new moment().format("YYYY/MM/D hh:mm:ss SSS"),
   theme: "",
   sponsorsTickers: [],
   trendingQueries: [],
   currentPage: 1,
   chartReportData: {
      initialAllFunds: [],
      allFunds: {},
      selectedFunds: [],
   },
   searchedResultsForCSV: [],
   requestPayloadFilters: {
      filterKeys: [],
      filterValues: [],
   },
   requestPayloadSort: {
      name: "",
      value: "",
   },
   commonActiveFundsReturnsRange: {},
   openFundDetailDrawerFor: {},
   similarSearches: [],
   trendingSearchTheme: [],
   loadingTrendingTheme: false,
   SHOWING_ALL_STOCKS: false,
   page_number: "",
   query_log: {},
   query_uuid: "",
   total_funds: "",
   suggestionSection: "",
};

export function discovery(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.REFRESH_DISCOVERY_STATE:
         return {
            ...state,
            data: [],
            universe:
               defaultRegion === "USA"
                  ? defaultUniverse
                  : internationalUniverse,
            catData: [],
            commData: {},
            sortAttr: DefaultSortAttr,
            filterAttrData: {},
            sortDesc: false,
            filterAttrs: {},
            dateRange: {},
            dateRangeSelected: {},
            view: ViewNames[0].name,
            selectedCards: [],
            cardsCount: 0,
            loading: true,
            related: [],
            correctedQuery: "",
            messages: [],
            activeRegion: "",
            highlightText: {
               feesHighLightEnable: false,
               returnHighLightEnable: false,
               riskHighLightEnable: false,
            },
            firstPop: false,
            chartData: "",
            itemCardView: "",
            actions: false,
            ticks: "",
            logId: "",
            updated_at: new moment().format("YYYY/MM/D hh:mm:ss SSS"),
            theme: "",
            sponsorsTickers: [],
            chartReportData: {
               initialAllFunds: [],
               allFunds: {},
               selectedFunds: [],
            },
            openFundDetailDrawerFor: {},
            similarSearches: [],
            previousSearch: {
               universe: null,
            },
            SHOWING_ALL_STOCKS: false,
         };

      case ActionTypes.SEARCH_QUERY_FETCH:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.SEARCH_QUERY_SUCCESS:
         // let categoryData = payload.catData;
         let dateRangeSelected = payload.dateRangeSelected;
         let _fundsData = payload.data;
         let _stockData = payload.stocks_data || [];
         let _sponsoredData = payload.sponsoredData || [];
         let _itemKey = payload._itemKey || "";
         let _commonActiveFundsReturnsRange =
            payload.commonActiveFundsReturnsRange || {};

         // console.log(payload);
         if (_stockData && _stockData.length > 0) {
            const calcData_stocks = getDiscoverFundsList({
               items: payload.stocks_data,
               range: {
                  start: moment(dateRangeSelected.start),
                  end: moment(dateRangeSelected.end),
               },
               chartData: payload.chartData,
            });
            _stockData = calcData_stocks.items;
         }
         if (_sponsoredData && _sponsoredData.length > 0) {
            const calcData_sponsored = getDiscoverFundsList({
               items: payload.sponsoredData,
               range: {
                  start: moment(dateRangeSelected.start),
                  end: moment(dateRangeSelected.end),
               },
               chartData: payload.chartData,
            });
            _sponsoredData = calcData_sponsored.items;
         }
         if (payload.data && payload.data.length > 0) {
            const calcData = getDiscoverFundsList({
               items: payload.data,
               range: {
                  start: moment(dateRangeSelected.start),
                  end: moment(dateRangeSelected.end),
               },
               chartData: payload.chartData,
            });
            if (calcData.items && calcData.items.length > 0) {
               _fundsData = calcData.items;
               _itemKey = calcData.itemsKey;
               _commonActiveFundsReturnsRange =
                  calcData.commonActiveFundsReturnsRange;
            }
         }

         return {
            ...state,
            ...payload,
            _itemKey,
            stocks_data: _stockData,
            sponsored_data: _sponsoredData,
            data: _fundsData,
            commonActiveFundsReturnsRange: _commonActiveFundsReturnsRange,
            updated_at:
               payload.updated_at ||
               new moment().format("YYYY/MM/D hh:mm:ss SSS"),
         };

      case ActionTypes.SET_DISCOVERY_STATE:
         const {
            data: _Data,
            stocks_data: _StocksData,
            sponsored_data: _SponsoredData,
            catData: _CatData,
            dateRangeSelected: _DateRangeSelected,
            chartData: _ChartData,
            commonActiveFundsReturnsRange: _comActivRetrnRange,
         } = state;
         let _data = _Data;
         let _stocksData = _StocksData;
         let _U_sponsoredData = _SponsoredData;
         let _categoryData = _CatData;
         let _dateRangeSelected = _DateRangeSelected;
         let _chartData = _ChartData;
         let _comActiveReturnsRange_ = _comActivRetrnRange;

         if (payload.data && typeof payload.data !== "undefined") {
            _data = payload.data;
         }
         if (
            payload.stocks_data &&
            typeof payload.stocks_data !== "undefined"
         ) {
            _stocksData = payload.stocks_data;
         }
         if (
            payload.sponsored_data &&
            typeof payload.sponsored_data !== "undefined"
         ) {
            _U_sponsoredData = payload.sponsored_data;
         }

         if (payload.catData && typeof payload.catData !== "undefined") {
            _categoryData = payload.catData;
         }
         if (
            payload.dateRangeSelected &&
            typeof payload.dateRangeSelected !== "undefined"
         ) {
            if (
               payload.dateRangeSelected.start &&
               typeof payload.dateRangeSelected.start !== "string"
            ) {
               _dateRangeSelected = payload.dateRangeSelected;
            } else {
               _dateRangeSelected = {
                  start: moment(payload.dateRangeSelected.start),
                  end: moment(payload.dateRangeSelected.end),
               };
            }
         } else {
            _dateRangeSelected = {
               start: moment(_dateRangeSelected.start),
               end: moment(_dateRangeSelected.end),
            };
         }

         if (payload.chartData && typeof payload.chartData !== "undefined") {
            _chartData = payload.chartData;
         }

         if (
            payload.commonActiveFundsReturnsRange &&
            typeof payload.commonActiveFundsReturnsRange !== "undefined"
         ) {
            _comActiveReturnsRange_ = payload.commonActiveFundsReturnsRange;
         }

         if (_stocksData && _stocksData.length > 0) {
            const mappedStockData = getDiscoverFundsList({
               items: _stocksData,
               // catData: _categoryData,
               range: _dateRangeSelected,
               chartData: _chartData,
            });
            if (mappedStockData && mappedStockData.items) {
               _stocksData = mappedStockData.items;
            }
         }
         if (_U_sponsoredData && _U_sponsoredData.length > 0) {
            const mappedSponsoredData = getDiscoverFundsList({
               items: _U_sponsoredData,
               range: _dateRangeSelected,
               chartData: _chartData,
            });
            if (mappedSponsoredData && mappedSponsoredData.items) {
               _U_sponsoredData = mappedSponsoredData.items;
            }
         }
         if (_data && _dateRangeSelected && _chartData) {
            const mappedFundsData = getDiscoverFundsList({
               items: _data,
               // catData: _categoryData,
               range: _dateRangeSelected,
               chartData: _chartData,
            });
            if (mappedFundsData && mappedFundsData.items) {
               _data = mappedFundsData.items;
            }
            if (
               mappedFundsData &&
               mappedFundsData.commonActiveFundsReturnsRange &&
               typeof mappedFundsData.commonActiveFundsReturnsRange !==
                  "undefined"
            ) {
               _comActiveReturnsRange_ =
                  mappedFundsData.commonActiveFundsReturnsRange;
            }
         }

         return {
            ...state,
            ...payload,
            data: _data,
            stocks_data: _stocksData,
            sponsored_data: _U_sponsoredData,
            catData: _categoryData,
            chartData: _chartData,
            dateRangeSelected: _dateRangeSelected,
            commonActiveFundsReturnsRange: _comActiveReturnsRange_,
            updated_at:
               payload.updated_at ||
               new moment().format("YYYY/MM/D hh:mm:ss SSS"),
         };

      case ActionTypes.UPDATE_DISCOVERY_DATA:
         const {
            data: _UData,
            catData: _UCatData,
            stocks_data: _UStocksData,
            sponsored_data: _USponsoredData,
            dateRangeSelected: _UDateRangeSelected,
            chartData: _UChartData,
            commonActiveFundsReturnsRange: _comAReturnsRange,
         } = state;
         let _U_data = _UData;
         let _U_stocks_data = _UStocksData;
         let _U__sponsored_data = _USponsoredData;
         let _U_categoryData = _UCatData;
         let _U_dateRangeSelected = _UDateRangeSelected;
         let _U_chartData = _UChartData;
         let _comAReturnsRange_ = _comAReturnsRange;

         if (payload.data && typeof payload.data !== "undefined") {
            _U_data = payload.data;
         }
         if (
            payload.stocks_data &&
            typeof payload.stocks_data !== "undefined"
         ) {
            _U_stocks_data = payload.stocks_data;
         }
         if (
            payload.sponsored_data &&
            typeof payload.sponsored_data !== "undefined"
         ) {
            _U__sponsored_data = payload.sponsored_data;
         }
         if (payload.catData && typeof payload.catData !== "undefined") {
            _U_categoryData = payload.catData;
         }
         if (
            payload.dateRangeSelected &&
            typeof payload.dateRangeSelected !== "undefined"
         ) {
            if (
               payload.dateRangeSelected.start &&
               typeof payload.dateRangeSelected.start !== "string"
            ) {
               _U_dateRangeSelected = payload.dateRangeSelected;
            } else {
               _U_dateRangeSelected = {
                  start: moment(payload.dateRangeSelected.start),
                  end: moment(payload.dateRangeSelected.end),
               };
            }
         } else {
            _U_dateRangeSelected = {
               start: moment(_U_dateRangeSelected.start),
               end: moment(_U_dateRangeSelected.end),
            };
         }
         if (payload.chartData && typeof payload.chartData !== "undefined") {
            _U_chartData = payload.chartData;
         }

         if (
            _U_data &&
            _U_categoryData &&
            _U_dateRangeSelected &&
            _U_chartData
         ) {
            const _mappedFundsData = getDiscoverFundsList({
               items: _U_data,
               catData: _U_categoryData,
               range: _U_dateRangeSelected,
               chartData: _U_chartData,
            });
            if (_mappedFundsData && _mappedFundsData.items) {
               _U_data = JSON.parse(JSON.stringify(_mappedFundsData.items));
            }
            if (
               _mappedFundsData &&
               _mappedFundsData.commonActiveFundsReturnsRange &&
               typeof _mappedFundsData.commonActiveFundsReturnsRange !==
                  "undefined"
            ) {
               _comAReturnsRange_ =
                  _mappedFundsData.commonActiveFundsReturnsRange;
            }
         }
         if (
            _U_stocks_data &&
            _U_categoryData &&
            _U_dateRangeSelected &&
            _U_chartData
         ) {
            const _mappedStocksData = getDiscoverFundsList({
               items: _U_stocks_data,
               catData: _U_categoryData,
               range: _U_dateRangeSelected,
               chartData: _U_chartData,
            });
            if (_mappedStocksData && _mappedStocksData.items) {
               _U_stocks_data = JSON.parse(
                  JSON.stringify(_mappedStocksData.items)
               );
            }
         }
         if (
            _U__sponsored_data &&
            _U_categoryData &&
            _U_dateRangeSelected &&
            _U_chartData
         ) {
            const _mappedSponsoredData = getDiscoverFundsList({
               items: _U__sponsored_data,
               catData: _U_categoryData,
               range: _U_dateRangeSelected,
               chartData: _U_chartData,
            });
            if (_mappedSponsoredData && _mappedSponsoredData.items) {
               _U__sponsored_data = JSON.parse(
                  JSON.stringify(_mappedSponsoredData.items)
               );
            }
         }

         return {
            ...state,
            ...payload,
            data: _U_data,
            stocks_data: _U_stocks_data,
            sponsored_data: _U__sponsored_data,
            catData: _U_categoryData,
            dateRangeSelected: _U_dateRangeSelected,
            commonActiveFundsReturnsRange: _comAReturnsRange_,
            updated_at:
               payload.updated_at ||
               new moment().format("YYYY/MM/D hh:mm:ss SSS"),
         };

      case ActionTypes.DISCOVERY_DATA:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.DISCOVERY_QUERY:
         return {
            ...state,
            query: action.query,
         };

      case ActionTypes.SET_UNIVERSE:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.SET_THEME_VALUE: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.SET_SPONSRED_VALUE: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.TRENDING_QUERIES_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.UPDATE_CHART_REPORT_DATA: {
         return {
            ...state,
            chartReportData: payload,
         };
      }

      case ActionTypes.DOWNLOAD_SEARCHED_RESULTS_FETCH: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.DOWNLOAD_SEARCHED_RESULTS_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.RESET_DISCOVER: {
         const {chartReportData} = state
         return {
            ...initialState,
            chartReportData,
            query: "",
         };
      }

      case ActionTypes.UPDATE_FUNDS_SELECTOR_STATE: {
         const { data: _Data } = state;
         const {
            selectedFunds,
            selectedType: _sfmSelectedType,
            realTimePriceSocket,
            selectedItemTickers,
         } = payload;

         console.log(" ==== realTimePriceSocket ==== ", realTimePriceSocket);

         _Data.forEach(kl => {
            if (
               kl &&
               kl.ticker &&
               (Object.prototype.toString.call(selectedFunds) ===
               "[object Array]"
                  ? selectedFunds.includes(kl.ticker)
                  : selectedFunds === kl.ticker)
            ) {
               kl._selector = true;
               kl._selected = true;
               kl._selectedType = selectedItemTickers
                  ? kl.ticker === selectedItemTickers
                     ? _sfmSelectedType
                     : kl._selectedType
                  : _sfmSelectedType;
            } else {
               kl._selector = false;
               kl._selected = false;
               kl._selectedType = selectedItemTickers
                  ? kl.ticker === selectedItemTickers
                     ? _sfmSelectedType
                     : kl._selectedType
                  : _sfmSelectedType;
            }
         });
         return {
            ...state,
            data: _Data,
         };
      }

      case ActionTypes.REMOVE_FUNDS_SELECTOR_STATE: {
         const { data: _rData } = state;
         const { unSelectTicker } = payload;
         _rData.forEach(kl => {
            if (kl && kl.ticker === unSelectTicker) {
               kl._selector = false;
               kl._selected = false;
               if (kl._order) {
                  delete kl._order;
               }
            }
         });
         return {
            ...state,
            data: _rData,
         };
      }

      case ActionTypes.REMOVE_ALL_FUNDS_SELECTOR_STATE: {
         const { data: _rFData } = state;
         _rFData.forEach(kl => {
            kl._selector = false;
            kl._selected = false;
            if (kl._order) {
               delete kl._order;
            }
         });
         return {
            ...state,
            data: _rFData,
         };
      }

      case ActionTypes.SET_DISCOVERY_FUND_SELECTION_TYPE: {
         const { data: _DDData } = state;
         const {
            selectedFunds: _selectedFFFFunds,
            selectedType: _sfmSSSelectedType,
         } = payload;
         _DDData.forEach(kl => {
            if (
               kl &&
               kl.ticker &&
               _selectedFFFFunds &&
               _selectedFFFFunds.ticker &&
               kl.ticker === _selectedFFFFunds.ticker
            ) {
               kl._selector = true;
               kl._selected = true;
               kl._selectedType = _sfmSSSelectedType || "buy";
            }
         });
         return {
            ...state,
            data: _DDData,
         };
      }

      case ActionTypes.RESET_FUNDS_UNIVERSE_STATE: {
         return {
            ...state,
            universe:
               defaultRegion === "USA"
                  ? defaultUniverse
                  : internationalUniverse,
         };
      }

      case ActionTypes.UPDATE_UNIVERSE_BY_REGION: {
         const { region } = payload;
         return {
            ...state,
            universe:
               region === "USA" ? defaultUniverse : internationalUniverse,
         };
      }

      case ActionTypes.GET_SELECTORS_DATA_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.GET_FUND_DATA_FOR_DRAWER_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.GET_SIMILAR_SEARCH_LIST_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.GET_TRENDING_SEARCH_THEME_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.GET_TRENDING_SEARCH_THEME_FETCH: {
         return {
            ...state,
            ...payload,
         };
      }

      default:
         return state;
   }
}
