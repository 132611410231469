export const getAllPortfolioStates = state => state.portfolio;
export const getPortfolioStates = state =>
   typeof state.portfolio.caching.query !== "undefined"
      ? state.portfolio.caching
      : state.portfolio;
export const getPortfolioEnhanceTickerFilter = state =>
   typeof state.portfolio.caching.enhanceTickerFilter !== "undefined"
      ? state.portfolio.caching.enhanceTickerFilter
      : state.portfolio.enhanceTickerFilter;
export const getPortfolioResultSet = state => state.portfolio.filterResultSet; // state.portfolio.resultSet // (typeof state.portfolio.caching.resultSet !== 'undefined') ? state.portfolio.caching.resultSet : state.portfolio.resultSet;
export const getPortfolioEnhanceReplace = state =>
   state.portfolio.enhanceCTA === "Enhance" &&
   typeof state.portfolio.caching.enhanceReplace !== "undefined"
      ? state.portfolio.caching.enhanceReplace
      : state.portfolio.enhanceReplace;
