import { CURRENCY } from "DataSet";

export const regionConfig = [
   // Default Region is Always the First List Item
   {
      code: "USA",
      hash: "usa",
      textIcon: "$",
      name: "United States",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: false,
   },
   {
      code: "GBR",
      hash: "gbr",
      textIcon: "£",
      name: "United Kingdom",
      currency: "Pound Sterling",
      symbol: CURRENCY["Pound Sterling"],
      isRegion: false,
   },
   {
      code: "EURP",
      hash: "eur",
      textIcon: "€",
      name: "Europe",
      currency: "Euro",
      symbol: CURRENCY["Euro"],
      isRegion: true,
   },
   {
      code: "MIDE",
      hash: "mide",
      textIcon: "$",
      name: "Middle East",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: true,
   },
   {
      code: "AUS",
      hash: "aus",
      textIcon: "$",
      name: "Australia",
      currency: "Australian Dollar",
      symbol: CURRENCY["Australian Dollar"],
      isRegion: false,
   },
   {
      code: "APAC",
      hash: "apac",
      textIcon: "$",
      name: "Asia Pacific",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: true,
   },
   {
      code: "AMERICAS",
      hash: "americas",
      textIcon: "$",
      name: "Americas",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: true,
   },
   {
      code: "AFRC",
      hash: "afrc",
      textIcon: "$",
      name: "Africa",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: true,
   },
   {
      code: "ALL",
      hash: "all",
      name: "All",
      textIcon: "$",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
   },
];

export const AfsRegionCodeMapper = {
   null: "USA",
   USA: "USA",
   AFR: "AFRC",
   ASP: "APAC",
   MDE: "MIDE",
   AME: "AMERICAS",
   EUR: "EURP",
   GBR: "GBR",
   AUS: "AUS",
   UKN: "GBR",
   AUL: "AUS",
   UST: "USA",
};

export const AFS_COUNTRY_LIST = [
   {
      name: "Afghanistan",
      countryCode: "AFG",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Aland Islands",
      countryCode: "ALA",
      continentCode: null,
      continent: null,
   },
   {
      name: "Albania",
      countryCode: "ALB",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Algeria",
      countryCode: "DZA",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "American Samoa",
      countryCode: "ASM",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Andorra",
      countryCode: "AND",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Angola",
      countryCode: "AGO",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Anguilla",
      countryCode: "AIA",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Antarctica",
      countryCode: "ATA",
      continentCode: null,
      continent: null,
   },
   {
      name: "Antigua & Barbuda",
      countryCode: "ATG",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Argentina",
      countryCode: "ARG",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Armenia",
      countryCode: "ARM",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Aruba",
      countryCode: "ABW",
      continentCode: null,
      continent: null,
   },
   {
      name: "Australia",
      countryCode: "AUS",
      continentCode: "AUL",
      continent: "AUSTRALIA",
   },
   {
      name: "Austria",
      countryCode: "AUT",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Azerbaijan",
      countryCode: "AZE",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Bahamas",
      countryCode: "BHS",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Bahrain",
      countryCode: "BHR",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Bangladesh",
      countryCode: "BGD",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Barbados",
      countryCode: "BRB",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Belarus",
      countryCode: "BLR",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Belgium",
      countryCode: "BEL",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Belize",
      countryCode: "BLZ",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Benin",
      countryCode: "BEN",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Bermuda",
      countryCode: "BMU",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Bhutan",
      countryCode: "BTN",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Bolivia",
      countryCode: "BOL",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Bosnia & Herzegovina",
      countryCode: "BIH",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Botswana",
      countryCode: "BWA",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Bouvet Island",
      countryCode: "BVT",
      continentCode: null,
      continent: null,
   },
   {
      name: "Brazil",
      countryCode: "BRA",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "British Indian Ocean Territory",
      countryCode: "IOT",
      continentCode: null,
      continent: null,
   },
   {
      name: "British Virgin Islands",
      countryCode: "VGB",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Brunei Darussalam",
      countryCode: "BRN",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Bulgaria",
      countryCode: "BGR",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Burkina Faso",
      countryCode: "BFA",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Burundi",
      countryCode: "BDI",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Cambodia",
      countryCode: "KHM",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Cameroon",
      countryCode: "CMR",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Canada",
      countryCode: "CAN",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Cape Verde",
      countryCode: "CPV",
      continentCode: null,
      continent: null,
   },
   {
      name: "Cayman Islands",
      countryCode: "CYM",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Central African Republic",
      countryCode: "CAF",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Chad",
      countryCode: "TCD",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Channel Islands",
      countryCode: "CHI",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Chile",
      countryCode: "CHL",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "China",
      countryCode: "CHN",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Christmas Island",
      countryCode: "CXR",
      continentCode: null,
      continent: null,
   },
   {
      name: "Cocos (Keeling) Islands",
      countryCode: "CCK",
      continentCode: null,
      continent: null,
   },
   {
      name: "Colombia",
      countryCode: "COL",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Comoros",
      countryCode: "COM",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Congo",
      countryCode: "COG",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Congo, the Democratic Republic of the",
      countryCode: "COD",
      continentCode: null,
      continent: null,
   },
   {
      name: "Cook Islands",
      countryCode: "COK",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Costa Rica",
      countryCode: "CRI",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Cote d'Ivoire",
      countryCode: "CIV",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Croatia",
      countryCode: "HRV",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Cuba",
      countryCode: "CUB",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Cyprus",
      countryCode: "CYP",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Czech Republic",
      countryCode: "CZE",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Denmark",
      countryCode: "DNK",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Djibouti",
      countryCode: "DJI",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Dominica",
      countryCode: "DMA",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Dominican Republic",
      countryCode: "DOM",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "East Timor",
      countryCode: "TMP",
      continentCode: null,
      continent: null,
   },
   {
      name: "Ecuador",
      countryCode: "ECU",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Egypt",
      countryCode: "EGY",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "El Salvador",
      countryCode: "SLV",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Equatorial Guinea",
      countryCode: "GNQ",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Eritrea",
      countryCode: "ERI",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Estonia",
      countryCode: "EST",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Ethiopia",
      countryCode: "ETH",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Falkland Islands (Malvinas)",
      countryCode: "FLK",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Faroe Islands",
      countryCode: "FRO",
      continentCode: null,
      continent: null,
   },
   {
      name: "Fiji",
      countryCode: "FJI",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Finland",
      countryCode: "FIN",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "France",
      countryCode: "FRA",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "France, Metropolitan",
      countryCode: "FXX",
      continentCode: null,
      continent: null,
   },
   {
      name: "French Guiana",
      countryCode: "GUF",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "French Polynesia",
      countryCode: "PYF",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "French Southern Territories",
      countryCode: "ATF",
      continentCode: null,
      continent: null,
   },
   {
      name: "Gabon",
      countryCode: "GAB",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Gambia",
      countryCode: "GMB",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Georgia",
      countryCode: "GEO",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Germany",
      countryCode: "DEU",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Ghana",
      countryCode: "GHA",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Gibraltar",
      countryCode: "GIB",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Greece",
      countryCode: "GRC",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Greenland",
      countryCode: "GRL",
      continentCode: null,
      continent: null,
   },
   {
      name: "Grenada",
      countryCode: "GRD",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Guadeloupe",
      countryCode: "GLP",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Guam",
      countryCode: "GUM",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Guatemala",
      countryCode: "GTM",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Guernsey",
      countryCode: "GGY",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Guinea",
      countryCode: "GIN",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Guinea-Bissau",
      countryCode: "GNB",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Guyana",
      countryCode: "GUY",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Haiti",
      countryCode: "HTI",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Heard Island and McDonald Islands",
      countryCode: "HMD",
      continentCode: null,
      continent: null,
   },
   {
      name: "Holy See (Vatican City State)",
      countryCode: "VAT",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Honduras",
      countryCode: "HND",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Hong Kong",
      countryCode: "HKG",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Hungary",
      countryCode: "HUN",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Iceland",
      countryCode: "ISL",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "India",
      countryCode: "IND",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Indonesia",
      countryCode: "IDN",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Iran, Islamic Republic of",
      countryCode: "IRN",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Iraq",
      countryCode: "IRQ",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Ireland",
      countryCode: "IRL",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Ireland, Dublin",
      countryCode: "IXX",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Isle of Man",
      countryCode: "IMN",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Israel",
      countryCode: "ISR",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Italy",
      countryCode: "ITA",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Jamaica",
      countryCode: "JAM",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Japan",
      countryCode: "JPN",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Jersey",
      countryCode: "JEY",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Jordan",
      countryCode: "JOR",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Kazakhstan",
      countryCode: "KAZ",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Kenya",
      countryCode: "KEN",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Kiribati",
      countryCode: "KIR",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Kuwait",
      countryCode: "KWT",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Kyrgyzstan",
      countryCode: "KGZ",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Laos",
      countryCode: "LAO",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Latvia",
      countryCode: "LVA",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Lebanon",
      countryCode: "LBN",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Lesotho",
      countryCode: "LSO",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Liberia",
      countryCode: "LBR",
      continentCode: null,
      continent: null,
   },
   {
      name: "Libyan Arab Jamahiriya",
      countryCode: "LBY",
      continentCode: null,
      continent: null,
   },
   {
      name: "Liechtenstein",
      countryCode: "LIE",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Lithuania",
      countryCode: "LTU",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Luxembourg",
      countryCode: "LUX",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Macao",
      countryCode: "MAC",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Macedonia, the Former Yugoslav Republic of",
      countryCode: "MKD",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Madagascar",
      countryCode: "MDG",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Malawi",
      countryCode: "MWI",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Malaysia",
      countryCode: "MYS",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Maldives",
      countryCode: "MDV",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Mali",
      countryCode: "MLI",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Malta",
      countryCode: "MLT",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Marshall Islands",
      countryCode: "MHL",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Martinique",
      countryCode: "MTQ",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Mauritania",
      countryCode: "MRT",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Mauritius",
      countryCode: "MUS",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Mayotte",
      countryCode: "MYT",
      continentCode: null,
      continent: null,
   },
   {
      name: "Mexico",
      countryCode: "MEX",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Micronesia, Federated States of",
      countryCode: "FSM",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Moldova, Republic of",
      countryCode: "MDA",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Monaco",
      countryCode: "MCO",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Mongolia",
      countryCode: "MNG",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Montenegro",
      countryCode: "MNE",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Montserrat",
      countryCode: "MSR",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Morocco",
      countryCode: "MAR",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Mozambique",
      countryCode: "MOZ",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Myanmar",
      countryCode: "MMR",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Namibia",
      countryCode: "NAM",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Nauru",
      countryCode: "NRU",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Nepal",
      countryCode: "NPL",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Netherlands",
      countryCode: "NLD",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Netherlands Antilles",
      countryCode: "ANT",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "New Caledonia",
      countryCode: "NCL",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "New Zealand",
      countryCode: "NZL",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Nicaragua",
      countryCode: "NIC",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Niger",
      countryCode: "NER",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Nigeria",
      countryCode: "NGA",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Niue",
      countryCode: "NIU",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Norfolk Island",
      countryCode: "NFK",
      continentCode: null,
      continent: null,
   },
   {
      name: "North Korea",
      countryCode: "PRK",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Northern Mariana Islands",
      countryCode: "MNP",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Norway",
      countryCode: "NOR",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Occupied Palestinian Territory",
      countryCode: "PSE",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Oman",
      countryCode: "OMN",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Pakistan",
      countryCode: "PAK",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Palau",
      countryCode: "PLW",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Panama",
      countryCode: "PAN",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Papua New Guinea",
      countryCode: "PNG",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Paraguay",
      countryCode: "PRY",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Peru",
      countryCode: "PER",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Philippines",
      countryCode: "PHL",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Pitcairn",
      countryCode: "PCN",
      continentCode: null,
      continent: null,
   },
   {
      name: "Poland",
      countryCode: "POL",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Portugal",
      countryCode: "PRT",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Puerto Rico",
      countryCode: "PRI",
      continentCode: "UST",
      continent: "United States",
   },
   {
      name: "Qatar",
      countryCode: "QAT",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Romania",
      countryCode: "ROU",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Russia",
      countryCode: "RUS",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Rwanda",
      countryCode: "RWA",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Réunion",
      countryCode: "REU",
      continentCode: null,
      continent: null,
   },
   {
      name: "Samoa",
      countryCode: "WSM",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "San Marino",
      countryCode: "SMR",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Sao Tome & Principe",
      countryCode: "STP",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Saudi Arabia",
      countryCode: "SAU",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Senegal",
      countryCode: "SEN",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Serbia",
      countryCode: "SRB",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Serbia and Montenegro",
      countryCode: "SCG",
      continentCode: null,
      continent: null,
   },
   {
      name: "Seychelles",
      countryCode: "SYC",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Sierra Leone",
      countryCode: "SLE",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Singapore",
      countryCode: "SGP",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Slovakia",
      countryCode: "SVK",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Slovenia",
      countryCode: "SVN",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Solomon Islands",
      countryCode: "SLB",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Somalia",
      countryCode: "SOM",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "South Africa",
      countryCode: "ZAF",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "South Georgia and the South Sandwich Islands",
      countryCode: "SGS",
      continentCode: null,
      continent: null,
   },
   {
      name: "South Korea",
      countryCode: "KOR",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Spain",
      countryCode: "ESP",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Sri Lanka",
      countryCode: "LKA",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "St. Helena",
      countryCode: "SHN",
      continentCode: null,
      continent: null,
   },
   {
      name: "St. Kitts & Nevis",
      countryCode: "KNA",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "St. Lucia",
      countryCode: "LCA",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "St. Pierre & Miquelon",
      countryCode: "SPM",
      continentCode: null,
      continent: null,
   },
   {
      name: "St. Vincent & the Grenadines",
      countryCode: "VCT",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Sudan",
      countryCode: "SDN",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Suriname",
      countryCode: "SUR",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Svalbard and Jan Mayen",
      countryCode: "SJM",
      continentCode: null,
      continent: null,
   },
   {
      name: "Swaziland",
      countryCode: "SWZ",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Sweden",
      countryCode: "SWE",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Switzerland",
      countryCode: "CHE",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "Syrian Arab Republic",
      countryCode: "SYR",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Taiwan",
      countryCode: "TWN",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Tajikistan",
      countryCode: "TJK",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Tanzania, United Republic Of",
      countryCode: "TZA",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Thailand",
      countryCode: "THA",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Timor-Leste",
      countryCode: "TLS",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Togo",
      countryCode: "TGO",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Tokelau",
      countryCode: "TKL",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Tonga",
      countryCode: "TON",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Trinidad & Tobago",
      countryCode: "TTO",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Tunisia",
      countryCode: "TUN",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Turkey",
      countryCode: "TUR",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Turkmenistan",
      countryCode: "TKM",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Turks and Caicos Islands",
      countryCode: "TCA",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "Tuvalu",
      countryCode: "TUV",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "US Minor Outlying Islands",
      countryCode: "UMI",
      continentCode: null,
      continent: null,
   },
   {
      name: "US Virgin Islands",
      countryCode: "VIR",
      continentCode: "UST",
      continent: "United States",
   },
   {
      name: "Uganda",
      countryCode: "UGA",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Ukraine",
      countryCode: "UKR",
      continentCode: "EUR",
      continent: "Europe",
   },
   {
      name: "United Arab Emirates",
      countryCode: "ARE",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "United Kingdom",
      countryCode: "GBR",
      continentCode: "UKN",
      continent: "United Kingdom",
   },
   {
      name: "United States",
      countryCode: "USA",
      continentCode: "UST",
      continent: "United States",
   },
   {
      name: "Uruguay",
      countryCode: "URY",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Uzbekistan",
      countryCode: "UZB",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Vanuatu",
      countryCode: "VUT",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Venezuela",
      countryCode: "VEN",
      continentCode: "AME",
      continent: "Americas",
   },
   {
      name: "Vietnam",
      countryCode: "VNM",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "Wallis & Futuna Islands",
      countryCode: "WLF",
      continentCode: "ASP",
      continent: "Asia Pacific",
   },
   {
      name: "West Bank of Gaza",
      countryCode: "WBG",
      continentCode: null,
      continent: null,
   },
   {
      name: "Western Sahara",
      countryCode: "ESH",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Yemen",
      countryCode: "YEM",
      continentCode: "MDE",
      continent: "Middle East",
   },
   {
      name: "Yugoslavia",
      countryCode: "YUG",
      continentCode: null,
      continent: null,
   },
   {
      name: "Zaire",
      countryCode: "ZRE",
      continentCode: null,
      continent: null,
   },
   {
      name: "Zambia",
      countryCode: "ZMB",
      continentCode: "AFR",
      continent: "Africa",
   },
   {
      name: "Zimbabwe",
      countryCode: "ZWE",
      continentCode: "AFR",
      continent: "Africa",
   },
];
