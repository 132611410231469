import moment from "moment";
import { find } from "lodash";
import { arrayToHash, arrSum, getNestDefault, monthToDate } from "./Utils";
import { cn } from "./DataSet";

export const calcStats = data => {
   let out = { n: 0, dMax: 0.0, series: {} },
      sum = 0.0,
      cuml = 0.0,
      sd = 0.0;

   data.forEach(d => {
      cuml = (1 + cuml) * (1 + d.v) - 1;
      out.series[d.d] = { ret: d.v * 100, cuml: cuml * 100 };
      sum += d.v;
      out.n += 1;
      if (out.n === 1) {
         out.cumlMax = cuml;
      } else {
         cuml > out.cumlMax && (out.cumlMax = cuml);
         let dmax = (out.cumlMax - cuml) / (1 + out.cumlMax);
         dmax > out.dMax && (out.dMax = dmax);
      }
   });

   let mean = sum / out.n;
   let month = 0;
   data.forEach(d => {
      month++;
      let df = d.v - mean;
      sd += df * df;
   });
   out.volt = 100 * Math.sqrt((12 * sd) / out.n);
   out.yRet =
      month >= 12 ? (Math.pow(1 + cuml, 12 / month) - 1) * 100 : cuml * 100;
   out.cuml = cuml * 100;
   out.dMax = out.dMax * 100; // negate???
   out.sd = sd;
   return out;
};

export const calcAttrStats = (data, attr = "all") => {
   let out = {},
      n = 0,
      sum = 0.0,
      cuml = 0.0,
      sd = 0.0;

   data.forEach(d => {
      cuml = (1 + cuml) * (1 + d.v) - 1;
      sum += d.v;
      n += 1;
   });

   let mean = sum / n;
   if (attr === "all" || attr === "risk") {
      data.forEach(d => {
         let df = d.v - mean;
         sd += df * df;
      });
      out.risk = 100 * Math.sqrt((12 * sd) / n);
   }
   if (attr === "all" || attr === "return") {
      out.yRet =
         data.length >= 12
            ? (Math.pow(1 + cuml, 12 / data.length) - 1) * 100
            : cuml * 100;
      out.cuml = cuml * 100;
   }

   return out;
};

/* calculate score of items and set as item.score
   IN: ([ {risk: 12.7, yRet: -1.6, fees: 0.13}, ... ], ScoreAttrs)
   OUT: [ {risk: 12.7, yRet: -1.6, fees: 0.13, score: 85}, ... ] */

const inverseCal = ({ v, min, max }) => {
   // if(v === 0) {
   //   return 0;
   // }
   // console.log('===formula','v', v, 'min', min, 'max', max, '1-((v-min)/(max-min))', 1-((v-min)/(max-min)));
   return v !== null ? (1 - (v - min) / (max - min)) * 100 : 0;
};

const normalCalc = ({ v, min, max }) => {
   // if(v === 0) {
   //   return 0;
   // }
   return v !== null ? ((v - min) / (max - min)) * 100 : 0;
};

export const calcScores = (items, attrs) => {
   const weights = [];
   let totalWeight = 0;
   const normalize = attr => {
      totalWeight += parseFloat(attr.weight);
      weights.push(attr.weight);
      if (items.length === 1) return [100];
      const vals = [];
      const excludeNull = [];
      items.forEach(e => {
         vals.push(e[attr.col] || 0);
         if (e[attr.col] !== null) {
            excludeNull.push(e[attr.col] || 0);
         }
      });
      if (!excludeNull.length) {
         excludeNull.push(0);
      }
      let [min, max] = [Math.min(...excludeNull), Math.max(...excludeNull)];
      let out =
         min === max
            ? vals.map(v => 0)
            : attr.inv
            ? vals.map(v => inverseCal({ v, min, max }))
            : vals.map(v => normalCalc({ v, min, max }));
      return out;
   };
   let norms = attrs.map(normalize);
   items.forEach(
      (e, i) =>
         (e.score = norms.reduce((total, current, index) => {
            const calculatedWeight = totalWeight
               ? weights[index] / totalWeight
               : 0;
            return total + current[i] * calculatedWeight;
         }, 0))
   );
   return items;
};

const getMultiplier = col => {
   const multiplierList = [
      "weight_bid_ask",
      "trkerror",
      "weight_premium_to_nav",
      "weight_trend",
      "outperformance_3y",
      "outperformance_5y",
      "outperformance_10y",
      "alpha_5y",
      "alpha_10y",
   ];
   return multiplierList.includes(col) ? 100 : 1;
};

export const calcScreenerScores = (items, attrs) => {
   items.forEach((e, i) => {
      e.score = 100;
      e.failedConditions = [];
      attrs.forEach(attr => {
         attr.screener.criteria.forEach(cri => {
            if (cri.status) {
               const colValue =
                  e[attr.col] !== null
                     ? e[attr.col] * getMultiplier(attr.col)
                     : e[attr.col];
               const suffixMultiplier = cri.suffix === "Mn" ? 1000000 : 1;
               let criValue = isNaN(Number(cri.value))
                  ? null
                  : Number(cri.value) * suffixMultiplier;
               if (attr.col === "expense_ratio") {
                  criValue = e[cri.optMapping[cri.value]];
               }
               if (colValue !== null && criValue !== null) {
                  if (cri.condition === ">") {
                     if (!(colValue > criValue)) {
                        e.score = 0;
                        e.failedConditions.push({
                           name: attr.name,
                           ...cri,
                        });
                     }
                  } else if (cri.condition === ">=") {
                     if (!(colValue >= criValue)) {
                        e.score = 0;
                        e.failedConditions.push({
                           name: attr.name,
                           ...cri,
                        });
                     }
                  } else if (cri.condition === "<") {
                     if (!(colValue < criValue)) {
                        e.score = 0;
                        e.failedConditions.push({
                           name: attr.name,
                           ...cri,
                        });
                     }
                  } else if (cri.condition === "<=") {
                     if (!(colValue <= criValue)) {
                        e.score = 0;
                        e.failedConditions.push({
                           name: attr.name,
                           ...cri,
                        });
                     }
                  } else if (cri.condition === "==") {
                     if (!(colValue === criValue)) {
                        e.score = 0;
                        e.failedConditions.push({
                           name: attr.name,
                           ...cri,
                        });
                     }
                  }
               }
            }
         });
      });
   });
   return items;
};

export const calcEnhancementScores = (items, attrs) => {
   const weights = [];
   let totalWeight = 0;
   const normalize = attr => {
      if (!attr.weight) {
         if (attr.name === "Return") {
            attr.weight = 0.5;
         } else if (attr.name === "Fee") {
            attr.weight = 0.15;
         } else if (attr.name === "Risk") {
            attr.weight = 0.35;
         }
      }
      totalWeight += parseFloat(attr.weight);
      weights.push(attr.weight);
      if (items.length === 1) return [100];
      let vals = items.map(e => e[attr.col]);
      let [min, max] = [Math.min(...vals), Math.max(...vals)];
      let out =
         min === max
            ? vals.map(v => 0)
            : attr.inv
            ? vals.map(v => (1 - (v - min) / (max - min)) * 100)
            : vals.map(v => ((v - min) / (max - min)) * 100);
      return out;
   };
   let norms = attrs
      .filter(e => e.name === "Return" || e.name === "Fee" || e.name === "Risk")
      .map(normalize);
   // items.forEach((e, i) => e.score = (0.5)*norms[0][i] + (0.35)*norms[1][i] + (0.15)*norms[2][i]);
   items.forEach(
      (e, i) =>
         (e.score = norms.reduce((total, current, index) => {
            const calculatedWeight = totalWeight
               ? weights[index] / totalWeight
               : 0;
            return total + current[i] * calculatedWeight;
         }, 0))
   );

   return items;
};

/* IN: ({ticker: ..., returns: [...], fees: 0.13},
        {name: ..., returns: [...], fees: 0.13} ],
        0.12,
        { return: { start: <date>, end: <date> } , risk: { ... } })
   OUT: {yRet: -0.961, risk: 0.026, fees: -0.021} */

export const calcCombineStatsWeighted = (fund, pfolio, weight, dateRange) => {
   let fundH = arrayToHash(fund.returns, "d");
   let pfolioH = arrayToHash(pfolio.returns, "d");
   var mStart = monthToDate(fund._start);

   const getData = range => {
      let mm = [];
      for (
         let m = moment(range.start);
         m.isSameOrBefore(range.end);
         m.add(1, "months")
      ) {
         mm.push(m.format("MMM YYYY"));
      }

      let data = [];
      mm.forEach(m => {
         if (fundH[m] === undefined && pfolioH[m] === undefined) return;
         data.push([
            getNestDefault([m, "v"], fundH, 0.0), // fund retrun
            getNestDefault([m, "v"], pfolioH, 0.0), // portfolio retrun
            0.0,
         ]); // weighted fund return
      });
      return data;
   };

   const getMean = data => {
      let sum1 = 0.0,
         sum2 = 0.0;
      data.forEach(d => {
         d[2] = (1 - weight) * d[1] + weight * d[0];
         sum1 += d[2];
         sum2 += d[1];
      });
      return [sum1 / data.length, sum2 / data.length];
   };

   let out = { weight };

   // Return
   let data = getData(dateRange.return);
   let [mean1, mean2] = getMean(data);

   let cuml_portfolio = 0.0;
   data.forEach(d => {
      cuml_portfolio = (1 + cuml_portfolio) * (1 + d[1]) - 1;
   });
   cuml_portfolio =
      data.length >= 12
         ? (Math.pow(1 + cuml_portfolio, 12 / data.length) - 1) * 100
         : cuml_portfolio * 100;

   let cuml_wtPortfolio = 0.0;
   data.forEach(d => {
      cuml_wtPortfolio = (1 + cuml_wtPortfolio) * (1 + d[2]) - 1;
   });
   cuml_wtPortfolio =
      data.length >= 12
         ? (Math.pow(1 + cuml_wtPortfolio, 12 / data.length) - 1) * 100
         : cuml_wtPortfolio * 100;
   // out.yRet = (mean1 - mean2) * 12 * 100;
   out.yRet = cuml_wtPortfolio - cuml_portfolio;
   if (mStart.isAfter(moment(dateRange.return.start))) out.yRetOff = true;

   // Risk
   data = getData(dateRange.risk);
   [mean1, mean2] = getMean(data);
   let n = data.length,
      sd1 = 0.0,
      sd2 = 0.0;
   data.forEach(d => {
      let df = d[2] - mean1;
      sd1 += df * df;
      df = d[1] - mean2;
      sd2 += df * df;
   });
   // Sample Standard Deviation
   out.risk =
      (Math.sqrt((12 * sd1) / (n - 1)) - Math.sqrt((12 * sd2) / (n - 1))) * 100;
   if (mStart.isAfter(moment(dateRange.risk.start))) out.riskOff = true;

   out.fees =
      (1 - weight) * (pfolio.fees * 100) +
      weight * fund.fees -
      pfolio.fees * 100;
   // out.fees = (fund.fees - pfolio.fees) * weight;
   out.feesOff = out.yRetOff && out.riskOff;
   out.aum = cn(fund, "Assets")
      ? cn(fund, "Assets") - (cn(fund, "Assets") - 1000)
      : 0;
   out.vol = cn(fund, "Volume")
      ? cn(fund, "Volume") - (cn(fund, "Volume") - 1000)
      : 0;
   out.trkerror = find(fund.riskAdjReturn, {
      name: "Tracking Error",
   })
      ? find(fund.riskAdjReturn, {
           name: "Tracking Error",
        }).value -
        (find(fund.riskAdjReturn, {
           name: "Tracking Error",
        }).value -
           0.1)
      : 0;
   return out;
};

/* IN: ([{ticker: ..., returns: [...], fees: 0.13}, ...],
        {name: ..., returns: [...], fees: 0.13} ],
        [0.12, 0.13, ...])
   OUT: {yRet: -0.961, risk: 0.026, fees: -0.021} */
export const calcCombineStatsWeightedAll = (
   funds,
   pfolio,
   weights,
   dateRange
) => {
   let pfolioH = arrayToHash(pfolio.returns, "d");
   let wtSum = arrSum(weights);

   const getData = (funds, range) => {
      let fundsH = funds.map(f => arrayToHash(f.returns, "d"));
      let mm = [];
      for (
         let m = moment(range.start);
         m.isSameOrBefore(range.end);
         m.add(1, "months")
      ) {
         mm.push(m.format("MMM YYYY"));
      }

      let data = [];
      mm.forEach(m => {
         let fdata = fundsH.map(f => f[m]);
         if (fdata.every(e => e === undefined) && pfolioH[m] === undefined)
            return;

         let pval = getNestDefault([m, "v"], pfolioH, 0.0);
         let val = (1 - wtSum) * pval;
         fdata.forEach((f, i) => {
            val += weights[i] * getNestDefault(["v"], f, 0.0);
         });

         data.push([val, pval]);
      });
      return data;
   };

   const getMean = data => {
      let sum1 = 0.0,
         sum2 = 0.0;
      data.forEach(d => {
         sum1 += d[0];
         sum2 += d[1];
      });
      return [sum1 / data.length, sum2 / data.length];
   };

   let out = {};

   // Return
   let data = getData(
      funds.filter(e => !e._sfWtStats.yRetOff),
      dateRange.return
   );
   let [mean1, mean2] = getMean(data);
   out.yRet = (mean1 - mean2) * 12 * 100;

   // Risk
   data = getData(
      funds.filter(e => !e._sfWtStats.riskOff),
      dateRange.risk
   );
   [mean1, mean2] = getMean(data);
   let n = data.length,
      sd1 = 0.0,
      sd2 = 0.0;
   data.forEach(d => {
      let df = d[0] - mean1;
      sd1 += df * df;
      df = d[1] - mean2;
      sd2 += df * df;
   });
   // Sample Standard Deviation
   out.risk =
      (Math.sqrt((12 * sd1) / (n - 1)) - Math.sqrt((12 * sd2) / (n - 1))) * 100;

   out.fees =
      arrSum(funds.map((f, i) => weights[i] * f.fees)) - wtSum * pfolio.fees;
   return out;
};

/* IN: ({ticker: ..., returns: [...], fees: 0.13},
        {name: ..., returns: [...], fees: 0.13} ],
        0.12,
        { start: <date>, end: <date> })
   OUT: { weight: 0.12, inactive: false, yRet: -0.961, risk: 0.026, fees: -0.021,
          series: [{ d: 'Mar 2017' , cuml: 1.23 }, ... ] } */

export const calcCombineStatsWeightedReturns = (
   fund,
   pfolio,
   weight,
   range
) => {
   let fundH = arrayToHash(fund.returns, "d");
   let pfolioH = arrayToHash(pfolio.returns, "d");
   let mStart = monthToDate(fund._start);
   let inactive = mStart.isAfter(moment(range.start));
   let out = { weight, inactive, series: [], yRet: 0.0, risk: 0.0, fees: 0.0 };
   if (inactive) return out;

   let retData = [],
      cuml = 0.0,
      pRetSum = 0.0,
      wRetSum = 0.0,
      series = [{ d: "Start", cuml: 0 }],
      mm = getRangeMonths(range);
   mm.forEach(m => {
      if (fundH[m] === undefined && pfolioH[m] === undefined) return;

      let fRet = getNestDefault([m, "v"], fundH, 0.0), // fund retrun
         pRet = getNestDefault([m, "v"], pfolioH, 0.0); // portfolio retrun
      let wRet = (1 - weight) * pRet + weight * fRet; // weighted fund return
      cuml = cuml + wRet + cuml * wRet; // cumulative return = (1 + cuml)*(1 + v) - 1
      series.push({ d: m, cuml: cuml * 100 });
      pRetSum += pRet;
      wRetSum += wRet;
      retData.push([fRet, pRet, wRet]);
   });

   let n = retData.length;
   let pMean = pRetSum / n,
      wMean = wRetSum / n;

   // Return
   out.yRet = (wMean - pMean) * 12 * 100;
   out.series = series;

   // Risk
   let sd1 = 0.0,
      sd2 = 0.0;
   retData.forEach(d => {
      let df = d[2] - wMean;
      sd1 += df * df;
      df = d[1] - pMean;
      sd2 += df * df;
   });
   // Sample Standard Deviation
   out.risk =
      (Math.sqrt((12 * sd1) / (n - 1)) - Math.sqrt((12 * sd2) / (n - 1))) * 100;

   //out.fees = (1 - weight) * pfolio.fees + weight * fund.fees - pfolio.fees;
   out.fees = (fund.fees - pfolio.fees) * weight;

   return out;
};

/* calculate cumulative returns of data for specified date range
   IN: ([ { d: 'Mar 2017', v: 0.12 }, ... ])
   OUT: { series: [ { d: 'Mar 2017', cuml: 0.12 }, ... ] } */

export const calcCumulativeReturns = (data, range, tickerStartDate = null) => {
   if (data && data.length === 0) return { series: [] };
   let dataH = arrayToHash(data, "d");
   let series = tickerStartDate
         ? [
              {
                 d: "Start",
                 v: 0,
                 cuml: 0,
                 cumlLog: 0,
                 startDate: tickerStartDate,
              },
           ]
         : [{ d: "Start", v: 0, cuml: 0, cumlLog: 0 }],
      cuml = 0.0;

   if (range) {
      let mm = getRangeMonths(range);
      mm.forEach(m => {
         if (dataH[m] === undefined) return;
         let v = getNestDefault([m, "v"], dataH, 0.0);
         cuml = (1 + cuml) * (1 + v) - 1;
         series.push({
            d: m,
            v: v * 100,
            cuml: cuml * 100,
            cumlLog: LogValue(cuml * 100),
         });
      });
   } else {
      data.forEach(m => {
         if (m === undefined) return;
         let v = m ? m : 0.0;
         cuml = (1 + cuml) * (1 + v) - 1;
         series.push({
            d: m,
            v: v * 100,
            cuml: cuml * 100,
            cumlLog: LogValue(cuml * 100),
         });
      });
   }

   return { series };
};

export const calcDailyCumulativeReturns = (
   data,
   range,
   tickerStartDate = null
) => {
   if ((data && data.length === 0) || !data || typeof data === "undefined")
      return null;
   let _data = data;
   const start = moment(_data[0].d);
   const end = moment(_data[_data.length - 1].d);

   if (start.isAfter(end)) {
      _data = _data.reverse();
   }
   let series = tickerStartDate
         ? [
              {
                 d: "Start",
                 m: "",
                 v: 0,
                 cuml: 0,
                 cumlLog: 0,
                 startDate: tickerStartDate,
              },
           ]
         : [{ d: "Start", m: "", v: 0, cuml: 0, cumlLog: 0 }],
      cuml = 0.0;

   if (range) {
      let mm = getRangeMonthsForDailyReturns(range, "MM/YYYY");
      _data
         .filter(e => {
            let dt = moment(e.d); // monthToDate(e.d)
            return (
               dt.isAfter(moment(range.start)) && dt.isBefore(moment(range.end))
            );
         })
         .forEach((xo, i) => {
            if (typeof xo === "undefined" || !xo) return;
            let _find = mm.find(item => item === xo.m);
            // console.log(xo, xo, _find);
            if (typeof _find === "undefined") return;
            let v = xo.v ? xo.v : 0.0; // getNestDefault([m, 'v'], _dataH, 0.0);
            cuml = (1 + cuml) * (1 + v) - 1;
            series.push({
               d: moment(xo.d).format("DD MMM YYYY"),
               m: xo.m,
               v: v * 100,
               cuml: cuml * 100,
               cumlLog: LogValue(cuml * 100),
            });
         });
   } else {
      _data.forEach(m => {
         if (m === undefined) return;
         let v = m ? m : 0.0;
         cuml = (1 + cuml) * (1 + v) - 1;
         series.push({
            d: moment(m).format("DD MMM YYYY"),
            v: v * 100,
            cuml: cuml * 100,
            cumlLog: LogValue(cuml * 100),
         });
      });
   }

   return { series };
};

export const arrayToHashForDailyReturns = (arr, key, format = "MM/YYYY") => {
   let out = {};
   arr.forEach(e => {
      let d = moment(e[key]).format(format);
      out[d] = e;
   });
   return out;
};

export const getRangeMonthsForDailyReturns = (range, format = "MM/YYYY") => {
   let mm = [];
   for (
      let m = moment(range.start);
      m.isSameOrBefore(range.end);
      m.add(1, "months")
   ) {
      mm.push(m.format(format));
   }
   return mm;
};

/* IN: ({ start: 'Mar 2017', end: 'Mar 2019' })
   OUT: [ 'Mar 2017', 'Apr 2017', ... , 'Mar 2019' ] */

export const getRangeMonths = range => {
   let mm = [];
   for (
      let m = moment(range.start);
      m.isSameOrBefore(range.end);
      m.add(1, "months")
   ) {
      mm.push(m.format("MMM YYYY"));
   }
   return mm;
};

const LogValue = v =>
   v < 0 ? -1 * Math.log10((100 - v) / 100) : Math.log10((100 + v) / 100);
