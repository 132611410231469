import ActionTypes from "../action-types";

const initialState = {
   show: false,
   modalType: "",
   modalProps: {
      tickers: [],
      source: "",
   },
};

export function globalModals(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.SET_GLOBAL_MODAL:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.CLEAR_GLOBAL_MODAL:
         return {
            ...initialState,
         };
      default:
         return state;
   }
}
