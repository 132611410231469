// absolute imports
import React, { Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import DocumentTitle from "react-document-title";
import { ContainerQuery } from "react-container-query";
import { Layout, Result } from "antd";
import {
   // BrowserRouter as Router,
   Route,
   Switch,
   // Redirect,
} from "react-router-dom";

import { logout } from "actions/auth";
import { setGlobalState, getTickerListRequest } from "actions/global";
import { setSFMState } from "actions/sfm";
import {
   subscriptionPlan,
   setProfileState,
   fetchProfile,
} from "actions/profile";
import { getHomeOfficeModelsRequest } from "actions/investment";

// import SearchResultsPageV3 from "layouts/WebDashboard/SearchResultsV3/Page";
// import ClientPortfolioV2 from 'layouts/WebDashboard/ClientPortfolioV2'
// import EnhancerPage from 'layouts/WebDashboard/AccountsPage'
// import Enhancer from 'layouts/WebDashboard/Enhancer'
// import Community from "layouts/WebDashboard/Community";
// import AccountsPageV2 from 'layouts/WebDashboard/AccountsPageV2'
// import BuildPortfolio from 'layouts/WebDashboard/BuildPortfolio'
// import WidgetLoginPage from 'layouts/WebDashboard/WidgetLogin'
// import ArchivedTable from 'layouts/WebDashboard/Settings/Branding/ArchiveTable'

import { getWindowScreen } from "Utils";
import { PrivateRoute } from "shared-components";
import { routerPages, hideHeaderForRoutes } from "routermenu";

// relative imports
import Header from "./Header";
import Renderer from "./Settings/Renderer";
import SharingAlphaTC from "./Elements/SharingAlphaTC";
// import HomePage from "pages/HomePage";

const Community = React.lazy(() => import("layouts/WebDashboard/Community"));
const SearchResultsPageV3 = React.lazy(() =>
   import("layouts/WebDashboard/SearchResultsV3/Page")
);
const HomePage = React.lazy(() => import("pages/HomePage"));

const { Content } = Layout;

const containerQuery = {
   "screen-xs": {
      maxWidth: 575,
   },
   "screen-sm": {
      minWidth: 576,
      maxWidth: 767,
   },
   "screen-md": {
      minWidth: 768,
      maxWidth: 991,
   },
   "screen-lg": {
      minWidth: 992,
      maxWidth: 1199,
   },
   "screen-xl": {
      minWidth: 1200,
   },
};

class WebDashboardLayout extends React.PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         headerHeight: "",
         notify: false,
         premiumModal: false,
         premiumFeature: "false",
         counter: [],
         apexStatus: null,
         uploadDocs: false,
      };
   }

   componentWillMount() {
      const { subscriptionPlan, fetchProfile, loggedIn, setProfileState } =
         this.props;
      if (loggedIn && fetchProfile && subscriptionPlan) {
         // subscriptionPlan();
         fetchProfile("", props => {
            // console.log(props);
         });
      } else {
         if (setProfileState) {
            setProfileState({
               logo: "/assets/images/MagnifiLogo/Magnifi_Logo_Charcoal_RGB.png",
            });
         }
      }
   }

   componentDidMount() {
      if (
         document.getElementsByTagName("body") &&
         document.getElementsByTagName("body")[0]
      ) {
         document.getElementsByTagName("body")[0].style.paddingTop = 0;
      }
      const { setGlobalState } = this.props;
      const windowScreen = getWindowScreen();
      if (setGlobalState && windowScreen) {
         setGlobalState({
            ...windowScreen,
         });
      }
      // const { getTickerListRequest, magnifiTickers } = this.props
      // setTimeout(() => {
      //   if (
      //     loggedIn &&
      //     magnifiTickers &&
      //     magnifiTickers.length === 0 &&
      //     getTickerListRequest
      //   ) {
      //     getTickerListRequest()
      //   }
      // }, 3000)
      // this.checkCounterValid();
   }

   getPageTitle() {
      const { location } = this.props;
      const { pathname } = location;
      let title = "Magnifi";
      if (
         routerPages[pathname] &&
         routerPages[pathname].name &&
         routerPages[pathname].title
      ) {
         title = routerPages[pathname].title; //`${routerData[pathname].name} - Magnifi`;
      }
      return title;
   }

   setHeaderHeight = () => {
      const headerElement = document.getElementsByTagName("header");
      // const bodyPadding = window.getComputedStyle(document.body).getPropertyValue('padding-top');
      if (headerElement && headerElement.length) {
         if (headerElement[0]) {
            this.setState({
               headerHeight: `${headerElement[0].offsetHeight}px`,
            });
         }
      }
   };

   notifyToggle = () => {
      this.setState({
         notify: !this.state.notify,
      });
   };

   openNotify = () => {
      this.setState({
         notify: true,
      });
   };

   SpoofModalToggle = () => {
      localStorage.setItem("counter", 2);
      localStorage.setItem("plusCounter", 2);
      const { setSFMState, setGlobalState, autoOpenModal, surveyModal } =
         this.props;
      if (!surveyModal) {
         setGlobalState({
            surveyModal: true,
         });
      }
      if (!autoOpenModal) {
         setSFMState({
            autoOpenModal: true,
         });
      }
   };

   toggleSfmModalFromSelector = () => {
      const { loggedIn, selectorCount, setSFMState } = this.props;
      if (!loggedIn) {
         this.SpoofModalToggle();
      } else if (selectorCount > 0) {
         this.modalToggle("add");
         setSFMState({
            autoOpenModal: true,
         });
      }
   };

   modalToggle = modalToggleState => {
      const { setSFMState } = this.props;
      if (modalToggleState === "add") {
         setSFMState({
            modalToggleState: true,
            modal: true,
         });
      } else if (modalToggleState === "remove") {
         setSFMState({
            modalToggleState: false,
            modal: false,
            autoOpenModal: undefined,
         });
      }
   };

   render() {
      document.body.classList.add("p-0");
      const { logo, loggedIn, location, isTermsAgreed, is_sharing_alpha_user } =
         this.props;
      const { headerHeight } = this.state;
      const { pathname } = location;

      const layout = (
         <Layout
            style={{
               minHeight: "100vh",
            }}
         >
            {!hideHeaderForRoutes.includes(pathname) && (
               <Header
                  {...this.props}
                  logo={logo}
                  setHeaderHeight={this.setHeaderHeight}
                  sfmModalToggle={this.toggleSfmModalFromSelector}
               />
            )}
            <Content
               id="web-dashboard-content-wrapper"
               className={`web-dashboard-content-wrapper web-dashboard-content-wrapper-height-1`}
               style={{ marginTop: headerHeight }}
            >
               <Fragment>
                  <SharingAlphaTC
                     visible={
                        loggedIn &&
                        is_sharing_alpha_user &&
                        (isTermsAgreed === 0 || !isTermsAgreed)
                     }
                  />
                  <Switch>
                     <Route exact path="/" component={HomePage} />
                     <Route
                        exact
                        path="/securities"
                        component={SearchResultsPageV3}
                     />
                     <PrivateRoute path="/community" component={Community} />
                     <PrivateRoute path="/settings" component={Renderer} />
                     {/*
                <Route
                  exact
                  path="/register/widget"
                  component={WidgetLoginPage}
                />
                <PrivateRoute path="/investments" component={EnhancerPage} />
                <PrivateRoute path="/enhancer" component={Enhancer} />
                <PrivateRoute
                  path="/build-portfolio"
                  component={BuildPortfolio}
                />
                <PrivateRoute
                  path="/advisor/accountV2"
                  component={ClientPortfolioV2}
                />
                <PrivateRoute
                  path="/archived/proposals"
                  component={ArchivedTable}
                />
                <PrivateRoute path="/accounts" component={AccountsPageV2} />
              */}
                     <Route render={NotFound} />
                  </Switch>
               </Fragment>
            </Content>
         </Layout>
      );

      return (
         <DocumentTitle title={this.getPageTitle()}>
            <ContainerQuery query={containerQuery}>
               {params => <div className={classNames(params)}>{layout}</div>}
            </ContainerQuery>
         </DocumentTitle>
      );
   }
}

const mapStateToProps = ({
   discovery,
   global,
   sfm,
   auth,
   register,
   router,
   profile,
}) => ({
   useremail: auth.user.email,
   firstName: auth.user.firstName,
   apex: profile.profile && profile.profile.apex ? profile.profile.apex : null,
   lastName: auth.user.lastName,
   premiumUser: auth.user.premiumUser,
   isTermsAgreed: auth.termsAgreed,
   isNewUser: auth.newUser,
   loggedIn: auth.loggedIn,
   loggingIn: auth.loggingIn,
   accConfirmedModal: auth.accConfirmedModal,
   location: router.location,
   registerSuccess: register.registerSuccess,
   loadPercent: global.fundsApiCallPercent,
   surveyModal: global.surveyModal,
   magnifiTickers: global.magnifiTickers,
   query: discovery.query,
   updated_at: discovery.updated_at,
   sfmToggle: sfm.modalToggleState,
   modalToggleState: sfm.modalToggleState,
   logo: profile.logo,
   profileDetails: profile.profile,
   is_sharing_alpha_user: profile.profile
      ? profile.profile.is_sharing_alpha_user
      : false,
   isRedirectFromHeaderButtons: sfm.isRedirectFromHeaderButtons,
   autoOpenModal: sfm.autoOpenModal,
   selectorCount:
      sfm.items.filter(k => k._selector || k._selected).length ||
      sfm.selectorCount,
});

const mapDispatchToProps = {
   logoutHandler: logout,
   setGlobalState,
   subscriptionPlan,
   setProfileState,
   fetchProfile,
   getHomeOfficeModelsRequest,
   setSFMState,
   getTickerListRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebDashboardLayout);

const NotFound = () => {
   return (
      <Result
         status="404"
         title="404"
         subTitle="Sorry, the page you visited does not exist."
      />
   );
};
