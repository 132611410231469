import styled from "styled-components";
import { Col, Button, Modal } from "antd";
import { color, size } from "style/variables";
import { Checkbox } from "antd";

export const StyledModal = styled(Modal)`
   width: 700px !important;
   color: ${color.black} !important;
   .ant-modal-content {
      border-radius: 20px !important;
      margin: auto;
      padding: 24px !important;
      background-color: ${color.athens_gray} !important;
   }
`;

export const StyledBody = styled.span`
   font-size: ${size.heading_level_6};
   font-weight: 400;
`;

export const StyledCard = styled.div`
   background-color: white;
   padding: 16px 12px;
   margin-top: 20px;
   font-size: ${size.subheading_level_1};
   color: ${color.black};
   font-weight: 600;
   border-radius: 10px;
`;

export const StyledHeading = styled.div`
   font-size: ${size.heading_level_3} !important;
   font-weight: 600 !important;
   margin-bottom: 24px;
`;

export const StyledCheckBox = styled(Checkbox)`
   .ant-checkbox-wrapper {
      color: black;
      margin-bottom: 7px;
      display: block;
      margin-left: 0 !important;
   }
`;

export const ButtonCol = styled(Col)`
   display: flex;
   justify-content: center;
   margin-top: 30px;
`;

export const CancelButton = styled(Button)`
   width: 201px;
   height: 40px;
   border-radius: 20px;
   font-size: ${size.subheading_level_3};
   font-weight: 500;
   text-transform: uppercase;
   background-color: ${color.white};
   border: 1px solid ${color.picton_blue};
   color: ${color.picton_blue};
`;

export const SubmitButton = styled(Button)`
   width: 201px;
   height: 40px;
   border-radius: 20px;
   text-transform: uppercase;
   font-size: ${size.subheading_level_3};
   font-weight: 500;
   background-color: ${color.picton_blue};
   color: ${color.white};
`;
