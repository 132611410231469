import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Menu, Radio, Dropdown } from "antd";
import Toggle from "components/Toggle";

import { setPremiumState, premiumFeatureAccessRequest } from "actions/profile";
import { allowTestDrive } from "apis/test-drive";
import { createLoadingSelector } from "selectors/loading-selector";
import { calcTestDriveKey } from "DataSet";

import { LoginWall, PremiumModal } from "../Premium";

class PremiumButton extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loginWall: false,
         premiumNoticeModalOpen: false,
         eventProps: null,
      };
      this.premiumNoticeModalOpen = this.premiumNoticeModalOpen.bind(this);
      this.checkForPremiumUser = this.checkForPremiumUser.bind(this);
   }

   openPremiumModal = () => {
      this.setState({
         loginWall: true,
      });
   };

   closePremiumModal = () => {
      this.setState({
         loginWall: !this.state.loginWall,
      });
   };

   checkForPremiumUser = (event, props) => {
      const {
         loggedIn,
         premiumAction,
         onClick,
         premiumFeatureAccessRequest,
         onChange,
         setPremiumState,
      } = this.props;
      // console.log('-- PButton checkForPremiumUser triggered ! --', featureName);

      if (event || props) {
         this.setState({
            eventProps: { event, props },
         });
      }

      let { featureName, mapFor } = this.props;
      if (mapFor === "selector") {
         const _val =
            event && event.target ? event.target.value : event.key || event;
         featureName = calcTestDriveKey[_val] || "my_calculator"; // _val;
      }

      if (premiumAction) {
         if (loggedIn) {
            try {
               if (
                  featureName &&
                  featureName !== "" &&
                  typeof featureName !== "undefined"
               ) {
                  if (featureName === "magnifi_calculator") {
                     // bypass logic for selector
                     if (onClick) {
                        onClick(event, {});
                     }
                     if (onChange) {
                        onChange(event, props, {});
                     }
                  } else {
                     premiumFeatureAccessRequest(featureName, res => {
                        // console.log('-- premiumFeatureAccessResponse --');
                        // console.log(res);
                        if (
                           res &&
                           typeof res.premiumNoticeModalOpen !== "undefined"
                        ) {
                           this.premiumNoticeModalOpen(
                              res.premiumNoticeModalOpen,
                              () => {
                                 if (res.premiumNoticeModalOpen === false) {
                                    if (onClick) {
                                       onClick(event, {});
                                    }
                                    if (onChange) {
                                       onChange(event, props, {});
                                    }
                                 }
                              }
                           );
                        }
                        // console.log('---------------------------------');
                     });
                  }
               } else {
                  if (setPremiumState) {
                     setPremiumState({
                        premiumNoticeModalOpen: true,
                        activeFeatureName: featureName,
                        access: "Denied",
                     });
                  }
                  this.premiumNoticeModalOpen(true);
               }
            } catch (e) {
               console.log(e);
               // if (onClick) {
               // 	onClick(event, {});
               // }
               // if (onChange) {
               //   onChange(event, props, {})
               // }
            }
         } else {
            this.openPremiumModal();
         }
      } else if (onClick) {
         onClick(event);
      } else if (onChange) {
         onChange(event, props, {});
      }
   };

   premiumNoticeModalOpen = (state, callback) => {
      this.setState({ premiumNoticeModalOpen: state }, () => {
         if (callback && typeof callback === "function") {
            callback();
         }
      });
   };

   onClickOK = () => {
      const { onClick, onChange } = this.props;
      const { eventProps } = this.state;
      // console.log('-- PremiumModal onClickOK() triggered ! --');
      this.premiumNoticeModalOpen(false);
      if (onClick) {
         onClick(eventProps.event, eventProps.props);
      }
      if (onChange) {
         onChange(eventProps.event, eventProps.props);
      }
      this.setState({ eventProps: null });
   };

   onClickCancel = () => {
      const { onClickCancel } = this.props;
      // console.log('-- PremiumModal onClickCancel() triggered ! --');
      this.premiumNoticeModalOpen(false);
      if (onClickCancel) {
         onClickCancel();
      }
   };

   premiumModalToggle = () => {
      const { premiumModalToggle } = this.props;
      // console.log('-- PremiumModal premiumModalToggle() triggered ! --');
      this.premiumNoticeModalOpen(false);
      if (premiumModalToggle) {
         premiumModalToggle();
      }
   };

   testDriveAction = () => {
      const { featureName } = this.props;
      // console.log('-- PremiumModal testDriveAction() triggered ! --');
      // if (testDriveAction) {
      //   testDriveAction();
      // }
      this.onClickOK();
      allowTestDrive(featureName);
   };

   render() {
      const {
         loggedIn,
         // loading,
         premiumUser,
         // fetchingPremiumDetails,
         children,
         style,
         className,
         // featureName,
         // name,
         // premiumAction,
         type,
         activeFeatureName,
         access,
         planName,
      } = this.props;
      const { loginWall } = this.state;
      // console.log('==', this.props.history);
      const unusedButtonAttrs = [
         "history",
         "location",
         "match",
         "children",
         "auth",
         "premiumFeatures",
         "setPremiumState",
         "premiumFeatureAccessRequest",
      ];
      const buttonProps = {};
      Object.keys(this.props).forEach(kl => {
         if (!unusedButtonAttrs.includes(kl)) {
            buttonProps[kl] = this.props[kl];
         }
      });
      let ComponentTag = null;
      switch (type) {
         case "button":
            ComponentTag = (
               <Button
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </Button>
            );
            break;
         case "dropdown":
            ComponentTag = (
               <Dropdown
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </Dropdown>
            );
            break;
         case "menu":
            ComponentTag = (
               <Menu
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </Menu>
            );
            break;
         case "menuItem":
            ComponentTag = (
               <Menu.Item
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </Menu.Item>
            );
            break;
         case "anchor":
            ComponentTag = (
               <a
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </a>
            );
            break;
         case "image":
            ComponentTag = (
               <img
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  alt="premium"
                  onClick={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </img>
            );
            break;
         case "div":
            ComponentTag = (
               <div
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </div>
            );
            break;
         case "radioGroup":
            ComponentTag = (
               <Radio.Group
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onChange={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </Radio.Group>
            );
            break;
         case "toggle":
            ComponentTag = (
               <Toggle
                  {...buttonProps}
                  className={className}
                  style={style}
                  onChange={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </Toggle>
            );
            break;
         default:
            ComponentTag = (
               <Button
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkForPremiumUser(event)}
               >
                  {children}
               </Button>
            );
      }
      return (
         <Fragment>
            {ComponentTag}
            {!loggedIn && loginWall && (
               <LoginWall
                  onToggle={() => this.closePremiumModal()}
                  history={this.props.history}
               />
            )}
            {loggedIn && this.state.premiumNoticeModalOpen && (
               <PremiumModal
                  access={access}
                  premiumUser={premiumUser}
                  planName={planName}
                  activeFeatureName={activeFeatureName}
                  premiumNoticeModalOpen={this.state.premiumNoticeModalOpen}
                  onClickOK={this.onClickOK}
                  onClickCancel={this.onClickCancel}
                  onToggle={this.premiumModalToggle}
                  testDriveAction={this.testDriveAction}
               />
            )}
         </Fragment>
      );
   }
}

const mapStateToProps = ({ auth, router, loading, profile }) => ({
   auth,
   loggedIn: auth.loggedIn,
   premiumUser: auth.user.premiumUser,
   planName: auth.user.planName,
   location: router.location,
   access: profile.access, // 'Access' or 'Denied' or 'Test Drive'
   premiumFeatures: profile.premiumFeatures,
   activeFeatureName: profile.activeFeatureName,
   self_role: profile.profile.self_role,
   fetchingPremiumDetails:
      createLoadingSelector([
         "GET_SUBSCRIPTION_PLAN",
         "PREMIUM_FEATURE_ACCESS",
      ])({ loading }) || false,
});

const mapDispatchToProps = {
   setPremiumState,
   premiumFeatureAccessRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withRouter(PremiumButton));
