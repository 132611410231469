import { createAPIInstance } from "./api";
import qs from "query-string";
import axios from "axios";
import { oktaAuth, REGISTRATION_BASE_URL } from "oktaAuth";

// const mockData = {
//   token: 'qwerty123',
//   first_name: 'Good',
//   last_name: 'morning',
//   g: 1,
//   new_user: 1,
//   terms_agreed: 0
// }

export function registerProfileData(payload) {
   const api = createAPIInstance();
   const { token, data } = payload;
   console.log(payload);
   return api.post("user/save-profile", qs.stringify(data), {
      headers: {
         Authorization: `Token ${token}`,
      },
   });
}

export function register(payload) {
   payload = qs.parse(payload);
   ["utm_campaign", "utm_source", "utm_medium"].forEach(el => {
      if (window.sessionStorage.getItem(el)) {
         payload[el] = window.sessionStorage.getItem(el);
      }
   });
   payload = qs.stringify(payload);
   const api = createAPIInstance();
   return api.post("user/register", payload, {
      headers: {
         Authorization: null,
      },
   });
}

export function widgetRegister(payload) {
   const api = createAPIInstance();
   return api.post("widget/registration", payload, {
      headers: {
         Authorization: null,
      },
   });
}

export function getLinkToken() {
   return createAPIInstance()
      .get("user/plaid/bank/link/init")
      .then(response => response.data);
}

export function getUsersAccountDetails(payload) {
   return createAPIInstance()
      .post("user/plaid/bank/details", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res.data);
}

export function createUserAccount(payload) {
   const form = new FormData();
   for (let key in payload) {
      if (key === "political_organization") {
         form.append(key, JSON.stringify(payload[key]));
      } else {
         form.append(key, payload[key]);
      }
   }
   return createAPIInstance()
      .post("user/apex/account_requests_new", form, {
         headers: { "Content-Type": "multipart/form-data" },
         // headers: { 'Content-Type': 'appx-www-form-urlencodedlication/json' }
      })
      .then(res => res.data);
}

export function saveProfileData(payload) {
   return createAPIInstance()
      .post("user/save_user_profile", qs.stringify(payload))
      .then(res => res.data);
}

export function getUserFormData() {
   return createAPIInstance()
      .get(
         `user/get_saved_user_profile?token=${window.localStorage.getItem(
            "token"
         )}`
      )
      .then(response => response.data);
}

export function getApexAccountStatus(id) {
   console.log(id);
   return createAPIInstance()
      .get(`user/apex/account/status/admin?apex_account_id=${id}`)
      .then(response => response.data);
}

export function uploadDocs(form) {
   return createAPIInstance()
      .post("/user/apex/snap/images", form, {
         headers: { "Content-Type": "multipart/form-data" },
      })
      .then(res => res.data);
}

export function saveForReview(payload) {
   return createAPIInstance().post(
      "user/apex/snap/save",
      JSON.stringify(payload),
      {
         headers: { "Content-Type": "application/json" },
      }
   );
}

export function createAchRelation(payload) {
   return createAPIInstance()
      .post("user/apex/create/ach/relation", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res.data);
}

export function rebuildACH(payload) {
   return createAPIInstance()
      .post("user/apex/rebuild/ach/relation", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res.data);
}

// curl --location --request GET 'https://dev.magnifi.com/api/user/get-user-country-list' \
// --header 'Cookie: csrftoken=GbiXd3LrjMKDlGtf8AEqZiMXBwaav0H5DFHBjwpipAP2AiIvzzW3C5o8hIU9RgKZ'
export function getCountryList() {
   return createAPIInstance()
      .get(`user/get-user-country-list`)
      .then(response => response.data);
}

export async function executOktaRegister(payload) {
   const sessionexists = await oktaAuth.session.exists();
   if (sessionexists) {
      console.log("Deleting existing session");
      await oktaAuth.session.close();
   }
   // console.log(payload)
   return await axios
      .post(REGISTRATION_BASE_URL, payload)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
}

export async function executOktaIdxRegister(payload) {
   return (
      (await oktaAuth) &&
      oktaAuth.idx &&
      oktaAuth.idx
         .register(payload)
         .catch(error => {
            const _err = Promise.reject(error);
            console.log(_err);
         })
         .then(response => response)
   );
}

export async function executOktaIdxProceed(payload) {
   console.log(payload);
   return (
      (await oktaAuth) &&
      oktaAuth.idx &&
      oktaAuth.idx
         .proceed(payload)
         .then(response => response)
         .catch(error => {
            const _err = Promise.reject(error);
            console.log(_err);
         })
   );
}

export async function oktaSocialLogin(payload) {
   console.log(payload);
   return (
      (await oktaAuth) &&
      oktaAuth.idx &&
      oktaAuth.idx
         .start(payload)
         .then(response => response)
         .catch(error => {
            const _err = Promise.reject(error);
            console.log(_err);
         })
   );
}
