import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Menu, Radio, Dropdown } from "antd";

// import { setSFMState } from 'actions/sfm';
import { setGlobalState } from "actions/global";
import { createLoadingSelector } from "selectors/loading-selector";

import Toggle from "components/Toggle";

import LoginModal from "./LoginModal";

class ProtectedButton extends Component {
   constructor(props) {
      super(props);
      this.state = {
         loginWall: false,
         eventProps: null,
      };
      this.checkLoggedInState = this.checkLoggedInState.bind(this);
   }

   openLoginWall = () => {
      if (this.props.sourceUrl !== "community") {
         this.setState({
            loginWall: true,
         });
      }
   };
   closeLoginWall = () => {
      this.setState({
         loginWall: !this.state.loginWall,
      });
   };

   checkLoggedInState = (event, props) => {
      const {
         loggedIn,
         protectedAction,
         onClick,
         onChange,
         setVisible,
         history,
      } = this.props;
      // console.log('-- PButton checkLoggedInState triggered ! --', featureName);

      if (event || props) {
         this.setState({
            eventProps: { event, props },
         });
      }
      setVisible && setVisible(false);
      if (protectedAction) {
         if (loggedIn) {
            history.push("/community/dashboard");
            if (onClick) {
               onClick(event, {});
            }
            if (onChange) {
               onChange(event, props, {});
            }
         } else {
            window.sessionStorage.setItem("postLoginRedirection", "/community");
            history.push({ pathname: "/login" });
            // NOTE: close 'Survey Modal' if its open
            // ----------------------------------------------------------------------------------------
            const counter = localStorage.getItem("counter");
            const val = parseInt(counter, 10);
            localStorage.setItem("plusCounter", val + 6);
            localStorage.setItem("counter", val + 1);
            const { setGlobalState, surveyModal } = this.props;
            if (setGlobalState && surveyModal) {
               setGlobalState({
                  surveyModal: false,
                  replacePremiumWithSurvey: false,
               });
            }
            // ----------------------------------------------------------------------------------------

            // this.openLoginWall();
         }
      } else if (onClick) {
         onClick(event);
      } else if (onChange) {
         onChange(event, props, {});
      }
   };

   render() {
      const {
         type,
         style,
         history,
         loggedIn,
         children,
         className,
         sourceUrl = null,
      } = this.props;
      const { loginWall } = this.state;
      const unusedButtonAttrs = [
         "history",
         "location",
         "match",
         "children",
         "auth",
         "premiumFeatures",
         "setPremiumState",
         "premiumFeatureAccessRequest",
      ];
      const buttonProps = {};
      Object.keys(this.props).forEach(kl => {
         if (!unusedButtonAttrs.includes(kl)) {
            buttonProps[kl] = this.props[kl];
         }
      });
      let ComponentTag = null;
      switch (type) {
         case "button":
            ComponentTag = (
               <Button
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkLoggedInState(event)}
               >
                  {children}
               </Button>
            );
            break;
         case "dropdown":
            ComponentTag = (
               <Dropdown
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkLoggedInState(event)}
               >
                  {children}
               </Dropdown>
            );
            break;
         case "menu":
            ComponentTag = (
               <Menu
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkLoggedInState(event)}
               >
                  {children}
               </Menu>
            );
            break;
         case "menuItem":
            ComponentTag = (
               <Menu.Item
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkLoggedInState(event)}
               >
                  {children}
               </Menu.Item>
            );
            break;
         case "anchor":
            ComponentTag = (
               <a
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkLoggedInState(event)}
               >
                  {children}
               </a>
            );
            break;
         case "image":
            ComponentTag = (
               <img
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  alt="icon"
                  onClick={event => this.checkLoggedInState(event)}
               >
                  {children}
               </img>
            );
            break;
         case "div":
            ComponentTag = (
               <div
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkLoggedInState(event)}
               >
                  {children}
               </div>
            );
            break;
         case "radioGroup":
            ComponentTag = (
               <Radio.Group
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onChange={event => this.checkLoggedInState(event)}
               >
                  {children}
               </Radio.Group>
            );
            break;
         case "toggle":
            ComponentTag = (
               <Toggle
                  {...buttonProps}
                  className={className}
                  style={style}
                  onChange={event => this.checkLoggedInState(event)}
               >
                  {children}
               </Toggle>
            );
            break;
         default:
            ComponentTag = (
               <Button
                  {...buttonProps}
                  className={className}
                  style={style}
                  loading={false}
                  onClick={event => this.checkLoggedInState(event)}
               >
                  {children}
               </Button>
            );
      }
      return (
         <Fragment>
            {ComponentTag}
            {!loggedIn && loginWall && (
               <LoginModal
                  history={history}
                  sourceUrl={sourceUrl}
                  onToggle={() => this.closeLoginWall()}
               />
            )}
         </Fragment>
      );
   }
}

const mapStateToProps = ({ auth, router, global, loading }) => ({
   auth,
   loggedIn: auth.loggedIn,
   location: router.location,
   surveyModal: global.surveyModal,
   fetching:
      createLoadingSelector([
         "PROFILE",
         "GET_SUBSCRIPTION_PLAN",
         "PREMIUM_FEATURE_ACCESS",
      ])({ loading }) || false,
});

const mapDispatchToProps = {
   // setSFMState,
   setGlobalState,
   // setPremiumState,
   // premiumFeatureAccessRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withRouter(ProtectedButton));
