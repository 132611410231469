import zipObject from "lodash/zipObject";

const actions = [
   "FETCH_RELATED_INSIGHTS_DATA_REQUEST",
   "FETCH_RELATED_INSIGHTS_DATA_FETCH",
   "FETCH_RELATED_INSIGHTS_DATA_SUCCESS",
   "CLEAR_PLUGINS",
];

export default zipObject(actions, actions);
