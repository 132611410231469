import ActionTypes from "../action-types";
import { getUpdatedFundVotes } from "./helpers/community";

const rateFundsInitialState = {
   getUserFundRating: {},
   updateUserFundRating: {},
   removeUserFundRating: {},
   addNewFundRating: {},
};

const fundComments = {
   fundComments: { result: [] },
   fundCommentsTotal: 0,
};

const rankingHistoryData = {
   result: [],
   regionData: [],
};

const userSearchFunds = {
   funds: [],
   emptyResults: false,
   searchLoading: false,
   searchFundQuery: "",
   correctedQuery: "",
};

const userSelectedDetails = {
   userSelectedFund: "",
   assetOverviewId: "",
};

const updateCommunityPortfolioInitialState = {
   updateCommunityPortfolio: {},
};

const topRankedMembersInitialState = {
   topRankedMembers: { fundSelection: {}, assetAllocation: {}, commentary: {} },
};

const ratingData = {
   result: [],
   total: 0,
   sortBy: "",
   sortDirection: "",
   isDrawerOpen: false,
   getPage: 1,
};

const userComments = {
   result: [],
   total: 0,
   sortBy: "",
   sortDirection: "",
};

const initialState = {
   portfolioData: null,
   portfolioId: null,
   portfolioName: null,
   portfolioType: null,
   rankingData: {},
   selectedPortfolio: null,
   rankingDataLoaded: false,
   assetAllocationDataLoaded: true,
   portfolioFetched: false,
   assetAllocationChartData: {},
   getUserFundRating: {},
   updateUserFundRating: {},
   removeUserFundRating: {},
   getUserSearchFundRating: {},
   //assetAllocationData: [],
   userSearchFunds,
   fundComments,
   regionData: [],
   rankingHistoryData,
   userSelectedDetails,
   ratingData,
   sponsorOptData: {},
   ...userComments,
   ...rateFundsInitialState,
   ...topRankedMembersInitialState,
   ...updateCommunityPortfolioInitialState,
};

export function community(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.GET_USER_RANKING_DATA_FETCH: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.GET_USER_RANKING_DATA_SUCCESS: {
         return {
            ...state,
            rankingData: { ...payload },
            rankingDataLoaded: payload.rankingDataLoaded,
         };
      }

      case ActionTypes.RESET_COMMUNITY_STATE:
         return {
            ...state,
            ...initialState,
            userSearchFunds: { funds: [] },
         };

      case ActionTypes.GET_TOP_RANKED_MEMBERS_SUCCESS: {
         return {
            ...state,
            rankingHistoryData: {},
            ...payload,
         };
      }

      case ActionTypes.SELECT_PORTFOLIO_TO_LOAD: {
         return {
            ...state,
            portfolioId: payload.portfolioId,
            portfolioName: payload.portfolioName,
            portfolioType: payload.portfolioType,
            selectedPortfolio: payload.selectedPortfolio,
         };
      }

      case ActionTypes.GET_FUND_DISCOVERY_SEARCH_SUCCESS: {
         return {
            ...state,
            userSearchFunds: {
               funds: payload.response.funds,
               emptyResults: payload.response.funds.length === 0,
               searchLoading: false,
               searchFundQuery: payload.query,
               correctedQuery: payload.response.corrected_query,
            },
         };
      }

      case ActionTypes.GET_FUND_DISCOVERY_SEARCH_FETCH: {
         return {
            ...state,
            userSearchFunds: {
               emptyResults: false,
               searchLoading: true,
               searchFundQuery: payload.query,
            },
         };
      }

      case ActionTypes.GET_FUND_DISCOVERY_SEARCH_RESET: {
         return {
            ...state,
            userSearchFunds: {
               ...userSearchFunds,
            },
         };
      }

      case ActionTypes.GET_YOUR_PORFOLIO_SUCCESS: {
         return {
            ...state,
            ...payload,
            portfolioFetched: true,
         };
      }

      case ActionTypes.GET_YOUR_PORFOLIO_FETCH: {
         return {
            ...state,
            portfolioFetched: false,
         };
      }
      case ActionTypes.GET_ASSET_ALLOCATION_DATA_FETCH: {
         return { ...initialState, ...payload };
      }

      case ActionTypes.SET_UPDATED_PORTFOLIO_DATA_SUCCESS: {
         return {
            ...state,
            updateCommunityPortfolio: payload,
         };
      }

      case ActionTypes.SET_UPDATED_PORTFOLIO_DATA_SUBMIT: {
         return {
            ...state,
         };
      }
      case ActionTypes.SET_UPDATED_PORTFOLIO_DATA_REQUEST: {
         return {
            ...state,
            ...updateCommunityPortfolioInitialState,
         };
      }
      // case ActionTypes.GET_USER_RANKING_HISTORY_FETCH: {
      //   return {
      //     ...state,
      //     rankingHistoryData: {}
      //   }
      // }
      case ActionTypes.GET_USER_RANKING_HISTORY_FETCH: {
         return {
            ...state,
            rankingHistoryData: {},
         };
      }
      case ActionTypes.GET_USER_RANKING_HISTORY_REQUEST: {
         return {
            ...state,
            rankingHistoryData: {},
         };
      }

      case ActionTypes.GET_USER_RANKING_HISTORY_SUCCESS: {
         return {
            ...state,
            rankingHistoryData: payload,
         };
      }
      case ActionTypes.GET_USER_RATINGS_SUCCESS: {
         return {
            ...state,
            ...rateFundsInitialState,
            ratingData: {
               result: payload.result,
               total: payload.total,
               sortBy: payload.sortBy,
               sortDirection: payload.sortDirection,
               isDrawerOpen: payload.total < 5 ? true : false,
               getPage: payload.getPage,
            },
         };
      }
      case ActionTypes.ADD_NEW_FUND_RATING_SUCCESS: {
         return {
            ...state,
            ...rateFundsInitialState,
            addNewFundRating: payload,
         };
      }
      case ActionTypes.RESET_FUND_RATING: {
         return {
            ...state,
            ...rateFundsInitialState,
         };
      }

      case ActionTypes.GET_USER_FUND_RATING_FETCH: {
         return { ...state, ...rateFundsInitialState };
      }
      case ActionTypes.GET_USER_FUND_RATING_SUCCESS: {
         return { ...state, getUserFundRating: payload };
      }
      case ActionTypes.UPDATE_USER_FUND_RATING_FETCH: {
         return { ...state, updateUserFundRating: {} };
      }
      case ActionTypes.UPDATE_USER_FUND_RATING_SUCCESS: {
         return {
            ...state,
            ...rateFundsInitialState,
            updateUserFundRating: payload,
         };
      }
      case ActionTypes.REMOVE_USER_FUND_RATING_FETCH: {
         return { ...state, removeUserFundRating: {} };
      }
      case ActionTypes.REMOVE_USER_FUND_RATING_SUCCESS: {
         return { ...state, removeUserFundRating: payload };
      }
      case ActionTypes.GET_USER_SEARCH_FUND_RATING_FETCH: {
         return {
            ...state,
            getUserFundRatingLoaded: true,
            getUserSearchFundRating: {},
         };
      }
      case ActionTypes.GET_USER_SEARCH_FUND_RATING_SUCCESS: {
         return {
            ...state,
            getUserFundRating: payload,
         };
      }
      case ActionTypes.UPDATE_ALLOCATION_FUND_DATA: {
         return {
            ...state,
            funds: payload.funds,
         };
      }

      // Commentary
      case ActionTypes.GET_USER_COMMENTS_FETCH: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.GET_USER_COMMENTS_SUCCESS: {
         return {
            ...state,
            userComments: {
               result: payload.result,
               total: payload.total,
               sortBy: payload.sortBy,
               sortDirection: payload.sortDirection,
            },
         };
      }

      case ActionTypes.GET_FUND_COMMENTS_DATA_FETCH: {
         return {
            ...state,
            fundComments: {},
         };
      }

      case ActionTypes.GET_FUND_COMMENTS_DATA_SUCCESS: {
         return {
            ...state,
            fundComments: payload,
         };
      }

      case ActionTypes.POST_FUND_REPLY_SUCCESS: {
         const updatedFundComments = state.fundComments;

         const findCommentIndex = updatedFundComments.result.findIndex(
            item => item.commentId === payload.commentId
         );

         if (findCommentIndex > -1) {
            const oldReplies =
               updatedFundComments.result[findCommentIndex].comment_reply || [];
            updatedFundComments.result[findCommentIndex].comment_reply = [
               ...(payload.newComment || []),
               ...oldReplies,
            ];
         }
         return {
            ...state,
            fundComments: updatedFundComments,
         };
      }

      case ActionTypes.POST_FUND_COMMENT_SUCCESS: {
         const updatedFundComments = state.fundComments;
         updatedFundComments.result = [payload, ...updatedFundComments.result];
         updatedFundComments.userCommentId = payload.commentId;
         return { ...state, fundComments: updatedFundComments };
      }

      case ActionTypes.DELETE_FUND_REPLY_SUCCESS: {
         const updatedFundComments = state.fundComments;

         const parentCommentIndex = updatedFundComments.result.findIndex(
            item => item.commentId === payload.commentId
         );
         if (parentCommentIndex > -1) {
            const updatedRepliesList = updatedFundComments.result[
               parentCommentIndex
            ].comment_reply.filter(item => item.reply_id !== payload.replyId);

            updatedFundComments.result[parentCommentIndex].comment_reply =
               updatedRepliesList;
         }

         return {
            ...state,
            fundComments: updatedFundComments,
         };
      }
      case ActionTypes.DELETE_FUND_COMMENT_SUCCESS: {
         const { commentId: deletedCommentId } = payload;
         const updatedFundComments = state.fundComments;
         updatedFundComments.result = updatedFundComments.result.filter(
            comment => comment.commentId !== deletedCommentId
         );
         updatedFundComments.userCommentId = null;

         return { ...state, fundComments: updatedFundComments };
      }

      case ActionTypes.ADD_FUND_COMMENT_VOTE_FETCH: {
         const { commentId = "", parentCommentId = "", newVotes } = payload;
         const updatedFundComments = getUpdatedFundVotes(
            state.fundComments,
            commentId,
            newVotes,
            parentCommentId
         );
         return {
            ...state,
            fundComments: updatedFundComments,
         };
      }

      case ActionTypes.ADD_FUND_COMMENT_VOTE_SUCCESS: {
         return { ...state };
      }

      case ActionTypes.ADD_FUND_COMMENT_VOTE_ERROR: {
         const { commentId = "", parentCommentId = "", oldVotes } = payload;
         const updatedFundComments = getUpdatedFundVotes(
            state.fundComments,
            commentId,
            oldVotes,
            parentCommentId
         );
         return {
            ...state,
            fundComments: updatedFundComments,
         };
      }
      case ActionTypes.GET_SPONSOR_OPT_REQUEST: {
         const { postSponsorOptUpdateUrl } = payload;
         return {
            ...state,
            sponsorOptData: { postSponsorOptUpdateUrl },
         };
      }
      case ActionTypes.POST_SPONSOR_OPT_REQUEST: {
         return {
            ...state,
         };
      }
      case ActionTypes.POST_SPONSOR_OPT_SUCCESS: {
         return {
            ...state,
            sponsorOptData: {},
         };
      }

      default:
         return state;
   }
}
