import { createAPIInstance } from "./api";
// import qs from 'query-string'

export function reportUpload(payload) {
   console.log(payload);
   return createAPIInstance()
      .post("/user/user-compare-reports", payload, {
         responseType: "blob",
         headers: {
            "Content-Type": "application/json",
         },
      })
      .then(response => response);
}

export function reportUploadPUT(payload) {
   return createAPIInstance()
      .put("/user/user-compare-reports", payload, {
         headers: {
            "Content-Type": "application/json",
         },
      })
      .then(response => response);
}

export function combineMyPFReturnsChart(id) {
   return createAPIInstance()
      .get(`v1/returns-chart-portfolio?portfolio=${id}`)
      .then(response => response.data);
}

export function getEnhancementScore(payload) {
   return createAPIInstance()
      .post("https://dev.magnifi.com/api/user/combine-score", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response);
}
