type UserVoteType = "upVote" | "downVote" | "noVote";

type NewVotesType = {
   up_votes: number;
   down_votes: number;
   user_vote: UserVoteType;
};

export const getFundCommentIndex = (commentId: string, fundComments: any) =>
   fundComments.result.findIndex(
      (comment: any) => comment.commentId === commentId
   );

export const getFundReplyIndex = (
   replyId: string,
   parentCommentIndex: number,
   fundComments: any
) =>
   fundComments.result[parentCommentIndex].comment_reply &&
   fundComments.result[parentCommentIndex].comment_reply.findIndex(
      (reply: any) => reply.reply_id === replyId
   );

export const getUpdatedFundVotes = (
   fundComments: any,
   commentId: string,
   newVotes: NewVotesType,
   parentCommentId?: string
) => {
   const updatedFundComments = fundComments;
   let fundCommentIndex: number = -1;
   let fundReplyIndex: number = -1;

   if (!parentCommentId) {
      fundCommentIndex = getFundCommentIndex(commentId, fundComments);
      if (fundCommentIndex > -1) {
         updatedFundComments.result[fundCommentIndex] = {
            ...updatedFundComments.result[fundCommentIndex],
            ...newVotes,
         };
      }
   } else {
      fundCommentIndex = getFundCommentIndex(parentCommentId, fundComments);

      if (fundCommentIndex > -1) {
         fundReplyIndex = getFundReplyIndex(
            commentId,
            fundCommentIndex,
            fundComments
         );
         updatedFundComments.result[fundCommentIndex].comment_reply[
            fundReplyIndex
         ] = {
            ...updatedFundComments.result[fundCommentIndex].comment_reply[
               fundReplyIndex
            ],
            ...{
               reply_up_votes: newVotes.up_votes,
               reply_down_votes: newVotes.down_votes,
               reply_user_vote: newVotes.user_vote,
            },
         };
      }
   }
   return updatedFundComments;
};
