import React from "react";
import { Button } from "antd";
import { Modal, ModalBody } from "reactstrap";

import { jsonInURL } from "Utils";

const LoginModal = ({ onToggle, history, sourceUrl = null }) => {
   const onClose = () => {
      onToggle();
   };

   const onRegisterClick = () => {
      let registerParams = {
         user: "sharing-alpha",
      };
      if (sourceUrl) {
         registerParams.sourceUrl = sourceUrl;
      }
      history.push(`${jsonInURL(registerParams, "/register")}`);
   };

   const onLoginClick = () => {
      let loginParams = {};
      if (sourceUrl) {
         loginParams = {
            sourceUrl,
         };
      }
      history.push(`${jsonInURL(loginParams, "/login")}`);
   };

   return (
      <Modal
         isOpen
         centered
         backdrop
         keyboard={true}
         className="protected-button-modal-container"
         backdropClassName="premium-feature-process-modal-backdrop"
      >
         <ModalBody
            className="ssf-modal__body"
            style={
               window.screen.width > 767
                  ? { padding: "3rem" }
                  : { padding: "1rem" }
            }
         >
            <div className="premium-feature-process-modal-container text-center protected-button-container">
               <i
                  className="fal fa-times-circle premium-feature-completed-close"
                  onClick={onClose}
               />
               <div className="header-wrapper">
                  <p className="fs-18" style={{ marginTop: "-5px" }}>
                     New to Magnifi?
                  </p>
                  <Button
                     type="primary"
                     className="primary-btn"
                     style={
                        window.screen.width < 767
                           ? { minWidth: 160, marginLeft: "0.5rem" }
                           : { minWidth: 160 }
                     }
                     onClick={onRegisterClick}
                  >
                     Register
                  </Button>
               </div>
               <div className="divider">
                  <div className="circle">
                     <span
                        className=""
                        style={{ color: "#fff", fontSize: "1rem" }}
                     >
                        OR{" "}
                     </span>
                  </div>
               </div>
               <div className="header-wrapper">
                  <p className="fs-18" style={{ marginTop: "-5px" }}>
                     Already a User?
                  </p>
                  <Button
                     className="secondary-btn"
                     style={
                        window.screen.width < 767
                           ? { minWidth: 160, marginLeft: "0.5rem" }
                           : { minWidth: 160 }
                     }
                     onClick={onLoginClick}
                  >
                     Login
                  </Button>
               </div>
            </div>
         </ModalBody>
      </Modal>
   );
};

export default LoginModal;
