import ActionTypes from "../action-types";

/* GET RESULT FOR A QUERY */
export function searchQueryRequest(payload) {
   return {
      type: ActionTypes.SEARCH_QUERY_REQUEST,
      payload,
   };
}

export function searchQueryFetch(payload) {
   return {
      type: ActionTypes.SEARCH_QUERY_FETCH,
      payload,
   };
}

export function searchQuerySuccess(payload) {
   return {
      type: ActionTypes.SEARCH_QUERY_SUCCESS,
      payload,
   };
}

export function dymLogs(payload) {
   return {
      type: ActionTypes.DYM_LOGS,
      payload,
   };
}

export function fetchFundsPrice(payload) {
   return {
      type: ActionTypes.UPDATE_DISCOVERY_DATA,
      payload,
   };
}

export function updateFundsData(payload) {
   return {
      type: ActionTypes.UPDATE_DISCOVERY_DATA,
      payload,
   };
}

export function refreshFundsState(payload) {
   return {
      type: ActionTypes.REFRESH_DISCOVERY_STATE,
      payload,
   };
}

export function setFundsState(payload) {
   return {
      type: ActionTypes.SET_DISCOVERY_STATE,
      payload,
   };
}

export function newQuery(query) {
   return {
      type: ActionTypes.DISCOVERY_QUERY,
      query,
   };
}

export function searchQuery(payload) {
   return {
      type: ActionTypes.SEARCH_QUERY,
      payload,
   };
}

export function setUniverse(payload) {
   return {
      type: ActionTypes.SET_UNIVERSE,
      payload,
   };
}

export function setUniverseByRegion(payload) {
   return {
      type: ActionTypes.UPDATE_UNIVERSE_BY_REGION,
      payload,
   };
}

export function searchAnalyticsAPI(payload) {
   return {
      type: ActionTypes.SEARCH_ANALYTICS_API,
      payload,
   };
}

export function setThemeValue(payload) {
   return {
      type: ActionTypes.SET_THEME_VALUE,
      payload,
   };
}

export function setSponsoredValue(payload) {
   return {
      type: ActionTypes.SET_SPONSRED_VALUE,
      payload,
   };
}

export function getTrendingFeeds() {
   return {
      type: ActionTypes.TRENDING_QUERIES_REQUEST,
   };
}

export function fetchTrendingFeeds() {
   return {
      type: ActionTypes.TRENDING_QUERIES_FETCH,
   };
}

export function trendingFeedsResponse(payload) {
   return {
      type: ActionTypes.TRENDING_QUERIES_SUCCESS,
      payload,
   };
}

export function getChartDataForAllFunds(payload) {
   return {
      type: ActionTypes.GET_CHART_DATA_FOR_ALL_DISCOVERY_FUNDS,
      payload,
   };
}

export function updateSelFundsInCrd(payload) {
   return {
      type: ActionTypes.UPDATE_SEL_FUNDS_IN_CRD,
      payload,
   };
}

export function updateChartReportData(payload) {
   return {
      type: ActionTypes.UPDATE_CHART_REPORT_DATA,
      payload,
   };
}

/* -- Download CSV of Searched Results -- */
export function downloadSearchedResultsRequest(payload, callback) {
   return {
      type: ActionTypes.DOWNLOAD_SEARCHED_RESULTS_REQUEST,
      payload,
      callback,
   };
}

export function downloadSearchedResultsFetch(payload) {
   return {
      type: ActionTypes.DOWNLOAD_SEARCHED_RESULTS_FETCH,
      payload,
   };
}

export function downloadSearchedResultsSuccess(payload) {
   return {
      type: ActionTypes.DOWNLOAD_SEARCHED_RESULTS_SUCCESS,
      payload,
   };
}

/* SELECTOR WITH TIME FRAME */
export function selectorWithTimeFrameRequest(payload, callback) {
   return {
      type: ActionTypes.SELECTOR_WITH_TIME_FRAME_REQUEST,
      payload,
      callback,
   };
}

export function selectorWithTimeFrameFetch(payload) {
   return {
      type: ActionTypes.SELECTOR_WITH_TIME_FRAME_FETCH,
      payload,
   };
}

export function selectorWithTimeFrameSuccess(payload) {
   return {
      type: ActionTypes.SELECTOR_WITH_TIME_FRAME_SUCCESS,
      payload,
   };
}

/* UPDATED EACH FUND SELECTOR STATE based on SFM selected ticker */
export function updatedFundsSelectorState(payload) {
   return {
      type: ActionTypes.UPDATE_FUNDS_SELECTOR_STATE,
      payload,
   };
}

/* REMOVE FUND or set _selector = false for ticker */
export function removeFundsSelectorState(payload) {
   return {
      type: ActionTypes.REMOVE_FUNDS_SELECTOR_STATE,
      payload,
   };
}

/* REMOVE ALL FUND or set _selector = false for ticker */
export function removeAllFundsSelectorState(payload) {
   return {
      type: ActionTypes.REMOVE_ALL_FUNDS_SELECTOR_STATE,
      payload,
   };
}

/* RESET_FUNDS_UNIVERSE_STATE */
export function refreshFundsUniverseState() {
   return {
      type: ActionTypes.RESET_FUNDS_UNIVERSE_STATE,
   };
}

/* SAVE DISCOVER POPUP  OPTIONS*/
export function savePopoverOption(payload) {
   return {
      type: ActionTypes.SAVE_POPOVER_OPTION,
      payload,
   };
}

/* GET SELECTORS DATA FROM API */
export function getSelectorDataRequest(payload, callback) {
   return {
      type: ActionTypes.GET_SELECTORS_DATA_REQUEST,
      payload,
      callback,
   };
}

export function getSelectorDataFetch(payload) {
   return {
      type: ActionTypes.GET_SELECTORS_DATA_FETCH,
      payload,
   };
}

export function executeMagnifiOrderRequest(payload) {
   console.log("eee");
   return {
      type: ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_REQUEST,
      payload,
   };
}

export function executeMagnifiOrderFetch(payload) {
   return {
      type: ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_FETCH,
      payload,
   };
}

export function executeMagnifiOrderSuccess(payload) {
   return {
      type: ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_SUCCESS,
      payload,
   };
}

export function getSelectorDataSuccess(payload) {
   return {
      type: ActionTypes.GET_SELECTORS_DATA_SUCCESS,
      payload,
   };
}

/* setFundSelectionType */
export function setFundSelectionType(payload) {
   return {
      type: ActionTypes.SET_DISCOVERY_FUND_SELECTION_TYPE,
      payload,
   };
}

/* BASKET DRAWER DATA*/
export function getFundDataForDrawerRequest(payload, callback) {
   return {
      type: ActionTypes.GET_FUND_DATA_FOR_DRAWER_REQUEST,
      payload,
      callback,
   };
}

/* GET Similar Search List */
export function getSimilarSearchListRequest(payload, callback) {
   return {
      type: ActionTypes.GET_SIMILAR_SEARCH_LIST_REQUEST,
      payload,
      callback,
   };
}

export function getFundDataForDrawerFetch(payload) {
   return {
      type: ActionTypes.GET_FUND_DATA_FOR_DRAWER_FETCH,
      payload,
   };
}

export function getSimilarSearchListFetch(payload) {
   return {
      type: ActionTypes.GET_SIMILAR_SEARCH_LIST_FETCH,
      payload,
   };
}

export function getFundDataForDrawerSuccess(payload) {
   return {
      type: ActionTypes.GET_FUND_DATA_FOR_DRAWER_SUCCESS,
      payload,
   };
}

export function getSimilarSearchListSuccess(payload) {
   return {
      type: ActionTypes.GET_SIMILAR_SEARCH_LIST_SUCCESS,
      payload,
   };
}

export function getTrendingSearchThemeRequest(payload, callback) {
   return {
      type: ActionTypes.GET_TRENDING_SEARCH_THEME_REQUEST,
      payload,
      callback,
   };
}

export function getTrendingSearchThemeFetch(payload) {
   return {
      type: ActionTypes.GET_TRENDING_SEARCH_THEME_FETCH,
      payload,
   };
}

export function getTrendingSearchThemeError(payload) {
   return {
      type: ActionTypes.GET_TRENDING_SEARCH_THEME_ERROR,
      payload,
   };
}

export function getTrendingSearchThemeSuccess(payload) {
   return {
      type: ActionTypes.GET_TRENDING_SEARCH_THEME_SUCCESS,
      payload,
   };
}

export function resetDiscover() {
   return {
      type: ActionTypes.RESET_DISCOVER,
   };
}
