import zipObject from "lodash/zipObject";

const actions = [
   "REGISTER_PROFILE_FAILURE",
   "REGISTER_PROFILE_FETCH",
   "REGISTER_PROFILE_SUCCESS",
   "REGISTER_PROFILE_REQUEST",

   "SET_REGISTER_STATE",

   "REGISTER_GOOGLE_REQUEST",
   "REGISTER_GOOGLE_FETCH",
   "REGISTER_GOOGLE_SUCCESS",
   "REGISTER_GOOGLE_FAILURE",

   "REGISTER_APPLE_REQUEST",
   "REGISTER_APPLE_FETCH",
   "REGISTER_APPLE_SUCCESS",
   "REGISTER_APPLE_FAILURE",

   "REGISTER_REQUEST",
   "REGISTER_SUCCESS",
   "REGISTER_FAILURE",

   "REGISTER_WIDGET",
   "SET_WIDGET_TOKEN",
   "WIDGET_FAILURE",
   "WIDGET_PENDING_TOKEN",
   "CLEAR_REGISTER_ERROR",
   "GENERATE_LINK_TOKEN",
   "LINK_TOKEN_ERROR",
   "GET_USERS_BANK_ACCOUNTS",
   "CREATE_ACCOUNT",

   "GET_ALL_COUNTRY_LIST_REQUEST",
   "GET_ALL_COUNTRY_LIST_SUCCESS",
   "GET_ALL_COUNTRY_LIST_FAILURE",

   "OKTA_AUTH_REGISTER_REQUEST",
   "OKTA_AUTH_REGISTER_FETCH",
   "OKTA_AUTH_REGISTER_SUCCESS",

   "VALIDATE_OKTA_USER_REQUEST",
   "VALIDATE_OKTA_USER_FETCH",
   "VALIDATE_OKTA_USER_SUCCESS",

   "REGISTER_BY_OKTA_STEPS_REQUEST",
   "REGISTER_BY_OKTA_STEPS_FETCH",
   "REGISTER_BY_OKTA_STEPS_SUCCESS",
];

export default zipObject(actions, actions);
