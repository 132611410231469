import ActionTypes from "../action-types";
import { SetGlobalModalProps } from "./types/globalModals";

export function setGlobalModalState(payload: SetGlobalModalProps) {
   return {
      type: ActionTypes.SET_GLOBAL_MODAL,
      payload,
   };
}

export function clearGlobalModalState() {
   return {
      type: ActionTypes.CLEAR_GLOBAL_MODAL,
   };
}
