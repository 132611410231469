import React from "react";
import { Select } from "antd";
import { DownArrow } from "./styles";
import { addNewEventsToAnalytics } from "Utils";
import { resetDiscover } from "actions/discovery";
import { useDispatch } from "react-redux";

const { Option, OptGroup } = Select;

const CURRENCY = [
   {
      code: "EU",
      name: "Euros",
      icon: <i class="far fa-euro-sign"></i>,
   },
   {
      code: "USD",
      name: "US Dollar",
      icon: <i class="far fa-dollar-sign"></i>,
   },
];
const displayesCountries = [
   "USA",
   "GBR",
   "EURP",
   "MIDE",
   "AUS",
   "APAC",
   "AMERICAS",
   "AFRC",
];
// const COUNTRY_CURRENCY = {
//   EU: ['Euros', 'US Dollar'],
//   USA: ['US Dollar'],
//   ALL: ['US Dollar'],
// };

// const CountryWithCurrency = ({ selectedCountry = COUNTRY[0].code, selectedCurrency = COUNTRY_CURRENCY[COUNTRY[0].code][0], onSelectCountry, onSelectCurrency }) => {
//   const [country, selectCountry] = useState(selectedCountry);
//   const [currency, selectCurrency] = useState(selectedCurrency);
//
//   const selectCountryHandler = value => {
//     selectCountry(value);
//     selectCurrency(COUNTRY_CURRENCY[value][0]);
//     if (onSelectCountry) {
//       onSelectCountry(value);
//     }
//   };
//
//   const selectCurrencyHandler = (value) => {
//     selectCurrency(value);
//     if (onSelectCurrency) {
//       onSelectCurrency(value);
//     }
//   }
//
//   return (
//     <div className={'country-current-dropdown-container'}>
//       <Select defaultValue={country} onChange={selectCountryHandler}>
//         {COUNTRY.map(c => (
//           <Option key={c.code}>
//             <span className={'country-current-icon'}>{c.icon}</span>
//             <span className={'country-current-text'}>{c.name}</span>
//           </Option>
//         ))}
//       </Select>
//       <Select value={currency} onChange={selectCurrencyHandler}>
//         {COUNTRY_CURRENCY[country].map(city => (
//           <Option key={city}>{city}</Option>
//         ))}
//       </Select>
//     </div>
//   );
// };

const CountryAndCurrency = ({
   regions,
   showCurrency = false,
   selectedCountry = "USA",
   selectedCurrency = "USA",
   onSelectCountry,
   onSelectCurrency,
   hideDropDown,
   history,
   location,
}) => {
   const dispatch = useDispatch();
   const selectCountryHandler = value => {
      if (onSelectCountry) {
         const isRegion = regions.find(({ code }) => code === value).isRegion;
         onSelectCountry(value, isRegion);
         addNewEventsToAnalytics("Region Change", {
            cta_clicked: "region_dropdown",
            region: value,
         });
         history.replace("/securities");
         window.sessionStorage.removeItem("query");
         window.sessionStorage.removeItem("old_query");
         dispatch(resetDiscover());
      }
   };

   const selectCurrencyHandler = value => {
      if (onSelectCurrency) {
         onSelectCurrency(value);
      }
   };

   return (
      <div
         id={"country-current-dropdown-container"}
         className={"country-current-dropdown-container"}
         style={{ visibility: hideDropDown ? "hidden" : "visible" }}
      >
         <Select
            virtual={false}
            placement="bottomRight"
            style={{ width: 165 }}
            listHeight={300}
            value={selectedCountry}
            suffixIcon={<DownArrow />}
            dropdownClassName={"country-current-dropdown-wrapper"}
            getPopupContainer={() =>
               document.getElementById("country-current-dropdown-container")
            }
            onChange={selectCountryHandler}
         >
            <OptGroup
               className={"country-current-dropdown-options-label"}
               label={"Choose your region"}
            >
               {regions
                  .filter(country => displayesCountries.includes(country.code))
                  .map(c => (
                     <Option
                        className={"country-current-dropdown-options"}
                        key={c.code}
                     >
                        <span className={"country-current-icon"}>
                           {" "}
                           {c.name}
                        </span>
                        {/* <span className={'country-current-text'}>
                  {c.name}
                </span> */}
                     </Option>
                  ))}
            </OptGroup>
         </Select>
         {showCurrency && (
            <Select
               placement="bottomRight"
               style={{ width: 125 }}
               value={selectedCurrency}
               onChange={selectCurrencyHandler}
               dropdownClassName={"country-current-dropdown-wrapper"}
               getPopupContainer={() =>
                  document.getElementById("country-current-dropdown-container")
               }
            >
               <OptGroup
                  className={"country-current-dropdown-options-label"}
                  label={"Select Currency"}
               >
                  {CURRENCY.map(k => (
                     <Option
                        className={"country-current-dropdown-options"}
                        key={k.code}
                     >
                        <span>{k.name}</span>
                        <span
                           className={"country-current-icon"}
                           style={{ margin: "0 0 0 8px" }}
                        >
                           {k.icon}
                        </span>
                     </Option>
                  ))}
               </OptGroup>
            </Select>
         )}
      </div>
   );
};

export default CountryAndCurrency;
