import ActionTypes from "../action-types";

export function resetCachingForInvestment() {
   return {
      type: ActionTypes.RESET_CACHING_FOR_INVESTMENT,
   };
}

export function setCachingForInvestment() {
   return {
      type: ActionTypes.SET_CACHING_FOR_INVESTMENT,
   };
}

export function downloadPortfolioReport(payload) {
   return {
      type: ActionTypes.DOWNLOAD_PORTFOLIO_REPORT,
      payload,
   };
}
export function downloadRecEnhancerReport(payload) {
   return {
      type: ActionTypes.DOWNLOAD_REC_ENHANCER_REPORT,
      payload,
   };
}

export function setInvestmentDefault() {
   return {
      type: ActionTypes.SET_DEFAULT_INVESTMENT,
   };
}

export function setInvestmentState(payload) {
   return {
      type: ActionTypes.SET_INVESTMENT_STATE,
      payload,
   };
}

export function dropFund(payload) {
   return {
      type: ActionTypes.DROP_FUND,
      payload,
   };
}

export function investmentLoading(payload) {
   return {
      type: ActionTypes.INVESTMENT_LOADING,
      payload,
   };
}

export function investmentFetch(callback) {
   return {
      type: ActionTypes.INVESTMENT_FETCH,
      callback,
   };
}

export function investmentFetchRequest() {
   return {
      type: ActionTypes.INVESTMENT_FETCH_REQUEST,
   };
}

export function investmentFetchSuccess(payload) {
   return {
      type: ActionTypes.INVESTMENT_FETCH_SUCCESS,
      payload,
   };
}

export function investmentAccountFilter(account) {
   return {
      type: ActionTypes.INVESTMENT_ACCOUNT_FILTER,
      account,
   };
}

export function investmentUiView(view) {
   return {
      type: ActionTypes.INVESTMENT_UI_VIEW,
      view,
   };
}

export function investmentActiveFund(fundId) {
   return {
      type: ActionTypes.INVESTMENT_ACTIVE_FUND,
      fundId,
   };
}

export function investmentAccountView(view) {
   return {
      type: ActionTypes.INVESTMENT_ACCOUNT_VIEW,
      view,
   };
}

export function investmentOptions(payload) {
   return {
      type: ActionTypes.INVESTMENT_OPTIONS,
      payload,
   };
}

export function investmentOptionsRedirect(payload, callback) {
   return {
      type: ActionTypes.INVESTMENT_OPTIONS_REDIRECT,
      payload,
      callback,
   };
}

export function fetchPortfolioData(payload, callback) {
   return {
      type: ActionTypes.FETCH_PORTFOLIO_DATA,
      payload,
      callback,
   };
}

export function fetchMarketData(payload, callback) {
   return {
      type: ActionTypes.FETCH_MARKET_DATA,
      payload,
      callback,
   };
}

export function uploadPortfolio(payload, callback) {
   return {
      type: ActionTypes.UPLOAD_PORTFOLIO,
      payload,
      callback,
   };
}

export function deletePortfolio(payload, callback) {
   return {
      type: ActionTypes.DELETE_PORTFOLIO,
      payload,
      callback,
   };
}

export function demoPortfolio(payload) {
   return {
      type: ActionTypes.SET_DEMO_PORTFOLIO,
      payload,
   };
}

export function saveDemoPortfolio(payload) {
   return {
      type: ActionTypes.SAVE_DEMO_PORTFOLIO,
      payload,
   };
}

export function resetInvestmentState() {
   return {
      type: ActionTypes.RESET_INVESTMENT_STATE,
   };
}

/* ----- GET_CLIENT_LIST ----- */
export function getClientListRequest(payload) {
   return {
      type: ActionTypes.GET_CLIENT_LIST_REQUEST,
      payload,
   };
}

export function getClientListFetch(payload) {
   return {
      type: ActionTypes.GET_CLIENT_LIST_FETCH,
      payload,
   };
}

export function getClientListSuccess(payload) {
   return {
      type: ActionTypes.GET_CLIENT_LIST_SUCCESS,
      payload,
   };
}
/* ------ */
export function getRealTimePriceForTickersRequest(payload, callback) {
   return {
      type: ActionTypes.GET_REAL_TIME_PRICE_TICKERS_REQUEST,
      payload,
      callback,
   };
}
export function getRealTimePriceForTickersFetch(payload, callback) {
   return {
      type: ActionTypes.GET_REAL_TIME_PRICE_TICKERS_FETCH,
      payload,
      callback,
   };
}
export function getRealTimePriceForTickersSuccess(payload, callback) {
   return {
      type: ActionTypes.GET_REAL_TIME_PRICE_TICKERS_SUCCESS,
      payload,
      callback,
   };
}
/* ---- GET_HOME_OFFICE_MODELS ---- */

export function getHomeOfficeModelsRequest(payload) {
   return {
      type: ActionTypes.GET_HOME_OFFICE_MODELS_REQUEST,
      payload,
   };
}

export function getHomeOfficeModelsFetch(payload) {
   return {
      type: ActionTypes.GET_HOME_OFFICE_MODELS_FETCH,
      payload,
   };
}

export function getHomeOfficeModelsSuccess(payload) {
   return {
      type: ActionTypes.GET_HOME_OFFICE_MODELS_SUCCESS,
      payload,
   };
}

/* ---- LOAD DEMO CSV FILE ---- */

export function loadDemoCSVRequest(payload) {
   return {
      type: ActionTypes.LOAD_DEMO_CSV_REQUEST,
      payload,
   };
}

export function loadDemoCSVFetch(payload) {
   return {
      type: ActionTypes.LOAD_DEMO_CSV_FETCH,
      payload,
   };
}

export function loadDemoCSVSuccess(payload) {
   return {
      type: ActionTypes.LOAD_DEMO_CSV_SUCCESS,
      payload,
   };
}

/* ---- GET PORTFOLIO LIST ONLY ---- */
export function getPortfolioListBuilderRequest(payload) {
   return {
      type: ActionTypes.GET_PORTFOLIO_LIST_BUILDER_REQUEST,
      payload,
   };
}
export function getPortfolioListBuilderFetch(payload) {
   return {
      type: ActionTypes.GET_PORTFOLIO_LIST_BUILDER_FETCH,
      payload,
   };
}
export function getPortfolioListBuilderSuccess(payload) {
   return {
      type: ActionTypes.GET_PORTFOLIO_LIST_BUILDER_SUCCESS,
      payload,
   };
}
