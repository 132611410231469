import zipObject from "lodash/zipObject";

const actions = [
   "SET_DEFAULT_INVESTMENT",
   "DOWNLOAD_PORTFOLIO_REPORT",
   "DOWNLOAD_REC_ENHANCER_REPORT",
   "SET_INVESTMENT_STATE",
   "DROP_FUND",
   "INVESTMENT_LOADING",
   "INVESTMENT_FETCH",
   "INVESTMENT_FETCH_REQUEST",
   "INVESTMENT_FETCH_SUCCESS",
   "INVESTMENT_ACCOUNT_FILTER",
   "INVESTMENT_UI_VIEW",
   "INVESTMENT_ACTIVE_FUND",
   "INVESTMENT_ACCOUNT_VIEW",
   "INVESTMENT_OPTIONS",
   "INVESTMENT_OPTIONS_REDIRECT",
   "FETCH_PORTFOLIO_DATA",
   "FETCH_MARKET_DATA",
   "UPLOAD_PORTFOLIO",
   "DELETE_PORTFOLIO",
   "SET_DEMO_PORTFOLIO",
   "SAVE_DEMO_PORTFOLIO",
   "SET_CACHING_FOR_INVESTMENT",
   "RESET_CACHING_FOR_INVESTMENT",
   "RESET_INVESTMENT_STATE",

   // 'GET_CLIENT_LIST_REQUEST',
   // 'GET_CLIENT_LIST_FETCH',
   // 'GET_CLIENT_LIST_SUCCESS',

   "GET_HOME_OFFICE_MODELS_REQUEST",
   "GET_HOME_OFFICE_MODELS_FETCH",
   "GET_HOME_OFFICE_MODELS_SUCCESS",

   "GET_REAL_TIME_PRICE_TICKERS_REQUEST",
   "GET_REAL_TIME_PRICE_TICKERS_FETCH",
   "GET_REAL_TIME_PRICE_TICKERS_SUCCESS",

   "LOAD_DEMO_CSV_REQUEST",
   "LOAD_DEMO_CSV_FETCH",
   "LOAD_DEMO_CSV_SUCCESS",

   "GET_PORTFOLIO_LIST_BUILDER_REQUEST",
   "GET_PORTFOLIO_LIST_BUILDER_FETCH",
   "GET_PORTFOLIO_LIST_BUILDER_SUCCESS",
];

export default zipObject(actions, actions);
