/* eslint-disable array-callback-return */
import moment from "moment";
import _ from "lodash";
// import { cn } from 'DataSet';
import { nameToDateRange, monthToDate } from "Utils";
import { numberCheck } from "layouts/utils";

export const dateSort = (a, b) => {
   let _a = monthToDate(a.d),
      _b = monthToDate(b.d);
   return _a.isBefore(_b) ? -1 : _a.isAfter(_b) ? 1 : 0;
};

export const getFundsByQuery = query => {
   console.log(query);
};

export const replicaGetPerformanceSeriesAndYear = ({
   currentSeries,
   enhancedSeries,
   query,
   retDropDown,
}) => {
   const currentS = JSON.parse(JSON.stringify(currentSeries));
   let enhancedS = JSON.parse(JSON.stringify(enhancedSeries));
   // check if current Month has 0 value..  for 1 year, 3 years and 5 years.
   const currentMonth = moment(new Date()).format("MMM YYYY");
   // console.log('currentMonth--->', currentMonth);

   if (
      enhancedS.pfRet1y[enhancedS.pfRet1y.length - 1].d === currentMonth &&
      enhancedS.pfRet1y[enhancedS.pfRet1y.length - 1].v === 0
   ) {
      // console.log(`for 1 year Returns current ${currentMonth} Month has 0 value`);
      const findIndex = _.findIndex(enhancedS.pfRet1y, ["d", currentMonth]);
      if (typeof findIndex !== "undefined") {
         enhancedS.pfRet1y = enhancedS.pfRet1y.splice(0, findIndex);
      }
   }

   if (
      enhancedS.pfRet3y[enhancedS.pfRet3y.length - 1].d === currentMonth &&
      enhancedS.pfRet3y[enhancedS.pfRet3y.length - 1].v === 0
   ) {
      // console.log(`for 3 years Returns current ${currentMonth} Month has 0 value`);
      const findIndex = _.findIndex(enhancedS.pfRet3y, ["d", currentMonth]);
      if (typeof findIndex !== "undefined") {
         enhancedS.pfRet3y = enhancedS.pfRet3y.splice(0, findIndex);
      }
   }

   if (
      enhancedS.pfRet5y[enhancedS.pfRet5y.length - 1].d === currentMonth &&
      enhancedS.pfRet5y[enhancedS.pfRet5y.length - 1].v === 0
   ) {
      // console.log(`for 5 years Returns current ${currentMonth} Month has 0 value`);
      const findIndex = _.findIndex(enhancedS.pfRet5y, ["d", currentMonth]);
      if (typeof findIndex !== "undefined") {
         enhancedS.pfRet5y = enhancedS.pfRet5y.splice(0, findIndex);
      }
   }

   // default 'out'
   let out = {
      currentSeries,
      enhancedSeries,
      ...retDropDown,
   };

   // console.log(out);

   const currentSeriesObj = {
      pfRet1y: currentS.pfRet1y.map(o => {
         return o;
      }),
      pfRet3y: currentS.pfRet3y.map(o => {
         return o;
      }),
      pfRet5y: currentS.pfRet5y.map(o => {
         return o;
      }),
   };
   const enhancedSeriesObj = {
      pfRet1y: enhancedS.pfRet1y.map(o => {
         return o;
      }),
      pfRet3y: enhancedS.pfRet3y.map(o => {
         return o;
      }),
      pfRet5y: enhancedS.pfRet5y.map(o => {
         return o;
      }),
   };

   const EnhancedActiveArr1Yr = enhancedSeriesObj.pfRet1y.filter(
      u => u.v !== 0
   );
   const EnhancedActiveArr3Yr = enhancedSeriesObj.pfRet3y.filter(
      u => u.v !== 0
   );
   const EnhancedActiveArr5Yr = enhancedSeriesObj.pfRet5y.filter(
      u => u.v !== 0
   );

   if (EnhancedActiveArr1Yr.length > 0) {
      // console.log('----- pfRet1y -----');
      // console.log('pfRet1y total months count', EnhancedActiveArr1Yr.length);
      if (EnhancedActiveArr1Yr.length < enhancedS.pfRet1y.length) {
         // console.log('lets updated series for 1 year ?', EnhancedActiveArr1Yr.length, enhancedS.pfRet1y);
         const yearCouldBe = `${EnhancedActiveArr1Yr[0].d} - ${
            EnhancedActiveArr1Yr[EnhancedActiveArr1Yr.length - 1].d
         }`;
         out.pfRet1y = {
            year: yearCouldBe,
            disabled: false,
            col: 12,
         };

         out.pfRet3y.disabled = true;
         out.pfRet5y.disabled = true;

         const startingIndex = _.findIndex(currentSeriesObj.pfRet1y, [
            "d",
            EnhancedActiveArr1Yr[0].d,
         ]);
         const endingIndex = _.findIndex(currentSeriesObj.pfRet1y, [
            "d",
            EnhancedActiveArr1Yr[EnhancedActiveArr1Yr.length - 1].d,
         ]);
         // console.log('startingIndex 1yr', startingIndex, EnhancedActiveArr1Yr[0].d);
         // console.log('endingIndex 1yr', endingIndex, EnhancedActiveArr1Yr[EnhancedActiveArr1Yr.length - 1].d);

         const ff = currentSeriesObj.pfRet1y.slice(
            startingIndex,
            endingIndex + 1
         );
         // console.log(ff);
         currentSeriesObj.pfRet1y = ff;
         enhancedSeriesObj.pfRet1y = EnhancedActiveArr1Yr;
         // console.log(enhancedSeriesObj);

         out.currentSeries = currentSeriesObj;
         out.enhancedSeries = enhancedSeriesObj;
      }
   }

   if (EnhancedActiveArr3Yr.length > 0) {
      // console.log('----- pfRet3y -----');
      // console.log('pfRet3y total months count', EnhancedActiveArr3Yr.length);
      // console.log('lets update series for 3 years ?', EnhancedActiveArr3Yr.length < enhancedS.pfRet3y,  EnhancedActiveArr3Yr.length, enhancedS.pfRet3y.length);
      if (EnhancedActiveArr3Yr.length < enhancedS.pfRet3y.length) {
         // console.log('lets update series for 3 years ?', EnhancedActiveArr3Yr.length, enhancedS.pfRet3y);
         const yearCouldBe = `${EnhancedActiveArr3Yr[0].d} - ${
            EnhancedActiveArr3Yr[EnhancedActiveArr3Yr.length - 1].d
         }`;
         if (!out.pfRet3y.disabled) {
            out.pfRet3y = {
               year: yearCouldBe,
               disabled: false,
               col: 12,
            };
         }

         out.pfRet5y.disabled = true;

         const startingIndex = _.findIndex(currentSeriesObj.pfRet3y, [
            "d",
            EnhancedActiveArr3Yr[0].d,
         ]);
         const endingIndex = _.findIndex(currentSeriesObj.pfRet3y, [
            "d",
            EnhancedActiveArr3Yr[EnhancedActiveArr3Yr.length - 1].d,
         ]);

         // console.log('startingIndex 3yr', startingIndex, EnhancedActiveArr3Yr[0].d);
         // console.log('endingIndex 3yr', endingIndex, EnhancedActiveArr3Yr[EnhancedActiveArr3Yr.length - 1].d);

         const ff = currentSeriesObj.pfRet3y.slice(
            startingIndex,
            endingIndex + 1
         );
         // console.log(ff);
         currentSeriesObj.pfRet3y = ff;
         enhancedSeriesObj.pfRet3y = EnhancedActiveArr3Yr;

         out.currentSeries = currentSeriesObj;
         out.enhancedSeries = enhancedSeriesObj;
      }
   }

   if (EnhancedActiveArr5Yr.length > 0) {
      // console.log('----- pfRet5y -----');
      // console.log('pfRet5y total months count', EnhancedActiveArr5Yr.length);
      if (EnhancedActiveArr5Yr.length < enhancedS.pfRet5y.length) {
         // console.log('lets update series for 5 years ?', EnhancedActiveArr5Yr.length, enhancedS.pfRet5y.length);
         const yearCouldBe = `${EnhancedActiveArr5Yr[0].d} - ${
            EnhancedActiveArr5Yr[EnhancedActiveArr5Yr.length - 1].d
         }`;
         if (!out.pfRet5y.disabled) {
            out.pfRet5y = {
               year: yearCouldBe,
               disabled: false,
               col: 12,
            };
         }

         const startingIndex = _.findIndex(currentSeriesObj.pfRet5y, [
            "d",
            EnhancedActiveArr5Yr[0].d,
         ]);
         const endingIndex = _.findIndex(currentSeriesObj.pfRet5y, [
            "d",
            EnhancedActiveArr5Yr[EnhancedActiveArr5Yr.length - 1].d,
         ]);

         // console.log('startingIndex 5yr', startingIndex, EnhancedActiveArr5Yr[0].d);
         // console.log('endingIndex 5yr', endingIndex, EnhancedActiveArr5Yr[EnhancedActiveArr5Yr.length - 1].d);

         const ff = currentSeriesObj.pfRet5y.slice(
            startingIndex,
            endingIndex + 1
         );
         // console.log(ff);
         currentSeriesObj.pfRet5y = ff;
         enhancedSeriesObj.pfRet5y = EnhancedActiveArr5Yr;

         out.currentSeries = currentSeriesObj;
         out.enhancedSeries = enhancedSeriesObj;
      }
   }

   // console.log(out.currentSeries);
   // console.log(currentSeriesObj);
   // console.log(enhancedSeriesObj);

   // NOTE:  current STATS sharpe calc only:
   let annualizedRet1y = getAnnualizedReturnsByYears(out.currentSeries.pfRet1y);
   let annualizedRet3y = getAnnualizedReturnsByYears(out.currentSeries.pfRet3y);
   let annualizedRet5y = getAnnualizedReturnsByYears(out.currentSeries.pfRet5y);

   let annualizedVolt1y = getAnnualizedVolatilityByYears(
      out.currentSeries.pfRet1y
   );
   let annualizedVolt3y = getAnnualizedVolatilityByYears(
      out.currentSeries.pfRet3y
   );
   let annualizedVolt5y = getAnnualizedVolatilityByYears(
      out.currentSeries.pfRet5y
   );

   // console.log(out);
   // debugger
   let sharpe_ratio_3y =
      isNaN((annualizedRet3y / annualizedVolt3y).toFixed(2))
         ? 0.0
         : (annualizedRet3y / annualizedVolt3y).toFixed(2);
   let sharpe_pfRet1y =
      isNaN((annualizedRet1y / annualizedVolt1y).toFixed(2))
         ? 0.0
         : (annualizedRet1y / annualizedVolt1y).toFixed(2);
   let sharpe_pfRet3y =
      isNaN((annualizedRet3y / annualizedVolt3y).toFixed(2))
         ? 0.0
         : (annualizedRet3y / annualizedVolt3y).toFixed(2);
   let sharpe_pfRet5y =
      isNaN((annualizedRet5y / annualizedVolt5y).toFixed(2))
         ? 0.0
         : (annualizedRet5y / annualizedVolt5y).toFixed(2);

   out.currentSeries.sharpe_ratio_3y = isFinite(sharpe_ratio_3y)
      ? sharpe_ratio_3y
      : 0;
   out.currentSeries.sharpe_pfRet1y = isFinite(sharpe_pfRet1y)
      ? sharpe_pfRet1y
      : 0;
   out.currentSeries.sharpe_pfRet3y = isFinite(sharpe_pfRet3y)
      ? sharpe_pfRet3y
      : 0;
   out.currentSeries.sharpe_pfRet5y = isFinite(sharpe_pfRet5y)
      ? sharpe_pfRet5y
      : 0;

   return out;
};

export const getPerformanceSeriesAndYear = ({
   currentSeries,
   enhancedSeries,
   query,
   retDropDown,
}) => {
   const currentS = JSON.parse(JSON.stringify(currentSeries));
   let enhancedS = JSON.parse(JSON.stringify(enhancedSeries));
   console.log(enhancedS);
   // check if current Month has 0 value..  for 1 year, 3 years and 5 years.
   const currentMonth = moment(new Date()).format("MMM YYYY");
   // console.log('currentMonth--->', currentMonth);

   if (
      enhancedS.pfRet1y[enhancedS.pfRet1y.length - 1].d === currentMonth &&
      enhancedS.pfRet1y[enhancedS.pfRet1y.length - 1].v === 0
   ) {
      // console.log(`for 1 year Returns current ${currentMonth} Month has 0 value`);
      const findIndex = _.findIndex(enhancedS.pfRet1y, ["d", currentMonth]);
      if (typeof findIndex !== "undefined") {
         enhancedS.pfRet1y = enhancedS.pfRet1y.splice(0, findIndex);
      }
   }

   if (
      enhancedS.pfRet3y[enhancedS.pfRet3y.length - 1].d === currentMonth &&
      enhancedS.pfRet3y[enhancedS.pfRet3y.length - 1].v === 0
   ) {
      // console.log(`for 3 years Returns current ${currentMonth} Month has 0 value`);
      const findIndex = _.findIndex(enhancedS.pfRet3y, ["d", currentMonth]);
      if (typeof findIndex !== "undefined") {
         enhancedS.pfRet3y = enhancedS.pfRet3y.splice(0, findIndex);
      }
   }

   if (
      enhancedS.pfRet5y[enhancedS.pfRet5y.length - 1].d === currentMonth &&
      enhancedS.pfRet5y[enhancedS.pfRet5y.length - 1].v === 0
   ) {
      // console.log(`for 5 years Returns current ${currentMonth} Month has 0 value`);
      const findIndex = _.findIndex(enhancedS.pfRet5y, ["d", currentMonth]);
      if (typeof findIndex !== "undefined") {
         enhancedS.pfRet5y = enhancedS.pfRet5y.splice(0, findIndex);
      }
   }

   // default 'out'
   let out = {
      currentSeries,
      enhancedSeries,
      ...retDropDown,
   };

   // console.log(out);

   const currentSeriesObj = {
      pfRet1y:
         currentS && currentS.pfRet1y
            ? currentS.pfRet1y.map(o => {
                 return o;
              })
            : [],
      pfRet3y:
         currentS && currentS.pfRet1y
            ? currentS.pfRet3y.map(o => {
                 return o;
              })
            : [],
      pfRet5y:
         currentS && currentS.pfRet1y
            ? currentS.pfRet5y.map(o => {
                 return o;
              })
            : [],
   };
   const enhancedSeriesObj = {
      pfRet1y: enhancedS.pfRet1y.map(o => {
         return o;
      }),
      pfRet3y: enhancedS.pfRet3y.map(o => {
         return o;
      }),
      pfRet5y: enhancedS.pfRet5y.map(o => {
         return o;
      }),
   };

   const EnhancedActiveArr1Yr = enhancedSeriesObj.pfRet1y.filter(
      u => u.v !== 0
   );
   const EnhancedActiveArr3Yr = enhancedSeriesObj.pfRet3y.filter(
      u => u.v !== 0
   );
   const EnhancedActiveArr5Yr = enhancedSeriesObj.pfRet5y.filter(
      u => u.v !== 0
   );

   if (EnhancedActiveArr1Yr.length > 0) {
      // console.log('----- pfRet1y -----');
      // console.log('pfRet1y total months count', EnhancedActiveArr1Yr.length);
      if (EnhancedActiveArr1Yr.length < enhancedS.pfRet1y.length) {
         // console.log('lets updated series for 1 year ?', EnhancedActiveArr1Yr.length, enhancedS.pfRet1y);
         const yearCouldBe =
            EnhancedActiveArr1Yr.length === 12
               ? "1 year"
               : `${EnhancedActiveArr1Yr[0].d} - ${
                    EnhancedActiveArr1Yr[EnhancedActiveArr1Yr.length - 1].d
                 }`;
         out.pfRet1y = {
            year: yearCouldBe,
            disabled: false,
            col: 12,
         };

         out.pfRet3y.disabled = true;
         out.pfRet5y.disabled = true;

         const startingIndex = _.findIndex(currentSeriesObj.pfRet1y, [
            "d",
            EnhancedActiveArr1Yr[0].d,
         ]);
         const endingIndex = _.findIndex(currentSeriesObj.pfRet1y, [
            "d",
            EnhancedActiveArr1Yr[EnhancedActiveArr1Yr.length - 1].d,
         ]);
         // console.log('startingIndex 1yr', startingIndex, EnhancedActiveArr1Yr[0].d);
         // console.log('endingIndex 1yr', endingIndex, EnhancedActiveArr1Yr[EnhancedActiveArr1Yr.length - 1].d);

         const ff = currentSeriesObj.pfRet1y.slice(
            startingIndex,
            endingIndex + 1
         );
         // console.log(ff);
         currentSeriesObj.pfRet1y = ff;
         enhancedSeriesObj.pfRet1y = EnhancedActiveArr1Yr;
         // console.log(enhancedSeriesObj);

         out.currentSeries = currentSeriesObj;
         out.enhancedSeries = enhancedSeriesObj;
      }
   }

   if (EnhancedActiveArr3Yr.length > 0) {
      // console.log('----- pfRet3y -----');
      // console.log('pfRet3y total months count', EnhancedActiveArr3Yr.length);
      // console.log('lets update series for 3 years ?', EnhancedActiveArr3Yr.length < enhancedS.pfRet3y.length,  EnhancedActiveArr3Yr.length, enhancedS.pfRet3y.length);
      if (EnhancedActiveArr3Yr.length < enhancedS.pfRet3y.length) {
         // console.log('lets update series for 3 years ?', EnhancedActiveArr3Yr.length, enhancedS.pfRet3y.length);
         const yearCouldBe =
            EnhancedActiveArr3Yr.length === 36
               ? "3 years"
               : `${EnhancedActiveArr3Yr[0].d} - ${
                    EnhancedActiveArr3Yr[EnhancedActiveArr3Yr.length - 1].d
                 }`;
         // console.log(' for 3 years --> yearCouldBe -> ', yearCouldBe);
         if (
            EnhancedActiveArr3Yr.length > 12 &&
            EnhancedActiveArr3Yr.length <= 36
         ) {
            out.pfRet3y = {
               year: yearCouldBe,
               disabled: false,
               col: 12,
            };
         }

         out.pfRet5y.disabled = true;

         const startingIndex = _.findIndex(currentSeriesObj.pfRet3y, [
            "d",
            EnhancedActiveArr3Yr[0].d,
         ]);
         const endingIndex = _.findIndex(currentSeriesObj.pfRet3y, [
            "d",
            EnhancedActiveArr3Yr[EnhancedActiveArr3Yr.length - 1].d,
         ]);

         // console.log('startingIndex 3yr', startingIndex, EnhancedActiveArr3Yr[0].d);
         // console.log('endingIndex 3yr', endingIndex, EnhancedActiveArr3Yr[EnhancedActiveArr3Yr.length - 1].d);

         const ff = currentSeriesObj.pfRet3y.slice(
            startingIndex,
            endingIndex + 1
         );
         // console.log(ff);
         currentSeriesObj.pfRet3y = ff;
         enhancedSeriesObj.pfRet3y = EnhancedActiveArr3Yr;

         out.currentSeries = currentSeriesObj;
         out.enhancedSeries = enhancedSeriesObj;
      }
   }

   if (EnhancedActiveArr5Yr.length > 0) {
      // console.log('----- pfRet5y -----');
      // console.log('pfRet5y total months count', EnhancedActiveArr5Yr.length);
      // console.log('lets update series for 5 years ?', EnhancedActiveArr5Yr.length < enhancedS.pfRet5y.length,  EnhancedActiveArr5Yr.length, enhancedS.pfRet5y.length);
      if (EnhancedActiveArr5Yr.length < enhancedS.pfRet5y.length) {
         // console.log('lets update series for 5 years ?', EnhancedActiveArr5Yr.length, enhancedS.pfRet5y.length);
         const yearCouldBe =
            EnhancedActiveArr5Yr.length === 60
               ? "5 years"
               : `${EnhancedActiveArr5Yr[0].d} - ${
                    EnhancedActiveArr5Yr[EnhancedActiveArr5Yr.length - 1].d
                 }`;
         if (
            EnhancedActiveArr5Yr.length > 36 &&
            EnhancedActiveArr5Yr.length <= 60
         ) {
            // console.log(' for 5 years --> yearCouldBe -> ', yearCouldBe);
            out.pfRet5y = {
               year: yearCouldBe,
               disabled: false,
               col: 12,
            };
         }

         const startingIndex = _.findIndex(currentSeriesObj.pfRet5y, [
            "d",
            EnhancedActiveArr5Yr[0].d,
         ]);
         const endingIndex = _.findIndex(currentSeriesObj.pfRet5y, [
            "d",
            EnhancedActiveArr5Yr[EnhancedActiveArr5Yr.length - 1].d,
         ]);

         // console.log('startingIndex 5yr', startingIndex, EnhancedActiveArr5Yr[0].d);
         // console.log('endingIndex 5yr', endingIndex, EnhancedActiveArr5Yr[EnhancedActiveArr5Yr.length - 1].d);

         const ff = currentSeriesObj.pfRet5y.slice(
            startingIndex,
            endingIndex + 1
         );
         // console.log(ff);
         currentSeriesObj.pfRet5y = ff;
         enhancedSeriesObj.pfRet5y = EnhancedActiveArr5Yr;

         out.currentSeries = currentSeriesObj;
         out.enhancedSeries = enhancedSeriesObj;
      }
   }

   // console.log(out.currentSeries);
   // console.log(currentSeriesObj);
   // console.log(enhancedSeriesObj);

   // NOTE:  current STATS sharpe calc only:
   // let annualizedRet1y = getAnnualizedReturnsByYears(out.currentSeries.pfRet1y);
   // let annualizedRet3y = getAnnualizedReturnsByYears(out.currentSeries.pfRet3y);
   // let annualizedRet5y = getAnnualizedReturnsByYears(out.currentSeries.pfRet5y);

   // let annualizedVolt1y = getAnnualizedVolatilityByYears(
   //    out.currentSeries.pfRet1y
   // );
   // let annualizedVolt3y = getAnnualizedVolatilityByYears(
   //    out.currentSeries.pfRet3y
   // );
   // let annualizedVolt5y = getAnnualizedVolatilityByYears(
   //    out.currentSeries.pfRet5y
   // );

   // console.log(out);
   // debugger
   // let sharpe_ratio_3y = (annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN  ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2);
   // let sharpe_pfRet1y = (annualizedRet1y/annualizedVolt1y).toFixed(2) == NaN  ? 0.00 : (annualizedRet1y/annualizedVolt1y).toFixed(2);
   // let sharpe_pfRet3y = (annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN  ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2);
   // let sharpe_pfRet5y = (annualizedRet5y/annualizedVolt5y).toFixed(2) == NaN  ? 0.00 : (annualizedRet5y/annualizedVolt5y).toFixed(2);
   //
   // out.currentSeries.sharpe_ratio_3y = isFinite(sharpe_ratio_3y) ? sharpe_ratio_3y : 0;
   // out.currentSeries.sharpe_pfRet1y  = isFinite(sharpe_pfRet1y)  ? sharpe_pfRet1y  : 0;
   // out.currentSeries.sharpe_pfRet3y  = isFinite(sharpe_pfRet3y)  ? sharpe_pfRet3y  : 0;
   // out.currentSeries.sharpe_pfRet5y  = isFinite(sharpe_pfRet5y)  ? sharpe_pfRet5y  : 0;

   // console.log(out);

   return out;
};

// models stats calculation (portfolio)
export const getCalculatedModelPortfolioEnhanceStats = (
   currentP,
   query,
   portfolioValue,
   portfolioBenchmark = {}
) => {
   // console.log('getCalculatedModelPortfolioEnhanceStats',currentP)
   const currentPf = JSON.parse(JSON.stringify(currentP));
   // console.log('sid 123', currentPf);

   if (!Array.isArray(currentP)) return;

   // let portfolioSumValue = 0.0,
     let portfolioSumExpenseRatioValue = 0.0,
      portfolioExpenseRatio = 0.0,
      // eslint-disable-next-line no-unused-vars
      weightSum;
   let yieldSum = 0.0,
      outperformSum = 0.0,
      trackRecord = 0.0;
   const currentYear = moment(new Date()).year();

   currentPf.forEach((e, index) => {
      // if (typeof e._pF === 'undefined') return
      const qty = (e._pF.quantity * e._pF._realTimePrice) / e.nav; // get realTimePrice of e

      e._realTimePrice = e.nav; // comment it later

      // if(e._realTimePrice && qty)
      //   portfolioSumValue += e._realTimePrice * qty;
      // if(e._realTimePrice && qty && e.fees)
      //   portfolioSumExpenseRatioValue += e._realTimePrice * qty * e.fees;
      portfolioSumExpenseRatioValue = e.fees;

      //calculate pf yield
      if (e["attribute/fundamental/yield"])
         yieldSum += e["attribute/fundamental/yield"] || e["yield"];
      // yieldSum += e._realTimePrice * qty * e['attribute/fundamental/yield']

      //calculate pf outperform
      if (e["outperform"]) outperformSum += e["outperform"] || 0.0;
      // outperformSum += e._realTimePrice * qty * (e['outperform'] || 0.0)

      //calculate pf trackRecord
      if (e["track_record"])
         //e['attribute/track_record']
         trackRecord += e["track_record"]; // e['attribute/track_record']
      // trackRecord += e._realTimePrice * qty * e['attribute/track_record']

      let returnsArr = e.return ? e.return.returns : e.returns;
      if (returnsArr.length > 0) {
         let start_dt = moment(
            new Date(`01 ${returnsArr[returnsArr.length - 1].d}`)
         );
         let end_dt = moment(new Date(`01 ${returnsArr[0].d}`));
         //check to reverse the returnsArr or not
         if (start_dt.isAfter(end_dt)) {
            returnsArr = returnsArr.reverse();
            start_dt = moment(
               new Date(`01 ${returnsArr[returnsArr.length - 1].d}`)
            );
            end_dt = moment(new Date(`01 ${returnsArr[0].d}`));
         }

         const start = start_dt;
         const end = end_dt;

         const DateRangeMoment = { start, end };

         const calculatePoints = (ret, year) => {
            let returns = JSON.parse(JSON.stringify(ret));
            const range = {
               start: nameToDateRange(year, DateRangeMoment).start._d,
               end: nameToDateRange(year, DateRangeMoment).end._d,
            };
            return returns.filter(e => {
               let dt = monthToDate(e.d);
               return (
                  dt.isAfter(moment(range.start).subtract(1, "seconds")) &&
                  dt.isBefore(moment(range.end).add(1, "seconds"))
               );
            });
         };

         currentPf[index].points1y = calculatePoints(returnsArr, "l1y");
         currentPf[index].points3y = calculatePoints(returnsArr, "l3y");
         currentPf[index].points5y = calculatePoints(returnsArr, "l5y");
         currentPf[index].points10y = calculatePoints(returnsArr, "l10y");
         currentPf[index].pointsHistorical = {};
         currentPf[index].pointsHistorical.points1y = calculatePoints(
            returnsArr,
            "l10y"
         );
         currentPf[index].pointsHistorical.points2y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 1
         );
         currentPf[index].pointsHistorical.points3y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 2
         );
         currentPf[index].pointsHistorical.points4y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 3
         );
         currentPf[index].pointsHistorical.points5y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 4
         );
         currentPf[index].pointsHistorical.points6y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 5
         );
         currentPf[index].pointsHistorical.points7y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 6
         );
         currentPf[index].pointsHistorical.points8y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 7
         );
         currentPf[index].pointsHistorical.points9y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 8
         );
         currentPf[index].pointsHistorical.points10y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 9
         );
         currentPf[index].pfWeight = e._realTimePrice * qty;
         weightSum += currentPf[index].pfWeight;
         currentPf[index].retWeighted1y = [];
         currentPf[index].retWeighted3y = [];
         currentPf[index].retWeighted5y = [];
         currentPf[index].retWeighted10y = [];
         currentPf[index].retWeightedHistorical = {
            retWeighted1y: [],
            retWeighted2y: [],
            retWeighted3y: [],
            retWeighted4y: [],
            retWeighted5y: [],
            retWeighted6y: [],
            retWeighted7y: [],
            retWeighted8y: [],
            retWeighted9y: [],
            retWeighted10y: [],
         };

         e.points1y.forEach((el, index1) => {
            el.v = el.v * 100; //multiplied by 100 to negate the divide by 100 for charts
            currentPf[index].retWeighted1y.push(el);
         });

         e.points3y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeighted3y.push(el);
         });

         e.points5y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeighted5y.push(el);
         });

         e.points10y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeighted10y.push(el);
         });

         e.pointsHistorical.points1y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted1y.push(el);
         });

         e.pointsHistorical.points2y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted2y.push(el);
         });

         e.pointsHistorical.points3y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted3y.push(el);
         });

         e.pointsHistorical.points4y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted4y.push(el);
         });

         e.pointsHistorical.points5y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted5y.push(el);
         });

         e.pointsHistorical.points6y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted6y.push(el);
         });

         e.pointsHistorical.points7y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted7y.push(el);
         });

         e.pointsHistorical.points8y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted8y.push(el);
         });

         e.pointsHistorical.points9y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted9y.push(el);
         });

         e.pointsHistorical.points10y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted10y.push(el);
         });
      }
   });
   // end for each portfolio

   // start pf ret calc
   let pfRet1y = getMonthFromYearDiff(1);
   let pfRet3y = getMonthFromYearDiff(3);
   let pfRet5y = getMonthFromYearDiff(5);
   let pfRet10y = getMonthFromYearDiff(10);
   let historical = {};
   historical.pfRet1y = getMonthFromYearDiff(1);
   historical.pfRet2y = getMonthFromYearDiff(1, currentYear - 1);
   historical.pfRet3y = getMonthFromYearDiff(1, currentYear - 2);
   historical.pfRet4y = getMonthFromYearDiff(1, currentYear - 3);
   historical.pfRet5y = getMonthFromYearDiff(1, currentYear - 4);
   historical.pfRet6y = getMonthFromYearDiff(1, currentYear - 5);
   historical.pfRet7y = getMonthFromYearDiff(1, currentYear - 6);
   historical.pfRet8y = getMonthFromYearDiff(1, currentYear - 7);
   historical.pfRet9y = getMonthFromYearDiff(1, currentYear - 8);
   historical.pfRet10y = getMonthFromYearDiff(1, currentYear - 9);

   // let outd1 = { n: 0, dMax: 0.0 },
      let cuml1 = 0.0;
   pfRet1y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.points1y &&
            e.points1y.forEach((el1, index11) => {
               if (pfRet1y[index1].d === el1.d) {
                  pfRet1y[index1].v += el1.v;
               }
            });
      });
      // pfRet1y[index1].v = pfRet1y[index1].v;
      //calculate drawdown
      let r = pfRet1y[index1].v / 100;
      cuml1 = (1 + cuml1) * (1 + r) - 1;
   });

   let outd = { n: 0, dMax: 0.0 },
      cuml = 0.0;
   pfRet3y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.points3y &&
            e.points3y.forEach((el1, index11) => {
               if (pfRet3y[index1].d === el1.d) {
                  pfRet3y[index1].v += el1.v;
               }
            });
      });
      // pfRet3y[index1].v = pfRet3y[index1].v;
      //calculate drawdown
      let r = pfRet3y[index1].v / 100;
      cuml = (1 + cuml) * (1 + r) - 1;
      outd.n += 1;
      if (outd.n === 1) {
         outd.cumlMax = cuml;
      } else {
         cuml > outd.cumlMax && (outd.cumlMax = cuml);
         let dmax = (outd.cumlMax - cuml) / (1 + outd.cumlMax);
         dmax > outd.dMax && (outd.dMax = dmax);
      }
   });

   // let outd5 = { n: 0, dMax: 0.0 },
      let cuml5 = 0.0;
   pfRet5y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.points5y &&
            e.points5y.forEach((el1, index11) => {
               if (pfRet5y[index1].d === el1.d) {
                  pfRet5y[index1].v += el1.v;
               }
            });
      });
      // pfRet5y[index1].v = pfRet5y[index1].v;
      //calculate drawdown
      let r = pfRet5y[index1].v / 100;
      cuml5 = (1 + cuml5) * (1 + r) - 1;
   });

   pfRet10y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.points10y &&
            e.points10y.forEach((el1, index11) => {
               if (pfRet10y[index1].d === el1.d) {
                  pfRet10y[index1].v += el1.v;
               }
            });
      });
      // pfRet10y[index1].v = pfRet10y[index1].v;
   });

   historical.pfRet1y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points1y &&
            e.pointsHistorical.points1y.forEach((el1, index11) => {
               if (historical.pfRet1y[index1].d === el1.d) {
                  historical.pfRet1y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet1y[index1].v = historical.pfRet1y[index1].v;
   });

   historical.pfRet2y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points2y &&
            e.pointsHistorical.points2y.forEach((el1, index11) => {
               if (historical.pfRet2y[index1].d === el1.d) {
                  historical.pfRet2y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet2y[index1].v = historical.pfRet2y[index1].v;
   });

   historical.pfRet3y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points3y &&
            e.pointsHistorical.points3y.forEach((el1, index11) => {
               if (historical.pfRet3y[index1].d === el1.d) {
                  historical.pfRet3y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet3y[index1].v = historical.pfRet3y[index1].v;
   });

   historical.pfRet4y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points4y &&
            e.pointsHistorical.points4y.forEach((el1, index11) => {
               if (historical.pfRet4y[index1].d === el1.d) {
                  historical.pfRet4y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet4y[index1].v = historical.pfRet4y[index1].v;
   });

   historical.pfRet5y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points5y &&
            e.pointsHistorical.points5y.forEach((el1, index11) => {
               if (historical.pfRet5y[index1].d === el1.d) {
                  historical.pfRet5y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet5y[index1].v = historical.pfRet5y[index1].v;
   });

   historical.pfRet6y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points6y &&
            e.pointsHistorical.points6y.forEach((el1, index11) => {
               if (historical.pfRet6y[index1].d === el1.d) {
                  historical.pfRet6y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet6y[index1].v = historical.pfRet6y[index1].v;
   });

   historical.pfRet7y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points7y &&
            e.pointsHistorical.points7y.forEach((el1, index11) => {
               if (historical.pfRet7y[index1].d === el1.d) {
                  historical.pfRet7y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet7y[index1].v = historical.pfRet7y[index1].v;
   });

   historical.pfRet8y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points8y &&
            e.pointsHistorical.points8y.forEach((el1, index11) => {
               if (historical.pfRet8y[index1].d === el1.d) {
                  historical.pfRet8y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet8y[index1].v = historical.pfRet8y[index1].v;
   });

   historical.pfRet9y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points9y &&
            e.pointsHistorical.points9y.forEach((el1, index11) => {
               if (historical.pfRet9y[index1].d === el1.d) {
                  historical.pfRet9y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet9y[index1].v = historical.pfRet9y[index1].v;
   });

   historical.pfRet10y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points10y &&
            e.pointsHistorical.points10y.forEach((el1, index11) => {
               if (historical.pfRet10y[index1].d === el1.d) {
                  historical.pfRet10y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet10y[index1].v = historical.pfRet10y[index1].v;
   });

   portfolioExpenseRatio = portfolioSumExpenseRatioValue;
   // let annualizedRet1y = getAnnualizedReturnsByYears(pfRet1y);
   // let annualizedRet3y = getAnnualizedReturnsByYears(pfRet3y);
   // let annualizedRet5y = getAnnualizedReturnsByYears(pfRet5y);
   let annualizedRet10y = getAnnualizedReturnsByYears(pfRet10y);

   // let annualizedVolt1y = getAnnualizedVolatilityByYears(pfRet1y);
   // let annualizedVolt3y = getAnnualizedVolatilityByYears(pfRet3y);
   // let annualizedVolt5y = getAnnualizedVolatilityByYears(pfRet5y);
   let annualizedVolt10y = getAnnualizedVolatilityByYears(pfRet10y);

   let annualizedRetHistorical = [];
   annualizedRetHistorical.push({
      key: "annualizedRet1y",
      value: getAnnualizedReturnsByYears(historical.pfRet1y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet2y",
      value: getAnnualizedReturnsByYears(historical.pfRet2y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet3y",
      value: getAnnualizedReturnsByYears(historical.pfRet3y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet4y",
      value: getAnnualizedReturnsByYears(historical.pfRet4y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet5y",
      value: getAnnualizedReturnsByYears(historical.pfRet5y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet6y",
      value: getAnnualizedReturnsByYears(historical.pfRet6y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet7y",
      value: getAnnualizedReturnsByYears(historical.pfRet7y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet8y",
      value: getAnnualizedReturnsByYears(historical.pfRet8y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet9y",
      value: getAnnualizedReturnsByYears(historical.pfRet9y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet10y",
      value: getAnnualizedReturnsByYears(historical.pfRet10y),
   });

   let annualizedVolHistorical = [
      getAnnualizedVolatilityByYears(historical.pfRet1y),
      getAnnualizedVolatilityByYears(historical.pfRet2y),
      getAnnualizedVolatilityByYears(historical.pfRet3y),
      getAnnualizedVolatilityByYears(historical.pfRet4y),
      getAnnualizedVolatilityByYears(historical.pfRet5y),
      getAnnualizedVolatilityByYears(historical.pfRet6y),
      getAnnualizedVolatilityByYears(historical.pfRet7y),
      getAnnualizedVolatilityByYears(historical.pfRet8y),
      getAnnualizedVolatilityByYears(historical.pfRet9y),
      getAnnualizedVolatilityByYears(historical.pfRet10y),
   ];

   let avgHis = 0.0,
      sumAvgHis = 0.0,
      nHis = 0.0,
      stdArr = [];
   annualizedRetHistorical.forEach((item, index) => {
      nHis += 1;
      item.value =
         query === "similar" ? item.value - portfolioExpenseRatio : item.value;
      stdArr.push(item.value);
      sumAvgHis += item.value;
   });
   if (nHis > 0) avgHis = sumAvgHis / nHis;
   let stdev = std(stdArr);
   let stdevVol = std(annualizedVolHistorical);

   //PF assetAllocation
   let assetPFFunds = 0,
      equitiesSum = 0.0,
      bondsSum = 0.0,
      commoditiesSum = 0.0,
      currenciesSum = 0.0,
      derivativesSum = 0.0,
      cashSum = 0.0,
      otherSum = 0.0;
   currentPf.forEach(e => {
      if (e.asset_alloc) {
         assetPFFunds += 1;
         if (e.asset_alloc.equities) equitiesSum += e.asset_alloc.equities;
         if (e.asset_alloc.bonds) bondsSum += e.asset_alloc.bonds;
         if (e.asset_alloc.commodities)
            commoditiesSum += e.asset_alloc.commodities;
         if (e.asset_alloc.currencies)
            currenciesSum += e.asset_alloc.currencies;
         if (e.asset_alloc.derivatives)
            derivativesSum += e.asset_alloc.derivatives;
         if (e.asset_alloc.cash) cashSum += e.asset_alloc.cash;
         if (e.asset_alloc.other) otherSum += e.asset_alloc.other;
      }
   });
   const asset_alloc = {
      equities: assetPFFunds ? equitiesSum / assetPFFunds : 0,
      bonds: assetPFFunds ? bondsSum / assetPFFunds : 0,
      commodities: assetPFFunds ? commoditiesSum / assetPFFunds : 0,
      currencies: assetPFFunds ? currenciesSum / assetPFFunds : 0,
      derivatives: assetPFFunds ? derivativesSum / assetPFFunds : 0,
      cash: assetPFFunds ? cashSum / assetPFFunds : 0,
      other: assetPFFunds ? otherSum / assetPFFunds : 0,
   };
   //calculate up capture and down capture
   //getGeoMean of portfolioBenchmark and potfolio
   //
   let up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr;
   const __ret = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y);
   if (__ret) {
      up_1yr = __ret.up_1yr;
      down_1yr = __ret.down_1yr;
      up_3yr = __ret.up_3yr;
      down_3yr = __ret.down_3yr;
      up_5yr = __ret.up_5yr;
      down_5yr = __ret.down_5yr;
   }
   // debugger
   // let sharpe_ratio_3y = parseFloat((annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2));
   // let sharpe_pfRet1y  = parseFloat((annualizedRet1y/annualizedVolt1y).toFixed(2) == NaN ? 0.00 : (annualizedRet1y/annualizedVolt1y).toFixed(2));
   // let sharpe_pfRet3y  = parseFloat((annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2));
   // let sharpe_pfRet5y  = parseFloat((annualizedRet5y/annualizedVolt5y).toFixed(2) == NaN ? 0.00 : (annualizedRet5y/annualizedVolt5y).toFixed(2));

   let modelPortFolioObj = currentP.length > 0 ? currentP[0] : null;
   let showAsteriskFor = [];

   let getValueForKey = (key, year, multiplier) => {
      if (key && year && modelPortFolioObj && multiplier) {
         if (
            typeof modelPortFolioObj[`${key}_${year}`] === "undefined" ||
            modelPortFolioObj[`${key}_${year}`] === null
         ) {
            if (year !== "10y") {
               showAsteriskFor = [...showAsteriskFor, { [`${key}`]: year }];
            }
            if (
               typeof modelPortFolioObj[`${key}_${year}_ui`] === "undefined" ||
               modelPortFolioObj[`${key}_${year}_ui`] === null
            ) {
               return null;
            }
            // console.log(`${key}_${year}_ui`, modelPortFolioObj[`${key}_${year}_ui`]);
            return modelPortFolioObj[`${key}_${year}_ui`];
         } else {
            // console.log(`${key}_${year}`, modelPortFolioObj[`${key}_${year}`]);
            return modelPortFolioObj[`${key}_${year}`] * multiplier;
         }
      } else {
         return null;
      }
   };

   let out = {
      pfRet1y,
      pfRet3y,
      pfRet5y,
      pfRet10y,
      averageCostAdjRet10Yr: avgHis,
      stdev: stdev.toFixed(2),
      stdevVol: stdevVol.toFixed(2),
      asset_alloc: asset_alloc,
      fee:
         portfolioSumExpenseRatioValue != null || portfolioValue != null
            ? parseFloat(
                 (
                    (portfolioValue * portfolioSumExpenseRatioValue) /
                    100
                 ).toFixed(2)
              )
            : "--",
      expenseRatio:
         portfolioExpenseRatio != null
            ? parseFloat(portfolioExpenseRatio.toFixed(2))
            : 0, // discuss with pratik for temporary solution ==> annual fees: 0 but expense ratio is --

      anRet1yr:
         getValueForKey("returns", "1y", 100) !== null
            ? numberCheck(getValueForKey("returns", "1y", 100), 2)
            : "--", // parseFloat(getAnnualizedReturnsByYears(pfRet1y).toFixed(2)),
      anRet3yr:
         getValueForKey("returns", "3y", 100) !== null
            ? numberCheck(getValueForKey("returns", "3y", 100), 2)
            : "--", // parseFloat(annualizedRet3y.toFixed(2)),
      anRet5yr:
         getValueForKey("returns", "5y", 100) !== null
            ? numberCheck(getValueForKey("returns", "5y", 100), 2)
            : "--", // parseFloat(getAnnualizedReturnsByYears(pfRet5y).toFixed(2)),
      anRet10yr:
         getValueForKey("returns", "10y", 100) !== null
            ? numberCheck(getValueForKey("returns", "10y", 100), 2)
            : "--", // parseFloat(annualizedRet10y.toFixed(2)),

      anRet1yr_report:
         getValueForKey("returns", "1y", 100) !== null
            ? numberCheck(getValueForKey("returns", "1y", 100), "all")
            : "--",
      anRet3yr_report:
         getValueForKey("returns", "3y", 100) !== null
            ? numberCheck(getValueForKey("returns", "3y", 100), "all")
            : "--",
      anRet5yr_report:
         getValueForKey("returns", "5y", 100) !== null
            ? numberCheck(getValueForKey("returns", "5y", 100), "all")
            : "--",
      anRet10yr_report:
         getValueForKey("returns", "10y", 100) !== null
            ? numberCheck(getValueForKey("returns", "10y", 100), "all")
            : "--",

      anVol1yr:
         getValueForKey("volatility", "1y", 100) !== null
            ? numberCheck(getValueForKey("volatility", "1y", 100), 2)
            : "--", // parseFloat(getAnnualizedVolatilityByYears(pfRet1y).toFixed(2)),
      anVol3yr:
         getValueForKey("volatility", "3y", 100) !== null
            ? numberCheck(getValueForKey("volatility", "3y", 100), 2)
            : "--", // parseFloat(annualizedVolt3y.toFixed(2)),
      anVol5yr:
         getValueForKey("volatility", "5y", 100) !== null
            ? numberCheck(getValueForKey("volatility", "5y", 100), 2)
            : "--", // parseFloat(getAnnualizedVolatilityByYears(pfRet5y).toFixed(2)),
      anVol10yr:
         getValueForKey("volatility", "10y", 100) !== null
            ? numberCheck(getValueForKey("volatility", "10y", 100), 2)
            : "--", // parseFloat(annualizedVolt10y.toFixed(2)),

      dMax:
         getValueForKey("drawdown", "3y", 100) !== null
            ? numberCheck(getValueForKey("drawdown", "3y", 100), 2)
            : "--", // parseFloat((outd.dMax*100).toFixed(2)),
      dMax_pfRet1y:
         getValueForKey("drawdown", "1y", 100) !== null
            ? numberCheck(getValueForKey("drawdown", "1y", 100), 2)
            : "--",
      dMax_pfRet3y:
         getValueForKey("drawdown", "3y", 100) !== null
            ? numberCheck(getValueForKey("drawdown", "3y", 100), 2)
            : "--",
      dMax_pfRet5y:
         getValueForKey("drawdown", "5y", 100) !== null
            ? numberCheck(getValueForKey("drawdown", "5y", 100), 2)
            : "--",

      sharpe_ratio_3y:
         getValueForKey("sharpe", "3y", 1) !== null
            ? numberCheck(getValueForKey("sharpe", "3y", 1), 2)
            : "--", // isFinite(sharpe_ratio_3y) ? sharpe_ratio_3y : 0,
      sharpe_pfRet1y:
         getValueForKey("sharpe", "1y", 1) !== null
            ? numberCheck(getValueForKey("sharpe", "1y", 1), 2)
            : "--", // isFinite(sharpe_pfRet1y)  ? sharpe_pfRet1y  : 0,
      sharpe_pfRet3y:
         getValueForKey("sharpe", "3y", 1) !== null
            ? numberCheck(getValueForKey("sharpe", "3y", 1), 2)
            : "--", // isFinite(sharpe_pfRet3y)  ? sharpe_pfRet3y  : 0,
      sharpe_pfRet5y:
         getValueForKey("sharpe", "5y", 1) !== null
            ? numberCheck(getValueForKey("sharpe", "5y", 1), 2)
            : "--", // isFinite(sharpe_pfRet5y)  ? sharpe_pfRet5y  : 0,

      cuml_pfRet1y:
         getValueForKey("cumulative_returns", "1y", 100) !== null
            ? numberCheck(getValueForKey("cumulative_returns", "1y", 100), 2)
            : "--", // cuml1*100,
      cuml_pfRet3y:
         getValueForKey("cumulative_returns", "3y", 100) !== null
            ? numberCheck(getValueForKey("cumulative_returns", "3y", 100), 2)
            : "--", // cuml*100,
      cuml_pfRet5y:
         getValueForKey("cumulative_returns", "5y", 100) !== null
            ? numberCheck(getValueForKey("cumulative_returns", "5y", 100), 2)
            : "--", // cuml5*100,

      minus_1_sd_10yr: parseFloat(annualizedRet10y + -1 * annualizedVolt10y),
      plus_1_sd_10yr: parseFloat(annualizedRet10y + annualizedVolt10y * 1),

      yield: parseFloat((yieldSum * 100).toFixed(2)),
      outperform: parseFloat((outperformSum * 100).toFixed(2)),
      trackRecord: parseFloat(trackRecord.toFixed(2)),

      up_1yr,
      down_1yr,
      up_3yr,
      down_3yr,
      up_5yr,
      down_5yr,
      cuml1,
      cuml3: cuml,
      cuml5,
      showAsteriskFor,
   };

   return out;
};

export const geopMeanCalc = (
   portfolioBenchmark,
   pfRet1y,
   pfRet3y,
   pfRet5y,
   pfRet10y
) => {
   if (portfolioBenchmark) {
      // console.log(pfRet1y)
      let pfb = portfolioBenchmark
         ? JSON.parse(JSON.stringify(portfolioBenchmark))
         : [];
      let pf1 = pfRet1y ? JSON.parse(JSON.stringify(pfRet1y)) : [];
      let pf3 = pfRet3y ? JSON.parse(JSON.stringify(pfRet3y)) : [];
      let pf5 = pfRet5y ? JSON.parse(JSON.stringify(pfRet5y)) : [];
      let pf10 = pfRet10y ? JSON.parse(JSON.stringify(pfRet10y)) : [];
      pfb.forEach((item, index) => {
         pfb[index].v = item.v / 100;
      });
      pf1.forEach((item, index) => {
         pf1[index].v = item.v / 100;
      });
      pf3.forEach((item, index) => {
         pf3[index].v = item.v / 100;
      });
      pf5.forEach((item, index) => {
         pf5[index].v = item.v / 100;
      });
      pf10.forEach((item, index) => {
         pf10[index].v = item.v / 100;
      });
      // console.log('geopMeanCalc')
      let up_1yr,
         down_1yr,
         up_3yr,
         down_3yr,
         up_5yr,
         down_5yr,
         up_10yr,
         down_10yr;

      const start = moment(
         new Date(`01 ${portfolioBenchmark[portfolioBenchmark.length - 1].d}`)
      );
      const end = moment(new Date(`01 ${portfolioBenchmark[0].d}`));
      const DateRangeMoment = { start, end };

      const calculatePoints = (ret, year, startFrom) => {
         let returns = JSON.parse(JSON.stringify(ret));
         const range = {
            start: nameToDateRange(year, DateRangeMoment, startFrom).start._d,
            end: nameToDateRange(year, DateRangeMoment, startFrom).end._d,
         };
         // console.log(range);
         return returns.filter(e => {
            let dt = monthToDate(e.d);
            return (
               dt.isAfter(moment(range.start).subtract(1, "seconds")) &&
               dt.isBefore(moment(range.end).add(1, "seconds"))
            );
         });
      };

      let benchPoints1y = calculatePoints(pfb, "l1y");
      let benchPoints3y = calculatePoints(pfb, "l3y");
      let benchPoints5y = calculatePoints(pfb, "l5y");
      let benchPoints10y = calculatePoints(pfb, "l10y");
      const res1 = getGeoMean(benchPoints1y, pf1);
      const res3 = getGeoMean(benchPoints3y, pf3);
      const res5 = getGeoMean(benchPoints5y, pf5);
      const res10 =
         benchPoints10y && pf10 ? getGeoMean(benchPoints10y, pf10) : res5;

      const geomean_bench_1y = res1.bench;
      const geomean_bench_3y = res3.bench;
      const geomean_bench_5y = res5.bench;
      const geomean_bench_10y = res10 && res10.bench ? res10.bench : res5.bench;

      const geomean_port_1y = res1.ret;
      const geomean_port_3y = res3.ret;
      const geomean_port_5y = res5.ret;
      const geomean_port_10y = res10 && res10.ret ? res10.ret : res5.ret;

      up_1yr = ((geomean_port_1y.up - 1) / (geomean_bench_1y.up - 1)) * 100;
      down_1yr =
         ((geomean_port_1y.down - 1) / (geomean_bench_1y.down - 1)) * 100;
      up_3yr = ((geomean_port_3y.up - 1) / (geomean_bench_3y.up - 1)) * 100;
      down_3yr =
         ((geomean_port_3y.down - 1) / (geomean_bench_3y.down - 1)) * 100;
      up_5yr = ((geomean_port_5y.up - 1) / (geomean_bench_5y.up - 1)) * 100;
      down_5yr =
         ((geomean_port_5y.down - 1) / (geomean_bench_5y.down - 1)) * 100;
      up_10yr = ((geomean_port_10y.up - 1) / (geomean_bench_10y.up - 1)) * 100;
      down_10yr =
         ((geomean_port_10y.down - 1) / (geomean_bench_10y.down - 1)) * 100;

      up_1yr = up_1yr ? Math.round(up_1yr) : null;
      down_1yr = down_1yr ? Math.round(down_1yr * -1) : null;
      up_3yr = up_3yr ? Math.round(up_3yr) : null;
      down_3yr = down_3yr ? Math.round(down_3yr * -1) : null;
      up_5yr = up_5yr ? Math.round(up_5yr) : null;
      down_5yr = down_5yr ? Math.round(down_5yr * -1) : null;
      up_10yr = up_10yr ? Math.round(up_10yr) : null;
      down_10yr = down_10yr ? Math.round(down_10yr * -1) : null;
      // console.log(up_1yr)
      return {
         up_1yr,
         down_1yr,
         up_3yr,
         down_3yr,
         up_5yr,
         down_5yr,
         up_10yr,
         down_10yr,
      };
   } else {
      console.log("error");
      return null;
   }
};

// funds stats calculation (holdings)
export const getCalculatedEnhanceStats = (
   currentP,
   query,
   portfolioBenchmark = {},
   invalidTickerFunds = [],
   tickerNotSupported = []
) => {
   // console.log(portfolioBenchmark)
   const currentPf = JSON.parse(JSON.stringify(currentP));
   let portfolioSumValue = 0.0,
      // feeSum = 0.0,
      portfolioSumExpenseRatioValue = 0.0,
      portfolioExpenseRatio = 0.0,
      weightSum = 0.0;
   let yieldSum = 0.0,
      outperformSum = 0.0,
      trackRecord = 0.0;
   const currentYear = moment(new Date()).year();

   // start pf ret calc
   let pfRet1y = getMonthFromYearDiff(1);
   let pfRet3y = getMonthFromYearDiff(3);
   let pfRet5y = getMonthFromYearDiff(5);
   let pfRet10y = getMonthFromYearDiff(10);

   let historical = {};
   historical.pfRet1y = getMonthFromYearDiff(1);
   historical.pfRet2y = getMonthFromYearDiff(1, currentYear - 1);
   historical.pfRet3y = getMonthFromYearDiff(1, currentYear - 2);
   historical.pfRet4y = getMonthFromYearDiff(1, currentYear - 3);
   historical.pfRet5y = getMonthFromYearDiff(1, currentYear - 4);
   historical.pfRet6y = getMonthFromYearDiff(1, currentYear - 5);
   historical.pfRet7y = getMonthFromYearDiff(1, currentYear - 6);
   historical.pfRet8y = getMonthFromYearDiff(1, currentYear - 7);
   historical.pfRet9y = getMonthFromYearDiff(1, currentYear - 8);
   historical.pfRet10y = getMonthFromYearDiff(1, currentYear - 9);

   currentPf.forEach((e, index) => {
      // if (typeof e._pF === 'undefined') return
      const qty = (e._pF.quantity * e._pF._realTimePrice) / e.nav; // get realTimePrice of e

      e._realTimePrice = e.nav; // comment it later

      if (e._realTimePrice && qty) portfolioSumValue += e._realTimePrice * qty;
      if (e._realTimePrice && qty && e.fees)
         portfolioSumExpenseRatioValue += e._realTimePrice * qty * e.fees;

      //calculate pf yield
      if (e["attribute/fundamental/yield"])
         yieldSum += e._realTimePrice * qty * e["attribute/fundamental/yield"];

      //calculate pf outperform
      if (e["outperform"])
         outperformSum += e._realTimePrice * qty * (e["outperform"] || 0.0);

      //calculate pf trackRecord
      if (e["attribute/track_record"])
         trackRecord += e._realTimePrice * qty * e["attribute/track_record"];

      let returnsArr = e.return ? e.return.returns : e.returns;

      if (returnsArr.length > 0) {
         let start_dt = moment(
            new Date(`01 ${returnsArr[returnsArr.length - 1].d}`)
         );
         let end_dt = moment(new Date(`01 ${returnsArr[0].d}`));

         //check to reverse the returnsArr or not
         if (start_dt.isAfter(end_dt)) {
            returnsArr = returnsArr.reverse();
            start_dt = moment(
               new Date(`01 ${returnsArr[returnsArr.length - 1].d}`)
            );
            end_dt = moment(new Date(`01 ${returnsArr[0].d}`));
         }
         // console.log(returnsArr);
         const start = start_dt;
         const end = end_dt;

         const DateRangeMoment = { start, end };

         const calculatePoints = (ret, year) => {
            let returns = JSON.parse(JSON.stringify(ret));
            const range = {
               start: nameToDateRange(year, DateRangeMoment).start._d,
               end: nameToDateRange(year, DateRangeMoment).end._d,
            };
            return returns.filter(e => {
               let dt = monthToDate(e.d);
               return (
                  dt.isAfter(moment(range.start).subtract(1, "seconds")) &&
                  dt.isBefore(moment(range.end).add(1, "seconds"))
               );
            });
         };

         currentPf[index].points1y = calculatePoints(returnsArr, "l1y");
         currentPf[index].points3y = calculatePoints(returnsArr, "l3y");
         currentPf[index].points5y = calculatePoints(returnsArr, "l5y");
         currentPf[index].points10y = calculatePoints(returnsArr, "l10y");
         currentPf[index].pointsHistorical = {};
         currentPf[index].pointsHistorical.points1y = calculatePoints(
            e.return.returns,
            "l10y"
         );
         currentPf[index].pointsHistorical.points2y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 1
         );
         currentPf[index].pointsHistorical.points3y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 2
         );
         currentPf[index].pointsHistorical.points4y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 3
         );
         currentPf[index].pointsHistorical.points5y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 4
         );
         currentPf[index].pointsHistorical.points6y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 5
         );
         currentPf[index].pointsHistorical.points7y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 6
         );
         currentPf[index].pointsHistorical.points8y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 7
         );
         currentPf[index].pointsHistorical.points9y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 8
         );
         currentPf[index].pointsHistorical.points10y = calculatePoints(
            e.return.returns,
            "l10y",
            currentYear - 9
         );
         currentPf[index].pfWeight = e.weight;
         weightSum += currentPf[index].pfWeight;
         currentPf[index].retWeighted1y = [];
         currentPf[index].retWeighted3y = [];
         currentPf[index].retWeighted5y = [];
         currentPf[index].retWeighted10y = [];
         currentPf[index].retWeightedHistorical = {
            retWeighted1y: [],
            retWeighted2y: [],
            retWeighted3y: [],
            retWeighted4y: [],
            retWeighted5y: [],
            retWeighted6y: [],
            retWeighted7y: [],
            retWeighted8y: [],
            retWeighted9y: [],
            retWeighted10y: [],
         };
         //_.differenceBy(pfRet1y, currentPf[index].points1y, 'd');
         currentPf[index].points1y_diff = _.differenceBy(
            pfRet1y,
            currentPf[index].points1y,
            "d"
         );
         currentPf[index].points3y_diff = _.differenceBy(
            pfRet3y,
            currentPf[index].points3y,
            "d"
         );
         currentPf[index].points5y_diff = _.differenceBy(
            pfRet5y,
            currentPf[index].points5y,
            "d"
         );
         currentPf[index].points10y_diff = _.differenceBy(
            pfRet10y,
            currentPf[index].points10y,
            "d"
         );
         currentPf[index].pointsHistorical_diff = {};
         currentPf[index].pointsHistorical_diff.points1y_diff = _.differenceBy(
            historical.pfRet1y,
            currentPf[index].pointsHistorical.points1y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points2y_diff = _.differenceBy(
            historical.pfRet2y,
            currentPf[index].pointsHistorical.points2y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points3y_diff = _.differenceBy(
            historical.pfRet3y,
            currentPf[index].pointsHistorical.points3y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points4y_diff = _.differenceBy(
            historical.pfRet4y,
            currentPf[index].pointsHistorical.points4y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points5y_diff = _.differenceBy(
            historical.pfRet5y,
            currentPf[index].pointsHistorical.points5y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points6y_diff = _.differenceBy(
            historical.pfRet6y,
            currentPf[index].pointsHistorical.points6y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points7y_diff = _.differenceBy(
            historical.pfRet7y,
            currentPf[index].pointsHistorical.points7y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points8y_diff = _.differenceBy(
            historical.pfRet8y,
            currentPf[index].pointsHistorical.points8y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points9y_diff = _.differenceBy(
            historical.pfRet9y,
            currentPf[index].pointsHistorical.points9y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points10y_diff = _.differenceBy(
            historical.pfRet10y,
            currentPf[index].pointsHistorical.points10y,
            "d"
         );

         e.points1y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100; //multiplied by 100 to negate the divide by 100 for charts
            currentPf[index].retWeighted1y.push(el);
         });

         e.points3y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeighted3y.push(el);
         });

         e.points5y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeighted5y.push(el);
         });

         e.points10y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeighted10y.push(el);
         });

         e.pointsHistorical.points1y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted1y.push(el);
         });

         e.pointsHistorical.points2y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted2y.push(el);
         });

         e.pointsHistorical.points3y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted3y.push(el);
         });

         e.pointsHistorical.points4y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted4y.push(el);
         });

         e.pointsHistorical.points5y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted5y.push(el);
         });

         e.pointsHistorical.points6y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted6y.push(el);
         });

         e.pointsHistorical.points7y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted7y.push(el);
         });

         e.pointsHistorical.points8y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted8y.push(el);
         });

         e.pointsHistorical.points9y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted9y.push(el);
         });

         e.pointsHistorical.points10y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted10y.push(el);
         });
      }
   });
   // end for each portfolio

   // let outd1 = { n: 0, dMax: 0.0 },
      let cuml1 = 0.0;
   pfRet1y.forEach((el, index1) => {
      pfRet1y[index1].diff = 0;
      pfRet1y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.points1y.forEach((el1, index11) => {
            if (pfRet1y[index1].d === el1.d) {
               pfRet1y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].points1y_diff, [
                  "d",
                  pfRet1y[index1].d,
               ]) &&
               pfRet1y[index1].diff_ticker.indexOf(currentPf[index2].ticker) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet1y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               pfRet1y[index1].diff += currentPf[index2].pfWeight;
               pfRet1y[index1].diff_ticker.push(currentPf[index2].ticker);
            }
         });
      });
      pfRet1y[index1].v =
         pfRet1y[index1].v / (weightSum - pfRet1y[index1].diff);
      //calculate drawdown
      let r = pfRet1y[index1].v / 100;
      cuml1 = (1 + cuml1) * (1 + r) - 1;
      delete pfRet1y[index1].diff;
      delete pfRet1y[index1].diff_ticker;
   });

   let outd = { n: 0, dMax: 0.0 },
      cuml = 0.0;
   pfRet3y.forEach((el, index1) => {
      pfRet3y[index1].diff = 0;
      pfRet3y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.points3y.forEach((el1, index11) => {
            if (pfRet3y[index1].d === el1.d) {
               pfRet3y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].points3y_diff, [
                  "d",
                  pfRet3y[index1].d,
               ]) &&
               pfRet3y[index1].diff_ticker.indexOf(currentPf[index2].ticker) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet1y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               pfRet3y[index1].diff += currentPf[index2].pfWeight;
               pfRet3y[index1].diff_ticker.push(currentPf[index2].ticker);
            }
         });
      });
      pfRet3y[index1].v =
         pfRet3y[index1].v / (weightSum - pfRet3y[index1].diff);
      //calculate drawdown
      let r = pfRet3y[index1].v / 100;
      cuml = (1 + cuml) * (1 + r) - 1;
      outd.n += 1;
      if (outd.n === 1) {
         outd.cumlMax = cuml;
      } else {
         cuml > outd.cumlMax && (outd.cumlMax = cuml);
         let dmax = (outd.cumlMax - cuml) / (1 + outd.cumlMax);
         dmax > outd.dMax && (outd.dMax = dmax);
      }
      delete pfRet3y[index1].diff;
      delete pfRet3y[index1].diff_ticker;
   });

   // let outd5 = { n: 0, dMax: 0.0 },
     let cuml5 = 0.0;
   pfRet5y.forEach((el, index1) => {
      pfRet5y[index1].diff = 0;
      pfRet5y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.points5y.forEach((el1, index11) => {
            if (pfRet5y[index1].d === el1.d) {
               pfRet5y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].points5y_diff, [
                  "d",
                  pfRet5y[index1].d,
               ]) &&
               pfRet5y[index1].diff_ticker.indexOf(currentPf[index2].ticker) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet5y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               pfRet5y[index1].diff += currentPf[index2].pfWeight;
               pfRet5y[index1].diff_ticker.push(currentPf[index2].ticker);
            }
         });
      });
      pfRet5y[index1].v =
         pfRet5y[index1].v / (weightSum - pfRet5y[index1].diff);
      //calculate drawdown
      let r = pfRet5y[index1].v / 100;
      cuml5 = (1 + cuml5) * (1 + r) - 1;
      delete pfRet5y[index1].diff;
      delete pfRet5y[index1].diff_ticker;
   });

   pfRet10y.forEach((el, index1) => {
      pfRet10y[index1].diff = 0;
      pfRet10y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.points10y.forEach((el1, index11) => {
            if (pfRet10y[index1].d === el1.d) {
               pfRet10y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].points10y_diff, [
                  "d",
                  pfRet10y[index1].d,
               ]) &&
               pfRet10y[index1].diff_ticker.indexOf(currentPf[index2].ticker) <
                  0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet10y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               pfRet10y[index1].diff += currentPf[index2].pfWeight;
               pfRet10y[index1].diff_ticker.push(currentPf[index2].ticker);
            }
         });
      });
      pfRet10y[index1].v =
         pfRet10y[index1].v / (weightSum - pfRet10y[index1].diff);
      delete pfRet10y[index1].diff;
      delete pfRet10y[index1].diff_ticker;
   });

   historical.pfRet1y.forEach((el, index1) => {
      historical.pfRet1y[index1].diff = 0;
      historical.pfRet1y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points1y.forEach((el1, index11) => {
            if (historical.pfRet1y[index1].d === el1.d) {
               historical.pfRet1y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points1y_diff, [
                  "d",
                  historical.pfRet1y[index1].d,
               ]) &&
               historical.pfRet1y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet1y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet1y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet1y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet1y[index1].v =
         historical.pfRet1y[index1].v /
         (weightSum - historical.pfRet1y[index1].diff);
      delete historical.pfRet1y[index1].diff;
      delete historical.pfRet1y[index1].diff_ticker;
   });

   historical.pfRet2y.forEach((el, index1) => {
      historical.pfRet2y[index1].diff = 0;
      historical.pfRet2y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points2y.forEach((el1, index11) => {
            if (historical.pfRet2y[index1].d === el1.d) {
               historical.pfRet2y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points2y_diff, [
                  "d",
                  historical.pfRet2y[index1].d,
               ]) &&
               historical.pfRet2y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet2y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet2y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet2y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet2y[index1].v =
         historical.pfRet2y[index1].v /
         (weightSum - historical.pfRet2y[index1].diff);
      delete historical.pfRet2y[index1].diff;
      delete historical.pfRet2y[index1].diff_ticker;
   });

   historical.pfRet3y.forEach((el, index1) => {
      historical.pfRet3y[index1].diff = 0;
      historical.pfRet3y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points3y.forEach((el1, index11) => {
            if (historical.pfRet3y[index1].d === el1.d) {
               historical.pfRet3y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points3y_diff, [
                  "d",
                  historical.pfRet3y[index1].d,
               ]) &&
               historical.pfRet3y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet3y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet3y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet3y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet3y[index1].v =
         historical.pfRet3y[index1].v /
         (weightSum - historical.pfRet3y[index1].diff);
      delete historical.pfRet3y[index1].diff;
      delete historical.pfRet3y[index1].diff_ticker;
   });

   historical.pfRet4y.forEach((el, index1) => {
      historical.pfRet4y[index1].diff = 0;
      historical.pfRet4y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points4y.forEach((el1, index11) => {
            if (historical.pfRet4y[index1].d === el1.d) {
               historical.pfRet4y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points4y_diff, [
                  "d",
                  historical.pfRet4y[index1].d,
               ]) &&
               historical.pfRet4y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet4y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet4y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet4y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet4y[index1].v =
         historical.pfRet4y[index1].v /
         (weightSum - historical.pfRet4y[index1].diff);
      delete historical.pfRet4y[index1].diff;
      delete historical.pfRet4y[index1].diff_ticker;
   });

   historical.pfRet5y.forEach((el, index1) => {
      historical.pfRet5y[index1].diff = 0;
      historical.pfRet5y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points5y.forEach((el1, index11) => {
            if (historical.pfRet5y[index1].d === el1.d) {
               historical.pfRet5y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points5y_diff, [
                  "d",
                  historical.pfRet5y[index1].d,
               ]) &&
               historical.pfRet5y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet5y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet5y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet5y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet5y[index1].v =
         historical.pfRet5y[index1].v /
         (weightSum - historical.pfRet5y[index1].diff);
      delete historical.pfRet5y[index1].diff;
      delete historical.pfRet5y[index1].diff_ticker;
   });

   historical.pfRet6y.forEach((el, index1) => {
      historical.pfRet6y[index1].diff = 0;
      historical.pfRet6y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points6y.forEach((el1, index11) => {
            if (historical.pfRet6y[index1].d === el1.d) {
               historical.pfRet6y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points6y_diff, [
                  "d",
                  historical.pfRet6y[index1].d,
               ]) &&
               historical.pfRet6y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet6y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet6y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet6y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet6y[index1].v =
         historical.pfRet6y[index1].v /
         (weightSum - historical.pfRet6y[index1].diff);
      delete historical.pfRet6y[index1].diff;
      delete historical.pfRet6y[index1].diff_ticker;
   });

   historical.pfRet7y.forEach((el, index1) => {
      historical.pfRet7y[index1].diff = 0;
      historical.pfRet7y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points7y.forEach((el1, index11) => {
            if (historical.pfRet7y[index1].d === el1.d) {
               historical.pfRet7y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points7y_diff, [
                  "d",
                  historical.pfRet7y[index1].d,
               ]) &&
               historical.pfRet7y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet7y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet7y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet7y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet7y[index1].v =
         historical.pfRet7y[index1].v /
         (weightSum - historical.pfRet7y[index1].diff);
      delete historical.pfRet7y[index1].diff;
      delete historical.pfRet7y[index1].diff_ticker;
   });

   historical.pfRet8y.forEach((el, index1) => {
      historical.pfRet8y[index1].diff = 0;
      historical.pfRet8y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points8y.forEach((el1, index11) => {
            if (historical.pfRet8y[index1].d === el1.d) {
               historical.pfRet8y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points8y_diff, [
                  "d",
                  historical.pfRet8y[index1].d,
               ]) &&
               historical.pfRet8y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet8y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet8y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet8y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet8y[index1].v =
         historical.pfRet8y[index1].v /
         (weightSum - historical.pfRet8y[index1].diff);
      delete historical.pfRet8y[index1].diff;
      delete historical.pfRet8y[index1].diff_ticker;
   });

   historical.pfRet9y.forEach((el, index1) => {
      historical.pfRet9y[index1].diff = 0;
      historical.pfRet9y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points9y.forEach((el1, index11) => {
            if (historical.pfRet9y[index1].d === el1.d) {
               historical.pfRet9y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points9y_diff, [
                  "d",
                  historical.pfRet9y[index1].d,
               ]) &&
               historical.pfRet9y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet9y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet9y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet9y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet9y[index1].v =
         historical.pfRet9y[index1].v /
         (weightSum - historical.pfRet9y[index1].diff);
      delete historical.pfRet9y[index1].diff;
      delete historical.pfRet9y[index1].diff_ticker;
   });

   historical.pfRet10y.forEach((el, index1) => {
      historical.pfRet10y[index1].diff = 0;
      historical.pfRet10y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         e.pointsHistorical.points10y.forEach((el1, index11) => {
            if (historical.pfRet10y[index1].d === el1.d) {
               historical.pfRet10y[index1].v += el1.v;
            }
            //find the diff sum
            if (
               _.find(currentPf[index2].pointsHistorical_diff.points10y_diff, [
                  "d",
                  historical.pfRet10y[index1].d,
               ]) &&
               historical.pfRet10y[index1].diff_ticker.indexOf(
                  currentPf[index2].ticker
               ) < 0
            ) {
               // console.log('sid pratik 1--', e.ticker, pfRet10y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
               historical.pfRet10y[index1].diff += currentPf[index2].pfWeight;
               historical.pfRet10y[index1].diff_ticker.push(
                  currentPf[index2].ticker
               );
            }
         });
      });
      historical.pfRet10y[index1].v =
         historical.pfRet10y[index1].v /
         (weightSum - historical.pfRet10y[index1].diff);
      delete historical.pfRet10y[index1].diff;
      delete historical.pfRet10y[index1].diff_ticker;
   });

   portfolioExpenseRatio = portfolioSumExpenseRatioValue / portfolioSumValue;
   let annualizedRet1y = getAnnualizedReturnsByYears(pfRet1y);
   let annualizedRet3y = getAnnualizedReturnsByYears(pfRet3y);
   let annualizedRet5y = getAnnualizedReturnsByYears(pfRet5y);
   let annualizedRet10y = getAnnualizedReturnsByYears(pfRet10y);

   let annualizedVolt1y = getAnnualizedVolatilityByYears(pfRet1y);
   let annualizedVolt3y = getAnnualizedVolatilityByYears(pfRet3y);
   let annualizedVolt5y = getAnnualizedVolatilityByYears(pfRet5y);
   let annualizedVolt10y = getAnnualizedVolatilityByYears(pfRet10y);

   let annualizedRetHistorical = [];
   annualizedRetHistorical.push({
      key: "annualizedRet1y",
      value: getAnnualizedReturnsByYears(historical.pfRet1y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet2y",
      value: getAnnualizedReturnsByYears(historical.pfRet2y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet3y",
      value: getAnnualizedReturnsByYears(historical.pfRet3y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet4y",
      value: getAnnualizedReturnsByYears(historical.pfRet4y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet5y",
      value: getAnnualizedReturnsByYears(historical.pfRet5y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet6y",
      value: getAnnualizedReturnsByYears(historical.pfRet6y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet7y",
      value: getAnnualizedReturnsByYears(historical.pfRet7y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet8y",
      value: getAnnualizedReturnsByYears(historical.pfRet8y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet9y",
      value: getAnnualizedReturnsByYears(historical.pfRet9y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet10y",
      value: getAnnualizedReturnsByYears(historical.pfRet10y),
   });

   let annualizedVolHistorical = [
      getAnnualizedVolatilityByYears(historical.pfRet1y),
      getAnnualizedVolatilityByYears(historical.pfRet2y),
      getAnnualizedVolatilityByYears(historical.pfRet3y),
      getAnnualizedVolatilityByYears(historical.pfRet4y),
      getAnnualizedVolatilityByYears(historical.pfRet5y),
      getAnnualizedVolatilityByYears(historical.pfRet6y),
      getAnnualizedVolatilityByYears(historical.pfRet7y),
      getAnnualizedVolatilityByYears(historical.pfRet8y),
      getAnnualizedVolatilityByYears(historical.pfRet9y),
      getAnnualizedVolatilityByYears(historical.pfRet10y),
   ];

   let avgHis = 0.0,
      sumAvgHis = 0.0,
      nHis = 0.0,
      stdArr = [];
   annualizedRetHistorical.forEach((item, index) => {
      nHis += 1;
      item.value =
         query === "similar" ? item.value - portfolioExpenseRatio : item.value;
      stdArr.push(item.value);
      sumAvgHis += item.value;
   });
   if (nHis > 0) avgHis = sumAvgHis / nHis;
   let stdev = std(stdArr);
   let stdevVol = std(annualizedVolHistorical);

   //PF assetAllocation
   let assetPFFunds = 0,
      equitiesSum = 0.0,
      bondsSum = 0.0,
      commoditiesSum = 0.0,
      currenciesSum = 0.0,
      derivativesSum = 0.0,
      cashSum = 0.0,
      otherSum = 0.0;

   invalidTickerFunds.forEach(e => {
      if (e.type === "CASH") {
         // type: CASH
         cashSum += 100 * e.weight;
      }
      if (e.type === "SHARE") {
         // type: SHARE
         equitiesSum += 100 * e.weight;
      }
   });

   tickerNotSupported.forEach(e => {
      // [5]
      otherSum += 100 * e.weight;
   });

   currentPf.forEach(e => {
      // [5]
      if (e.asset_alloc) {
         assetPFFunds += 1;
         if (e.asset_alloc.equities)
            equitiesSum += e.asset_alloc.equities * e.weight;
         if (e.asset_alloc.bonds) bondsSum += e.asset_alloc.bonds * e.weight;
         if (e.asset_alloc.commodities)
            commoditiesSum += e.asset_alloc.commodities * e.weight;
         if (e.asset_alloc.currencies)
            currenciesSum += e.asset_alloc.currencies * e.weight;
         if (e.asset_alloc.derivatives)
            derivativesSum += e.asset_alloc.derivatives * e.weight;
         if (e.asset_alloc.cash) cashSum += e.asset_alloc.cash * e.weight;
         if (e.asset_alloc.other) otherSum += e.asset_alloc.other * e.weight;
      }
   });

   const asset_alloc = {
      equities: assetPFFunds ? equitiesSum / 100 : 0, // assetPFFunds ? equitiesSum / assetPFFunds : 0,
      bonds: assetPFFunds ? bondsSum / 100 : 0, // assetPFFunds ? bondsSum / assetPFFunds : 0,
      commodities: assetPFFunds ? commoditiesSum / 100 : 0, //assetPFFunds ? commoditiesSum / assetPFFunds : 0,
      currencies: assetPFFunds ? currenciesSum / 100 : 0, // assetPFFunds ? currenciesSum / assetPFFunds : 0,
      derivatives: assetPFFunds ? derivativesSum / 100 : 0, // assetPFFunds ? derivativesSum / assetPFFunds : 0,
      cash: assetPFFunds ? cashSum / 100 : 0, // assetPFFunds ? cashSum / assetPFFunds : 0,
      other: assetPFFunds ? otherSum / 100 : 0, // assetPFFunds ? otherSum / assetPFFunds : 0,
   };

   // console.log('========== Enhanced Portfolio Asset Alloc ==========');
   // console.log(asset_alloc);
   // console.log('===================================================');

   //calculate up capture and down capture
   //getGeoMean of portfolioBenchmark and potfolio
   //
   let up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr;
   const __ret = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y);
   if (__ret) {
      up_1yr = __ret.up_1yr;
      down_1yr = __ret.down_1yr;
      up_3yr = __ret.up_3yr;
      down_3yr = __ret.down_3yr;
      up_5yr = __ret.up_5yr;
      down_5yr = __ret.down_5yr;
   }

   // debugger
   let sharpe_ratio_3y = parseFloat(
      isNaN((annualizedRet3y / annualizedVolt3y).toFixed(2))
         ? 0.0
         : (annualizedRet3y / annualizedVolt3y).toFixed(2)
   );
   let sharpe_pfRet1y = parseFloat(
      isNaN((annualizedRet1y / annualizedVolt1y).toFixed(2))
         ? 0.0
         : (annualizedRet1y / annualizedVolt1y).toFixed(2)
   );
   let sharpe_pfRet3y = parseFloat(
      isNaN((annualizedRet3y / annualizedVolt3y).toFixed(2))
         ? 0.0
         : (annualizedRet3y / annualizedVolt3y).toFixed(2)
   );
   let sharpe_pfRet5y = parseFloat(
      isNaN((annualizedRet5y / annualizedVolt5y).toFixed(2))
         ? 0.0
         : (annualizedRet5y / annualizedVolt5y).toFixed(2)
   );

   let out = {
      pfRet1y,
      pfRet3y,
      pfRet5y,
      pfRet10y,
      averageCostAdjRet10Yr: avgHis,
      stdev: stdev.toFixed(2),
      stdevVol: stdevVol.toFixed(2),
      asset_alloc: asset_alloc,
      fee:
         portfolioSumExpenseRatioValue != null
            ? parseFloat((portfolioSumExpenseRatioValue / 100).toFixed(2))
            : "--",
      expenseRatio:
         portfolioExpenseRatio != null
            ? parseFloat(portfolioExpenseRatio.toFixed(2))
            : "--",
      anRet1yr: parseFloat(getAnnualizedReturnsByYears(pfRet1y).toFixed(2)),
      anVol1yr: parseFloat(getAnnualizedVolatilityByYears(pfRet1y).toFixed(2)),
      anVol3yr: parseFloat(annualizedVolt3y.toFixed(2)),
      anRet3yr: parseFloat(annualizedRet3y.toFixed(2)),
      anVol5yr: parseFloat(getAnnualizedVolatilityByYears(pfRet5y).toFixed(2)),
      anRet5yr: parseFloat(getAnnualizedReturnsByYears(pfRet5y).toFixed(2)),
      anVol10yr: parseFloat(annualizedVolt10y.toFixed(2)),
      anRet10yr: parseFloat(annualizedRet10y.toFixed(2)),

      anRet1yr_report:
         getAnnualizedReturnsByYears(pfRet1y) !== null &&
         !isNaN(getAnnualizedReturnsByYears(pfRet1y))
            ? numberCheck(getAnnualizedReturnsByYears(pfRet1y), "all")
            : "--",
      anRet3yr_report:
         annualizedVolt3y !== null && !isNaN(annualizedVolt3y)
            ? numberCheck(annualizedVolt3y, "all")
            : "--",
      anRet5yr_report:
         getAnnualizedReturnsByYears(pfRet5y) !== null &&
         !isNaN(getAnnualizedReturnsByYears(pfRet5y))
            ? numberCheck(getAnnualizedReturnsByYears(pfRet5y), "all")
            : "--",
      anRet10yr_report:
         annualizedRet10y !== null && !isNaN(annualizedRet10y)
            ? numberCheck(annualizedRet10y, "all")
            : "--",

      minus_1_sd_10yr: parseFloat(annualizedRet10y + -1 * annualizedVolt10y),
      plus_1_sd_10yr: parseFloat(annualizedRet10y + annualizedVolt10y * 1),
      dMax: parseFloat((outd.dMax * 100).toFixed(2)),
      sharpe_ratio_3y: isFinite(sharpe_ratio_3y) ? sharpe_ratio_3y : 0,
      sharpe_pfRet1y: isFinite(sharpe_pfRet1y) ? sharpe_pfRet1y : 0,
      sharpe_pfRet3y: isFinite(sharpe_pfRet3y) ? sharpe_pfRet3y : 0,
      sharpe_pfRet5y: isFinite(sharpe_pfRet5y) ? sharpe_pfRet5y : 0,
      yield: parseFloat(((yieldSum / portfolioSumValue) * 100).toFixed(2)),
      outperform: parseFloat(
         ((outperformSum / portfolioSumValue) * 100).toFixed(2)
      ),
      trackRecord: parseFloat((trackRecord / portfolioSumValue).toFixed(2)),
      cuml_pfRet1y: cuml1 * 100,
      cuml_pfRet3y: cuml * 100,
      cuml_pfRet5y: cuml5 * 100,
      up_1yr,
      down_1yr,
      up_3yr,
      down_3yr,
      up_5yr,
      down_5yr,
   };
   return out;
};

// summary page stats calc (holdings)
export const getCurrentPfStats = (
   currentP,
   query,
   portfolioBenchmark = {},
   invalidTickerFunds = [],
   tickerNotSupported = []
) => {
   const currentPf = JSON.parse(JSON.stringify(currentP));
   let portfolioSumValue = 0.0,
      feeSum = 0.0,
      portfolioSumExpenseRatioValue = 0.0,
      portfolioExpenseRatio = 0.0,
      weightSum = 0.0;
   let yieldSum = 0.0,
      outperformSum = 0.0,
      trackRecord = 0.0;
   const currentYear = moment(new Date()).year();

   // start pf ret calc
   let pfRet1y = getMonthFromYearDiff(1);
   let pfRet3y = getMonthFromYearDiff(3);
   // console.log(pfRet3y);
   let pfRet5y = getMonthFromYearDiff(5);
   // console.log(pfRet5y);

   let pfRet10y = getMonthFromYearDiff(10);
   let historical = {};
   historical.pfRet1y = getMonthFromYearDiff(1);
   historical.pfRet2y = getMonthFromYearDiff(1, currentYear - 1);
   historical.pfRet3y = getMonthFromYearDiff(1, currentYear - 2);
   historical.pfRet4y = getMonthFromYearDiff(1, currentYear - 3);
   historical.pfRet5y = getMonthFromYearDiff(1, currentYear - 4);
   historical.pfRet6y = getMonthFromYearDiff(1, currentYear - 5);
   historical.pfRet7y = getMonthFromYearDiff(1, currentYear - 6);
   historical.pfRet8y = getMonthFromYearDiff(1, currentYear - 7);
   historical.pfRet9y = getMonthFromYearDiff(1, currentYear - 8);
   historical.pfRet10y = getMonthFromYearDiff(1, currentYear - 9);

   currentPf.forEach((e, index) => {
      // const qty = (e._pF.quantity*e._pF._realTimePrice)/e.nav  // get realTimePrice of e
      // e._realTimePrice = e.nav; // comment it later
      //
      if (e._realTimePrice && e.quantity && e.weight)
         portfolioSumValue += e.weight;
      if (e._realTimePrice && e.quantity && e.weight && e.fees) {
         feeSum += e._realTimePrice * e.quantity * e.fees;
         portfolioSumExpenseRatioValue += e.weight * e.fees;
      }

      //calculate pf yield
      if (e["yield"]) yieldSum += e.weight * e["yield"];

      //calculate pf outperform
      if (e["outperform"]) outperformSum += e.weight * (e["outperform"] || 0.0);

      //calculate pf trackRecord
      if (e["track_record"]) trackRecord += e.weight * e["track_record"];

      let returnsArr = e.return ? e.return.returns : e.returns;
      if (returnsArr.length > 0) {
         let start_dt = moment(
            new Date(`01 ${returnsArr[returnsArr.length - 1].d}`)
         );
         let end_dt = moment(new Date(`01 ${returnsArr[0].d}`));

         //check to reverse the returnsArr or not
         if (start_dt.isAfter(end_dt)) {
            returnsArr = returnsArr.reverse();
            start_dt = moment(
               new Date(`01 ${returnsArr[returnsArr.length - 1].d}`)
            );
            end_dt = moment(new Date(`01 ${returnsArr[0].d}`));
         }

         const start = start_dt;
         const end = end_dt;

         const DateRangeMoment = { start, end };

         const calculatePoints = (ret, year, startFrom) => {
            let returns = JSON.parse(JSON.stringify(ret));
            const range = {
               start: nameToDateRange(year, DateRangeMoment, startFrom).start
                  ._d,
               end: nameToDateRange(year, DateRangeMoment, startFrom).end._d,
            };
            // console.log(range);
            return returns.filter(e => {
               let dt = monthToDate(e.d);
               return (
                  dt.isAfter(moment(range.start).subtract(1, "seconds")) &&
                  dt.isBefore(moment(range.end).add(1, "seconds"))
               );
            });
         };

         currentPf[index].points1y = calculatePoints(returnsArr, "l1y");
         // console.log(currentPf[index].points1y);
         currentPf[index].points3y = calculatePoints(returnsArr, "l3y");
         // console.log(currentPf[index].points3y);
         currentPf[index].points5y = calculatePoints(returnsArr, "l5y");
         // console.log(currentPf[index].points5y);

         currentPf[index].points10y = calculatePoints(returnsArr, "l10y");
         currentPf[index].pointsHistorical = {};
         currentPf[index].pointsHistorical.points1y = calculatePoints(
            returnsArr,
            "l10y"
         );
         currentPf[index].pointsHistorical.points2y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 1
         );
         currentPf[index].pointsHistorical.points3y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 2
         );
         currentPf[index].pointsHistorical.points4y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 3
         );
         currentPf[index].pointsHistorical.points5y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 4
         );
         currentPf[index].pointsHistorical.points6y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 5
         );
         currentPf[index].pointsHistorical.points7y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 6
         );
         currentPf[index].pointsHistorical.points8y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 7
         );
         currentPf[index].pointsHistorical.points9y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 8
         );
         currentPf[index].pointsHistorical.points10y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 9
         );
         currentPf[index].pfWeight = e.weight;
         weightSum += currentPf[index].pfWeight;
         currentPf[index].retWeighted1y = [];
         currentPf[index].retWeighted3y = [];
         currentPf[index].retWeighted5y = [];
         currentPf[index].retWeighted10y = [];
         currentPf[index].retWeightedHistorical = {
            retWeighted1y: [],
            retWeighted2y: [],
            retWeighted3y: [],
            retWeighted4y: [],
            retWeighted5y: [],
            retWeighted6y: [],
            retWeighted7y: [],
            retWeighted8y: [],
            retWeighted9y: [],
            retWeighted10y: [],
         };
         //_.differenceBy(pfRet1y, currentPf[index].points1y, 'd');
         currentPf[index].points1y_diff = _.differenceBy(
            pfRet1y,
            currentPf[index].points1y,
            "d"
         );
         currentPf[index].points3y_diff = _.differenceBy(
            pfRet3y,
            currentPf[index].points3y,
            "d"
         );
         currentPf[index].points5y_diff = _.differenceBy(
            pfRet5y,
            currentPf[index].points5y,
            "d"
         );
         currentPf[index].points10y_diff = _.differenceBy(
            pfRet10y,
            currentPf[index].points10y,
            "d"
         );
         currentPf[index].pointsHistorical_diff = {};
         currentPf[index].pointsHistorical_diff.points1y_diff = _.differenceBy(
            historical.pfRet1y,
            currentPf[index].pointsHistorical.points1y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points2y_diff = _.differenceBy(
            historical.pfRet2y,
            currentPf[index].pointsHistorical.points2y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points3y_diff = _.differenceBy(
            historical.pfRet3y,
            currentPf[index].pointsHistorical.points3y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points4y_diff = _.differenceBy(
            historical.pfRet4y,
            currentPf[index].pointsHistorical.points4y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points5y_diff = _.differenceBy(
            historical.pfRet5y,
            currentPf[index].pointsHistorical.points5y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points6y_diff = _.differenceBy(
            historical.pfRet6y,
            currentPf[index].pointsHistorical.points6y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points7y_diff = _.differenceBy(
            historical.pfRet7y,
            currentPf[index].pointsHistorical.points7y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points8y_diff = _.differenceBy(
            historical.pfRet8y,
            currentPf[index].pointsHistorical.points8y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points9y_diff = _.differenceBy(
            historical.pfRet9y,
            currentPf[index].pointsHistorical.points9y,
            "d"
         );
         currentPf[index].pointsHistorical_diff.points10y_diff = _.differenceBy(
            historical.pfRet10y,
            currentPf[index].pointsHistorical.points10y,
            "d"
         );

         e.points1y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100; //multiplied by 100 to negate the divide by 100 for charts
            currentPf[index].retWeighted1y.push(el);
         });

         e.points3y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeighted3y.push(el);
         });

         e.points5y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeighted5y.push(el);
         });

         e.points10y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeighted10y.push(el);
         });

         e.pointsHistorical.points1y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted1y.push(el);
         });

         e.pointsHistorical.points2y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted2y.push(el);
         });

         e.pointsHistorical.points3y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted3y.push(el);
         });

         e.pointsHistorical.points4y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted4y.push(el);
         });

         e.pointsHistorical.points5y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted5y.push(el);
         });

         e.pointsHistorical.points6y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted6y.push(el);
         });

         e.pointsHistorical.points7y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted7y.push(el);
         });

         e.pointsHistorical.points8y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted8y.push(el);
         });

         e.pointsHistorical.points9y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted9y.push(el);
         });

         e.pointsHistorical.points10y.forEach((el, index1) => {
            el.v = el.v * currentPf[index].pfWeight * 100;
            currentPf[index].retWeightedHistorical.retWeighted10y.push(el);
         });
      }
      // console.log('points',e.points1y,e.points3y, e.points5y)
   });
   // end for each portfolio

   // start pf ret calc
   // let outd1 = { n: 0, dMax: 0.0 },
   // eslint-disable-next-line no-unused-vars
     let cuml1 = 0.0;
   pfRet1y.forEach((el, index1) => {
      pfRet1y[index1].diff = 0;
      pfRet1y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.points1y && typeof e.points1y !== "undefined") {
            e.points1y.forEach((el1, index11) => {
               if (pfRet1y[index1].d === el1.d) {
                  pfRet1y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(currentPf[index2].points1y_diff, [
                     "d",
                     pfRet1y[index1].d,
                  ]) &&
                  pfRet1y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet1y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  pfRet1y[index1].diff += currentPf[index2].pfWeight;
                  pfRet1y[index1].diff_ticker.push(currentPf[index2].ticker);
               }
               // console.log('sid pratik 2--', e.ticker, pfRet1y[index1].diff_ticker)
            });
         }
      });
      if (typeof pfRet1y[index1].v !== "undefined") {
         //check if the d is in diff
         // if yes then subtract it from here.
         pfRet1y[index1].v =
            pfRet1y[index1].v / (weightSum - pfRet1y[index1].diff);
         //calculate drawdown
         let r = pfRet1y[index1].v / 100;
         cuml1 = (1 + cuml1) * (1 + r) - 1;
      }
      delete pfRet1y[index1].diff;
      delete pfRet1y[index1].diff_ticker;
   });

   let outd = { n: 0, dMax: 0.0 },
      cuml = 0.0;
   pfRet3y.forEach((el, index1) => {
      pfRet3y[index1].diff = 0;
      pfRet3y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.points3y && typeof e.points3y !== "undefined") {
            e.points3y.forEach((el1, index11) => {
               if (pfRet3y[index1].d === el1.d) {
                  pfRet3y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(currentPf[index2].points3y_diff, [
                     "d",
                     pfRet3y[index1].d,
                  ]) &&
                  pfRet3y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet1y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  pfRet3y[index1].diff += currentPf[index2].pfWeight;
                  pfRet3y[index1].diff_ticker.push(currentPf[index2].ticker);
               }
            });
         }
      });
      if (typeof pfRet3y[index1].v !== "undefined") {
         pfRet3y[index1].v =
            pfRet3y[index1].v / (weightSum - pfRet3y[index1].diff);
         //calculate drawdown
         let r = pfRet3y[index1].v / 100;
         cuml = (1 + cuml) * (1 + r) - 1;
         outd.n += 1;
         if (outd.n === 1) {
            outd.cumlMax = cuml;
         } else {
            cuml > outd.cumlMax && (outd.cumlMax = cuml);
            let dmax = (outd.cumlMax - cuml) / (1 + outd.cumlMax);
            dmax > outd.dMax && (outd.dMax = dmax);
         }
      }
      delete pfRet3y[index1].diff;
      delete pfRet3y[index1].diff_ticker;
   });

   // let outd5 = { n: 0, dMax: 0.0 },
     let cuml5 = 0.0;
   pfRet5y.forEach((el, index1) => {
      pfRet5y[index1].diff = 0;
      pfRet5y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.points5y && typeof e.points5y !== "undefined") {
            e.points5y.forEach((el1, index11) => {
               if (pfRet5y[index1].d === el1.d) {
                  pfRet5y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(currentPf[index2].points5y_diff, [
                     "d",
                     pfRet5y[index1].d,
                  ]) &&
                  pfRet5y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet5y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  pfRet5y[index1].diff += currentPf[index2].pfWeight;
                  pfRet5y[index1].diff_ticker.push(currentPf[index2].ticker);
               }
            });
         }
      });
      if (typeof pfRet5y[index1].v !== "undefined") {
         pfRet5y[index1].v =
            pfRet5y[index1].v / (weightSum - pfRet5y[index1].diff);
         //calculate drawdown
         let r = pfRet5y[index1].v / 100;
         cuml5 = (1 + cuml5) * (1 + r) - 1;
      }
      delete pfRet5y[index1].diff;
      delete pfRet5y[index1].diff_ticker;
   });

   pfRet10y.forEach((el, index1) => {
      pfRet10y[index1].diff = 0;
      pfRet10y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.points10y && typeof e.points10y !== "undefined") {
            e.points10y.forEach((el1, index11) => {
               if (pfRet10y[index1].d === el1.d) {
                  pfRet10y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(currentPf[index2].points10y_diff, [
                     "d",
                     pfRet10y[index1].d,
                  ]) &&
                  pfRet10y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet10y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  pfRet10y[index1].diff += currentPf[index2].pfWeight;
                  pfRet10y[index1].diff_ticker.push(currentPf[index2].ticker);
               }
            });
         }
      });
      if (typeof pfRet10y[index1].v !== "undefined") {
         pfRet10y[index1].v =
            pfRet10y[index1].v / (weightSum - pfRet10y[index1].diff);
      }
      delete pfRet10y[index1].diff;
      delete pfRet10y[index1].diff_ticker;
   });

   historical.pfRet1y.forEach((el, index1) => {
      historical.pfRet1y[index1].diff = 0;
      historical.pfRet1y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points1y.forEach((el1, index11) => {
               if (historical.pfRet1y[index1].d === el1.d) {
                  historical.pfRet1y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points1y_diff,
                     ["d", historical.pfRet1y[index1].d]
                  ) &&
                  historical.pfRet1y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet1y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet1y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet1y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet1y[index1].v !== "undefined") {
         historical.pfRet1y[index1].v =
            historical.pfRet1y[index1].v /
            (weightSum - historical.pfRet1y[index1].diff);
      }
      delete historical.pfRet1y[index1].diff;
      delete historical.pfRet1y[index1].diff_ticker;
   });

   historical.pfRet2y.forEach((el, index1) => {
      historical.pfRet2y[index1].diff = 0;
      historical.pfRet2y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points2y.forEach((el1, index11) => {
               if (historical.pfRet2y[index1].d === el1.d) {
                  historical.pfRet2y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points2y_diff,
                     ["d", historical.pfRet2y[index1].d]
                  ) &&
                  historical.pfRet2y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet2y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet2y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet2y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet2y[index1].v !== "undefined") {
         historical.pfRet2y[index1].v =
            historical.pfRet2y[index1].v /
            (weightSum - historical.pfRet2y[index1].diff);
      }
      delete historical.pfRet2y[index1].diff;
      delete historical.pfRet2y[index1].diff_ticker;
   });

   historical.pfRet3y.forEach((el, index1) => {
      historical.pfRet3y[index1].diff = 0;
      historical.pfRet3y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points3y.forEach((el1, index11) => {
               if (historical.pfRet3y[index1].d === el1.d) {
                  historical.pfRet3y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points3y_diff,
                     ["d", historical.pfRet3y[index1].d]
                  ) &&
                  historical.pfRet3y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet3y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet3y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet3y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet3y[index1].v !== "undefined") {
         historical.pfRet3y[index1].v =
            historical.pfRet3y[index1].v /
            (weightSum - historical.pfRet3y[index1].diff);
      }
      delete historical.pfRet3y[index1].diff;
      delete historical.pfRet3y[index1].diff_ticker;
   });

   historical.pfRet4y.forEach((el, index1) => {
      historical.pfRet4y[index1].diff = 0;
      historical.pfRet4y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points4y.forEach((el1, index11) => {
               if (historical.pfRet4y[index1].d === el1.d) {
                  historical.pfRet4y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points4y_diff,
                     ["d", historical.pfRet4y[index1].d]
                  ) &&
                  historical.pfRet4y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet4y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet4y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet4y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet4y[index1].v !== "undefined") {
         historical.pfRet4y[index1].v =
            historical.pfRet4y[index1].v /
            (weightSum - historical.pfRet4y[index1].diff);
      }
      delete historical.pfRet4y[index1].diff;
      delete historical.pfRet4y[index1].diff_ticker;
   });

   historical.pfRet5y.forEach((el, index1) => {
      historical.pfRet5y[index1].diff = 0;
      historical.pfRet5y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points5y.forEach((el1, index11) => {
               if (historical.pfRet5y[index1].d === el1.d) {
                  historical.pfRet5y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points5y_diff,
                     ["d", historical.pfRet5y[index1].d]
                  ) &&
                  historical.pfRet5y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet5y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet5y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet5y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet5y[index1].v !== "undefined") {
         historical.pfRet5y[index1].v =
            historical.pfRet5y[index1].v /
            (weightSum - historical.pfRet5y[index1].diff);
      }
      delete historical.pfRet5y[index1].diff;
      delete historical.pfRet5y[index1].diff_ticker;
   });

   historical.pfRet6y.forEach((el, index1) => {
      historical.pfRet6y[index1].diff = 0;
      historical.pfRet6y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points6y.forEach((el1, index11) => {
               if (historical.pfRet6y[index1].d === el1.d) {
                  historical.pfRet6y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points6y_diff,
                     ["d", historical.pfRet6y[index1].d]
                  ) &&
                  historical.pfRet6y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet6y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet6y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet6y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet6y[index1].v !== "undefined") {
         historical.pfRet6y[index1].v =
            historical.pfRet6y[index1].v /
            (weightSum - historical.pfRet6y[index1].diff);
      }
      delete historical.pfRet6y[index1].diff;
      delete historical.pfRet6y[index1].diff_ticker;
   });

   historical.pfRet7y.forEach((el, index1) => {
      historical.pfRet7y[index1].diff = 0;
      historical.pfRet7y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points7y.forEach((el1, index11) => {
               if (historical.pfRet7y[index1].d === el1.d) {
                  historical.pfRet7y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points7y_diff,
                     ["d", historical.pfRet7y[index1].d]
                  ) &&
                  historical.pfRet7y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet7y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet7y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet7y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet7y[index1].v !== "undefined") {
         historical.pfRet7y[index1].v =
            historical.pfRet7y[index1].v /
            (weightSum - historical.pfRet7y[index1].diff);
      }
      delete historical.pfRet7y[index1].diff;
      delete historical.pfRet7y[index1].diff_ticker;
   });

   historical.pfRet8y.forEach((el, index1) => {
      historical.pfRet8y[index1].diff = 0;
      historical.pfRet8y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points8y.forEach((el1, index11) => {
               if (historical.pfRet8y[index1].d === el1.d) {
                  historical.pfRet8y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points8y_diff,
                     ["d", historical.pfRet8y[index1].d]
                  ) &&
                  historical.pfRet8y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet8y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet8y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet8y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet8y[index1].v !== "undefined") {
         historical.pfRet8y[index1].v =
            historical.pfRet8y[index1].v /
            (weightSum - historical.pfRet8y[index1].diff);
      }
      delete historical.pfRet8y[index1].diff;
      delete historical.pfRet8y[index1].diff_ticker;
   });

   historical.pfRet9y.forEach((el, index1) => {
      historical.pfRet9y[index1].diff = 0;
      historical.pfRet9y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points9y.forEach((el1, index11) => {
               if (historical.pfRet9y[index1].d === el1.d) {
                  historical.pfRet9y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points9y_diff,
                     ["d", historical.pfRet9y[index1].d]
                  ) &&
                  historical.pfRet9y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet9y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet9y[index1].diff += currentPf[index2].pfWeight;
                  historical.pfRet9y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet9y[index1].v !== "undefined") {
         historical.pfRet9y[index1].v =
            historical.pfRet9y[index1].v /
            (weightSum - historical.pfRet9y[index1].diff);
      }
      delete historical.pfRet9y[index1].diff;
      delete historical.pfRet9y[index1].diff_ticker;
   });

   historical.pfRet10y.forEach((el, index1) => {
      historical.pfRet10y[index1].diff = 0;
      historical.pfRet10y[index1].diff_ticker = [];
      currentPf.forEach((e, index2) => {
         if (e.pointsHistorical && typeof e.pointsHistorical !== "undefined") {
            e.pointsHistorical.points10y.forEach((el1, index11) => {
               if (historical.pfRet10y[index1].d === el1.d) {
                  historical.pfRet10y[index1].v += el1.v;
               }
               //find the diff sum
               if (
                  _.find(
                     currentPf[index2].pointsHistorical_diff.points10y_diff,
                     ["d", historical.pfRet10y[index1].d]
                  ) &&
                  historical.pfRet10y[index1].diff_ticker.indexOf(
                     currentPf[index2].ticker
                  ) < 0
               ) {
                  // console.log('sid pratik 1--', e.ticker, pfRet10y[index1].d, currentPf[index2].pfWeight, currentPf[index2].points1y_diff)
                  historical.pfRet10y[index1].diff +=
                     currentPf[index2].pfWeight;
                  historical.pfRet10y[index1].diff_ticker.push(
                     currentPf[index2].ticker
                  );
               }
            });
         }
      });
      if (typeof historical.pfRet10y[index1].v !== "undefined") {
         historical.pfRet10y[index1].v =
            historical.pfRet10y[index1].v /
            (weightSum - historical.pfRet10y[index1].diff);
      }
      delete historical.pfRet10y[index1].diff;
      delete historical.pfRet10y[index1].diff_ticker;
   });

   portfolioExpenseRatio = portfolioSumExpenseRatioValue / portfolioSumValue;
   let annualizedRet1y = getAnnualizedReturnsByYears(pfRet1y);
   let annualizedRet3y = getAnnualizedReturnsByYears(pfRet3y);
   let annualizedRet5y = getAnnualizedReturnsByYears(pfRet5y);
   let annualizedRet10y = getAnnualizedReturnsByYears(pfRet10y);

   let annualizedVolt1y = getAnnualizedVolatilityByYears(pfRet1y);
   let annualizedVolt3y = getAnnualizedVolatilityByYears(pfRet3y);
   let annualizedVolt5y = getAnnualizedVolatilityByYears(pfRet5y);
   let annualizedVolt10y = getAnnualizedVolatilityByYears(pfRet10y);

   let annualizedRetHistorical = [];
   annualizedRetHistorical.push({
      key: "annualizedRet1y",
      value: getAnnualizedReturnsByYears(historical.pfRet1y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet2y",
      value: getAnnualizedReturnsByYears(historical.pfRet2y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet3y",
      value: getAnnualizedReturnsByYears(historical.pfRet3y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet4y",
      value: getAnnualizedReturnsByYears(historical.pfRet4y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet5y",
      value: getAnnualizedReturnsByYears(historical.pfRet5y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet6y",
      value: getAnnualizedReturnsByYears(historical.pfRet6y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet7y",
      value: getAnnualizedReturnsByYears(historical.pfRet7y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet8y",
      value: getAnnualizedReturnsByYears(historical.pfRet8y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet9y",
      value: getAnnualizedReturnsByYears(historical.pfRet9y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet10y",
      value: getAnnualizedReturnsByYears(historical.pfRet10y),
   });

   let annualizedVolHistorical = [
      getAnnualizedVolatilityByYears(historical.pfRet1y),
      getAnnualizedVolatilityByYears(historical.pfRet2y),
      getAnnualizedVolatilityByYears(historical.pfRet3y),
      getAnnualizedVolatilityByYears(historical.pfRet4y),
      getAnnualizedVolatilityByYears(historical.pfRet5y),
      getAnnualizedVolatilityByYears(historical.pfRet6y),
      getAnnualizedVolatilityByYears(historical.pfRet7y),
      getAnnualizedVolatilityByYears(historical.pfRet8y),
      getAnnualizedVolatilityByYears(historical.pfRet9y),
      getAnnualizedVolatilityByYears(historical.pfRet10y),
   ];

   let avgHis = 0.0,
      sumAvgHis = 0.0,
      nHis = 0.0,
      stdArr = [];
   annualizedRetHistorical.forEach((item, index) => {
      nHis += 1;
      item.value =
         query === "similar" ? item.value - portfolioExpenseRatio : item.value;
      stdArr.push(item.value);
      sumAvgHis += item.value;
   });
   if (nHis > 0) avgHis = sumAvgHis / nHis;
   let stdev = std(stdArr);
   let stdevVol = std(annualizedVolHistorical);

   //PF assetAllocation
   let assetPFFunds = 0,
      equitiesSum = 0.0,
      bondsSum = 0.0,
      commoditiesSum = 0.0,
      currenciesSum = 0.0,
      derivativesSum = 0.0,
      cashSum = 0.0,
      otherSum = 0.0;

   invalidTickerFunds.forEach(e => {
      if (e.type === "CASH") {
         // type: CASH
         cashSum += 100 * e.weight;
      }
      if (e.type === "SHARE") {
         // type: SHARE
         equitiesSum += 100 * e.weight;
      }
   });

   tickerNotSupported.forEach(e => {
      // [5]
      otherSum += 100 * e.weight;
   });

   currentPf.forEach(e => {
      // [5]
      if (e.asset_alloc) {
         assetPFFunds += 1;
         if (e.asset_alloc.equities)
            equitiesSum += e.asset_alloc.equities * e.weight;
         if (e.asset_alloc.bonds) bondsSum += e.asset_alloc.bonds * e.weight;
         if (e.asset_alloc.commodities)
            commoditiesSum += e.asset_alloc.commodities * e.weight;
         if (e.asset_alloc.currencies)
            currenciesSum += e.asset_alloc.currencies * e.weight;
         if (e.asset_alloc.derivatives)
            derivativesSum += e.asset_alloc.derivatives * e.weight;
         if (e.asset_alloc.cash) cashSum += e.asset_alloc.cash * e.weight;
         if (e.asset_alloc.other) otherSum += e.asset_alloc.other * e.weight;
      }
   });

   const asset_alloc = {
      equities: assetPFFunds ? equitiesSum / 100 : 0, // assetPFFunds ? equitiesSum / assetPFFunds : 0,
      bonds: assetPFFunds ? bondsSum / 100 : 0, // assetPFFunds ? bondsSum / assetPFFunds : 0,
      commodities: assetPFFunds ? commoditiesSum / 100 : 0, //assetPFFunds ? commoditiesSum / assetPFFunds : 0,
      currencies: assetPFFunds ? currenciesSum / 100 : 0, // assetPFFunds ? currenciesSum / assetPFFunds : 0,
      derivatives: assetPFFunds ? derivativesSum / 100 : 0, // assetPFFunds ? derivativesSum / assetPFFunds : 0,
      cash: assetPFFunds ? cashSum / 100 : 0, // assetPFFunds ? cashSum / assetPFFunds : 0,
      other: assetPFFunds ? otherSum / 100 : 0, // assetPFFunds ? otherSum / assetPFFunds : 0,
   };

   // console.log('========== Current Portfolio Asset Alloc ==========');
   // console.log(asset_alloc);
   // console.log('===================================================');
   //calculate up capture and down capture
   //getGeoMean of portfolioBenchmark and potfolio
   //
   let up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr;
   // let portfolioBenchmark1 = JSON.parse(JSON.stringify(portfolioBenchmark)).reverse();
   const __ret = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y);
   if (__ret) {
      up_1yr = __ret.up_1yr;
      down_1yr = __ret.down_1yr;
      up_3yr = __ret.up_3yr;
      down_3yr = __ret.down_3yr;
      up_5yr = __ret.up_5yr;
      down_5yr = __ret.down_5yr;
   }
   // debugger
   let sharpe_ratio_3y = parseFloat(
      isNaN((annualizedRet3y / annualizedVolt3y).toFixed(2))
         ? 0.0
         : (annualizedRet3y / annualizedVolt3y).toFixed(2)
   );
   let sharpe_pfRet1y = parseFloat(
      isNaN((annualizedRet1y / annualizedVolt1y).toFixed(2))
         ? 0.0
         : (annualizedRet1y / annualizedVolt1y).toFixed(2)
   );
   let sharpe_pfRet3y = parseFloat(
      isNaN((annualizedRet3y / annualizedVolt3y).toFixed(2))
         ? 0.0
         : (annualizedRet3y / annualizedVolt3y).toFixed(2)
   );
   let sharpe_pfRet5y = parseFloat(
      isNaN((annualizedRet5y / annualizedVolt5y).toFixed(2))
         ? 0.0
         : (annualizedRet5y / annualizedVolt5y).toFixed(2)
   );

   let out = {
      pfRet1y,
      pfRet3y,
      pfRet5y,
      pfRet10y,
      averageCostAdjRet10Yr: avgHis,
      stdev: stdev.toFixed(2),
      stdevVol: stdevVol.toFixed(2),
      asset_alloc: asset_alloc,
      fee: feeSum != null ? parseFloat((feeSum / 100).toFixed(2)) : "--",
      expenseRatio:
         portfolioExpenseRatio != null
            ? parseFloat(portfolioExpenseRatio.toFixed(2))
            : "--",
      anRet1yr: getAnnualizedReturnsByYears(pfRet1y).toFixed(2),
      anVol1yr: getAnnualizedVolatilityByYears(pfRet1y).toFixed(2),
      anVol3yr: annualizedVolt3y.toFixed(2),
      anRet3yr: annualizedRet3y.toFixed(2),
      anVol5yr: getAnnualizedVolatilityByYears(pfRet5y).toFixed(2),
      anRet5yr: getAnnualizedReturnsByYears(pfRet5y).toFixed(2),
      anVol10yr: annualizedVolt10y.toFixed(2),
      anRet10yr: annualizedRet10y.toFixed(2),

      anRet1yr_report:
         getAnnualizedReturnsByYears(pfRet1y) !== null &&
         !isNaN(getAnnualizedReturnsByYears(pfRet1y))
            ? numberCheck(getAnnualizedReturnsByYears(pfRet1y), "all")
            : "--",
      anRet3yr_report:
         annualizedVolt3y !== null && !isNaN(annualizedVolt3y)
            ? numberCheck(annualizedVolt3y, "all")
            : "--",
      anRet5yr_report:
         getAnnualizedReturnsByYears(pfRet5y) !== null &&
         !isNaN(getAnnualizedReturnsByYears(pfRet5y))
            ? numberCheck(getAnnualizedReturnsByYears(pfRet5y), "all")
            : "--",
      anRet10yr_report:
         annualizedRet10y !== null && !isNaN(annualizedRet10y)
            ? numberCheck(annualizedRet10y, "all")
            : "--",

      minus_1_sd_10yr: annualizedRet10y + -1 * annualizedVolt10y,
      plus_1_sd_10yr: annualizedRet10y + annualizedVolt10y * 1,
      dMax: (outd.dMax * 100).toFixed(2),
      sharpe_ratio_3y: isFinite(sharpe_ratio_3y) ? sharpe_ratio_3y : 0,
      sharpe_pfRet1y: isFinite(sharpe_pfRet1y) ? sharpe_pfRet1y : 0,
      sharpe_pfRet3y: isFinite(sharpe_pfRet3y) ? sharpe_pfRet3y : 0,
      sharpe_pfRet5y: isFinite(sharpe_pfRet5y) ? sharpe_pfRet5y : 0,
      yield: ((yieldSum / portfolioSumValue) * 100).toFixed(2),
      outperform: ((outperformSum / portfolioSumValue) * 100).toFixed(2),
      trackRecord: (trackRecord / portfolioSumValue).toFixed(2),
      cuml_pfRet1y: cuml1 * 100,
      cuml_pfRet3y: cuml * 100,
      cuml_pfRet5y: cuml5 * 100,
      up_1yr,
      down_1yr,
      up_3yr,
      down_3yr,
      up_5yr,
      down_5yr,
   };
   return out;
};

export const getAnnualizedReturnsByYears = retArray => {
   if (!retArray || typeof retArray === "undefined" || retArray.length === 0)
      return null;
   let returnsArrayByYears = JSON.parse(JSON.stringify(retArray));
   if (
      !returnsArrayByYears ||
      typeof returnsArrayByYears === "undefined" ||
      returnsArrayByYears.length === 0
   )
      return null;
   // const userIndex = _.keyBy(returnsArrayByYears, 'd');
   // const sum = _.sum(_.map(userIndex, 'v'));
   // const mean = _.mean(_.map(userIndex, 'v'));
   // const avg = (sum / returnsArrayByYears.length) || 0;
   //calc cuml
   let cuml = 0.0;
   // console.log(returnsArrayByYears)
   returnsArrayByYears.forEach(d => {
      cuml = (1 + cuml) * (1 + d.v / 100) - 1;
   });

   // return AnnualizedReturns;
   return returnsArrayByYears.length >= 12
      ? (Math.pow(1 + cuml, 12 / returnsArrayByYears.length) - 1) * 100
      : cuml * 100;
};

export const getAnnualizedVolatilityByYears = retArray => {
   if (!retArray || typeof retArray === "undefined" || retArray.length === 0)
      return null;
   let returnsArrayByYears = JSON.parse(JSON.stringify(retArray));
   if (
      !returnsArrayByYears ||
      typeof returnsArrayByYears === "undefined" ||
      returnsArrayByYears.length === 0
   )
      return null;
   const userIndex = _.keyBy(returnsArrayByYears, "d");
   const filterARRAY = _.map(userIndex, "v");
   const AnnualizedVolumes = std(filterARRAY) * Math.sqrt(12);

   return AnnualizedVolumes;
};

export const getGeoMean = (benchmarkArray, retArray) => {
   //Geometric mean = (x1 * x2 * x3 * . . . * xn)^1/n
   let benchmarkReturnsArrayByYears = JSON.parse(
      JSON.stringify(benchmarkArray)
   );
   let returnsArrayByYears = JSON.parse(JSON.stringify(retArray));
   // console.log('sid sid 0', benchmarkReturnsArrayByYears, returnsArrayByYears)
   if (
      !benchmarkReturnsArrayByYears ||
      typeof benchmarkReturnsArrayByYears === "undefined" ||
      benchmarkReturnsArrayByYears.length === 0
   )
      return null;
   if (
      !returnsArrayByYears ||
      typeof returnsArrayByYears === "undefined" ||
      returnsArrayByYears.length === 0
   )
      return null;
   let benchUpsum = 1.0,
      benchDownsum = 1.0;
   let upsum = 1.0,
      downsum = 1.0;

   //create array of objects by date
   const benchUserIndex = _.keyBy(benchmarkReturnsArrayByYears, "d");
   // const userIndex = _.keyBy(returnsArrayByYears, "d");
   // console.log('sid sid 1', benchUserIndex, userIndex)
   //filter the positives from benchmark array
   // const benchFilterARRAY = _.map(benchUserIndex, 'v');
   // const filterARRAY = _.map(userIndex, 'v');

   const upDates = _.filter(
      _.map(benchUserIndex, function (e) {
         if (e.v >= 0) {
            return e.d;
         }
      }),
      function (o) {
         if (o) return o;
      }
   );
   const downDates = _.filter(
      _.map(benchUserIndex, function (e) {
         if (e.v < 0) {
            return e.d;
         }
      }),
      function (o) {
         if (o) return o;
      }
   );
   // console.log('sid sid 2', upDates, downDates)
   let benchUpSeries = [],
      benchDownSeries = [],
      upSeries = [],
      downSeries = [];

   benchUpSeries = _.map(
      benchmarkReturnsArrayByYears.filter(e => {
         if (upDates.indexOf(e.d) > -1) return e;
      }),
      "v"
   );
   benchDownSeries = _.map(
      benchmarkReturnsArrayByYears.filter(e => {
         if (downDates.indexOf(e.d) > -1) return e;
      }),
      "v"
   );
   // console.log('sid sid 3', benchUpSeries, benchDownSeries)
   // upSeries = filterARRAY.filter(e => e>=0);
   // downSeries = filterARRAY.filter(e => e<0);
   upSeries = _.map(
      returnsArrayByYears.filter(e => {
         if (upDates.indexOf(e.d) > -1) return e;
      }),
      "v"
   );
   downSeries = _.map(
      returnsArrayByYears.filter(e => {
         if (downDates.indexOf(e.d) > -1) return e;
      }),
      "v"
   );
   // console.log('sid sid 4', upSeries, downSeries)

   benchUpSeries.forEach(e => {
      benchUpsum *= e + 1;
   });
   benchDownSeries.forEach(e => {
      benchDownsum *= e + 1;
   });
   upSeries.forEach(e => {
      upsum *= e + 1;
   });
   downSeries.forEach(e => {
      downsum *= e + 1;
   });

   const benchGeoMean = {
      up: Math.pow(benchUpsum, 1 / benchUpSeries.length),
      down: Math.pow(benchDownsum, 1 / benchDownSeries.length),
   };
   const GeoMean = {
      up: Math.pow(upsum, 1 / upSeries.length),
      down: Math.pow(downsum, 1 / downSeries.length),
   };
   return { ret: GeoMean, bench: benchGeoMean };
};

export const std = arr => {
   let array = JSON.parse(JSON.stringify(arr));
   var avg = _.sum(array) / array.length;
   return Math.sqrt(
      _.sum(_.map(array, i => Math.pow(i - avg, 2))) / array.length
   );
};

export const getMonthFromYearDiff = (yearDiff = 0, startFrom) => {
   const currentYear = startFrom || moment(new Date()).year();
   // console.log(currentYear);
   const currentMonth = moment(new Date()).month();
   // console.log(currentMonth);
   // console.log('yearDiff ==>', yearDiff);

   let expectedYear =
      currentMonth + 1 === 12
         ? yearDiff === 1
            ? currentYear
            : currentYear - (yearDiff - 1)
         : currentYear - yearDiff;
   // above logic is for case handling when year difference is (1) one year
   // then expected range [Jan 2020 - Dec 2020],
   // but bcoz of old logic `const startYear = moment(new Date()).month(currentMonth + 1).year(currentYear - yearDiff);` ===>>> getting [Jan 2019 - Dec 2020]
   // bcoz  `currentYear - yearDiff` gives 2020 - 1 = 2019 .. thus [Jan 2019 - Dec 2020], so handled by
   // `let expectedYear = (currentMonth + 1) === 12 ? ((yearDiff === 1) ? currentYear : (currentYear - (yearDiff - 1))) : (currentYear - yearDiff);`

   // console.log('expectedYear==> ', expectedYear);

   const startYear = moment(new Date())
      .month(currentMonth + 1)
      .year(expectedYear);
   const endYear = moment(new Date());

   // console.log(startYear.format('MMM YYYY'), endYear.format('MMM YYYY'));

   const monthList = [];

   while (
      endYear > startYear ||
      startYear.format("M") === endYear.format("M")
   ) {
      monthList.push({ d: startYear.format("MMM YYYY"), v: 0 });
      startYear.add(1, "month");
   }

   return monthList;
};

// performanceImpact/Summary stats calc (portfolio) [used in both current/enhanced]
export const getUploadedPortfolioStats = (
   currentP,
   query,
   portfolioValue,
   portfolioBenchmark = {}
) => {
   const currentPf = JSON.parse(JSON.stringify(currentP));
   // let portfolioSumValue = 0.0,
   //    feeSum = 0.0,
     let portfolioSumExpenseRatioValue = 0.0,
      portfolioExpenseRatio = 0.0,
      // eslint-disable-next-line no-unused-vars
      weightSum = 0.0;
   let yieldSum = 0.0,
      outperformSum = 0.0,
      trackRecord = 0.0;
   const currentYear = moment(new Date()).year();

   // start pf ret calc
   let pfRet1y = getMonthFromYearDiff(1);
   let pfRet3y = getMonthFromYearDiff(3);
   let pfRet5y = getMonthFromYearDiff(5);
   let pfRet10y = getMonthFromYearDiff(10);
   let historical = {};
   historical.pfRet1y = getMonthFromYearDiff(1);
   historical.pfRet2y = getMonthFromYearDiff(1, currentYear - 1);
   historical.pfRet3y = getMonthFromYearDiff(1, currentYear - 2);
   historical.pfRet4y = getMonthFromYearDiff(1, currentYear - 3);
   historical.pfRet5y = getMonthFromYearDiff(1, currentYear - 4);
   historical.pfRet6y = getMonthFromYearDiff(1, currentYear - 5);
   historical.pfRet7y = getMonthFromYearDiff(1, currentYear - 6);
   historical.pfRet8y = getMonthFromYearDiff(1, currentYear - 7);
   historical.pfRet9y = getMonthFromYearDiff(1, currentYear - 8);
   historical.pfRet10y = getMonthFromYearDiff(1, currentYear - 9);

   currentPf.forEach((e, index) => {
      portfolioSumExpenseRatioValue = e.fees;
      //calculate pf yield
      if (e["attribute/fundamental/yield"])
         yieldSum += e["attribute/fundamental/yield"] || e["yield"];
      // yieldSum += e._realTimePrice * qty * e['attribute/fundamental/yield']

      //calculate pf outperform
      if (e["outperform"]) outperformSum += e["outperform"] || 0.0;
      // outperformSum += e._realTimePrice * qty * (e['outperform'] || 0.0)

      //calculate pf trackRecord
      if (e["track_record"])
         //e['attribute/track_record']
         trackRecord += e["track_record"]; // e['attribute/track_record']
      // trackRecord += e._realTimePrice * qty * e['attribute/track_record']

      let returnsArr = e.returns_data ? e.returns_data : e.returns;
      if (returnsArr && returnsArr.length > 0) {
         let start_dt = moment(
            new Date(`01 ${returnsArr[returnsArr.length - 1].d}`)
         );
         let end_dt = moment(new Date(`01 ${returnsArr[0].d}`));

         //check to reverse the returnsArr or not
         if (start_dt.isAfter(end_dt)) {
            returnsArr = returnsArr.reverse();
            start_dt = moment(
               new Date(`01 ${returnsArr[returnsArr.length - 1].d}`)
            );
            end_dt = moment(new Date(`01 ${returnsArr[0].d}`));
         }

         const start = start_dt;
         const end = end_dt;

         const DateRangeMoment = { start, end };

         const calculatePoints = (ret, year, startFrom) => {
            let returns = JSON.parse(JSON.stringify(ret));
            const range = {
               start: nameToDateRange(year, DateRangeMoment, startFrom).start
                  ._d,
               end: nameToDateRange(year, DateRangeMoment, startFrom).end._d,
            };
            // console.log(range);
            return returns.filter(e => {
               let dt = monthToDate(e.d);
               return (
                  dt.isAfter(moment(range.start).subtract(1, "seconds")) &&
                  dt.isBefore(moment(range.end).add(1, "seconds"))
               );
            });
         };

         currentPf[index].points1y = calculatePoints(returnsArr, "l1y");
         currentPf[index].points3y = calculatePoints(returnsArr, "l3y");
         currentPf[index].points5y = calculatePoints(returnsArr, "l5y");
         currentPf[index].points10y = calculatePoints(returnsArr, "l10y");
         currentPf[index].pointsHistorical = {};
         currentPf[index].pointsHistorical.points1y = calculatePoints(
            returnsArr,
            "l10y"
         );
         currentPf[index].pointsHistorical.points2y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 1
         );
         currentPf[index].pointsHistorical.points3y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 2
         );
         currentPf[index].pointsHistorical.points4y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 3
         );
         currentPf[index].pointsHistorical.points5y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 4
         );
         currentPf[index].pointsHistorical.points6y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 5
         );
         currentPf[index].pointsHistorical.points7y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 6
         );
         currentPf[index].pointsHistorical.points8y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 7
         );
         currentPf[index].pointsHistorical.points9y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 8
         );
         currentPf[index].pointsHistorical.points10y = calculatePoints(
            returnsArr,
            "l10y",
            currentYear - 9
         );
         currentPf[index].pfWeight = e.weight;
         weightSum += currentPf[index].pfWeight;
         currentPf[index].retWeighted1y = [];
         currentPf[index].retWeighted3y = [];
         currentPf[index].retWeighted5y = [];
         currentPf[index].retWeighted10y = [];
         currentPf[index].retWeightedHistorical = {
            retWeighted1y: [],
            retWeighted2y: [],
            retWeighted3y: [],
            retWeighted4y: [],
            retWeighted5y: [],
            retWeighted6y: [],
            retWeighted7y: [],
            retWeighted8y: [],
            retWeighted9y: [],
            retWeighted10y: [],
         };

         e.points1y.forEach((el, index1) => {
            el.v = el.v * 100; //multiplied by 100 to negate the divide by 100 for charts
            currentPf[index].retWeighted1y.push(el);
         });

         e.points3y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeighted3y.push(el);
         });

         e.points5y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeighted5y.push(el);
         });

         e.points10y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeighted10y.push(el);
         });

         e.pointsHistorical.points1y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted1y.push(el);
         });

         e.pointsHistorical.points2y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted2y.push(el);
         });

         e.pointsHistorical.points3y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted3y.push(el);
         });

         e.pointsHistorical.points4y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted4y.push(el);
         });

         e.pointsHistorical.points5y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted5y.push(el);
         });

         e.pointsHistorical.points6y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted6y.push(el);
         });

         e.pointsHistorical.points7y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted7y.push(el);
         });

         e.pointsHistorical.points8y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted8y.push(el);
         });

         e.pointsHistorical.points9y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted9y.push(el);
         });

         e.pointsHistorical.points10y.forEach((el, index1) => {
            el.v = el.v * 100;
            currentPf[index].retWeightedHistorical.retWeighted10y.push(el);
         });
      }
   });
   // end for each portfolio

   // start pf ret calc
   // let outd1 = { n: 0, dMax: 0.0 },
     let cuml1 = 0.0;
   pfRet1y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.points1y &&
            e.points1y.forEach((el1, index11) => {
               if (pfRet1y[index1].d === el1.d) {
                  pfRet1y[index1].v += el1.v;
               }
            });
      });
      // pfRet1y[index1].v = pfRet1y[index1].v;
      //calculate drawdown
      let r = pfRet1y[index1].v / 100;
      cuml1 = (1 + cuml1) * (1 + r) - 1;
   });

   let outd = { n: 0, dMax: 0.0 },
      cuml = 0.0;
   pfRet3y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.points3y &&
            e.points3y.forEach((el1, index11) => {
               if (pfRet3y[index1].d === el1.d) {
                  pfRet3y[index1].v += el1.v;
               }
            });
      });
      // pfRet3y[index1].v = pfRet3y[index1].v;
      //calculate drawdown
      let r = pfRet3y[index1].v / 100;
      cuml = (1 + cuml) * (1 + r) - 1;
      outd.n += 1;
      if (outd.n === 1) {
         outd.cumlMax = cuml;
      } else {
         cuml > outd.cumlMax && (outd.cumlMax = cuml);
         let dmax = (outd.cumlMax - cuml) / (1 + outd.cumlMax);
         dmax > outd.dMax && (outd.dMax = dmax);
      }
   });

   // let outd5 = { n: 0, dMax: 0.0 },
     let cuml5 = 0.0;
   pfRet5y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.points5y &&
            e.points5y.forEach((el1, index11) => {
               if (pfRet5y[index1].d === el1.d) {
                  pfRet5y[index1].v += el1.v;
               }
            });
      });
      // pfRet5y[index1].v = pfRet5y[index1].v;
      //calculate drawdown
      let r = pfRet5y[index1].v / 100;
      cuml5 = (1 + cuml5) * (1 + r) - 1;
   });

   pfRet10y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.points10y &&
            e.points10y.forEach((el1, index11) => {
               if (pfRet10y[index1].d === el1.d) {
                  pfRet10y[index1].v += el1.v;
               }
            });
      });
      // pfRet10y[index1].v = pfRet10y[index1].v;
   });

   historical.pfRet1y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points1y &&
            e.pointsHistorical.points1y.forEach((el1, index11) => {
               if (historical.pfRet1y[index1].d === el1.d) {
                  historical.pfRet1y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet1y[index1].v = historical.pfRet1y[index1].v;
   });

   historical.pfRet2y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points2y &&
            e.pointsHistorical.points2y.forEach((el1, index11) => {
               if (historical.pfRet2y[index1].d === el1.d) {
                  historical.pfRet2y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet2y[index1].v = historical.pfRet2y[index1].v;
   });

   historical.pfRet3y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points3y &&
            e.pointsHistorical.points3y.forEach((el1, index11) => {
               if (historical.pfRet3y[index1].d === el1.d) {
                  historical.pfRet3y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet3y[index1].v = historical.pfRet3y[index1].v;
   });

   historical.pfRet4y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points4y &&
            e.pointsHistorical.points4y.forEach((el1, index11) => {
               if (historical.pfRet4y[index1].d === el1.d) {
                  historical.pfRet4y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet4y[index1].v = historical.pfRet4y[index1].v;
   });

   historical.pfRet5y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points5y &&
            e.pointsHistorical.points5y.forEach((el1, index11) => {
               if (historical.pfRet5y[index1].d === el1.d) {
                  historical.pfRet5y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet5y[index1].v = historical.pfRet5y[index1].v;
   });

   historical.pfRet6y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points6y &&
            e.pointsHistorical.points6y.forEach((el1, index11) => {
               if (historical.pfRet6y[index1].d === el1.d) {
                  historical.pfRet6y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet6y[index1].v = historical.pfRet6y[index1].v;
   });

   historical.pfRet7y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points7y &&
            e.pointsHistorical.points7y.forEach((el1, index11) => {
               if (historical.pfRet7y[index1].d === el1.d) {
                  historical.pfRet7y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet7y[index1].v = historical.pfRet7y[index1].v;
   });

   historical.pfRet8y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points8y &&
            e.pointsHistorical.points8y.forEach((el1, index11) => {
               if (historical.pfRet8y[index1].d === el1.d) {
                  historical.pfRet8y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet8y[index1].v = historical.pfRet8y[index1].v;
   });

   historical.pfRet9y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points9y &&
            e.pointsHistorical.points9y.forEach((el1, index11) => {
               if (historical.pfRet9y[index1].d === el1.d) {
                  historical.pfRet9y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet9y[index1].v = historical.pfRet9y[index1].v;
   });

   historical.pfRet10y.forEach((el, index1) => {
      currentPf.forEach((e, index2) => {
         e.pointsHistorical &&
            e.pointsHistorical.points10y &&
            e.pointsHistorical.points10y.forEach((el1, index11) => {
               if (historical.pfRet10y[index1].d === el1.d) {
                  historical.pfRet10y[index1].v += el1.v;
               }
            });
      });
      // historical.pfRet10y[index1].v = historical.pfRet10y[index1].v;
   });

   // portfolioExpenseRatio = portfolioSumExpenseRatioValue/portfolioSumValue;
   portfolioExpenseRatio = portfolioSumExpenseRatioValue;

   // let annualizedRet1y = getAnnualizedReturnsByYears(pfRet1y);
   // let annualizedRet3y = getAnnualizedReturnsByYears(pfRet3y);
   // let annualizedRet5y = getAnnualizedReturnsByYears(pfRet5y);
   let annualizedRet10y = getAnnualizedReturnsByYears(pfRet10y);

   // let annualizedVolt1y = getAnnualizedVolatilityByYears(pfRet1y);
   // let annualizedVolt3y = getAnnualizedVolatilityByYears(pfRet3y);
   // let annualizedVolt5y = getAnnualizedVolatilityByYears(pfRet5y);
   let annualizedVolt10y = getAnnualizedVolatilityByYears(pfRet10y);

   let annualizedRetHistorical = [];
   annualizedRetHistorical.push({
      key: "annualizedRet1y",
      value: getAnnualizedReturnsByYears(historical.pfRet1y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet2y",
      value: getAnnualizedReturnsByYears(historical.pfRet2y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet3y",
      value: getAnnualizedReturnsByYears(historical.pfRet3y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet4y",
      value: getAnnualizedReturnsByYears(historical.pfRet4y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet5y",
      value: getAnnualizedReturnsByYears(historical.pfRet5y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet6y",
      value: getAnnualizedReturnsByYears(historical.pfRet6y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet7y",
      value: getAnnualizedReturnsByYears(historical.pfRet7y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet8y",
      value: getAnnualizedReturnsByYears(historical.pfRet8y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet9y",
      value: getAnnualizedReturnsByYears(historical.pfRet9y),
   });
   annualizedRetHistorical.push({
      key: "annualizedRet10y",
      value: getAnnualizedReturnsByYears(historical.pfRet10y),
   });

   let annualizedVolHistorical = [
      getAnnualizedVolatilityByYears(historical.pfRet1y),
      getAnnualizedVolatilityByYears(historical.pfRet2y),
      getAnnualizedVolatilityByYears(historical.pfRet3y),
      getAnnualizedVolatilityByYears(historical.pfRet4y),
      getAnnualizedVolatilityByYears(historical.pfRet5y),
      getAnnualizedVolatilityByYears(historical.pfRet6y),
      getAnnualizedVolatilityByYears(historical.pfRet7y),
      getAnnualizedVolatilityByYears(historical.pfRet8y),
      getAnnualizedVolatilityByYears(historical.pfRet9y),
      getAnnualizedVolatilityByYears(historical.pfRet10y),
   ];
   // eslint-disable-next-line no-unused-vars
   let avgHis = 0.0
     let sumAvgHis = 0.0,
      nHis = 0.0,
      stdArr = [];
   annualizedRetHistorical.forEach((item, index) => {
      nHis += 1;
      item.value =
         query === "similar" ? item.value - portfolioExpenseRatio : item.value;
      stdArr.push(item.value);
      sumAvgHis += item.value;
   });
   if (nHis > 0) avgHis = sumAvgHis / nHis;
   let stdev = std(stdArr);
   let stdevVol = std(annualizedVolHistorical);

   let up_1yr, down_1yr, up_3yr, down_3yr, up_5yr, down_5yr;
   // let portfolioBenchmark1 = JSON.parse(JSON.stringify(portfolioBenchmark)).reverse();
   const __ret = geopMeanCalc(portfolioBenchmark, pfRet1y, pfRet3y, pfRet5y);
   if (__ret) {
      up_1yr = __ret.up_1yr;
      down_1yr = __ret.down_1yr;
      up_3yr = __ret.up_3yr;
      down_3yr = __ret.down_3yr;
      up_5yr = __ret.up_5yr;
      down_5yr = __ret.down_5yr;
   }

   // debugger
   // let sharpe_ratio_3y = parseFloat((annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2));
   // let sharpe_pfRet1y  = parseFloat((annualizedRet1y/annualizedVolt1y).toFixed(2) == NaN ? 0.00 : (annualizedRet1y/annualizedVolt1y).toFixed(2));
   // let sharpe_pfRet3y  = parseFloat((annualizedRet3y/annualizedVolt3y).toFixed(2) == NaN ? 0.00 : (annualizedRet3y/annualizedVolt3y).toFixed(2));
   // let sharpe_pfRet5y  = parseFloat((annualizedRet5y/annualizedVolt5y).toFixed(2) == NaN ? 0.00 : (annualizedRet5y/annualizedVolt5y).toFixed(2));

   let uploadedPortFolioObj = currentP.length > 0 ? currentP[0] : null;
   let showAsteriskFor = [];

   let getValueForKey = (key, year, multiplier) => {
      if (key && year && uploadedPortFolioObj && multiplier) {
         if (
            typeof uploadedPortFolioObj[`${key}_${year}`] === "undefined" ||
            uploadedPortFolioObj[`${key}_${year}`] === null
         ) {
            if (year !== "10y") {
               showAsteriskFor = [...showAsteriskFor, { [`${key}`]: year }];
            }
            if (
               typeof uploadedPortFolioObj[`${key}_${year}_ui`] ===
                  "undefined" ||
               uploadedPortFolioObj[`${key}_${year}_ui`] === null
            ) {
               return null;
            }
            // console.log(`${key}_${year}_ui`, uploadedPortFolioObj[`${key}_${year}_ui`]);
            return uploadedPortFolioObj[`${key}_${year}_ui`];
         } else {
            // console.log(`${key}_${year}`, uploadedPortFolioObj[`${key}_${year}`]);
            return uploadedPortFolioObj[`${key}_${year}`] * multiplier;
         }
      } else {
         return null;
      }
   };

   let out = {
      pfRet1y,
      pfRet3y,
      pfRet5y,
      pfRet10y,
      stdev: stdev.toFixed(2),
      stdevVol: stdevVol.toFixed(2),
      asset_alloc:
         typeof currentPf[0].asset_alloc !== "undefined"
            ? currentPf[0].asset_alloc.length > 0
               ? currentPf[0].asset_alloc[0]
               : currentPf[0].asset_alloc
            : null,
      fee:
         portfolioSumExpenseRatioValue != null || portfolioValue != null
            ? parseFloat(
                 (
                    (portfolioValue * portfolioSumExpenseRatioValue) /
                    100
                 ).toFixed(2)
              )
            : "--",
      expenseRatio: numberCheck(portfolioExpenseRatio, 2)
         ? numberCheck(portfolioExpenseRatio, 2)
         : 0, // parseFloat(portfolioExpenseRatio.toFixed(2)) : 0,

      anRet1yr:
         getValueForKey("returns", "1y", 100) !== null
            ? numberCheck(getValueForKey("returns", "1y", 100), 2)
            : "--", // parseFloat(getAnnualizedReturnsByYears(pfRet1y).toFixed(2)),
      anRet3yr:
         getValueForKey("returns", "3y", 100) !== null
            ? numberCheck(getValueForKey("returns", "3y", 100), 2)
            : "--", // parseFloat(annualizedRet3y.toFixed(2)),
      anRet5yr:
         getValueForKey("returns", "5y", 100) !== null
            ? numberCheck(getValueForKey("returns", "5y", 100), 2)
            : "--", // parseFloat(getAnnualizedReturnsByYears(pfRet5y).toFixed(2)),
      anRet10yr:
         getValueForKey("returns", "10y", 100) !== null
            ? numberCheck(getValueForKey("returns", "10y", 100), 2)
            : "--", // parseFloat(annualizedRet10y.toFixed(2)),

      anRet1yr_report:
         getValueForKey("returns", "1y", 100) !== null
            ? numberCheck(getValueForKey("returns", "1y", 100), "all")
            : "--",
      anRet3yr_report:
         getValueForKey("returns", "3y", 100) !== null
            ? numberCheck(getValueForKey("returns", "3y", 100), "all")
            : "--",
      anRet5yr_report:
         getValueForKey("returns", "5y", 100) !== null
            ? numberCheck(getValueForKey("returns", "5y", 100), "all")
            : "--",
      anRet10yr_report:
         getValueForKey("returns", "10y", 100) !== null
            ? numberCheck(getValueForKey("returns", "10y", 100), "all")
            : "--",

      anVol1yr:
         getValueForKey("volatility", "1y", 100) !== null
            ? numberCheck(getValueForKey("volatility", "1y", 100), 2)
            : "--", // parseFloat(getAnnualizedVolatilityByYears(pfRet1y).toFixed(2)),
      anVol3yr:
         getValueForKey("volatility", "3y", 100) !== null
            ? numberCheck(getValueForKey("volatility", "3y", 100), 2)
            : "--", // parseFloat(annualizedVolt3y.toFixed(2)),
      anVol5yr:
         getValueForKey("volatility", "5y", 100) !== null
            ? numberCheck(getValueForKey("volatility", "5y", 100), 2)
            : "--", // parseFloat(getAnnualizedVolatilityByYears(pfRet5y).toFixed(2)),
      anVol10yr:
         getValueForKey("volatility", "10y", 100) !== null
            ? numberCheck(getValueForKey("volatility", "10y", 100), 2)
            : "--", // parseFloat(annualizedVolt10y.toFixed(2)),

      dMax:
         getValueForKey("drawdown", "3y", 100) !== null
            ? numberCheck(getValueForKey("drawdown", "3y", 100), 2)
            : "--", // parseFloat((outd.dMax*100).toFixed(2)),
      dMax_pfRet1y:
         getValueForKey("drawdown", "1y", 100) !== null
            ? numberCheck(getValueForKey("drawdown", "1y", 100), 2)
            : "--",
      dMax_pfRet3y:
         getValueForKey("drawdown", "3y", 100) !== null
            ? numberCheck(getValueForKey("drawdown", "3y", 100), 2)
            : "--",
      dMax_pfRet5y:
         getValueForKey("drawdown", "5y", 100) !== null
            ? numberCheck(getValueForKey("drawdown", "5y", 100), 2)
            : "--",

      sharpe_ratio_3y:
         getValueForKey("sharpe", "3y", 1) !== null
            ? numberCheck(getValueForKey("sharpe", "3y", 1), 2)
            : "--", // isFinite(sharpe_ratio_3y) ? sharpe_ratio_3y : 0,
      sharpe_pfRet1y:
         getValueForKey("sharpe", "1y", 1) !== null
            ? numberCheck(getValueForKey("sharpe", "1y", 1), 2)
            : "--", // isFinite(sharpe_pfRet1y)  ? sharpe_pfRet1y  : 0,
      sharpe_pfRet3y:
         getValueForKey("sharpe", "3y", 1) !== null
            ? numberCheck(getValueForKey("sharpe", "3y", 1), 2)
            : "--", // isFinite(sharpe_pfRet3y)  ? sharpe_pfRet3y  : 0,
      sharpe_pfRet5y:
         getValueForKey("sharpe", "5y", 1) !== null
            ? numberCheck(getValueForKey("sharpe", "5y", 1), 2)
            : "--", // isFinite(sharpe_pfRet5y)  ? sharpe_pfRet5y  : 0,

      cuml_pfRet1y:
         getValueForKey("cumulative_returns", "1y", 100) !== null
            ? numberCheck(getValueForKey("cumulative_returns", "1y", 100), 2)
            : "--", // cuml1*100,
      cuml_pfRet3y:
         getValueForKey("cumulative_returns", "3y", 100) !== null
            ? numberCheck(getValueForKey("cumulative_returns", "3y", 100), 2)
            : "--", // cuml*100,
      cuml_pfRet5y:
         getValueForKey("cumulative_returns", "5y", 100) !== null
            ? numberCheck(getValueForKey("cumulative_returns", "5y", 100), 2)
            : "--", // cuml5*100,

      minus_1_sd_10yr: parseFloat(annualizedRet10y + -1 * annualizedVolt10y),
      plus_1_sd_10yr: parseFloat(annualizedRet10y + annualizedVolt10y * 1),

      yield: parseFloat((yieldSum * 100).toFixed(2)),
      outperform: parseFloat((outperformSum * 100).toFixed(2)),
      trackRecord: parseFloat(trackRecord.toFixed(2)),

      up_1yr,
      down_1yr,
      up_3yr,
      down_3yr,
      up_5yr,
      down_5yr,
      cuml1,
      cuml3: cuml,
      cuml5,
      showAsteriskFor,
   };

   return out;
};

export const getDrawdownByYears = retArray => {
   let out = { n: 0, dMax: 0.0 },
      // eslint-disable-next-line no-unused-vars
      sum = 0.0,
      cuml = 0.0;
   let clone = _.cloneDeep(retArray);
   clone.forEach(d => {
      d.v = d.v / 100;
      cuml = (1 + cuml) * (1 + d.v) - 1;
      sum += d.v;
      out.n += 1;
      if (out.n === 1) {
         out.cumlMax = cuml;
      } else {
         cuml > out.cumlMax && (out.cumlMax = cuml);
         let dmax = (out.cumlMax - cuml) / (1 + out.cumlMax);
         dmax > out.dMax && (out.dMax = dmax);
      }
   });
   out.dMax = out.dMax * 100; // negate???
   return out;
};

export default {
   dateSort,
};
