import React from "react";

export default function FieldValue(props) {
   if (typeof props.dashIfEmpty === "boolean") {
      return (
         <p className="field-value">{props.children ? props.children : "-"}</p>
      );
   } else if (typeof props.dashIfEmpty === "string") {
      return (
         <p className="field-value">
            {props.children ? props.children : props.dashIfEmpty}
         </p>
      );
   }
   return <p className="field-value">{props.children}</p>;
}
