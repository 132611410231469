import { takeEvery, fork, call, put, all, select } from "redux-saga/effects";
import get from "lodash/get";

import ActionTypes from "action-types";
import {
   getTickerListFetch,
   getTickerListSuccess,
   getGlobalMarketStateFetch,
   getGlobalMarketStateSuccess,
} from "actions/global";

import { openNotificationWithIcon } from "layouts/utils";
import { getAllInternationalTickersApi } from "apis/enhancerAPIs";
import { AFS_COUNTRY_LIST } from "constants/global";

function* getAllTickerListData(action) {
   try {
      yield put(getTickerListFetch());
      let result = yield call(getAllInternationalTickersApi);
      if (result) {
         result.forEach((kl, i) => {
            if (kl && kl.ticker) {
               kl.label = kl.ticker;
               kl.value = kl.ticker;
            }
         });
         yield put(
            getTickerListSuccess({
               magnifiTickers: result,
            })
         );
      } else {
         yield put(getTickerListSuccess([]));
      }
   } catch (error) {
      console.log(error);
      yield put(getTickerListSuccess([]));
   }
}

function* checkGlobalMarket(action) {
   const { payload, callback } = action;
   const globalRegionsState = yield select(state => state.global.regions);
   try {
      if (payload && payload.market) {
         const foundRegion = globalRegionsState.find(
            region => region.name.toLowerCase() === payload.market.toLowerCase()
         ) || { code: "USA" };
         yield put(getGlobalMarketStateFetch());
         // let response = yield call(changeRegion, payload);
         // if (response && (typeof response.Message !== 'undefined' || typeof response.message !== 'undefined')) {
         //   if (response.Message === 'Region not changed' || response.message === 'Region not changed') {
         //     throw `-- Failed to switch ${payload.market} region --`
         //   } else {
         // TODO: Rmove this after BE api is ready
         yield put(
            getGlobalMarketStateSuccess({
               globalMarket: foundRegion.code,
            })
         );
         window.sessionStorage.setItem(
            "selectedGlobalMarket",
            foundRegion.code
         );
         // }
         // } else {
         //   throw '-- Api failed for change-region request --'
         // }
         // } else {
         //   throw '-- Payload is missing for Change Region request --'
      } else if (payload.countryName) {
         const foundRegion = AFS_COUNTRY_LIST.find(
            region =>
               region.name.toLowerCase() === payload.countryName.toLowerCase()
         );
         foundRegion &&
            (yield put(
               getGlobalMarketStateSuccess({
                  globalMarket: foundRegion.continent,
               })
            ));
         window.sessionStorage.setItem(
            "selectedGlobalMarket",
            foundRegion.continent
         );
      }

      if (callback && typeof callback === "function") {
         callback();
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      const foundRegion = globalRegionsState.find(
         item => item.code === payload.market
      );
      const regionName =
         foundRegion && foundRegion.name ? foundRegion.name : payload.market;
      // NOTE: Toast a message on UI
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Something went wrong",
         className: "api-response-notification-class",
         description: `Failed to switch ${regionName} region.`,
      });
      yield put(getGlobalMarketStateSuccess());
   }
}

function* globalSaga() {
   yield all([
      fork(
         takeEvery,
         ActionTypes.GET_ALL_TICKER_LIST_REQUEST,
         getAllTickerListData
      ),
      fork(
         takeEvery,
         ActionTypes.GET_GLOBAL_MARKET_STATE_REQUEST,
         checkGlobalMarket
      ),
   ]);
}

export default globalSaga;
