import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { auth } from "./auth";
import { register } from "./register";
import { profile } from "./profile";
import { loading } from "./loading";
import { funds } from "./funds";
import { sfm } from "./sfm";
import { investment } from "./investment";
import { plugins } from "./plugins";
import { weights } from "./weights";
import { global } from "./global";
import { portfolio } from "./portfolio";
import { buildportfolio } from "./buildportfolio";
//import { clientportfolio } from './clientportfolio'
import { clientportfolioV2 } from "./clientportfolioV2";
import { videoplayer } from "./videoplayer";
import { reports } from "./reports";
import { enhancer } from "./enhancer";
import { accounts } from "./accounts";
import { discovery } from "./discovery";
import { community } from "./community";
import { quicktrade } from "./quicktrade";
import { globalModals } from "./globalModals";
import { trendingSearches } from "./trendingsearches";
import { alerts } from "./alerts";
import {
   broker,
   fetchBrokerAccountReducer,
   importBrokerAccountReducer,
   deleteImportedAccountReducer,
   fetchImportedAccountReducer,
   setDefaultAccountReducer,
   tdameritradeReducer,
   tradeItSecurityHandlingReducer,
} from "./broker";

export default history =>
   combineReducers({
      auth,
      register,
      profile,
      loading,
      globalModals,
      funds,
      sfm,
      investment,
      broker,
      plugins,
      weights,
      global,
      portfolio,
      community,
      buildportfolio,
      clientportfolioV2,
      //clientportfolio,
      videoplayer,
      reports,
      enhancer,
      accounts,
      discovery,
      quicktrade,
      importedAccounts: fetchImportedAccountReducer,
      fetchBrokerAccount: fetchBrokerAccountReducer,
      deleteImportAccounts: deleteImportedAccountReducer,
      importBrokerAccount: importBrokerAccountReducer,
      tdameritradeBroker: tdameritradeReducer,
      setDefaultAccount: setDefaultAccountReducer,
      tradeItSecurityHandling: tradeItSecurityHandlingReducer,
      router: connectRouter(history),
      trendingSearches,
      alerts,
   });
