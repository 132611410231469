import { createAPIInstance } from "./api";

export function downloadPortfolioReportPDF(payload) {
   return createAPIInstance()
      .post("enhancement-print-report", payload, {
         // .post('print-portfolio-recommendation', JSON.stringify(payload), {
         responseType: "blob",
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res);
}

export function downloadRecEnhancerReportPDF(payload) {
   return createAPIInstance()
      .post("/print-rec-enhancer-report", payload, {
         responseType: "blob",
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res);
}

export function fetchFundByTicker(ticker) {
   return createAPIInstance()
      .get(`user/account-performance?ticker=${ticker}`)
      .then(res => res.data);
}

export function fetchAccountSummary(payload) {
   return createAPIInstance()
      .get("user/account/summary", payload)
      .then(response => response.data);
}

export function fetchAccountPositions(payload) {
   return createAPIInstance()
      .get("user/account/positions", payload)
      .then(response => response.data);
}

export function fetchPortfolioList(payload) {
   return createAPIInstance()
      .get("user/portfolio-upload-list", payload)
      .then(response => response.data);
}

export function fetchMarketPlaceList(payload) {
   return createAPIInstance()
      .get("model-portfolio-list", payload)
      .then(response => response.data);
}

// export function uploadPortfolioAPI(payload) {
//   return createAPIInstance()
//     .post('user/portfolio-upload', payload, {
//       headers: { 'Content-Type': 'multipart/form-data' }
//     })
//     .then(res => res.data)
// }

//v1/user/portfolio-upload
export function uploadPortfolioAPI(payload) {
   return createAPIInstance()
      .post("v1/user/portfolio-upload", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res.data);
}

export function getPortfolioResultAPI(payload) {
   return createAPIInstance()
      .get(`user/upload-portfolio-result`, { params: payload })
      .then(res => res.data);
}

export function getMarketResultAPI(name) {
   return createAPIInstance()
      .get(`model-portfolio-details?ticker=${encodeURIComponent(name)}`)
      .then(res => res.data);
}

export function deletePortfolioAPI(name) {
   return createAPIInstance()
      .delete(`user/delete-portfolio?file_name=${encodeURIComponent(name)}`)
      .then(res => res.data);
}

export function demoPortfolioAPI(payload) {
   return createAPIInstance()
      .post("/demo-portfolio", payload, {
         headers: { "Content-Type": "multipart/form-data" },
      })
      .then(res => res.data);
}

// old -> user/client-list
// new -> v1/user/client-list?skip_all_data=true
export function getClientList() {
   return createAPIInstance()
      .get(`v1/user/client-list?skip_all_data=true`)
      .then(res => res.data);
}

export function getHomeOfficeModelsData() {
   // return createAPIInstance()
   //   .get(`user/client-list`)
   //   .then(res => res.data)
}

export function fetchAlertCount(req) {
   return createAPIInstance().get(`user/apex/get/notification?${req}`);
}

export function fetchDailyReturns(payload) {
   return createAPIInstance()
      .get("/user/daily-returns", { params: payload })
      .then(res => res.data);
}

export function getPortfolioGains(payload) {
   return createAPIInstance()
      .get("/user/portfolio-gains", { params: payload })
      .then(res => res.data);
}
