const devConfig = {
   apiBaseUrl: "https://dev.magnifi.com/api",
   tdAmeritradeCallbackUrl:
      "https://auth.tdameritrade.com/oauth?client_id=LOCALTD5@AMER.OAUTHAP&response_type=code&redirect_uri=https://test.opensesafi.com/callbacks/tdameritrade/td-auth-code",
   tradeItOrigin: "https://www.trade.it",
   tradingTicketorigin: "https://ems.tradingticket.com",
   googleAnalyticsCode: "AIzaSyC2_hXo2574jMnsCi2FKJZLbT2LSInreac",
   googleTaManagerCode: "UA-153849204-1",
   googleGTAGUAConfig: {},
   universeShare: true,
   heapAnalyticsCode: "4262087573",
   krakenId: "5df2a89236c054a5e077c7e5",
   relatedInsight: true,
   impressionTag: true,
   tdCallbackPath: "/callbacks/tdameritrade",
   sponsoredTicker: true,
   PtarmiganTag: false,
   advisorRetailRedirect: true,
   advisor_redirect: "https://devadvisor.magnifi.com",
   retail_redirect: "https://dev2.retail.magnifi.com",
   stripePublishableKey: "pk_test_bnUyP0ez8RUw3uggjKjjXZDR008XvxROs0",
   version: "2.0.0",
   sentryEnv: "dev",
   appleSSORedirectURL: "https://dev2.magnifi.com/register",
   transferRequest: true,
   googleManagerTag: false,
   vendorConversionPixel: false,
   REACT_APP_OKTA_IDP: "0oa1k4ywmmhmfXmOT697",
   REACT_APP_OKTA_ISSUER: "magnifi-dev.okta.com", // 'dev.auth.tifinwealth.com',
   REACT_APP_OKTA_CLIENT_ID: "0oa1k4uyv06twnAW8697", // '0oa5gieiczjZLXlnd5d7',
   feedbackGooglesheetKey:
      "https://script.google.com/macros/s/AKfycbyqfUvOcJ162PlpBkjGGuLOG6Xz175WwqzhC-qsW8Ud7CgWpDsd/exec",
   avatarURL: "https://avatar.magnifi.com/",
   googleClientId:
      "444564269788-i2f23dh0svn4jb8ckuevjvbh9067p8kc.apps.googleusercontent.com",
   // segmentWriteKey: '5vRMXTEdWuUtvQRs5gjSyqADqM1BcEVK',
};

export default devConfig;
