import ActionTypes from "../action-types";

// add state which will be refreshed on component unmount
const initialState = {
   loading: false,
   uploadedClientList: [],
   showUpdateColumnInPreview: false,
   searchAccTickerIndex: 0,
   initClientList: [],
   clientList: [],
   clientListLoading: false,
   clientsfilteredColumn: "",
   clientsfilteredText: "",
   clientsClearAllFilter: false,
   selectedClientDetails: [],
   selectedClientHoldings: [],
   customPortfolioList: [],
   sponsoredPortfolioList: [],
   finalizeObject: [],
   finalizeAllocationSuccess: "",
   downloadTradelist: [],
   accSearchQuery: "",
   query_var: {},
   clientChartDetails: "",
   searchResultsData: "",
   modelSearchQuery: "",
   modelSearchResults: [],
   noSearchResults: false,
   clientSearchStatus: true,
   optmizeTaxBills: "",
   singleClientUploaded: [],
   downloadedList: {
      data: [],
      query: "",
      pagination: {
         count: 20,
         totalListData: 0,
         totalPageNumber: 0,
         currentPageNumber: 0,
      },
      filter: {
         key: "",
         query: "",
      },
      isSearchQueryBased: false,
      searchResultTotalAssets: 0,
   },
   totalAssets: 0,
   totalAccounts: 0,
};

export function clientportfolioV2(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.UPLOAD_CLIENT_LIST_SUCCESS:
         return {
            ...state,
            // Once consistency is maintained, structure it properly
            ...payload,
         };
      case ActionTypes.CLIENT_LIST_LOADER:
         return {
            ...state,
            clientListLoading: true,
         };
      case ActionTypes.SET_INITIAL_CLIENT_LIST:
         return {
            ...state,
            initClientList: payload.initialClientList,
         };
      case ActionTypes.CLIENT_LIST_SUCCESS:
         return {
            ...state,
            //initialClientList: payload,
            clientList: payload,
            uploadedClientList: [],
            clientListLoading: false,
         };
      case ActionTypes.UPDATE_CLIENT_LIST_SUCCESS:
         return {
            ...state,
            clientList: payload,
            clientListLoading: false,
         };
      case ActionTypes.FILTER_CLIENT_LIST:
         let { downloadedList: _downloadedList, clientList: _clientList } =
            state;
         let { data: _downloadedListData, preserveData } = _downloadedList;

         // NOTE: before applying filter, preserve downloadedList
         if (typeof preserveData === "undefined") {
            preserveData = _downloadedListData;
            _clientList = _downloadedListData;
         }

         // NOTE: after resetting filter or when 'clientList' is empty, use 'preserveData' -> 'data'
         if (payload.clientsClearAllFilter) {
            _downloadedListData = preserveData;
         } else {
            _downloadedListData = payload.clientList || [];
            _clientList = payload.clientList || [];
         }

         return {
            ...state,
            downloadedList: {
               ..._downloadedList,
               preserveData,
               data: _downloadedListData,
            },
            clientList: _clientList,
            clientsfilteredColumn: payload.clientsfilteredColumn,
            clientsfilteredText: payload.clientsfilteredText,
            clientsClearAllFilter: payload.clientsClearAllFilter,
         };
      case ActionTypes.CHART_DETAIL_SUCCESS:
         return {
            ...state,
            clientChartDetails: payload,
         };
      case ActionTypes.CLIENT_DETAILS_SUCCESS:
         return {
            ...state,
            selectedClientDetails: payload,
         };
      case ActionTypes.CLIENT_HOLDINGS_SUCCESS:
         return {
            ...state,
            selectedClientHoldings: payload,
         };
      case ActionTypes.PORTFOLIO_OPTIONS_SUCCESS:
         return {
            ...state,
            customPortfolioList: payload[0],
            sponsoredPortfolioList: payload[1],
            upDownDataSet: payload[2],
         };
      case ActionTypes.CREATE_FINALIZE_OBJECT:
         return {
            ...state,
            finalizeObject: payload,
         };
      case ActionTypes.FINALIZE_ALLOCATION_SUCCESS:
         return {
            ...state,
            finalizeAllocationSuccess: payload.message,
         };
      case ActionTypes.GENERATE_TRADELIST_SUCCESS:
         return {
            ...state,
            downloadTradelist: payload,
         };
      case ActionTypes.SEARCH_ACCOUNT_FUNDS_SUCCESS:
         return {
            ...state,
            accSearchQuery: payload[0] || "",
            searchResultsData: payload[1] || "",
            clientSearchStatus: true,
            query_var: payload[1].query_var || {},
            clientListLoading: false,
         };
      case ActionTypes.SEARCH_ACCOUNT_FUNDS_SUCCESS_QUERY:
         return {
            ...state,
            searchResultsData: "",
            query_var: {},
            clientsfilteredText: "",
            accSearchQuery: "",
         };
      case ActionTypes.SEARCH_ACCOUNT_FUNDS_FAILURE:
         return {
            ...state,
            accSearchQuery: payload[0] || "",
            searchResultsData: "",
            query_var: {},
            clientSearchStatus: false,
            clientListLoading: false,
         };
      case ActionTypes.SEARCH_ACCOUNT_FUNDS_RESET:
         return {
            ...state,
            accSearchQuery: "",
            searchResultsData: "",
            query_var: {},
            clientSearchStatus: true,
            clientListLoading: false,
         };
      case ActionTypes.MODEL_SEARCH_RESULTS_SUCCESS:
         return {
            ...state,
            modelSearchQuery: payload[0] || "",
            modelSearchResults: payload[1] || "",
            noSearchResults: false,
         };
      case ActionTypes.MODEL_SEARCH_NO_RESULTS:
         return {
            ...state,
            noSearchResults: true,
         };
      case ActionTypes.TAX_BILLS_SUCCESS:
         return {
            ...state,
            optmizeTaxBills: payload,
         };
      case ActionTypes.UPLOAD_SINGLE_CLIENT_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.ACCOUNTPAGE_CLIENT_LIST_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.RESET_PAGINATION_AND_DOWNLOADED_LIST:
         return {
            ...state,
            downloadedList: {
               data: [],
               query: "",
               pagination: {
                  count: 20,
                  totalListData: 0,
                  totalPageNumber: 0,
                  currentPageNumber: 0,
               },
               filter: {
                  key: "",
                  query: "",
               },
               isSearchQueryBased: false,
               searchResultTotalAssets: 0,
               ...payload, // <- DO NOT MOVE THIS
            },
         };
      case ActionTypes.GET_CLIENT_LIST_BY_QUERY_FETCH:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_CLIENT_LIST_BY_QUERY_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.DOWNLOAD_MORE_DATA_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_SELECTED_CLIENT_DETAILS_FETCH:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_SELECTED_CLIENT_DETAILS_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.UPDATE_RESTRICTED_TICKERS_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.FILTER_CLIENT_LIST_SUCCESS:
         let { downloadedList: _DownLoadedList } = state;

         return {
            ...state,
            downloadedList: {
               ..._DownLoadedList,
               filter: payload.filter,
            },
            clientsfilteredColumn: payload.clientsfilteredColumn, // used in old-logic
            clientsfilteredText: payload.clientsfilteredText, // used in old-logic
            clientsClearAllFilter: payload.clientsClearAllFilter, // used in old-logic
         };

      case ActionTypes.RESET_FILTER_CLIENT_LIST_SUCCESS:
         let { downloadedList: _DList } = state;

         return {
            ...state,
            downloadedList: {
               ..._DList,
               filter: {
                  key: "",
                  query: "",
               },
            },
            _currentTotalAssets: 0,
            _currentTotalAccounts: 0,
            clientsfilteredColumn: "", // used in old-logic
            clientsfilteredText: "", // used in old-logic
            clientsClearAllFilter: false, // used in old-logic
         };

      case ActionTypes.SET_SEARCH_ACCOUNT_TICKER_INDEX:
         return {
            ...state,
            ...payload,
         };

      default:
         return state;
   }
}
