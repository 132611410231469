// import { createSelector } from 'reselect';

export const getFundsData = state => state.discovery.data;
export const getStocksData = state => state.discovery.stocks_data;

export const getOnlySponsoredFunds = discovery => {
   let { data } = discovery;
   if (data && typeof data !== "undefined") {
      return data.filter(kl => kl.is_sponsored);
   }
   return [];
};

export const getOnlyStockFunds = discovery => {
   let { stocks_data } = discovery;
   if (stocks_data && typeof stocks_data !== "undefined") {
      return stocks_data.filter(kl => kl.type === "SHARE");
   }
   return [];
};

// export const getSearchResultsFunds = createSelector(
//   [ getOnlySponsoredFunds, getOnlyStockFunds ],
//   ( data ) => {
//
// 		console.log('selector discovery funds --> ', data);
//
//   }
// )
