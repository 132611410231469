import { PREMIUM_ACCESS } from "DataSet";
import config from "env";
import { setCookie, deleteCookie, isDev } from "Utils";

export function getAuthToken() {
   var c = document.cookie.split("; ").reduce((prev, current) => {
      const [name, value] = current.split("=");
      prev[name] = value;
      return prev;
   }, {});
   const email = window.localStorage.getItem("email");
   const firstName = window.localStorage.getItem("firstName") || "";
   const lastName = window.localStorage.getItem("lastName") || "";
   var hostname = window.location.hostname
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .split(/[/?#]/)[0];

   if (!c.magnifi_session && hostname === "magnifi.com") {
      if (email && firstName && lastName)
         document.cookie =
            "magnifi_session=" +
            JSON.stringify({ email, firstName, lastName }) +
            ";domain=.magnifi.com;path=/;";
   }

   return {
      token: window.localStorage.getItem("token"),
      email: window.localStorage.getItem("email"),
      firstName: window.localStorage.getItem("firstName") || "",
      lastName: window.localStorage.getItem("lastName") || "",
      isGoogleLogin: window.localStorage.getItem("isGoogleLogin") === "true",
      isAppleLogin: window.localStorage.getItem("isAppleLogin") === "true",
      termsAgreed: parseInt(window.localStorage.getItem("termsAgreed")),
      self_role: window.localStorage.getItem("self_role") || "",
      client_exist: window.localStorage.getItem("client_exist") === "true",
      user_advisor_menu:
         window.localStorage.getItem("user_advisor_menu") === "true",
      restricted_universe:
         window.localStorage.getItem("restricted_universe") === "true",
      is_sharing_alpha_user:
         window.localStorage.getItem("is_sharing_alpha_user") === "true",
   };
}

export function isPremiumUser(data) {
   const email = data.email || window.localStorage.getItem("email");
   return PREMIUM_ACCESS.includes(email);
}

export function storeAuthToken({
   token,
   email,
   firstName = "",
   lastName = "",
   isGoogleLogin = false,
   isAppleLogin = false,
   termsAgreed = 0,
   self_role = "",
   client_exist,
   user_advisor_menu,
   profile_self_role,
   restricted_universe = false,
   is_sharing_alpha_user = false,
}) {
   const version = config.version || "0";
   window.localStorage.setItem("auth-type", "user");
   window.localStorage.setItem("token", token);
   window.localStorage.setItem("email", email);
   window.localStorage.setItem("firstName", firstName || "");
   window.localStorage.setItem("lastName", lastName || "");
   window.localStorage.setItem("isGoogleLogin", isGoogleLogin);
   window.localStorage.setItem("isAppleLogin", isAppleLogin);
   window.localStorage.setItem(
      "termsAgreed",
      is_sharing_alpha_user ? termsAgreed : 1
   );
   window.localStorage.setItem("self_role", self_role);
   window.localStorage.setItem("client_exist", client_exist);
   window.localStorage.setItem("user_advisor_menu", user_advisor_menu);
   window.localStorage.setItem("profile_self_role", profile_self_role);
   window.localStorage.setItem("version", version);
   window.localStorage.setItem("is_sharing_alpha_user", is_sharing_alpha_user);
   window.localStorage.setItem("restricted_universe", restricted_universe);

   let hostname = window.location.hostname
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .split(/[/?#]/)[0];
   if (hostname === "magnifi.com") {
      document.cookie =
         "magnifi_session=" +
         JSON.stringify({ email, firstName, lastName }) +
         ";domain=.magnifi.com;path=/;";
   }

   // NOTE: `advAuthToken` key used by retail-build
   // ---------------------------------------------------------------
   setCookie("advAuthToken", token);
   // ---------------------------------------------------------------
}

export function deleteAuthToken() {
   window.localStorage.removeItem("auth-type");
   window.localStorage.removeItem("token");
   window.localStorage.removeItem("email");
   window.localStorage.removeItem("firstName");
   window.localStorage.removeItem("lastName");
   window.localStorage.removeItem("isGoogleLogin");
   window.localStorage.removeItem("isAppleLogin");
   window.localStorage.removeItem("termsAgreed");
   window.sessionStorage.removeItem("query");
   window.localStorage.removeItem("plusCounter");
   window.localStorage.removeItem("counter");
   window.localStorage.removeItem("self_role");
   window.localStorage.removeItem("client_exist");
   window.localStorage.removeItem("user_advisor_menu");
   window.localStorage.removeItem("profile_self_role");
   window.localStorage.removeItem("restricted_universe");
   window.localStorage.removeItem("is_sharing_alpha_user");

   window.sessionStorage.removeItem("okta-cache-storage");
   window.sessionStorage.removeItem("okta-token-storage");
   window.sessionStorage.removeItem("okta-transaction-storage");
   window.sessionStorage.removeItem("okta-shared-transaction-storage");
   window.sessionStorage.removeItem("sharingAlphaEmail");

   window.localStorage.removeItem("okta-cache-storage");
   window.localStorage.removeItem("okta-token-storage");
   window.localStorage.removeItem("okta-transaction-storage");
   window.localStorage.removeItem("okta-shared-transaction-storage");

   // NOTE: `advAuthToken` key used by retail-build
   // ---------------------------------------------------------------
   deleteCookie("advAuthToken");
   // ---------------------------------------------------------------

   var hostname = window.location.hostname
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .split(/[/?#]/)[0];
   if (hostname === "magnifi.com") {
      document.cookie =
         "magnifi_session=; path=/; domain=.magnifi.com; expires=" +
         new Date(0).toUTCString();
   }
}

export function updateToken(token) {
   window.localStorage.setItem("token", token);
   // NOTE: `advAuthToken` key used by retail-build
   // ---------------------------------------------------------------
   setCookie("advAuthToken", token);
   // ---------------------------------------------------------------
}

export function updateQuery(query) {
   window.sessionStorage.setItem("query", query);
}

export function getQuery() {
   return window.sessionStorage.getItem("query");
}

export function redirectOktaNonGroupMagnifiUsers(oktaResponse) {
   const {
      tokens: {
         idToken: { claims: { groups = [], email = "" } = {} } = {},
         accessToken: { accessToken = "" } = {},
      } = {},
   } = oktaResponse;
   if (accessToken && email) {
      if (groups.includes("Magnifi Retail")) {
         const retailBaseUrl = isDev
            ? "https://staging.retail.magnifi.com"
            : "https://magnifi.com";
         window.location.href = `${retailBaseUrl}/login?token=${accessToken}&email=${encodeURIComponent(
            email
         )}`;
         return true;
      } else if (groups.includes("Magnifi Advisor")) {
         const advisorBaseUrl = isDev
            ? "https://test2.magnifi.com"
            : "https://app.magnifi.com";
         window.location.href = `${advisorBaseUrl}/login?response=${encodeURIComponent(
            JSON.stringify({
               token: accessToken,
               email,
            })
         )}`;
         return true;
      }
   }
   return false;
}
