export enum GlobalModalTypes {
   AssetSubscriptionModal = "AssetSubscriptionModal",
}

export type AssetSubscriptionModalType = {
   tickers: { ticker: string; sponsor: string; is_consent: boolean }[];
   source:
      | "fundRating"
      | "portfolio"
      | "sponsoredArticles"
      | "discoverySearch"
      | "fundComments";
};
