import zipObject from "lodash/zipObject";

const actions = [
   "CLEAR_FUNDS_API_LOAD_PERCENT",
   "UPDATE_LOAD_PERCENT",
   "FUNDS_API_CALL_DURATION",
   "FUNDS_API_CALL_TIME",
   "SET_GLOBAL_STATE",

   "GET_ALL_TICKER_LIST_REQUEST",
   "GET_ALL_TICKER_LIST_FETCH",
   "GET_ALL_TICKER_LIST_SUCCESS",

   "SHOW_JOIN_MODAL",

   "GET_GLOBAL_MARKET_STATE_REQUEST",
   "GET_GLOBAL_MARKET_STATE_FETCH",
   "GET_GLOBAL_MARKET_STATE_SUCCESS",

   "SET_GLOBAL_MARKET_STATE",

   "SET_PROFILE_GLOBAL_MARKET_REGION",
   "TOGGLE_GLOBAL_MARKET_REGION",
];

export default zipObject(actions, actions);
