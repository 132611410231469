/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function DragDropFile({ onUploaded, error }) {
   function handleFile(files) {
      onUploaded(files);
   }

   const [dragActive, setDragActive] = React.useState(false);
   const inputRef = React.useRef(null);

   const handleDrag = function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") {
         setDragActive(true);
      } else if (e.type === "dragleave") {
         setDragActive(false);
      }
   };

   const handleDrop = function (e) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
         handleFile(e.dataTransfer.files);
      }
   };

   const handleChange = function (e) {
      e.preventDefault();
      if (e.target.files && e.target.files[0]) {
         handleFile(e.target.files);
      }
   };
   // const onButtonClick = () => {
   //    inputRef.current.click();
   // };

   return (
      <form
         id="form-file-upload"
         className="form-file-upload"
         onDragEnter={handleDrag}
         onSubmit={e => e.preventDefault()}
      >
         <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
            style={{ display: "none" }}
         />
         <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? "drag-active" : ""}
            style={{
               height: "100%",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               borderWidth: "2px",
               borderRadius: "1rem",
               borderStyle: "dashed",
               borderColor: "#cbd5e1",
               backgroundColor: "#f8fafc",
               margin: 0,
            }}
         >
            <div className="file-upload-label">
               <p className="ant-upload-drag-icon">
                  <img
                     src="/assets/images/ic_upload_cloud.svg"
                     alt="upload-icon"
                  />
               </p>
               <p>
                  Drag and drop your file here, or{" "}
                  <a className="upload-button" style={{ color: "#56A9E8" }}>
                     browse
                  </a>
               </p>
            </div>
         </label>
         {/* {error && <div style={{ color: '#721c24', textAlign: 'left', padding: '5px' }}>{error}</div>} */}
         {dragActive && (
            <div
               id="drag-file-element"
               onDragEnter={handleDrag}
               onDragLeave={handleDrag}
               onDragOver={handleDrag}
               onDrop={handleDrop}
               style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "1rem",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
               }}
            ></div>
         )}
      </form>
   );
}
