/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
import { setSFMCatData } from "actions/sfm";
import {
   checkTickerExist,
   finalizeModelPortfolio,
   geReplaceTickerApi,
   getBatchSearch,
   getEnhancedCardApi,
   getModelPortfolio,
   portfolioEnhanceTranssApi,
   setPortfolioOptsApi,
   setProposalPortfolioApi,
} from "apis/portfolio";
import {
   cn,
   PORTFOLIO_CLASS_MAP,
   QUERY_MAP,
   REVERSE_MODEL_PORTFOOLIO_QUERY_MAP,
} from "DataSet";
import { dataMapForEnhancer, statsForPerformanceImpact } from "layouts/utils";
import { getCalculatedModelPortfolioEnhanceStats } from "layouts/WebDashboard/AccountsPage/utils";
import _ from "lodash";
import get from "lodash/get";
import { delay } from "redux-saga";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import { getPortfolioBenchmarkOfSelectedTicker } from "Utils";
import ActionTypes from "../action-types";

import {
   checkTickerFetch,
   checkTickerSuccess,
   enableEnhanceBtnControlState,
   fetchMyCurrentPortfolio,
   fetchReplacePortfolioOptions,
   finalizeReplaceModelPortfolioFetch,
   finalizeReplaceModelPortfolioSuccess,
   myCurrentPortfolioSuccess,
   portfolioFetchSuccess,
   replacePortfolioOptionsSuccess,
   resetCachingForPortfolio,
   resetModelEnhancePortfoliosState,
   saveEnhancedCard,
   saveEnhancedTransactionData,
   saveReplaceTicker,
   setCachingForPortfolio,
   setPortfolioState,
} from "actions/portfolio";
import {
   fetchPortfolioData,
   investmentFetchSuccess,
   resetCachingForInvestment,
   setCachingForInvestment,
} from "../actions/investment";

import {
   getAllInvestmentState,
   getInvestmentPositionStats,
   getInvestmentWatchlistStats,
   getPortfolioFunds,
} from "../selectors/investment";
import { getAllPortfolioStates } from "../selectors/portfolio";

function* portfolioFetch(action) {
   try {
      yield put(setPortfolioState({ enhanceCTA: "Enhance" }));
      // NOTE: call update-portfolio with filename for reason to use 'OLD TICKER' which we get while clicking on dropdown for batch search.
      const portState = yield select(getAllPortfolioStates);
      const investmentStates = yield select(getAllInvestmentState);
      const { tickers, prtQuery } = action.payload;
      let payloadRequest = { q: tickers, query: prtQuery };
      if (
         action.payload.fundUniverse &&
         typeof action.payload.fundUniverse !== "undefined" &&
         action.payload.fundUniverse !== ""
      ) {
         payloadRequest = {
            q: tickers,
            query: prtQuery,
            universe: action.payload.fundUniverse,
            similarity_measure: action.payload.similarityMeasures,
         };
         if (
            action.payload.shareClass &&
            typeof action.payload.shareClass !== "undefined" &&
            action.payload.shareClass !== "" &&
            action.payload.fundUniverse !== "etf,etn" &&
            PORTFOLIO_CLASS_MAP[action.payload.shareClass]
         ) {
            payloadRequest = {
               q: tickers,
               query: prtQuery,
               universe: action.payload.fundUniverse,
               mf_class_type: PORTFOLIO_CLASS_MAP[action.payload.shareClass],
               similarity_measure: action.payload.similarityMeasures,
            };
         } else if (
            portState.shareClass !== "" &&
            typeof portState.shareClass !== "undefined" &&
            portState.shareClass !== null &&
            portState.shareClass !== "etf,etn" &&
            PORTFOLIO_CLASS_MAP[portState.shareClass]
         ) {
            payloadRequest = {
               q: tickers,
               query: prtQuery,
               universe: action.payload.fundUniverse,
               mf_class_type: PORTFOLIO_CLASS_MAP[portState.shareClass],
               similarity_measure: action.payload.similarityMeasures,
            };
         }
      } else if (
         portState.universe !== "" &&
         typeof portState.universe !== "undefined" &&
         portState.universe !== null
      ) {
         payloadRequest = {
            q: tickers,
            query: prtQuery,
            universe: portState.universe,
            similarity_measure: action.payload.similarityMeasures,
         };
         if (
            action.payload.shareClass &&
            typeof action.payload.shareClass !== "undefined" &&
            action.payload.shareClass !== "" &&
            action.payload.fundUniverse !== "etf,etn" &&
            PORTFOLIO_CLASS_MAP[action.payload.shareClass]
         ) {
            payloadRequest = {
               q: tickers,
               query: prtQuery,
               universe: action.payload.fundUniverse,
               mf_class_type: PORTFOLIO_CLASS_MAP[action.payload.shareClass],
               similarity_measure: action.payload.similarityMeasures,
            };
         } else if (
            portState.shareClass !== "" &&
            typeof portState.shareClass !== "undefined" &&
            portState.shareClass !== null &&
            portState.shareClass !== "etf,etn" &&
            PORTFOLIO_CLASS_MAP[portState.shareClass]
         ) {
            payloadRequest = {
               q: tickers,
               query: prtQuery,
               universe: action.payload.fundUniverse,
               mf_class_type: PORTFOLIO_CLASS_MAP[portState.shareClass],
               similarity_measure: action.payload.similarityMeasures,
            };
         }
      }

      if (
         portState &&
         portState.selectedWeightOptions &&
         portState.searchTypeMode === "FUNDS" &&
         prtQuery === "user_criteria"
      ) {
         // selectedWeightOptions: {
         //   name: 'Passive Fund Selector',
         //   value: 'passive fund selector',
         //   code: 'passive_fund_selector',
         // },
         let readableQuery = QUERY_MAP[prtQuery] || prtQuery;
         if (portState.selectedWeightOptions.code === "active_fund_selector") {
            readableQuery = QUERY_MAP["user_criteria_active_fund_selector"];
         } else if (
            portState.selectedWeightOptions.code === "passive_fund_selector"
         ) {
            readableQuery = QUERY_MAP["user_criteria_passive_fund_selector"];
         }
         yield put(setPortfolioState({ query_english: readableQuery }));

         const fundSelectorCode = portState.selectedWeightOptions.value;
         console.log(
            "================ FUND WEIGHT SELECTOR CODE PRESENT ================"
         );
         console.log(fundSelectorCode);
         console.log(
            "==================================================================="
         );
         payloadRequest.calculator_name = fundSelectorCode;
      }

      let _result = yield call(getBatchSearch, payloadRequest);
      // console.log(_result);
      if (_result) {
         const mappedData = yield dataMapForEnhancer(_result, prtQuery);
         const {
            data,
            categories,
            // updated_at,
            checkboxToggleState,
            swapTickerData,
            enhanceTickerFilter,
            guidanceMessage,
         } = mappedData;
         if (data) {
            yield put(
               portfolioFetchSuccess({
                  resultSet: data,
                  filterResultSet: data,
                  replacePortfolioToggle: checkboxToggleState,
                  enhanceReplace: swapTickerData,
                  enhanceTickerFilter,
                  guidanceMessage,
               })
            );
            if (swapTickerData.length > 0) {
               console.log(
                  "========== CALCULATING PERFORMANCE IMPACT =========="
               );
               yield call(delay, 600);
               const portfolioFunds = yield select(getPortfolioFunds);
               const accountFundstats = yield select(
                  getInvestmentPositionStats
               );
               const watchlistFundstats = yield select(
                  getInvestmentWatchlistStats
               );
               let enhancedPerformanceImpact = {},
                  // currentPerformanceImpact = {},
                  header2Disable = true;
               let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(
                  portState.upDownDataSet,
                  portState.selectedBenchmark
               );
               const stats = yield statsForPerformanceImpact({
                  portfolioEnhanceReplace: swapTickerData,
                  watchlistFundstats,
                  accountFundstats,
                  portfolioFunds,
                  marketFunds: investmentStates.marketFunds,
                  portfolioQuery: portState.query,
                  enhancePortfolioVisible: portState.enhancePortfolioVisible,
                  view: investmentStates.view,
                  portfolioBenchmark,
               });
               console.log(
                  "========== PLOTTING PERFORMANCE IMPACT DATA =========="
               );
               if (
                  stats &&
                  typeof stats.enhancedPerformanceImpact !== "undefined"
               ) {
                  enhancedPerformanceImpact = stats.enhancedPerformanceImpact;
                  // if (stats && typeof stats.currentPerformanceImpact !== 'undefined') {
                  //   currentPerformanceImpact = stats.currentPerformanceImpact;
                  // }
                  header2Disable =
                     typeof stats.header2Disable !== "undefined"
                        ? stats.header2Disable
                        : true;
                  yield put(
                     setPortfolioState({
                        header2Disable,
                        enhancedPerformanceImpact,
                        // summaryDrawer: true,
                        enhanceReplace: stats.portfolioEnhanceReplace,
                        // currentPerformanceImpact,
                     })
                  );
               }
            }
         }
         if (categories) {
            yield call(delay, 500);
            yield put(setSFMCatData(categories["categories"]));
         }
      }
   } catch (error) {
      console.log(error);
      yield put(
         portfolioFetchSuccess({
            resultSet: [],
            filterResultSet: [],
            pageCrash: "Failed to load query.",
            guidanceMessage: ["Failed to load query."],
         })
      );
   }
}

// For view == portfolio
function* savePortfolioOpts(action) {
   try {
      yield put(
         investmentFetchSuccess({ portfolioLoading: true, loading: true })
      );
      yield put(setPortfolioState({ summaryDrawer: false }));
      // yield put(setPortfolioReplace([]));
      const portState = yield select(getAllPortfolioStates);
      // const investmentStates = yield select(getAllInvestmentState);

      const response = yield call(setPortfolioOptsApi, action.payload);

      if (response.message === "Success") {
         // NOTE: To EDIT bug for enhancement
         // NOTE: call update-portfolio with filename for reason to use 'OLD TICKER' which we get while clicking on dropdown for batch search.

         // if (portState.caching && typeof portState.caching.loading !== 'undefined') {
         //   yield put(resetCachingForPortfolio());
         // }

         if (typeof portState.caching.loading === "undefined") {
            // yield put(setCachingForPortfolio({ enhanceReplace: portState.enhanceReplace }));
            yield put(setCachingForPortfolio());
            yield put(setCachingForInvestment());
         }

         if (
            action.payload &&
            typeof action.payload.enhanceCTA !== "undefined"
         ) {
            yield put(
               setPortfolioState({ enhanceCTA: action.payload.enhanceCTA })
            );
         }

         yield put(
            fetchPortfolioData(
               response.portfolio_name,
               () =>
                  action.callback &&
                  typeof action.callback == "function" &&
                  action.callback()
            )
         );
         if (action.callback && typeof action.callback == "function") {
            yield put(
               setPortfolioState({
                  query: "",
                  filterResultSet: [],
                  enhanceReplace: [],
                  enhancedPortfolioName: response.portfolio_name,
                  portfolioCheck: true,
                  enhancedPortfolioId: response.portfolio_id,
               })
            );
            yield put(
               investmentFetchSuccess({
                  view: "uplPrt",
                  setPfEnhancementContent: false,
               })
            );
            // action.callback();
         } else {
            yield put(
               setPortfolioState({
                  enhancedPortfolioName: response.portfolio_name,
                  enhancedPortfolioId: response.portfolio_id,
                  portfolioCheck: true,
                  enhanceReplace: [],
                  enhanceTickerFilter: [],
                  resultSet: [],
                  filterResultSet: [],
                  query: "",
                  summaryDrawer: false,
               })
            );
            yield put(
               investmentFetchSuccess({
                  view: "uplPrt",
                  setPfEnhancementContent: false,
               })
            );
         }
         yield put(enableEnhanceBtnControlState());
      } else
         yield put(
            investmentFetchSuccess({ portfolioLoading: false, loading: false })
         );
   } catch (error) {
      // yield put(investmentFetchSuccess({ portfolioLoading: false }));
      // const errorDetails = get(error, 'response.data', error.stack)
      console.log(error);
      // yield put(actions.failedToPerform(errorDetails))
      yield put(
         investmentFetchSuccess({ portfolioLoading: false, loading: false })
      );
   }
}

// For view == account|| watchlist || marketplace
function* saveAccountOpts(action) {
   try {
      yield put(
         investmentFetchSuccess({ portfolioLoading: true, loading: true })
      );
      yield put(setPortfolioState({ summaryDrawer: false }));
      const portState = yield select(getAllPortfolioStates);
      // const investmentStates = yield select(getAllInvestmentState);
      // console.log(portState);
      // console.log(portState.caching);
      // console.log(investmentStates.caching);
      // yield put(setPortfolioReplace([]));
      const response = yield call(setPortfolioOptsApi, action.payload);
      if (response.message === "Success") {
         // NOTE: To EDIT bug for enhancement
         // NOTE: call update-portfolio with filename for reason to use 'OLD TICKER' which we get while clicking on dropdown for batch search.

         // if (portState.caching && typeof portState.caching.loading !== 'undefined') {
         //   yield put(resetCachingForPortfolio());
         // }
         //
         // console.log(action.payload);
         //
         // if (action.payload && typeof action.payload.enhanceCTA !== 'undefined') {
         //   yield put(setCachingForPortfolio({ enhanceReplace: portState.enhanceReplace }));
         //   yield put(setCachingForInvestment());
         //   yield put(setPortfolioState({ enhanceCTA: action.payload.enhanceCTA }));
         // }

         if (typeof portState.caching.loading === "undefined") {
            console.log(action.payload);
            // yield put(setCachingForPortfolio({ enhanceReplace: portState.enhanceReplace }));
            yield put(setCachingForPortfolio());
            yield put(setCachingForInvestment());
         }

         if (
            action.payload &&
            typeof action.payload.enhanceCTA !== "undefined"
         ) {
            yield put(
               setPortfolioState({ enhanceCTA: action.payload.enhanceCTA })
            );
         }

         yield put(
            fetchPortfolioData(
               response.portfolio_name,
               () =>
                  action.callback &&
                  typeof action.callback == "function" &&
                  action.callback()
            )
         );
         if (action.callback && typeof action.callback == "function") {
            yield put(
               setPortfolioState({
                  query: "",
                  filterResultSet: [],
                  enhanceReplace: [],
                  enhancedPortfolioName: response.portfolio_name,
                  portfolioCheck: true,
                  enhancedPortfolioId: response.portfolio_id,
               })
            );
            yield put(
               investmentFetchSuccess({ setPfEnhancementContent: false })
            );
            // action.callback();
         } else {
            yield put(
               setPortfolioState({
                  enhancedPortfolioName: response.portfolio_name,
                  enhancedPortfolioId: response.portfolio_id,
                  portfolioCheck: true,
                  enhanceReplace: [],
                  enhanceTickerFilter: [],
                  resultSet: [],
                  filterResultSet: [],
                  query: "",
                  summaryDrawer: false,
               })
            );
            yield put(
               investmentFetchSuccess({
                  view: "uplPrt",
                  setPfEnhancementContent: false,
               })
            );
         }
         yield put(enableEnhanceBtnControlState());
      } else
         yield put(
            investmentFetchSuccess({ portfolioLoading: false, loading: false })
         );
   } catch (error) {
      // yield put(investmentFetchSuccess({ portfolioLoading: false }));
      // const errorDetails = get(error, 'response.data', error.stack)
      console.log(error);
      // yield put(actions.failedToPerform(errorDetails))
      yield put(
         investmentFetchSuccess({ portfolioLoading: false, loading: false })
      );
   }
}

function* saveEnhancedTransaction(action) {
   try {
      // const { filename } = action;
      const result = yield call(portfolioEnhanceTranssApi, action.payload);
      if (
         result &&
         (result.message === "Failed" || result.message === "No File Present.")
      ) {
         console.log("response", result);
      } else {
         yield put(saveEnhancedTransactionData(result));
      }
   } catch (error) {
      console.log(error);
   }
}

function* saveEnhanceProposalPortfolio(action) {
   try {
      yield put(
         investmentFetchSuccess({ portfolioLoading: true, loading: true })
      );
      yield put(setPortfolioState({ summaryDrawer: false }));
      // yield put(setPortfolioReplace([]));
      const response = yield call(setProposalPortfolioApi, action.payload);
      if (response.message === "Portfolio saved successfully!") {
         yield put(fetchPortfolioData(action.payload.portfolio_name, () => {}));
         if (action.callback && typeof action.callback == "function") {
            yield put(
               setPortfolioState({
                  enhancedPortfolioName: "",
                  enhancedPortfolioId: "",
                  enhanceReplace: [],
                  enhanceTickerFilter: [],
                  resultSet: [],
                  filterResultSet: [],
                  query: "",
                  downloadQuery: "",
                  summaryDrawer: false,
                  enableEnhanceBtnControl: false,
                  portfolioSave: false,
                  portfolioSaveError: "",
                  portfolioCheck: false,
               })
            );
            yield put(
               investmentFetchSuccess({
                  view: "uplPrt",
                  activeAccount: action.payload.portfolio_name,
                  filename: action.payload.portfolio_name,
                  setPfEnhancementContent: false,
               })
            );
            action.callback(
               action.payload.portfolio_name,
               action.payload.portfolio_id
            );
         } else {
            yield put(
               setPortfolioState({
                  enhancedPortfolioName: "",
                  enhancedPortfolioId: "",
                  enhanceReplace: [],
                  enhanceTickerFilter: [],
                  resultSet: [],
                  filterResultSet: [],
                  query: "",
                  downloadQuery: "",
                  summaryDrawer: false,
                  enableEnhanceBtnControl: false,
                  portfolioSave: false,
                  portfolioSaveError: "",
                  portfolioCheck: false,
               })
            );
            yield put(
               investmentFetchSuccess({
                  view: "uplPrt",
                  activeAccount: action.payload.portfolio_name,
                  filename: action.payload.portfolio_name,
                  setPfEnhancementContent: false,
               })
            );
         }
         yield put(resetCachingForPortfolio());
         yield put(resetCachingForInvestment());
      } else {
         yield put(setPortfolioState({ portfolioSave: true }));
         yield put(
            investmentFetchSuccess({
               portfolioLoading: false,
               loading: false,
               portfolioSave: true,
            })
         );
      }
   } catch (error) {
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails.status === 400) {
         yield put(
            setPortfolioState({
               portfolioLoading: false,
               loading: false,
               portfolioSave: true,
               portfolioSaveError: errorDetails.data.message,
            })
         );
         yield put(
            investmentFetchSuccess({ portfolioLoading: false, loading: false })
         );
      }
      // yield put(investmentFetchSuccess({ portfolioLoading: false }));
      // const errorDetails = get(error, 'response.data', error.stack)
      console.log(error);
      // yield put(actions.failedToPerform(errorDetails))
   }
}

function* setReplaceTickerData(action) {
   try {
      // const { filename } = action;
      const result = yield call(geReplaceTickerApi);
      if (result) {
         yield put(saveReplaceTicker(result));
      } else {
         //yield put(saveEnhancedTransactionData(result));
      }
   } catch (error) {
      console.log(error);
   }
}

function* setEnhancedCardData(action) {
   try {
      const { ticker, overlap } = action.payload;
      const response = yield call(getEnhancedCardApi, { ticker, overlap });
      const fundsData = Object.values(response.result.funds);

      if (response) {
         yield put(saveEnhancedCard({ overlap, fundsData }));
      } else {
         //yield put(saveEnhancedTransactionData(result));
      }
   } catch (error) {
      console.log(error);
   }
}

function* getCurrentPortfolioDetailsRequest(action) {
   try {
      if (action.payload) {
         yield put(fetchMyCurrentPortfolio());
         const { query, portfolioId, myCurrentPortfolio } = action.payload;
         if (query && portfolioId && myCurrentPortfolio) {
            const currentEnhancePortfolioStates = yield select(
               getAllPortfolioStates
            );
            yield put(
               myCurrentPortfolioSuccess({
                  enhancePortfolio: {
                     requestQuery: query,
                     ...currentEnhancePortfolioStates.enhancePortfolio,
                     myCurrentPortfolio: {
                        ...currentEnhancePortfolioStates.enhancePortfolio
                           .myCurrentPortfolio,
                        ...myCurrentPortfolio,
                        portfolioId,
                     },
                  },
               })
            );
         } else {
            console.log("---- NO PAYLOAD -----");
            yield put(
               myCurrentPortfolioSuccess({
                  error: "failed to update",
               })
            );
         }
      } else {
         console.log("---- NO PAYLOAD -----");
      }
   } catch (error) {
      console.log(error);
   }
}

function* getReplacePortfoliosOptionsRequest(action) {
   try {
      if (action.payload) {
         const {
            query,
            portfolioId,
            portfolioState,
            myCurrentPortfolio,
            portfolio_type,
            fundUniverse,
            similarityMeasures,
         } = action.payload;
         const MODEL_QUERY = REVERSE_MODEL_PORTFOOLIO_QUERY_MAP[query] || query;
         if (
            query &&
            portfolioId &&
            myCurrentPortfolio &&
            portfolioState &&
            MODEL_QUERY
         ) {
            const currentPortfolioStates = yield select(getAllPortfolioStates);
            let TARGET_CARD_REGION_DATA = [],
               OVERLAP_DATA = [];

            yield put(
               fetchReplacePortfolioOptions({
                  ...portfolioState,
                  query: MODEL_QUERY,
                  portfolio_type,
                  enhancePortfolio: {
                     ...currentPortfolioStates.enhancePortfolio,
                     portfolioId,
                     myCurrentPortfolio,
                     fundUniverse,
                  },
                  guidanceMessage: [],
               })
            );

            const payloadRequest =
               fundUniverse && fundUniverse !== "mp"
                  ? {
                       query,
                       portfolio_id: portfolioId,
                       portfolio_type,
                       universe: fundUniverse,
                       similarity_measure: similarityMeasures,
                    }
                  : {
                       query,
                       portfolio_id: portfolioId,
                       portfolio_type,
                       similarity_measure: similarityMeasures,
                    };
            // console.log(payloadRequest);
            let response = yield call(getModelPortfolio, payloadRequest);
            try {
               if (response) {
                  let guidanceMessage = response.messages || [];
                  let _defaultFRR = ["fees", "risk", "returns"];

                  // const FRR_DATA_ATTRS = getFRRAttrDataForModels().map(
                  //    kj => kj.attr
                  // );

                  let NEW_QUERY_VAR_OBJECT = {
                     _defaultFRR: _defaultFRR,
                     _originalQueryVar: response.query_var || [],
                     _relevanceChartTabs: [],
                  };

                  response._NEW_QUERY_VAR_OBJECT = NEW_QUERY_VAR_OBJECT;

                  if (response.funds && response.funds.length > 0) {
                     const replacePortfolioWith = response.funds[0];
                     //shares = 	(total*(percent/100))/fund price
                     //total = investment.portfolioValue;
                     //percent = holdings[0].weight
                     //fund price = holdings[0].nav
                     // if shares < 1 then shares = 1

                     const portfolioValue = (yield select(
                        getAllPortfolioStates
                     )).enhancePortfolio.myCurrentPortfolio.portfolioValue;

                     if (
                        typeof response.query_var !== "undefined" &&
                        response.query_var.length > 0
                     ) {
                        // Tabs section: 'Tabs' view name => ['Overlap', 'Risk', 'Returns']
                        // NOTE: default active View index [1, 2, 3]; whereas 1 for Overlap chart
                        // and 2, 3 ... N for remaining 'Risk', 'Return' ... etc.
                        NEW_QUERY_VAR_OBJECT._viewIndex = "1"; // 1 ALWAYS stands for chart

                        NEW_QUERY_VAR_OBJECT._defaultFRR = [
                           "fees",
                           "risk",
                           "returns",
                        ];
                        // const excludeNodesForFRR = [
                        //    "correlation",
                        //    "overlap",
                        //    "similarity",
                        //    "fees",
                        //    "risk",
                        //    "returns",
                        // ];
                        NEW_QUERY_VAR_OBJECT._originalQueryVar =
                           response.query_var.reverse();

                        // const _nodes = NEW_QUERY_VAR_OBJECT._originalQueryVar
                        //    .filter(
                        //       s =>
                        //          s.view_name !== "" &&
                        //          s.node !== "" &&
                        //          !excludeNodesForFRR.includes(s.node)
                        //    )
                        // .filter((l) => FRR_DATA_ATTRS.includes(l.node))
                        // .filter(w => !w.view_name.includes("Highlight"))
                        // .slice(0, 2)
                        // .map(k => k.node);
                        // console.log('_nodes', _nodes); // _nodes: ['Yield', 'Assets'];
                        const chartNodes =
                           NEW_QUERY_VAR_OBJECT._originalQueryVar
                              .filter(
                                 hg =>
                                    hg.variable.includes("overlap") ||
                                    hg.variable.includes("correlation")
                              )
                              .filter(w => !w.view_name.includes("Highlight"))
                              .map(k => k.view_name);
                        // console.log('chart Nodes', chartNodes);

                        // uncomment below for dynamic query_var, when Data is ready from backend side

                        // if (_nodes && _nodes.length > 0) {
                        //   _nodes.map((l, li) => {
                        //     if (typeof NEW_QUERY_VAR_OBJECT._defaultFRR[li+1] === 'undefined') {
                        //       NEW_QUERY_VAR_OBJECT._defaultFRR.push(l);
                        //     } else {
                        //       NEW_QUERY_VAR_OBJECT._defaultFRR[li+1] = l // NEW_QUERY_VAR_OBJECT._defaultFRR: ['Fees', 'Yield', 'Assets'];
                        //     }
                        //   });
                        // }

                        const filtr = ["fees", "risk", "returns"].filter(
                           j => !NEW_QUERY_VAR_OBJECT._defaultFRR.includes(j)
                        );

                        NEW_QUERY_VAR_OBJECT._relevanceChartTabs = [
                           ...chartNodes,
                           ...filtr,
                        ];

                        response._NEW_QUERY_VAR_OBJECT = NEW_QUERY_VAR_OBJECT;
                     }

                     response.funds.map(item => {
                        if (
                           typeof response.query_var !== "undefined" &&
                           response.query_var.length > 0
                        ) {
                           item._originalQueryVar =
                              NEW_QUERY_VAR_OBJECT._originalQueryVar;
                           item._defaultFRR = NEW_QUERY_VAR_OBJECT._defaultFRR;
                           item._relevanceChartTabs =
                              NEW_QUERY_VAR_OBJECT._relevanceChartTabs;

                           const overlapQueryVar = response.query_var.filter(
                              v =>
                                 v.chart_type === "odometer" &&
                                 v.variable.includes("overlap")
                           );
                           // console.log(overlapQueryVar);
                           if (overlapQueryVar.length > 0) {
                              item._queryVar = overlapQueryVar[0];
                           }
                           // console.log(item._queryVar);
                        }
                        if (response.overlap_burst_chart) {
                           const REGIONS_DATA = response.overlap_burst_chart;
                           if (
                              REGIONS_DATA &&
                              Object.keys(REGIONS_DATA).length > 0
                           ) {
                              item["Region"] = REGIONS_DATA[cn(item, "Ticker")]; //REGIONS_DATA[Object.keys(REGIONS_DATA)[0]];
                           }
                        }
                        if (response.target_overlap_burst_chart) {
                           TARGET_CARD_REGION_DATA =
                              response.target_overlap_burst_chart;
                        }
                        if (response.overlap) {
                           // OVERLAP_DATA = response.overlap;
                           const OVERLAP = response.overlap;
                           if (OVERLAP && Object.keys(OVERLAP).length > 0) {
                              item["overlap_data"] =
                                 OVERLAP[cn(item, "Ticker")]; //REGIONS_DATA[Object.keys(REGIONS_DATA)[0]];
                           }
                        }
                        if (item.holdings && item.holdings.length > 0) {
                           item._calculateEnhanceStatsFlag = true;
                           item.weight = 0;
                           item.holdings.map(h => {
                              item.weight += h.weight;
                              const total = portfolioValue;
                              const percent = h.weight;
                              const fundPrice = h.nav || 1;
                              // console.log(total, percent, fundPrice);
                              let shares =
                                 (total * (percent / 100)) / fundPrice;
                              if (shares < 1) {
                                 shares = 1;
                              }
                              h._h_quantity = Math.round(shares);
                              h._pF = {
                                 quantity: Math.round(shares),
                                 _realTimePrice: h.nav || 1,
                              };
                           });

                           item.holdings = _.orderBy(
                              item.holdings,
                              ["weight"],
                              ["desc"]
                           );

                           const total = portfolioValue;
                           const percent = item.weight;
                           const fundPrice = item.nav || 1;
                           // console.log(total, percent, fundPrice);

                           let shares = (total * (percent / 100)) / fundPrice;
                           if (shares < 1) {
                              shares = 1;
                           }

                           item._pF = {
                              quantity: Math.round(shares),
                              _realTimePrice: item.nav || 1,
                           };

                           if (typeof item.returns !== "undefined") {
                              item._calculateEnhanceStatsFlag = false;
                           }
                        }
                     });

                     try {
                        yield put(
                           replacePortfolioOptionsSuccess({
                              valid: true,
                              enhancedPerformanceImpact: enhanceStats,
                              enhanceReplace: [
                                 {
                                    query,
                                    replace: replacePortfolioWith.ticker,
                                    selectedPortfolioFunds:
                                       replacePortfolioWith,
                                    ticker: (yield select(
                                       getAllPortfolioStates
                                    )).enhancePortfolio.myCurrentPortfolio
                                       .portfolioDetails.name,
                                 },
                              ],
                              // summaryDrawer: true,
                              enhancePortfolio: {
                                 ...(yield select(getAllPortfolioStates))
                                    .enhancePortfolio,
                                 requestQuery: query,
                                 replacePortfolioOptions: response,
                                 replacePortfolioWith,
                                 myCurrentPortfolio: {
                                    ...(yield select(getAllPortfolioStates))
                                       .enhancePortfolio.myCurrentPortfolio,
                                    targetCard: {
                                       Region: TARGET_CARD_REGION_DATA,
                                       chartData: [],
                                       overlap_data: OVERLAP_DATA,
                                    },
                                    targetCardData: response.target_card[0],
                                 },
                              },
                              guidanceMessage,
                           })
                        );
                        yield call(delay, 500);
                        const investmentStates = yield select(
                           getAllInvestmentState
                        );
                        const portState = yield select(getAllPortfolioStates);
                        let portfolioBenchmark =
                           getPortfolioBenchmarkOfSelectedTicker(
                              portState.upDownDataSet,
                              portState.selectedBenchmark
                           );
                        const enhanceStats =
                           getCalculatedModelPortfolioEnhanceStats(
                              [replacePortfolioWith],
                              query,
                              investmentStates.portfolioValue,
                              portfolioBenchmark
                           );
                        if (enhanceStats) {
                           yield put(
                              setPortfolioState({
                                 enhancedPerformanceImpact: enhanceStats,
                                 triggerModelPortfolioQuery: false,
                              })
                           );
                        }
                     } catch (e) {
                        console.log(e);
                     }
                  } else {
                     if (response.target_overlap_burst_chart) {
                        TARGET_CARD_REGION_DATA =
                           response.target_overlap_burst_chart;
                     }
                     console.log("---------- NO FUNDS FOUND ----------");
                     yield put(
                        replacePortfolioOptionsSuccess({
                           valid: true,
                           enhanceReplace: [],
                           // summaryDrawer: true,
                           triggerModelPortfolioQuery: false,
                           enhancePortfolio: {
                              ...(yield select(getAllPortfolioStates))
                                 .enhancePortfolio,
                              requestQuery: query,
                              replacePortfolioOptions: response,
                              myCurrentPortfolio: {
                                 ...(yield select(getAllPortfolioStates))
                                    .enhancePortfolio.myCurrentPortfolio,
                                 targetCard: {
                                    Region: TARGET_CARD_REGION_DATA,
                                    chartData: [],
                                    overlap_data: OVERLAP_DATA,
                                 },
                                 targetCardData: response.target_card[0],
                              },
                           },
                           guidanceMessage,
                        })
                     );
                  }
               } else {
                  yield put(
                     replacePortfolioOptionsSuccess({
                        valid: true,
                        requestQuery: query,
                        triggerModelPortfolioQuery: false,
                        guidanceMessage: [],
                        error: "failed to response",
                     })
                  );
                  throw new Error("------ failed to response -------");
               }
            } catch (e) {
               console.log(e);
               yield put(
                  replacePortfolioOptionsSuccess({
                     valid: true,
                     requestQuery: query,
                     triggerModelPortfolioQuery: false,
                     guidanceMessage: [],
                     error: "failed to response",
                  })
               );
            }
         } else {
            yield put(
               replacePortfolioOptionsSuccess({
                  valid: true,
                  requestQuery: query,
                  triggerModelPortfolioQuery: false,
                  guidanceMessage: [],
                  error: "------ failed to response, required parameter missing -------",
               })
            );
            console.log(
               query,
               portfolioId,
               myCurrentPortfolio,
               portfolioState,
               MODEL_QUERY
            );
            throw new Error(
               "------ failed to response, required parameter missing -------"
            );
         }
      } else {
         console.log("---- NO PAYLOAD -----");
      }
   } catch (error) {
      console.log(error);
   }
}

function* finalizeReplaceModelPortfolioRequest(action) {
   try {
      if (action.payload) {
         const { request, enhancementScreenView } = action.payload;
         if (request) {
            yield put(setPortfolioState({ summaryDrawer: false }));
            // yield put(setPortfolioReplace([]));
            const portState = yield select(getAllPortfolioStates);
            // const investmentStates = yield select(getAllInvestmentState);

            yield put(
               finalizeReplaceModelPortfolioFetch({
                  request,
                  summaryDrawer: false,
                  hideGenerateProposalBtn: true,
               })
            );

            const response = yield call(finalizeModelPortfolio, { ...request });

            if (response && response.portfolio_id) {
               if (typeof portState.caching.loading === "undefined") {
                  yield put(setCachingForPortfolio());
                  yield put(setCachingForInvestment());
               }

               if (
                  action.payload &&
                  typeof action.payload.enhanceCTA !== "undefined"
               ) {
                  yield put(
                     setPortfolioState({
                        enhanceCTA: action.payload.enhanceCTA,
                     })
                  );
               }

               yield put(
                  fetchPortfolioData(
                     response.portfolio_name,
                     () =>
                        action.callback &&
                        typeof action.callback == "function" &&
                        action.callback()
                  )
               );

               if (action.callback && typeof action.callback == "function") {
                  yield put(
                     setPortfolioState({
                        query: "",
                        filterResultSet: [],
                        enhanceReplace: [],
                        enhancedPortfolioName: response.portfolio_name,
                        portfolioCheck: true,
                        enhancedPortfolioId: response.portfolio_id,
                     })
                  );
                  yield put(resetModelEnhancePortfoliosState());
                  // console.log("====== enhancementScreenView =====");
                  // console.log(enhancementScreenView);
                  // console.log("==================================");
                  if (enhancementScreenView) {
                     yield put(
                        investmentFetchSuccess({
                           view: enhancementScreenView,
                           setPfEnhancementContent: false,
                        })
                     );
                  } else {
                     yield put(
                        investmentFetchSuccess({
                           setPfEnhancementContent: false,
                        })
                     );
                  }
                  // action.callback();
               } else {
                  yield put(
                     setPortfolioState({
                        enhancedPortfolioName: response.portfolio_name,
                        enhancedPortfolioId: response.portfolio_id,
                        portfolioCheck: true,
                        enhanceReplace: [],
                        enhanceTickerFilter: [],
                        resultSet: [],
                        filterResultSet: [],
                        query: "",
                        summaryDrawer: false,
                     })
                  );
                  yield put(resetModelEnhancePortfoliosState());
                  yield put(
                     investmentFetchSuccess({
                        view: "uplPrt",
                        setPfEnhancementContent: false,
                     })
                  );
               }
               yield put(
                  finalizeReplaceModelPortfolioSuccess({
                     hideGenerateProposalBtn: true,
                  })
               );
               yield put(enableEnhanceBtnControlState());
            } else {
               yield put(
                  finalizeReplaceModelPortfolioSuccess({
                     error: "failed to response",
                  })
               );
               throw new Error("------ failed to response -------");
            }
         } else {
            console.log("---- NO PAYLOAD -----");
            yield put(
               finalizeReplaceModelPortfolioSuccess({
                  error: "---- NO PAYLOAD -----",
               })
            );
         }
      } else {
         console.log("---- NO PAYLOAD -----");
      }
   } catch (error) {
      console.log(error);
   }
}

function* getAllTickersValidate(action) {
   try {
      if (action.payload) {
         // console.log(action.payload);
         const { tickers } = action.payload;
         if (tickers) {
            // const portState = yield select(getAllPortfolioStates);
            yield put(checkTickerFetch());
            const response = yield call(checkTickerExist, { tickers });
            // console.log(response);
            if (response) {
               if (response.tickers_not_exist) {
                  yield put(
                     checkTickerSuccess({
                        tickerNotExist: response.tickers_not_exist,
                     })
                  );
               } else {
                  yield put(
                     checkTickerSuccess({
                        error: "`tickers_not_exist` not present in response",
                     })
                  );
                  throw new Error(
                     "------ `tickers_not_exist` not present in response -------"
                  );
               }

               if (action.callback && typeof action.callback == "function") {
                  action.callback();
               }
            } else {
               yield put(
                  checkTickerSuccess({
                     error: "failed to response",
                  })
               );
               throw new Error("------ failed to response -------");
            }
         } else {
            console.log("---- NO PAYLOAD -----");
            yield put(
               checkTickerSuccess({
                  error: "---- NO PAYLOAD -----",
               })
            );
         }
      } else {
         console.log("---- NO PAYLOAD -----");
      }
   } catch (error) {
      console.log(error);
   }
}

// function* checkSingleTickerValidity(action) {
//    try {
//       let { updatedTicker, selectedRecord } = action.payload;
//       const { callback } = action;
//       const response = yield call(checkTickerExist, {
//          tickers: [updatedTicker],
//       });
//       let updateStatus = false;
//       if (!response.tickers_not_exist.length) {
//          const realTimePrice = yield call(fetchRealTimePricesNav, {
//             tickers: updatedTicker,
//          });
//          if (realTimePrice && realTimePrice.funds[0]) {
//             selectedRecord.realTimePrice = realTimePrice.funds[0].price;
//          }
//          updateStatus = true;
//       }
//       const oldTicker = selectedRecord.ticker;
//       selectedRecord.ticker = updatedTicker;
//       if (callback) {
//          callback(oldTicker, selectedRecord, updateStatus);
//       }
//    } catch (error) {
//       console.log(error);
//    }
// }

function* portfolioSaga() {
   yield all([
      fork(takeEvery, ActionTypes.PORTFOLIO_QUERY, portfolioFetch),
      fork(
         takeEvery,
         ActionTypes.SAVE_PORTFOLIO_REPLACE_OPTS,
         savePortfolioOpts
      ),
      fork(takeEvery, ActionTypes.SAVE_ACCOUNT_REPLACE_OPTS, saveAccountOpts),
      fork(
         takeEvery,
         ActionTypes.SET_ENHANCED_TRANSACTIONS,
         saveEnhancedTransaction
      ),
      fork(
         takeEvery,
         ActionTypes.SAVE_ENHANCE_PROPOSAL_PORTFOLIO,
         saveEnhanceProposalPortfolio
      ),
      fork(takeEvery, ActionTypes.SET_REPLACE_TICKER, setReplaceTickerData),
      fork(takeEvery, ActionTypes.SET_ENHANCED_CARD, setEnhancedCardData),
      fork(
         takeEvery,
         ActionTypes.GET_MY_CURRENT_PORTFOLIO_REQUEST,
         getCurrentPortfolioDetailsRequest
      ),
      fork(
         takeEvery,
         ActionTypes.GET_REPLACE_PORTFOLIO_OPTIONS_REQUEST,
         getReplacePortfoliosOptionsRequest
      ),
      fork(
         takeEvery,
         ActionTypes.FINALIZE_REPLACE_MODEL_PORTFOLIO_REQUEST,
         finalizeReplaceModelPortfolioRequest
      ),
      fork(
         takeEvery,
         ActionTypes.CHECK_TICKER_EXIST_REQUEST,
         getAllTickersValidate
      ),
      // fork(takeEvery, ActionTypes.CHECK_SINGLE_TICKER_EXIST_REQUEST, checkSingleTickerValidity),
   ]);
}

export default portfolioSaga;
