import ActionTypes from "../action-types";

export function resetCachingForPortfolio() {
   return {
      type: ActionTypes.RESET_CACHING_FOR_PORTFOLIO,
   };
}

export function setCachingForPortfolio(payload) {
   return {
      type: ActionTypes.SET_CACHING_FOR_PORTFOLIO,
      payload,
   };
}

export function setEnhancementData(payload) {
   return {
      type: ActionTypes.SET_ENHANCEMENT_DATA,
      payload,
   };
}

export function disableEnhanceBtnControlState(payload) {
   return {
      type: ActionTypes.DISABLE_ENHANCE_BTN_CONTROL,
      payload,
   };
}

export function enableEnhanceBtnControlState(payload) {
   return {
      type: ActionTypes.ENABLE_ENHANCE_BTN_CONTROL,
      payload,
   };
}

export function setPortfolioState(payload) {
   return {
      type: ActionTypes.SET_PORTFOLIO_STATE,
      payload,
   };
}

export function setPortfolioDefault() {
   return {
      type: ActionTypes.SET_DEFAULT_PORTFOLIO,
   };
}

export function portfolioQuery(payload) {
   return {
      type: ActionTypes.PORTFOLIO_QUERY,
      payload,
   };
}

export function portfolioFetchSuccess(payload) {
   return {
      type: ActionTypes.PORTFOLIO_FETCH_SUCCESS,
      payload,
   };
}

export function setReplacePortfolioToggle(payload) {
   return {
      type: ActionTypes.SET_REPLACE_PORTFOLIO_TOGGLE,
      payload,
   };
}

export function setPortfolioReplace(payload) {
   return {
      type: ActionTypes.SET_PORTFOLIO_REPLACE,
      payload,
   };
}

export function addPortfolioEnhanceReplace(payload) {
   return {
      type: ActionTypes.ADD_PORTFOLIO_ENHANCE_REPLACE,
      payload,
   };
}

export function deletePortfolioEnhanceReplace(payload) {
   return {
      type: ActionTypes.DELETE_PORTFOLIO_ENHANCE_REPLACE,
      payload,
   };
}

export function saveEnhancedOption(payload, callback) {
   return {
      type: ActionTypes.SAVE_PORTFOLIO_REPLACE_OPTS,
      payload,
      callback,
   };
}

export function saveAccountEnhancedOption(payload, callback) {
   return {
      type: ActionTypes.SAVE_ACCOUNT_REPLACE_OPTS,
      payload,
      callback,
   };
}

export function saveEnhanceProposalPortfolio(payload, callback) {
   return {
      type: ActionTypes.SAVE_ENHANCE_PROPOSAL_PORTFOLIO,
      payload,
      callback,
   };
}

export function saveEnhancedTicker(payload) {
   return {
      type: ActionTypes.SAVE_ENHANCED_TICKER,
      payload,
   };
}

export function saveEnhancedTransactionData(payload) {
   return {
      type: ActionTypes.SAVE_ENHANCED_TRANSACTIONS,
      payload,
   };
}

export function setEnhancedTransaction(payload) {
   return {
      type: ActionTypes.SET_ENHANCED_TRANSACTIONS,
      payload,
   };
}

export function saveReplaceTicker(payload) {
   return {
      type: ActionTypes.SAVE_REPLACE_TICKER,
      payload,
   };
}

export function setReplaceTicker(payload) {
   return {
      type: ActionTypes.SET_REPLACE_TICKER,
      payload,
   };
}

export function setEnhancedCard(payload) {
   return {
      type: ActionTypes.SET_ENHANCED_CARD,
      payload,
   };
}

export function saveEnhancedCard(payload) {
   return {
      type: ActionTypes.SAVE_ENHANCED_CARD,
      payload,
   };
}

export function deleteCustomReplaceTicker(payload) {
   return {
      type: ActionTypes.DELETE_CUSTOM_REPLACE_TICKER,
      payload,
   };
}

// --------------- Enhance Portfolio With (screen actions) ----------------
export function enhancePortfolioVisible(payload) {
   return {
      type: ActionTypes.SET_ENHANCE_PORTFOLIO_VISIBLE,
      payload,
   };
}

// -----  get current portfolio data ------
export function getMyCurrentPortfolio(payload) {
   return {
      type: ActionTypes.GET_MY_CURRENT_PORTFOLIO_REQUEST,
      payload,
   };
}

export function fetchMyCurrentPortfolio(payload) {
   return {
      type: ActionTypes.GET_MY_CURRENT_PORTFOLIO_FETCH,
      payload,
   };
}

export function myCurrentPortfolioSuccess(payload) {
   return {
      type: ActionTypes.GET_MY_CURRENT_PORTFOLIO_SUCCESS,
      payload,
   };
}

// -----  get 'REPLACE Portfolio with Options' data ------
export function getReplacePortfolios(payload) {
   return {
      type: ActionTypes.GET_REPLACE_PORTFOLIO_OPTIONS_REQUEST,
      payload,
   };
}

export function fetchReplacePortfolioOptions(payload) {
   return {
      type: ActionTypes.GET_REPLACE_PORTFOLIO_OPTIONS_FETCH,
      payload,
   };
}

export function replacePortfolioOptionsSuccess(payload) {
   return {
      type: ActionTypes.GET_REPLACE_PORTFOLIO_OPTIONS_SUCCESS,
      payload,
   };
}

export function replaceCurrentModelPortfolioWith(payload) {
   return {
      type: ActionTypes.REPLACE_CURRENT_MODEL_PORTFOLIO_WITH,
      payload,
   };
}

export function finalizeReplaceModelPortfolioRequest(payload, callback) {
   return {
      type: ActionTypes.FINALIZE_REPLACE_MODEL_PORTFOLIO_REQUEST,
      payload,
      callback,
   };
}

export function finalizeReplaceModelPortfolioFetch(payload) {
   return {
      type: ActionTypes.FINALIZE_REPLACE_MODEL_PORTFOLIO_FETCH,
      payload,
   };
}

export function finalizeReplaceModelPortfolioSuccess(payload) {
   return {
      type: ActionTypes.FINALIZE_REPLACE_MODEL_PORTFOLIO_SUCCESS,
      payload,
   };
}

export function resetModelEnhancePortfoliosState() {
   return {
      type: ActionTypes.RESET_MODEL_ENHANCE_PORTFOLIOS_STATE,
   };
}

export function resetFundsPortfoliosState() {
   return {
      type: ActionTypes.RESET_FUNDS_ENHANCE_PORTFOLIOS_STATE,
   };
}

export function resetPortfolioState() {
   return {
      type: ActionTypes.RESET_PORTFOLIO_STATE,
   };
}

// ------ csv preflight request, get all tickers validate -------
export function checkTickerRequest(payload, callback) {
   return {
      type: ActionTypes.CHECK_TICKER_EXIST_REQUEST,
      payload,
      callback,
   };
}
export function checkTickerFetch(payload) {
   return {
      type: ActionTypes.CHECK_TICKER_EXIST_FETCH,
      payload,
   };
}
export function checkTickerSuccess(payload) {
   return {
      type: ActionTypes.CHECK_TICKER_EXIST_SUCCESS,
      payload,
   };
}

export function checkSingleTickerValidity(payload, callback) {
   return {
      type: ActionTypes.CHECK_SINGLE_TICKER_EXIST_REQUEST,
      payload,
      callback,
   };
}

export function setModelEnhancementData(payload) {
   return {
      type: ActionTypes.SET_MODEL_ENHANCEMENT_DATA,
      payload,
   };
}
