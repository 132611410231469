import { takeLatest, fork, call, put, all } from "redux-saga/effects";
import ActionTypes from "../action-types";
import * as fundsAPI from "../apis/funds";
import {
   getTredingSearchesSuccess,
   getViolatorSearchesLoading,
   getViolatorSearchesSuccess,
   getViolatorSearchesError,
} from "../actions/trendingsearches";

function* fetchTrendingSearches() {
   try {
      // yield put(getTredingSearchesLoading(true));
      const response = yield call(fundsAPI.getTrendingQueries);
      if (response) {
         yield put(getTredingSearchesSuccess(response));
      } else {
         throw new Error("empty response");
      }
      // yield put(getTredingSearchesLoading(false));
   } catch (error) {
      console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
      // yield put(getTredingSearchesLoading(false));
   }
}

function* fetchViolatorSearches() {
   try {
      yield put(getViolatorSearchesLoading(true));
      const response = yield call(fundsAPI.getViolatorQueries);
      if (response) {
         yield put(getViolatorSearchesSuccess(response));
      } else {
         throw new Error("empty response");
      }
      yield put(getViolatorSearchesLoading(false));
   } catch (error) {
      console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
      yield put(getViolatorSearchesError(error));
      yield put(getViolatorSearchesLoading(false));
   }
}

function* trendingSearchSaga() {
   yield all([
      fork(
         takeLatest,
         ActionTypes.GET_TRENDING_SEARCHES,
         fetchTrendingSearches
      ),
      fork(
         takeLatest,
         ActionTypes.GET_VIOLATOR_SEARCHES,
         fetchViolatorSearches
      ),
   ]);
}

export default trendingSearchSaga;
