import React, { useState, useEffect } from "react";
import {
   Dropdown,
   DropdownItem,
   DropdownToggle,
   DropdownMenu,
} from "reactstrap";
import moment from "moment";

function SSFDropDownDatePicker(props) {
   const [monthtoggle, setMonthToggle] = useState(false);
   const [monthValue, setMonthValue] = useState("");

   const [daytoggle, setDayToggle] = useState(false);
   const [dayValue, setDayValue] = useState("");

   const [yeartoggle, setYearToggle] = useState(false);
   const [yearValue, setYearValue] = useState("");

   const getYearsArray = () => {
      let currentYear = parseInt(moment().format("YYYY")),
         array = [];
      for (let i = currentYear; i >= 1900; i--) {
         array.push(i.toString());
      }
      return array;
   };

   const checkForMaxDate = (type, value) => {
      if (props.maxDate && moment(props.maxDate).isValid()) {
         if (
            type === "year" &&
            parseInt(value) > parseInt(moment(props.maxDate).format("YYYY"))
         ) {
            return false;
         } else if (
            type === "month" &&
            parseInt(value) > parseInt(moment(props.maxDate).format("MM")) &&
            yearValue &&
            parseInt(yearValue) >=
               parseInt(moment(props.maxDate).format("YYYY"))
         ) {
            return false;
         } else if (
            type === "day" &&
            parseInt(value) > parseInt(moment(props.maxDate).format("DD")) &&
            monthValue &&
            parseInt(monthValue) >=
               parseInt(moment(props.maxDate).format("MM")) &&
            yearValue &&
            parseInt(yearValue) >=
               parseInt(moment(props.maxDate).format("YYYY"))
         ) {
            return false;
         }
      }
      return true;
   };

   useEffect(() => {
      if (props.value && moment(props.value).isValid()) {
         setMonthValue(moment(props.value).format("MM"));
         setDayValue(moment(props.value).format("DD"));
         setYearValue(moment(props.value).format("YYYY"));
      }
   }, [props.value]);

   useEffect(() => {
      // if (props.maxDate && moment(props.maxDate).isValid()) {
      //   let monthInvalid = false, dayInvalid = false;
      //   if (monthValue && parseInt(monthValue) > parseInt(moment(props.maxDate).format('MM')) && yearValue && parseInt(yearValue) >= parseInt(moment(props.maxDate).format('YYYY'))) {
      //     monthInvalid = true
      //   }
      //   if (dayValue && parseInt(dayValue) > parseInt(moment(props.maxDate).format('DD')) && monthValue && parseInt(monthValue) >= parseInt(moment(props.maxDate).format('MM')) && yearValue && parseInt(yearValue) >= parseInt(moment(props.maxDate).format('YYYY'))) {
      //     dayInvalid = true
      //   }
      //   if(monthInvalid){
      //     setMonthValue('');
      //   }
      //   if(dayInvalid){
      //     setDayValue('');
      //   }
      //   if(monthInvalid || dayInvalid){
      //     return
      //   }
      // }

      if (monthValue && dayValue && yearValue) {
         props.onDateChange(
            moment(`${monthValue}-${dayValue}-${yearValue}`, "MM-DD-YYYY")._d
         );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [monthValue, dayValue, yearValue]);

   return (
      <div className="ssf_dropdown_datepicker">
         <div className="month_container">
            <Dropdown
               isOpen={monthtoggle}
               className={"dropdown-wrapper"}
               toggle={() => setMonthToggle(!monthtoggle)}
            >
               <DropdownToggle
                  value={monthValue}
                  style={{ backgroundColor: props.bg ? props.bg : "#fff" }}
                  className={"input-area"}
                  caret
               >
                  {monthValue
                     ? moment(monthValue, "MM").format("MMM")
                     : props.shortForm
                     ? "MM"
                     : "Month"}
               </DropdownToggle>
               <DropdownMenu
                  className="date_dopdown"
                  center
                  style={{ textAlign: "center" }}
               >
                  {[
                     "01",
                     "02",
                     "03",
                     "04",
                     "05",
                     "06",
                     "07",
                     "08",
                     "09",
                     "10",
                     "11",
                     "12",
                  ].map(item => {
                     if (checkForMaxDate("month", item)) {
                        return (
                           <DropdownItem
                              value={item}
                              onClick={e => {
                                 setMonthValue(e.target.value);
                              }}
                           >
                              {moment(item, "MM").format("MMMM")}
                           </DropdownItem>
                        );
                     }
                     return null;
                  })}
               </DropdownMenu>
            </Dropdown>
         </div>
         <div className="day_container">
            <Dropdown
               isOpen={daytoggle}
               className={"dropdown-wrapper"}
               toggle={() => setDayToggle(!daytoggle)}
            >
               <DropdownToggle
                  value={dayValue}
                  style={{ backgroundColor: props.bg ? props.bg : "#fff" }}
                  className={"input-area"}
                  caret
               >
                  {dayValue ? dayValue : props.shortForm ? "DD" : "Day"}
               </DropdownToggle>
               <DropdownMenu
                  className="date_dopdown"
                  center
                  style={{ textAlign: "center" }}
               >
                  {[
                     "01",
                     "02",
                     "03",
                     "04",
                     "05",
                     "06",
                     "07",
                     "08",
                     "09",
                     "10",
                     "11",
                     "12",
                     "13",
                     "14",
                     "15",
                     "16",
                     "17",
                     "18",
                     "19",
                     "20",
                     "21",
                     "22",
                     "23",
                     "24",
                     "25",
                     "26",
                     "27",
                     "28",
                     "29",
                     "30",
                     "31",
                  ].map(item => {
                     if (checkForMaxDate("day", item)) {
                        return (
                           <DropdownItem
                              className={"item"}
                              value={item}
                              onClick={e => {
                                 setDayValue(e.target.value);
                              }}
                           >
                              {item}
                           </DropdownItem>
                        );
                     }
                     return null;
                  })}
               </DropdownMenu>
            </Dropdown>
         </div>
         <div className="year_container">
            <Dropdown
               isOpen={yeartoggle}
               className={"dropdown-wrapper"}
               toggle={() => setYearToggle(!yeartoggle)}
            >
               <DropdownToggle
                  value={yearValue}
                  style={{ backgroundColor: props.bg ? props.bg : "#fff" }}
                  className={"input-area"}
                  caret
               >
                  {yearValue ? yearValue : props.shortForm ? "YY" : "Year"}
               </DropdownToggle>
               <DropdownMenu
                  className="date_dopdown"
                  center
                  style={{ textAlign: "center" }}
               >
                  {getYearsArray().map(item => {
                     if (checkForMaxDate("year", item)) {
                        return (
                           <DropdownItem
                              className={"item"}
                              value={item}
                              onClick={e => {
                                 setYearValue(e.target.value);
                              }}
                           >
                              {item}
                           </DropdownItem>
                        );
                     }
                     return null;
                  })}
               </DropdownMenu>
            </Dropdown>
         </div>
      </div>
   );
}

export default SSFDropDownDatePicker;
