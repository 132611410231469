import { takeEvery, fork, call, put, all, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import qs from "query-string";
import get from "lodash/get";
import { push } from "connected-react-router";

import ActionTypes from "action-types";
import * as actions from "actions/auth";
import * as profileActions from "actions/profile";
import * as globalActions from "actions/global";
import { setPortfolioState } from "actions/portfolio";
import { getGlobalState } from "selectors/global";

import config from "env";
import * as AuthApi from "apis/auth";
import * as ProfileApi from "apis/profile";
import {
   oktaAuth,
   SCOPES,
   CLIENT_ID,
   REDIRECT_URI,
   OKTA_AUTHENTICATOR,
} from "oktaAuth";
// import { REDIRECT_DOMAIN_PATH } from 'DataSet';
import {
   searchUrlV3,
   jsonInURL,
   getAdditionalDetailsForRegister,
   clearUtmSession,
   addIdentifyEvent,
} from "Utils";
import {
   domainRedirectForRetailAdvisor,
   encryptPassword,
   openNotificationWithIcon,
} from "layouts/utils";

import * as AuthUtils from "../common/auth-utils";
import { addNewEventsToAnalytics } from "Utils";

function* auth(action) {
   const { payload, callback } = action;
   let oktaResponse = null;
   const isSharingAlphaUser = yield select(
      state => state.auth.userSourceCheck.is_sharing_alpha
   ) || false;
   try {
      if (payload) {
         const email = payload.email;
         let old_password = payload.password;
         // payload.password = encryptPassword(payload.password);
         payload.e = true;

         yield put(actions.authFetch());

         if ("query" in payload) {
            delete payload.query;
         }

         let requestPayload = {
            username: email,
            password: old_password,
         };

         if (payload.executOktaLoginFor === "register") {
            requestPayload = JSON.parse(JSON.stringify(payload));
            if ("e" in payload) {
               delete payload.e;
            }
            if ("executOktaLoginFor" in requestPayload) {
               delete requestPayload.executOktaLoginFor;
            }
         }

         console.log("payload --> ", payload);
         console.log("requestPayload --> ", requestPayload);

         oktaResponse = yield call(AuthApi.executOktaLogin, requestPayload);

         console.log("oktaResponse --> ", oktaResponse);

         // Handle Retail Redirection
         if (
            oktaResponse &&
            oktaResponse.status === "SUCCESS" &&
            AuthUtils.redirectOktaNonGroupMagnifiUsers(oktaResponse)
         ) {
            console.log("Navigating outsite global.magnifi.com.");
         } else if (
            oktaResponse &&
            oktaResponse.status === "PENDING" &&
            oktaResponse.nextStep &&
            oktaResponse.nextStep.name === "reenroll-authenticator"
         ) {
            yield put(
               actions.setAuthState({
                  oktaChangePasswordModal: true,
                  oktaChangePasswordToken: oktaResponse.stateToken,
               })
            );
         } else {
            if (
               oktaResponse.status === "PENDING" &&
               oktaResponse.messages &&
               Array.isArray(oktaResponse.messages) &&
               oktaResponse.messages.length &&
               oktaResponse.messages[0].i18n &&
               oktaResponse.messages[0].i18n.key &&
               oktaResponse.messages[0].i18n.key === "errors.E0000119"
            ) {
               throw new Error("Your account is locked.");
            } else {
               if (
                  oktaResponse.status === "SUCCESS" &&
                  oktaResponse.tokens &&
                  oktaResponse.tokens.accessToken &&
                  oktaResponse.tokens.accessToken.accessToken
               ) {
                  yield put(
                     actions.oktaAuthLoginRequest({
                        token: oktaResponse.tokens.accessToken.accessToken,
                     })
                  );

                  // NOTE: to stop all loader
                  // ------------------------------------------
                  yield put(actions.successfulAuth());
                  addNewEventsToAnalytics(
                     "Sign In",
                     {
                        cta_clicked: "continue",
                        is_SA_user: isSharingAlphaUser,
                        sign_in_succesful: true,
                        Email: payload.email,
                        id: payload.email,
                     },
                     true
                  );
                  // ------------------------------------------
               } else {
                  // NOTE: OLD FLOW, BEFORE "IDX"
                  // ----------------------------------------------------------------------------------------------------------
                  if (
                     oktaResponse &&
                     oktaResponse.sessionToken &&
                     oktaResponse.status === "SUCCESS"
                  ) {
                     console.log(
                        "oktaResponse.sessionToken -> ",
                        oktaResponse.sessionToken
                     );
                     // NOTE: for change-password case
                     // -----------------------------------------------------
                     yield put(actions.setOldPassword(old_password));
                     // -----------------------------------------------------

                     // NOTE: after OKTA login, redirect to '/'
                     // -----------------------------------------------------
                     const sessionToken = oktaResponse.sessionToken;
                     if (sessionToken) {
                        let __payload = {
                           sessionToken,
                           state: encodeURIComponent(
                              JSON.stringify({
                                 sourceURI:
                                    window.location.pathname === "/register"
                                       ? `/register?plan=basic`
                                       : `${window.location.pathname}`, // redirect user back to source path if something went wrong
                              })
                           ),
                        };
                        if (payload.executOktaLoginFor === "register") {
                           __payload.state = encodeURIComponent(
                              JSON.stringify({
                                 isNew: true,
                                 validateOktaUserFor: "register",
                                 sourceURI: `/register?plan=basic`, // `${window.location.pathname}`, // redirect user back to source path if something went wrong
                              })
                           );
                        }

                        if (payload.executOktaLoginFor === "google") {
                           __payload = {
                              scopes: SCOPES,
                              clientId: CLIENT_ID,
                              responseType: "code",
                              idp: "0oa5o7sccuy5YgrIz5d7",
                              redirectUri: REDIRECT_URI,
                              useInteractionCodeFlow: true,
                              state: encodeURIComponent(
                                 JSON.stringify({
                                    validateOktaUserFor: "google",
                                    sourceURI:
                                       window.location.pathname === "/register"
                                          ? `/register?plan=basic`
                                          : `${window.location.pathname}`, // redirect user back to source path if something went wrong
                                 })
                              ),
                           };
                        }

                        console.log("__payload --> ", __payload);
                        yield put(oktaAuth.signInWithRedirect(__payload));

                        if (callback && typeof callback === "function") {
                           callback(oktaResponse);
                        }
                        // let signInRedirectResponse = yield call(oktaAuth.signInWithRedirect, { sessionToken });
                        // console.log(signInRedirectResponse)
                        // if (signInRedirectResponse) {
                        //   if (callback && typeof callback === 'function') {
                        //     callback(oktaResponse);
                        //   }
                        // }
                     }
                     // -----------------------------------------------------
                  } else {
                     throw new Error("Authentication failed");
                  }

                  // ----------------------------------------------------------------------------------------------------------
               }
            }
         }
      } else {
         throw new Error("Email/Password missing");
      }
   } catch (error) {
      console.log(error);
      let errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      console.log(oktaResponse);
      if (!errorDetails && error && error.includes("locked")) {
         errorDetails = {
            error,
         };
      }
      if (
         !errorDetails ||
         (!errorDetails.error &&
            errorDetails &&
            errorDetails.includes("Authentication failed"))
      ) {
         errorDetails = {
            error: "Authentication failed",
         };
         addNewEventsToAnalytics("Sign In", {
            cta_clicked: "continue",
            is_SA_user: isSharingAlphaUser,
            sign_in_succesful: false,
         });
      }
      let _error =
         errorDetails && errorDetails.error
            ? errorDetails
            : { error: errorDetails };
      AuthUtils.deleteAuthToken();
      yield put(actions.failedAuth(_error));
      if (callback && typeof callback === "function") {
         callback(_error);
      }
      // openNotificationWithIcon({
      //   duration: 5,
      //   type: 'error',
      //   message: 'Error',
      //   className: 'api-response-notification-class',
      //   description: errorDetails && errorDetails.error ? errorDetails.error : errorDetails,
      // });
   }
}

function* oktaChangePassword(action) {
   const { payload, callback } = action;
   let oktaResponse = null;
   try {
      if (payload && payload.password) {
         yield put(actions.oktaChangePasswordFetch());
         let requestPayload = {
            authenticator: OKTA_AUTHENTICATOR,
            newPassword: payload.password,
         };
         console.log("payload --> ", payload);
         console.log("requestPayload --> ", requestPayload);

         oktaResponse = yield call(
            AuthApi.executOktaIdxChangePassword,
            requestPayload
         );

         console.log("oktaResponse --> ", oktaResponse);

         if (
            oktaResponse.status === "PENDING" &&
            oktaResponse.messages &&
            Array.isArray(oktaResponse.messages) &&
            oktaResponse.messages.length &&
            oktaResponse.messages[0].message
         ) {
            yield put(
               actions.oktaChangePasswordSuccess({
                  oktaChangePasswordError: oktaResponse.messages[0].message,
               })
            );
         } else {
            if (
               oktaResponse.status === "SUCCESS" &&
               oktaResponse.tokens &&
               oktaResponse.tokens.accessToken &&
               oktaResponse.tokens.accessToken.accessToken
            ) {
               yield put(
                  actions.oktaAuthLoginRequest({
                     token: oktaResponse.tokens.accessToken.accessToken,
                  })
               );

               yield put(
                  actions.oktaChangePasswordSuccess({
                     oktaChangePasswordModal: false,
                  })
               );
            }
         }

         // NOTE: to stop all loader
         // ------------------------------------------
         yield put(actions.successfulAuth());
         // ------------------------------------------
      } else {
         throw new Error("Password is missing");
      }
   } catch (error) {
      let errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      console.log(oktaResponse);
      if (
         !errorDetails ||
         (!errorDetails.error &&
            errorDetails &&
            errorDetails.includes("Authentication failed"))
      ) {
         errorDetails = {
            error: "Authentication failed",
         };
      }
      let _error =
         errorDetails && errorDetails.error
            ? errorDetails
            : { error: errorDetails };
      // NOTE: to stop all loader
      // ------------------------------------------
      AuthUtils.deleteAuthToken();
      yield put(
         actions.successfulAuth({
            loggedIn: false,
            loggingIn: false,
            authFailed: true,
            error: _error,
            newUser: 0,
            termsAgreed: 0,
         })
      );
      yield put(actions.oktaChangePasswordSuccess());
      // ------------------------------------------
      if (callback && typeof callback === "function") {
         callback(_error);
      }
   }
}

function* oktaAuthLogin(action) {
   let newUser = null,
      userData = null,
      authAccessToken = null,
      loginDataResponse = null,
      openCalendarInviteModal = null;
   const { payload, callback } = action;
   try {
      console.log("LOGGG oktaAuthLogin saga --> ", payload);
      if (payload && payload.token) {
         yield put(actions.oktaAuthLoginFetch());

         // NOTE: Get query from discovery reducer's state
         // --------------------------------------------------------------------------------------------
         const discoveryQuery = yield select(state => state.discovery.query) ||
            "";
         const query =
            payload.query ||
            discoveryQuery ||
            window.sessionStorage.getItem("query");
         // --------------------------------------------------------------------------------------------

         if ("query" in payload) {
            delete payload.query;
         }

         const requestPayload = JSON.parse(JSON.stringify(payload));
         if (requestPayload.sourceUrl) {
            delete requestPayload.sourceUrl;
         }

         // NOTE: Delete old Auth "TOKEN"
         // --------------------------------------------
         AuthUtils.deleteAuthToken();
         // --------------------------------------------

         console.log("LOGGG payload --> ", payload);

         if (
            payload.validateOktaUserFor === "register" ||
            payload.validateOktaUserFor === "google"
         ) {
            // payload ->> {"access_token":"actual value","uid":"00u5lwc23vd7ShiGm5d7","termsCondition":true,"freeTrial":true}
            console.log(
               "LOGGG AuthApi.validateOktaUser access_token -> ",
               payload.token
            );
            loginDataResponse = yield call(AuthApi.validateOktaUser, {
               ...getAdditionalDetailsForRegister(),
               termsCondition: payload.termsCondition || false,
               region: payload.market,
               zipcode: payload.zipcode,
               country: payload.country,
               access_token: payload.token,
               market: "international",
            });
            console.log("LOGGG loginDataResponse --> ", loginDataResponse);
            // debugger
            if (loginDataResponse && typeof loginDataResponse === "object") {
               if (
                  (typeof loginDataResponse.register_step_status !==
                     "undefined" &&
                     loginDataResponse.register_step_status === "") ||
                  !loginDataResponse.original_self_role // which means profile data is incomplete
               ) {
                  if (callback && typeof callback === "function") {
                     callback(loginDataResponse);
                     yield put(actions.oktaAuthLoginSuccess(loginDataResponse));
                     return;
                     // NOTE: ---- EVERYTHING ENDS HERE ----
                  }
               } else {
                  if (
                     loginDataResponse.email &&
                     Array.isArray(loginDataResponse.email) &&
                     loginDataResponse.email[0] ===
                        "E-mail ID already registered"
                  ) {
                     // AUTO LOGIN with token
                     console.log(
                        " LOGGG token for oktaAuthLoginRequest -> ",
                        payload.token
                     );
                     // NOTE: New action starts from here
                     // --------------------------------------------
                     yield put(
                        actions.oktaAuthLoginRequest({
                           token: payload.token,
                        })
                     );
                     // --------------------------------------------
                  } else {
                     // NOTE: status code 200 or 201 area
                     // --------------------------------------------

                     authAccessToken = payload.token;

                     if (typeof loginDataResponse.profile === "undefined") {
                        // User registered! response (status code -> 201)
                        console.log(
                           "LOGGG ========== New user created response (status code -> 201) =========="
                        );

                        AuthUtils.storeAuthToken({
                           token: authAccessToken,
                           email: loginDataResponse.email,
                           lastName:
                              loginDataResponse.lastName ||
                              loginDataResponse.last_name,
                           firstName:
                              loginDataResponse.firstName ||
                              loginDataResponse.first_name,
                           self_role:
                              loginDataResponse.original_self_role ||
                              loginDataResponse.self_role,
                           profile_self_role:
                              loginDataResponse.original_self_role ||
                              loginDataResponse.self_role,
                        });

                        openCalendarInviteModal = true;
                        newUser = loginDataResponse.new_user || false;

                        let profileResponse = yield call(
                           ProfileApi.fetchProfile
                        );
                        if (profileResponse && profileResponse.data) {
                           userData = profileResponse.data;
                        } else {
                           throw new Error("Failed to fetch PROFILE api data");
                        }
                     } else {
                        console.log("LOGGG token -> ", payload.token);
                        // User already exist response (status code -> 200)
                        console.log(
                           "========== User already exist response (status code -> 200) =========="
                        );
                        userData = loginDataResponse;
                     }

                     console.log(
                        "userData from 200 or 201 area --> ",
                        userData
                     );
                     // --------------------------------------------
                  }
               }
            } else {
               throw new Error("Failed to fetch profile data");
            }
         } else {
            // NOTE: "FOR LOGIN WORKFLOW"
            // NOTE: Snehal changed the end-point, now validate-okta
            // ---------------------------------------------------------------------------

            // before it was /login api
            // loginDataResponse = yield call(AuthApi.getOktaLoginData, {
            //   token: payload.token,
            // });

            // now it's
            userData = yield call(AuthApi.validateOktaUser, {
               ...getAdditionalDetailsForRegister(),
               // termsCondition: false,
               access_token: payload.token,
               country: payload.country,
               termsCondition: payload.termsCondition,
               market: "international",
            });
            authAccessToken = payload.token;
            console.log("LOGGG userData from login flow --> ", userData);

            // ---------------------------------------------------------------------------
         }

         // NOTE: GOOD TO GO
         // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
         if (userData && typeof userData === "object") {
            if (typeof userData.profile === "undefined") {
               userData.profile = userData;
            }

            const email = userData.profile.email;
            const token = userData.token || null;
            const self_role = userData.original_self_role || null;
            const remind_for_password_change =
               userData.remind_for_password_change;

            userData.token = authAccessToken;
            userData.email = userData.profile.email;
            userData.firstName = userData.profile.first_name;
            userData.lastName = userData.profile.last_name;

            /** NOTE- To Handle remind_for_password_change   */
            if (remind_for_password_change) {
               // yield put(actions.successfulAuth(result))
               yield put(actions.oktaAuthLoginSuccess(userData));
               // yield put(actions.setOldPassword(old_password)); <-- calling inside "auth*" function
               yield put(push("/changepwd"));
            } else {
               if (config.advisorRetailRedirect && token) {
                  const redirectTo = domainRedirectForRetailAdvisor({
                     token,
                     email,
                     selfRole: self_role,
                  });
                  if (redirectTo) {
                     window.location.href = redirectTo;
                     return;
                  }
                  // yield put(oktaAuth.signInWithRedirect({
                  //   redirectUri: config.advisor_redirect + '/auth/callback',
                  //    scopes: SCOPES,
                  //    sessionToken,
                  // }));
               }

               // console.log('==== GOOD TO GO ====');
               AuthUtils.storeAuthToken({
                  token: authAccessToken,
                  email: userData.email,
                  lastName: userData.lastName,
                  firstName: userData.firstName,
                  self_role: userData.self_role,
                  client_exist: userData.client_exist,
                  termsAgreed: userData.profile.terms_agreed,
                  user_advisor_menu: userData.user_advisor_menu,
                  profile_self_role: userData.profile.self_role,
                  restricted_universe:
                     userData.profile.restricted_universe || false,
               });

               clearUtmSession();

               // NOTE: Segment Script
               // ------------------------------------------------------------------------------------------
               addIdentifyEvent(userData.email, {
                  from: "Auth",
                  user_type: "Advisor",
                  email: userData.email,
                  lastName: userData.lastName,
                  firstName: userData.firstName,
                  name: userData.firstName + " " + userData.lastName,
               });
               // ------------------------------------------------------------------------------------------

               // addEventToAnalytics('Login', 'Login', 'LOGIN', {
               //   email: userData.email
               // })
               // NOTE: Google in login page triggers 'handleGoogleSignInError'
               // Which set 'loggedIn: false', to resolve this -> `loggedInByToken` used in reducers/auth.js -> GOOGLE_AUTH_FAILURE
               // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
               yield put(
                  actions.oktaAuthLoginSuccess({
                     user: {
                        ...userData,
                        premiumUser: false,
                        loggedInByToken:
                           payload.token &&
                           typeof payload.token !== "undefined",
                     },
                     loggedIn: true,
                     loggingIn: false,
                     authSuccess: true,
                     isGoogleLogin: payload.validateOktaUserFor === "google",
                     token: authAccessToken,
                     openCalendarInviteModal,
                     newUser: newUser || false,
                     client_exist: userData.client_exist,
                     termsAgreed: userData.profile.terms_agreed,
                     user_advisor_menu: userData.user_advisor_menu,
                     remind_for_password_change:
                        userData.remind_for_password_change,
                     loggedInByToken:
                        payload.token && typeof payload.token !== "undefined",
                  })
               );
               window.localStorage.setItem(
                  "isGoogleLogin",
                  payload.validateOktaUserFor === "google"
               );
               // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

               if (payload.validateOktaUserFor === "google") {
                  addNewEventsToAnalytics("Sign In", {
                     cta_clicked: "continue_with_google",
                     sign_in_succesful: true,
                  });
               }
               yield put(profileActions.loadProfile(userData.profile));
               // yield put(profileActions.subscriptionPlan());

               if (payload.market) {
                  yield put(
                     globalActions.getGlobalMarketStateRequest({
                        market: payload.market || "USA",
                        isRegion: payload.isRegion || false,
                     })
                  );
               }

               const { surveyModal } = yield select(getGlobalState);
               if (surveyModal) {
                  yield put(
                     globalActions.setGlobalState({ surveyModal: false })
                  );
               }

               const loggedIn = (yield select(state => state.auth)).loggedIn;
               // const location = yield select(state => state.router.location);
               //const remind_for_password_change = (yield select(state => state.auth)).remind_for_password_change;
               if (loggedIn || window.localStorage.getItem("token")) {
                  const isCommunityClick =
                     window.sessionStorage.getItem("postLoginRedirection") ===
                        "/community" || payload.flow === "community";
                  let __redirectionUrl = isCommunityClick
                     ? "/community/dashboard"
                     : searchUrlV3(query);
                  if (
                     typeof payload.sourceUrl !== "undefined" &&
                     payload.sourceUrl
                  ) {
                     __redirectionUrl = `${jsonInURL(
                        {},
                        `/${payload.sourceUrl}`
                     )}`;
                  }
                  console.log("login flow google-login -> ", __redirectionUrl);
                  yield put(push(__redirectionUrl));
               }
            }
         } else {
            throw new Error("Failed to fetch USER_DATA");
         }

         // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

         // if (callback && typeof callback === 'function') {
         //   callback(loginDataResponse);
         // }
      } else {
         throw new Error("-- Payload Missing --");
      }
   } catch (error) {
      console.log("error =>", error);
      let errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      console.log(userData);
      if (
         !errorDetails ||
         (errorDetails &&
            (!errorDetails.error ||
               errorDetails.includes("Authentication failed")))
      ) {
         errorDetails = {
            error: "Authentication failed",
         };
      }
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Error",
         className: "api-response-notification-class",
         description:
            errorDetails && errorDetails.error
               ? errorDetails.error
               : errorDetails,
      });
      AuthUtils.deleteAuthToken();
      yield put(actions.oktaAuthLoginSuccess(errorDetails));
      if (callback && typeof callback === "function") {
         callback(errorDetails);
      }
   }
}

// function* auth(action) {
//   const { payload } = action
//   try {
//     let old_password = payload.password;
//     // const ps = encryptPassword(payload.password)
//     payload.password = encryptPassword(payload.password);
//     payload.e = true;
//
//     // NOTE: Get query from discovery reducer's state
//     // --------------------------------------------------------------------------------------------
//     const discoveryQuery = yield select(state => state.discovery.query) || '';
//     // --------------------------------------------------------------------------------------------
//
//     const email = payload.email;
//     const query = payload.query || discoveryQuery || window.sessionStorage.getItem('query');
//
//     if ('query' in payload) {
//       delete payload.query;
//     }
//
//     const response = yield call(AuthApi.authenticate, qs.stringify(payload))
//
//     const result = response.data;
//     const token = result.token || null ;
//     const self_role = result.original_self_role || null;
//     const remind_for_password_change = result.remind_for_password_change;
//
//     /** NOTE- To Handle remind_for_password_change   */
//     if (remind_for_password_change) {
//       yield put(actions.successfulAuth(result))
//       yield put(actions.setOldPassword(old_password));
//       yield put(push('/changepwd'))
//     } else {
//       if (config.advisorRetailRedirect && !payload.token) {
//         const redirectTo = domainRedirectForRetailAdvisor({
//           selfRole: self_role,
//           token,
//           email,
//         });
//         if (redirectTo) {
//           window.location.href = redirectTo;
//           return;
//         }
//       }
//
//       // console.log('==== GOOD TO GO ====');
//       AuthUtils.storeAuthToken({
//         token: result.token,
//         email: payload.email,
//         firstName: result.profile.first_name,
//         lastName: result.profile.last_name,
//         termsAgreed: result.profile.terms_agreed,
//         self_role: result.self_role,
//         client_exist: result.client_exist,
//         user_advisor_menu: result.user_advisor_menu,
//         profile_self_role: result.profile.self_role,
//         restricted_universe: result.profile.restricted_universe || false,
//       })
//
//       result.email = payload.email
//       result.firstName = result.profile.first_name
//       result.lastName = result.profile.last_name
//
//       if (window.heap) {
//         window.heap.identify(payload.email);
//         window.heap.addUserProperties({'First Name': result.profile.first_name,'Last Name': result.profile.last_name});
//         window.heap.track('Login',{email:payload.email});
//       }
//
//       window.addkrakenUser && typeof window.addkrakenUser === "function" && window.addkrakenUser({
//         email: payload.email,
//         firstName: result.profile.first_name,
//         lastName:result.profile.last_name,
//       });
//
//       // NOTE: Segment Script
//       // ------------------------------------------------------------------------------------------
//       // ------------------------------------------------------------------------------------------
//
//       addEventToAnalytics('Login','Login','LOGIN',{ email: payload.email });
//       // NOTE: Google in login page triggers 'handleGoogleSignInError'
//       // Which set 'loggedIn: false', to resolve this -> `loggedInByToken` used in reducers/auth.js -> GOOGLE_AUTH_FAILURE
//       // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//       yield put(actions.successfulAuth({ ...result, loggedInByToken: (payload.token && typeof payload.token !== 'undefined') }))
//       // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//       // yield put(sfmActions.setSFMState({
//       //   isRedirectFromHeaderButtons: true,
//       //   autoOpenModal: false,
//       // })) // removed because it was blocking auto-open modal after sign in
//       yield put(profileActions.loadProfile(result.profile))
//       yield put(profileActions.subscriptionPlan())
//
//       if (result && result.profile) {
//         const { share_class } = result.profile;
//         if (share_class && typeof share_class !== 'undefined') {
//           yield put(setPortfolioState({ shareClass: share_class }));
//         } else {
//           console.log('======= SHARE CLASS NOT PRESENT =======');
//         }
//       }
//
//       const { surveyModal } = yield select(getGlobalState);
//       if (surveyModal) {
//         yield put(globalActions.setGlobalState({ surveyModal: false }));
//       }
//
//       const loggedIn = (yield select(state => state.auth)).loggedIn;
//       //const remind_for_password_change = (yield select(state => state.auth)).remind_for_password_change;
//       if (loggedIn || window.localStorage.getItem('token')) {
//         const securitiesPath = searchUrlV3(query); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
//         console.log('login flow google-login -> ', securitiesPath)
//         yield put(push(securitiesPath));
//       }
//
//     }
//
//   } catch (error) {
//     const errorDetails = get(error, 'response.data', error.stack)
//     yield put(actions.failedAuth(errorDetails))
//   }
// }

function* termsAgreement() {
   try {
      yield put(actions.termsAgreementFetch());
      const payload = {
         terms: "y",
      };
      const response = yield call(AuthApi.acceptsTerms, qs.stringify(payload));
      if (response) {
         localStorage.setItem("termsAgreed", 1);
         yield put(
            actions.termsAgreementSuccess({
               terms_agreed: 1,
               accConfirmedModal: true,
            })
         );
         yield put(
            profileActions.profileDataSuccess({
               terms_agreed: true,
            })
         );
         // yield put(push('/account-confirm'))
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      yield put(actions.termsAgreementFailed(errorDetails));
   }
}

function* logout(action) {
   try {
      // yield call(
      //   AuthApi.logout,
      //   qs.stringify({ email: AuthUtils.getAuthToken().email })
      // )
      // AuthUtils.deleteAuthToken()
      // yield put(actions.successfulLogout(response))
      //
      // window.location.href = REDIRECT_DOMAIN_PATH;
   } catch (error) {
      console.log("--- LOGOUT ERROR ---");
      console.log(error);
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      console.log("--------------------");
      // yield put(actions.failedLogout(errorDetails))
   } finally {
      let oktaResponse = yield call(AuthApi.executOktaLogout);
      AuthUtils.deleteAuthToken();
      yield put(actions.successfulLogout());
      console.log("oktaResponse --> ", oktaResponse);
      yield call(AuthApi.checkSessionClosePostLogout);
   }

   // window.location.reload(); //TODO: instead reset Redux store
}

function* sendChangePwdEmail(action) {
   const { payload, callback } = action;
   let response = null;
   try {
      if (payload && payload.email) {
         response = yield call(AuthApi.executeOktaResetPassword, {
            username: payload.email,
            factorType: "EMAIL",
         });
         console.log("response --> ", response);
         // factorResult: "WAITING"
         // factorType: "EMAIL"
         // recoveryType: "PASSWORD"
         // status: "RECOVERY_CHALLENGE"
         if (response && response.status === "RECOVERY_CHALLENGE") {
            callback && typeof callback === "function" && callback("success");
            yield put(push("/forgotpwdthankyou"));
         }
      } else {
         throw new Error("-- Email is missing in payload --");
      }
   } catch (error) {
      let errorDetails =
         error && error.toString()
            ? error.toString()
            : get(error, "response.data", error.stack);
      console.log(errorDetails);
      console.log(response);
      if (
         !errorDetails ||
         (errorDetails &&
            (!errorDetails.error || errorDetails.includes("AuthApiError:")))
      ) {
         if (errorDetails.includes("AuthApiError:")) {
            let errorText = errorDetails
               .split("AuthApiError: ")
               .filter(e => e.length)[0];
            errorDetails = {
               error: errorText,
            };
         } else {
            errorDetails = {
               error: errorDetails,
            };
         }
      }
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Error",
         className: "api-response-notification-class",
         description:
            errorDetails && errorDetails.error
               ? errorDetails.error
               : errorDetails,
      });
      yield put(actions.oktaAuthLoginSuccess(errorDetails));
      if (callback && typeof callback === "function") {
         callback(errorDetails);
      }
   }
}

function* resendPasswordResetEmail(action) {
   const { payload, callback } = action;
   let oktaResponse = null;
   try {
      if (payload && payload.email) {
         yield put(actions.resendPasswordResetEmailFetch());

         oktaResponse = yield call(AuthApi.executeOktaResetPassword, {
            username: payload.email,
            factorType: "EMAIL",
         });

         console.log("response --> ", oktaResponse);
         // factorResult: "WAITING"
         // factorType: "EMAIL"
         // recoveryType: "PASSWORD"
         // status: "RECOVERY_CHALLENGE"

         if (oktaResponse && oktaResponse.status === "RECOVERY_CHALLENGE") {
            callback &&
               typeof callback === "function" &&
               callback({
                  oktaPasswordResetEmailSent: true,
               });
         }
         yield put(actions.resendPasswordResetEmailSuccess());
      } else {
         throw new Error("Email is missing");
      }
   } catch (error) {
      let errorDetails =
         error && error.toString()
            ? error.toString()
            : get(error, "response.data", error.stack);
      console.log(errorDetails);
      console.log(oktaResponse);
      if (
         !errorDetails ||
         (errorDetails &&
            (!errorDetails.error || errorDetails.includes("AuthApiError:")))
      ) {
         if (errorDetails.includes("AuthApiError:")) {
            let errorText = errorDetails
               .split("AuthApiError: ")
               .filter(e => e.length)[0];
            errorDetails = {
               error: errorText,
            };
         } else {
            errorDetails = {
               error: errorDetails,
            };
         }
      }
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Error",
         className: "api-response-notification-class",
         description:
            errorDetails && errorDetails.error
               ? errorDetails.error
               : errorDetails,
      });
      yield put(actions.resendPasswordResetEmailSuccess(errorDetails));
      if (callback && typeof callback === "function") {
         callback();
      }
   }
}

function* verifyChangePwdToken(action) {
   const { payload, callback } = action;
   try {
      const response = yield call(AuthApi.verifyChangePwdToken, payload);

      if (response.status === 200) {
         const { name, token, message } = response.data;
         yield put(
            actions.saveverifyChangePwdToken({
               name: name || "",
               token: token || "",
            })
         );
         if (message) {
            yield put(push("/invalidtoken"));
         }
         if (callback) {
            callback();
         }
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack) || {
         message: "Token invalid",
      };
      console.log(errorDetails);
      yield put(push("/invalidtoken"));
   }
}

function* changePassword(action) {
   let { payload } = action;
   try {
      // let key = CryptoJS.enc.Utf8.parse('[fGJq$4ZGC~jjZtF');
      // let iv = CryptoJS.lib.WordArray.random(16);
      // let ps = CryptoJS.AES.encrypt(payload.values.password, key, {
      //   iv: iv,
      // });
      // ps = iv.concat(ps.ciphertext).toString(CryptoJS.enc.Base64)
      //
      // payload.values.password = ps;

      // NOTE: Encrypt password
      // --------------------------------------------------------------------
      // payload.values.password = encryptPassword(payload.values.password)
      // --------------------------------------------------------------------
      const old_password = encryptPassword(payload.old_password);
      const currentPassword =
         payload.values && payload.values.password
            ? payload.values.password
            : payload.password;
      const encryptedPassword = encryptPassword(currentPassword);
      const requestPayload = {
         email: payload.email,
         old_password: old_password,
         password: encryptedPassword,
         verifypwdtoken: payload.token,
      };
      if (typeof payload.terms_agreed !== "undefined") {
         requestPayload.terms_agreed = payload.terms_agreed;
      }
      const response = yield call(AuthApi.changePassword, requestPayload);
      if (response && response.status === 200) {
         yield put(actions.successfulSetPassword(response));
         const _email = (yield select(state => state.auth)).user.profile.email;
         /**NOTE- To Call Login API Again After Change Password  */
         console.log("payload --> ", payload);
         if (_email) {
            yield put(
               actions.auth({
                  email: _email,
                  password: currentPassword,
               })
            );
         }
      }
   } catch (error) {
      if (error && error.response && error.response.status === 400) {
         openNotificationWithIcon({
            duration: null,
            type: "error",
            message: "Failed",
            className: "api-response-notification-class",
            description: error.response.data.error,
         });
      }
   }
}

function* autoLoginUser(action) {
   const { payload } = action;
   try {
      const { query, params, token, result, redirect } = payload;
      console.log("--- AUTO LOGGING IN ---");
      console.log(params);
      console.log(query, token, result);
      console.log("-----------------------");
      if (token && result) {
         if (result.profile) {
            // ---------------------------- AUTO LOGIN actions starts here ----------------------------
            AuthUtils.storeAuthToken({
               token: result.token,
               email: result.profile.email,
               firstName: result.profile.first_name,
               lastName: result.profile.last_name,
               termsAgreed: result.profile.terms_agreed,
               self_role: result.self_role,
               client_exist: result.client_exist,
               user_advisor_menu: result.user_advisor_menu,
               profile_self_role: result.profile.self_role,
               restricted_universe: result.profile.restricted_universe || false,
            });

            result.email = result.profile.email;
            result.firstName = result.profile.first_name;
            result.lastName = result.profile.last_name;

            // NOTE: Kraken, Heap and Segment Script
            // ------------------------------------------------------------------------------------------
            // if (window.heap) {
            //    window.heap.identify(result.profile.email);
            //    window.heap.addUserProperties({
            //       "First Name": result.profile.first_name,
            //       "Last Name": result.profile.last_name,
            //    });
            //    window.heap.track("Login", {
            //       email: result.profile.email,
            //    });
            // }
            // window.addkrakenUser &&
            //    typeof window.addkrakenUser === "function" &&
            //    window.addkrakenUser({
            //       email: result.profile.email,
            //       firstName: result.profile.first_name,
            //       lastName: result.profile.last_name,
            //    });
            addIdentifyEvent(result.profile.email, {
               from: "Auth",
               user_type: "Advisor",
               name: result.profile.first_name + " " + result.profile.last_name,
               firstName: result.profile.first_name,
               lastName: result.profile.last_name,
               email: result.profile.email,
            });
            // ------------------------------------------------------------------------------------------

            // addEventToAnalytics('Login', 'Login', 'LOGIN', {
            //   email: result.profile.email
            // })
            // NOTE: Google in login page triggers 'handleGoogleSignInError'
            // Which set 'loggedIn: false', to resolve this -> `loggedInByToken` used in reducers/auth.js -> GOOGLE_AUTH_FAILURE
            // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            yield put(
               actions.successfulAuth({
                  ...result,
                  loggedInByToken: token && typeof token !== "undefined",
               })
            );
            // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

            yield put(profileActions.loadProfile(result.profile));
            yield put(profileActions.subscriptionPlan());

            if (result && result.profile) {
               const { share_class } = result.profile;
               if (share_class && typeof share_class !== "undefined") {
                  yield put(setPortfolioState({ shareClass: share_class }));
               } else {
                  console.log("======= SHARE CLASS NOT PRESENT =======");
               }
            }

            const { surveyModal } = yield select(getGlobalState);
            if (surveyModal) {
               yield put(globalActions.setGlobalState({ surveyModal: false }));
            }

            // ---------------------------- AUTO LOGIN actions ends here ----------------------------

            if (redirect) {
               // NOTE: auto-login and redirect to discovery page
               // ----------------------------  ----------------------------
               const loggedIn = (yield select(state => state.auth)).loggedIn;
               //const remind_for_password_change = (yield select(state => state.auth)).remind_for_password_change;
               if (loggedIn || window.localStorage.getItem("token")) {
                  console.log("params --> ", params);
                  // const securitiesPath =
                  //    jsonInURL(params, "/securities") || searchUrlV3(query); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
                  yield put(push("/"));
               }
               // ------------------------------------------------------------------------------------
            }
         } else {
            throw new Error("-- Missing reponse data --");
         }
      } else {
         throw new Error("-- Missing token and reponse data --");
      }
   } catch (error) {
      console.log(error);
   }
}

function* validateLink(action) {
   let { payload, callback } = action;
   try {
      if (payload && payload.token) {
         yield put(actions.validateLinkFetch());
         // NOTE: Get query from discovery reducer's state
         // --------------------------------------------------------------------------------------------
         const discoveryQuery = yield select(state => state.discovery.query) ||
            "";
         // --------------------------------------------------------------------------------------------
         const query =
            payload.query ||
            discoveryQuery ||
            window.sessionStorage.getItem("query");
         const requestPayload = {
            token: payload.token,
         };
         let params = {};
         if (payload.resetPassword) {
            requestPayload.is_reset = true;
         }
         if (
            payload.params &&
            typeof payload.params === "object" &&
            Object.keys(payload.params)
         ) {
            params = payload.params;
         }
         const response = yield call(
            AuthApi.validateSharingAlphaLink,
            requestPayload
         );
         if (response) {
            if (
               response.email &&
               (typeof payload.email === "undefined" ||
                  !payload.email ||
                  payload.email === "--")
            ) {
               payload.email = response.email;
            }
            if (payload.resetPassword && payload.email) {
               yield put(
                  actions.autoLoginUserRequest({
                     query,
                     params,
                     redirect: false,
                     result: response,
                     token: payload.token,
                  })
               );
               const verifyToken = yield call(
                  AuthApi.verifyChangePwdToken,
                  payload.token
               );
               if (verifyToken && verifyToken.status === 200) {
                  // console.log(verifyToken.data)
                  const { token: verifiedAuthToken, message } =
                     verifyToken.data;
                  if (message) {
                     // NOTE: message can be "Session Expired"
                     // ----------------------------------------------------------------------------------------------------
                     throw message;
                     // ----------------------------------------------------------------------------------------------------
                  } else {
                     // console.log('verifiedAuthToken --> ', verifiedAuthToken);
                     // NOTE: State -> ['EMAIL_STATUS_WARNING', 'EMAIL_STATUS_SUCCESS', 'CREATE_PASSWORD', 'NEW_USER_EMAIL']
                     // NOTE: When 'smart link' contains 'email' & 'password' param re-direct user to reset-password screen
                     // ----------------------------------------------------------------------------------------------------
                     yield put(
                        actions.validateLinkSuccess({
                           smartLinkRequest: payload,
                           smartLinkResponse: {
                              ...response,
                              verifiedAuthToken,
                           },
                           sharingAlphaScreen: "CREATE_PASSWORD",
                        })
                     );
                     // ----------------------------------------------------------------------------------------------------
                  }
               } else {
                  throw new Error("Error: Failed to verify token");
               }
            } else if (response.result === "Link is expired") {
               // NOTE: State -> ['EMAIL_STATUS_WARNING', 'EMAIL_STATUS_SUCCESS', 'CREATE_PASSWORD', 'NEW_USER_EMAIL']
               // NOTE: When 'smart link' expired
               // ---------------------------------------------
               yield put(
                  actions.validateLinkSuccess({
                     smartLinkRequest: payload,
                     smartLinkResponse: response,
                     sharingAlphaScreen: "EMAIL_STATUS_WARNING",
                  })
               );
               yield put(
                  actions.resendPasswordResetEmailRequest({
                     email: response.email,
                  })
               );
               // ---------------------------------------------
            } else if (response.result === "Please provide correct token") {
               // NOTE: State -> ['EMAIL_STATUS_WARNING', 'EMAIL_STATUS_SUCCESS', 'CREATE_PASSWORD', 'NEW_USER_EMAIL']
               // NOTE: When 'smart link' contains incorrect 'token'
               // ---------------------------------------------
               throw response.result;
            } else if (response.token) {
               // NOTE: When 'smart link' is active,
               // save 'response.token' as auth-token and loggedIn user then redirect user to discover
               // -----------------------------------------------------------------------------------------------------
               yield put(
                  actions.autoLoginUserRequest({
                     query,
                     params,
                     redirect: true,
                     result: response,
                     token: payload.token,
                  })
               );
               yield put(actions.validateLinkSuccess());
               if (callback && typeof callback == "function") {
                  callback();
               }
               // ---------------------------------------------------------------------------------------------------------------------------------
            }
         } else {
            throw new Error("-- Api Failed --");
         }
      } else {
         throw new Error("-- Missing payload for Validating Link --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         // NOTE: if you want to auto sent the smart-link for 'Please enter valid sent code' case
         // uncomment below code
         // -------------------------------------------------------------------------------------------
         // if (payload.email &&
         //   errorDetails.data &&
         //   errorDetails.data.message === 'Please enter valid sent code') {
         //   openNotificationWithIcon({
         //     duration: 5,
         //     type: 'warning',
         //     message: 'Expired',
         //     className: 'api-response-notification-class',
         //     description: `Please enter valid sent code'`,
         //   });
         //   yield put(
         //     actions.sendEmailInviteRequest({
         //       email: payload.email,
         //       notification: true,
         //     })
         //   )
         //   yield call(delay, 400);
         //   yield put(actions.validateLinkSuccess({
         //     smartLinkRequest: payload,
         //     smartLinkResponse: errorDetails.data.message,
         //     sharingAlphaScreen: 'EMAIL_STATUS_WARNING',
         //   }));
         // } else {
         //   openNotificationWithIcon({
         //     duration: 5,
         //     type: 'error',
         //     message: 'Something went wrong',
         //     className: 'api-response-notification-class',
         //     description: `Sorry, ${errorDetails.data.message}`,
         //   });
         //   yield put(
         //     actions.validateLinkSuccess({
         //       apiFailed: errorDetails.data.message,
         //       sharingAlphaScreen: 'NEW_USER_EMAIL',
         //     })
         //   )
         // }
         // -------------------------------------------------------------------------------------------
         openNotificationWithIcon({
            duration: 5,
            type: "error",
            message: "Something went wrong",
            className: "api-response-notification-class",
            description: `Sorry, ${errorDetails.data.message}`,
         });
         yield put(
            actions.validateLinkSuccess({
               apiFailed: errorDetails.data.message,
               sharingAlphaScreen: "NEW_USER_EMAIL",
            })
         );
      } else {
         openNotificationWithIcon({
            duration: 5,
            type: "error",
            message: "Something went wrong",
            className: "api-response-notification-class",
            description: `Error: ${error}`,
         });
         yield put(
            actions.validateLinkSuccess({
               apiFailed: "-- Failed to process --",
               sharingAlphaScreen: "NEW_USER_EMAIL",
            })
         );
      }
      if (callback && typeof callback == "function") {
         callback({ notify: true, error });
      }
   }
}

function* sendEmailInvite(action) {
   const { payload, callback } = action;
   try {
      if (payload && payload.email) {
         yield put(actions.sendEmailInviteFetch());

         let resetPasswordResponse = yield call(
            AuthApi.executeOktaResetPassword,
            {
               username: payload.email,
               factorType: "EMAIL",
            }
         );
         console.log("resetPasswordResponse --> ", resetPasswordResponse);
         // factorResult: "WAITING"
         // factorType: "EMAIL"
         // recoveryType: "PASSWORD"
         // status: "RECOVERY_CHALLENGE"
         if (
            resetPasswordResponse &&
            resetPasswordResponse.status === "RECOVERY_CHALLENGE"
         ) {
            // debugger

            // NOTE: for resend-email case -> "click here to resend email", trigger notifications
            if (payload.notification) {
               openNotificationWithIcon({
                  duration: 5,
                  type: "success",
                  message: "Sent",
                  className: "api-response-notification-class",
                  description: `Reset password link has been sent to ${payload.email}`,
               });
            }
            // ------------------------------------------------------------------------------------------
            // NOTE: State -> ['EMAIL_STATUS_WARNING', 'EMAIL_STATUS_SUCCESS', 'CREATE_PASSWORD', 'NEW_USER_EMAIL']
            // NOTE: When email sent successfully
            // ------------------------------------------------------------------------------------------
            const { smartLinkRequest: _currentSmartLinkRequest } = yield select(
               state => state.auth
            );
            yield put(
               actions.sendEmailInviteSuccess({
                  smartLinkRequest: {
                     ..._currentSmartLinkRequest,
                     ...payload,
                  },
                  // smartLinkResponse: response.data,
                  sharingAlphaScreen: "EMAIL_STATUS_SUCCESS",
               })
            );
            // ------------------------------------------------------------------------------------------
            yield call(delay, 400);
            // NOTE: for resend-email case -> "click here to resend email", then redirect to "path"
            if (payload.redirectTo) {
               yield put(push(payload.redirectTo));
            }
            // ------------------------------------------------------------------------------------------
            if (callback && typeof callback == "function") {
               callback();
            }
         }

         // const response = yield call(AuthApi.sendSAConfirmationEmail, { email: payload.email });
         // console.log('-- response --', response)
         // if (response) {
         //
         //   // NOTE: for resend-email case -> "click here to resend email", trigger notifications
         //   if (payload.notification) {
         //     openNotificationWithIcon({
         //       duration: 5,
         //       type: 'success',
         //       message: 'Sent',
         //       className: 'api-response-notification-class',
         //       description: `Reset password link has been sent to ${payload.email}`,
         //     });
         //   }
         //   // ------------------------------------------------------------------------------------------
         //   // NOTE: State -> ['EMAIL_STATUS_WARNING', 'EMAIL_STATUS_SUCCESS', 'CREATE_PASSWORD', 'NEW_USER_EMAIL']
         //   // NOTE: When email sent successfully
         //   // ------------------------------------------------------------------------------------------
         //   const { smartLinkRequest: _currentSmartLinkRequest } = (yield select(state => state.auth));
         //   yield put(actions.sendEmailInviteSuccess({
         //     smartLinkRequest: {
         //       ..._currentSmartLinkRequest,
         //       ...payload,
         //     },
         //     // smartLinkResponse: response.data,
         //     sharingAlphaScreen: 'EMAIL_STATUS_SUCCESS',
         //   }));
         //   // ------------------------------------------------------------------------------------------
         //   yield call(delay, 400);
         //   // NOTE: for resend-email case -> "click here to resend email", then redirect to "path"
         //   if (payload.redirectTo) {
         //     yield put(push(payload.redirectTo));
         //   }
         //   // ------------------------------------------------------------------------------------------
         //   if (callback && typeof callback == 'function') {
         //     callback();
         //   }
         //
         // } else {
         //   console.log(response)
         //   throw '-- Api Failed --'
         // }
      } else {
         throw new Error("-- Missing payload for Validating Link --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         if (
            errorDetails.data &&
            errorDetails.data.message === "Email Not Found"
         ) {
            openNotificationWithIcon({
               duration: 5,
               type: "warning",
               message: "Email Address Not Found",
               className: "api-response-notification-class",
               description: `Sorry, "${payload.email}" email address is not registered.`,
            });
            yield call(delay, 500);
            const registerParams = {
               user: "sharing-alpha",
               email: payload.email,
            };
            yield put(push(`${jsonInURL(registerParams, "/register")}`));
         } else {
            yield put(
               actions.sendEmailInviteSuccess({
                  apiFailed: errorDetails.data.message,
               })
            );
         }
      } else {
         yield put(
            actions.sendEmailInviteSuccess({
               apiFailed: "-- Failed to process --",
            })
         );
      }
      if (callback && typeof callback == "function") {
         callback({ error });
      }
   }
}

function* autoLoginByToken(action) {
   let { payload, callback } = action;
   let userData = null;
   try {
      if (payload && payload.token) {
         yield put(actions.autoLoginByTokenFetch());

         // NOTE: Get query from discovery reducer's state
         // --------------------------------------------------------------------------------------------
         const discoveryQuery = yield select(state => state.discovery.query) ||
            "";
         const query =
            payload.query ||
            discoveryQuery ||
            window.sessionStorage.getItem("query");
         // --------------------------------------------------------------------------------------------

         // NOTE: Update new auth "TOKEN"
         // --------------------------------------------
         AuthUtils.updateToken(payload.token);
         // --------------------------------------------

         let profileResponse = yield call(ProfileApi.fetchProfile);
         console.log("profileResponse --> ", profileResponse);
         if (profileResponse && profileResponse.data) {
            userData = profileResponse.data;

            if (typeof userData.profile === "undefined") {
               userData.profile = userData;
            }

            AuthUtils.storeAuthToken({
               token: payload.token,
               email: userData.email,
               lastName: userData.last_name,
               self_role: userData.self_role,
               firstName: userData.first_name,
               termsAgreed: userData.terms_agreed,
               client_exist: userData.client_exist,
               profile_self_role: userData.self_role,
               user_advisor_menu: userData.user_advisor_menu,
               restricted_universe: userData.restricted_universe,
            });

            // Which set 'loggedIn: false', to resolve this -> `loggedInByToken` used in reducers/auth.js -> GOOGLE_AUTH_FAILURE
            // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            yield put(
               actions.autoLoginByTokenSuccess({
                  user: {
                     ...userData,
                     premiumUser: false,
                     loggedInByToken:
                        payload.token && typeof payload.token !== "undefined",
                  },
                  loggedIn: true,
                  loggingIn: false,
                  authSuccess: true,
                  token: payload.token,
                  client_exist: userData.client_exist,
                  termsAgreed: userData.terms_agreed,
                  user_advisor_menu: userData.user_advisor_menu,
                  remind_for_password_change:
                     userData.remind_for_password_change,
                  loggedInByToken:
                     payload.token && typeof payload.token !== "undefined",
               })
            );
            // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------

            yield put(profileActions.loadProfile(userData.profile));
            yield put(profileActions.subscriptionPlan());

            const loggedIn = (yield select(state => state.auth)).loggedIn;
            //const remind_for_password_change = (yield select(state => state.auth)).remind_for_password_change;
            if (loggedIn || window.localStorage.getItem("token")) {
               const securitiesPath = searchUrlV3(query); // '/securities' + (query ? `?query=${encodeURIComponent(query)}` : '');
               debugger;
               yield put(push(securitiesPath));
            }
         } else {
            throw new Error("Failed to fetch PROFILE api data");
         }
      } else {
         throw new Error("-- Missing Payload --");
      }
   } catch (error) {
      let errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      console.log(userData);
      if (
         !errorDetails ||
         (!errorDetails.error &&
            errorDetails &&
            errorDetails.includes("Authentication failed"))
      ) {
         errorDetails = {
            error: "Authentication failed",
         };
      }
      yield put(actions.failedAuth(errorDetails));
      if (callback && typeof callback === "function") {
         callback(errorDetails);
      }
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Error",
         className: "api-response-notification-class",
         description:
            errorDetails && errorDetails.error
               ? errorDetails.error
               : errorDetails,
      });
      AuthUtils.deleteAuthToken();
      yield put(actions.autoLoginByTokenSuccess());
   }
}

function* userSourceCheck(action) {
   const { payload } = action;

   try {
      if (payload) {
         const response = yield call(AuthApi.userSourceData, payload);
         if (response && response.result) {
            yield put(actions.userSourceCheckSuccess({ ...response.result }));
         } else {
            throw new Error("-- API FAILED --");
         }
      }
   } catch (error) {
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         if (
            (errorDetails.data &&
               errorDetails.data.result === "Email doesn't exist") ||
            errorDetails.data.result === "Email doesn't exist on okta"
         ) {
            yield put(actions.userSourceCheckSuccess(errorDetails.data.result));
         }
      } else {
         yield put(
            actions.userSourceCheckSuccess(
               "Something went wrong. Please try again later."
            )
         );
      }
      console.log("---error---", error);
   }
}

function* loginSaga() {
   yield all([
      // fork(takeEvery, ActionTypes.GOOGLE_AUTH_SUCCESS, googleAuth),
      fork(takeEvery, ActionTypes.AUTH_REQUEST, auth),
      fork(takeEvery, ActionTypes.TERMS_AGREEMENT_REQUEST, termsAgreement),
      fork(takeEvery, ActionTypes.LOGOUT_REQUEST, logout),
      fork(takeEvery, ActionTypes.SEND_CHANGE_PWD_EMAIL, sendChangePwdEmail),
      fork(
         takeEvery,
         ActionTypes.VERIFY_CHANGE_PWD_TOKEN,
         verifyChangePwdToken
      ),
      fork(takeEvery, ActionTypes.FORGOT_CHANGE_PASSWORD, changePassword),
      fork(takeEvery, ActionTypes.VALIDATE_LINK_REQUEST, validateLink),
      fork(takeEvery, ActionTypes.SEND_EMAIL_INVITE_REQUEST, sendEmailInvite),
      fork(takeEvery, ActionTypes.AUTO_LOGIN_USER_REQUEST, autoLoginUser),
      fork(takeEvery, ActionTypes.OKTA_AUTH_LOGIN_REQUEST, oktaAuthLogin),
      fork(
         takeEvery,
         ActionTypes.AUTO_LOGIN_BY_TOKEN_REQUEST,
         autoLoginByToken
      ),
      fork(
         takeEvery,
         ActionTypes.OKTA_CHANGE_PASSWORD_REQUEST,
         oktaChangePassword
      ),
      fork(
         takeEvery,
         ActionTypes.RESEND_PASSWORD_RESET_EMAIL_REQUEST,
         resendPasswordResetEmail
      ),
      fork(takeEvery, ActionTypes.USER_SOURCE_CHECK_REQUEST, userSourceCheck),
   ]);
}

export default loginSaga;
