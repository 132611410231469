import zipObject from "lodash/zipObject";

const actions = [
   "REFRESH_WEIGHT_STATE",
   "FETCH_WEIGHTS_DATA",
   "SET_INITIAL_WEIGHTS",
   "SET_WEIGHTS_DATA",
   "SET_CALCULATOR",
   "POST_WEIGHTS_FORM_DATA",
   "SAVE_SCORE_ATTRS",
   "CHANGE_CALCULATOR",
   "SET_SCORE_ATTRS",
   "POST_SCREENER_DATA",
   "SET_CUSTOM_SELECTOR_NAME",
   "SET_CUSTOM_SELECTOR_SCORE_ATTRS",
   "UPDATE_CALCULATOR_NAME",
   "DELETE_CALCULATOR",
   "UPDATE_CUSTOM_CALCULATOR_LIST",
   "UPDATE_ALL_CALCULATOR_LIST",
   "UPDATE_ALL_SELECTOR_ATTRS",
   "UPDATE_DEFAULT_CALCULATOR",
];

export default zipObject(actions, actions);
