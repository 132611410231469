import React from "react";

export const routerPages = {
   "/": {
      name: "Home",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/securities": {
      name: "Discovery",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   // '/accounts/apex': {
   //   name: 'Accounts',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.',
   // },
   "/selector": {
      name: "Selector",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/advisor/account": {
      name: "Client Portfolio",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/advisor/accountV2": {
      name: "Client Portfolio v2",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/investments": {
      name: "Enhancer",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/settings/profile": {
      name: "Settings",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   // '/settings/proposals': {
   //   name: 'Settings',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   "/archived/proposals": {
      name: "Archived Proposals",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   // '/settings/tradingAccount': {
   //   name: 'Settings',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/alert': {
   //   name: 'Alerts',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/referral': {
   //   name: 'Referral Page',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/subscriptionPlan': {
   //   name: 'Subscription Plan',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/profile': {
   //   name: 'Build Portfolio',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/weights': {
   //   name: 'Build Portfolio',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/broker': {
   //   name: 'Build Portfolio',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/broker/select': {
   //   name: 'Build Portfolio',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/broker/:brokerName/account': {
   //   name: 'Build Portfolio',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/broker/:brokerName/register': {
   //   name: 'Build Portfolio',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/broker/tdameritrade/setup': {
   //   name: 'Build Portfolio',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   // '/settings/broker/tradeit/security-questions': {
   //   name: 'Build Portfolio',
   //   icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
   //   title: 'Magnifi – Discover a world of investing.'
   // },
   "/build-portfolio": {
      name: "Build Portfolio",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/build-portfolio/onboard-screen": {
      name: "Build Portfolio",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/build-portfolio/market-place/:name": {
      name: "Build Portfolio",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/build-portfolio/custom/:name": {
      name: "Build Portfolio",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/build-portfolio/asset-allocation": {
      name: "Build Portfolio",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/build-portfolio/scratch": {
      name: "Build Portfolio - Scratch",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/build-portfolio/exception/403": {
      name: "Exception 403",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/build-portfolio/exception/404": {
      name: "Exception 404",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/build-portfolio/exception/500": {
      name: "Exception 500",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer": {
      name: "Enhancer",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/onboard-screen": {
      name: "OnBoardScreen",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/summary": {
      name: "Summary",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/dashboard": {
      name: "Dashboard",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/dashboard/summary": {
      name: "Summary",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/dashboard/funds": {
      name: "Funds",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/dashboard/models": {
      name: "Models",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/exception/403": {
      name: "Exception 403",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/exception/404": {
      name: "Exception 404",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/enhancer/exception/500": {
      name: "Exception 500",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community": {
      name: "Community",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/dashboard": {
      name: "Community",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/getting-started": {
      name: "Getting Started",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/allocation": {
      name: "Allocation",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/user-comments": {
      name: "Commentary",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/fund-comments": {
      name: "Comments",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/fund-rating": {
      name: "Fund Rating",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/faqs": {
      name: "FAQ",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/fundSelectionFaqs": {
      name: "fundSelectionFaqs",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/exception/403": {
      name: "Exception 403",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/exception/404": {
      name: "Exception 404",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/community/exception/500": {
      name: "Exception 500",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts": {
      name: "Accounts",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/apex": {
      name: "Apex",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/apex/create": {
      name: "Apex",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/apex/under-verification": {
      name: "Apex",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/apex/empty-result": {
      name: "Apex",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/onboard-screen": {
      name: "OnBoardScreen",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/summary": {
      name: "OnBoardScreen",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/dashboard": {
      name: "Dashboard",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/dashboard/summary": {
      name: "Summary",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/dashboard/funds": {
      name: "Funds",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/dashboard/models": {
      name: "Models",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/exception/400": {
      name: "Exception 400",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/exception/403": {
      name: "Exception 403",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/exception/404": {
      name: "Exception 404",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/exception/500": {
      name: "Exception 500",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
   "/accounts/exception/no-trade": {
      name: "Exception 500",
      icon: <i style={{ marginRight: 10 }} className="fas fa-home-lg-alt" />,
      title: "Magnifi – Discover a world of investing.",
   },
};

export const hideHeaderForRoutes = [
   "/register/widget",
   "/password-reset",
   "/reports",
   "/reports-v2",
   "/portfolio-reports",
   "/portfolio-reports-v2",
   "/builder-report",
   "/rec-enhancer",
   "/maintenance",
];

export default {
   routerPages,
   hideHeaderForRoutes,
};
