import ActionTypes from "../action-types";

const redirectFrom = {
   module: "",
   portfolioName: "",
   type: "",
   payload: "",
   returnPortfolioID: "",
};

const initialState = {
   loading: true,
   valid: false,
   activeAccount: "",
   activeAccAccount: "",
   activePortAccount: "",
   portfolioValue: "",
   view: "",
   acView: "default",
   droppedFund: false,
   dropFundResponse: "",
   triggerInvestment: false,
   realTimePrice: [],
   checkPurchaseValues: false,
   uploadfileDuplicate: false,
   portfolioLoading: false,
   portfolioList: [],
   portfolioFunds: [],
   marketPlaceList: [],
   marketFunds: [],
   uploadFileFailure: false,
   uploadFileFailureMsg: "",
   uploadFilePartial: false,
   uploadFilePartialMsg: "",
   reportLoader: false,
   reportPDFDownloadStatus: {
      message: "",
      downloadStatus: "",
   },
   filename: "",
   setPfEnhancementContent: false,
   retData: [],
   portfolioTickers: "",
   demoPortfolioName: "",
   portfolioOldTickersBeforeUpload: "",
   oldViewBeforeUpload: "",
   caching: {},
   redirectFrom,
   copyOfRedirectFrom: {
      ...redirectFrom,
   },
   clientList: [],
   homeOfficeModelsList: [],
};

export function investment(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.RESET_CACHING_FOR_INVESTMENT:
         console.log("------ RESET CACHING FOR INVESTMENT ------");
         return {
            ...state,
            caching: {},
         };
      case ActionTypes.SET_CACHING_FOR_INVESTMENT:
         const CACHE_STATE = { ...state };
         console.log("------ CACHING FOR INVESTMENT ------");
         console.log(CACHE_STATE);
         console.log("-----------------------------------");
         return {
            ...state,
            caching: CACHE_STATE,
         };
      case ActionTypes.SET_DEFAULT_INVESTMENT:
         return {
            ...initialState,
         };
      case ActionTypes.DOWNLOAD_PORTFOLIO_REPORT:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.DOWNLOAD_REC_ENHANCER_REPORT:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SET_INVESTMENT_STATE:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.DROP_FUND:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.INVESTMENT_LOADING:
         return {
            ...state,
            portfolioLoading: payload,
         };

      case ActionTypes.INVESTMENT_FETCH:
         return {
            ...state,
            loading: true,
            valid: false,
         };

      case ActionTypes.INVESTMENT_FETCH_SUCCESS:
         return {
            ...state,
            ...action.payload,
            loading: false,
            valid: true,
         };

      case ActionTypes.FUNDS_QUERY_SUCCESS:
         return {
            ...state,
            funds: action.funds.map(e => e),
            dateRange: action.dateRange,
            filterAttrs: action.filterAttrs,
            filterAttrsSelected: action.filterAttrsSelected,
            correctedQuery: action.correctedQuery,
         };

      case ActionTypes.INVESTMENT_ACCOUNT_FILTER:
         return {
            ...state,
            activeAccount: action.account,
         };

      case ActionTypes.INVESTMENT_UI_VIEW:
         return {
            ...state,
            view: action.view,
         };

      case ActionTypes.INVESTMENT_ACTIVE_FUND:
         return {
            ...state,
            activeFund: action.fundId,
         };

      case ActionTypes.INVESTMENT_ACCOUNT_VIEW:
         return {
            ...state,
            acView: action.view,
         };

      case ActionTypes.INVESTMENT_OPTIONS:
         return {
            ...state,
            ...action.payload,
         };

      case ActionTypes.SET_DEMO_PORTFOLIO:
         return {
            ...state,
            loading: true,
         };

      case ActionTypes.SAVE_DEMO_PORTFOLIO:
         return {
            ...state,
            ...payload,
            // demoPortfolioName : action.payload,
         };

      case ActionTypes.RESET_INVESTMENT_STATE:
         return {
            ...state,
            ...initialState,
         };

      case ActionTypes.GET_CLIENT_LIST_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.GET_HOME_OFFICE_MODELS_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_PORTFOLIO_LIST_BUILDER_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      default:
         return state;
   }
}
