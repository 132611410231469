/* eslint-disable array-callback-return */
import { createSelector } from "reselect";
import { RegionColors, SectorColors } from "data/Colors";
import { cn, SectorsAttrs } from "DataSet";

export const getPortfolioFunds = community => {
   let { funds } = community;
   if (funds && funds.length > 0) {
      return funds;
   }
   return [];
};

const formatTree = data => {
   if (!data) return data;
   return data.map(e => {
      let out = { name: e.n, size: e.v };
      if (e.sub) out.children = formatTree(e.sub);
      return out;
   });
};

const sum = (arr, key) => {
   return arr.reduce((a, b) => a + (b[key] || 0), 0);
};

// const sortFn = attr => (a, b) => {
//    // sort descending
//    if (a[attr] < b[attr]) return 1;
//    else if (a[attr] > b[attr]) return -1;
//    return 0;
// };

export const preparePortfolioFunds = createSelector(
   [getPortfolioFunds],
   data => {
      return chartDataForPortfolio(data);
   }
);

export const chartDataForPortfolio = data => {
   let out = {
      chartDetails: [],
      funds: [],
      portfolioValue: 0,
      fundBalance: 0,
      cashBalance: 0,
      gainToday: 0,
      gainMonthly: 0,
      gainYearly: 0,
      allocationPriceSectors: [],
   };
   if (!data) return out;

   let fundsH = {},
      totalMarketValue = 0;
   let allRegionData = [],
      uniqueRegions = [],
      allSectorData = [],
      uniqueSectors = [];

   data.forEach((kl, i) => {
      if (
         kl &&
         typeof kl.price !== "undefined" &&
         typeof kl.shares !== "undefined"
      ) {
         out.portfolioValue += kl.price * kl.shares;
      }
      if (typeof kl.ticker === "undefined") {
         kl.ticker = kl.ticker_short || kl.script;
      }
   });

   out.funds = data.filter(kl => !kl.invalid);

   for (const e of out.funds) {
      if (fundsH[e.script] === undefined)
         fundsH[e.script] = { ...e, _price: 0, symbol: e.script };

      totalMarketValue += e.market_value;
      if (e.daily_nav !== null)
         out.gainToday += (e.price - e.daily_nav) * e.shares;
      if (e.monthly_nav !== null)
         out.gainMonthly += (e.price - e.monthly_nav) * e.shares;
      if (e.yearly_nav !== null)
         out.gainYearly += (e.price - e.yearly_nav) * e.shares;

      fundsH[e.script]._price += e.price;

      // Sectors
      if (e.sector && Object.keys(e.sector).length > 0) {
         let sec = SectorsAttrs.map(v => ({
            name: v,
            value: cn(e.sector, v)
               ? parseFloat((cn(e.sector, v) * 1).toFixed(1))
               : 0,
         }));
         sec = sec.map(item => {
            !uniqueSectors.includes(item.name) && uniqueSectors.push(item.name);
            const obj = {
               name: item.name,
               value: item.value * e.market_value,
               pl:
                  (e.price - parseFloat(e.purchase_price)) *
                  e.shares *
                  (item.value / 100),
            };
            allSectorData.push(obj);
         });
      }

      // Regions
      if (e.region && Object.keys(e.region).length > 0) {
         let reg = formatTree(cn(e, "Region"));
         reg = reg.map(item => {
            !uniqueRegions.includes(item.name) && uniqueRegions.push(item.name);
            const region = {
               name: item.name,
               size: sum(item.children, "size") * e.market_value,
               pl:
                  (e.price - parseFloat(e.purchase_price)) *
                  e.shares *
                  (sum(item.children, "size") / 100),
            };
            return region;
         });
         allRegionData.push(reg);
      }
   }

   // All Sectors details
   // ------------------------------------------------------------------
   let priceSectors = [];
   if (uniqueSectors.length > 0) {
      priceSectors = uniqueSectors.reduce((acc, item, i) => {
         const value = allSectorData.reduce(
            (sum, data) => {
               if (data.name === item) sum["sum"] = sum["sum"] + data.value;
               if (data.name === item) sum["plSum"] = sum["plSum"] + data.pl;
               return sum;
            },
            {
               sum: 0,
               plSum: 0,
            }
         );

         const obj = {
            name: item,
            value: value.sum / totalMarketValue,
            color: SectorColors[i % SectorColors.length],
            pl: value.plSum,
         };
         acc.push(obj);
         return acc;
      }, []);
   }

   out.chartDetails = [
      ...out.chartDetails,
      {
         label: "All Sectors",
         data: priceSectors,
      },
   ];
   // ------------------------------------------------------------------

   if (uniqueSectors.length > 0) {
      // Sectors details
      // ------------------------------------------------------------------
      let _SECTORS = [];
      _SECTORS = priceSectors
         .sort((a, b) => b.pl - a.pl)
         .filter(
            (item, index) =>
               (index <= 4 && item.pl > 0) ||
               (index >= priceSectors.length - 5 && item.pl <= 0)
         );
      if (_SECTORS && _SECTORS.length) {
         out.chartDetails = [
            ...out.chartDetails,
            {
               label: "Sectors",
               data: _SECTORS,
            },
         ];
      } else {
         out.chartDetails.forEach(e => {
            if (e.label === "All Sectors") {
               e.label = "Sectors";
            }
         });
      }
      // ------------------------------------------------------------------
   }

   // Regions details
   // ------------------------------------------------------------------
   let _REGIONS = [];
   if (uniqueRegions.length > 0) {
      _REGIONS = uniqueRegions.reduce((acc, item) => {
         const size = allRegionData.reduce(
            (sum, data) => {
               const index = data.findIndex(x => x.name === item);
               if (index >= 0) sum["sum"] = sum["sum"] + data[index].size;
               if (index >= 0) sum["plSum"] = sum["plSum"] + data[index].pl;
               return sum;
            },
            {
               sum: 0,
               plSum: 0,
            }
         );
         const abc = {
            name: item,
            value:
               size.sum / totalMarketValue < 0
                  ? 0
                  : size.sum / totalMarketValue,
            color: RegionColors[item],
            pl: size.plSum,
         };
         acc.push(abc);
         return acc;
      }, []);
   }

   out.chartDetails = [
      ...out.chartDetails,
      {
         label: "Regions",
         data: _REGIONS,
      },
   ];
   // ------------------------------------------------------------------

   out.uniqueRegions = uniqueRegions || [];

   out.funds.sort((a, b) => {
      return new Date(b.purchase_date) - new Date(a.purchase_date);
   });

   //PF assetAllocation
   let assetPFFunds = 0,
      equitiesSum = 0.0,
      bondsSum = 0.0,
      commoditiesSum = 0.0,
      currenciesSum = 0.0,
      derivativesSum = 0.0,
      cashSum = 0.0,
      otherSum = 0.0;

   out.funds.forEach(e => {
      if (e.asset_alloc) {
         assetPFFunds += 1;
         if (e.asset_alloc.equities)
            equitiesSum += e.asset_alloc.equities * e.weight;
         if (e.asset_alloc.bonds) bondsSum += e.asset_alloc.bonds * e.weight;
         if (e.asset_alloc.commodities)
            commoditiesSum += e.asset_alloc.commodities * e.weight;
         if (e.asset_alloc.currencies)
            currenciesSum += e.asset_alloc.currencies * e.weight;
         if (e.asset_alloc.derivatives)
            derivativesSum += e.asset_alloc.derivatives * e.weight;
         if (e.asset_alloc.cash) cashSum += e.asset_alloc.cash * e.weight;
         if (e.asset_alloc.other) otherSum += e.asset_alloc.other * e.weight;
      }
   });

   out.assetAllocationChartData = {
      equities: assetPFFunds ? equitiesSum / 100 : 0, // assetPFFunds ? equitiesSum / assetPFFunds : 0,
      bonds: assetPFFunds ? bondsSum / 100 : 0, // assetPFFunds ? bondsSum / assetPFFunds : 0,
      commodities: assetPFFunds ? commoditiesSum / 100 : 0, //assetPFFunds ? commoditiesSum / assetPFFunds : 0,
      currencies: assetPFFunds ? currenciesSum / 100 : 0, // assetPFFunds ? currenciesSum / assetPFFunds : 0,
      derivatives: assetPFFunds ? derivativesSum / 100 : 0, // assetPFFunds ? derivativesSum / assetPFFunds : 0,
      cash: assetPFFunds ? cashSum / 100 : 0, // assetPFFunds ? cashSum / assetPFFunds : 0,
      other: assetPFFunds ? otherSum / 100 : 0, // assetPFFunds ? otherSum / assetPFFunds : 0,
   };

   return out;
};
