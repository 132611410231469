import ActionTypes from "../action-types";

export function quickTradeModalToggle(payload) {
   return {
      type: ActionTypes.QUICK_TRADE_MODAL_TOGGLE,
      payload,
   };
}

/* ADD TICKERS TO CART */
export function addTickersToQuickTradeRequest(payload, callback) {
   return {
      type: ActionTypes.ADD_TICKERS_TO_QUICK_TRADE_REQUEST,
      payload,
      callback,
   };
}

export function addTickersToQuickTradeFetch(payload) {
   return {
      type: ActionTypes.ADD_TICKERS_TO_QUICK_TRADE_FETCH,
      payload,
   };
}

export function addTickersToQuickTradeSuccess(payload) {
   return {
      type: ActionTypes.ADD_TICKERS_TO_QUICK_TRADE_SUCCESS,
      payload,
   };
}

/* REMOVE QUICK TRADE TICKERS */
export function removeQuickTradeTickersRequest(payload, callback) {
   return {
      type: ActionTypes.REMOVE_QUICK_TRADE_TICKERS_REQUEST,
      payload,
      callback,
   };
}

export function removeQuickTradeTickersFetch(payload) {
   return {
      type: ActionTypes.REMOVE_QUICK_TRADE_TICKERS_FETCH,
      payload,
   };
}

export function removeQuickTradeTickersSuccess(payload) {
   return {
      type: ActionTypes.REMOVE_QUICK_TRADE_TICKERS_SUCCESS,
      payload,
   };
}

export function deleteAllQuickTradeTickersRequest(payload, callback) {
   return {
      type: ActionTypes.DELETE_ALL_QUICK_TRADE_TICKERS_REQUEST,
      payload,
      callback,
   };
}

export function deleteAllQuickTradeTickersFetch(payload) {
   return {
      type: ActionTypes.DELETE_ALL_QUICK_TRADE_TICKERS_FETCH,
      payload,
   };
}

export function deleteAllQuickTradeTickersSuccess(payload) {
   return {
      type: ActionTypes.DELETE_ALL_QUICK_TRADE_TICKERS_SUCCESS,
      payload,
   };
}

/* CLOSE QUICK TRADE AND OPEN SFM */
export function quickTradeToSFMRequest(payload, callback) {
   return {
      type: ActionTypes.QUICK_TRADE_TO_SFM_REQUEST,
      payload,
      callback,
   };
}

export function quickTradeToSFMFetch(payload) {
   return {
      type: ActionTypes.QUICK_TRADE_TO_SFM_FETCH,
      payload,
   };
}

export function quickTradeToSFMSuccess(payload) {
   return {
      type: ActionTypes.QUICK_TRADE_TO_SFM_SUCCESS,
      payload,
   };
}

/* GET BROKER DETAILS */
export function getBrokerDetailsRequest(payload, callback) {
   return {
      type: ActionTypes.GET_BROKER_DETAILS_REQUEST,
      payload,
      callback,
   };
}

export function getBrokerDetailsFetch(payload) {
   return {
      type: ActionTypes.GET_BROKER_DETAILS_FETCH,
      payload,
   };
}

export function getBrokerDetailsSuccess(payload) {
   return {
      type: ActionTypes.GET_BROKER_DETAILS_SUCCESS,
      payload,
   };
}

/* EXECUTE QUICK TRADE ORDER */
export function executeQuickTradeRequest(payload, callback) {
   return {
      type: ActionTypes.EXECUTE_QUICK_TRADE_ORDERS_REQUEST,
      payload,
      callback,
   };
}

export function executeQuickTradeFetch(payload) {
   return {
      type: ActionTypes.EXECUTE_QUICK_TRADE_ORDERS_FETCH,
      payload,
   };
}

export function executeQuickTradeSuccess(payload) {
   return {
      type: ActionTypes.EXECUTE_QUICK_TRADE_ORDERS_SUCCESS,
      payload,
   };
}
