import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setPremiumState, inviteFriends } from "actions/profile";
import { setGlobalState } from "actions/global";
import { addEventToAnalytics } from "Utils";
import InviteForm from "./InviteForm";

class PremiumFeature extends Component {
   handleSubmit = values => {
      this.props.inviteFriends(values);
      var emails = values.map(i => {
         return i.Email;
      });
      addEventToAnalytics(
         "Send Invite",
         "Send Invite",
         "SEND_INVITE",
         { email: emails.toString() },
         true
      );
   };

   render() {
      return (
         <React.Fragment>
            <InviteForm
               {...this.props}
               handleSubmit={this.handleSubmit}
               onToggle={this.handleToggle}
            />
         </React.Fragment>
      );
   }
}

const mapStateToProps = ({ auth, profile, global }) => ({
   loggedIn: auth.loggedIn,
   inviteFailed: profile.inviteFailed,
   inviteSuccess: profile.inviteSent,
   inviteError: profile.error,
   inviteMessage: profile.inviteMessage,
   premiumUser: auth.user.premiumUser,
   surveyModal: global.surveyModal,
   mobileDevice: window.innerWidth <= 1023,
});

const mapDispatchToProps = {
   inviteFriends,
   setPremiumState,
   setGlobalState,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withRouter(PremiumFeature));
