import ActionTypes from "../action-types";

const initialState = {
   funds: [],
   broker: {},
   modalToggle: false, // to minimize/maxmize modal
};

export function quicktrade(state = initialState, action) {
   const { payload } = action;

   switch (action.type) {
      case ActionTypes.REFRESH_STATE:
         return {
            funds: [],
            modalToggle: false,
         };

      case ActionTypes.QUICK_TRADE_MODAL_TOGGLE:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.ADD_TICKERS_TO_QUICK_TRADE_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.REMOVE_QUICK_TRADE_TICKERS_SUCCESS:
         const { funds: currentFunds } = state;
         const { removeTicker } = payload;
         let _currentFunds = currentFunds;
         if (removeTicker) {
            _currentFunds = _currentFunds.filter(
               k => k.ticker !== removeTicker
            );
         }
         return {
            ...state,
            funds: _currentFunds,
            modalToggle: _currentFunds.length !== 0,
         };

      case ActionTypes.DELETE_ALL_QUICK_TRADE_TICKERS_SUCCESS:
         return {
            ...state,
            funds: [],
            modalToggle: false,
         };

      case ActionTypes.QUICK_TRADE_TO_SFM_SUCCESS:
         return {
            ...state,
            funds: [],
            modalToggle: false,
         };

      case ActionTypes.GET_BROKER_DETAILS_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.EXECUTE_QUICK_TRADE_ORDERS_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      default:
         return state;
   }
}
