import styled from "styled-components";

export const ProfileContainer = styled.div`
   padding: 45px 0 30px 0;
   width: 110px;
   @media (max-width: 768px) {
      padding: 6px 0 30px 0;
   }
`;
export const ProfileImage = styled.div`
   height: 110px;
   border-radius: 10px;
   background: #fff;
   cursor: ${props => (props.uploaded ? "unset" : "pointer")};
   position: relative;
   @media (max-width: 768px) {
      width: inherit;
      display: inline-block;
   }
`;
export const ProfileIcon = styled.img`
   display: block;
   padding: 30px 0px 13px 0px;
   margin: auto;
`;

export const ProfileLabel = styled.div`
   font-family: Roboto;
   font-size: 10px;
   font-weight: 400;
   text-align: center;
   color: #c4c4c4;
`;
export const HideEdit = styled.div`
   margin-top: 7px;
   font-family: Roboto;
   font-size: 12px;
   font-weight: 400;
   line-height: 14px;
   letter-spacing: 0em;
   text-align: center;
   padding: 6px;
   @media (max-width: 768px) {
      margin: 0;
      display: inline-block;
      position: absolute;
      padding: 6px 0 16px 14px;
      top: 0;
   }
`;
export const ProfileUploadedAvatar = styled.img`
   height: 100%;
   width: 110px;
   border-radius: 10px;
   object-fit: contain;
   object-poisition: center;
`;
export const ProfileUploadedDefaultAvatar = styled.img`
   padding: 14px 7px;
   width: 100%;
`;
export const CenterContainer = styled.div`
   text-align: center;
   width: 100%;
   top: 50%;
   left: 50%;
   position: relative;
   -webkit-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
`;

export const FallBackImageContainer = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   -webkit-transform: translate(-50%, -50%);
   border-radius: 10px;
   p {
      font-size: 24px !important;
      margin: 0;
   }
`;
