// import React from "react";
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */

import moment from "moment";
import _ from "lodash";
import enquire from "enquire-js";
import { ViewNames, cn, QuinItems, SectorsAttrs } from "./DataSet";
import { fetchProfile } from "apis/profile";
import { getCookie } from "./layouts/utils";
import { isNull, isUndefined } from "util";
// import { PoundSterling } from "SvgComponent";
import Bowser from "bowser";

const parseDomain = require("parse-domain");

export const arrayInChunk = (inputArray, perChunk) => {
   let iR = inputArray; //[inputArray[0], inputArray[0], inputArray[0], inputArray[0], inputArray[0]];
   let result = iR.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
         resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
   }, []);
   return result;
};

export const getImage = img => {
   const obj = {
      TrackRecord: "/assets/images/icons/TrackRecord.svg",
      OutPerform: "/assets/images/icons/OutPerformance.svg",
      Yield: "/assets/images/icons/Yield.svg",
      Sharpe: "/assets/images/icons/Sharpe.svg",
      Assets: "/assets/images/icons/Assets.svg",
   };
   return obj[img];
};

export const formatNumber = n => {
   if (n < 1e3) return n;
   if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
   if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
   if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
   if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export const getWindowScreen = () => {
   let size = {
      mobileDevice: false,
      tabDevice: false,
      smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
      desktopScreen: false,
      bigScreen: false,
   };

   if (window.innerWidth > 1850) {
      // hd desktop resol or bigScreen
      size = {
         mobileDevice: false,
         tabDevice: false,
         smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
         desktopScreen: false,
         bigScreen: true,
      };
   } else if (window.innerWidth >= 1281 && window.innerWidth <= 1680) {
      // desktop resol or desktopScreen
      size = {
         mobileDevice: false,
         tabDevice: false,
         smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
         desktopScreen: true,
         bigScreen: false,
      };
   } else if (window.innerWidth >= 1024 && window.innerWidth <= 1280) {
      // smallScreen
      // const media =
      //    "only screen and (min-width: 1024px) and (max-width: 1280px)";
      size = {
         mobileDevice: false,
         tabDevice: false,
         smallScreen: true, //only screen and (min-width: 1024px) and (max-width: 1280px)
         desktopScreen: false,
         bigScreen: false,
      };
   } else if (window.innerWidth <= 1023) {
      // mobileDevice
      // const media =
      //    "only screen and (min-width: 320px) and (max-width: 1023px)";
      size = {
         mobileDevice: true,
         tabDevice: false,
         smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
         desktopScreen: false,
         bigScreen: false,
      };
   }

   return size;
};

export const enquireScreen = (cb, media) => {
   if (media !== "") {
      enquire.register(media, {
         match: () => {
            cb && cb(true);
         },
         unmatch: () => {
            cb && cb();
         },
      });
   }
};

export const findAndUpdateArray = ({ array, item, searchKey, targetKey }) => {
   if (array && array.length && searchKey && item && targetKey) {
      let _item = array.find(e => cn(e, searchKey) === cn(item, searchKey));
      if (_item) _item[targetKey] = item[targetKey];
      return array;
   }
};

const applyRegex = (str, re) => {
   const result = str.replace(re, function (m, g1, g2) {
      return g1 ? g1 : g2 + "\r";
   });
   const arr = result.split("\r");
   return arr;
};

export const paraToSentence = string => {
   const regex = /\b(\w\.\w\.)|([.?!])\s+(?=[A-Za-z])/g;
   const arr = applyRegex(string, regex);
   return arr;
};

export const getImageOrFallback = (path, fallback) => {
   return new Promise(resolve => {
      const img = new Image();
      img.src = path;
      img.onload = () => resolve(path);
      img.onerror = () => resolve(fallback);
   });
};

// returns either array or object
export const recursiveSearch = (arr, childArr, key, value) => {
   if (typeof arr !== "undefined") {
      if (Object.prototype.toString.call(arr[childArr]) === "[object Array]") {
         const check = arr[childArr].find(item => item[key] === value);
         if (typeof check !== "undefined") {
            return check;
         } else {
            return arr[childArr].map(item => {
               if (
                  Object.prototype.toString.call(item[childArr]) ===
                  "[object Array]"
               ) {
                  return item[childArr].find(i => {
                     if (typeof i !== "undefined") {
                        if (i[key] === value) {
                           return i;
                        }
                        recursiveSearch(i, childArr, key, value);
                     }
                  });
               }
            });
         }
      }
   } else {
      return `Not an array`;
   }
};

export const randomValue = val => {
   const r = Math.random();
   return val * (0.9 + 0.2 * r);
};

export const getQuintile = (val, ref) => {
   if (val < 0.6 * ref) return 1;
   else if (val >= 0.6 * ref && val < 0.9 * ref) return 2;
   else if (val >= 0.9 * ref && val < 1.1 * ref) return 3;
   else if (val >= 1.1 * ref && val < 1.5 * ref) return 4;
   else if (val >= 1.5 * ref) return 5;
};

export const quintileClass = (value, reverse = false) => {
   var v = reverse ? 6 - value : value;
   switch (v) {
      case 1:
         return "danger";
      case 2:
      case 3:
      case 4:
         return "warning";
      case 5:
         return "success";
      default:
         return "muted";
   }
};

export const quintileSize = value => {
   switch (value) {
      case 1:
         return 1;
      case 2:
      case 3:
      case 4:
         return 2;
      case 5:
         return 3;
      default:
         return 1;
   }
};

export const quintileText = value => ["Low", "Average", "High"][value - 1];

export const arrayToHash = (arr, key) => {
   let out = {};
   arr.forEach(e => {
      out[e[key]] = e;
   });
   return out;
};

export const queryStr = q => {
   return q ? `?q=${encodeURIComponent(q)}` : "";
};

export const searchUrl = q => {
   let utm_campaign = window.sessionStorage.getItem("utm_campaign");
   let utm_medium = window.sessionStorage.getItem("utm_medium");
   let utm_source = window.sessionStorage.getItem("utm_source");
   let utm_term = window.sessionStorage.getItem("utm_term");
   let utm_content = window.sessionStorage.getItem("utm_content");
   return (
      "/securities" +
      (q ? `?query=${encodeURIComponent(q)}` : "") +
      (utm_source ? `&utm_source=${utm_source}` : "") +
      (utm_medium ? `&utm_medium=${utm_medium}` : "") +
      (utm_campaign ? `&utm_campaign=${utm_campaign}` : "") +
      (utm_term ? `&utm_term=${utm_term}` : "") +
      (utm_content ? `&utm_content=${utm_content}` : "")
   );
};

export const searchUrlV2 = q => {
   let utm_campaign = window.sessionStorage.getItem("utm_campaign");
   let utm_medium = window.sessionStorage.getItem("utm_medium");
   let utm_source = window.sessionStorage.getItem("utm_source");
   let utm_term = window.sessionStorage.getItem("utm_term");
   let utm_content = window.sessionStorage.getItem("utm_content");
   return (
      "/securities" +
      (q ? `?query=${encodeURIComponent(q)}` : "") +
      (utm_source ? `&utm_source=${utm_source}` : "") +
      (utm_medium ? `&utm_medium=${utm_medium}` : "") +
      (utm_campaign ? `&utm_campaign=${utm_campaign}` : "") +
      (utm_term ? `&utm_term=${utm_term}` : "") +
      (utm_content ? `&utm_content=${utm_content}` : "")
   );
};

export const clearQuickTrade = () => {
   clearWindowUtmSession();
   clearQuickTradeSession();
};

export const clearQuickTradeSession = () => {
   // console.log('==== clearQuickTradeSession ====');
   if (window.sessionStorage.getItem("buy")) {
      window.sessionStorage.removeItem("buy");
   }
   if (window.sessionStorage.getItem("compare")) {
      window.sessionStorage.removeItem("compare");
   }
   if (window.sessionStorage.getItem("fundPrice")) {
      window.sessionStorage.removeItem("fundPrice");
   }
   if (window.sessionStorage.getItem("fundIsSponsored")) {
      window.sessionStorage.removeItem("fundIsSponsored");
   }
   if (window.sessionStorage.getItem("fundSponsorLogo")) {
      window.sessionStorage.removeItem("fundSponsorLogo");
   }
   if (window.sessionStorage.getItem("fundName")) {
      window.sessionStorage.removeItem("fundName");
   }
   if (window.sessionStorage.getItem("fundCategory")) {
      window.sessionStorage.removeItem("fundCategory");
   }
   if (window.sessionStorage.getItem("fundVehicle")) {
      window.sessionStorage.removeItem("fundVehicle");
   }
   if (window.sessionStorage.getItem("fundMI")) {
      window.sessionStorage.removeItem("fundMI");
   }
};

export const jsonInURL = (jObj = {}, path = "/securities") => {
   let urlParam = [];
   for (var i in jObj) {
      urlParam.push(encodeURIComponent(i) + "=" + encodeURIComponent(jObj[i]));
   }
   if (urlParam.length) {
      return `${path}?${urlParam.join("&")}`;
   }
   return path;
};

export const searchUrlV3 = (q, _paymentStatus = null) => {
   if (_paymentStatus !== null) {
      window.sessionStorage.setItem("paymentStatus", _paymentStatus);
   } else if (window.sessionStorage.getItem("paymentStatus")) {
      window.sessionStorage.removeItem("paymentStatus");
   }
   let utm_campaign = window.sessionStorage.getItem("utm_campaign");
   let utm_medium = window.sessionStorage.getItem("utm_medium");
   let utm_source = window.sessionStorage.getItem("utm_source");
   let utm_term = window.sessionStorage.getItem("utm_term");
   let utm_content = window.sessionStorage.getItem("utm_content");
   let utm_usage_log_id = window.sessionStorage.getItem("utm_usage_log_id");
   let utm_token = window.sessionStorage.getItem("utm_token");
   let buy = window.sessionStorage.getItem("buy");
   let compare = window.sessionStorage.getItem("compare");

   let fundPrice = window.sessionStorage.getItem("fundPrice");
   let fundIsSponsored = window.sessionStorage.getItem("fundIsSponsored");
   let fundSponsorLogo = window.sessionStorage.getItem("fundSponsorLogo");
   let fundName = window.sessionStorage.getItem("fundName");
   let fundCategory = window.sessionStorage.getItem("fundCategory");
   let fundMI = window.sessionStorage.getItem("fundMI");
   let fundVehicle = window.sessionStorage.getItem("fundVehicle");

   let paymentStatus = window.sessionStorage.getItem("paymentStatus");

   if ((utm_source || utm_medium || utm_campaign) && addEventToAnalytics) {
      let qq = q || window.sessionStorage.getItem("query");
      addEventToAnalytics(
         "UTM_LINK_CAPTURED",
         "UTM_LINK_CAPTURED",
         "UTM_LINK_CAPTURED",
         { utm_source, utm_medium, utm_campaign, query: qq },
         true
      );
   }
   if (
      (utm_source || utm_medium || utm_campaign) &&
      utm_token &&
      addEventToAnalytics
   ) {
      let qq = q || window.sessionStorage.getItem("query");
      if (buy) {
         addEventToAnalytics(
            "WIDGET_TOKEN_CAPTURED_BUY",
            "WIDGET_TOKEN_CAPTURED_BUY",
            "WIDGET_TOKEN_CAPTURED_BUY",
            { utm_token, utm_source, utm_medium, utm_campaign, query: qq, buy },
            true
         );
      }
      addEventToAnalytics(
         "WIDGET_TOKEN_CAPTURED",
         "WIDGET_TOKEN_CAPTURED",
         "WIDGET_TOKEN_CAPTURED",
         { utm_token, utm_source, utm_medium, utm_campaign, query: qq },
         true
      );
   }

   let jObj = {};
   if (q) jObj = { ...jObj, query: q };
   if (utm_source) jObj = { ...jObj, utm_source: utm_source };
   if (utm_medium) jObj = { ...jObj, utm_medium: utm_medium };
   if (utm_campaign) jObj = { ...jObj, utm_campaign: utm_campaign };
   if (utm_term) jObj = { ...jObj, utm_term: utm_term };
   if (utm_content) jObj = { ...jObj, utm_content: utm_content };
   if (utm_usage_log_id) jObj = { ...jObj, utm_usage_log_id: utm_usage_log_id };
   if (utm_token) jObj = { ...jObj, utm_token: utm_token };
   if (buy) jObj = { ...jObj, buy: buy };
   if (compare) jObj = { ...jObj, compare: compare };
   if (fundPrice) jObj = { ...jObj, fundPrice: fundPrice };
   if (fundIsSponsored) jObj = { ...jObj, fundIsSponsored: fundIsSponsored };
   if (fundSponsorLogo) jObj = { ...jObj, fundSponsorLogo: fundSponsorLogo };
   if (fundName) jObj = { ...jObj, fundName: fundName };
   if (fundCategory) jObj = { ...jObj, fundCategory: fundCategory };
   if (fundMI) jObj = { ...jObj, fundMI: fundMI };
   if (fundVehicle) jObj = { ...jObj, fundVehicle: fundVehicle };
   if (paymentStatus) jObj = { ...jObj, paymentStatus: paymentStatus };

   let urlWillBe = jsonInURL(jObj, "/securities");

   return urlWillBe;

   // return (
   //   '/securities' +
   //   (q ? `?query=${encodeURIComponent(q)}` : '') +
   //   (utm_source ? `&utm_source=${utm_source}` : '') +
   //   (utm_medium ? `&utm_medium=${utm_medium}` : '') +
   //   (utm_campaign ? `&utm_campaign=${utm_campaign}` : '') +
   //   (utm_term ? `&utm_term=${utm_term}` : '') +
   //   (utm_content ? `&utm_content=${utm_content}` : '') +
   //   (utm_usage_log_id ? `&utm_usage_log_id=${utm_usage_log_id}` : '') +
   //   (utm_token ? `&utm_token=${utm_token}` : '') +
   //   (buy ? `&buy=${buy}` : '') +
   //   (compare ? `&compare=${compare}` : '') +
   //   (fundPrice ? `&fundPrice=${fundPrice}` : '') +
   //   (fundIsSponsored ? `&fundIsSponsored=${fundIsSponsored}` : '') +
   //   (fundSponsorLogo ? `&fundSponsorLogo=${encodeURIComponent(fundSponsorLogo)}` : '') +
   //   (fundName ? `&fundName=${encodeURIComponent(fundName)}` : '') +
   //   (fundCategory ? `&fundCategory=${encodeURIComponent(fundCategory)}` : '') +
   //   (fundMI ? `&fundMI=${fundMI}` : '') +
   //   (fundVehicle ? `&fundVehicle=${fundVehicle}` : '') +
   //   (paymentStatus ? `&paymentStatus=${paymentStatus}` : '')
   // )
};

/* getNest(['user', 'posts', 0, 'comments'], dataObj) */
export const getNest = (path, obj) =>
   path.reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : undefined), obj);
export const getNestDefault = (path, obj, val) =>
   path.reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : val), obj);

export const uniqValues = (arr, key) => [...new Set(arr.map(e => e[key]))];

export const nameToDateRange = (name, range, startFrom) => {
   var out = {
      end: startFrom
         ? range.end.clone().endOf("month").year(startFrom)
         : range.end.clone().endOf("month"),
   };
   out.start = out.end.clone();
   // let endDate = range.end.date();
   if (name === "l10y") {
      out.start.add(1, "seconds").subtract(10, "year");
   } else if (name === "l5y") {
      out.start.add(1, "seconds").subtract(5, "year");
   } else if (name === "l3y") {
      out.start.add(1, "seconds").subtract(3, "year");
   } else if (name === "l1y") {
      out.start.add(1, "seconds").subtract(1, "year");
   } else {
      out.start = moment(range.start);
   }

   return out;
};

export const nameToDateRangeForDailyReturns = (name, range, startFrom) => {
   var out = {
      end: startFrom
         ? range.end.clone().endOf("month").year(startFrom)
         : range.end.clone().endOf("month"),
   };
   out.start = out.end.clone();
   let endDate = range.end.date();
   if (name === "l10y") {
      out.start
         .add(1, "seconds")
         .subtract(10, "year")
         .subtract(1, "days")
         .date(endDate);
   } else if (name === "l5y") {
      out.start
         .add(1, "seconds")
         .subtract(5, "year")
         .subtract(1, "days")
         .date(endDate);
   } else if (name === "l3y") {
      out.start
         .add(1, "seconds")
         .subtract(3, "year")
         .subtract(1, "days")
         .date(endDate);
   } else if (name === "l1y") {
      out.start
         .add(1, "seconds")
         .subtract(1, "year")
         .subtract(1, "days")
         .date(endDate);
   } else if (name === "l6m") {
      out.start = out.start
         .add(1, "seconds")
         .subtract(6, "months")
         .subtract(1, "days")
         .date(endDate);
   } else if (name === "l3m") {
      out.start = out.start
         .add(1, "seconds")
         .subtract(3, "months")
         .subtract(1, "days")
         .date(endDate);
   } else if (name === "l1m") {
      out.start = out.start
         .add(1, "seconds")
         .subtract(1, "months")
         .subtract(1, "days")
         .date(endDate);
   } else {
      out.start = moment(range.start);
   }

   return out;
};

export const monthToDate = str => moment("01 " + str, "DD MMM YYYY");
export const dateToMonth = date => date.format("MMM YYYY");

export const isString = x => {
   return Object.prototype.toString.call(x) === "[object String]";
};

export const toNumber = v => {
   if (isString(v)) {
      return Number(v);
   } else {
      return v;
   }
};

export const numFmt = (num, prec = 2) =>
   num && !isNaN(num)
      ? num.toLocaleString("en", {
           minimumFractionDigits: 0,
           maximumFractionDigits: prec,
        })
      : "";
// export const numFmt = (num, prec = 2) => (num || 0).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: prec });
export const numDc = (num, prec = 2) => +(num || 0.0).toFixed(prec);

export const arrSum = arr => arr.reduce((a, b) => a + b, 0);

export const validWeight = num => !/\D/.test(num) && num >= 0 && num <= 50;

export const validWeight2 = num => {
   const _num = +num;
   return Number.isInteger(_num) && _num >= 0 && _num <= 50;
};

export const sortFn = attr => (a, b) => {
   let [_a, _b] = [a[attr], b[attr]];
   if (_a !== undefined && _b !== undefined) {
      if (_a < _b) return 1;
      else if (_a > _b) return -1;
   }
   return 0;
};

export const closeTraditWindow = () => {
   if (window._tradeItWindow) {
      window._tradeItWindow.close();
   }
};

export const convertToCapitalCase = str => {
   var frags = str.split("_");
   if (frags.length <= 1) frags = frags[0].split(" ");

   for (let i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
   }
   return frags.join(" ");
};

// eslint-disable-next-line no-extend-native
Number.prototype.before = function () {
   var value = parseInt(this.toString().split(".")[0], 10); //before
   return value ? value : 0;
};
// eslint-disable-next-line no-extend-native
Number.prototype.after = function () {
   var value = parseInt(this.toString().split(".")[1], 10); //after
   return value ? value : 0;
};

export const formatTickerForView = str => {
   return str.split(".")[0].substring(0, 4);
};

export const getViewNameList = (showCarousel, view) =>
   (showCarousel && view && view.split(" & ")) || [];

export const isViewPresentInChartTypes = view => {
   return ViewNames.some(item => item.name === view);
};

export const CapitalizeEachWord = string => {
   return string.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
   });
};
const bowser = Bowser.getParser(window.navigator.userAgent);
export const clientDetails = {
   browser: bowser.getBrowserName(),
   platform: bowser.getPlatformType(),
   operatingSystem: bowser.getOSName(),
   osVersion: bowser.getOSVersion(),
};

export const addNewEventsToAnalytics = (
   eventName = "",
   data,
   queryUUID = ""
) => {
   addEventToAnalytics(eventName, eventName, eventName, data, queryUUID);
};

export const addPageViewEvent = page => {
   if (window.analytics) {
      window.analytics &&
         window.analytics.page &&
         enableTrackingEvents() &&
         window.analytics.page({
            title: page,
         });
   }
};

export const addIdentifyEvent = (identifier, properties) => {
   window.analytics &&
      window.analytics.identify &&
      enableTrackingEvents() &&
      window.analytics.identify(identifier, properties);
};

export const addEventToAnalytics = (
   eventName,
   _,
   googleEvt,
   data,
   queryUUID
) => {
   //to make analytics event async
   setTimeout(() => {
      const query_uuid = window.sessionStorage.getItem("query_uuid");
      if (queryUUID === true && query_uuid) {
         data = {
            ...data,
            query_uuid: query_uuid,
         };
      }
      try {
         enableTrackingEvents() &&
            window.gtag("event", googleEvt, {
               event_label: JSON.stringify(data),
            });
         // NOTE: Segment Script
         // ------------------------------------------------------------------------------------------
         if (
            window.analytics &&
            window.analytics.track &&
            typeof window.analytics.track === "function" &&
            enableTrackingEvents()
         ) {
            window.analytics.track(eventName, { ...data, ...clientDetails });
         }
         // ------------------------------------------------------------------------------------------
      } catch (e) {
         console.log(e);
      }
   }, 0);
};

export const changeLogo = () => {
   let logo = "/assets/images/MagnifiLogo/Magnifi_byTIFIN-darkgrey.svg";
   return fetchProfile().then(res => {
      if (res.data.restrict_sponsor) {
         if (res.data.restrict_sponsor === "blackrock") {
            logo = "/assets/images/MagnifiLogo/BlackRock-PoweredbyMagnifi.png";
            return logo;
         } else if (res.data.restrict_sponsor === "invesco") {
            logo = "/assets/images/MagnifiLogo/Invesco-PoweredbyMagnifi.png";
            return logo;
         } else if (res.data.restrict_sponsor === "fidelity") {
            logo = "/assets/images/MagnifiLogo/Fidelity-PoweredbyMagnifi.png";
            return logo;
         } else {
            logo = "/assets/images/MagnifiLogo/Magnifi_byTIFIN-darkgrey.svg";
            return logo;
         }
      } else {
         logo = "/assets/images/MagnifiLogo/Magnifi_byTIFIN-darkgrey.svg";
         return logo;
      }
   });
};

export const getAdditionalDetailsForRegister = () => {
   const campaignDetails = JSON.parse(
      getCookie("magnifiRegisterCampaign") || "{}"
   );
   let utm_source =
      sessionStorage.getItem("utm_source") || campaignDetails.utm_source;
   let utm_medium =
      sessionStorage.getItem("utm_medium") || campaignDetails.utm_medium;
   let utm_campaign =
      sessionStorage.getItem("utm_campaign") || campaignDetails.utm_campaign;
   let buy = sessionStorage.getItem("buy") || campaignDetails.buy;

   let domain = parseDomain(utm_source);
   if (domain && domain.domain) {
      utm_source = domain.domain;
   }

   let domain1 = parseDomain(utm_campaign);
   if (domain1 && domain1.domain) {
      utm_campaign = domain1.domain;
   }

   return {
      ...(utm_source && { utm_source }),
      ...(utm_medium && { utm_medium }),
      ...(utm_campaign && { utm_campaign }),
      ...(buy && { buy }),
   };
};

export const clearUtmSession = () => {
   sessionStorage.removeItem("utm_source");
   sessionStorage.removeItem("utm_medium");
   sessionStorage.removeItem("utm_campaign");
   sessionStorage.removeItem("utm_content");
   sessionStorage.removeItem("utm_term");
};

export const clearWindowUtmSession = () => {
   console.log("==== clearWindowUtmSession ====");
   if (window.sessionStorage.getItem("utm_campaign")) {
      window.sessionStorage.removeItem("utm_campaign");
   }
   if (window.sessionStorage.getItem("utm_medium")) {
      window.sessionStorage.removeItem("utm_medium");
   }
   if (window.sessionStorage.getItem("utm_source")) {
      window.sessionStorage.removeItem("utm_source");
   }
   if (window.sessionStorage.getItem("utm_term")) {
      window.sessionStorage.removeItem("utm_term");
   }
   if (window.sessionStorage.getItem("utm_content")) {
      window.sessionStorage.removeItem("utm_content");
   }
   if (window.sessionStorage.getItem("utm_usage_log_id")) {
      window.sessionStorage.removeItem("utm_usage_log_id");
   }
   if (window.sessionStorage.getItem("utm_token")) {
      window.sessionStorage.removeItem("utm_token");
   }
   if (window.sessionStorage.getItem("buy")) {
      window.sessionStorage.removeItem("buy");
   }
   if (window.sessionStorage.getItem("compare")) {
      window.sessionStorage.removeItem("compare");
   }
};

export const FormatCurrency = (value, prec = 0, curr = "$", type) => {
   let _prefix = value < 0 ? "-" : "";
   let [_value, _suffix, _prec] = [Math.abs(value), "", prec];

   if (_value >= 1e9) [_value, _suffix, _prec] = [_value / 1e9, "B", 2];
   else if (_value >= 1e6) [_value, _suffix, _prec] = [_value / 1e6, "M", 2];
   else if (_value >= 1e3) [_value, _suffix, _prec] = [_value / 1e3, "K", 2];

   if (_value >= 100 && _prec >= 0) _prec = 0;
   else if (_value >= 10 && _prec >= 0) _prec = 1;

   if (type === "unf") return `${_prefix}${curr}${numFmt(Math.abs(value))}`;
   return `${_prefix}${curr}${numFmt(_value, _prec)}${_suffix}`;
};

export const getDelimeter = lines => {
   //supported delimeters
   //check for split length for top 10 lines
   //return the first match
   // 245,"sid", sid, 23
   const supported_delimeters = [",", "|", ";", "\t"];
   let loop_length = lines.length < 10 ? lines.length : 10;
   let identified_delimeter = "";
   for (let y = 0; y < supported_delimeters.length; y++) {
      let current_delimeter = supported_delimeters[y];
      let split_length = -1;
      for (let i = 0; i < loop_length; i++) {
         if (lines[i] === "") {
            break;
         }
         let current_delimeter_split = lines[i].split(current_delimeter);
         current_delimeter_split = current_delimeter_split.map(l => {
            l = l.trim();
            return l;
         });
         // console.log(
         //   'split_length',
         //   i,
         //   lines[i],
         //   split_length,
         //   current_delimeter_split.length
         // )
         if (current_delimeter_split.length === 1) {
            identified_delimeter = "";
            break;
         } else if (split_length === -1 && current_delimeter_split.length > 1) {
            // console.log(
            //   'current_delimeter set',
            //   current_delimeter,
            //   identified_delimeter
            // )
            split_length = current_delimeter_split.length;
         } else {
            // other than first row
            if (split_length === current_delimeter_split.length) {
               // console.log(
               //   'current_delimeter',
               //   current_delimeter,
               //   identified_delimeter
               // )
               identified_delimeter = current_delimeter;
            } else {
               //did not matches the first row split length
               // console.log(
               //   'current_delimeter',
               //   current_delimeter,
               //   identified_delimeter
               // )
               identified_delimeter = "";
               break;
            }
         }
      }
      // console.log('current_delimeter', current_delimeter, identified_delimeter)
      if (identified_delimeter !== "") {
         break;
      }
   }
   // console.log('identified_delimeter', identified_delimeter)
   return identified_delimeter;
};

export const convertToFloatvalues = val => {
   if (val) {
      let number = String(val).replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "");
      number = parseFloat(number);
      return number;
   }
};

export const convertToUpperCase = (val, withQuotes = false) => {
   if (val) {
      let value = withQuotes
         ? String(val).trim().toUpperCase()
         : removeWrappingQuotes(String(val).trim().toUpperCase());
      return value;
   }
};

export const convertToLowerCase = (val, withQuotes = false) => {
   if (val) {
      let value = withQuotes
         ? String(val).trim().toLowerCase()
         : removeWrappingQuotes(String(val).trim().toLowerCase());
      return value;
   }
};

export const convertWordToCapitalizeCase = str => {
   if (str.length) {
      return str[0].toUpperCase() + str.slice(1).toLowerCase();
   }
   return str;
};

export const wholeNumberCheck = (val, prec = 2) => {
   if (val) {
      const number = convertToFloatvalues(val);
      const isWholeNumber = number - Math.floor(number) === 0;
      if (isWholeNumber) {
         return parseInt(number);
      } else {
         return Number(number.toFixed(prec));
      }
   }
};

export const removeWrappingQuotes = str => {
   if (str) {
      if (String(str.charAt(0)) === '"' || String(str.charAt(0)) === "'") {
         str = str.substring(1, str.length);
      }
      if (
         String(str.charAt(str.length - 1)) === '"' ||
         String(str.charAt(str.length - 1)) === "'"
      ) {
         str = str.substring(0, str.length - 1);
      }
      return str;
   }
};

export const findDelimiter = text => {
   const supportedDelimeters = [",", "|", ";", "\t"];
   let identified_delimeter = supportedDelimeters.filter(checkDelimiter);
   let commaDelimiter = /,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g;
   if (identified_delimeter[0] && identified_delimeter[0] === ",") {
      identified_delimeter[0] = commaDelimiter;
   }
   return identified_delimeter.length > 0
      ? identified_delimeter[0]
      : commaDelimiter;
   function checkDelimiter(delimiter) {
      var cache = -1;
      return text.split("\n").every(checkLength);

      function checkLength(line) {
         if (!line) {
            return true;
         }

         var length = line.split(delimiter).length;
         if (cache < 0) {
            cache = length;
         }
         return cache === length && length > 1;
      }
   }
};

export const csvToJSON = csv => {
   var lines = csv.split("\n");
   var result = [];
   var existingAccount = [];
   // var headers = lines[0].split(",");
   var inc = 1;
   for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      if (!existingAccount.includes(currentline[1])) {
         // Null value check
         if (
            currentline[0] &&
            currentline[1] &&
            currentline[2] &&
            currentline[3] &&
            currentline[4]
         ) {
            existingAccount.push(currentline[1]);
            obj.refID = inc;
            obj.name = formatValues(currentline[0]);
            obj.account_number = formatValues(currentline[1]);
            obj.custodian = formatValues(currentline[2]);
            obj.holdings = [
               {
                  ticker: formatValues(currentline[3]),
                  shares: parseInt(formatValues(currentline[4])),
                  price: parseFloat(formatValues(currentline[5])),
               },
            ];
            inc++;
         }
      } else {
         // Null value check
         if (
            currentline[0] &&
            currentline[1] &&
            currentline[2] &&
            currentline[3] &&
            currentline[4]
         ) {
            for (var j = 0; j < result.length; j++) {
               if (result[j].account_number === currentline[1]) {
                  result[j].holdings.push({
                     ticker: formatValues(currentline[3]),
                     shares: parseInt(formatValues(currentline[4])),
                     price: parseFloat(formatValues(currentline[5])),
                  });
               }
            }
         }
      }

      if (Object.entries(obj).length) result.push(obj);
   }

   return result; //JSON
};

const formatValues = val => {
   return val.replace(/(\r\n|\n|\r)/gm, "");
};

export const replaceTicker = obj => {
   if (obj.view_name && obj.view_name.includes("ticker")) {
      if (Object.prototype.toString.call(obj.data) === "[object Array]") {
         obj.view_name = obj.view_name.replace("ticker", obj.data[0]);
      } else if (typeof obj.data === "string") {
         obj.view_name = obj.view_name.replace("ticker", obj.data);
      }
   }
   return obj;
};

export const deflatLastNode = (obj, isAllListRequired = false) => {
   let allItems = [];
   for (const [key] of Object.entries(obj)) {
      let uncatagorized = [];
      for (const [key1] of Object.entries(obj[key])) {
         if (Array.isArray(obj[key][key1])) {
            if (isAllListRequired) {
               for (const item of obj[key][key1]) {
                  allItems.push(item);
               }
            }
            if (obj[key][key1].length === 1) {
               uncatagorized.push(obj[key][key1][0]);
               delete obj[key][key1];
            }
         }
      }
      if (uncatagorized.length) {
         obj[key]["uncatagorized"] = uncatagorized;
      }
   }
   return { obj, allItems };
};

export const buildComputedSearchFundsData = (funds, feeRiskReturn) => {
   const updatedFunds = funds.map(item => {
      let newItem = Object.assign({}, item);
      if ("assetTypeData" in newItem) {
         newItem.asset_alloc = newItem.assetTypeData;
         delete newItem.assetTypeData;
      }
      if (!newItem.Fee_Risk_Return) {
         const curFeeRiskReturn = feeRiskReturn
            ? feeRiskReturn.find(obj => item.ticker in obj)
            : {};
         newItem.Fee_Risk_Return = curFeeRiskReturn
            ? curFeeRiskReturn[item.ticker].Fee_Risk_Return
            : {};
      }
      return newItem;
   });
   return updatedFunds;
};

export const buildAllFundsData = (chartReportData, itemView) => {
   let allFunds = chartReportData.allFunds.funds;
   // let allFunds = _.uniqBy([...chartReportData.allFunds.funds, ...chartReportData.selectedFunds],'ticker');
   let additionalFundData = {
      Assets: {},
      "1 Year Yield": {},
      "30-Day Yield": {},
      "Dividend Yield": {},
      Regions: {},
      Sectors: {},
      Volume: {},
      Themes: {},
      "Top Holdings": { funds: [] },
      "Asset Allocation": [],
   };
   const view = itemView.view;
   allFunds.map(item => {
      additionalFundData["Assets"][item.ticker] = getAssetData(item);
      additionalFundData["30-Day Yield"][item.ticker] =
         getDividentYieldData(item);
      additionalFundData["1 Year Yield"][item.ticker] =
         getDividentYieldData(item);
      additionalFundData["Dividend Yield"][item.ticker] =
         getDividentYieldData(item);
      additionalFundData["Regions"][item.ticker] = item.region || [];
      additionalFundData["Sectors"][item.ticker] = getSectorData(item);
      additionalFundData["Volume"][item.ticker] = getVolumeData(item);
      additionalFundData["Themes"][item.ticker] = getThemesData(item);
      additionalFundData["Top Holdings"]["funds"].push(getHoldingsData(item));
      additionalFundData["Asset Allocation"].push(item.asset_alloc);
      if (view === "Diversification") {
         additionalFundData["Diversification"] =
            additionalFundData["Diversification"] || [];
         additionalFundData["Diversification"].push(item.diverseData);
      }
      if (view === "Return Quality") {
         additionalFundData["Return Quality"] = additionalFundData[
            "Return Quality"
         ]
            ? { ...additionalFundData["Return Quality"] }
            : {};
         additionalFundData["Return Quality"][item.ticker] =
            getReturnQualityChart(item);
      }
      if (!isViewPresentInChartTypes(view) && isDynamicChart(view, item)) {
         additionalFundData[view] = additionalFundData[view]
            ? { ...additionalFundData[view] }
            : {};
         additionalFundData[view][item.ticker] = getDynamicChartData(
            item,
            view
         );
      }
      item.query_var = chartReportData.allFunds.query_var;
   });

   // Remove unnecessary keys to reduce payload
   removeKeys.forEach(item => {
      if (item in chartReportData.allFunds) {
         delete chartReportData.allFunds[item];
      }
   });

   chartReportData.allFunds = {
      ...chartReportData.allFunds,
      ...additionalFundData,
      // funds: allFunds,
   };
   chartReportData.initialAllFunds = JSON.parse(
      JSON.stringify(chartReportData.allFunds)
   );
   return chartReportData;
};

const removeKeys = [
   "data",
   "http_status",
   "http_statusText",
   "date",
   "page_number",
   "q",
   "related_queries",
   "sponsors_tickers",
   "total_funds",
];

export const updateAllFundsData = (
   chartReportData,
   itemView,
   isSelectedFundExist,
   selectedFund,
   query_var
) => {
   const view = itemView.view;
   if (!isSelectedFundExist) {
      chartReportData.allFunds["Assets"][selectedFund.ticker] =
         getAssetData(selectedFund);
      chartReportData.allFunds["1 Year Yield"][selectedFund.ticker] =
         getDividentYieldData(selectedFund);
      chartReportData.allFunds["30-Day Yield"][selectedFund.ticker] =
         getDividentYieldData(selectedFund);
      chartReportData.allFunds["Dividend Yield"][selectedFund.ticker] =
         getDividentYieldData(selectedFund);
      chartReportData.allFunds["Regions"][selectedFund.ticker] =
         selectedFund.region || [];
      chartReportData.allFunds["Sectors"][selectedFund.ticker] =
         getSectorData(selectedFund);
      chartReportData.allFunds["Volume"][selectedFund.ticker] =
         getVolumeData(selectedFund);
      chartReportData.allFunds["Themes"][selectedFund.ticker] =
         getThemesData(selectedFund);
      chartReportData.allFunds["Top Holdings"]["funds"].push(
         getHoldingsData(selectedFund)
      );
      chartReportData.allFunds["Asset Allocation"].push(
         selectedFund.asset_alloc
      );
      if (view === "Diversification") {
         chartReportData.allFunds["Diversification"].push(
            selectedFund.diverseData
         );
      }
      if (view === "Return Quality") {
         chartReportData.allFunds["Return Quality"][selectedFund.ticker] =
            getReturnQualityChart(selectedFund);
      }
      if (
         !isViewPresentInChartTypes(view) &&
         isDynamicChart(view, selectedFund)
      ) {
         chartReportData.allFunds[view][selectedFund.ticker] =
            getDynamicChartData(selectedFund, view);
      }
      selectedFund.query_var = query_var;
      chartReportData.allFunds.funds.push(selectedFund);
   } else {
      delete chartReportData.allFunds["Assets"][selectedFund.ticker];
      delete chartReportData.allFunds["1 Year Yield"][selectedFund.ticker];
      delete chartReportData.allFunds["30-Day Yield"][selectedFund.ticker];
      delete chartReportData.allFunds["Dividend Yield"][selectedFund.ticker];
      delete chartReportData.allFunds["Regions"][selectedFund.ticker];
      delete chartReportData.allFunds["Sectors"][selectedFund.ticker];
      delete chartReportData.allFunds["Volume"][selectedFund.ticker];
      delete chartReportData.allFunds["Themes"][selectedFund.ticker];
      chartReportData.allFunds["Top Holdings"]["funds"] =
         chartReportData.allFunds["Top Holdings"]["funds"].filter(
            item => item.ticker !== selectedFund.ticker
         );
      chartReportData.allFunds["Asset Allocation"] = chartReportData.allFunds[
         "Asset Allocation"
      ].filter(item => item.ticker !== selectedFund.ticker);
      if (view === "Diversification") {
         chartReportData.allFunds["Diversification"] = chartReportData.allFunds[
            "Diversification"
         ].filter(item => item.ticker !== selectedFund.ticker);
      }
      if (view === "Return Quality") {
         delete chartReportData.allFunds["Return Quality"][selectedFund.ticker];
      }
      if (
         !isViewPresentInChartTypes(view) &&
         isDynamicChart(view, selectedFund)
      ) {
         delete chartReportData.allFunds[view][selectedFund.ticker];
      }
   }
   return chartReportData;
};

const getAssetData = item => {
   if (!item._cstats) return;
   const qi = QuinItems[2];
   const mult = qi.name === "Assets" ? 1000 * 1000 : 1;
   const _min = item._cstats[qi.col + "_min"],
      _max = item._cstats[qi.col + "_max"],
      _val = qi.name === "Assets" ? item[qi.col] : item[qi.col] * mult,
      _pc = item[qi.pcCol];
   return {
      max: _max,
      min: _min,
      pctile: _pc,
      q: 5,
      value: _val,
   };
};

const getDividentYieldData = item => {
   // const name = "Dividend Yield";
   // const mult = name === "Dividend Yield" ? 100 : 1;
   if (
      isNull(item.dividend_yield) ||
      isUndefined(item.dividend_yield) ||
      item.dividend_yield === "null" ||
      item.dividend_yield === null ||
      typeof item.dividend_yield == "undefined"
   )
      return "";
   if (isNull(item.dividendMin) || isUndefined(item.dividendMin)) return "";
   if (
      isNull(item.dividendMax) ||
      isUndefined(item.dividendMax) ||
      item.dividendMax <= 0.0
   )
      return "";
   if (item.dividendMax === item.dividendMin) return "";

   let _min = item.dividendMin,
      _max = item.dividendMax,
      _val = item.dividend_yield;

   return {
      max: _max,
      min: _min,
      value: _val,
   };
};

const getSectorData = item => {
   const name = "Sectors";
   var mult = name === "Theme" ? 100 : 1;
   const attrs = SectorsAttrs;
   let data = {};
   attrs.forEach(
      v =>
         (data[v === "Other Sectors" ? "Others Non Classified" : v] = cn(
            item,
            v
         )
            ? parseFloat(cn(item, v) * mult)
            : 0)
   );
   return data;
};

const getVolumeData = item => {
   if (!item._cstats) return;
   const qi = QuinItems[1];
   const mult = qi.name === "Assets" ? 1000 * 1000 : 1;
   const _min = item._cstats[qi.col + "_min"],
      _max = item._cstats[qi.col + "_max"],
      _val = qi.name === "Assets" ? item[qi.col] : item[qi.col] * mult,
      _pc = item[qi.pcCol];
   return {
      max: _max,
      min: _min,
      pctile: _pc,
      q: 5,
      value: _val,
   };
};

const getThemesData = item => {
   // const name = "Theme Score";
   // const mult = name === 'Theme Score' ? 100 : 1; //Check this multiplier might vary, just using for now to match it with computed search
   const mult = 1;
   const data = item.themeScore
      ? item.themeScore
           .filter(e => e.value > 1e-5)
           .map((e, i) => {
              return {
                 name: e.name,
                 value: +e.value * mult,
              };
           })
      : [];
   return data;
};

const getHoldingsData = item => {
   let newItem = {};
   if (item && item.ticker && item.holdingData) {
      newItem.ticker = item.ticker;
      newItem.holdings = item.holdingData.map(holding => {
         return {
            name: holding.name,
            weight: holding.value,
         };
      });
      return newItem;
   }
};

const isDynamicChart = (view, item) => {
   let chartData =
      item.chartData && item.chartData.length
         ? item.chartData.filter(data => {
              return data.display_view_name === view;
           })
         : [];
   if (chartData.length) {
      if (!chartData[0].Static && chartData[0].chart_type === "odometer") {
         return true;
      }
   }
   return false;
};

const getReturnQualityChart = item => {
   if (!item.riskAdjReturn) return "";
   const dataViews = ["sharpe", "sortino", "trackingError"];
   const returnQualityData = dataViews.map(singleView => {
      const currentView =
         singleView === "sharpe"
            ? "Sharpe"
            : singleView === "sortino"
            ? "Sortino"
            : singleView === "trackingError"
            ? "Tracking Error"
            : "";
      const riskAdjReturn = item.riskAdjReturn.filter(
         e => e.name === currentView
      );
      if (!riskAdjReturn.length) return "";
      let data = {};
      data.name = currentView;
      data.max = item[singleView + "Max"];
      data.min = item[singleView + "Min"];
      data.value = riskAdjReturn[0].value;
      return data.max !== data.min ? data : "";
   });
   return returnQualityData;
};

const getDynamicChartData = (item, view) => {
   if (typeof item.chartData === "undefined") return "";
   const currentChartData = item.chartData.filter(
      e => e.display_view_name === view
   )[0];
   if (typeof currentChartData === "undefined") return "";
   const mult =
      typeof currentChartData !== "undefined"
         ? currentChartData.multiplier !== ""
            ? currentChartData.multiplier
            : 1
         : 1;
   if (isNaN(currentChartData.min)) return "";

   if (isNaN(currentChartData.max)) return "";

   if (isNaN(item[currentChartData.variable])) return "";

   //not present
   if (
      item[currentChartData.variable] == null ||
      typeof item[currentChartData.variable] == undefined
   )
      return "";

   //les then min
   if (item[currentChartData.variable] < currentChartData.min) return "";

   //les then max
   if (item[currentChartData.variable] > currentChartData.max) return "";

   //if same
   if (currentChartData.min === currentChartData.max) return "";

   let _min = currentChartData.min,
      _max = currentChartData.max,
      _val = item[currentChartData.variable],
      decimal = currentChartData.decimal,
      suffix = currentChartData.suffix;

   return {
      max: _max,
      min: _min,
      decimal,
      multiplier: mult,
      suffix,
      value: _val,
   };
};

export const deepMergeObjects = (source, destination) => {
   let out = JSON.parse(JSON.stringify(source));
   Object.keys(destination).map(key => {
      if (key in out) {
         if (isObject(out[key])) {
            if (key === "Top Holdings") {
               out[key].funds = _.uniqBy(
                  [...out[key].funds, ...destination[key].funds],
                  "ticker"
               );
            } else {
               out[key] = { ...out[key], ...destination[key] };
            }
         } else if (Array.isArray(out[key])) {
            if (Array.isArray(destination[key])) {
               out[key] = [...out[key], ...destination[key]];
               if (key === "funds" || key === "Asset Allocation") {
                  out[key] = _.uniqBy(out[key], "ticker");
               }
            } else {
               out[key] = [...out[key], destination[key]];
            }
         } else {
            out[key] = [out[key], destination[key]];
         }
      } else {
         out[key] = destination[key];
      }
   });
   return out;
};

export const isObject = val => {
   if (val) {
      if (Object.prototype.toString.call(val) === "[object Object]") {
         return true;
      } else {
         return false;
      }
   }
   return false;
};

export const getPortfolioBenchmarkOfSelectedTicker = (data, ticker) => {
   if (data) {
      let returns = null;
      for (let item of data) {
         if (item.ticker === ticker) {
            returns = item.returns;
            break;
         }
      }
      return returns;
   }
   return null;
};

export const getDefaultScreenerValue = attr => {
   let out;
   if (attr.weight_name === "expense_ratio") {
      out = {
         criteria: [
            {
               id: 1,
               value: 50,
               suffix: "% Peers",
               condition: "<=",
               status: false,
               type: "select",
               options: [0, 25, 50, 75, 100],
               optMapping: {
                  0: "min_expense_ratio",
                  25: "expense_ratio_25pct",
                  50: "median_expense_ratio",
                  75: "expense_ratio_75pct",
                  100: "max_expense_ratio",
               },
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "alpha_5y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 0,
               suffix: "%",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "alpha_10y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 0,
               suffix: "%",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "manager_tenure") {
      out = {
         criteria: [
            {
               id: 1,
               value: 2,
               suffix: "Yr",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
                  onlyPositive: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "outperformance_3y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 0,
               suffix: "%",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "downside_capture_5y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 100,
               suffix: "%",
               condition: "<=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "upside_capture_5y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 100,
               suffix: "%",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "upside_capture_10y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 100,
               suffix: "%",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "aumAC") {
      out = {
         criteria: [
            {
               id: 1,
               value: 100,
               suffix: "Mn",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
                  onlyPositive: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "downside_capture_10y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 100,
               suffix: "%",
               condition: "<=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "outperformance_5y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 0,
               suffix: "%",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   if (attr.weight_name === "outperformance_10y") {
      out = {
         criteria: [
            {
               id: 1,
               value: 0,
               suffix: "%",
               condition: ">=",
               status: false,
               type: "input",
               validations: {
                  isRequired: true,
               },
            },
         ],
      };
   }
   return out;
};

export const immutableObject = val => {
   if (typeof val === "object" && val !== null) {
      return JSON.parse(JSON.stringify(val));
   } else {
      return val;
   }
};

export const fixedValue = (val, prec = 2) => {
   return val || val === 0 ? Number(Number(val).toFixed(prec)) : "";
};

// executed when we upload csv file
export const mapCsvToJSON = (
   csv,
   filter,
   realTimeTickerPrice,
   noData,
   portfolioValue
) => {
   let lines = csv.split(/\r\n|\n/);
   const identified_delimeter = findDelimiter(csv);
   if (lines.length > 1) {
      let result = [];
      // let sumOfPriceAndShares = 0,
      // let sumOfShares = 0,
      let sumOfValues = 0,
         sumOfAmount = 0,
         // sumOfWeight = 0,
         SUM_OF_VALUES_FOR_API_WEIGHT = 0,
         // SUM_OF_SHARES_API_WEIGHT = 0,
         ORIGINAL_SHARE_PRESENT = false;
      let headers = lines[0].split(identified_delimeter);

      headers = headers
         .map(l => {
            l = convertToLowerCase(l);
            if (l === "lot date") l = "date";
            return l;
         })
         .filter(item => item);

      if (!headers.length) {
         const obj = {
            error: "Please upload a file with the ticker column and at least one of these columns - shares, weight or amount.",
            headers,
         };
         return JSON.stringify(obj);
      }

      if (headers.length === 1) {
         if (headers[0] === "ticker") {
            const obj = {
               error: "Only ticker column available! Please upload a file with the ticker column and at least one of these columns - shares, weight or amount.",
            };
            return JSON.stringify(obj);
         }
      }

      if (!headers.includes("ticker")) {
         const obj = {
            error: "Ticker column is missing. Please add the ticker details.",
         };
         return JSON.stringify(obj);
      }

      // console.log('-------lines------',lines);

      if (filter) {
         const { headersArray, maxHeadersCount, addHeader } = filter;
         if (headersArray && headersArray.length > 0 && maxHeadersCount) {
            if (headers.length <= maxHeadersCount) {
               // const datePresent = _.includes(headersArray, "date");

               let found = headers.filter(l => {
                  // console.log(l, l.length);
                  if (_.includes(headersArray, l)) {
                     return l;
                  }
               });

               if (found.length === headers.length) {
                  // console.log(found);
                  if (addHeader && addHeader.length) {
                     // console.log(result);
                     found = [...found, ...addHeader];
                  }
                  let uniqueTickers = [];

                  lines.forEach((item, i) => {
                     let obj = {};
                     const commaDelimiter =
                        /,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/g;
                     let line_delimeter = identified_delimeter;
                     if (identified_delimeter === ",") {
                        line_delimeter = commaDelimiter;
                     }
                     let currentline = lines[i].split(line_delimeter);

                     found.forEach((y, k) => {
                        obj[found[k]] = currentline[k];
                     });
                     // Add duplicate values of tickers shares, amount and weight
                     if (
                        obj.ticker &&
                        !uniqueTickers.includes(convertToUpperCase(obj.ticker))
                     ) {
                        result.push(obj);
                        uniqueTickers.push(convertToUpperCase(obj.ticker));
                     } else {
                        result = result.map(item => {
                           if (
                              convertToUpperCase(item.ticker) ===
                              convertToUpperCase(obj.ticker)
                           ) {
                              if (item.shares && obj.shares) {
                                 item.shares =
                                    convertToFloatvalues(item.shares) +
                                    convertToFloatvalues(obj.shares);
                              }
                              if (item.amount && obj.amount) {
                                 item.amount =
                                    convertToFloatvalues(item.amount) +
                                    convertToFloatvalues(obj.amount);
                              }
                              if (item.weight && obj.weight) {
                                 item.weight =
                                    convertToFloatvalues(item.weight) +
                                    convertToFloatvalues(obj.weight);
                                 item.original_weight =
                                    item.original_weight || item.weight;
                              }
                              return item;
                           } else {
                              if (item.ticker) {
                                 return item;
                              }
                           }
                        });
                     }
                  });

                  var invalidFormatError = true;

                  result.map(kl => {
                     if (kl.ticker !== "ticker") {
                        if (
                           kl.shares !== "" ||
                           kl.weight !== "" ||
                           kl.amount !== ""
                        ) {
                           invalidFormatError = false;
                        }
                     }
                  });

                  if (invalidFormatError) {
                     const obj = {
                        error: "Invalid file format.",
                     };
                     return JSON.stringify(obj);
                  }

                  if (addHeader && addHeader.length) {
                     // console.log(result);
                     const invalidPriceFormat = new RegExp(
                        /[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/
                     );
                     let isDateValid = true;
                     // console.log(result);
                     // considering 3 scenarios for CSV type
                     // ticker + share
                     // ticker + amount
                     // ticker + weight
                     result.map(kl => {
                        if (kl.ticker !== "ticker") {
                           if (kl.shares && kl.shares !== "undefined") {
                              kl.shares = convertToFloatvalues(kl.shares);
                              // sumOfShares += kl.shares;
                           }
                           if (kl.amount && kl.amount !== "undefined") {
                              kl.amount = convertToFloatvalues(kl.amount);
                              sumOfAmount += kl.amount;
                           }
                           if (kl.weight && kl.weight !== "undefined") {
                              kl.weight = convertToFloatvalues(kl.weight);
                              // sumOfWeight += kl.weight;
                           }
                        }
                     });
                     // sumOfShares = JSON.parse(JSON.stringify(sumOfShares));
                     sumOfAmount = JSON.parse(JSON.stringify(sumOfAmount));
                     // sumOfWeight = JSON.parse(JSON.stringify(sumOfWeight));
                     sumOfValues = JSON.parse(JSON.stringify(sumOfValues));
                     result.map((y, k) => {
                        // Convert ticker to lowercase
                        // Step 1: Check Ticker
                        if (
                           y.ticker &&
                           convertToLowerCase(y.ticker) === "ticker"
                        ) {
                           y.ticker = convertToLowerCase(y.ticker);
                        }
                        if (
                           y.ticker &&
                           y.ticker !== "ticker" &&
                           y.ticker !== "" &&
                           typeof y.ticker !== "undefined"
                        ) {
                           // if (y.ticker && convertToLowerCase(y.ticker) === 'cash') {
                           //   y.price = 1;
                           //   y.realTimePrice = 1;
                           // }
                           y.ticker = convertToUpperCase(y.ticker);
                           if (
                              typeof y.weight !== "undefined" &&
                              y.weight !== "NA"
                           ) {
                              y.weight_calculated_format = "weight";
                              y.format_value = y.weight;
                           } else if (
                              typeof y.amount !== "undefined" &&
                              y.amount !== "NA"
                           ) {
                              y.weight_calculated_format = "amount";
                              y.format_value = y.amount;
                           } else if (
                              typeof y.shares !== "undefined" &&
                              y.shares !== "NA"
                           ) {
                              y.weight_calculated_format = "shares";
                              y.format_value = y.shares;
                           }
                           // Step 2: Bind realtime-price
                           // Add real-time price for each ticker
                           if (
                              realTimeTickerPrice &&
                              realTimeTickerPrice.funds
                           ) {
                              let tickerDetails =
                                 realTimeTickerPrice.funds.find(
                                    a =>
                                       a.ticker.toUpperCase() ===
                                       y.ticker.toUpperCase()
                                 );
                              if (
                                 tickerDetails &&
                                 typeof tickerDetails.price !== "undefined"
                              ) {
                                 if (
                                    invalidPriceFormat.test(tickerDetails.price)
                                 ) {
                                    // console.log(y.ticker, 'invalid price format ----', tickerDetails.price);
                                    y.realTimePrice =
                                       tickerDetails.price.replace(
                                          /[&\/\\#,+()$~%'":*?<>{}]/g,
                                          ""
                                       );
                                    y.price = Number(y.realTimePrice); // ? y.price : y.realTimePrice
                                 } else {
                                    y.realTimePrice = Number(
                                       tickerDetails.price
                                    );
                                    y.price = Number(y.realTimePrice); // ? y.price : y.realTimePrice
                                 }
                              } else {
                                 // console.log(`== missing realtime price for ticker ==> ${y.ticker} ` , tickerDetails, y.price);
                                 y.price = "NA"; // 0; //Number(y.price) || 'NA';
                              }
                              // console.log(`Step 2: executed price for ticker ${y.ticker} ===>>>`, y.price);
                              if (
                                 typeof y.price !== "undefined" &&
                                 y.price &&
                                 y.price !== "NA"
                              ) {
                                 // Step 3(a): Calculate weight
                                 // check for ticker weight is present in csv
                                 if (
                                    y.weight &&
                                    y.weight !== "NA" &&
                                    typeof y.weight !== "undefined"
                                 ) {
                                    // console.log(convertToFloatvalues(y.weight));
                                    y.weight = convertToFloatvalues(y.weight);
                                    y.original_weight = convertToFloatvalues(
                                       y.weight
                                    );
                                    y["weight(%)"] = y.weight;
                                 } else {
                                    // console.log(`=== WEIGHT is missing in csv file for ticker ${y.ticker} ===> `, y.weight);
                                    // check if AMOUNT is present
                                    if (
                                       y.amount &&
                                       typeof y.amount !== "undefined" &&
                                       y.amount !== "NA" &&
                                       sumOfAmount
                                    ) {
                                       // console.log('=== calculating original_weight by using Amount ===>', y.amount);
                                       y.original_weight =
                                          (y.amount / sumOfAmount) * 100;
                                       y.original_weight = convertToFloatvalues(
                                          y.original_weight
                                       );
                                    } else {
                                       // console.log(`== Failed to calculate original_weight for ticker ${y.ticker}, amount/sumOfAmount is wrong =>> `, y.amount, sumOfAmount);
                                       y.original_weight = "NA";
                                    }
                                    y["weight(%)"] = y.original_weight;
                                 }

                                 // Steps 4: calculate SHARES
                                 if (
                                    y.shares &&
                                    typeof y.shares !== "undefined" &&
                                    y.shares !== "NA"
                                 ) {
                                    // console.log('=== SHARES already present ===>', y.shares);
                                    // y.shares = wholeNumberCheck(y.shares);
                                    y.shares = Number(y.shares);
                                    ORIGINAL_SHARE_PRESENT = true;
                                 } else {
                                    if (
                                       y.original_weight &&
                                       typeof y.original_weight !==
                                          "undefined" &&
                                       y.original_weight !== "NA" &&
                                       y.realTimePrice &&
                                       y.realTimePrice !== "NA" &&
                                       typeof y.realTimePrice !== "undefined"
                                    ) {
                                       if (
                                          y.amount &&
                                          y.amount !== "NA" &&
                                          typeof y.amount !== "undefined"
                                       ) {
                                          y.shares =
                                             ((y.original_weight / 100) *
                                                sumOfAmount) /
                                             y.realTimePrice;
                                       } else {
                                          y.shares =
                                             ((y.original_weight / 100) *
                                                portfolioValue) /
                                             y.realTimePrice;
                                       }
                                    } else {
                                       // console.log(`===  Failed to calculate SHARES for ticker ${y.ticker}, original_weight/realTimePrice is missing ==>`, y.original_weight, y.realTimePrice);
                                       y.shares = "NA";
                                    }
                                 }

                                 // Steps 5: calculate VALUES
                                 if (
                                    y.amount &&
                                    y.amount !== "NA" &&
                                    typeof y.amount !== "undefined" &&
                                    !ORIGINAL_SHARE_PRESENT
                                 ) {
                                    // if shares are present we need to calc values from realTimePrice --> "hence else condition will run"
                                    y.values = y.amount;
                                 } else {
                                    if (
                                       !y.values ||
                                       y.values === "NA" ||
                                       typeof y.values === "undefined"
                                    ) {
                                       // console.log(`=== values is not present in csv file for ticker ${y.ticker} ==> `, y.values);
                                       // console.log('===> calculating values... ');
                                       if (
                                          typeof y.price !== "undefined" &&
                                          y.price !== "NA" &&
                                          y.shares &&
                                          typeof y.shares !== "undefined" &&
                                          y.shares !== "NA"
                                       ) {
                                          y.values =
                                             y.shares &&
                                             typeof y.shares !== "undefined" &&
                                             y.shares !== "NA"
                                                ? y.price * y.shares
                                                : "NA";
                                          // console.log('=== calculated values ==>', y.values);
                                       } else {
                                          // console.log(`failed to calculate VALUES for ticker ${y.ticker}, missing shares/price =>`, y.shares, y.price);
                                          // y.values = portfolioValue*y.weight/100
                                          y.values = 0; // 'NA'
                                          // console.log(y.values);
                                       }
                                    } else {
                                       console.log(
                                          " VALUES already present",
                                          y.values
                                       );
                                    }
                                 }
                                 // console.log(' --- y.values --- ', y.values);

                                 if (
                                    y.realTimePrice &&
                                    y.realTimePrice !== "NA" &&
                                    typeof y.realTimePrice !== "undefined"
                                 ) {
                                    // SUM_OF_SHARES_API_WEIGHT += y.shares;
                                    SUM_OF_VALUES_FOR_API_WEIGHT += y.values;
                                 }

                                 if (
                                    y.values &&
                                    y.values !== "NA" &&
                                    typeof y.values !== "undefined"
                                 ) {
                                    sumOfValues += y.values; // amount/values are same in csv
                                 }
                              } else {
                                 console.log(
                                    `==  Failed !, Real-Time-Price/Price for ticker ${y.ticker} is missing ==>`,
                                    y.realTimePrice,
                                    y.price
                                 );
                                 // scenario where ticker is incorrect and realTimePrice is NA, price is NA, share is NA, but original_weight is present but in string format.

                                 // NOTE: for invalid ticker case, price is NULL or NA, so assinging 0 to it.
                                 y.price = 0;
                                 y.realTimePrice = 0;

                                 // Step 3(a): calculate Weight for invalid-tickers
                                 if (
                                    y.weight &&
                                    y.weight !== "NA" &&
                                    typeof y.weight !== "undefined"
                                 ) {
                                    y.weight = convertToFloatvalues(y.weight);
                                    y.original_weight = convertToFloatvalues(
                                       y.weight
                                    );
                                    y["weight(%)"] = y.weight;
                                 } else {
                                    if (
                                       y.original_weight &&
                                       typeof y.original_weight !==
                                          "undefined" &&
                                       y.original_weight !== "NA"
                                    ) {
                                       if (!_.isNumber(y.original_weight)) {
                                          y.original_weight =
                                             y.original_weight.replace(
                                                /[&\/\\#,+()$~%'":*?<>{}]/g,
                                                ""
                                             );
                                       }
                                       y.original_weight = Number(
                                          y.original_weight
                                       );
                                       // for invalid-ticker case 'weight' was 'undefined'
                                       y.weight = y.original_weight;
                                       y["weight(%)"] = y.original_weight || 0;
                                    } else {
                                       // when weight & original_weight is missing for invalid-ticker
                                       // Calculate Weight by "Amount" for invalid-tickers

                                       // console.log(`=== WEIGHT is missing in csv file for ticker ${y.ticker} ===> `, y.weight);
                                       // check if AMOUNT is present
                                       if (
                                          y.amount &&
                                          typeof y.amount !== "undefined" &&
                                          y.amount !== "NA" &&
                                          sumOfAmount
                                       ) {
                                          // console.log('=== calculating original_weight by using Amount ===>', y.amount);
                                          y.original_weight =
                                             (y.amount / sumOfAmount) * 100;
                                          y.original_weight =
                                             convertToFloatvalues(
                                                y.original_weight
                                             );
                                       } else {
                                          console.log(
                                             `== Failed to calculate original_weight for ticker ${y.ticker}, amount/sumOfAmount is wrong =>> `,
                                             y.amount,
                                             sumOfAmount
                                          );
                                          y.original_weight = 0; //'NA';
                                       }

                                       // for invalid-ticker case 'weight' was 'undefined'
                                       y.weight = y.original_weight;
                                       y["weight(%)"] = y.original_weight;
                                    }
                                 }

                                 // Steps 4: calculate SHARES for invalid-tickers
                                 if (
                                    y.shares &&
                                    typeof y.shares !== "undefined" &&
                                    y.shares !== "NA"
                                 ) {
                                    // console.log('=== SHARES already present ===>', y.shares);
                                    // y.shares = wholeNumberCheck(y.shares);
                                    y.shares = Number(y.shares);
                                    // ORIGINAL_SHARE_PRESENT = true; // check again for this line
                                 } else {
                                    if (
                                       y.original_weight &&
                                       typeof y.original_weight !==
                                          "undefined" &&
                                       y.original_weight !== "NA" &&
                                       y.realTimePrice &&
                                       y.realTimePrice !== "NA" &&
                                       typeof y.realTimePrice !== "undefined"
                                    ) {
                                       if (
                                          y.amount &&
                                          y.amount !== "NA" &&
                                          typeof y.amount !== "undefined"
                                       ) {
                                          y.shares =
                                             ((y.original_weight / 100) *
                                                sumOfAmount) /
                                             y.realTimePrice; // this wont execute, coz 'realTimePrice' is missing for invalid-ticker
                                       } else {
                                          y.shares =
                                             ((y.original_weight / 100) *
                                                portfolioValue) /
                                             y.realTimePrice; // this wont execute, coz 'realTimePrice' is missing for invalid-ticker
                                       }
                                    } else {
                                       // console.log(`===  Failed to calculate SHARES for ticker ${y.ticker}, original_weight/realTimePrice is missing ==>`, y.original_weight, y.realTimePrice);
                                       y.shares = "NA";
                                    }
                                 }

                                 // Steps 5(b): calculate VALUES for invalid-tickers
                                 if (
                                    y.amount &&
                                    y.amount !== "NA" &&
                                    typeof y.amount !== "undefined"
                                 ) {
                                    y.values = y.amount;
                                 } else {
                                    if (
                                       !y.values ||
                                       y.values === "NA" ||
                                       typeof y.values === "undefined" ||
                                       y.values === null
                                    ) {
                                       // console.log(`=== values is not present in csv file for ticker ${y.ticker} ==> `, y.values);
                                       // console.log('===> calculating values... ');
                                       if (
                                          typeof y.price !== "undefined" &&
                                          y.price !== "NA" &&
                                          y.price &&
                                          y.shares &&
                                          typeof y.shares !== "undefined" &&
                                          y.shares !== "NA"
                                       ) {
                                          y.values =
                                             y.shares &&
                                             typeof y.shares !== "undefined" &&
                                             y.shares !== "NA"
                                                ? y.price * y.shares
                                                : 0;
                                          // console.log('=== calculated values ==>', y.values);
                                       } else {
                                          console.log(
                                             `failed to calculate VALUES for ticker ${y.ticker}, missing shares/price =>`,
                                             y.shares,
                                             y.price,
                                             y.weight
                                          );
                                          y.values =
                                             (portfolioValue * y.weight) / 100;
                                          console.log(
                                             `y value --> `,
                                             y.values,
                                             portfolioValue,
                                             y.weight
                                          );
                                          // y.values = 0; // 'NA'
                                          // console.log(y.values);
                                       }
                                    } else {
                                       console.log(
                                          " VALUES already present",
                                          y.values
                                       );
                                    }
                                 }
                              }
                           }

                           // Date Validation
                           if (
                              typeof y.date !== "undefined" &&
                              y.date &&
                              isDateValid
                           ) {
                              let checkDate = moment(String(y.date)).isValid();
                              if (!checkDate) {
                                 checkDate = moment(
                                    String(y.date),
                                    "DD-MM-YYYY"
                                 ).isValid();
                              }
                              isDateValid = checkDate;
                           }
                        } else {
                           console.log("==== NO TICKER PRESENT ====");
                        }
                     });

                     if (!isDateValid) {
                        const obj = {
                           error: "Date format is invalid. Please provide dates in the correct format.",
                           headers,
                        };
                        return JSON.stringify(obj);
                     }

                     SUM_OF_VALUES_FOR_API_WEIGHT = JSON.parse(
                        JSON.stringify(SUM_OF_VALUES_FOR_API_WEIGHT)
                     );
                     // SUM_OF_SHARES_API_WEIGHT = JSON.parse(
                     //    JSON.stringify(SUM_OF_SHARES_API_WEIGHT)
                     // );

                     // console.log('SUM_OF_VALUES_FOR_API_WEIGHT-=====>', SUM_OF_VALUES_FOR_API_WEIGHT);
                     // console.log('SUM_OF_SHARES_API_WEIGHT ===>>', SUM_OF_SHARES_API_WEIGHT);

                     // CALCULATE 'weight' to be passed in API's request payload
                     result.forEach((y, k) => {
                        if (
                           y.ticker &&
                           y.ticker !== "ticker" &&
                           y.ticker !== "" &&
                           typeof y.ticker !== "undefined"
                        ) {
                           // Step 3(b): Calculate weight
                           // when only ticker, shares, price are present in csv (using DEMO PORTFOLIO case)
                           // console.log('y.original_weight ==>>', y.original_weight, sumOfValues, y.values);
                           if (
                              (y.original_weight === null ||
                                 y.original_weight === "NA" ||
                                 typeof y.original_weight === "undefined") &&
                              y.values !== "NA" &&
                              typeof y.values !== "undefined" &&
                              sumOfValues
                           ) {
                              y.original_weight =
                                 (y.values / sumOfValues) * 100;
                              y.original_weight =
                                 convertToFloatvalues(y.original_weight) ||
                                 y.original_weight;
                              y["weight(%)"] = y.original_weight;

                              y.weight = y.original_weight;
                              // console.log(y.original_weight);
                           } else if (
                              y.original_weight === 0 ||
                              y.original_weight === "NA" ||
                              typeof y.original_weight === "undefined"
                           ) {
                              // for "unrecognized ticker" weight field is missing
                              y.original_weight = 0;
                              y.weight = 0;
                              y["weight(%)"] = 0;
                           }

                           // commenting below logic, because it was re-calculating 'weight'.
                           if (
                              typeof y.realTimePrice !== "undefined" &&
                              y.realTimePrice !== "NA"
                           ) {
                              // commenting below logic for weight calc, coz it effects DEMO PORTFOLIO scenario
                              // console.log(y.realTimePrice, y.weight, y.values);
                              // if (y.shares && typeof y.shares !== 'undefined' && y.shares !== 'NA' && SUM_OF_SHARES_API_WEIGHT) {
                              //   y.weight = (y.shares/SUM_OF_SHARES_API_WEIGHT)*100;
                              //   y.weight = convertToFloatvalues(y.weight);
                              // } else
                              if (
                                 y.values &&
                                 typeof y.values !== "undefined" &&
                                 y.values !== "NA" &&
                                 SUM_OF_VALUES_FOR_API_WEIGHT
                              ) {
                                 y.weight =
                                    (y.values / SUM_OF_VALUES_FOR_API_WEIGHT) *
                                    100;
                                 y.weight = convertToFloatvalues(y.weight);
                              }
                           } else {
                              console.log(
                                 `realTimePrice is not present for ticker ${y.ticker} or weight is already present ==>>>> `,
                                 y.weight,
                                 y.values
                              );
                              if (
                                 typeof y.amount !== "undefined" &&
                                 y.amount !== null &&
                                 y.amount !== "NA"
                              ) {
                                 y.values = y.amount;
                              }
                              if (
                                 y.values === null ||
                                 (typeof y.values === "undefined" &&
                                    y.values === "NA")
                              ) {
                                 y.values = 0;
                              }
                              y.realTimePrice = y.price || 0;
                           }
                        } else {
                           console.log(`------ CSV Row => ${y.ticker} ------`);
                        }
                     });
                     // console.log(result);
                  }

                  if (result.length < 2) {
                     const obj = {
                        error: `Please upload a file with details in the columns - ${headers.toString()}`,
                        headers,
                     };
                     return JSON.stringify(obj);
                  }

                  return JSON.stringify(result);
               } else {
                  const obj = {
                     error: "Your file should have tickers in the first column and allocations in the second.",
                     // error: `Invalid csv format, ${headers.toString()}`,
                     headers,
                  };
                  return JSON.stringify(obj);
               }
            } else {
               const obj = {
                  error: "Your file should have tickers in the first column and allocations in the second.",
                  // error: `Invalid csv format, ${headers.toString()}`,
                  headers,
               };
               return JSON.stringify(obj);
            }
         } else {
            const obj = {
               error: "Filters parameter needs to have headersArray and maxHeadersCount",
            };
            return JSON.stringify(obj);
         }
      } else {
         // console.log(lines.splice(0, 1));
         lines.forEach((item, i) => {
            let obj = {};
            let currentline = lines[i].split(identified_delimeter);
            headers.forEach((y, k) => {
               obj[headers[k]] = currentline[k];
            });
            result.push(obj);
         });
         return JSON.stringify(result);
      }
   } else {
      console.log("not enough lines", lines);
      const obj = {
         error: "Not Enough Lines",
      };
      return JSON.stringify(obj);
   }
};

// when portfolio selected from dropdown
export const calculatePortfolioDataFormat = (
   requestedData,
   realTimeTickerPrice,
   portfolioValue
) => {
   // console.log('========== portfolioValue =============>', portfolioValue);
   try {
      if (requestedData) {
         // let sumOfPriceAndShares = 0,
         // let sumOfShares = 0,
         let sumOfAmount = 0,
            sumOfValues = 0,
            // sumOfWeight = 0,
            SUM_OF_VALUES_FOR_API_WEIGHT = 0;
         // SUM_OF_SHARES_API_WEIGHT = 0;
         const invalidPriceFormat = new RegExp(
            /[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/
         );
         let isDateValid = true;
         requestedData.map(kl => {
            if (kl.ticker !== "ticker") {
               if (kl.shares && kl.shares !== "undefined") {
                  kl.shares = convertToFloatvalues(kl.shares);
                  // sumOfShares += kl.shares;
               }
               if (kl.amount && kl.amount !== "undefined") {
                  kl.amount = convertToFloatvalues(kl.amount);
                  sumOfAmount += kl.amount;
               }
               if (kl.weight && kl.weight !== "undefined") {
                  kl.weight = convertToFloatvalues(kl.weight);
                  // sumOfWeight += kl.weight;
               }
            }
         });
         // sumOfShares = JSON.parse(JSON.stringify(sumOfShares));
         sumOfAmount = JSON.parse(JSON.stringify(sumOfAmount));
         // sumOfWeight = JSON.parse(JSON.stringify(sumOfWeight));
         sumOfValues = JSON.parse(JSON.stringify(sumOfValues));
         requestedData.map((y, k) => {
            // Convert ticker to lowercase
            // Step 1: Check Ticker
            if (y.ticker && convertToLowerCase(y.ticker) === "ticker") {
               y.ticker = convertToLowerCase(y.ticker);
            }
            if (
               y.ticker &&
               y.ticker !== "ticker" &&
               y.ticker !== "" &&
               typeof y.ticker !== "undefined"
            ) {
               y.ticker = convertToUpperCase(y.ticker);
               if (typeof y.weight !== "undefined" && y.weight !== "NA") {
                  y.weight_calculated_format = "weight";
                  y.format_value = y.weight;
               } else if (
                  typeof y.amount !== "undefined" &&
                  y.amount !== "NA"
               ) {
                  y.weight_calculated_format = "amount";
                  y.format_value = y.amount;
               } else if (
                  typeof y.shares !== "undefined" &&
                  y.shares !== "NA"
               ) {
                  y.weight_calculated_format = "shares";
                  y.format_value = y.shares;
               }
               // Step 2: Bind realtime-price
               // Add real-time price for each ticker
               if (realTimeTickerPrice && realTimeTickerPrice.funds) {
                  let tickerDetails = realTimeTickerPrice.funds.find(
                     a => a.ticker.toUpperCase() === y.ticker.toUpperCase()
                  );
                  if (tickerDetails && tickerDetails.price) {
                     if (invalidPriceFormat.test(tickerDetails.price)) {
                        // console.log(y.ticker, 'invalid price format ----', tickerDetails.price);
                        y.realTimePrice = tickerDetails.price.replace(
                           /[&\/\\#,+()$~%'":*?<>{}]/g,
                           ""
                        );
                        y.price = Number(y.realTimePrice); // ? y.price : y.realTimePrice
                     } else {
                        y.realTimePrice = Number(tickerDetails.price);
                        y.price = Number(y.realTimePrice); // ? y.price : y.realTimePrice
                     }
                  } else {
                     // console.log(`== missing realtime price for ticker ==> ${y.ticker} ` , tickerDetails, y.price);
                     // if (invalidPriceFormat.test(y.price)) {
                     //   console.log(y.ticker, 'invalid price format ----', y.price);
                     //   y.price = y.price.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '')
                     // }
                     y.price = 0; //Number(y.price) || 'NA';
                  }

                  if (
                     typeof y.price !== "undefined" &&
                     y.price &&
                     y.price !== "NA"
                  ) {
                     // Step 3: Calculate weight
                     // check for ticker weight is present in csv
                     if (y.weight && typeof y.weight !== "undefined") {
                        y.weight = convertToFloatvalues(y.weight);
                        y.original_weight = convertToFloatvalues(y.weight);
                        y["weight(%)"] = y.weight;
                        // y.weight_calculated_format = 'weight';
                        // y.format_value = y.weight;
                     } else {
                        // console.log(`=== WEIGHT is missing in csv file for ticker ${y.ticker} ===> `, y.weight);
                        // check if AMOUNT is present
                        if (
                           y.amount &&
                           typeof y.amount !== "undefined" &&
                           y.amount !== "NA" &&
                           sumOfAmount
                        ) {
                           // console.log('=== calculating original_weight by using Amount ===>', y.amount);
                           y.original_weight = (y.amount / sumOfAmount) * 100;
                           y.original_weight = convertToFloatvalues(
                              y.original_weight
                           );
                        }
                        // else if (y.shares && typeof y.shares !== 'undefined' && y.shares !== 'NA' && sumOfShares) {
                        //   console.log('=== calculating original_weight by using Shares ===>', y.shares);
                        //   y.original_weight = (y.shares/sumOfShares)*100;
                        //   y.original_weight = convertToFloatvalues(y.original_weight);
                        // }
                        else {
                           // console.log(`== Failed to calculate original_weight for ticker ${y.ticker}, amount/shares/sumOfAmount/sumOfShares is wrong =>> `, y.amount, y. shares, sumOfAmount, sumOfShares);
                           y.original_weight = "NA";
                        }
                        y["weight(%)"] = y.original_weight;
                        // console.log(JSON.parse(JSON.stringify(y)));
                     }

                     // Steps 4: calculate SHARES
                     if (
                        y.shares &&
                        typeof y.shares !== "undefined" &&
                        y.shares !== "NA"
                     ) {
                        // console.log('=== SHARES already present ===>', y.shares);
                        // if (typeof y.weight_calculated_format !== 'undefined' && y.weight_calculated_format === 'shares') {
                        //
                        // }
                        y.shares = Number(y.shares);
                     } else {
                        // console.log(' -- SHARE is missing in csv file --', y.shares);
                        // console.log('===> calculating shares... ');
                        if (
                           y.original_weight &&
                           typeof y.original_weight !== "undefined" &&
                           y.original_weight !== "NA" &&
                           y.realTimePrice &&
                           y.realTimePrice !== "NA" &&
                           typeof y.realTimePrice !== "undefined"
                        ) {
                           // y.shares = ((y.original_weight / 100) * portfolioValue) / y.realTimePrice;

                           // if weight use ->> portfolioValue
                           // if amount use ->> sumOfAmount

                           if (
                              y.amount &&
                              y.amount !== "NA" &&
                              typeof y.amount !== "undefined"
                           ) {
                              y.shares =
                                 ((y.original_weight / 100) * sumOfAmount) /
                                 y.realTimePrice;
                           } else {
                              y.shares =
                                 ((y.original_weight / 100) * portfolioValue) /
                                 y.realTimePrice;
                           }
                        } else {
                           // console.log(`===  Failed to calculate SHARES for ticker ${y.ticker}, original_weight/realTimePrice is missing ==>`, y.original_weight, y.realTimePrice);
                           y.shares = "NA";
                        }
                     }

                     // Steps 5: calculate VALUES/AMOUNT
                     if (
                        y.amount &&
                        y.amount !== "NA" &&
                        typeof y.amount !== "undefined"
                     ) {
                        // console.log('=== AMOUNT PRESENT IN CSV ===');
                        y.values = y.amount;
                     } else {
                        // console.log('=== CALCULATING VALUES ===');
                        if (
                           !y.values ||
                           y.values === "NA" ||
                           typeof y.values === "undefined"
                        ) {
                           // console.log(`=== values is not present in csv file for ticker ${y.ticker} ==> `, y.values);
                           // console.log('===> calculating values... ');
                           if (
                              typeof y.price !== "undefined" &&
                              y.price !== "NA" &&
                              y.price &&
                              y.shares &&
                              typeof y.shares !== "undefined" &&
                              y.shares !== "NA"
                           ) {
                              y.values =
                                 y.shares &&
                                 typeof y.shares !== "undefined" &&
                                 y.shares !== "NA"
                                    ? y.price * y.shares
                                    : "NA";
                           } else {
                              // console.log(`failed to calculate VALUES for ticker ${y.ticker}, missing shares/price =>`, y.shares, y.price);
                              y.values = 0; // 'NA'
                           }
                        } else {
                           // console.log(' VALUES already present', y.values);
                        }
                     }
                     // console.log(' --- y.values --- ', y.values);

                     if (
                        y.realTimePrice !== "NA" &&
                        typeof y.realTimePrice !== "undefined"
                     ) {
                        // SUM_OF_SHARES_API_WEIGHT += y.shares;
                        SUM_OF_VALUES_FOR_API_WEIGHT += y.values;
                     }

                     if (y.values !== "NA" && typeof y.values !== "undefined") {
                        sumOfValues += y.values; // amount/values are same in csv
                     }
                  } else {
                     // console.log(`==  Failed !, Real-Time-Price/Price for ticker ${y.ticker} is missing ==>`, y.realTimePrice, y.price);
                     // scenario where ticker is incorrect and realTimePrice is NA, price is NA, share is NA, but original_weight is present but in string format.

                     // Step 3(a): calculate Weight for invalid-tickers
                     if (y.weight && typeof y.weight !== "undefined") {
                        y.weight = convertToFloatvalues(y.weight);
                        y.original_weight = convertToFloatvalues(y.weight);
                        y["weight(%)"] = y.weight;
                        // y.weight_calculated_format = 'weight';
                        // y.format_value = y.weight;
                     } else {
                        if (
                           typeof y.original_weight !== "undefined" &&
                           y.original_weight !== "NA"
                        ) {
                           // console.log(y.ticker, y.original_weight);
                           if (!_.isNumber(y.original_weight)) {
                              y.original_weight = y.original_weight.replace(
                                 /[&\/\\#,+()$~%'":*?<>{}]/g,
                                 ""
                              );
                           }

                           y.original_weight = Number(y.original_weight);
                           // for invalid-ticker case 'weight' was 'undefined'
                           y.weight = y.original_weight;
                           y["weight(%)"] = y.original_weight || 0;
                        } else {
                           // when weight & original_weight is missing for invalid-ticker
                           // Calculate Weight by "Amount" for invalid-tickers

                           // console.log(`=== WEIGHT is missing in csv file for ticker ${y.ticker} ===> `, y.weight);
                           // check if AMOUNT is present
                           if (
                              y.amount &&
                              typeof y.amount !== "undefined" &&
                              y.amount !== "NA" &&
                              sumOfAmount
                           ) {
                              // console.log('=== calculating original_weight by using Amount ===>', y.amount);
                              y.original_weight =
                                 (y.amount / sumOfAmount) * 100;
                              y.original_weight = convertToFloatvalues(
                                 y.original_weight
                              );
                           } else {
                              // console.log(`== Failed to calculate original_weight for ticker ${y.ticker}, amount/sumOfAmount is wrong =>> `, y.amount, sumOfAmount);
                              y.original_weight = "NA";
                           }

                           // for invalid-ticker case 'weight' was 'undefined'
                           y.weight = y.original_weight;
                           y["weight(%)"] = y.original_weight;
                        }
                     }

                     // Steps 4: calculate SHARES for invalid-tickers
                     if (
                        y.shares &&
                        typeof y.shares !== "undefined" &&
                        y.shares !== "NA"
                     ) {
                        // console.log('=== SHARES already present ===>', y.shares);
                        // y.shares = wholeNumberCheck(y.shares);
                        y.shares = Number(y.shares);
                     } else {
                        if (
                           y.original_weight &&
                           typeof y.original_weight !== "undefined" &&
                           y.original_weight !== "NA" &&
                           y.realTimePrice &&
                           y.realTimePrice !== "NA" &&
                           typeof y.realTimePrice !== "undefined"
                        ) {
                           if (
                              y.amount &&
                              y.amount !== "NA" &&
                              typeof y.amount !== "undefined"
                           ) {
                              y.shares =
                                 ((y.original_weight / 100) * sumOfAmount) /
                                 y.realTimePrice; // this wont execute, coz 'realTimePrice' is missing for invalid-ticker
                           } else {
                              y.shares =
                                 ((y.original_weight / 100) * portfolioValue) /
                                 y.realTimePrice; // this wont execute, coz 'realTimePrice' is missing for invalid-ticker
                           }
                        } else {
                           // console.log(`===  Failed to calculate SHARES for ticker ${y.ticker}, original_weight/realTimePrice is missing ==>`, y.original_weight, y.realTimePrice);
                           y.shares = "NA";
                        }
                     }

                     // Steps 5(b): calculate VALUES for invalid-tickers
                     if (
                        y.amount &&
                        y.amount !== "NA" &&
                        typeof y.amount !== "undefined"
                     ) {
                        y.values = y.amount;
                     } else {
                        if (
                           !y.values ||
                           y.values === "NA" ||
                           typeof y.values === "undefined"
                        ) {
                           // console.log(`=== values is not present in csv file for ticker ${y.ticker} ==> `, y.values);
                           // console.log('===> calculating values... ');
                           if (
                              typeof y.price !== "undefined" &&
                              y.price !== "NA" &&
                              y.shares &&
                              typeof y.shares !== "undefined" &&
                              y.shares !== "NA"
                           ) {
                              y.values =
                                 y.shares &&
                                 typeof y.shares !== "undefined" &&
                                 y.shares !== "NA"
                                    ? y.price * y.shares
                                    : 0;
                              // console.log('=== calculated values ==>', y.values);
                           } else {
                              console.log(
                                 `failed to calculate VALUES for ticker ${y.ticker}, missing shares/price =>`,
                                 y.shares,
                                 y.price
                              );
                              y.values = (portfolioValue * y.weight) / 100;
                              // y.values = 0; // 'NA'
                              // console.log(y.values);
                           }
                        } else {
                           // console.log(' VALUES already present', y.values);
                        }
                     }

                     // console.log(y.ticker, y.original_weight, y['weight(%)'], y.values);
                  }
               }

               // Date Validation
               if (typeof y.date !== "undefined" && y.date && isDateValid) {
                  let checkDate = moment(String(y.date)).isValid();
                  if (!checkDate) {
                     checkDate = moment(String(y.date), "DD-MM-YYYY").isValid();
                  }
                  isDateValid = checkDate;
               }
            } else {
               // console.log('==== NO TICKER PRESENT ====');
            }
         });

         // if (!isDateValid) {
         //   const obj = {
         //     error: "Date format is invalid. Please provide dates in the correct format.",
         //     headers,
         //   };
         //   return JSON.stringify(obj);
         // }

         SUM_OF_VALUES_FOR_API_WEIGHT = JSON.parse(
            JSON.stringify(SUM_OF_VALUES_FOR_API_WEIGHT)
         );
         // SUM_OF_SHARES_API_WEIGHT = JSON.parse(
         //    JSON.stringify(SUM_OF_SHARES_API_WEIGHT)
         // );
         // console.log('SUM_OF_VALUES_FOR_API_WEIGHT-=====>', SUM_OF_VALUES_FOR_API_WEIGHT);
         // console.log('SUM_OF_SHARES_API_WEIGHT ===>>', SUM_OF_SHARES_API_WEIGHT);

         // console.log(JSON.parse(JSON.stringify(requestedData)));

         // CALCULATE 'weight' to be passed in API's request payload
         requestedData.forEach((y, k) => {
            if (
               y.ticker &&
               y.ticker !== "ticker" &&
               y.ticker !== "" &&
               typeof y.ticker !== "undefined"
            ) {
               // Step 3(b): Calculate weight
               // when only ticker, shares, price are present in csv (using DEMO PORTFOLIO case)
               // console.log('y.original_weight ==>>', y.original_weight, sumOfValues, y.values);
               if (
                  (y.original_weight === 0 ||
                     y.original_weight === "NA" ||
                     typeof y.original_weight === "undefined") &&
                  y.values !== "NA" &&
                  typeof y.values !== "undefined" &&
                  sumOfValues
               ) {
                  y.original_weight = (y.values / sumOfValues) * 100;
                  y.original_weight =
                     convertToFloatvalues(y.original_weight) ||
                     y.original_weight;
                  y["weight(%)"] = y.original_weight;
                  // console.log(y.original_weight);
               }
               if (
                  typeof y.realTimePrice !== "undefined" &&
                  y.realTimePrice !== "NA"
               ) {
                  // if (y.shares && typeof y.shares !== 'undefined' && y.shares !== 'NA' && SUM_OF_SHARES_API_WEIGHT) {
                  //   y.weight = (y.shares/SUM_OF_SHARES_API_WEIGHT)*100;
                  //   console.log(y.ticker, y.weight, y.original_weight);
                  //   y.weight = convertToFloatvalues(y.weight);
                  // } else
                  if (
                     y.values &&
                     typeof y.values !== "undefined" &&
                     y.values !== "NA" &&
                     SUM_OF_VALUES_FOR_API_WEIGHT
                  ) {
                     y.weight = (y.values / SUM_OF_VALUES_FOR_API_WEIGHT) * 100;
                     // console.log(y.ticker, y.weight, y.original_weight);
                     y.weight = convertToFloatvalues(y.weight);
                     // y.weight_calculated_format = 'amount';
                     // y.format_value = y.values;
                  }
               } else {
                  // console.log(`realTimePrice is not present for ticker ${y.ticker} or weight is already present ==>>>> ${y.weight}`);
               }
            } else {
               console.log(`------ CSV Row => ${y.ticker} ------`);
            }
         });

         // console.log(requestedData);
         return requestedData;
      } else {
         throw new Error("-- DATA not found --");
      }
   } catch (e) {
      console.log(e);
   }
};

// executed everytime before uploading in api
export const updateTickerData = (
   tickerData,
   portfolioValue,
   weightCalculatedFromCSVBy,
   OVER_RIDE_DATA
) => {
   let allTickers = tickerData.slice(0);
   // let sumOfPriceAndShares = 0,
   //   let sumOfShares = 0,
   let sumOfAmount = 0,
      sumOfValues = 0;
   // sumOfWeight = 0,
   // SUM_OF_VALUES_FOR_API_WEIGHT = 0,
   // SUM_OF_SHARES_API_WEIGHT = 0;
   const invalidPriceFormat = new RegExp(
      /[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/
   );
   let uniqueTickers = [],
      result = []; // OVER_RIDE_DATA = false; //  OVER_RIDE_DATA is for editing invalid ticker to valid ticker

   allTickers.map(obj => {
      if (!uniqueTickers.includes(obj.ticker)) {
         result.push(obj);
         uniqueTickers.push(obj.ticker);
      } else {
         // let copyObj = Object.assign({}, obj);
         result = result.map(data => {
            let item = Object.assign({}, data);
            if (item.ticker === obj.ticker) {
               if (item.shares && obj.shares) {
                  item.shares =
                     convertToFloatvalues(item.shares) +
                     convertToFloatvalues(obj.shares);
                  // copyObj.shares = item.shares;
               }
               if (item.amount && obj.amount) {
                  item.amount =
                     convertToFloatvalues(item.amount) +
                     convertToFloatvalues(obj.amount);
                  // copyObj.amount = item.amount;
               }
               if (item.original_weight && obj.original_weight) {
                  // item.weight = convertToFloatvalues(item.weight) + convertToFloatvalues(obj.weight);
                  item.weight =
                     convertToFloatvalues(item.original_weight) +
                     convertToFloatvalues(obj.original_weight);
                  item.original_weight = item.original_weight || item.weight;
                  // copyObj.weight = item.weight;
               }
               // if(item.realTimePrice){
               //   copyObj.realTimePrice = item.realTimePrice;
               // }
               return item;
            } else {
               return item;
            }
         });
         // result.push(copyObj);
      }
   });

   result.map(kl => {
      if (kl.ticker !== "ticker") {
         if (kl.shares !== "undefined") {
            kl.shares = convertToFloatvalues(kl.shares);
            // if (
            //    typeof kl.weight_calculated_format !== "undefined" &&
            //    kl.weight_calculated_format === "shares"
            // ) {
            //    sumOfShares += kl.format_value;
            // } else {
            //    sumOfShares += kl.shares;
            // }
         }
         if (kl.amount !== "undefined") {
            kl.amount = convertToFloatvalues(kl.amount);
            if (
               kl.values !== "NA" &&
               typeof kl.values !== "undefined" &&
               (kl.amount !== kl.values || !kl.amount)
            ) {
               kl.amount = kl.values;
            }
            if (
               typeof kl.weight_calculated_format !== "undefined" &&
               kl.weight_calculated_format === "amount"
            ) {
               sumOfAmount += kl.format_value;
            } else {
               sumOfAmount += kl.amount;
            }
         }
      }
   });

   // OVER_RIDE_DATA = (result.filter((op) => op.message === 'Valid Ticker' && op.showInMissingTicker && op.validTicker).length > 0)

   // sumOfShares = JSON.parse(JSON.stringify(sumOfShares));
   sumOfAmount = JSON.parse(JSON.stringify(sumOfAmount));
   sumOfValues = JSON.parse(JSON.stringify(sumOfValues));

   console.log("sumOfAmount --> ", sumOfAmount);

   // console.log('================== OVERRIDING DATA ==================', OVER_RIDE_DATA);

   result.map((y, k) => {
      // Convert ticker to lowercase
      // Step 1: Check Ticker
      if (y.ticker && convertToLowerCase(y.ticker) === "ticker") {
         y.ticker = convertToLowerCase(y.ticker);
      }

      if (
         y.ticker &&
         y.ticker !== "ticker" &&
         y.ticker !== "" &&
         typeof y.ticker !== "undefined"
      ) {
         y.ticker = convertToUpperCase(y.ticker);

         // Step 2: Check price
         if (invalidPriceFormat.test(y.price)) {
            y.price = y.price.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, "");
            y.price = Number(y.price);
         } else if (
            typeof y.realTimePrice !== "undefined" &&
            y.realTimePrice !== null &&
            y.realTimePrice !== "NA"
         ) {
            y.price = Number(y.realTimePrice) || Number(y.price); // ? y.price : y.realTimePrice
         }

         if (typeof y.price !== "undefined" && y.price && y.price !== "NA") {
            // Step 3(a): Calculate weight
            // check for ticker weight is present in csv
            if (
               y.weight &&
               y.weight !== "NA" &&
               typeof y.weight !== "undefined"
            ) {
               // console.log(convertToFloatvalues(y.weight));
               y.weight = convertToFloatvalues(y.weight);
               y.original_weight = convertToFloatvalues(y.weight);
               y["weight(%)"] = y.weight;
            } else {
               // console.log(`=== WEIGHT is missing in csv file for ticker ${y.ticker} ===> `, y.weight);
               // check if AMOUNT is present
               if (
                  y.amount &&
                  typeof y.amount !== "undefined" &&
                  y.amount !== "NA" &&
                  sumOfAmount
               ) {
                  // console.log('=== calculating original_weight by using Amount ===>', y.amount);
                  y.original_weight = (y.amount / sumOfAmount) * 100;
                  y.original_weight = convertToFloatvalues(y.original_weight);
               } else {
                  // console.log(`== Failed to calculate original_weight for ticker ${y.ticker}, amount/sumOfAmount is wrong =>> `, y.amount, sumOfAmount);
                  y.original_weight = "NA";
               }
               y["weight(%)"] = y.original_weight;
            }

            // Steps 4: calculate SHARES
            if (
               y.shares &&
               typeof y.shares !== "undefined" &&
               y.shares !== "NA"
            ) {
               //  && typeof y.weight_calculated_format !== 'undefined' && y.weight_calculated_format === 'shares'
               y.shares = Number(y.shares);
               // console.log('=== SHARES already present ===>', y.shares);
            } else {
               // console.log(' -- SHARE is missing in csv file --', y.shares, y.realTimePrice, y.original_weight);
               if (
                  y.original_weight &&
                  typeof y.original_weight !== "undefined" &&
                  y.original_weight !== "NA" &&
                  y.realTimePrice &&
                  y.realTimePrice !== "NA" &&
                  typeof y.realTimePrice !== "undefined"
               ) {
                  if (
                     weightCalculatedFromCSVBy === "weight" &&
                     weightCalculatedFromCSVBy !== null &&
                     typeof weightCalculatedFromCSVBy !== "undefined"
                  ) {
                     console.log(
                        "weightCalculatedFromCSVBy ->",
                        weightCalculatedFromCSVBy,
                        y.ticker,
                        "portfolioValue ==>",
                        portfolioValue
                     );
                     y.shares =
                        ((y.original_weight / 100) * portfolioValue) /
                        y.realTimePrice;
                  } else {
                     if (
                        y.amount &&
                        y.amount !== "NA" &&
                        typeof y.amount !== "undefined" &&
                        sumOfAmount
                     ) {
                        y.shares =
                           ((y.original_weight / 100) * sumOfAmount) /
                           y.realTimePrice;
                        // console.log(y.ticker, 'y.shares = ==>', y.shares);
                     } else {
                        console.log(
                           y.ticker,
                           "portfolioValue ==>",
                           portfolioValue
                        );
                        y.shares =
                           ((y.original_weight / 100) * portfolioValue) /
                           y.realTimePrice;
                        // console.log(y.ticker, 'y.shares = ==>', y.shares);
                     }
                  }
               } else {
                  // console.log(`===  Failed to calculate SHARES for ticker ${y.ticker}, original_weight/realTimePrice is missing ==>`, y.original_weight, y.realTimePrice);
                  y.shares = "NA";
               }
            }
            console.log("=== SHARES now ===>", y.shares);

            // Steps 5: calculate VALUES
            if (
               y.amount &&
               y.amount !== "NA" &&
               typeof y.amount !== "undefined"
            ) {
               // && typeof y.weight_calculated_format !== 'undefined' && y.weight_calculated_format === 'amount'
               y.values = y.amount;
               // console.log(' VALUES already present', y.values);
            } else {
               if (
                  !y.values ||
                  y.values === "NA" ||
                  typeof y.values === "undefined"
               ) {
                  if (
                     typeof y.price !== "undefined" &&
                     y.price !== "NA" &&
                     typeof y.shares !== "undefined" &&
                     y.shares !== "NA" &&
                     y.shares
                  ) {
                     y.values =
                        typeof y.shares !== "undefined" &&
                        y.shares !== "NA" &&
                        y.shares
                           ? y.price * y.shares
                           : 0;
                     // console.log('y.values ==>', y.values);
                  } else {
                     // console.log(`failed to calculate VALUES for ticker ${y.ticker}, missing shares/price =>`, y.shares, y.price);
                     y.values = 0; // 'NA'
                  }
               }
               if (
                  y.values &&
                  y.values !== "NA" &&
                  typeof y.values !== "undefined" &&
                  (y.amount !== y.values || !y.amount)
               ) {
                  // console.log(' VALUES already present', y.values);
                  y.amount = y.values;
               }
            }

            // if (
            //    y.realTimePrice !== "NA" &&
            //    typeof y.realTimePrice !== "undefined" &&
            //    y.realTimePrice
            // ) {
            // SUM_OF_SHARES_API_WEIGHT += y.shares;
            // SUM_OF_VALUES_FOR_API_WEIGHT += y.values;
            // }

            if (
               y.values &&
               y.values !== "NA" &&
               typeof y.values !== "undefined"
            ) {
               sumOfValues += y.values; // amount/values are same in csv
            }
         }
      } else {
         // console.log('==== NO TICKER PRESENT ====');
      }
   });

   // Weights computation
   // SUM_OF_VALUES_FOR_API_WEIGHT = JSON.parse(
   //    JSON.stringify(SUM_OF_VALUES_FOR_API_WEIGHT)
   // );
   // SUM_OF_SHARES_API_WEIGHT = JSON.parse(
   //    JSON.stringify(SUM_OF_SHARES_API_WEIGHT)
   // );

   // CALCULATE 'weight' to be passed in API's request payload
   result.forEach((y, k) => {
      if (
         y.ticker &&
         y.ticker !== "ticker" &&
         y.ticker !== "" &&
         typeof y.ticker !== "undefined"
      ) {
         // Step 3(b): Calculate weight
         // when only ticker, shares, price are present in csv (using DEMO PORTFOLIO case)
         // console.log('y.original_weight ==>>', y.original_weight, sumOfValues, y.values);
         // for editing invalid ticker to valid ticker case,
         // helpful for delete operation as well
         if (OVER_RIDE_DATA) {
            console.log("====== OVER_RID_WEIGHT ======> ", true);
            if (
               (y.values !== "NA" &&
                  typeof y.values !== "undefined" &&
                  sumOfValues) ||
               y.original_weight === "NA" ||
               y["weight(%)"] === "NA" ||
               y["weight"] === "NA"
            ) {
               y.original_weight = (y.values / sumOfValues) * 100;
               y.original_weight =
                  convertToFloatvalues(y.original_weight) || y.original_weight;

               y.weight = y.original_weight;
               y["weight(%)"] = y.original_weight;
               // console.log(y.original_weight);
            }

            // // Moved inside OVER_RIDE_DATA condition, because it was weight was re-calculating at the last
            // // realTimePrice
            // if (
            //   typeof y.realTimePrice !== 'undefined' &&
            //   y.realTimePrice !== 'NA'
            // ) {
            //   // console.log('when realTimePrice is present, original_weight ==>>>>', y.original_weight, y.values, y.shares, y.weight);
            //   // if (y.shares && typeof y.shares !== 'undefined' && y.shares !== 'NA' && SUM_OF_SHARES_API_WEIGHT) {
            //   //   y.weight = (y.shares/SUM_OF_SHARES_API_WEIGHT)*100;
            //   //   y.weight = convertToFloatvalues(y.weight);
            //   // } else
            //   if (y.values && typeof y.values !== 'undefined' && y.values !== 'NA' && SUM_OF_VALUES_FOR_API_WEIGHT) {
            //     // console.log(y.ticker, 'y.values =->', y.values, 'SUM_OF_VALUES_FOR_API_WEIGHT ==>', SUM_OF_VALUES_FOR_API_WEIGHT);
            //     y.weight = (y.values/SUM_OF_VALUES_FOR_API_WEIGHT)*100;
            //     y.weight = convertToFloatvalues(y.weight);
            //     // y.weight_calculated_format = 'amount';
            //     // y.format_value = y.values;
            //   }
            // } else {
            //   // console.log(`realTimePrice is not present for ticker ${y.ticker} or weight is already present ==>>>> `, y.weight);
            // }

            // Steps 4: calculate SHARES
            console.log(
               " -- SHARE is missing in csv file --",
               y.shares,
               y.realTimePrice,
               y.original_weight
            );
            // if (!y.shares || y.shares === 'NA' || typeof y.shares === 'undefined') {
            if (
               y.original_weight &&
               typeof y.original_weight !== "undefined" &&
               y.original_weight !== "NA" &&
               y.realTimePrice &&
               y.realTimePrice !== "NA" &&
               typeof y.realTimePrice !== "undefined"
            ) {
               console.log(y.ticker, "portfolioValue ==>", portfolioValue);
               y.shares =
                  ((y.original_weight / 100) * portfolioValue) /
                  y.realTimePrice;
               // console.log(y.ticker, 'y.shares = ==>', y.shares);
            }
            // }

            console.log("=== SHARES now ===>", y.shares);
         } else {
            // if ((y.original_weight === 0 || y.original_weight === 'NA' ||
            //   typeof y.original_weight === 'undefined') &&
            //   y.values !== 'NA' &&
            //   typeof y.values !== 'undefined' &&
            //   sumOfValues
            // ) {
            //   y.original_weight = (y.values/sumOfValues)*100;
            //   y.original_weight = convertToFloatvalues(y.original_weight) || y.original_weight;
            //   y['weight(%)'] = y.original_weight;
            //   console.log(y.original_weight,  y['weight(%)']);
            // }
         }

         // // realTimePrice
         // if (
         //   typeof y.realTimePrice !== 'undefined' &&
         //   y.realTimePrice !== 'NA'
         // ) {
         //   // console.log('when realTimePrice is present, original_weight ==>>>>', y.original_weight, y.values, y.shares, y.weight);
         //   // if (y.shares && typeof y.shares !== 'undefined' && y.shares !== 'NA' && SUM_OF_SHARES_API_WEIGHT) {
         //   //   y.weight = (y.shares/SUM_OF_SHARES_API_WEIGHT)*100;
         //   //   y.weight = convertToFloatvalues(y.weight);
         //   // } else
         //   if (y.values && typeof y.values !== 'undefined' && y.values !== 'NA' && SUM_OF_VALUES_FOR_API_WEIGHT) {
         //     // console.log(y.ticker, 'y.values =->', y.values, 'SUM_OF_VALUES_FOR_API_WEIGHT ==>', SUM_OF_VALUES_FOR_API_WEIGHT);
         //     y.weight = (y.values/SUM_OF_VALUES_FOR_API_WEIGHT)*100;
         //     y.weight = convertToFloatvalues(y.weight);
         //     // y.weight_calculated_format = 'amount';
         //     // y.format_value = y.values;
         //   }
         // } else {
         //   console.log(`realTimePrice is not present for ticker ${y.ticker} or weight is already present ==>>>> `, y.weight);
         // }
         // console.log(' --- y.weight --->', y.weight);
      } else {
         console.log(`------ CSV Row => ${y.ticker} ------`);
      }
   });

   // console.log(result);
   return result;
};
export const setWithExpiry = (key, value, ttl) => {
   const now = new Date();

   // `item` is an object which contains the original value
   // as well as the time when it's supposed to expire
   const item = {
      value: value,
      expiry: now.getTime() + ttl,
   };
   window.localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = key => {
   const itemStr = window.localStorage.getItem(key);
   // if the item doesn't exist, return null
   if (!itemStr) {
      return null;
   }
   const item = JSON.parse(itemStr);
   const now = new Date();
   // compare the expiry time of the item with the current time
   if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      window.localStorage.removeItem(key);
      return null;
   }
   return item;
};

export const FormatCurrencyHOC = num => {
   if (num === 0) {
      return `${num}`;
   } else {
      return FormatCurrency(num);
   }
};

export const renderMappedVehicle = vehicle => {
   const VEHICLE = {
      SHARE: "Stock",
      BASKET: "Managed Portfolio",
   };
   if (VEHICLE[vehicle]) {
      return VEHICLE[vehicle];
   }
   return vehicle;
};

export const chunkString = (str = "", len = 20) => {
   if (!str) return str;
   const size = Math.ceil(str.length / len);
   const r = Array(size);
   let offset = 0;

   for (let i = 0; i < size; i++) {
      r[i] = str.substr(offset, len);
      offset += len;
   }

   return r;
};

export const valuesToTQFormat = ({
   num,
   prefix = "$",
   limitDecimal = 2,
   returnType = "Number",
}) => {
   const suffix = ["K", "M", "B", "T", "Q"];
   let unit = Math.floor((num / 1.0e1).toFixed(0).toString().length);
   let r = unit % 3;
   let x = Math.abs(Number(num)) / Number("1.0e+" + (unit - r)).toFixed(2);
   let _suffix = suffix[Math.floor(unit / 3) - 2];
   _suffix = _suffix && typeof _suffix !== "undefined" ? _suffix : "";

   const label = `${prefix}${parseFloat(x.toFixed(limitDecimal))}${_suffix}`;

   if (returnType === "Object") {
      return {
         label,
         prefix,
         value: x.toFixed(limitDecimal),
         suffix: _suffix,
      };
   }
   // console.log('convertAmount label -> ', label);
   return label;
};

export const valuesToKmbFormat = ({
   num,
   prefix = "$",
   limitDecimal = 2,
   returnType = "Number",
}) => {
   // console.log('valuesToKmbFormat --> ', num);
   // const suffix = ['K', 'M', 'B', 'T', 'Q'];
   // const sign = Math.sign(Number(num));

   // const vlaue =  Math.abs(Number(num)) >= 1.0e+9
   //               ? (Math.abs(Number(num)) / 1.0e+9).toFixed(2) + "B"
   //               // Six Zeroes for Millions
   //               : Math.abs(Number(num)) >= 1.0e+6
   //               ? (Math.abs(Number(num)) / 1.0e+6).toFixed(2) + "M"
   //               // Three Zeroes for Thousands
   //               : Math.abs(Number(num)) >= 1.0e+3
   //               ? (Math.abs(Number(num)) / 1.0e+3).toFixed(2) + "K"
   //               : Math.abs(Number(num));

   // NOTE: For negative number figure
   const sign = Math.sign(Number(num));

   // // Nine Zeroes for Billions
   // const value = Math.abs(Number(num)) >= 1.0e9
   //   ? sign * (Math.abs(Number(num)) / 1.0e9) + "B"
   //   : // Six Zeroes for Millions
   //   Math.abs(Number(num)) >= 1.0e6
   //   ? sign * (Math.abs(Number(num)) / 1.0e6) + "M"
   //   : // Three Zeroes for Thousands
   //   Math.abs(Number(num)) >= 1.0e3
   //   ? sign * (Math.abs(Number(num)) / 1.0e3) + "K"
   //   : Math.abs(Number(num));

   let suffix = "";
   let label = "";
   let value = "";

   if (Math.abs(Number(num)) >= 1.0e9) {
      // value are in Billions
      // ---------------------------------------------------------
      suffix = "B";
      value =
         sign *
         Math.round(
            parseFloat((Math.abs(Number(num)) / 1.0e9).toFixed(limitDecimal))
         );
      label = `${prefix}${
         sign *
         Math.round(
            parseFloat((Math.abs(Number(num)) / 1.0e9).toFixed(limitDecimal))
         )
      }B`;
      // ---------------------------------------------------------
   } else if (Math.abs(Number(num)) >= 1.0e6) {
      // value are in Millions
      // ---------------------------------------------------------
      suffix = "M";
      value =
         sign *
         Math.round(
            parseFloat((Math.abs(Number(num)) / 1.0e6).toFixed(limitDecimal))
         );
      label = `${prefix}${
         sign *
         Math.round(
            parseFloat((Math.abs(Number(num)) / 1.0e6).toFixed(limitDecimal))
         )
      }M`;
      // ---------------------------------------------------------
   } else if (Math.abs(Number(num)) >= 1.0e3) {
      // value are in Thousands
      // ---------------------------------------------------------
      suffix = "K";
      value =
         sign *
         Math.round(
            parseFloat((Math.abs(Number(num)) / 1.0e3).toFixed(limitDecimal))
         );
      label = `${prefix}${
         sign *
         Math.round(
            parseFloat((Math.abs(Number(num)) / 1.0e3).toFixed(limitDecimal))
         )
      }K`;
      // ---------------------------------------------------------
   } else {
      // Can be Trillion values
      // ---------------------------------------------------------
      // const convertedData = valuesToTQFormat({ num, prefix, limitDecimal, returnType });
      // if (convertedData && Object.keys(convertedData)) {
      //   suffix = convertedData.suffix; // || '';
      //   value = convertedData.value; // || sign * (Math.abs(Number(num))).toFixed(limitDecimal);
      //   label = convertedData.label; // `${prefix}${sign * (Math.abs(Number(num))).toFixed(limitDecimal)}`;
      // } else {
      //   label = convertedData;
      // }

      suffix = "";
      value =
         sign *
         Math.round(parseFloat(Math.abs(Number(num)).toFixed(limitDecimal)));
      label = `${prefix}${
         sign *
         Math.round(parseFloat(Math.abs(Number(num)).toFixed(limitDecimal)))
      }`;

      // ---------------------------------------------------------
   }

   // console.log({
   //   label,
   //   prefix,
   //   suffix,
   //   value,
   // });

   if (returnType === "Object") {
      return {
         label,
         prefix,
         suffix,
         value,
      };
   }

   return label;
};

export const validateUrl = value => {
   return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value
   );
};

export const sponsorPdfUrl = (url, defaultUrl = "/assets/data") => {
   if (url) {
      if (validateUrl(url)) {
         // let _url = new URL(url);
         // return _url.pathname.split('/').pop().indexOf('.') > 0;
         return url;
      } else {
         return defaultUrl + url;
         // let domainPath = 'https://magnifi.com/assets/data'+url;
         // if (fileExists(domainPath)) {
         //   return domainPath;
         // } else {
         //   return false
         // }
      }
   }
};

export const checkHTMLStringNotBlank = htmlString => {
   const _regex = />[-\w .()!@&$#]+</;
   return _regex.test(htmlString);
};

export const getHostName = () => {
   return window.location.hostname
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "")
      .split(/[/?#]/)[0];
};

// NOTE: For disclosure EMPTY string check
export const haveHTMLData = htmlString => {
   const aux = document.createElement("div");
   aux.innerHTML = htmlString; //parses the html
   const trimmedContent = aux.innerText.trim();
   return trimmedContent !== "";
};

export const setCookie = (name, value) => {
   console.log("setCookie ->", name, value);
   // document.cookie = `${name}=${value}; domain=${
   //   process.env.REACT_APP_ENV === 'prod'
   //     ? '.app.magnifi.com; path=/;'
   //     : `.${getHostName()}; path=/;`
   // }`;
   document.cookie = `${name}=${value}; domain=.magnifi.com;path=/;`;
};

export const deleteCookie = name => {
   // document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${
   //   process.env.REACT_APP_ENV === 'prod'
   //     ? '.app.magnifi.com; path=/;'
   //     : `.${getHostName()}; path=/;`
   // }`;
   document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.magnifi.com;path=/;`;
};

export const hasMinimumCharactersRegex = /^.{8,}$/;
export const hasUpperCaseRegex = /(?=.*[A-Z])/;
export const hasLowerCaseRegex = /(?=.*[a-z])/;
export const hasDigitRegex = /(?=.*[0-9])/;
export const hasSpecialCharactersRegex = /(?=.*[!@#\$%\^&\*?,.()_|<>|])/;

export const UTM_PARAMS = [
   "utm_campaign",
   "utm_medium",
   "utm_source",
   "utm_term",
   "utm_content",
];
export const readUTMParamsFromURl = (url, utmParams = UTM_PARAMS) => {
   let utm_params_obj = {};
   if (!url) return utm_params_obj;
   const params = Object.fromEntries(new URLSearchParams(url));
   Object.keys(params).forEach(entry => {
      if (utmParams.includes(entry)) {
         utm_params_obj[`${entry}`] = params[entry];
      }
   });
   return utm_params_obj;
};

export const isDev = ["dev", "international_dev"].includes(
   process.env.REACT_APP_ENV
);

export const getSearchResultForAnalytics = (results = []) => {
   const searchResults = [];

   results
      .filter((_, index) => index < 10)
      .forEach(({ isin = "", name = "", ticker = "" }) =>
         searchResults.push({ isin, name, ticker })
      );

   return searchResults;
};

export const indexPageMapping = {
   "ex-sum": {
      name: "Executive Summary",
      showPageNum: true,
   },
   "rel-funds": {
      name: "Relevant Funds",
      showPageNum: true,
   },
   "sel-criteria": {
      name: "Selection Criteria",
      showPageNum: true,
   },
   "perf-analytics-tradeoff": {
      name: "Performance Analytics : Return-Risk Trade-Off",
      showPageNum: true,
   },
   "perf-analytics-up-down-capture": {
      name: "Performance Analytics : Upside-Downside Capture",
      showPageNum: true,
   },
   "perf-analytics-cor-matrix": {
      name: "Performance Analytics : Correlation Matrix",
      showPageNum: true,
   },
   "perf-analytics-factor-exposure": {
      name: "Performance Analytics : Factor Exposures",
      showPageNum: true,
   },
   "high-rel-funds": {
      name: "Highest Scoring Relevant Investment Options",
      showPageNum: true,
   },
   conclusion: {
      name: "Conclusion",
      showPageNum: true,
   },
   appendix: {
      name: "Appendix",
      showPageNum: false,
   },
   "other-rel-funds": {
      name: "a. Other Relevant Funds",
      showPageNum: true,
   },
   "fund-fact-sheet": {
      name: "Fund Fact Sheets",
      showPageNum: true,
   },
};

export const removeURLParameter = (url, parameter) => {
   var urlparts = url.split("?");
   if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + "=";
      var pars = urlparts[1].split(/[&;]/g);

      for (var i = pars.length; i-- > 0; ) {
         if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            pars.splice(i, 1);
         }
      }

      return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
   }
   return url;
};

export const enableTrackingEvents = () => {
   const trackingPreference = JSON.parse(
      decodeURIComponent(getCookie("tracking-preferences")) || "{}"
   );
   const isEnabled = _.isEmpty(trackingPreference)
      ? true
      : trackingPreference?.custom?.marketingAndAnalytics;
   return isEnabled;
};
