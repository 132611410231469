import React from "react";
import { FormFeedback } from "reactstrap";

export default function CustomFormFeedback(props) {
   const { errors, touched } = props.formProps;
   const { fieldName } = props;
   if (errors[fieldName] && touched[fieldName]) {
      return <FormFeedback>{errors[fieldName]}</FormFeedback>;
   }
   return null;
}
