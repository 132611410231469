import zipObject from "lodash/zipObject";

const actions = [
   "REMOVE_QUICK_TRADE_TICKERS_REQUEST",
   "REMOVE_QUICK_TRADE_TICKERS_FETCH",
   "REMOVE_QUICK_TRADE_TICKERS_SUCCESS",

   "DELETE_ALL_QUICK_TRADE_TICKERS_REQUEST",
   "DELETE_ALL_QUICK_TRADE_TICKERS_FETCH",
   "DELETE_ALL_QUICK_TRADE_TICKERS_SUCCESS",

   "ADD_TICKERS_TO_QUICK_TRADE_REQUEST",
   "ADD_TICKERS_TO_QUICK_TRADE_FETCH",
   "ADD_TICKERS_TO_QUICK_TRADE_SUCCESS",

   "QUICK_TRADE_MODAL_TOGGLE",

   "QUICK_TRADE_TO_SFM_REQUEST",
   "QUICK_TRADE_TO_SFM_FETCH",
   "QUICK_TRADE_TO_SFM_SUCCESS",

   "GET_BROKER_DETAILS_REQUEST",
   "GET_BROKER_DETAILS_FETCH",
   "GET_BROKER_DETAILS_SUCCESS",

   "EXECUTE_QUICK_TRADE_ORDERS_REQUEST",
   "EXECUTE_QUICK_TRADE_ORDERS_FETCH",
   "EXECUTE_QUICK_TRADE_ORDERS_SUCCESS",
];

export default zipObject(actions, actions);
