import { takeEvery, fork, put, all, select } from "redux-saga/effects";

import ActionTypes from "../action-types";
import { setSelectorReportIndexData } from "actions/reports";
import { indexPageMapping } from "Utils";

function* updateIndexData(data) {
   const { payload: value } = data;
   try {
      const currentIndexData = yield select(
         state => state.reports.selectorReportIndexData
      );
      let updatedIndexData = JSON.parse(JSON.stringify(currentIndexData));
      if (value) {
         const items = value.name && value.name.split(",");
         if (items) {
            items.forEach(pgName => {
               if (pgName) {
                  const hasOtherRelFunds = updatedIndexData.find(
                     item => item.name === "a. Other Relevant Funds"
                  );
                  const pageMap = indexPageMapping[pgName];
                  const pageName =
                     (pageMap.name === "Fund Fact Sheets"
                        ? hasOtherRelFunds
                           ? "b. "
                           : "a. "
                        : "") + pageMap.name;
                  const pageNumber = pageMap.showPageNum
                     ? value.pageNumber
                     : "";
                  let doesPageExists = updatedIndexData.find(
                     item => item.name === pageName
                  );
                  // Append page number to the array if the page already exists
                  // Or create a new page object if it's a new page
                  if (doesPageExists) {
                     updatedIndexData = updatedIndexData.map(item => {
                        if (item.name === pageName && pageNumber) {
                           item.pageNumber = pageNumber
                              ? [...item.pageNumber, pageNumber]
                              : "";
                        }
                        return item;
                     });
                  } else {
                     const newPage = {
                        name: pageName,
                        pageNumber: pageNumber ? [pageNumber] : "",
                     };
                     updatedIndexData.push(newPage);
                  }
               }
            });
         }
         yield put(setSelectorReportIndexData(updatedIndexData));
      }
   } catch (error) {
      console.log("error ----->", error);
   }
}

function* reportSaga() {
   yield all([
      fork(
         takeEvery,
         ActionTypes.GET_SELECTOR_REPORT_INDEX_DATA,
         updateIndexData
      ),
   ]);
}

export default reportSaga;
