export const color = {
   black: "#000000",
   white: "#ffffff",
   oxford_blue: "#344054",
   porcelain: "#FCFDFD",
   alto: "#e0e0e0",
   mercury: "#E4E4E4",
   gray: "#dedede",
   picton_blue: "#56a9e8",
   silver_chalice: "#9E9E9E",
   danube: "#5488c9",
   abbey: "#565e60",
   concrete: "#f2f2f2",
   lightGray: "#828282",
   athens_gray: "#f5f7f9",
   alabaster: "#f7f7f7",
   chathams_blue: "#145b8a",
   tundora: "#474747",
   cornflower_blue: " #1e5f91",
   boulder: "#797979",
   gainsboro: "#DCDCDC",
   steam: "#DDDDDD",
   ash: "#4E4D4D",
   alice_blue: "#EAF4FE",
   yankees_blue: "#1D2939",
   outer_space: "#4A494A",
   dark_liver: "#4F4F4F",
   bright_gray: "#ECE9E4",
   ebony: "#101828",
   red: "#ED4C5C",
   boston_blue: "#3A80B7",
   gull_gray: "#98A2B3",
};

export const size = {
   heading_level_1: "48px",
   heading_level_2: "36px",
   heading_level_3: "28px",
   heading_level_4: "24px",
   heading_level_5: "20px",
   heading_level_6: "18px",
   subheading_level_1: "16px",
   subheading_level_2: "14px",
   subheading_level_3: "12px",
   subheading_level_4: "15px",
   subheading_level_5: "10px",
};
