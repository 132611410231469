export const themeMapping = [
   {
      name: "adult_entertainment",
      miovalue: "",
   },
   {
      name: "agriculture",
      miovalue: "Agricultural Commodities",
   },
   {
      name: "artificial_intelligence",
      miovalue: "Artificial Intelligence",
   },
   {
      name: "baby_boomers",
      miovalue: "Baby boomers",
   },
   {
      name: "big_data",
      miovalue: "Big Data",
   },
   {
      name: "bitcoin",
      miovalue: "Bitcoin",
   },
   {
      name: "bitcoin_cash",
      miovalue: "",
   },
   {
      name: "blockchain",
      miovalue: "Blockchain",
   },
   {
      name: "buybacks",
      miovalue: "Buybacks",
   },
   {
      name: "cancer",
      miovalue: "",
   },
   {
      name: "cars",
      miovalue: "Cars",
   },
   {
      name: "catholic",
      miovalue: "",
   },
   {
      name: "children",
      miovalue: "",
   },
   {
      name: "clean_energy",
      miovalue: "Clean energy",
   },
   {
      name: "cleantech",
      miovalue: "Cleantech",
   },
   {
      name: "climate_change",
      miovalue: "Climate change",
   },
   {
      name: "cloud_computing",
      miovalue: "Cloud computing",
   },
   {
      name: "coal",
      miovalue: "Coal",
   },
   {
      name: "coins",
      miovalue: "Coins",
   },
   {
      name: "computer_vision",
      miovalue: "Computer Vision",
   },
   {
      name: "cybersecurity",
      miovalue: "Cybersecurity",
   },
   {
      name: "demographics",
      miovalue: "Demographics",
   },
   {
      name: "development",
      miovalue: "Development goals",
   },
   {
      name: "digital_health",
      miovalue: "",
   },
   {
      name: "drip_irrigation",
      miovalue: "",
   },
   {
      name: "driverless_cars",
      miovalue: "Driverless Cars",
   },
   {
      name: "drone",
      miovalue: "Drone",
   },
   {
      name: "ecommerce",
      miovalue: "Ecommerce",
   },
   {
      name: "electric",
      miovalue: "Electric",
   },
   {
      name: "employee_treatment",
      miovalue: "Employee treatment",
   },
   {
      name: "entertainment",
      miovalue: "Entertainment",
   },
   {
      name: "entrepreneurship",
      miovalue: "Entrepreneurship",
   },
   {
      name: "environmental",
      miovalue: "Environmental",
   },
   {
      name: "esg",
      miovalue: "ESG",
   },
   {
      name: "ethereum",
      miovalue: "Ethereum",
   },
   {
      name: "fang",
      miovalue: "",
   },
   {
      name: "fintech",
      miovalue: "Fintech",
   },
   {
      name: "food",
      miovalue: "Food",
   },
   {
      name: "forestry",
      miovalue: "",
   },
   {
      name: "fossil_free",
      miovalue: "Fossil free",
   },
   {
      name: "gafa",
      miovalue: "",
   },
   {
      name: "gaming",
      miovalue: "Gaming",
   },
   {
      name: "gen_x",
      miovalue: "Gen X",
   },
   {
      name: "gender",
      miovalue: "",
   },
   {
      name: "geothermal",
      miovalue: "Geothermal",
   },
   {
      name: "global_goals",
      miovalue: "",
   },
   {
      name: "governance",
      miovalue: "Governance",
   },
   {
      name: "health_tech",
      miovalue: "Health Tech",
   },
   {
      name: "healthcare",
      miovalue: "Health Care",
   },
   {
      name: "health_care",
      miovalue: "Health Care",
   },
   {
      name: "home_ownership",
      miovalue: "Home ownership",
   },
   {
      name: "human_rights",
      miovalue: "Human rights",
   },
   {
      name: "hydro",
      miovalue: "",
   },
   {
      name: "immunotherapy",
      miovalue: "Immunotherapy",
   },
   {
      name: "infrastructure",
      miovalue: "Infrastructure",
   },
   {
      name: "innovation",
      miovalue: "Innovation",
   },
   {
      name: "insurtech",
      miovalue: "Insurtech",
   },
   {
      name: "iot",
      miovalue: "IOT",
   },
   {
      name: "ipos",
      miovalue: "IPOs",
   },
   {
      name: "leisure",
      miovalue: "Leisure",
   },
   {
      name: "lending_platforms",
      miovalue: "Lending platforms",
   },
   {
      name: "lgbt",
      miovalue: "LGBT",
   },
   {
      name: "logistics",
      miovalue: "",
   },
   {
      name: "luxury",
      miovalue: "",
   },
   {
      name: "manufacturing",
      miovalue: "Manufacturing",
   },
   {
      name: "marijuana",
      miovalue: "Marijuana",
   },
   {
      name: "medical_devices",
      miovalue: "Medical Devices",
   },
   {
      name: "medical_technology",
      miovalue: "",
   },
   {
      name: "military",
      miovalue: "Military",
   },
   {
      name: "military_equipment",
      miovalue: "",
   },
   {
      name: "military_spending",
      miovalue: "Military spending",
   },
   {
      name: "millenials",
      miovalue: "millennials",
   },
   {
      name: "mri",
      miovalue: "MRI",
   },
   {
      name: "music",
      miovalue: "Music",
   },
   {
      name: "nanotech",
      miovalue: "",
   },
   {
      name: "natural_language_processing",
      miovalue: "Natural Language Processing",
   },
   {
      name: "nuclear",
      miovalue: "Nuclear",
   },
   {
      name: "organic",
      miovalue: "Organic",
   },
   {
      name: "outsourcing",
      miovalue: "Outsourcing",
   },
   {
      name: "payments",
      miovalue: "Payments",
   },
   {
      name: "pets",
      miovalue: "Pets",
   },
   {
      name: "philantrophy",
      miovalue: "Philantrophy",
   },
   {
      name: "precision_agriculture",
      miovalue: "",
   },
   {
      name: "precision_medicine",
      miovalue: "Precision Medicine",
   },
   {
      name: "qsr",
      miovalue: "Qsr",
   },
   {
      name: "quantum_computing",
      miovalue: "",
   },
   {
      name: "renewable_energy",
      miovalue: "Renewable energy",
   },
   {
      name: "ripple",
      miovalue: "Ripple",
   },
   {
      name: "roboadvisors",
      miovalue: "Roboadvisors",
   },
   {
      name: "robotics",
      miovalue: "Robotics",
   },
   {
      name: "saas",
      miovalue: "",
   },
   {
      name: "seniors",
      miovalue: "Seniors",
   },
   {
      name: "sensors",
      miovalue: "Sensors",
   },
   {
      name: "shale",
      miovalue: "Shale",
   },
   {
      name: "smartphone",
      miovalue: "",
   },
   {
      name: "social",
      miovalue: "",
   },
   {
      name: "social_media",
      miovalue: "Social Media",
   },
   {
      name: "solar_energy",
      miovalue: "Solar Energy",
   },
   {
      name: "spinoffs",
      miovalue: "Spinoffs",
   },
   {
      name: "sports",
      miovalue: "Sports",
   },
   {
      name: "stemcell",
      miovalue: "Stemcell",
   },
   {
      name: "streaming",
      miovalue: "Streaming",
   },
   {
      name: "tech",
      miovalue: "Technology",
   },
   {
      name: "telecom",
      miovalue: "Telecommunication Services",
   },
   {
      name: "theme",
      miovalue: "",
   },
   {
      name: "threed_printing",
      miovalue: "3D printing",
   },
   {
      name: "toys",
      miovalue: "",
   },
   {
      name: "travel",
      miovalue: "Travel",
   },
   {
      name: "vegan",
      miovalue: "",
   },
   {
      name: "vice",
      miovalue: "Vice",
   },
   {
      name: "virtual_reality",
      miovalue: "Virtual Reality",
   },
   {
      name: "waste_management",
      miovalue: "",
   },
   {
      name: "water",
      miovalue: "Water",
   },
   {
      name: "wealthtech",
      miovalue: "Wealth Management",
   },
   {
      name: "wearable_tech",
      miovalue: "Wearable Tech",
   },
   {
      name: "wellness",
      miovalue: "Wellness",
   },
   {
      name: "wind_energy",
      miovalue: "Wind Energy",
   },
   {
      name: "women",
      miovalue: "Women",
   },
   {
      name: "energy",
      miovalue: "Energy",
   },
   {
      name: "emerging_markets",
      miovalue: "Emerging Markets",
   },
   {
      name: "china",
      miovalue: "China",
   },
   {
      name: "volatility",
      miovalue: "Volatility",
   },
   {
      name: "style",
      miovalue: "Multifactor",
   },
   {
      name: "gold",
      miovalue: "Gold",
   },
   {
      name: "gold_sector",
      miovalue: "Gold",
   },
   {
      name: "income",
      miovalue: "Income",
   },
   {
      name: "yield",
      miovalue: "Yield",
   },
   {
      name: "high_yield",
      miovalue: "Yield",
   },
   {
      name: "alternatives",
      miovalue: "Alternatives",
   },
   {
      name: "muni",
      miovalue: "Municipal Bonds",
   },
   {
      name: "muni_tax_exempt",
      miovalue: "Municipal Bonds",
   },
   {
      name: "muni_taxable",
      miovalue: "Municipal Bonds",
   },
];

export const themeCode = [
   {
      name: "Wealth Management",
      id_hash: "XDmNpv7l",
   },
   {
      name: "Mutual Funds",
      id_hash: "Vzkppdkp",
   },
   {
      name: "Bonds",
      id_hash: "GaBdRGkV",
   },
   {
      name: "Recession",
      id_hash: "Q6mZDE7X",
   },
   {
      name: "Pension Funds",
      id_hash: "15BW9AkA",
   },
   {
      name: "Innovation",
      id_hash: "1NBzWRmg",
   },
   {
      name: "Food",
      id_hash: "8jmYnakq",
   },
   {
      name: "Commodities",
      id_hash: "gb7n8ym0",
   },
   {
      name: "Student loans",
      id_hash: "zxBEayBe",
   },
   {
      name: "Infrastructure",
      id_hash: "dakPpgkK",
   },
   {
      name: "Tourism",
      id_hash: "Q6mZOQkX",
   },
   {
      name: "Accounting",
      id_hash: "2WBXKL7V",
   },
   {
      name: "Finance",
      id_hash: "dakPZ2BK",
   },
   {
      name: "Human Resources",
      id_hash: "nJ7LazBN",
   },
   {
      name: "Manufacturing",
      id_hash: "3Mk285BD",
   },
   {
      name: "Education",
      id_hash: "a1meww79",
   },
   {
      name: "Natural Resources",
      id_hash: "q0BJVZBL",
   },
   {
      name: "ESG",
      id_hash: "zv7VaNkX",
   },
   {
      name: "Global trade",
      id_hash: "EWBRNAB4",
   },
   {
      name: "Financial Planning",
      id_hash: "Op74A1BG",
   },
   {
      name: "Insurance",
      id_hash: "Vzkp60Bp",
   },
   {
      name: "Social Security",
      id_hash: "1NBzx6kg",
   },
   {
      name: "Sports",
      id_hash: "wYk3Dr74",
   },
   {
      name: "Taxes",
      id_hash: "8jmY19mq",
   },
   {
      name: "Capital structure",
      id_hash: "K571pbkD",
   },
   {
      name: "Economy",
      id_hash: "Yd7xjamX",
   },
   {
      name: "Federal debt",
      id_hash: "Y07OrPkP",
   },
   {
      name: "Non Profits",
      id_hash: "ZABQpQ71",
   },
   {
      name: "Tax policies",
      id_hash: "ew75Mq7M",
   },
   {
      name: "Alternatives",
      id_hash: "zxBER0me",
   },
   {
      name: "Outsourcing",
      id_hash: "zVmy1gmw",
   },
   {
      name: "Blockchain",
      id_hash: "aDkqpGm0",
   },
   {
      name: "Buybacks",
      id_hash: "Mbk06lB1",
   },
   {
      name: "Cars",
      id_hash: "oZB91ZBW",
   },
   {
      name: "Coal",
      id_hash: "2WBXjKkV",
   },
   {
      name: "Collectibles",
      id_hash: "bA7vMykR",
   },
   {
      name: "Cybersecurity",
      id_hash: "dakP66BK",
   },
   {
      name: "Demographics",
      id_hash: "nJ7LWvkN",
   },
   {
      name: "Ecommerce",
      id_hash: "nWB8j9kr",
   },
   {
      name: "Entrepreneurship",
      id_hash: "GAkMlDmb",
   },
   {
      name: "Gaming",
      id_hash: "XDmN4M7l",
   },
   {
      name: "Home ownership",
      id_hash: "3Mk2KN7D",
   },
   {
      name: "Hospitality",
      id_hash: "zv7V3NBX",
   },
   {
      name: "IPOs",
      id_hash: "EWBRKAm4",
   },
   {
      name: "Leisure",
      id_hash: "w2BawPkX",
   },
   {
      name: "Luxury",
      id_hash: "a1meRDk9",
   },
   {
      name: "Marijuana",
      id_hash: "zXBoro7w",
   },
   {
      name: "Penny Stocks",
      id_hash: "Qb7g1lmr",
   },
   {
      name: "Pets",
      id_hash: "15BWJN7A",
   },
   {
      name: "Philantrophy",
      id_hash: "zGklQV7V",
   },
   {
      name: "Shale",
      id_hash: "Op74v1mG",
   },
   {
      name: "Social Media",
      id_hash: "q5kr2ake",
   },
   {
      name: "Streaming",
      id_hash: "Dy76vb7p",
   },
   {
      name: "Structured Products",
      id_hash: "Vzkpw0mp",
   },
   {
      name: "Trading",
      id_hash: "gXkKvamZ",
   },
   {
      name: "Travel",
      id_hash: "NwBDvyk2",
   },
   {
      name: "Wellness",
      id_hash: "w9kAvz76",
   },
   {
      name: "Technology",
      id_hash: "1NBzg6mg",
   },
   {
      name: "Music",
      id_hash: "Mr7jn27R",
   },
   {
      name: "Military",
      id_hash: "K571vbBD",
   },
   {
      name: "Government Bonds",
      id_hash: "Mbk0vlB1",
   },
   {
      name: "Mortgage Bonds",
      id_hash: "oZB9vZmW",
   },
   {
      name: "Corporate Bonds",
      id_hash: "2WBXZKkV",
   },
   {
      name: "Treasury Bonds",
      id_hash: "bA7veykR",
   },
   {
      name: "Municipality Bonds",
      id_hash: "dakPN6BK",
   },
   {
      name: "Bank debt",
      id_hash: "edmwoPmj",
   },
   {
      name: "Japanese Yen",
      id_hash: "zv7VoNBX",
   },
   {
      name: "Australian Dollar",
      id_hash: "EWBRVAB4",
   },
   {
      name: "Pound Sterling",
      id_hash: "w2BaDP7X",
   },
   {
      name: "Canadian Dollar",
      id_hash: "a1meYDm9",
   },
   {
      name: "Swedish Krona",
      id_hash: "zXBo5okw",
   },
   {
      name: "Swiss Franc",
      id_hash: "q0BJ9M7L",
   },
   {
      name: "Yuan Renminbi",
      id_hash: "Q6mZvMmX",
   },
   {
      name: "Indian Rupee",
      id_hash: "Qb7gvlkr",
   },
   {
      name: "Brazilian Real",
      id_hash: "15BWvN7A",
   },
   {
      name: "Singapore Dollar",
      id_hash: "edmwwdmj",
   },
   {
      name: "Euro",
      id_hash: "zv7VvP7X",
   },
   {
      name: "US Dollar",
      id_hash: "EWBR9w74",
   },
   {
      name: "Private Equity",
      id_hash: "a1mevqk9",
   },
   {
      name: "Real Estate",
      id_hash: "zXBovE7w",
   },
   {
      name: "Venture Capital",
      id_hash: "q0BJ9d7L",
   },
   {
      name: "Options",
      id_hash: "Vzkpv3Bp",
   },
   {
      name: "Futures",
      id_hash: "NwBD9em2",
   },
   {
      name: "Balanced",
      id_hash: "w9kA94k6",
   },
   {
      name: "Value",
      id_hash: "1NBzVXBg",
   },
   {
      name: "Growth",
      id_hash: "wYk39ZB4",
   },
   {
      name: "Blend",
      id_hash: "8jmYvnkq",
   },
   {
      name: "Energy",
      id_hash: "K571547D",
   },
   {
      name: "Metals",
      id_hash: "Yd7xNrmX",
   },
   {
      name: "Precious metals",
      id_hash: "Y07O9JmP",
   },
   {
      name: "Corn",
      id_hash: "gb7nvQ70",
   },
   {
      name: "Oats",
      id_hash: "MbBbvNBr",
   },
   {
      name: "Soybeans",
      id_hash: "GaBdvvkV",
   },
   {
      name: "Rapeseed",
      id_hash: "MLBG9lko",
   },
   {
      name: "Wheat",
      id_hash: "zVmy2ykw",
   },
   {
      name: "Milk",
      id_hash: "aDkqvb70",
   },
   {
      name: "Cocoa",
      id_hash: "Mbk0Gxm1",
   },
   {
      name: "Coffee",
      id_hash: "oZB99wBW",
   },
   {
      name: "Palm Oil",
      id_hash: "2WBXvakV",
   },
   {
      name: "Rubber",
      id_hash: "bA7vv37R",
   },
   {
      name: "Wool",
      id_hash: "dakP3LmK",
   },
   {
      name: "Amber",
      id_hash: "nJ7LKwkN",
   },
   {
      name: "Timber",
      id_hash: "nWB851mr",
   },
   {
      name: "Large cap",
      id_hash: "edmwxdkj",
   },
   {
      name: "Mid Cap",
      id_hash: "XDmNAyBl",
   },
   {
      name: "Small Cap",
      id_hash: "3Mk2OX7D",
   },
   {
      name: "Live cattle",
      id_hash: "a1meoq79",
   },
   {
      name: "Feeder cattle",
      id_hash: "zXBoKEBw",
   },
   {
      name: "Natural Gas",
      id_hash: "Qb7gZNkr",
   },
   {
      name: "Copper",
      id_hash: "Dy76dxmp",
   },
   {
      name: "Lead",
      id_hash: "VzkpD3kp",
   },
   {
      name: "Zinc",
      id_hash: "gXkKpA7Z",
   },
   {
      name: "Tin",
      id_hash: "NwBDZek2",
   },
   {
      name: "Aluminium",
      id_hash: "w9kAe4k6",
   },
   {
      name: "Aluminium alloy",
      id_hash: "1NBzbXBg",
   },
   {
      name: "Nickel",
      id_hash: "wYk3nZm4",
   },
   {
      name: "Cobalt",
      id_hash: "8jmYdnBq",
   },
   {
      name: "Molybdenum",
      id_hash: "Mr7jlymR",
   },
   {
      name: "Recycled Steel",
      id_hash: "K571x4kD",
   },
   {
      name: "Gold",
      id_hash: "Yd7xOrkX",
   },
   {
      name: "Platinum",
      id_hash: "Y07OzJkP",
   },
   {
      name: "Palladium",
      id_hash: "gb7npQm0",
   },
   {
      name: "Silver",
      id_hash: "MbBbRNBr",
   },
   {
      name: "Dynamic",
      id_hash: "ZABQY2B1",
   },
   {
      name: "Emerging Markets",
      id_hash: "MLBGllmo",
   },
   {
      name: "Frontier markets",
      id_hash: "ew75QY7M",
   },
   {
      name: "Active",
      id_hash: "3Mk2AXmD",
   },
   {
      name: "Passive",
      id_hash: "zv7VAPkX",
   },
   {
      name: "Australia",
      id_hash: "EWBR6wB4",
   },
   {
      name: "Austria",
      id_hash: "w2Bad57X",
   },
   {
      name: "Belgium",
      id_hash: "a1meyqm9",
   },
   {
      name: "Canada",
      id_hash: "zXBoPEmw",
   },
   {
      name: "Denmark",
      id_hash: "q0BJodmL",
   },
   {
      name: "Finland",
      id_hash: "Q6mZjbmX",
   },
   {
      name: "France",
      id_hash: "Qb7gPNBr",
   },
   {
      name: "Germany",
      id_hash: "15BWz47A",
   },
   {
      name: "Hong Kong",
      id_hash: "edmwPVkj",
   },
   {
      name: "Ireland",
      id_hash: "XDmNWGkl",
   },
   {
      name: "Israel",
      id_hash: "3Mk2AamD",
   },
   {
      name: "Italy",
      id_hash: "zv7VAekX",
   },
   {
      name: "Japan",
      id_hash: "EWBR6RB4",
   },
   {
      name: "Netherlands",
      id_hash: "w2BadQ7X",
   },
   {
      name: "New Zealand",
      id_hash: "a1meyKm9",
   },
   {
      name: "Norway",
      id_hash: "zXBoPLmw",
   },
   {
      name: "Portugal",
      id_hash: "q0BJo2mL",
   },
   {
      name: "Singapore",
      id_hash: "Q6mZj0mX",
   },
   {
      name: "Spain",
      id_hash: "Qb7gPnBr",
   },
   {
      name: "Sweden",
      id_hash: "15BWzy7A",
   },
   {
      name: "Switzerland",
      id_hash: "zGklPpkV",
   },
   {
      name: "United Kingdom",
      id_hash: "Op743RkG",
   },
   {
      name: "Multifactor",
      id_hash: "q5krPMBe",
   },
   {
      name: "Momentum",
      id_hash: "Dy76jR7p",
   },
   {
      name: "Beta",
      id_hash: "VzkpP6kp",
   },
   {
      name: "Trend",
      id_hash: "NwBDN9k2",
   },
   {
      name: "Volatility",
      id_hash: "w9kAyek6",
   },
   {
      name: "Valuation",
      id_hash: "1NBzPPkg",
   },
   {
      name: "Quality",
      id_hash: "wYk3zwm4",
   },
   {
      name: "Size",
      id_hash: "8jmYY2mq",
   },
   {
      name: "Sentiment",
      id_hash: "Yd7xPY7X",
   },
   {
      name: "Chile",
      id_hash: "GaBdlDBV",
   },
   {
      name: "Colombia",
      id_hash: "MLBGzy7o",
   },
   {
      name: "Czech Republic",
      id_hash: "ew7521BM",
   },
   {
      name: "Egypt",
      id_hash: "zxBEDp7e",
   },
   {
      name: "Greece",
      id_hash: "zVmyELmw",
   },
   {
      name: "Hungary",
      id_hash: "aDkqxam0",
   },
   {
      name: "Indonesia",
      id_hash: "Mbk0yj71",
   },
   {
      name: "South Korea",
      id_hash: "oZB9edBW",
   },
   {
      name: "Malaysia",
      id_hash: "2WBXwb7V",
   },
   {
      name: "Mexico",
      id_hash: "bA7vdamR",
   },
   {
      name: "Pakistan",
      id_hash: "dakPQX7K",
   },
   {
      name: "Peru",
      id_hash: "nJ7Lx17N",
   },
   {
      name: "Philippines",
      id_hash: "nWB8aLmr",
   },
   {
      name: "Poland",
      id_hash: "GAkMOVBb",
   },
   {
      name: "Qatar",
      id_hash: "edmwGVmj",
   },
   {
      name: "South Africa",
      id_hash: "XDmNMGBl",
   },
   {
      name: "Taiwan",
      id_hash: "3Mk20aBD",
   },
   {
      name: "Thailand",
      id_hash: "zv7VqekX",
   },
   {
      name: "Turkey",
      id_hash: "EWBRGRm4",
   },
   {
      name: "Short Term",
      id_hash: "Qb7g6nkr",
   },
   {
      name: "Medium Term",
      id_hash: "15BW0ymA",
   },
   {
      name: "Long term",
      id_hash: "zGklzpBV",
   },
   {
      name: "Argentina",
      id_hash: "q5krOM7e",
   },
   {
      name: "Bangladesh",
      id_hash: "Dy76VR7p",
   },
   {
      name: "Benin",
      id_hash: "Vzkp167p",
   },
   {
      name: "Botswana",
      id_hash: "gXkK1vBZ",
   },
   {
      name: "Bulgaria",
      id_hash: "NwBDK972",
   },
   {
      name: "Burkina Faso",
      id_hash: "w9kAre76",
   },
   {
      name: "Croatia",
      id_hash: "1NBzyP7g",
   },
   {
      name: "Estonia",
      id_hash: "wYk3wwk4",
   },
   {
      name: "Ghana",
      id_hash: "8jmYN2kq",
   },
   {
      name: "Ivory Coast",
      id_hash: "Mr7jD3mR",
   },
   {
      name: "Jamaica",
      id_hash: "K571qO7D",
   },
   {
      name: "Jordan",
      id_hash: "Yd7x5YBX",
   },
   {
      name: "Kazakhstan",
      id_hash: "Y07ObXkP",
   },
   {
      name: "Kenya",
      id_hash: "gb7nYak0",
   },
   {
      name: "Kuwait",
      id_hash: "MbBbWMmr",
   },
   {
      name: "Lebanon",
      id_hash: "ZABQ5nB1",
   },
   {
      name: "Lithuania",
      id_hash: "GaBd4DBV",
   },
   {
      name: "Mali",
      id_hash: "MLBGYyko",
   },
   {
      name: "Mauritius",
      id_hash: "ew75R1BM",
   },
   {
      name: "Morocco",
      id_hash: "zxBEzpme",
   },
   {
      name: "Niger",
      id_hash: "zVmyyLmw",
   },
   {
      name: "Nigeria",
      id_hash: "aDkqOaB0",
   },
   {
      name: "North Korea",
      id_hash: "Mbk09j71",
   },
   {
      name: "Oman",
      id_hash: "oZB9JdmW",
   },
   {
      name: "Palestine",
      id_hash: "2WBXNbkV",
   },
   {
      name: "Panama",
      id_hash: "bA7vGaBR",
   },
   {
      name: "Romania",
      id_hash: "dakPMX7K",
   },
   {
      name: "Saudia Arabia",
      id_hash: "nJ7Ln1mN",
   },
   {
      name: "Senegal",
      id_hash: "nWB8oL7r",
   },
   {
      name: "Serbia",
      id_hash: "GAkMbV7b",
   },
   {
      name: "Slovenia",
      id_hash: "edmw5V7j",
   },
   {
      name: "Sri Lanka",
      id_hash: "XDmNQGBl",
   },
   {
      name: "Materials",
      id_hash: "3Mk2NakD",
   },
   {
      name: "Industrials",
      id_hash: "zv7V9ekX",
   },
   {
      name: "Consumer discretionary",
      id_hash: "EWBRER74",
   },
   {
      name: "Consumer staples",
      id_hash: "w2BagQkX",
   },
   {
      name: "Health Care",
      id_hash: "a1merKk9",
   },
   {
      name: "Financials",
      id_hash: "zXBo1Lkw",
   },
   {
      name: "Information Technology",
      id_hash: "q0BJx2kL",
   },
   {
      name: "Communication services",
      id_hash: "Q6mZV0mX",
   },
   {
      name: "Utilities",
      id_hash: "Qb7gpnBr",
   },
   {
      name: "Chemicals",
      id_hash: "15BWwyBA",
   },
   {
      name: "Construction materials",
      id_hash: "edmw5n7j",
   },
   {
      name: "Capital goods",
      id_hash: "Vzkpn9Bp",
   },
   {
      name: "Transportation",
      id_hash: "gXkKQLBZ",
   },
   {
      name: "Building products",
      id_hash: "w9kAALk6",
   },
   {
      name: "Electrical equipment",
      id_hash: "1NBz9r7g",
   },
   {
      name: "Industrial Conglomerates",
      id_hash: "wYk3a1B4",
   },
   {
      name: "Machinery",
      id_hash: "8jmYR4kq",
   },
   {
      name: "Professional services",
      id_hash: "Y07OE5mP",
   },
   {
      name: "Income",
      id_hash: "gb7nx1m0",
   },
   {
      name: "Cash Management",
      id_hash: "MLBGxOmo",
   },
   {
      name: "Retirement",
      id_hash: "ew75ojmM",
   },
   {
      name: "Tax management",
      id_hash: "zxBEjdme",
   },
   {
      name: "Government Shutdown",
      id_hash: "zVmy0xBw",
   },
   {
      name: "Inflation",
      id_hash: "dakPyJ7K",
   },
   {
      name: "Deflation",
      id_hash: "nJ7Ld07N",
   },
   {
      name: "Airlines",
      id_hash: "zv7VNa7X",
   },
   {
      name: "Marine",
      id_hash: "EWBR0Kk4",
   },
   {
      name: "Transportation Infrastructure",
      id_hash: "w2BalD7X",
   },
   {
      name: "Consumer services",
      id_hash: "Q6mZa2BX",
   },
   {
      name: "Media",
      id_hash: "Qb7gDpBr",
   },
   {
      name: "Retailing",
      id_hash: "15BWMMkA",
   },
   {
      name: "Auto components",
      id_hash: "zGkljaBV",
   },
   {
      name: "Household durables",
      id_hash: "Op74e0BG",
   },
   {
      name: "Leisure products",
      id_hash: "q5krQGme",
   },
   {
      name: "Distributors",
      id_hash: "Mr7jVd7R",
   },
   {
      name: "Multiline Retail",
      id_hash: "K571OAmD",
   },
   {
      name: "Specialty Retail",
      id_hash: "Yd7xK0kX",
   },
   {
      name: "Beverages",
      id_hash: "zVmyDxBw",
   },
   {
      name: "Food products",
      id_hash: "aDkqgrB0",
   },
   {
      name: "Tobacco",
      id_hash: "Mbk0xMk1",
   },
   {
      name: "Home Insurance",
      id_hash: "bA7v6L7R",
   },
   {
      name: "Health Insurance",
      id_hash: "dakPKJmK",
   },
   {
      name: "Disability insurance",
      id_hash: "nJ7L407N",
   },
   {
      name: "Health Tech",
      id_hash: "edmwpnkj",
   },
   {
      name: "Fintech",
      id_hash: "XDmN6Nml",
   },
   {
      name: "Artificial Intelligence",
      id_hash: "3Mk2n8kD",
   },
   {
      name: "Cloud computing",
      id_hash: "zv7Vja7X",
   },
   {
      name: "Big Data",
      id_hash: "EWBRAK74",
   },
   {
      name: "Virtual Reality",
      id_hash: "w2BayDBX",
   },
   {
      name: "Wearable Tech",
      id_hash: "a1meXvB9",
   },
   {
      name: "Drone",
      id_hash: "zXBoJK7w",
   },
   {
      name: "Sensors",
      id_hash: "q0BJWomL",
   },
   {
      name: "IOT",
      id_hash: "Q6mZn2mX",
   },
   {
      name: "3D printing",
      id_hash: "Qb7gwp7r",
   },
   {
      name: "Computer Vision",
      id_hash: "15BW1MkA",
   },
   {
      name: "Robotics",
      id_hash: "edmwppkj",
   },
   {
      name: "Governance",
      id_hash: "zv7Vjw7X",
   },
   {
      name: "Climate change",
      id_hash: "w2BaP67X",
   },
   {
      name: "Clean energy",
      id_hash: "a1meVQk9",
   },
   {
      name: "Cleantech",
      id_hash: "zXBo4Nmw",
   },
   {
      name: "Renewable energy",
      id_hash: "q0BJK3mL",
   },
   {
      name: "Fossil free",
      id_hash: "Q6mZEKBX",
   },
   {
      name: "Water",
      id_hash: "Qb7ga0mr",
   },
   {
      name: "Human rights",
      id_hash: "1NBz1e7g",
   },
   {
      name: "Employee treatment",
      id_hash: "wYk3qRB4",
   },
   {
      name: "Immunotherapy",
      id_hash: "8jmY5Gmq",
   },
   {
      name: "Medical Devices",
      id_hash: "Mr7jJo7R",
   },
   {
      name: "Stemcell",
      id_hash: "K571EW7D",
   },
   {
      name: "MRI",
      id_hash: "Yd7xYyBX",
   },
   {
      name: "Payments",
      id_hash: "Y07OYKmP",
   },
   {
      name: "Insurtech",
      id_hash: "gb7nlLm0",
   },
   {
      name: "Lending platforms",
      id_hash: "MbBbw2mr",
   },
   {
      name: "Roboadvisors",
      id_hash: "GaBd2nkV",
   },
   {
      name: "Organic",
      id_hash: "MLBGrM7o",
   },
   {
      name: "Qsr",
      id_hash: "ew759K7M",
   },
   {
      name: "Coins",
      id_hash: "zxBEl37e",
   },
   {
      name: "Bitcoin",
      id_hash: "zVmyjzkw",
   },
   {
      name: "Ethereum",
      id_hash: "aDkq6E70",
   },
   {
      name: "Ripple",
      id_hash: "Mbk05671",
   },
   {
      name: "Seniors",
      id_hash: "2WBXGv7V",
   },
   {
      name: "millennials",
      id_hash: "bA7vz17R",
   },
   {
      name: "Gen X",
      id_hash: "dakPj4BK",
   },
   {
      name: "Baby boomers",
      id_hash: "nJ7LAxBN",
   },
   {
      name: "Military spending",
      id_hash: "nWB8PoBr",
   },
   {
      name: "Driverless Cars",
      id_hash: "GAkMzG7b",
   },
   {
      name: "Electric",
      id_hash: "edmw3pmj",
   },
   {
      name: "Low-cost Investment",
      id_hash: "3Mk2yZmD",
   },
   {
      name: "Energy equipment and services",
      id_hash: "EWBRLek4",
   },
   {
      name: "Oil and gas",
      id_hash: "w2Bar6mX",
   },
   {
      name: "Containers and Packaging",
      id_hash: "a1meDQB9",
   },
   {
      name: "Metals and mining",
      id_hash: "zXBobN7w",
   },
   {
      name: "Paper and forest products",
      id_hash: "q0BJE37L",
   },
   {
      name: "Commercial and Professional services",
      id_hash: "Q6mZoKkX",
   },
   {
      name: "Aerospace and Defense",
      id_hash: "Qb7gz0kr",
   },
   {
      name: "Construction and Engineering",
      id_hash: "15BWRX7A",
   },
   {
      name: "Trading companies & distributors",
      id_hash: "zGklxDkV",
   },
   {
      name: "Commercial services and Supplies",
      id_hash: "Op74WqkG",
   },
   {
      name: "Air freight and Logistics",
      id_hash: "q5krGw7e",
   },
   {
      name: "Road and rail",
      id_hash: "Dy764Omp",
   },
   {
      name: "Automobiles and Components",
      id_hash: "VzkpQa7p",
   },
   {
      name: "Consumer durables and Apparel",
      id_hash: "gXkKy8BZ",
   },
   {
      name: "Automobiles",
      id_hash: "NwBDnJk2",
   },
   {
      name: "Textiles apparel and luxury goods",
      id_hash: "w9kA1Ok6",
   },
   {
      name: "Hotels restaurants and leisure",
      id_hash: "1NBzveBg",
   },
   {
      name: "Diversified consumer services",
      id_hash: "wYk3gRk4",
   },
   {
      name: "Internet and Direct marketing retail",
      id_hash: "8jmYMGmq",
   },
   {
      name: "Food and staples retailing",
      id_hash: "Mr7jjo7R",
   },
   {
      name: "Food beverage and tobacco",
      id_hash: "K571JWBD",
   },
   {
      name: "Household and personal products",
      id_hash: "Yd7x9yBX",
   },
   {
      name: "Household products",
      id_hash: "Y07OXKBP",
   },
   {
      name: "Personal products",
      id_hash: "gb7nnL70",
   },
   {
      name: "Healthcare equipment and services",
      id_hash: "MbBb62Br",
   },
   {
      name: "Pharmaceuticals biotechnology and life sciences",
      id_hash: "ZABQP071",
   },
   {
      name: "Health care equipment and supplies",
      id_hash: "GaBdKn7V",
   },
   {
      name: "Healthcare providers and services",
      id_hash: "MLBGdMmo",
   },
   {
      name: "Health care technology",
      id_hash: "ew75lKkM",
   },
   {
      name: "Biotechnology",
      id_hash: "zxBEb3me",
   },
   {
      name: "Pharmaceuticals",
      id_hash: "zVmyXzBw",
   },
   {
      name: "Life Sciences tools and services",
      id_hash: "aDkqdEm0",
   },
   {
      name: "Banks",
      id_hash: "Mbk0o6m1",
   },
   {
      name: "Diversified financials",
      id_hash: "oZB9pv7W",
   },
   {
      name: "Thrifts and mortgage finance",
      id_hash: "2WBXavmV",
   },
   {
      name: "Diversified financial services",
      id_hash: "bA7vq1kR",
   },
   {
      name: "Consumer finance",
      id_hash: "dakPv4BK",
   },
   {
      name: "Capital markets",
      id_hash: "nJ7LqxBN",
   },
   {
      name: "Mortgage real estate investment trusts",
      id_hash: "nWB86okr",
   },
   {
      name: "Software and services",
      id_hash: "GAkM0Gmb",
   },
   {
      name: "technology hardware and equipment",
      id_hash: "edmw0pkj",
   },
   {
      name: "Semiconductors and semiconductor equipment",
      id_hash: "XDmN93Bl",
   },
   {
      name: "IT services",
      id_hash: "3Mk2ZZkD",
   },
   {
      name: "Software",
      id_hash: "zv7VVw7X",
   },
   {
      name: "Communications equipment",
      id_hash: "EWBRqe74",
   },
   {
      name: "Technology hardware storage and Peripherals",
      id_hash: "w2BaY6BX",
   },
   {
      name: "Electronic equipment instruments and Components",
      id_hash: "a1medQB9",
   },
   {
      name: "Telecommunication Services",
      id_hash: "zXBooNBw",
   },
   {
      name: "Media and Entertainment",
      id_hash: "q0BJA37L",
   },
   {
      name: "Diversified Telecommunication Services",
      id_hash: "Q6mZJKmX",
   },
   {
      name: "Wireless Telecommunication Services",
      id_hash: "Qb7gy0mr",
   },
   {
      name: "Entertainment",
      id_hash: "15BW2X7A",
   },
   {
      name: "Interactive media and Services",
      id_hash: "edmwjgBj",
   },
   {
      name: "Electric utilities",
      id_hash: "XDmNnJml",
   },
   {
      name: "Gas utilities",
      id_hash: "3Mk2rjkD",
   },
   {
      name: "Multi utilities",
      id_hash: "zv7VwxBX",
   },
   {
      name: "Water utilities",
      id_hash: "EWBRqd74",
   },
   {
      name: "Independent power and Renewable electricity producers",
      id_hash: "w2BaY0BX",
   },
   {
      name: "Real Estate Investment Trust(REITS)",
      id_hash: "a1med9B9",
   },
   {
      name: "Real Estate management and Development",
      id_hash: "zXBooMBw",
   },
   {
      name: "Developed Non US markets",
      id_hash: "q0BJAe7L",
   },
   {
      name: "United Arab Emirates",
      id_hash: "Qb7gyGmr",
   },
   {
      name: "BICK markets",
      id_hash: "15BW2o7A",
   },
   {
      name: "Bosnia and Herzegovina",
      id_hash: "zGklNXBV",
   },
   {
      name: "Trinidad and Tobago",
      id_hash: "Op74DK7G",
   },
   {
      name: "Tunisia",
      id_hash: "q5kr4yBe",
   },
   {
      name: "Ukraine",
      id_hash: "Dy76Y2kp",
   },
   {
      name: "Vietnam",
      id_hash: "VzkpZpBp",
   },
   {
      name: "Zimbabwe",
      id_hash: "gXkK30kZ",
   },
   {
      name: "Capital appreciation",
      id_hash: "NwBDoq72",
   },
   {
      name: "Capital preservation",
      id_hash: "w9kANo76",
   },
   {
      name: "Tax loss harvesting",
      id_hash: "1NBzQ27g",
   },
   {
      name: "Tax transition",
      id_hash: "wYk3pV74",
   },
   {
      name: "Tax optimized withdrawals",
      id_hash: "8jmYeDmq",
   },
   {
      name: "Tax optimized allocation",
      id_hash: "Mr7jPxmR",
   },
   {
      name: "Tax location",
      id_hash: "K571lpkD",
   },
   {
      name: "Fees",
      id_hash: "Yd7xxJ7X",
   },
   {
      name: "Equities",
      id_hash: "Y07OQ9mP",
   },
   {
      name: "Mortgage backed security",
      id_hash: "MbBb1G7r",
   },
   {
      name: "Asset backed security",
      id_hash: "ZABQEdB1",
   },
   {
      name: "Non bank debt",
      id_hash: "GaBdb4kV",
   },
   {
      name: "Currencies",
      id_hash: "MLBG4xmo",
   },
   {
      name: "Hedge Funds",
      id_hash: "ew75zdBM",
   },
   {
      name: "Risk arbitrage",
      id_hash: "zxBEZlme",
   },
   {
      name: "Hedge fund tracking",
      id_hash: "zVmynXkw",
   },
   {
      name: "Long Short equity",
      id_hash: "aDkqWWB0",
   },
   {
      name: "Managed futures fund",
      id_hash: "bk0Y3371",
   },
   {
      name: "Event driven strategy",
      id_hash: "ZB9PzP7W",
   },
   {
      name: "Long Short credit strategy",
      id_hash: "WBXlzdBV",
   },
   {
      name: "Relative value strategy",
      id_hash: "A7v8n0mR",
   },
   {
      name: "Market neutral strategy",
      id_hash: "akPRLOBK",
   },
   {
      name: "Asset allocation",
      id_hash: "WB8X4NBr",
   },
   {
      name: "Agricultural Commodities",
      id_hash: "AkMjNR7b",
   },
   {
      name: "Livestock and Meat",
      id_hash: "dmwWXgmj",
   },
   {
      name: "Lean hogs",
      id_hash: "DmNE2JBl",
   },
   {
      name: "Crude oil",
      id_hash: "Mk2Wdj7D",
   },
   {
      name: "Interest rate cycles",
      id_hash: "v7VKgxBX",
   },
   {
      name: "Market cycles",
      id_hash: "WBR4edk4",
   },
   {
      name: "Economic cycles",
      id_hash: "2Ba610mX",
   },
   {
      name: "Revenues",
      id_hash: "1me8O9B9",
   },
   {
      name: "Dividends",
      id_hash: "XBoD0Mmw",
   },
   {
      name: "Yield",
      id_hash: "0BJyMe7L",
   },
   {
      name: "Earnings",
      id_hash: "6mZML1BX",
   },
   {
      name: "Buy and Hold",
      id_hash: "b7gXxGBr",
   },
   {
      name: "Currency hedging",
      id_hash: "5BWx3omA",
   },
   {
      name: "Master limited partnerships",
      id_hash: "Gkl58X7V",
   },
   {
      name: "Exchange Trade Funds",
      id_hash: "p74MdKBG",
   },
   {
      name: "Non Performing Assets",
      id_hash: "y766W27p",
   },
   {
      name: "Trading Services",
      id_hash: "zkpy9p7p",
   },
   {
      name: "Client Service",
      id_hash: "XkKLY07Z",
   },
   {
      name: "Marketing & sales",
      id_hash: "wBDL3qB2",
   },
   {
      name: "Health",
      id_hash: "9kA0gom6",
   },
   {
      name: "Environmental investing",
      id_hash: "NBzlL2kg",
   },
   {
      name: "Environmental",
      id_hash: "J7LwpQkN",
   },
   {
      name: "Social investing",
      id_hash: "Yk3jLVm4",
   },
   {
      name: "Development goals",
      id_hash: "jmYLEDBq",
   },
   {
      name: "United States",
      id_hash: "bBblMGkr",
   },
   {
      name: "BRIC markets",
      id_hash: "ABQnadm1",
   },
   {
      name: "Coaching",
      id_hash: "aBdY14mV",
   },
   {
      name: "Life and Wellness",
      id_hash: "LBG5Xxko",
   },
   {
      name: "Leadership",
      id_hash: "xBEyYlBe",
   },
   {
      name: "Management",
      id_hash: "Vmyr5Xkw",
   },
   {
      name: "CEO",
      id_hash: "Dkq5nWk0",
   },
   {
      name: "Personal Growth",
      id_hash: "bk0YY371",
   },
   {
      name: "Professional Growth",
      id_hash: "ZB9PPP7W",
   },
   {
      name: "Mindfulness",
      id_hash: "WBXlldBV",
   },
   {
      name: "College Football",
      id_hash: "A7v880mR",
   },
   {
      name: "Baseball",
      id_hash: "akPRROBK",
   },
   {
      name: "Basketball",
      id_hash: "J7LwwykN",
   },
   {
      name: "Football",
      id_hash: "WB8XXNBr",
   },
   {
      name: "Golf",
      id_hash: "AkMjjR7b",
   },
   {
      name: "Brazil",
      id_hash: "0BJyye7L",
   },
   {
      name: "Russia",
      id_hash: "6mZMM1BX",
   },
   {
      name: "India",
      id_hash: "b7gXXGBr",
   },
   {
      name: "China",
      id_hash: "5BWxxomA",
   },
   {
      name: "Natural Language Processing",
      id_hash: "dmwWWNmj",
   },
   {
      name: "Safety",
      id_hash: "DmNEEXBl",
   },
   {
      name: "Diversification",
      id_hash: "Mk2WWg7D",
   },
   {
      name: "Brexit",
      id_hash: "WBR44gk4",
   },
   {
      name: "401K",
      id_hash: "2Ba66bmX",
   },
   {
      name: "Cryptocurrency",
      id_hash: "1me88nB9",
   },
   {
      name: "Medical Marijuana",
      id_hash: "XBoDDymw",
   },
   {
      name: "Cannabis",
      id_hash: "0BJyyg7L",
   },
   {
      name: "Divorce",
      id_hash: "6mZMM4BX",
   },
   {
      name: "Women",
      id_hash: "b7gXX2Br",
   },
   {
      name: "Business Liquidity",
      id_hash: "5BWxxemA",
   },
   {
      name: "LGBT",
      id_hash: "Gkl55W7V",
   },
   {
      name: "Solar Energy",
      id_hash: "p74MMvBG",
   },
   {
      name: "Geothermal",
      id_hash: "5krZZvke",
   },
   {
      name: "Wind Energy",
      id_hash: "y7666d7p",
   },
   {
      name: "Nuclear",
      id_hash: "zkpyyP7p",
   },
   {
      name: "Vice",
      id_hash: "XkKLL17Z",
   },
   {
      name: "Precision Medicine",
      id_hash: "wBDLLAB2",
   },
   {
      name: "Spinoffs",
      id_hash: "9kA00Am6",
   },
   {
      name: "Philanthropy",
      id_hash: "NBzllAkg",
   },
   {
      name: "Miscellaneous",
      id_hash: "Yk3jjqm4",
   },
   {
      name: "Estate Planning",
      id_hash: "d7x181BX",
   },
   {
      name: "Impeachment",
      id_hash: "07OV44kP",
   },
   {
      name: "alternatives",
      miovalue: "Alternatives",
   },
   {
      name: "Municipal Bonds",
      id_hash: "xBEyLGBe",
   },
];

export const getInsightHashKey = varName => {
   const foundVar = themeMapping.filter(i => i.name === varName);

   if (foundVar.length) {
      if (foundVar[0].miovalue) {
         return mioVar(foundVar[0].miovalue);
      }
      return false;
   }
   return false;
};

const mioVar = varName => {
   const foundVar = themeCode.filter(i => i.name === varName);
   if (foundVar.length) {
      return foundVar[0].id_hash;
   }
   return false;
};

export default {
   getInsightHashKey,
};
