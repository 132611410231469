import zipObject from "lodash/zipObject";

const actions = [
   "GET_SUBSCRIPTION_PLAN_REQUEST",
   "GET_SUBSCRIPTION_PLAN_FETCH",
   "GET_SUBSCRIPTION_PLAN_SUCCESS",

   "SUCCESS_TO_INVITE_FRIENDS",
   "INVITE_FRIENDS",
   "FAILED_TO_INVITE_FRIENDS",
   "SET_PROFILE_STATE",
   "SET_PREMIUM_STATE",

   "SET_REFERRAL_CODE",
   "REFERRAL_REQUEST",

   "PROFILE_REQUEST",
   "PROFILE_SUCCESS",
   "PROFILE_FAILURE",

   "PROFILE_LOAD",

   "CHANGE_PASSWORD_REQUEST",
   "CHANGE_PASSWORD_FETCH",
   "CHANGE_PASSWORD_SUCCESS",
   "CHANGE_PASSWORD_FAILURE",

   "PROFILE_UPDATE_REQUEST",
   "PROFILE_UPDATE_SUCCESS",
   "PROFILE_UPDATE_FAILURE",

   "CREATE_SUBSCRIPTION_REQUEST",
   "CREATE_SUBSCRIPTION_SUCCESS",
   "CREATE_SUBSCRIPTION_FAILURE",

   "UNSUBSCRIBE_USER_REQUEST",
   "UNSUBSCRIBE_USER_SUCCESS",
   "UNSUBSCRIBE_USER_FAILURE",

   "REACTIVATE_SUBSCRIPTION_REQUEST",
   "REACTIVATE_SUBSCRIPTION_SUCCESS",
   "REACTIVATE_SUBSCRIPTION_FAILURE",

   "CHECK_PREMIUM_STATE_REQUEST",
   "CHECK_PREMIUM_STATE_FETCH",
   "CHECK_PREMIUM_STATE_SUCCES",

   "PREMIUM_FEATURE_ACCESS_REQUEST",
   "PREMIUM_FEATURE_ACCESS_FETCH",
   "PREMIUM_FEATURE_ACCESS_SUCCESS",

   "PROPOSALS_LIST_LOADER",
   "GET_DWNLD_PORTFOLIO_LIST_REQUEST",
   "GET_DWNLD_PORTFOLIO_LIST_FETCH",
   "GET_DWNLD_PORTFOLIO_LIST_SUCCESS",
   "FILTER_PROPOSALS_LIST",

   "GET_PROPOSALS_COUNT",
   "GET_PROPOSALS_COUNT_SUCCESS",
   "CANCEL_ACH_TRANSACTION",
   "CANCEL_ACH_TRANSACTION_SUCCESS",
   "CANCEL_ACH_LOADING",

   "UPDATE_TRADING_ACCOUNT_DISCLOSURE",
   "UPDATE_TRADING_ACCOUNT_DISCLOSURE_SUCCESS",
   "UPDATE_TRADING_ACCOUNT_DISCLOSURE_LOADING",
   "UPDATE_TRADING_ACCOUNT_DISCLOSURE_ERROR",

   "TOGGLEEDIT",
   "SETUPDATE",
   "CHANGE_FNAME",
   "CHANGE_LNAME",
   "GET_FNAME_LNAME",

   "SET_POPOVER_OPTION_STATE",

   "ACTIVATE_SIMILAR_SEARCH_REQUEST",
   "ACTIVATE_SIMILAR_SEARCH_FETCH",
   "ACTIVATE_SIMILAR_SEARCH_SUCCESS",

   "DELETE_ACCOUNT_REQUEST",
   "DELETE_ACCOUNT_FETCH",
   "DELETE_ACCOUNT_SUCCESS",

   "EMAIL_DELETE_ACCOUNT_REQUEST",
   "EMAIL_DELETE_ACCOUNT_FETCH",
   "EMAIL_DELETE_ACCOUNT_SUCCESS",

   "TOKEN_EXPIRY_CHECK_REQUEST",
   "TOKEN_EXPIRY_CHECK_FETCH",
   "TOKEN_EXPIRY_CHECK_SUCCESS",

   "PROFILE_DATA_REQUEST",
   "PROFILE_DATA_FETCH",
   "PROFILE_DATA_SUCCESS",

   "GET_PROFILE_ORDERS_REQUEST",
   "GET_PROFILE_ORDERS_FETCH",
   "GET_PROFILE_ORDERS_SUCCESS",

   "SAVE_DISCLOSURES_REQUEST",
   "SAVE_DISCLOSURES_FETCH",
   "SAVE_DISCLOSURES_SUCCESS",

   "ACTIVE_SUBSCRIPTIONS_CANCELLATION_REQUEST",
   "ACTIVE_SUBSCRIPTIONS_CANCELLATION_FETCH",
   "ACTIVE_SUBSCRIPTIONS_CANCELLATION_SUCCESS",

   "PROFILE_PHOTO_UPLOAD_REQUEST",
   "PROFILE_PHOTO_UPLOAD_FETCH",
   "PROFILE_PHOTO_UPLOAD_SUCCESS",

   "PROFILE_PHOTO_HIDE_UN_HIDE_REQUEST",
   "PROFILE_PHOTO_HIDE_UN_HIDE_FETCH",
   "PROFILE_PHOTO_HIDE_UN_HIDE_SUCCESS",
   "PROFILE_BROKEN_IMAGE_UPDATE",
];

export default zipObject(actions, actions);
