/* eslint-disable array-callback-return */
import ActionTypes from "../action-types";
// import { areIntervalsOverlapping } from 'date-fns'
import { mappingRequiredKeys, skipRequiredKeys } from "DataSet";
import { isBurstChartDataValid } from "layouts/utils";
// import { getPortfolioBenchmarkOfSelectedTicker } from 'Utils'
// import {
//   statsForPerformanceImpact,
// } from 'layouts/utils'
// import {
//   getCalculatedEnhanceStats,
// } from 'layouts/WebDashboard/AccountsPage/utils'

// add state which will be refreshed on component unmount
const initialState = {
   loading: false,
   portfolioValue: 0,
   portfolioName: "",
   tickers: [],
   tickerNotExist: [],
   tickerNotSupported: [],

   searchTypeMode: "FUNDS",
   fundUniverse: "etf,etn,mf,cef",
   activeFundUniverse: ["etf,etn", "mf", "cef"],
   modelUniverse: "", // 'mp',
   activeModelUniverse: [],
   similarityMeasures: "fund_category",
   activeSelectorUniverse: [],
   disableSelectorUniverse: false,

   query: "",
   query_english: "",
   marketPlaceList: [],
   portfolioList: [],
   clientList: [],

   fundPortfolios: [],
   guidanceMessage: "",
   enhanceReplace: [],
   useSuggestedFunds: [],
   usingSuggestedFunds: false,
   finalizeData: {},
   modelPortfolios: {
      myCurrentPortfolio: {
         portfolioDetails: {
            name: "",
            profile: {
               firstName: "",
               lastName: "",
            }, // do not change this
            riskFeeReturn: {},
            chart: [],
         },
         holdings: [],
         targetCard: {},
         _NEW_QUERY_VAR_OBJECT: {
            _originalQueryVar: [],
            _defaultFRR: ["fees", "risk", "returns"],
            _relevanceChartTabs: ["BurstChart"],
         },
      },
      replacePortfolioOptions: [],
      replacePortfolioWith: {},
      customReplaceMPOptions: {},
   },
   enhanceTickerFilter: [],
   totalTargetCardTickers: [], // to populate evaluate ticker checkbox (default)

   modelEnhancement: {
      current: {},
      enhanced: {},
      otherEnhancements: [],
   },

   checkPurchaseValues: false,
   uploadfileDuplicate: false,
   uploadFileFailure: false,
   uploadFileFailureMsg: "",
   uploadFilePartial: false,
   uploadFilePartialMsg: "",

   allTickers: [],
   isGettingAllTickers: false,
   customReplacementTickersData: [],
   customReplacementMPData: [],

   summaryDrawer: false,

   pdfReportLoader: false,
   pdfReportDownloadStatus: {
      message: "",
      downloadStatus: "",
   },

   filename: "",
   enhance_transaction: [],

   selectedWeightOptions: {
      // name: 'Passive Fund Selector',
      // value: 'passive fund selector',
      // code: 'passive_fund_selector',
   },

   portfolioID: "",
   portfolioId: null,
   sponsoredPortfolioName: "",
   selectedPortfolioTicker: "",
   uploadedCSVPortfolioRequest: [],
   originalCSVFormatPortfolioData: [],
   originalCSVFormatPortfolioTickers: [],

   totumScore: {
      min: 0,
      max: 100,
      scoreMeter: [
         {
            key: "current",
            label: "Current Portfolio Risk",
            value: null,
            color: "#56a9e8",
         },
         {
            key: "enhanced",
            label: "Enhanced",
            value: null,
            color: "#1e5f91",
         },
      ],
   },
   currentTargetCard: null,
};

const definedState = {
   ...initialState,
   summary: {
      portfolioFunds: [],
      invalidTickerFunds: [],
      tickerNotSupported: [], // for summary page holdings listing
      tickerNamesForCSVPreview: [],
      activeFund: "",
   },
   performanceImpact: {
      selectedBenchmark: "SPY",
      upDownDataSet: [],
      currentPerformanceImpact: {},
      enhancedPerformanceImpact: {},
   },
   onboardData: {
      selectedPortfolioType: "",
      selectedPortfolioPath: "",
   },
};

export function enhancer(state = definedState, action) {
   const { customReplacementTickersData, enhanceReplace, fundPortfolios } =
      state;
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.SET_ENHANCER_STATE:
         if (payload.callback && typeof payload.callback === "function") {
            return (
               {
                  ...state,
                  ...payload,
               },
               () => payload.callback()
            );
         } else {
            return {
               ...state,
               ...payload,
            };
         }
      case ActionTypes.SET_WEIGHTS_REQUEST:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SET_WEIGHTS_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SET_SUMMARY_REQUEST:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SET_SUMMARY_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.PORTFOLIO_DATA_FETCH:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.PORTFOLIO_DATA_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_CLIENT_LIST_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_PORTFOLIO_LIST_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_MODELS_LIST_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.LOAD_CPM_LIST_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.UPLOAD_CSV_FETCH:
         return {
            ...state,
            uploadedCSVPortfolioRequest: payload.data || [],
         };
      case ActionTypes.UPLOAD_CSV_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_PORTFOLIO_BY_ID_FETCH:
         const { _for } = payload;
         let loadState = payload;
         if (_for === "REDIRECTING") {
            loadState = {
               ...initialState,
               ...payload,
               finalizeData: {},
            };
         }

         return {
            ...state,
            ...loadState,
         };

      case ActionTypes.GET_PORTFOLIO_BY_ID_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.VERIFY_CSV_TICKER_AND_PRICE_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.VALIDATE_TICKER_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.CHECK_SINGLE_TICKER_EXIST_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_FUNDS_PORTFOLIO_DATA_BY_ID_FETCH:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_FUNDS_PORTFOLIO_DATA_BY_ID_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.ADD_FUND_FOR_ENHANCE_FETCH:
         const { useSuggestedFunds, usingSuggestedFunds } = state;
         const { replaceTicker, ticker, query, selectedPortfolioFunds } =
            payload;
         let usingGivenSuggestion = usingSuggestedFunds;

         const index = enhanceReplace.findIndex(x => x.ticker === ticker);
         if (index >= 0) {
            enhanceReplace[index]["replace"] = replaceTicker;
            enhanceReplace[index]["replaceTicker"] = replaceTicker;
            enhanceReplace[index]["query"] = query;
            enhanceReplace[index]["selectedPortfolioFunds"] =
               selectedPortfolioFunds;
         } else {
            enhanceReplace.push({
               ticker,
               replace: replaceTicker,
               query,
               selectedPortfolioFunds,
            });
         }

         // AUTO LOGIC
         let temp_arr = enhanceReplace
            .map(po => {
               if (po) {
                  const found = useSuggestedFunds.find(
                     lo => lo.ticker === po.ticker && lo.replace === po.replace
                  );
                  if (found) {
                     return po;
                  }
               }
               return po;
            })
            .filter(l => typeof l !== "undefined" || l !== undefined);

         // console.log(temp_arr);
         if (temp_arr.length !== useSuggestedFunds.length) {
            usingGivenSuggestion = false;
         } else {
            usingGivenSuggestion = true;
         }

         return {
            ...state,
            enhanceReplace: [...enhanceReplace],
            usingSuggestedFunds: usingGivenSuggestion,
            // fundPortfolios,
         };
      case ActionTypes.DELETE_FUND_FROM_ENHANCE_FETCH:
         const {
            enhanceReplace: deleteEnhanceRepalce,
            fundPortfolios: portfoiliosArr,
            usingSuggestedFunds: _usingSuggestedFunds,
            useSuggestedFunds: _useSuggestedFunds,
         } = state;
         const { ticker: targetCardTicker } = payload;
         let uSF = _usingSuggestedFunds;

         let _targetCard;
         portfoiliosArr.forEach(item => {
            if (item.ticker === targetCardTicker) {
               // NOTE:  below 'if' doesnt work -> when 'NO suggestions' found -> for a 'target card' ticker
               // if (item.result && item.result.funds && item.result.funds.length > 0) {
               if (
                  item.target_card &&
                  item.target_card.funds &&
                  item.target_card.funds.length > 0
               ) {
                  _targetCard = {
                     ...item.target_card.funds[0],
                     _pF: {
                        quantity:
                           item.target_card.funds[0].quantity ||
                           item.target_card.funds[0].shares ||
                           1,
                        _realTimePrice:
                           item.target_card.funds[0].nav ||
                           item.target_card.funds[0].price,
                     },
                  };
               }
               // }
            }
         });

         const index1 = deleteEnhanceRepalce.findIndex(
            x => x.ticker === targetCardTicker
         );
         if (index1 >= 0) {
            // console.log('found ticker to delete');
            deleteEnhanceRepalce[index1]["replace"] =
               deleteEnhanceRepalce[index1].ticker;
            deleteEnhanceRepalce[index1]["replaceTicker"] =
               deleteEnhanceRepalce[index1].ticker;
            deleteEnhanceRepalce[index1]["selectedPortfolioFunds"] =
               _targetCard;
         }

         // AUTO LOGIC
         let _tempArrrr = deleteEnhanceRepalce
            .map(po => {
               if (po && _useSuggestedFunds) {
                  const found = _useSuggestedFunds.find(
                     lo => lo.ticker === po.ticker && lo.replace === po.replace
                  );
                  if (found) {
                     return po;
                  }
               }
            })
            .filter(l => typeof l !== "undefined" || l !== undefined);

         // console.log(_tempArrrr);
         if (_tempArrrr.length !== _useSuggestedFunds.length) {
            uSF = false;
         } else {
            uSF = true;
         }

         return {
            ...state,
            enhanceReplace: [...deleteEnhanceRepalce],
            usingSuggestedFunds: uSF,
            // fundPortfolios: portfoiliosArr,
         };

      case ActionTypes.RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN_SUCCESS:
         return {
            ...definedState,
            searchTypeMode: "FUNDS",
            fundUniverse: "etf,etn,mf,cef",
            activeFundUniverse: ["etf,etn", "mf", "cef"],
            modelUniverse: "", // 'mp',
            activeModelUniverse: [],
            similarityMeasures: "fund_category",
            activeSelectorUniverse: [],
            portfolioName: "",
            finalizeData: {},
         };
      case ActionTypes.RESET_FUNDS_PORTFOLIOS_RTO_SUCCESS:
         return {
            ...definedState,
            searchTypeMode: "FUNDS",
            fundUniverse: "etf,etn,mf,cef",
            activeFundUniverse: ["etf,etn", "mf", "cef"],
            modelUniverse: "", // 'mp',
            activeModelUniverse: [],
            similarityMeasures: "fund_category",
            activeSelectorUniverse: [],
            portfolioName: "",
            finalizeData: {},
         };
      case ActionTypes.GET_ALL_TICKER:
         return {
            ...state,
            isGettingAllTickers: true,
         };
      case ActionTypes.SAVE_ALL_TICKER:
         return {
            ...state,
            allTickers: payload,
            isGettingAllTickers: false,
         };

      case ActionTypes.FINALIZE_PORTFOLIO_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.FINALIZE_MODEL_PORTFOLIO_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SAVE_PORTFOLIO_FETCH:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SAVE_PORTFOLIO_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      // CLEAR_FINALIZE_DATA_FETCH is required
      case ActionTypes.CLEAR_FINALIZE_DATA_FETCH:
         return {
            ...state,
            finalizeData: {},
         };
      case ActionTypes.CLEAR_FINALIZE_DATA_SUCCESS:
         return {
            ...state,
            finalizeData: {},
         };
      case ActionTypes.CLEAR_FUND_PORTFOLIO_PAGE_DATA:
         return {
            ...state,
            fundPortfolios: [],
            guidanceMessage: "",
            enhanceReplace: [],
            finalizeData: {},
            query: "",
            query_english: "",
            tickers: [],
            performanceImpact: {
               selectedBenchmark: "SPY",
               upDownDataSet: [],
               currentPerformanceImpact: {},
               enhancedPerformanceImpact: {},
            },
         };
      case ActionTypes.GET_MODEL_PORTFOLIOS_BY_ID_FETCH:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_MODEL_PORTFOLIOS_BY_ID_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.REPLACE_CURRENT_MODEL_PORTFOLIO_FETCH:
         return {
            ...state,
            modelPortfolios: {
               ...state.modelPortfolios,
               replacePortfolioWith: payload.replacePortfolioWith,
            },
         };
      // case ActionTypes.DOWNLOAD_ENHANCER_REPORT:
      //   return {
      //     ...state,
      //     ...payload,
      //   }
      // case ActionTypes.DOWNLOAD_RECOMMENDATION_ENHANCER_REPORT:
      //   return {
      //     ...state,
      //     ...payload,
      //   }
      case ActionTypes.SAVE_ENHANCED_TRANSACTIONS_TRADE:
         return {
            ...state,
            enhance_transaction: payload,
         };
      case ActionTypes.SET_MODEL_PORTFOLIO_ENHANCEMENT_DATA:
         return {
            ...state,
            modelEnhancement: payload,
         };
      case ActionTypes.GET_TICKER_DATA_SUCCESS:
         const {
            // performanceImpact: _PerforManceImpact,
            // enhanceTickerFilter: _eTFltr,
            enhanceReplace: _enhanceReplace,
            // fundPortfolios: _fundPortfolios,
            useSuggestedFunds: _USF,
            usingSuggestedFunds: _USINGSuggestedFunds,
         } = state;
         // const _QY = state.query;
         const { overlap, fundsData: _fundsData, targetCardQuery } = payload;
         let __USINGSuggestedFunds = _USINGSuggestedFunds;
         // let _PImpact = _PerforManceImpact;

         if (_fundsData) {
            customReplacementTickersData.push({
               ticker: overlap,
               replace: _fundsData.ticker,
               replaceTicker: _fundsData.ticker,
               fundsData: _fundsData,
            });
            const index = _enhanceReplace.findIndex(x => x.ticker === overlap);
            if (index >= 0) {
               _enhanceReplace[index]["replace"] = _fundsData.ticker;
               _enhanceReplace[index]["replaceTicker"] = _fundsData.ticker;
               _enhanceReplace[index]["selectedPortfolioFunds"] = _fundsData;
            } else {
               _enhanceReplace.push({
                  ticker: overlap,
                  replace: _fundsData.ticker,
                  replaceTicker: _fundsData.ticker,
                  query: targetCardQuery,
                  selectedPortfolioFunds: _fundsData,
               });
            }
         }

         let _tempArrrrr = _enhanceReplace
            .map(po => {
               if (po) {
                  const found = _USF.find(
                     lo => lo.ticker === po.ticker && lo.replace === po.replace
                  );
                  if (found) {
                     return po;
                  }
               }
            })
            .filter(l => typeof l !== "undefined" || l !== undefined);

         if (_tempArrrrr.length !== _USF.length) {
            __USINGSuggestedFunds = false;
         } else {
            __USINGSuggestedFunds = true;
         }

         // if (_PerforManceImpact && state.query && _eTFltr) {
         //   let _pBnchmrk = getPortfolioBenchmarkOfSelectedTicker(_PerforManceImpact.upDownDataSet, _PerforManceImpact.selectedBenchmark);
         //   let upArr = _enhanceReplace.map((item) => {
         //     return item.selectedPortfolioFunds
         //   });
         //
         //   const _STATS = getCalculatedEnhanceStats(upArr, state.query, _pBnchmrk);
         //   console.log('========== PLOTTING PERFORMANCE IMPACT DATA ==========');
         //   if (_STATS && typeof _STATS.pfRet1y !== 'undefined') {
         //     _PImpact = {
         //       ..._PerforManceImpact,
         //       enhancedPerformanceImpact: _STATS,
         //     };
         //   }
         // }

         return {
            ...state,
            customReplacementTickersData: [...customReplacementTickersData],
            enhanceReplace: [..._enhanceReplace],
            usingSuggestedFunds: __USINGSuggestedFunds,
            // performanceImpact: _PImpact,
            // fundPortfolios: _fundPortfolios,
         };

      case ActionTypes.DELETE_SPECIFY_REPLACEMENT:
         const {
            enhanceReplace: _deleteEnhanceReplace,
            fundPortfolios: _portfoiliosArr,
            customReplacementTickersData: _customReplacementTickersData,
            useSuggestedFunds: _useSugF,
            usingSuggestedFunds: __UsingSF,
         } = state;
         const { replaceTicker: _deleteTicker, ticker: _targetCardTicker } =
            payload;
         let __USFF = __UsingSF;

         let _targetCardData;
         _portfoiliosArr.map(_item => {
            if (_item.ticker === _targetCardTicker) {
               // NOTE:  below 'if' doesnt work -> when 'NO suggestions' found -> for a 'target card' ticker
               // if (_item.result && _item.result.funds && _item.result.funds.length > 0) {
               if (
                  _item.target_card &&
                  _item.target_card.funds &&
                  _item.target_card.funds.length > 0
               ) {
                  _targetCardData = {
                     ..._item.target_card.funds[0],
                     _pF: {
                        quantity:
                           _item.target_card.funds[0].quantity ||
                           _item.target_card.funds[0].shares ||
                           1,
                        _realTimePrice:
                           _item.target_card.funds[0].nav ||
                           _item.target_card.funds[0].price,
                     },
                  };
               }
               // }
            }
         });

         const id1 = _deleteEnhanceReplace.findIndex(
            x =>
               x.ticker === _targetCardTicker &&
               x.selectedPortfolioFunds.ticker === _deleteTicker
         );
         if (id1 >= 0) {
            // console.log('found ticker to delete');
            _deleteEnhanceReplace[id1]["replace"] =
               _deleteEnhanceReplace[id1].ticker;
            _deleteEnhanceReplace[id1]["replaceTicker"] =
               _deleteEnhanceReplace[id1].ticker;
            _deleteEnhanceReplace[id1]["selectedPortfolioFunds"] =
               _targetCardData;
         }

         // AUTO LOGIC
         let _tempArrrrrr = _deleteEnhanceReplace
            .map(po => {
               if (po) {
                  const found = _useSugF.find(
                     lo => lo.ticker === po.ticker && lo.replace === po.replace
                  );
                  if (found) {
                     return po;
                  }
               }
            })
            .filter(l => typeof l !== "undefined" || l !== undefined);

         // console.log(_tempArrrrrr);
         if (_tempArrrrrr.length !== _useSugF.length) {
            __USFF = false;
         } else {
            __USFF = true;
         }

         return {
            ...state,
            enhanceReplace: [..._deleteEnhanceReplace],
            usingSuggestedFunds: __USFF,
            // fundPortfolios: _portfoiliosArr,
            customReplacementTickersData: _customReplacementTickersData.filter(
               l => l.replace !== _deleteTicker
            ),
         };

      case ActionTypes.RESET_FINALIZEDATA_STATE_SUCCESS:
         return {
            ...state,
            ...initialState,
            ...payload,
            finalizeData: {},
         };

      case ActionTypes.GET_MP_DETAILS_CUSTOM_REPLACE_REQUEST:
         return {
            ...state,
            isGettingMPData: true,
         };
      case ActionTypes.SAVE_MP_DETAILS_CUSTOM_REPLACE:
         return {
            ...state,
            customReplacementMPData: payload,
            isGettingMPData: false,
         };
      case ActionTypes.REMOVE_CUSTOM_MP_SELECTION:
         const { modelPortfolios, performanceImpact, modelEnhancement } = state;
         return {
            ...state,
            modelPortfolios: {
               ...modelPortfolios,
               customReplacementMPData: [],
               replacePortfolioWith: {},
               customReplaceMPOptions: {},
            },
            modelEnhancement: {
               ...modelEnhancement,
               enhanced: {},
               otherEnhancements: [],
            },
            enhanceReplace: [],
            performanceImpact: {
               ...performanceImpact,
               enhancedPerformanceImpact: {},
            },
         };

      case ActionTypes.UPDATE_EVALUATE_FILTER_FETCH:
         const {
            // enhanceTickerFilter: _enhanceTickerFilter,
            enhanceReplace: _ER,
         } = state;
         const { evaluateTickers } = payload;
         let _EnReplace = [];
         if (evaluateTickers.length === 0) {
            _EnReplace = _ER;
         } else {
            evaluateTickers.map(jk => {
               if (jk) {
                  // const {
                  //    replaceTicker,
                  //    ticker,
                  //    query,
                  //    selectedPortfolioFunds,
                  // } = payload;
                  let _found = fundPortfolios.find(ky => ky.ticker === jk);
                  let _populateTargetData = {},
                     _selectedFirstCard = {};
                  if (
                     _found &&
                     _found.ticker === jk &&
                     _found.target_card &&
                     _found.target_card.funds &&
                     _found.target_card.funds.length > 0
                  ) {
                     if (
                        _found.result &&
                        _found.result.funds &&
                        _found.result.funds.length > 0 &&
                        _found.result.funds[0].ticker
                     ) {
                        const requiredFields = {};
                        Object.keys(_found.result.funds[0]).map(kl => {
                           if (
                              kl &&
                              mappingRequiredKeys.includes(kl) &&
                              !skipRequiredKeys.includes(kl)
                           ) {
                              requiredFields[kl] =
                                 _found.result.funds[0][kl] || null;
                           }
                        });
                        _selectedFirstCard = {
                           ..._found.result.funds[0],
                           ...requiredFields,
                           _pF: {
                              quantity:
                                 _found.target_card.funds[0].quantity ||
                                 _found.target_card.funds[0].shares ||
                                 1,
                              _realTimePrice:
                                 _found.target_card.funds[0].nav ||
                                 _found.target_card.funds[0].price,
                           },
                        };
                     }
                     const _requiredFields = {};
                     Object.keys(_found.target_card.funds[0]).map(kl => {
                        if (
                           kl &&
                           mappingRequiredKeys.includes(kl) &&
                           !skipRequiredKeys.includes(kl)
                        ) {
                           _requiredFields[kl] =
                              _found.target_card.funds[0][kl] || null;
                        }
                     });
                     _populateTargetData = {
                        ..._found.target_card.funds[0],
                        ..._requiredFields,
                        _pF: {
                           quantity:
                              _found.target_card.funds[0].quantity ||
                              _found.target_card.funds[0].shares ||
                              1,
                           _realTimePrice:
                              _found.target_card.funds[0].nav ||
                              _found.target_card.funds[0].price,
                        },
                     };
                     _EnReplace.push({
                        ticker: jk,
                        query: _found.query,
                        replace:
                           Object.keys(_selectedFirstCard).length > 0
                              ? _selectedFirstCard.ticker
                              : _populateTargetData.ticker,
                        replaceTicker:
                           Object.keys(_selectedFirstCard).length > 0
                              ? _selectedFirstCard.ticker
                              : _populateTargetData.ticker,
                        selectedPortfolioFunds:
                           Object.keys(_selectedFirstCard).length > 0
                              ? _selectedFirstCard
                              : _populateTargetData,
                     });
                  }
               }
            });
         }

         return {
            ...state,
            enhanceReplace: _EnReplace,
            enhanceTickerFilter:
               evaluateTickers.length === 0
                  ? []
                  : _EnReplace.map(j => j.ticker),
         };

      case ActionTypes.UPDATE_EVALUATE_FILTER_SUCCESS:
         return {
            ...state,
         };

      case ActionTypes.DESELECT_ALL_ENHANCER_FUNDS:
         const {
            enhanceReplace: deselectAllEnhanceReplace,
            fundPortfolios: _PortfoiliosArr,
            useSuggestedFunds: _useSUGGGFFFFF,
            usingSuggestedFunds: ___uSFF,
         } = state;
         let __UsingSUGGGF = ___uSFF;

         _PortfoiliosArr.map(item => {
            const index1 = deselectAllEnhanceReplace.findIndex(
               x => x.ticker === item.ticker
            );
            // console.log('target card =>', deselectAllEnhanceReplace[index1]['ticker'], 'replace with =>', deselectAllEnhanceReplace[index1]['replaceTicker']);
            if (
               deselectAllEnhanceReplace[index1] &&
               deselectAllEnhanceReplace[index1]["ticker"] &&
               item.ticker === deselectAllEnhanceReplace[index1]["ticker"] &&
               item.result &&
               item.result.funds &&
               item.result.funds.length > 0
            ) {
               if (
                  item.target_card &&
                  item.target_card.funds &&
                  item.target_card.funds.length > 0
               ) {
                  let _TargetCard = {
                     ...item.target_card.funds[0],
                     _pF: {
                        quantity:
                           item.target_card.funds[0].quantity ||
                           item.target_card.funds[0].shares ||
                           1,
                        _realTimePrice:
                           item.target_card.funds[0].nav ||
                           item.target_card.funds[0].price,
                     },
                  };
                  if (index1 >= 0) {
                     deselectAllEnhanceReplace[index1]["replace"] =
                        deselectAllEnhanceReplace[index1].ticker;
                     deselectAllEnhanceReplace[index1]["replaceTicker"] =
                        deselectAllEnhanceReplace[index1].ticker;
                     deselectAllEnhanceReplace[index1][
                        "selectedPortfolioFunds"
                     ] = _TargetCard;
                  }
               }
            }
         });

         // AUTO LOGIC
         let _tempArrrrrrr = deselectAllEnhanceReplace
            .map(po => {
               if (po) {
                  const found = _useSUGGGFFFFF.find(
                     lo => lo.ticker === po.ticker && lo.replace === po.replace
                  );
                  if (found) {
                     return po;
                  }
               }
            })
            .filter(l => typeof l !== "undefined" || l !== undefined);

         // console.log(_tempArrrrrrr);
         if (_tempArrrrrrr.length !== _useSUGGGFFFFF.length) {
            __UsingSUGGGF = false;
         } else {
            __UsingSUGGGF = true;
         }

         // console.log(deselectAllEnhanceReplace);

         return {
            ...state,
            enhanceReplace: [...deselectAllEnhanceReplace],
            usingSuggestedFunds: __UsingSUGGGF,
            // fundPortfolios: portfoiliosArr,
         };

      case ActionTypes.USE_SUGGESTED_FUNDS_SUCCESS:
         // const {
         // performanceImpact: _performanceImpact,
         // query: _QRY,
         // totalTargetCardTickers: _totalTargetCardTickers,
         // enhanceTickerFilter: _eTFilter,
         // } = state;
         const {
            enhanceReplace: _ENHANCE_REPLACE,
            fundPortfolios: _FUND_PORTFOLIOS,
            // useSuggestedFunds: _USE_SUGGESTED_FUNDS,
         } = state;
         const { overrideState, toggleState } = payload;

         let _usingGivenSuggestion = toggleState;
         // let suggestedFundsCount = _USE_SUGGESTED_FUNDS.length;
         // let _ENHANCE_REPLACE_COUNT = _ENHANCE_REPLACE.length;
         // let PImpact = _performanceImpact;

         switch (overrideState) {
            case "MANUAL":
               if (!toggleState) {
                  // using TARGET CARD DATA for enhancing... deselect all suggested funds
                  _FUND_PORTFOLIOS.map(item => {
                     const index1 = _ENHANCE_REPLACE.findIndex(
                        x => x.ticker === item.ticker
                     );
                     if (
                        _ENHANCE_REPLACE[index1] &&
                        _ENHANCE_REPLACE[index1]["ticker"] &&
                        item.ticker === _ENHANCE_REPLACE[index1]["ticker"] &&
                        item.result &&
                        item.result.funds &&
                        item.result.funds.length > 0
                     ) {
                        if (
                           item.target_card &&
                           item.target_card.funds &&
                           item.target_card.funds.length > 0
                        ) {
                           let _TargetCard = {
                              ...item.target_card.funds[0],
                              _pF: {
                                 quantity:
                                    item.target_card.funds[0].quantity ||
                                    item.target_card.funds[0].shares ||
                                    1,
                                 _realTimePrice:
                                    item.target_card.funds[0].nav ||
                                    item.target_card.funds[0].price,
                              },
                           };
                           if (index1 >= 0) {
                              _ENHANCE_REPLACE[index1]["replace"] =
                                 _ENHANCE_REPLACE[index1].ticker;
                              _ENHANCE_REPLACE[index1]["replaceTicker"] =
                                 _ENHANCE_REPLACE[index1].ticker;
                              _ENHANCE_REPLACE[index1][
                                 "selectedPortfolioFunds"
                              ] = _TargetCard;
                           }
                        }
                     }
                  });
               } else {
                  // using all suggested funds
                  console.log("--- using all suggested funds ---");
                  _FUND_PORTFOLIOS.map(item => {
                     const index1 = _ENHANCE_REPLACE.findIndex(
                        x => x.ticker === item.ticker
                     );
                     if (
                        _ENHANCE_REPLACE[index1] &&
                        _ENHANCE_REPLACE[index1]["ticker"] &&
                        item.ticker === _ENHANCE_REPLACE[index1]["ticker"] &&
                        item.result &&
                        item.result.funds &&
                        item.result.funds.length > 0
                     ) {
                        if (item.result.funds && item.result.funds.length > 0) {
                           let _REPLACE_TICKER = item.result.funds[0].ticker;
                           let _TargetCard = {
                              ...item.result.funds[0],
                              // _pF: {
                              //   quantity: item.result.funds[0].quantity || item.result.funds[0].shares || 1,
                              //   _realTimePrice: item.result.funds[0].nav || item.result.funds[0].price,
                              // },
                           };
                           if (index1 >= 0) {
                              _ENHANCE_REPLACE[index1]["replace"] =
                                 _REPLACE_TICKER;
                              _ENHANCE_REPLACE[index1]["replaceTicker"] =
                                 _REPLACE_TICKER;
                              _ENHANCE_REPLACE[index1][
                                 "selectedPortfolioFunds"
                              ] = _TargetCard;
                           }
                        }
                     }
                  });
               }
               break;

            default:
         }

         // if (_performanceImpact && _QRY && _eTFilter) {
         //   let _pBenchmark = getPortfolioBenchmarkOfSelectedTicker(_performanceImpact.upDownDataSet, _performanceImpact.selectedBenchmark);
         //   let updatedArray = _ENHANCE_REPLACE.map((item) => {
         //     return item.selectedPortfolioFunds
         //   });
         //   const stats = getCalculatedEnhanceStats(updatedArray, _QRY, _pBenchmark);
         //   console.log('========== PLOTTING PERFORMANCE IMPACT DATA ==========');
         //   if (stats && typeof stats.pfRet1y !== 'undefined') {
         //     PImpact = {
         //       ..._performanceImpact,
         //       enhancedPerformanceImpact: stats,
         //     };
         //   }
         // }

         return {
            ...state,
            enhanceReplace: [..._ENHANCE_REPLACE],
            usingSuggestedFunds: _usingGivenSuggestion,
            // performanceImpact: PImpact,
            // fundPortfolios: _FUND_PORTFOLIOS
         };

      case ActionTypes.UPLOAD_PORTFOLIO_DATA_BY_ID_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.SET_ENHANCER_UNIVERSE:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.RESET_ENHANCER_UNIVERSE:
         return {
            ...state,
            searchTypeMode: "FUNDS",
            fundUniverse: "etf,etn,mf,cef",
            activeFundUniverse: ["etf,etn", "mf", "cef"],
            modelUniverse: "", // 'mp',
            activeModelUniverse: [],
            similarityMeasures: "fund_category",
            activeSelectorUniverse: [],
         };

      case ActionTypes.SET_ENHANCER_FUNDS_UNIVERSE:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.SET_ENHANCER_MODELS_UNIVERSE:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.GET_PORTFOLIO_SCORE_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.GET_CURRENT_PORTFOLIO_SCORE_SUCCESS:
         const {
            totumScore: { scoreMeter: _totumScoreMeter },
         } = state;
         const { score, For } = payload;
         let __SCORE_METER = _totumScoreMeter;
         if (
            For &&
            score !== null &&
            _totumScoreMeter &&
            typeof For !== "undefined" &&
            typeof score !== "undefined"
         ) {
            __SCORE_METER = _totumScoreMeter.map(item => {
               if (item.key === For) {
                  item.value = score;
               }
               return item;
            });
         }
         // console.log(__SCORE_METER);
         return {
            ...state,
            totumScore: {
               ...state.totumScore,
               scoreMeter: __SCORE_METER,
            },
         };

      case ActionTypes.GET_ENHANCED_PORTFOLIO_SCORE_SUCCESS:
         const {
            totumScore: { scoreMeter: __totumScoreMeter },
         } = state;
         const { score: _SCOREE, For: _FOR } = payload;
         let __SCORE_METER_ = __totumScoreMeter;
         if (
            _FOR &&
            _SCOREE !== null &&
            __totumScoreMeter &&
            typeof _FOR !== "undefined" &&
            typeof _SCOREE !== "undefined"
         ) {
            __SCORE_METER_ = __totumScoreMeter.map(item => {
               if (item.key === _FOR) {
                  item.value = _SCOREE;
               }
               return item;
            });
         }
         // console.log(__SCORE_METER_);
         return {
            ...state,
            totumScore: {
               ...state.totumScore,
               scoreMeter: __SCORE_METER_,
            },
         };

      case ActionTypes.TOTUM_SCORE_RESET:
         return {
            ...state,
            totumScore: {
               min: 0,
               max: 100,
               scoreMeter: [
                  {
                     key: "current",
                     label: "Current Portfolio Risk",
                     value: null,
                     color: "#56a9e8",
                  },
                  {
                     key: "enhanced",
                     label: "Enhanced",
                     value: null,
                     color: "#1e5f91",
                  },
               ],
            },
         };

      case ActionTypes.GET_CURRENT_PORTFOLIO_STATS_SUCCESS:
         const {
            currentTargetCard: __CURRENT_TARGET_CARD_PAYLOAD,
            performanceImpact: __PERFORMANCE_IMPACT_DATA,
         } = payload;
         let {
            modelPortfolios: { myCurrentPortfolio: __MY_CURRENR_PORTFOLIO },
         } = state;
         let { currentPerformanceImpact: __CURRENT_PERFORMANCE_IMPACT } =
            __PERFORMANCE_IMPACT_DATA;
         let { portfolioDetails: __PORTFOLIO_DETAILS } = __MY_CURRENR_PORTFOLIO;

         let __TARGET_CARD_REGION_DATA = [],
            __OVERLAP_DATA = [],
            __TARGET_CARD_OBJECT = {};
         let NEW_QUERY_VAR_OBJECT =
            __MY_CURRENR_PORTFOLIO._NEW_QUERY_VAR_OBJECT || {
               _defaultFRR: ["fees", "risk", "returns"],
               _originalQueryVar: [],
               _relevanceChartTabs: ["BurstChart"],
            };

         if (
            __CURRENT_TARGET_CARD_PAYLOAD &&
            __CURRENT_TARGET_CARD_PAYLOAD.length &&
            __CURRENT_TARGET_CARD_PAYLOAD[0]
         ) {
            __TARGET_CARD_OBJECT = {
               ...__CURRENT_TARGET_CARD_PAYLOAD[0],
               chartData: [],
               Region: __TARGET_CARD_REGION_DATA,
               overlap_data: __OVERLAP_DATA,
               ...NEW_QUERY_VAR_OBJECT,
            };

            if (
               __CURRENT_TARGET_CARD_PAYLOAD[0].target_overlap_burst_chart &&
               __CURRENT_TARGET_CARD_PAYLOAD[0].target_overlap_burst_chart
                  .length &&
               isBurstChartDataValid(
                  __CURRENT_TARGET_CARD_PAYLOAD[0].target_overlap_burst_chart
               )
            ) {
               __TARGET_CARD_REGION_DATA =
                  __CURRENT_TARGET_CARD_PAYLOAD[0].target_overlap_burst_chart;
               __TARGET_CARD_OBJECT = {
                  ...__TARGET_CARD_OBJECT,
                  Region: __TARGET_CARD_REGION_DATA,
               };
            }
         }

         __MY_CURRENR_PORTFOLIO = {
            ...__MY_CURRENR_PORTFOLIO,
            portfolioDetails: {
               ...__PORTFOLIO_DETAILS,
               riskFeeReturn: __CURRENT_PERFORMANCE_IMPACT,
            },
            targetCard: __TARGET_CARD_OBJECT,
            _NEW_QUERY_VAR_OBJECT: NEW_QUERY_VAR_OBJECT,
         };

         return {
            ...state,
            ...payload,
            modelPortfolios: {
               ...state.modelPortfolios,
               myCurrentPortfolio: {
                  ...state.modelPortfolios.myCurrentPortfolio,
                  ...__MY_CURRENR_PORTFOLIO,
               },
            },
         };

      case ActionTypes.GET_ENHANCED_STATS_FOR_SELECTED_TICKER_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.TOTUM_QUESTIONNAIRE_STATUS_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      default:
         return state;
   }
}
