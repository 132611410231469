import moment from "moment";
import { DefaultSortAttr, DateRange, ViewNames } from "../DataSet";
import ActionTypes from "../action-types";

const initialState = {
   query: "",
   universe: ["etf,etn", "mf", "cef"],
   // universe: ['etf,etn', 'mf'],
   fundsResponse: "",
   data: [],
   catData: [],
   commData: {},
   sortAttr: DefaultSortAttr,
   // filterAttrData: FilterAttrData.reduce(
   //   (acc, v) => ({ ...acc, [v.name]: [] }),
   //   {}
   // ),
   filterAttrData: {},
   sortDesc: false,
   filterAttrs: {},
   dateRange: { start: moment(DateRange.start), end: moment(DateRange.end) },
   dateRangeSelected: {},
   view: ViewNames[0].name,
   selectedCards: [],
   cardsCount: 0,
   loading: true,
   related: [],
   didYouMean: [],
   correctedQuery: "",
   messages: [],
   activeRegion: "",
   highlightText: {
      feesHighLightEnable: false,
      returnHighLightEnable: false,
      riskHighLightEnable: false,
   },
   firstPop: false,
   chartData: "",
   itemCardView: "",
   actions: false,
   ticks: "",
   logId: "",
   updated_at: new moment().format("YYYY/MM/D hh:mm:ss SSS"),
   theme: "",
   sponsorsTickers: [],
   trendingQueries: [],
   currentPage: 1,
   // pageLoader: false,
   chartReportData: {
      initialAllFunds: [],
      allFunds: {},
      selectedFunds: [],
   },
   searchedResultsForCSV: [],
   requestPayloadFilters: {
      filterKeys: [],
      filterValues: [],
   },
   requestPayloadSort: {
      name: "",
      value: "",
   },
   sfmTradeOrderResponse: {},
};

export function funds(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.REFRESH_FUNDS_STATE:
         return {
            ...state,
            data: [],
            // universe: ['etf,etn', 'mf'],
            universe: ["etf,etn", "mf", "cef"],
            catData: [],
            commData: {},
            sortAttr: DefaultSortAttr,
            // filterAttrData: FilterAttrData.reduce(
            //   (acc, v) => ({ ...acc, [v.name]: [] }),
            //   {}
            // ),
            filterAttrData: {},
            sortDesc: false,
            filterAttrs: {},
            dateRange: {},
            dateRangeSelected: {},
            view: ViewNames[0].name,
            selectedCards: [],
            cardsCount: 0,
            loading: true,
            related: [],
            correctedQuery: "",
            messages: [],
            activeRegion: "",
            highlightText: {
               feesHighLightEnable: false,
               returnHighLightEnable: false,
               riskHighLightEnable: false,
            },
            firstPop: false,
            chartData: "",
            itemCardView: "",
            actions: false,
            ticks: "",
            logId: "",
            updated_at: new moment().format("YYYY/MM/D hh:mm:ss SSS"),
            theme: "",
            sponsorsTickers: [],
            trendingQueries: [],
            chartReportData: {
               initialAllFunds: [],
               allFunds: {},
               selectedFunds: [],
            },
         };
      case ActionTypes.UPDATE_FUNDS_DATA:
         return {
            ...state,
            data: payload.data,
            updated_at:
               payload.updated_at ||
               new moment().format("YYYY/MM/D hh:mm:ss SSS"),
         };
      case ActionTypes.SEARCH_QUERY:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SET_FUNDS_STATE:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.FUNDS_DATA:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.FUNDS_QUERY:
         return {
            ...state,
            query: action.query,
         };
      case ActionTypes.SET_UNIVERSE:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SET_THEME_VALUE: {
         return {
            ...state,
            ...payload,
         };
      }
      case ActionTypes.SET_SPONSRED_VALUE: {
         return {
            ...state,
            ...payload,
         };
      }
      case ActionTypes.TRENDING_QUERIES_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }
      case ActionTypes.UPDATE_CHART_REPORT_DATA: {
         return {
            ...state,
            chartReportData: payload,
         };
      }

      case ActionTypes.DOWNLOAD_SEARCHED_RESULTS_FETCH: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.DOWNLOAD_SEARCHED_RESULTS_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.UPDATE_FUNDS_SELECTOR_STATE: {
         const { data: _Data } = state;
         const { selectedFunds } = payload;
         _Data.forEach(kl => {
            if (kl && kl.ticker && selectedFunds.includes(kl.ticker)) {
               kl._selector = true;
               kl._selected = true;
            } else {
               kl._selector = false;
               kl._selected = false;
            }
         });
         return {
            ...state,
            data: _Data,
         };
      }

      case ActionTypes.REMOVE_FUNDS_SELECTOR_STATE: {
         const { data: _rData } = state;
         const { unSelectTicker } = payload;
         // let _chartRepSeleF = _chartRepData.selectedFunds;
         _rData.forEach(kl => {
            if (kl && kl.ticker === unSelectTicker) {
               kl._selector = false;
               kl._selected = false;
               // _chartRepSeleF = _chartRepSeleF.filter((jh) => jh.ticker !== unSelectTicker)
            }
         });
         return {
            ...state,
            data: _rData,
            // chartReportData: {
            //   ..._chartRepData,
            //   selectedFunds: _chartRepSeleF,
            // }
         };
      }

      case ActionTypes.REMOVE_ALL_FUNDS_SELECTOR_STATE: {
         const { data: _rFData } = state;
         _rFData.forEach(kl => {
            kl._selector = false;
            kl._selected = false;
         });
         return {
            ...state,
            data: _rFData,
            // chartReportData: {
            //   ..._chartReportData,
            //   selectedFunds: [],
            // }
         };
      }

      case ActionTypes.RESET_FUNDS_UNIVERSE_STATE: {
         return {
            ...state,
            universe: ["etf,etn", "mf", "cef"],
         };
      }

      default:
         return state;
   }
}
