import React from "react";
import { connect } from "react-redux";
import UploadPhoto from "./uploadPhoto";
import { Modal, ModalBody } from "reactstrap";
import { profilePhotoUploadRequest } from "actions/profile";

class ProfilePhotoUploadModel extends React.Component {
   onUploadPhoto() {
      // addEventToAnalytics('Unsubscribe Yes', 'Unsubscribe Yes', 'UNSUBSCRIBE_YES', {}, false);
      // this.props.unsubscribeUserRequest(this.props.subscription);
   }
   render() {
      const { modalStatus, modelClose, isProfileEdit, profileEditUrl } =
         this.props;

      return (
         <React.Fragment>
            <Modal
               isOpen={modalStatus}
               toggle={() => {
                  modelClose();
               }}
               //centered
               keyboard={true}
               backdrop
               className="upload-profile-modal-sm-light"
               backdropClassName="premium-feature-process-modal-backdrop"
            >
               <ModalBody className="ssf-modal__body">
                  <UploadPhoto
                     modelClose={modelClose}
                     isProfileEdit={isProfileEdit}
                     profileEditUrl={profileEditUrl}
                     onProfileUpload={this.onUploadPhoto()}
                  ></UploadPhoto>
               </ModalBody>
            </Modal>
         </React.Fragment>
      );
   }
}

const mapStateToProps = ({ profile, auth }) => ({
   subscription: profile.profile.subscription,
   unSubscribeUserStatus: profile.unSubscribeUserStatus,
   unSubscriptionError: profile.unSubscriptionError,
   cancellation_request_status: auth.user.cancellation_request_status,
});

const mapDispatchToProps = {
   profilePhotoUploadRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ProfilePhotoUploadModel);
