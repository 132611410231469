import { createAPIInstance } from "./api";

export async function getPremiumFeatureDetails(featureName) {
   const { data } = await createAPIInstance().get(
      `user-testdrive?feature_name=${featureName}`
   );
   return data;
}

export async function allowTestDrive(feature_name) {
   const { data } = await createAPIInstance().get(
      `user-testdrive?feature_name=${feature_name}&testdrive=true`
   );
   return data;
}
