import ActionTypes from "../action-types";

/* --- SET ACCOUNTS STATE --- */
export function setAccountsState(payload, callback) {
   if (callback && typeof callback === "function") {
      return {
         type: ActionTypes.SET_ACCOUNTS_STATE,
         payload,
         callback,
      };
   } else {
      return {
         type: ActionTypes.SET_ACCOUNTS_STATE,
         payload,
      };
   }
}

/* ---- GET PORTFOLIO DATA BY ID ---- */
export function getAccountsPortfolioDataByIDRequest(payload, callback) {
   return {
      type: ActionTypes.GET_ACCOUNTS_PORTFOLIO_BY_ID_REQUEST,
      payload,
      callback,
   };
}
export function getAccountsPortfolioDataByIDFetch(payload) {
   return {
      type: ActionTypes.GET_ACCOUNTS_PORTFOLIO_BY_ID_FETCH,
      payload,
   };
}
export function getAccountsPortfolioDataByIDSuccess(payload) {
   return {
      type: ActionTypes.GET_ACCOUNTS_PORTFOLIO_BY_ID_SUCCESS,
      payload,
   };
}

/* ---- UPLOAD PORTFOLIO DATA BY ID from onBoardScreen ---- */
export function uploadAccountsPortfolioDataByIdRequest(payload, callback) {
   return {
      type: ActionTypes.UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID_REQUEST,
      payload,
      callback,
   };
}
export function uploadAccountsPortfolioDataByIdFetch(payload) {
   return {
      type: ActionTypes.UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID_FETCH,
      payload,
   };
}
export function uploadAccountsPortfolioDataByIdSuccess(payload) {
   return {
      type: ActionTypes.UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID_SUCCESS,
      payload,
   };
}

/* ---- RESET_ACCOUNTS_STATE ---- */
export function resetAccountsStateRequest(payload, callback) {
   return {
      type: ActionTypes.RESET_ACCOUNTS_STATE_REQUEST,
      payload,
      callback,
   };
}
export function resetAccountsStateFetch(payload) {
   return {
      type: ActionTypes.RESET_ACCOUNTS_STATE_FETCH,
      payload,
   };
}
export function resetAccountsStateSuccess(payload) {
   return {
      type: ActionTypes.RESET_ACCOUNTS_STATE_SUCCESS,
      payload,
   };
}

/* ---- GET_ALERT_COUNT ---- */
export function getAlertCountRequest(payload) {
   return {
      type: ActionTypes.GET_ALERT_COUNT_REQUEST,
      payload,
   };
}
export function getAlertCountFetch(payload) {
   return {
      type: ActionTypes.GET_ALERT_COUNT_FETCH,
      payload,
   };
}
export function getAlertCountSuccess(payload) {
   return {
      type: ActionTypes.GET_ALERT_COUNT_SUCCESS,
      payload,
   };
}

/* ---- GET_ACCOUNTS_TRENDING_SEARCHES ---- */
export function getAccountsTrendingSearchesRequest(payload) {
   return {
      type: ActionTypes.GET_ACCOUNTS_TRENDING_SEARCHES_REQUEST,
      payload,
   };
}
export function getAccountsTrendingSearchesFetch(payload) {
   return {
      type: ActionTypes.GET_ACCOUNTS_TRENDING_SEARCHES_FETCH,
      payload,
   };
}
export function getAccountsTrendingSearchesSuccess(payload) {
   return {
      type: ActionTypes.GET_ACCOUNTS_TRENDING_SEARCHES_SUCCESS,
      payload,
   };
}

/* removeAllFundsSelectorState */
export function removeAllFundsSelectorState(payload) {
   return {
      type: ActionTypes.REMOVE_ALL_SFM_FUNDS_FROM_ACCOUNTS_STATE,
      payload,
   };
}

/* removeAllFundsSelectorState */
export function removeFundsSelectorState(payload) {
   return {
      type: ActionTypes.REMOVE_SFM_FUND_FROM_ACCOUNTS_STATE,
      payload,
   };
}

/* updatedFundsSelectorState */
export function addFundFromAccountHoldingsToSFM(payload) {
   return {
      type: ActionTypes.ADD_SFM_FUND_STATE_TO_ACCOUNTS_STATE,
      payload,
   };
}

/* setFundSelectionType */
export function setFundSelectionType(payload) {
   return {
      type: ActionTypes.SET_ACCOUNTS_HOLDINGS_FUND_SELECTION_TYPE,
      payload,
   };
}

/* ---- CANCEL_DEPOSITS_REQUEST ---- */
export function cancelDepositsRequest(payload) {
   return {
      type: ActionTypes.CANCEL_DEPOSITS_REQUEST,
      payload,
   };
}
export function cancelDepositsFetch(payload) {
   return {
      type: ActionTypes.CANCEL_DEPOSITS_FETCH,
      payload,
   };
}
export function cancelDepositsSuccess(payload) {
   return {
      type: ActionTypes.CANCEL_DEPOSITS_SUCCESS,
      payload,
   };
}

/* ---- CANCEL_TRADES_REQUEST ---- */
export function cancelTradesRequest(payload) {
   return {
      type: ActionTypes.CANCEL_TRADES_REQUEST,
      payload,
   };
}
export function cancelTradesFetch(payload) {
   return {
      type: ActionTypes.CANCEL_TRADES_FETCH,
      payload,
   };
}
export function cancelTradesSuccess(payload) {
   return {
      type: ActionTypes.CANCEL_TRADES_SUCCESS,
      payload,
   };
}

/* ---- REFRESH APEX ALL STATES ---- */
export function refreshApexStateRequest(payload) {
   return {
      type: ActionTypes.REFRESH_APEX_STATE_REQUEST,
      payload,
   };
}
export function refreshApexStateFetch(payload) {
   return {
      type: ActionTypes.REFRESH_APEX_STATE_FETCH,
      payload,
   };
}
export function refreshApexStateSuccess(payload) {
   return {
      type: ActionTypes.REFRESH_APEX_STATE_SUCCESS,
      payload,
   };
}

/* ---- REFRESH APEX ALL STATES ---- */
export function getApexAccountDetailsRequest(payload, callback) {
   return {
      type: ActionTypes.GET_APEX_AMOUNT_DETAILS_REQUEST,
      payload,
      callback,
   };
}
export function getApexAccountDetailsFetch(payload, callback) {
   return {
      type: ActionTypes.GET_APEX_AMOUNT_DETAILS_FETCH,
      payload,
      callback,
   };
}
export function getApexAccountDetailsSuccess(payload, callback) {
   return {
      type: ActionTypes.GET_APEX_AMOUNT_DETAILS_SUCCESS,
      payload,
      callback,
   };
}

/* ---- REFRESH APEX ALL STATES ---- */
export function getPortfolioGainsRequest(payload, callback) {
   return {
      type: ActionTypes.GET_PORTFOLIO_GAINS_REQUEST,
      payload,
      callback,
   };
}
export function getPortfolioGainsFetch(payload, callback) {
   return {
      type: ActionTypes.GET_PORTFOLIO_GAINS_FETCH,
      payload,
      callback,
   };
}
export function getPortfolioGainsSuccess(payload, callback) {
   return {
      type: ActionTypes.GET_PORTFOLIO_GAINS_SUCCESS,
      payload,
      callback,
   };
}

// export function getAlertCount(payload) {
//   return {
//     type: ActionTypes.GET_ALERT_COUNT,
//     payload
//   }
// }
//
// export function setAlertCountLoading(payload) {
//   return {
//     type: ActionTypes.GET_ALERT_COUNT_LOADING,
//     payload
//   }
// }
//
// export function setAlertCountSuccess(payload) {
//   return {
//     type: ActionTypes.GET_ALERT_COUNT_SUCCESS,
//     payload
//   }
// }
//
// export function setAlertCountError(payload) {
//   return {
//     type: ActionTypes.GET_ALERT_COUNT_FAILURE,
//     payload
//   }
// }
