import { createAPIInstance } from "./api";
import qs from "query-string";

// 'https://dev.magnifi.com/api/user/highly-ranked?regionId=EURP&countryId=---&size=10'

export function getTopRankedMembers(payload) {
   return createAPIInstance()
      .get(`/user/v1/highly-ranked?${qs.stringify(payload)}&size=3`)
      .then(response => response.data);
}

// 'https://dev.magnifi.com/api/user/ranking/status?user_id=9707'
// export function getUserRankingStatus(payload) {
//   return createAPIInstance()
//     .get('/user/ranking/status?user_id=9707', {
//       params: payload
//     })
//     .then(response => response.data)
// }

// 'https://dev.magnifi.com/api/user/ranking/best?user_id=9707'
// export function getUserBestRanking(payload) {
//   return createAPIInstance()
//     .get('/user/ranking/best?user_id=9707', {
//       params: payload
//     })
//     .then(response => response.data)
// }

// 'https://dev.magnifi.com/api/user/ranking/history?userId=9707&type=selection&countryId=---&regionId=----'
export function getUserRankingHistory(payload) {
   return createAPIInstance()
      .get(
         "/user/v1/ranking/history", //?userId=9707&type=selection&countryId=---&regionId=----',
         {
            params: payload,
         }
      )
      .then(response => response.data);
}

// https://dev.magnifi.com/api/user/ratings?userId=9707&getPage=3&size=25&sortBy=recent&sortDirection=desc'
export function getUserRatings(payload) {
   return createAPIInstance()
      .get("/user/v1/ratings", {
         params: payload,
      })
      .then(response => response.data);
}

export function getYourRankingData(payload) {
   const { userId } = payload;
   return createAPIInstance()
      .get(`/user/v1/ranking/best/status?user_id=${userId}`)
      .then(res => res.data);
}

export function getUserFundRating(payload) {
   return createAPIInstance()
      .get(`/user/v1/user-fund-rating?${qs.stringify(payload)}`)
      .then(response => response.data);
}

export function addNewFundRating(payload) {
   return createAPIInstance()
      .post(`/user/v1/add-user-fund-rating`, payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function updateCommunityPortfolio(payload) {
   return createAPIInstance()
      .post(`/user/edit-user-portfolio`, payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}
export function updateUserFundRating(payload) {
   return createAPIInstance()
      .put(`/user/v1/user-fund-rating`, payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}
export function removeUserFundRating(payload) {
   return createAPIInstance()
      .patch(
         "/user/v1/user-fund-rating",
         { ...payload, isActive: 0 },
         {
            headers: { "Content-Type": "application/json" },
         }
      )
      .then(response => response.data);
}

export function getUserSearchFundsRating(payload) {
   return createAPIInstance()
      .get(`/user/v1/get-user-fund-rating?${qs.stringify(payload)}`)
      .then(response => response.data);
}

export function getInternationalTickerSuggestions(payload) {
   return createAPIInstance()
      .get(`/user/get-ticker-search?${qs.stringify(payload)}`)
      .then(response => response.data);
}

// Commentary
export function getUserCommentsApi(payload) {
   return createAPIInstance()
      .get(`/user/v1/comments?${qs.stringify(payload)}`)
      .then(response => response.data);
}

export function getFundCommentsData(payload) {
   const { assetOverviewId = "", pageNumber = 1, pageSize = 10 } = payload;
   return createAPIInstance()
      .get("/user/v1/fund/comments", {
         params: { assetOverviewId, pageNumber, pageSize },
      })
      .then(res => res.data);
}

export function voteFundCommentApi(payload) {
   const { commentId = "", voteType = "noVote" } = payload;
   return createAPIInstance()
      .post(
         `/user/v1/add-fund-comment-vote`,
         { commentId, voteType },
         {
            headers: { "Content-Type": "application/json" },
         }
      )
      .then(response => response.data)
      .catch(error => error);
}

export function postFundCommentApi(payload) {
   const { content, assetOverviewId, commentId } = payload;
   return createAPIInstance()
      .post(
         `/user/v1/comments`,
         {
            assetOverviewId,
            content,
            // For Root comments and Replies to Root Comments
            ...(commentId ? { commentId } : {}),
         },
         {
            headers: { "Content-Type": "application/json" },
         }
      )
      .then(response => response.data)
      .catch(error => error);
}

export function deleteFundCommentApi(payload) {
   const { commentId } = payload;
   return createAPIInstance()
      .delete("/user/v1/comments", { params: { commentId } })
      .then(res => res.data);
}

export function getSponsoredArticles() {
   return createAPIInstance()
      .get(`/user/sponsor-article`)
      .then(response => response.data);
}

export function getAssetSponsorOptApi(payload) {
   const { tickers = [] } = payload;
   return createAPIInstance()
      .get(`/user/get-sponsor-opt`, {
         params: { tickers: tickers.join(",") },
      })
      .then(response => response.data)
      .catch(error => error);
}

export function postAssetSponsorOpt(payload) {
   const { data, override } = payload;
   return createAPIInstance()
      .post(
         `/user/add-sponsor-opt`,
         {
            data: data,
            ...(override ? { override: true } : {}),
         },
         {
            headers: { "Content-Type": "application/json" },
         }
      )
      .then(response => response.data)
      .catch(error => error);
}

export function getAnnualAwards(payload) {
   return createAPIInstance()
      .get(`/user/get-user-awards`, {
         params: { type: payload || "selection" },
      })
      .then(response => response.data);
}
