import ActionTypes from "../action-types";

export function setEnhancerState(payload, callback) {
   if (callback && typeof callback === "function") {
      return {
         type: ActionTypes.SET_ENHANCER_STATE,
         payload,
         callback,
      };
   } else {
      return {
         type: ActionTypes.SET_ENHANCER_STATE,
         payload,
      };
   }
}

export function getBatchResult(payload) {
   return {
      type: ActionTypes.GET_BUILD_PORTFOLIO_FUNDS,
      payload,
   };
}

/* --- CUSTOM TICKERS --- */
export function customTickerRequest(payload) {
   return {
      type: ActionTypes.CUSTOM_TICKER_REQUEST,
      payload,
   };
}
export function customTickerFetch(payload) {
   return {
      type: ActionTypes.CUSTOM_TICKER_FETCH,
      payload,
   };
}
export function customTickerSuccess(payload) {
   return {
      type: ActionTypes.CUSTOM_TICKER_SUCCESS,
      payload,
   };
}

/* --- TICKER DATA --- */
export function getTickerDataRequest(payload, callback) {
   return {
      type: ActionTypes.GET_TICKER_DATA_REQUEST,
      payload,
      callback,
   };
}

export function getTickerDataFetch(payload) {
   return {
      type: ActionTypes.GET_TICKER_DATA_FETCH,
      payload,
   };
}

export function getTickerDataSuccess(payload) {
   return {
      type: ActionTypes.GET_TICKER_DATA_SUCCESS,
      payload,
   };
}

/* --- WEIGHTS BATCH --- */
export function setWeightsRequest(payload) {
   return {
      type: ActionTypes.SET_WEIGHTS_REQUEST,
      payload,
   };
}
export function setWeightsFetch(payload) {
   return {
      type: ActionTypes.SET_WEIGHTS_FETCH,
      payload,
   };
}
export function setWeightsSuccess(payload) {
   return {
      type: ActionTypes.SET_WEIGHTS_SUCCESS,
      payload,
   };
}

/* --- SAVE PORTFOLIO --- */
export function finalizeSavePortfolioRequest(payload) {
   return {
      type: ActionTypes.FINALIZE_SAVE_PORTFOLIO_REQUEST,
      payload,
   };
}
export function finalizeSavePortfolioFetch(payload) {
   return {
      type: ActionTypes.FINALIZE_SAVE_PORTFOLIO_FETCH,
      payload,
   };
}
export function finalizeSavePortfolioSuccess(payload) {
   return {
      type: ActionTypes.FINALIZE_SAVE_PORTFOLIO_SUCCESS,
      payload,
   };
}

/* --- SEARCH NEW QUERY--- */
export function searchForNewQueryRequest(payload) {
   return {
      type: ActionTypes.SEARCH_NEW_QUERY_REQUEST,
      payload,
   };
}
export function searchForNewQueryFetch(payload) {
   return {
      type: ActionTypes.SEARCH_NEW_QUERY_FETCH,
      payload,
   };
}
export function searchForNewQuerySuccess(payload) {
   return {
      type: ActionTypes.SEARCH_NEW_QUERY_SUCCESS,
      payload,
   };
}

/* --- FETCH PORTFOLIO --- */
export function fetchPortfolioRequest(payload) {
   return {
      type: ActionTypes.PORTFOLIO_DATA_REQUEST,
      payload,
   };
}
export function fetchPortfolioFetch(payload) {
   return {
      type: ActionTypes.PORTFOLIO_DATA_FETCH,
      payload,
   };
}
export function fetchPortfolioSuccess(payload) {
   return {
      type: ActionTypes.PORTFOLIO_DATA_SUCCESS,
      payload,
   };
}

/* --- RESET ENHANCER STATE --- */
export function resetEnhancerState() {
   // dont need to pass payload... discuss with ankit if change required
   return {
      type: ActionTypes.RESET_ENHANCER_STATE,
   };
}

/* --- UPLOAD BUILDER PORTFOLIO --- */
export function uploadBuilderPortfolioRequest(payload, callback) {
   return {
      type: ActionTypes.UPLOAD_BUILDER_PORTFOLIO_REQUEST,
      payload,
      callback,
   };
}

// ------ csv preflight request, get all tickers validate -------
export function checkBuilderTickerRequest(payload, callback) {
   return {
      type: ActionTypes.CHECK_BUILDER_TICKER_EXIST_REQUEST,
      payload,
      callback,
   };
}
export function checkBuilderTickerFetch(payload) {
   return {
      type: ActionTypes.CHECK_BUILDER_TICKER_EXIST_FETCH,
      payload,
   };
}

/* ---- LOAD DEMO CSV FILE ---- */
export function uploadCSVFileRequest(payload) {
   return {
      type: ActionTypes.UPLOAD_CSV_FILE_REQUEST,
      payload,
   };
}
export function uploadCSVFileFetch(payload) {
   return {
      type: ActionTypes.UPLOAD_CSV_FILE_FETCH,
      payload,
   };
}
export function uploadCSVFileSuccess(payload) {
   return {
      type: ActionTypes.UPLOAD_CSV_FILE_SUCCESS,
      payload,
   };
}

/* ---- GET PORTFOLIO LIST  ---- */
export function getPortfolioListRequest(payload) {
   return {
      type: ActionTypes.GET_PORTFOLIO_LIST_REQUEST,
      payload,
   };
}
export function getPortfolioListFetch(payload) {
   return {
      type: ActionTypes.GET_PORTFOLIO_LIST_FETCH,
      payload,
   };
}
export function getPortfolioListSuccess(payload) {
   return {
      type: ActionTypes.GET_PORTFOLIO_LIST_SUCCESS,
      payload,
   };
}

/* ---- GET MODELS LIST  ---- */
export function getModelsListRequest(payload) {
   return {
      type: ActionTypes.GET_MODELS_LIST_REQUEST,
      payload,
   };
}
export function getModelsListFetch(payload) {
   return {
      type: ActionTypes.GET_MODELS_LIST_FETCH,
      payload,
   };
}
export function getModelsListSuccess(payload) {
   return {
      type: ActionTypes.GET_MODELS_LIST_SUCCESS,
      payload,
   };
}

/* ---- GET CLIENT LIST  ---- */
export function getClientListRequest(payload, callback) {
   return {
      type: ActionTypes.GET_CLIENT_LIST_REQUEST,
      payload,
      callback,
   };
}
export function getClientListFetch(payload) {
   return {
      type: ActionTypes.GET_CLIENT_LIST_FETCH,
      payload,
   };
}
export function getClientListSuccess(payload) {
   return {
      type: ActionTypes.GET_CLIENT_LIST_SUCCESS,
      payload,
   };
}

/* ---- DELETE CLIENT LIST ---- */

export function clientDeleteFetch(payload, callback) {
   return {
      type: ActionTypes.DELETE_CLIENT_LIST_FETCH,
      payload,
   };
}

export function clientDeleteRequest(payload, callback) {
   return {
      type: ActionTypes.DELETE_CLIENT_LIST_REQUEST,
      payload,
   };
}

export function clientDeleteSuccess(payload) {
   return {
      type: ActionTypes.DELETE_CLIENT_LIST_SUCCESS,
      payload,
   };
}

/* ---- GET CPM (Client, Portfolio, Models) LIST DATA  ---- */
export function loadCPMListRequest(payload, callback) {
   return {
      type: ActionTypes.LOAD_CPM_LIST_REQUEST,
      payload,
      callback,
   };
}
export function loadCPMListFetch(payload) {
   return {
      type: ActionTypes.LOAD_CPM_LIST_FETCH,
      payload,
   };
}
export function loadCPMListSuccess(payload) {
   return {
      type: ActionTypes.LOAD_CPM_LIST_SUCCESS,
      payload,
   };
}

// ------ csv preflight request, get all tickers validate -------
export function checkTickerRequest(payload, callback) {
   return {
      type: ActionTypes.VALIDATE_TICKER_REQUEST,
      payload,
      callback,
   };
}
export function checkTickerFetch(payload) {
   return {
      type: ActionTypes.VALIDATE_TICKER_FETCH,
      payload,
   };
}
export function checkTickerSuccess(payload) {
   return {
      type: ActionTypes.VALIDATE_TICKER_SUCCESS,
      payload,
   };
}

export function checkSingleTickerValidityRequest(payload, callback) {
   return {
      type: ActionTypes.CHECK_SINGLE_TICKER_EXIST_REQUEST,
      payload,
      callback,
   };
}

export function checkSingleTickerValidityFetch(payload, callback) {
   return {
      type: ActionTypes.CHECK_SINGLE_TICKER_EXIST_FETCH,
      payload,
   };
}

export function checkSingleTickerValiditySuccess(payload) {
   return {
      type: ActionTypes.CHECK_SINGLE_TICKER_EXIST_SUCCESS,
      payload,
   };
}

/* ---- UPLOAD CSV-TO-JSON ---- */
export function uploadCSVRequest(payload, callback) {
   return {
      type: ActionTypes.UPLOAD_CSV_REQUEST,
      payload,
      callback,
   };
}
export function uploadCSVFetch(payload) {
   return {
      type: ActionTypes.UPLOAD_CSV_FETCH,
      payload,
   };
}
export function uploadCSVSuccess(payload) {
   return {
      type: ActionTypes.UPLOAD_CSV_SUCCESS,
      payload,
   };
}

/* ---- PORTFOLIO UPLOAD AFTER UPLOADED CSV ---- */

export function getPortfolioDataByIDRequest(payload, callback) {
   return {
      type: ActionTypes.GET_PORTFOLIO_BY_ID_REQUEST,
      payload,
      callback,
   };
}
export function getPortfolioDataByIDFetch(payload) {
   return {
      type: ActionTypes.GET_PORTFOLIO_BY_ID_FETCH,
      payload,
   };
}
export function getPortfolioDataByIDSuccess(payload) {
   return {
      type: ActionTypes.GET_PORTFOLIO_BY_ID_SUCCESS,
      payload,
   };
}

/* ---- VERIFY DEMO CSV TICKER AND PRICE ---- */

export function verifyCSVTickerAndPriceRequest(payload, callback) {
   return {
      type: ActionTypes.VERIFY_CSV_TICKER_AND_PRICE_REQUEST,
      payload,
      callback,
   };
}

export function verifyCSVTickerAndPriceFetch(payload) {
   return {
      type: ActionTypes.VERIFY_CSV_TICKER_AND_PRICE_FETCH,
      payload,
   };
}

export function verifyCSVTickerAndPriceSuccess(payload) {
   return {
      type: ActionTypes.VERIFY_CSV_TICKER_AND_PRICE_SUCCESS,
      payload,
   };
}

/* ---- RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN ---- */

export function resetSummaryDataRequest(payload, callback) {
   return {
      type: ActionTypes.RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN_REQUEST,
      payload,
      callback,
   };
}

export function resetSummaryDataFetch(payload) {
   return {
      type: ActionTypes.RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN_FETCH,
      payload,
   };
}

export function resetSummaryDataSuccess(payload) {
   return {
      type: ActionTypes.RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN_SUCCESS,
      payload,
   };
}

/* ---- GET FUNDS PORTFOLIO DATA BY ID ---- */

export function getFundsPortfolioBatchRequest(payload, callback) {
   return {
      type: ActionTypes.GET_FUNDS_PORTFOLIO_DATA_BY_ID_REQUEST,
      payload,
      callback,
   };
}

export function getFundsPortfolioBatchFetch(payload) {
   return {
      type: ActionTypes.GET_FUNDS_PORTFOLIO_DATA_BY_ID_FETCH,
      payload,
   };
}

export function getFundsPortfolioBatchSuccess(payload) {
   return {
      type: ActionTypes.GET_FUNDS_PORTFOLIO_DATA_BY_ID_SUCCESS,
      payload,
   };
}

/* ---- RESET_FUNDS_PORTFOLIOS_RETURN_TO_ONBOARDSCREEN ---- */

export function resetFundsPortfolioRequest(payload, callback) {
   return {
      type: ActionTypes.RESET_FUNDS_PORTFOLIOS_RTO_REQUEST,
      payload,
      callback,
   };
}

export function resetFundsPortfolioFetch(payload) {
   return {
      type: ActionTypes.RESET_FUNDS_PORTFOLIOS_RTO_FETCH,
      payload,
   };
}

export function resetFundsPortfolioSuccess(payload) {
   return {
      type: ActionTypes.RESET_FUNDS_PORTFOLIOS_RTO_SUCCESS,
      payload,
   };
}

/* ADD FUND FOR ENHANCE*/
export function addFundForEnhanceRequest(payload, callback) {
   return {
      type: ActionTypes.ADD_FUND_FOR_ENHANCE_REQUEST,
      payload,
      callback,
   };
}

export function addFundForEnhanceFetch(payload) {
   return {
      type: ActionTypes.ADD_FUND_FOR_ENHANCE_FETCH,
      payload,
   };
}

export function addFundForEnhanceSuccess(payload) {
   return {
      type: ActionTypes.ADD_FUND_FOR_ENHANCE_SUCCESS,
      payload,
   };
}

/* ADD FUND FOR ENHANCE*/
export function deleteFundForEnhanceRequest(payload, callback) {
   return {
      type: ActionTypes.DELETE_FUND_FROM_ENHANCE_REQUEST,
      payload,
      callback,
   };
}

export function deleteFundForEnhanceFetch(payload) {
   return {
      type: ActionTypes.DELETE_FUND_FROM_ENHANCE_FETCH,
      payload,
   };
}

export function deleteFundForEnhanceSuccess(payload) {
   return {
      type: ActionTypes.DELETE_FUND_FROM_ENHANCE_SUCCESS,
      payload,
   };
}

/* FINALIZE PORTFOLIO */

export function finalizePortfolioRequest(payload, callback) {
   return {
      type: ActionTypes.FINALIZE_PORTFOLIO_REQUEST,
      payload,
      callback,
   };
}

export function finalizePortfolioFetch(payload) {
   return {
      type: ActionTypes.FINALIZE_PORTFOLIO_FETCH,
      payload,
   };
}

export function finalizePortfolioSuccess(payload) {
   return {
      type: ActionTypes.FINALIZE_PORTFOLIO_SUCCESS,
      payload,
   };
}

/* FINALIZE MODEL PORTFOLIO */

export function finalizeModelPortfolioRequest(payload, callback) {
   return {
      type: ActionTypes.FINALIZE_MODEL_PORTFOLIO_REQUEST,
      payload,
      callback,
   };
}

export function finalizeModelPortfolioFetch(payload) {
   return {
      type: ActionTypes.FINALIZE_MODEL_PORTFOLIO_FETCH,
      payload,
   };
}

export function finalizeModelPortfolioSuccess(payload) {
   return {
      type: ActionTypes.FINALIZE_MODEL_PORTFOLIO_SUCCESS,
      payload,
   };
}

/* SAVE PORTFOLIO */

export function savePortfolioRequest(payload, callback) {
   return {
      type: ActionTypes.SAVE_PORTFOLIO_REQUEST,
      payload,
      callback,
   };
}

export function savePortfolioFetch(payload) {
   return {
      type: ActionTypes.SAVE_PORTFOLIO_FETCH,
      payload,
   };
}

export function savePortfolioSuccess(payload) {
   return {
      type: ActionTypes.SAVE_PORTFOLIO_SUCCESS,
      payload,
   };
}

/* CLEAR FINALIZE DATA */

export function clearFinalizeDataRequest(payload, callback) {
   return {
      type: ActionTypes.CLEAR_FINALIZE_DATA_REQUEST,
      payload,
      callback,
   };
}

export function clearFinalizeDataFetch(payload) {
   return {
      type: ActionTypes.CLEAR_FINALIZE_DATA_FETCH,
      payload,
   };
}

export function clearFinalizeDataSuccess(payload) {
   return {
      type: ActionTypes.CLEAR_FINALIZE_DATA_SUCCESS,
      payload,
   };
}

/* CLEAR FINALIZE DATA */

export function clearFundPortfolioState(payload, callback) {
   return {
      type: ActionTypes.CLEAR_FUND_PORTFOLIO_PAGE_DATA,
      payload,
      callback,
   };
}

/* GET MODEL PORTFOLIOS */

export function getModelPortfoliosByIdRequest(payload, callback) {
   return {
      type: ActionTypes.GET_MODEL_PORTFOLIOS_BY_ID_REQUEST,
      payload,
      callback,
   };
}

export function getModelPortfoliosByIdFetch(payload) {
   return {
      type: ActionTypes.GET_MODEL_PORTFOLIOS_BY_ID_FETCH,
      payload,
   };
}

export function getModelPortfoliosByIdSuccess(payload) {
   return {
      type: ActionTypes.GET_MODEL_PORTFOLIOS_BY_ID_SUCCESS,
      payload,
   };
}

/* GET CURRENT PORTFOLIOS */

export function getCurrentPortfolioRequest(payload, callback) {
   return {
      type: ActionTypes.GET_CURRENT_PORTFOLIO_REQUEST,
      payload,
      callback,
   };
}

export function getCurrentPortfolioFetch(payload) {
   return {
      type: ActionTypes.GET_CURRENT_PORTFOLIO_FETCH,
      payload,
   };
}

export function getCurrentPortfolioSuccess(payload) {
   return {
      type: ActionTypes.GET_MY_CURRENT_PORTFOLIO_SUCCESS,
      payload,
   };
}

export function getAllTickers(payload) {
   return {
      type: ActionTypes.GET_ALL_TICKER,
      payload,
   };
}

export function saveAllTickersData(payload) {
   return {
      type: ActionTypes.SAVE_ALL_TICKER,
      payload,
   };
}

export function setReplacementTicker(payload) {
   return {
      type: ActionTypes.SET_REPLACEMENT_TICKER,
      payload,
   };
}

export function saveReplacementTicker(payload) {
   return {
      type: ActionTypes.SAVE_REPLACEMENT_TICKER,
      payload,
   };
}

export function downloadEnhancerReport(payload) {
   return {
      type: ActionTypes.DOWNLOAD_ENHANCER_REPORT,
      payload,
   };
}

export function downloadCurrentPortfolioReport(payload) {
   return {
      type: ActionTypes.DOWNLOAD_CURRENT_PORTFOLIO_REPORT,
      payload,
   };
}

export function downloadRecommendationEnhancerReport(payload) {
   return {
      type: ActionTypes.DOWNLOAD_RECOMMENDATION_ENHANCER_REPORT,
      payload,
   };
}

export function setEnhancedTransaction(payload) {
   return {
      type: ActionTypes.SET_ENHANCED_TRANSACTIONS_TRADE,
      payload,
   };
}

export function saveEnhancedTransactionData(payload) {
   return {
      type: ActionTypes.SAVE_ENHANCED_TRANSACTIONS_TRADE,
      payload,
   };
}

/* GET CURRENT PORTFOLIOS */

export function replaceCurrentModelPortfolioWithRequest(payload, callback) {
   return {
      type: ActionTypes.REPLACE_CURRENT_MODEL_PORTFOLIO_REQUEST,
      payload,
      callback,
   };
}

export function replaceCurrentModelPortfolioWithFetch(payload, callback) {
   return {
      type: ActionTypes.REPLACE_CURRENT_MODEL_PORTFOLIO_FETCH,
      payload,
      callback,
   };
}

export function replaceCurrentModelPortfolioWithSuccess(payload, callback) {
   return {
      type: ActionTypes.REPLACE_CURRENT_MODEL_PORTFOLIO_SUCCESS,
      payload,
      callback,
   };
}
/* DELETE SPECIFY REPLACEMENT */

export function deleteSpecifyReplacement(payload, callback) {
   return {
      type: ActionTypes.DELETE_SPECIFY_REPLACEMENT,
      payload,
      callback,
   };
}

/* MODEL PORTFOLIO ENHANCER */

export function setModelEnhancementData(payload) {
   return {
      type: ActionTypes.SET_MODEL_PORTFOLIO_ENHANCEMENT_DATA,
      payload,
   };
}

/* DELETE PORTFOLIO BY ID */

export function deletePortfolioByIdRequest(payload, callback) {
   return {
      type: ActionTypes.DELETE_PORTFOLIO_BY_ID_REQUEST,
      payload,
      callback,
   };
}

export function deletePortfolioByIdFetch(payload) {
   return {
      type: ActionTypes.DELETE_PORTFOLIO_BY_ID_FETCH,
      payload,
   };
}

export function deletePortfolioByIdSuccess(payload) {
   return {
      type: ActionTypes.DELETE_PORTFOLIO_BY_ID_SUCCESS,
      payload,
   };
}

/* ---- RESET_FINALIZEDATA_STATE ---- */

export function resetFinalizeDataRequest(payload, callback) {
   return {
      type: ActionTypes.RESET_FINALIZEDATA_STATE_REQUEST,
      payload,
      callback,
   };
}

export function resetFinalizeDataFetch(payload) {
   return {
      type: ActionTypes.RESET_FINALIZEDATA_STATE_FETCH,
      payload,
   };
}

export function resetFinalizeDataSuccess(payload) {
   return {
      type: ActionTypes.RESET_FINALIZEDATA_STATE_SUCCESS,
      payload,
   };
}

/* UPDATE EVALUATE ALL FUNDS TICKERS CHECK/UNCHECK STATE */
export function updateEvaluateCheckboxRequest(payload, callback) {
   return {
      type: ActionTypes.UPDATE_EVALUATE_FILTER_REQUEST,
      payload,
      callback,
   };
}

export function updateEvaluateCheckboxFetch(payload) {
   return {
      type: ActionTypes.UPDATE_EVALUATE_FILTER_FETCH,
      payload,
   };
}

export function updateEvaluateCheckboxSuccess(payload) {
   return {
      type: ActionTypes.UPDATE_EVALUATE_FILTER_SUCCESS,
      payload,
   };
}

export function getMPDetailsCustomReplaceRequest(payload) {
   return {
      type: ActionTypes.GET_MP_DETAILS_CUSTOM_REPLACE_REQUEST,
      payload,
   };
}
export function getMPDetailsCustomReplaceFetch(payload) {
   return {
      type: ActionTypes.GET_MP_DETAILS_CUSTOM_REPLACE_FETCH,
      payload,
   };
}
export function getMPDetailsCustomReplaceSuccess(payload) {
   return {
      type: ActionTypes.GET_MP_DETAILS_CUSTOM_REPLACE_SUCCESS,
      payload,
   };
}

export function saveMPDetailsCustomReplace(payload) {
   return {
      type: ActionTypes.SAVE_MP_DETAILS_CUSTOM_REPLACE,
      payload,
   };
}

export function removeCustomMPSelection() {
   return {
      type: ActionTypes.REMOVE_CUSTOM_MP_SELECTION,
   };
}

// DESELECT ALL SELECTED ENHANCER FUNDS
export function deselectAllEnhancerFunds() {
   return {
      type: ActionTypes.DESELECT_ALL_ENHANCER_FUNDS,
   };
}

// USE SUGGESTED FUNDS FOR REPLACE
export function usingSuggestedFundsRequest(payload) {
   return {
      type: ActionTypes.USE_SUGGESTED_FUNDS_REQUEST,
      payload,
   };
}

export function usingSuggestedFundsFetch(payload) {
   return {
      type: ActionTypes.USE_SUGGESTED_FUNDS_FETCH,
      payload,
   };
}

export function usingSuggestedFundsSuccess(payload) {
   return {
      type: ActionTypes.USE_SUGGESTED_FUNDS_SUCCESS,
      payload,
   };
}

/* ---- UPLOAD PORTFOLIO DATA BY ID from onBoardScreen ---- */

export function uploadPortfolioDataByIdRequest(payload, callback) {
   return {
      type: ActionTypes.UPLOAD_PORTFOLIO_DATA_BY_ID_REQUEST,
      payload,
      callback,
   };
}
export function uploadPortfolioDataByIdFetch(payload) {
   return {
      type: ActionTypes.UPLOAD_PORTFOLIO_DATA_BY_ID_FETCH,
      payload,
   };
}
export function uploadPortfolioDataByIdSuccess(payload) {
   return {
      type: ActionTypes.UPLOAD_PORTFOLIO_DATA_BY_ID_SUCCESS,
      payload,
   };
}

/* ---- SET ENHANCER UNIVERSE ---- */

export function setEnhancerUniverse(payload, callback) {
   return {
      type: ActionTypes.SET_ENHANCER_UNIVERSE,
      payload,
      callback,
   };
}

/* ---- RESET ENHANCER UNIVERSE ---- */

export function resetEnhancerUniverse(payload, callback) {
   return {
      type: ActionTypes.RESET_ENHANCER_UNIVERSE,
      payload,
      callback,
   };
}

/* ---- SET ENHANCER FUNDS UNIVERSE ---- */

export function setEnhancerFundsUniverse(payload, callback) {
   return {
      type: ActionTypes.SET_ENHANCER_FUNDS_UNIVERSE,
      payload,
      callback,
   };
}

/* ---- RESET ENHANCER FUNDS UNIVERSE ---- */

export function resetEnhancerFundsUniverse(payload, callback) {
   return {
      type: ActionTypes.RESET_ENHANCER_FUNDS_UNIVERSE,
      payload,
      callback,
   };
}

/* ---- SET ENHANCER MODELS UNIVERSE ---- */

export function setEnhancerModelsUniverse(payload, callback) {
   return {
      type: ActionTypes.SET_ENHANCER_MODELS_UNIVERSE,
      payload,
      callback,
   };
}

/* ---- RESET ENHANCER MODELS UNIVERSE ---- */

export function resetEnhancerModelsUniverse(payload, callback) {
   return {
      type: ActionTypes.RESET_ENHANCER_MODELS_UNIVERSE,
      payload,
      callback,
   };
}

/* ---- fetch totum advisor when adisor_id is missing in profile api ---- */

export function checkTotumAdvisorRequest(payload, callback) {
   return {
      type: ActionTypes.CHECK_TOTUM_ADVISOR_REQUEST,
      payload,
      callback,
   };
}

export function checkTotumAdvisorFetch(payload) {
   return {
      type: ActionTypes.CHECK_TOTUM_ADVISOR_FETCH,
      payload,
   };
}

export function checkTotumAdvisorSuccess(payload) {
   return {
      type: ActionTypes.CHECK_TOTUM_ADVISOR_SUCCESS,
      payload,
   };
}

/* ---- submit totum data ---- */

export function submitTotumAdvisorRequest(payload, callback) {
   return {
      type: ActionTypes.SUBMIT_TOTUM_ADVISOR_REQUEST,
      payload,
      callback,
   };
}

export function submitTotumAdvisorFetch(payload) {
   return {
      type: ActionTypes.SUBMIT_TOTUM_ADVISOR_FETCH,
      payload,
   };
}

export function submitTotumAdvisorSuccess(payload) {
   return {
      type: ActionTypes.SUBMIT_TOTUM_ADVISOR_SUCCESS,
      payload,
   };
}

/* ---- TOTUM_SCORE_RESET ---- */

export function resetTotumScore(payload) {
   return {
      type: ActionTypes.TOTUM_SCORE_RESET,
      payload,
   };
}

/* ---- GET CURRENT PORTFOLIO STATS FOR PERFORMANCE IMPACT ---- */

export function getCurrentPortfolioStatsRequest(payload, callback) {
   return {
      type: ActionTypes.GET_CURRENT_PORTFOLIO_STATS_REQUEST,
      payload,
      callback,
   };
}

export function getCurrentPortfolioStatsFetch(payload) {
   return {
      type: ActionTypes.GET_CURRENT_PORTFOLIO_STATS_FETCH,
      payload,
   };
}

export function getCurrentPortfolioStatsSuccess(payload) {
   return {
      type: ActionTypes.GET_CURRENT_PORTFOLIO_STATS_SUCCESS,
      payload,
   };
}

/* ---- GET_ENHANCED_STATS_FOR_SELECTED_TICKER ---- */
export function getEnhanceStatsForSelectedTickerRequest(payload, callback) {
   return {
      type: ActionTypes.GET_ENHANCED_STATS_FOR_SELECTED_TICKER_REQUEST,
      payload,
      callback,
   };
}

export function getEnhanceStatsForSelectedTickerFetch(payload) {
   return {
      type: ActionTypes.GET_ENHANCED_STATS_FOR_SELECTED_TICKER_FETCH,
      payload,
   };
}

export function getEnhanceStatsForSelectedTickerSuccess(payload) {
   return {
      type: ActionTypes.GET_ENHANCED_STATS_FOR_SELECTED_TICKER_SUCCESS,
      payload,
   };
}

/* ---- RESEND_TOTUM_INVITE_EMAIL ---- */
export function resendTotumInviteRequest(payload, callback) {
   return {
      type: ActionTypes.RESEND_TOTUM_INVITE_EMAIL_REQUEST,
      payload,
      callback,
   };
}

export function resendTotumInviteFetch(payload) {
   return {
      type: ActionTypes.RESEND_TOTUM_INVITE_EMAIL_FETCH,
      payload,
   };
}

export function resendTotumInviteSuccess(payload) {
   return {
      type: ActionTypes.RESEND_TOTUM_INVITE_EMAIL_SUCCESS,
      payload,
   };
}

/* ---- GET_CURRENT_PORTFOLIO_SCORE totum slider ---- */

export function getCurrentPortfolioScoreRequest(payload, callback) {
   return {
      type: ActionTypes.GET_CURRENT_PORTFOLIO_SCORE_REQUEST,
      payload,
      callback,
   };
}

export function getCurrentPortfolioScoreFetch(payload) {
   return {
      type: ActionTypes.GET_CURRENT_PORTFOLIO_SCORE_FETCH,
      payload,
   };
}

export function getCurrentPortfolioScoreSuccess(payload) {
   return {
      type: ActionTypes.GET_CURRENT_PORTFOLIO_SCORE_SUCCESS,
      payload,
   };
}

/* ---- GET_CURRENT_PORTFOLIO_SCORE totum slider ---- */

export function getEnhancedPortfolioScoreRequest(payload, callback) {
   return {
      type: ActionTypes.GET_ENHANCED_PORTFOLIO_SCORE_REQUEST,
      payload,
      callback,
   };
}

export function getEnhancedPortfolioScoreFetch(payload) {
   return {
      type: ActionTypes.GET_ENHANCED_PORTFOLIO_SCORE_FETCH,
      payload,
   };
}

export function getEnhancedPortfolioScoreSuccess(payload) {
   return {
      type: ActionTypes.GET_ENHANCED_PORTFOLIO_SCORE_SUCCESS,
      payload,
   };
}

/* ---- GET_TICKER_REAL_TIME_PRICE_REQUEST totum slider ---- */

export function getTickerRealTimePriceRequest(payload, callback) {
   return {
      type: ActionTypes.GET_TICKER_REAL_TIME_PRICE_REQUEST,
      payload,
      callback,
   };
}

export function getTickerRealTimePriceFetch(payload) {
   return {
      type: ActionTypes.GET_TICKER_REAL_TIME_PRICE_FETCH,
      payload,
   };
}

export function getTickerRealTimePriceSuccess(payload) {
   return {
      type: ActionTypes.GET_TICKER_REAL_TIME_PRICE_SUCCESS,
      payload,
   };
}

/* TOTUM_QUESTIONNAIRE_STATUS */

export function getTotumQuestionnaireStatusRequest(payload, callback) {
   return {
      type: ActionTypes.TOTUM_QUESTIONNAIRE_STATUS_REQUEST,
      payload,
      callback,
   };
}

export function getTotumQuestionnaireStatusFetch(payload) {
   return {
      type: ActionTypes.TOTUM_QUESTIONNAIRE_STATUS_FETCH,
      payload,
   };
}

export function getTotumQuestionnaireStatusSuccess(payload) {
   return {
      type: ActionTypes.TOTUM_QUESTIONNAIRE_STATUS_SUCCESS,
      payload,
   };
}

/* UPLOAD PORTFOLIO CSV */

export function uploadPortfolioCSVRequest(payload, callback) {
   return {
      type: ActionTypes.UPLOAD_PORTFOLIO_CSV_REQUEST,
      payload,
      callback,
   };
}

export function uploadPortfolioCSVFetch(payload) {
   return {
      type: ActionTypes.UPLOAD_PORTFOLIO_CSV_FETCH,
      payload,
   };
}

export function uploadPortfolioCSVSuccess(payload) {
   return {
      type: ActionTypes.UPLOAD_PORTFOLIO_CSV_SUCCESS,
      payload,
   };
}

/* UPDATE CLIENT ACCOUNT NAME  */

export function updateClientAccountNameRequest(For, payload, callback) {
   return {
      type: ActionTypes.UPDATE_CLIENT_ACCOUNT_NAME_REQUEST,
      For,
      payload,
      callback,
   };
}

export function updateClientAccountNameFetch(payload) {
   return {
      type: ActionTypes.UPDATE_CLIENT_ACCOUNT_NAME_FETCH,
      payload,
   };
}

export function updateClientAccountNameSuccess(payload) {
   return {
      type: ActionTypes.UPDATE_CLIENT_ACCOUNT_NAME_SUCCESS,
      payload,
   };
}
