import zipObject from "lodash/zipObject";

const actions = [
   "DYM_LOGS",
   "UPDATE_DISCOVERY_DATA",
   "SEARCH_QUERY",
   "REFRESH_DISCOVERY_STATE",
   "SET_DISCOVERY_STATE",
   "DISCOVERY_DATA",
   "DISCOVERY_QUERY",
   "SET_UNIVERSE",
   "SEARCH_ANALYTICS_API",
   "SET_THEME_VALUE",
   "SET_SPONSRED_VALUE",
   "RESET_DISCOVER",
   "UPDATE_UNIVERSE_BY_REGION",

   "TRENDING_QUERIES_REQUEST",
   "TRENDING_QUERIES_FETCH",
   "TRENDING_QUERIES_SUCCESS",

   "GET_CHART_DATA_FOR_ALL_DISCOVERY_FUNDS",
   "UPDATE_SEL_FUNDS_IN_CRD",
   "UPDATE_CHART_REPORT_DATA",

   "DOWNLOAD_SEARCHED_RESULTS_REQUEST",
   "DOWNLOAD_SEARCHED_RESULTS_FETCH",
   "DOWNLOAD_SEARCHED_RESULTS_SUCCESS",

   "SELECTOR_WITH_TIME_FRAME_REQUEST",
   "SELECTOR_WITH_TIME_FRAME_FETCH",
   "SELECTOR_WITH_TIME_FRAME_SUCCESS",

   "UPDATE_FUNDS_SELECTOR_STATE",
   "REMOVE_FUNDS_SELECTOR_STATE",
   "REMOVE_ALL_FUNDS_SELECTOR_STATE",

   "RESET_FUNDS_UNIVERSE_STATE",

   "SAVE_POPOVER_OPTION",

   "SEARCH_QUERY_REQUEST",
   "SEARCH_QUERY_FETCH",
   "SEARCH_QUERY_SUCCESS",

   "SET_DISCOVERY_STATE",

   "GET_SELECTORS_DATA_REQUEST",
   "GET_SELECTORS_DATA_FETCH",
   "GET_SELECTORS_DATA_SUCCESS",

   "SET_DISCOVERY_FUND_SELECTION_TYPE",

   "GET_FUND_DATA_FOR_DRAWER_REQUEST",
   "GET_FUND_DATA_FOR_DRAWER_FETCH",
   "GET_FUND_DATA_FOR_DRAWER_SUCCESS",
   "GET_SIMILAR_SEARCH_LIST_REQUEST",
   "GET_SIMILAR_SEARCH_LIST_FETCH",
   "GET_SIMILAR_SEARCH_LIST_SUCCESS",

   "GET_TRENDING_SEARCH_THEME_REQUEST",
   "GET_TRENDING_SEARCH_THEME_FETCH",
   "GET_TRENDING_SEARCH_THEME_ERROR",
   "GET_TRENDING_SEARCH_THEME_SUCCESS",
];

export default zipObject(actions, actions);
