/* eslint-disable array-callback-return */
import { takeEvery, fork, call, put, all, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import { push } from "connected-react-router";
import moment from "moment";
import get from "lodash/get";
import { message } from "antd";
import _ from "lodash";
import {getAssetSponsorOptRequest} from "actions/community";

import ActionTypes from "action-types";

import {
   setEnhancerState,
   getClientListRequest,
   getClientListFetch,
   getClientListSuccess,
   getPortfolioListRequest,
   getPortfolioListFetch,
   getPortfolioListSuccess,
   getModelsListFetch,
   getModelsListSuccess,
   loadCPMListFetch,
   loadCPMListSuccess,
   uploadCSVFetch,
   uploadCSVSuccess,
   getPortfolioDataByIDRequest,
   getPortfolioDataByIDFetch,
   getPortfolioDataByIDSuccess,
   verifyCSVTickerAndPriceFetch,
   verifyCSVTickerAndPriceSuccess,
   checkTickerFetch,
   checkTickerSuccess,
   // resetSummaryDataFetch,
   resetSummaryDataSuccess,
   checkSingleTickerValidityFetch,
   checkSingleTickerValiditySuccess,
   getFundsPortfolioBatchFetch,
   getFundsPortfolioBatchSuccess,
   resetFundsPortfolioFetch,
   resetFundsPortfolioSuccess,
   addFundForEnhanceFetch,
   addFundForEnhanceSuccess,
   deleteFundForEnhanceFetch,
   deleteFundForEnhanceSuccess,
   finalizePortfolioFetch,
   finalizePortfolioSuccess,
   savePortfolioFetch,
   savePortfolioSuccess,
   clearFinalizeDataFetch,
   clearFinalizeDataSuccess,
   // clearFundPortfolioState,
   getModelPortfoliosByIdFetch,
   getModelPortfoliosByIdSuccess,
   saveEnhancedTransactionData,
   finalizeModelPortfolioFetch,
   finalizeModelPortfolioSuccess,
   getTickerDataFetch,
   getTickerDataSuccess,
   deletePortfolioByIdFetch,
   deletePortfolioByIdSuccess,
   replaceCurrentModelPortfolioWithFetch,
   replaceCurrentModelPortfolioWithSuccess,
   updateEvaluateCheckboxFetch,
   updateEvaluateCheckboxSuccess,
   saveMPDetailsCustomReplace,
   getMPDetailsCustomReplaceFetch,
   getMPDetailsCustomReplaceSuccess,
   resetFinalizeDataFetch,
   resetFinalizeDataSuccess,
   usingSuggestedFundsFetch,
   usingSuggestedFundsSuccess,
   uploadPortfolioDataByIdFetch,
   uploadPortfolioDataByIdSuccess,
   clientDeleteFetch,
   // clientDeleteRequest,
   clientDeleteSuccess,
   checkTotumAdvisorFetch,
   checkTotumAdvisorSuccess,
   submitTotumAdvisorFetch,
   submitTotumAdvisorSuccess,
   getCurrentPortfolioStatsRequest,
   getCurrentPortfolioStatsFetch,
   getCurrentPortfolioStatsSuccess,
   getEnhanceStatsForSelectedTickerRequest,
   getEnhanceStatsForSelectedTickerFetch,
   getEnhanceStatsForSelectedTickerSuccess,
   resendTotumInviteFetch,
   resendTotumInviteSuccess,
   // resetTotumScore,
   getCurrentPortfolioScoreFetch,
   getCurrentPortfolioScoreSuccess,
   getEnhancedPortfolioScoreFetch,
   getEnhancedPortfolioScoreSuccess,
   // getTotumQuestionnaireStatusRequest,
   getTotumQuestionnaireStatusFetch,
   getTotumQuestionnaireStatusSuccess,
   getTickerRealTimePriceFetch,
   getTickerRealTimePriceSuccess,
   // getCurrentPortfolioScoreRequest,
   // getEnhancedPortfolioScoreRequest,
   uploadPortfolioCSVFetch,
   uploadPortfolioCSVSuccess,
   updateClientAccountNameFetch,
   updateClientAccountNameSuccess,
   // retryAndLoadTargetCardDataFetch,
   // retryAndLoadTargetCardDataSuccess,
} from "actions/enhancer";
import { selectPortfolioToLoad } from "actions/community";
import { accountPageClientListRequest } from "actions/clientportfolioV2";

import { deleteSelectedClients } from "apis/clientportfolioV2";

// import { setSFMCatData } from 'actions/sfm'

import { saveAllTickersData } from "actions/enhancer";
import { getSFMItems } from "selectors/sfm";
import { getEnhancerState } from "selectors/enhancer";
// import { getAllPortfolioStates } from 'selectors/portfolio'
import { getPortfolioSelectedBenchmark } from "../selectors/investment";

import {
   fetchAccountSummary,
   // fetchAccountPositions,
   fetchPortfolioList,
   fetchMarketPlaceList,
   getMarketResultAPI,
   uploadPortfolioAPI,
   getPortfolioResultAPI,
   deletePortfolioAPI,
   // downloadPortfolioReportPDF,
   // downloadRecEnhancerReportPDF,
   // demoPortfolioAPI,
   // getHomeOfficeModelsData
} from "apis/account";
import {
   // fetchReturnsData,
   fetchReturnsDataV1,
   // trackFundsRemove,
   fetchRealTimePrices,
   fetchRealTimePricesNav,
   fetchUpAndDownChart,
} from "apis/funds";
import {
   checkTickerExist,
   getBatchSearch,
   // portfolioEnhanceTranssApi,
   setPortfolioOptsApi,
   setProposalPortfolioApi,
   getModelPortfolio,
} from "apis/portfolio";
import {
   getEnhancedCardAPI,
   getAllTickersAPI,
   downloadEnhancerReportPDFAPI,
   downloadRecEnhancerReportPDFAPI,
   portfolioEnhanceTransAPI,
   finalizeModelPortfolioAPI, // wrong api
   getMPDetailsCustomReplaceAPI,
   addTotumAdvisorApi,
   sendEmailAdvisorApi,
   portfolioScoreApi,
   getPortfolioStats,
   getStatsForSelectedTicker,
   resendInviteEmail,
   uploadPortfolioFile,
   getClientList,
   updateClientAccountName,
} from "apis/enhancerAPIs";
import {
   fetchModelPfHoldingsData,
   fetchClientPortfolioHoldingsById,
} from "apis/clientportfolioV2";

import {
   // uniqValues,
   getNestDefault,
   nameToDateRange,
   arrayToHash,
   getPortfolioBenchmarkOfSelectedTicker,
   convertToUpperCase,
   addEventToAnalytics,
} from "Utils";
import {
   // DateRange,
   cn,
   CN,
   // PORTFOLIO_CLASS_MAP,
   QUERY_MAP,
   // REVERSE_MODEL_PORTFOOLIO_QUERY_MAP,
   TO_MODEL_QUERY,
} from "DataSet";
import { calcCumulativeReturns } from "Stats";
import {
   accountsPageUpdatePrice,
   // statsForPerformanceImpact,
   replicaDataMapForEnhancer,
   replicaStatsForPerformanceImpact,
   // getFRRAttrDataForModels,
   prepareFundObject,
   getOdometerChart,
   portfolioDataToCSVUploadPortfolioFormat,
   // checkTimeDiff,
   openNotificationWithIcon,
   isBurstChartDataValid,
   // burstToPieChart,
} from "layouts/utils";
import {
   getCurrentPfStats,
   getCalculatedModelPortfolioEnhanceStats,
   getCalculatedEnhanceStats,
   getUploadedPortfolioStats,
} from "layouts/WebDashboard/AccountsPage/utils";
// import { upDownData } from 'DummyData'
import {
   // enableEnhanceBtnControlState,
   // finalizeReplaceModelPortfolioFetch,
   // finalizeReplaceModelPortfolioSuccess,
   // resetModelEnhancePortfoliosState,
   // setCachingForPortfolio,
   setPortfolioState,
} from "actions/portfolio";
// import { fetchPortfolioRequest } from 'actions/buildportfolio'

// import { clientListRequest } from '../actions/clientportfolioV2'

const FileSaver = require("file-saver");

// Portfolio Section
export function* uploadPortfolio(data) {
   const { payload, callback } = data;
   try {
      if (payload && payload.data) {
         const { data } = payload;
         let weight_calculated_format;
         // console.log(data);
         const updatedPayloadData = data.map(val => {
            let obj = Object.assign({}, val);
            if ("key" in obj) {
               delete obj.key;
            }
            if ("weight(%)" in obj) {
               delete obj["weight(%)"];
            }
            if ("loading" in obj) {
               delete obj.loading;
            }
            if ("message" in obj) {
               delete obj.message;
            }
            if ("showInMissingTicker" in obj) {
               delete obj.showInMissingTicker;
            }
            if ("oldTicker" in obj) {
               delete obj.oldTicker;
            }
            if ("message" in obj) {
               delete obj.message;
            }
            if ("type" in obj) {
               delete obj.type;
            }
            // 'date'
            if ("date" in obj) {
               delete obj.date;
            }
            if ("realTimePrice" in obj) {
               obj.realTimePrice =
                  obj.realTimePrice === "NA" ? 0 : Number(obj.realTimePrice);
            }
            if ("price" in obj) {
               obj.price = obj.price === "NA" ? 0 : Number(obj.price);
            }
            if ("shares" in obj) {
               obj.shares =
                  obj.shares === "NA" ||
                  obj.shares === null ||
                  obj.shares === undefined
                     ? 0
                     : Number(obj.shares);
            }
            // for incorect-ticker-case
            if (
               typeof obj.values === "undefined" ||
               obj.values === "NA" ||
               obj.values === null ||
               obj.values === undefined
            ) {
               obj.values = 0;
            }
            if ("validTicker" in obj) {
               obj.invalid = !obj.validTicker;
               delete obj.validTicker;
            }
            if ("invalidType" in obj) {
               obj.invalid_type = obj.invalidType;
               delete obj.invalidType;
            }
            if ("weight_calculated_format" in obj) {
               weight_calculated_format = obj.weight_calculated_format;
               delete obj.weight_calculated_format;
            }
            return obj;
         });

         payload.data = updatedPayloadData.filter(data => !data.invalid);
         if (weight_calculated_format) {
            payload.weight_calculated_format = weight_calculated_format;
         }

         yield put(uploadCSVFetch(payload));

         // const updatedPayloadData = data.filter(item => item.invalid).map(val => {
         // console.log(updatedPayloadData);

         const response = yield call(uploadPortfolioAPI, payload);
         if (
            response.message === "Portfolio uploaded successfully." &&
            response.portfolio_name
         ) {
            console.log("PORTFOLIO A",payload)
            yield put(
               uploadCSVSuccess({
                  uploadfileDuplicate: false,
                  uploadfileIncomplete: false,
                  portfolioName: response.portfolio_name,
                  portfolioId: response.id,
               })
            );
            if (callback && typeof callback == "function") {
               callback(response);
            }
            yield put(
               getAssetSponsorOptRequest({
                  tickers: payload.data.map(portfolio => ({
                     ticker: portfolio.ticker,
                     sponsor: portfolio.sponsor || "",
                  })),
                  source: "portfolio",
               })
            );         } else if (response.message === "File already Exist") {
            yield put(
               setEnhancerState({
                  uploadfileDuplicate: true,
               })
            );
            openNotificationWithIcon({
               duration: 5,
               type: "error",
               message: "Failed!",
               className: "api-response-notification-class",
               description: "File already exists.",
            });
            yield put(
               uploadCSVSuccess({
                  uploadFileFailure: true,
                  uploadFileFailureMsg: response.message,
               })
            );
         } else if (
            response.status === "Fail" ||
            response.message.includes("Failed")
         ) {
            yield put(
               uploadCSVSuccess({
                  uploadFileFailure: true,
                  uploadFileFailureMsg: response.message,
               })
            );
         } else if (response.status === "Partial" && response.portfolio_name) {
            if (callback && typeof callback == "function") {
               callback();
            }
            yield put(
               uploadCSVSuccess({
                  uploadFilePartial: true,
                  uploadFilePartialMsg: response.message,
                  portfolioName: response.portfolio_name,
                  portfolioId: response.id,
               })
            );
         }
      } else {
         throw new Error("-- 'data' key is missing --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(uploadCSVSuccess());
   }
}

export function* uploadPortfolioDataById(action) {
   const { payload, callback } = action;
   try {
      const {
         filename,
         portfolioType,
         // _for,
         selectedPortfolioName,
         selectedPortfolioTicker,
         selectedPortfolioId,
      } = payload;

      if (filename && portfolioType && selectedPortfolioName) {
         yield put(uploadPortfolioDataByIdFetch(payload));

         let ticks;
         let portfolioFunds = [],
            portfolioValue = 0,
            invalidTickers = [],
            tickerNotSupported = [];

         if (filename === "Watchlist") {
            //load Watchlist Data
            let [_summary] = yield all([call(fetchAccountSummary)]);
            portfolioFunds =
               _summary && _summary.results ? _summary.results : [];
         } else if (portfolioType === "MarketPlace") {
            //load MarketPlace Data
            [portfolioFunds] = yield all([call(getMarketResultAPI, filename)]);
         } else if (portfolioType === "Client Portfolio") {
            //load Client Portfolio Data
            console.log("=== filename ===> ", filename);
            [portfolioFunds] = yield all([
               call(fetchClientPortfolioHoldingsById, { file_name: filename }),
            ]);
         } else {
            //load My Portfolio Data
            [portfolioFunds] = yield all([
               call(getPortfolioResultAPI, filename),
            ]);
         }

         if (portfolioFunds) {
            console.log("----  portfolioFunds will be ----", portfolioFunds);
            if (portfolioFunds.message && portfolioFunds.message === "Failed") {
               console.log(
                  `========= Failed to load data for ${filename} =========`
               );
               // yield put(setPortfolioState({ onBoardScreen: true }));
               // NOTE:  return back to on-BoardScreen with Failed to load message.
               yield put(push("/enhancer"));
               yield put(resetFundsPortfolioSuccess());
               yield put(
                  uploadPortfolioDataByIdSuccess({
                     summary: {
                        portfolioFunds: [],
                        pageCrash: `Failed to load data for ${filename}`,
                     },
                  })
               );
            } else if (portfolioFunds.length > 0) {
               portfolioFunds.forEach(e => {
                  e.ticker = e.ticker || e.script || e.symbol;
                  e.ticker = convertToUpperCase(e.ticker);
                  e.price = e.price || e.current_price;
                  e.transaction_date = e.transaction_date || e.purchase_date;
                  e.nav = e.nav || e.fees;
                  e.quantity = e.quantity || e.shares;
                  e.market_value = e.price * e.quantity;
                  e.original_weight =
                     Number(e.original_weight) || e.weight || 0;
                  if (!e.invalid) {
                     portfolioValue += e.price * e.quantity;
                  }
                  if (portfolioType === "Client Portfolio") {
                     // done to treat client portfolio as "Ticker+share" format
                     delete e.weight;
                     delete e.original_weight;
                  }
               });

               let ticks1 = portfolioFunds.map(
                  e => e.ticker || e.script || e.symbol
               );
               let validTickersArr = portfolioFunds
                  .filter(kl => !kl.invalid)
                  .map(e => e.ticker || e.script || e.symbol);
               invalidTickers = [
                  ...portfolioFunds
                     .filter(
                        gh => gh.invalid && gh.invalid_type === "unrecognized"
                     )
                     .map(kl => kl.ticker || kl.script || kl.symbol),
               ];
               tickerNotSupported = [
                  ...portfolioFunds
                     .filter(
                        gh => gh.invalid && gh.invalid_type === "unsupported"
                     )
                     .map(kl => kl.ticker || kl.script || kl.symbol),
               ];
               ticks = [...new Set([...ticks1])].join(",");
               // console.log(validTickersArr)

               let [realTimePrice, checkTickerExistResponse] = yield all([
                  call(fetchRealTimePricesNav, { tickers: ticks }),
                  call(checkTickerExist, { tickers: validTickersArr }),
               ]);

               if (
                  checkTickerExistResponse &&
                  checkTickerExistResponse.tickers_not_exist
               ) {
                  invalidTickers = [
                     ...invalidTickers,
                     checkTickerExistResponse.tickers_not_exist,
                  ];
                  console.log("invalidTickers ===>", invalidTickers);
               }
               if (
                  checkTickerExistResponse &&
                  checkTickerExistResponse.ticker_not_supported
               ) {
                  tickerNotSupported = [
                     ...tickerNotSupported,
                     checkTickerExistResponse.ticker_not_supported,
                  ];
                  console.log("tickerNotSupported ===>", tickerNotSupported);
               }

               const mappedDataWillBe =
                  yield portfolioDataToCSVUploadPortfolioFormat(portfolioFunds);
               console.log(mappedDataWillBe);

               const injectObject = {
                  uploadedPortfolioTicker: filename,
                  tickerNotExist: invalidTickers,
                  tickerNotSupported,
                  originalCSVFormatPortfolioTickers: ticks1,
                  originalCSVFormatPortfolioData: mappedDataWillBe,
                  onboardData: {
                     selectedPortfolioType: portfolioType,
                     selectedPortfolioPath: `onboard-screen`,
                  },
               };

               if (portfolioType === "Client Portfolio") {
                  injectObject.portfolioName = selectedPortfolioName;
                  injectObject.portfolioID = selectedPortfolioId;
                  injectObject.portfolioId = selectedPortfolioId;
                  injectObject.selectedPortfolioTicker =
                     selectedPortfolioTicker;
               } else if (portfolioType === "MarketPlace") {
                  injectObject.sponsoredPortfolioName = selectedPortfolioName;
               }

               yield put(uploadPortfolioDataByIdSuccess(injectObject));

               // NOTE: Step 2: execute `callback` function
               if (callback && typeof callback == "function") {
                  callback({
                     portfolioValue,
                     portfolioFunds,
                     realTimePrice,
                     tickers: ticks,
                     portfolioName: filename,
                     selectedPortfolioType: portfolioType,
                     csvFormatPortfolioData: mappedDataWillBe,
                  });
               }
            }
         } else {
            yield put(
               uploadPortfolioDataByIdSuccess({
                  summary: {
                     portfolioFunds: [],
                  },
               })
            );
         }
      } else {
         console.log("---- filename/selectedPortfolioTicker not present ----");
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(uploadPortfolioDataByIdSuccess());
   }
}

export function* getPortfolioDataById(action) {
   const { payload, callback } = action;
   try {
      const {
         _for,
         filename,
         portfolioType,
         allocatedModel,
         finalizedSponsorModel,
      } = payload;
      // console.log(payload);
      if (filename && portfolioType) {
         yield put(getPortfolioDataByIDFetch(payload));

         // let ticks
         // let retData = [],
         let positions = [],
            portfolioFunds = [],
            portfolioValue = 0,
            _upDownDataSet = [],
            invalidTickerFunds = [],
            tickerNotSupported = [];

         if (allocatedModel) {
            console.log(" === CLIENT PORTAL REQUEST === ");
            [portfolioFunds, _upDownDataSet] = yield all([
               call(fetchModelPfHoldingsData, { ticker: filename }),
               call(fetchUpAndDownChart),
            ]);
            portfolioFunds = portfolioFunds.data;
            console.log(" ==============================");
         } else {
            if (filename === "Watchlist") {
               //load Watchlist Data
               let [_summary] = yield all([
                  call(fetchAccountSummary),
                  // call(fetchUpAndDownChart),
               ]);
               portfolioFunds =
                  _summary && _summary.results ? _summary.results : [];
               // positions = (_positions && _positions.positions) ? _positions.positions : [];
            } else if (
               portfolioType === "MarketPlace" &&
               typeof finalizedSponsorModel === "undefined"
            ) {
               //load MarketPlace Data
               // portfolioFunds = yield call(getMarketResultAPI, filename);
               [portfolioFunds, _upDownDataSet] = yield all([
                  call(getMarketResultAPI, filename),
                  call(fetchUpAndDownChart),
               ]);
            } else {
               // portfolioFunds = yield call(getPortfolioResultAPI, filename);
               [portfolioFunds, _upDownDataSet] = yield all([
                  call(getPortfolioResultAPI, filename),
                  call(fetchUpAndDownChart),
               ]);
            }
         }

         if (portfolioFunds) {
            if (portfolioFunds.message && portfolioFunds.message === "Failed") {
               // yield put(setPortfolioState({ onBoardScreen: true }));
               // NOTE:  return back to on-BoardScreen with Failed to load message.
               yield put(push("/enhancer"));
               yield put(resetFundsPortfolioSuccess());
               yield put(
                  getPortfolioDataByIDSuccess({
                     summary: {
                        portfolioFunds: [],
                        pageCrash: `Failed to load data for ${filename}`,
                     },
                  })
               );
            } else if (portfolioFunds.length > 0) {
               _upDownDataSet.forEach(v => {
                  if (v.ticker === "0 ACWI/100 AGG") {
                     v.ticker = "AGG";
                  }
                  if (v.ticker === "100 ACWI/0 AGG") {
                     v.ticker = "ACWI";
                  }
               });
               _upDownDataSet = [
                  ..._upDownDataSet.slice(0, 2),
                  _upDownDataSet[_upDownDataSet.length - 1],
                  ..._upDownDataSet.slice(2, _upDownDataSet.length - 1),
               ];

               let ticks1 = portfolioFunds.map(
                  e => e.ticker || e.script || e.symbol
               );
               let ticks = [...new Set([...ticks1])].join(",");

               let [retData, fundsPrice] = yield all([
                  call(fetchReturnsDataV1, { tickers: ticks }),
                  call(fetchRealTimePrices, { tickers: ticks }),
               ]);

               if (fundsPrice && retData) {
                  const updatedFundsPriceArr =
                     fundsPrice.funds && fundsPrice.funds.length > 0
                        ? accountsPageUpdatePrice(
                             fundsPrice.funds,
                             portfolioFunds,
                             "script"
                          )
                        : portfolioFunds;
                  const updatedPositionsPriceArr =
                     fundsPrice.funds &&
                     fundsPrice.funds.length > 0 &&
                     positions &&
                     positions.length > 0
                        ? accountsPageUpdatePrice(
                             fundsPrice.funds,
                             positions,
                             "symbol"
                          )
                        : positions;

                  if (
                     updatedPositionsPriceArr &&
                     updatedPositionsPriceArr.funds
                  ) {
                     positions = updatedPositionsPriceArr.funds;
                  }

                  if (updatedFundsPriceArr && updatedFundsPriceArr.funds) {
                     portfolioFunds = updatedFundsPriceArr.funds;
                  }

                  retData.forEach((e, index) => {
                     if (e.returns.length > 0) {
                        let n = getNestDefault(["returns", "length"], e, 0);
                        if (n > 0) {
                           const start = moment(
                              new Date(
                                 `01 ${e.returns[e.returns.length - 1].d}`
                              )
                           );
                           const end = moment(new Date(`01 ${e.returns[0].d}`));
                           const PortfolioDateRangeMoment = { start, end };
                           e.returns.forEach((e, i) => {
                              retData[index].returns[i].v =
                                 retData[index].returns[i].v / 100;
                              return (e.v = +e.v);
                           });
                           e._start = e.returns[0]["d"];
                           e._end = e.returns[n - 1]["d"];
                           let stats = calcCumulativeReturns(
                              e.returns,
                              nameToDateRange("l3y", PortfolioDateRangeMoment)
                           );
                           e._series = stats.series;
                           e._selected = false;
                           e._portfolioFund = true;
                        }
                     }
                  });
                  const reducerSfmItems = yield select(getSFMItems);
                  let tickerWiseRet = arrayToHash(retData, CN["Ticker"]);
                  let purchaseValuesCheck = [];

                  portfolioFunds.forEach(e => {
                     e._portfolioFund = true;
                     e.ticker = e.ticker || e.script || e.symbol;
                     e.price = e.price || e.current_price;
                     e.transaction_date = e.transaction_date || e.purchase_date;
                     e.nav = e.nav || e.fees;
                     e._realTimePrice = e.price; // realTimePrice mapped to 'price' key by executing 'accountsPageUpdatePrice' func
                     e.quantity = e.quantity || e.shares;
                     e.market_value = e.price * e.quantity;
                     e.return = tickerWiseRet[e.ticker];
                     e.original_weight =
                        Number(e.original_weight) || e.weight || 0;

                     if (!e.invalid) {
                        portfolioValue += e.price * e.quantity;
                     }

                     if (e.purchase_price == null && e.purchase_date == null)
                        purchaseValuesCheck.push(e.script);

                     let i = reducerSfmItems.findIndex(
                        s => cn(s, "Ticker") === cn(e, "Ticker")
                     );
                     if (i >= 0) {
                        e._selected = true;
                     }
                  });
                  const _portfolioFund = JSON.parse(
                     JSON.stringify(portfolioFunds)
                  );
                  tickerNotSupported = _portfolioFund.filter(
                     gh => gh.invalid && gh.invalid_type === "unsupported"
                  );
                  invalidTickerFunds = _portfolioFund.filter(
                     kl => kl.invalid && kl.invalid_type === "unrecognized"
                  );
                  portfolioFunds = _portfolioFund.filter(gh => !gh.invalid);

                  // when api gives 'validTickers' key in each fund's data then use below line
                  // portfolioFunds = portfolioFunds.filter((kl) => kl.invalid);

                  ticks = portfolioFunds.map(e => e.ticker);

                  const checkPurchaseValues =
                     purchaseValuesCheck.length === portfolioFunds.length;
                  const onboardData = yield select(
                     state => state.enhancer.onboardData
                  );
                  const activeFund =
                     portfolioFunds.length > 0
                        ? portfolioFunds[0].ticker || portfolioFunds[0].symbol
                        : "";

                  // NOTE: Step 1: execute getPortfolioDataByIDSuccess

                  // _for can be 'FINALIZE' || '' || undefined
                  if (_for === "FINALIZE") {
                     // preserve current state, DO NOT DELETE BELOW LOGIC
                     const currentState = yield select(state => state.enhancer);
                     // console.log(currentState);
                     yield put(
                        getPortfolioDataByIDSuccess({
                           portfolioName: currentState.portfolioName,
                           portfolioValue: currentState.portfolioValue,
                           tickers: currentState.tickers,
                           onboardData: currentState.onboardData,
                           summary: currentState.summary,
                        })
                     );
                     // NOTE: Step 2: execute `callback` function
                     if (callback && typeof callback == "function") {
                        callback({
                           portfolioName: filename,
                           portfolioValue,
                           tickers: ticks,
                           onboardData: {
                              ...onboardData,
                              selectedPortfolioType: portfolioType,
                           },
                           summary: {
                              portfolioFunds,
                              invalidTickerFunds,
                              tickerNotSupported,
                              retData,
                              activeFund,
                              realTimePrice: fundsPrice,
                              checkPurchaseValues,
                              portfolioTickers: ticks,
                              portfolioRetData: retData[0].returns,
                           },
                           finalizePerformanceImpact:
                              currentState.performanceImpact,
                        });
                     }
                  } else if (_for === "REDIRECTING") {
                     // NOTE: FOR REDIRECTING FORM 'CLIENT PORTFOLIO || BUILDER PAGE TO enhancer/dashboard/funds page'
                     // NOTE: DO NOT CHANGE BELOW CODE AND ORDERING OF CALLING ACTIONS AND CALLBACK FUNCTION

                     const enhancerState = yield select(getEnhancerState);
                     let selectedBenchmark =
                        enhancerState.performanceImpact.selectedBenchmark ||
                        "SPY";
                     let portfolioBenchmark =
                        getPortfolioBenchmarkOfSelectedTicker(
                           _upDownDataSet,
                           selectedBenchmark
                        );
                     const currentStats = yield getCurrentPfStats(
                        portfolioFunds,
                        "",
                        portfolioBenchmark,
                        invalidTickerFunds,
                        tickerNotSupported
                     );

                     console.log(
                        "========== FOR REDIRECTING -> PLOTTING PERFORMANCE IMPACT DATA =========="
                     );

                     if (currentStats) {
                        yield put(
                           setEnhancerState({
                              performanceImpact: {
                                 ...enhancerState.performanceImpact,
                                 selectedBenchmark,
                                 upDownDataSet: _upDownDataSet,
                                 currentPerformanceImpact: currentStats,
                              },
                           })
                        );
                     }

                     // NOTE: Step 2: execute `callback` function
                     if (callback && typeof callback == "function") {
                        callback({
                           portfolioName: filename,
                           portfolioValue,
                           tickers: ticks,
                           onboardData: {
                              ...onboardData,
                              selectedPortfolioType: portfolioType,
                           },
                           summary: {
                              portfolioFunds,
                              invalidTickerFunds,
                              tickerNotSupported,
                              retData,
                              activeFund,
                              realTimePrice: fundsPrice,
                              checkPurchaseValues,
                              portfolioTickers: ticks,
                              portfolioRetData: retData[0].returns,
                           },
                        });
                     }
                     yield put(
                        getPortfolioDataByIDSuccess({
                           portfolioName: filename,
                           portfolioValue,
                           tickers: ticks,
                           onboardData: {
                              ...onboardData,
                              selectedPortfolioType: portfolioType,
                           },
                           summary: {
                              portfolioFunds,
                              invalidTickerFunds,
                              tickerNotSupported,
                              retData,
                              activeFund,
                              realTimePrice: fundsPrice,
                              checkPurchaseValues,
                              portfolioTickers: ticks,
                              portfolioRetData: retData[0].returns,
                           },
                        })
                     );
                  } else {
                     // NOTE: This 'else' will execute when summary page load with correct URL (enhancer/dashboard/summary?portfolioType=My%20Portfolio&portfolioId=MyPortfolio)
                     const _enState = yield select(getEnhancerState);
                     yield put(
                        getPortfolioDataByIDSuccess({
                           portfolioName: filename,
                           portfolioValue,
                           tickers: ticks,
                           onboardData: {
                              ...onboardData,
                              selectedPortfolioType: portfolioType,
                           },
                           summary: {
                              portfolioFunds,
                              invalidTickerFunds,
                              tickerNotSupported,
                              retData,
                              activeFund,
                              realTimePrice: fundsPrice,
                              checkPurchaseValues,
                              portfolioTickers: ticks,
                              portfolioRetData: retData[0].returns,
                           },

                           // NOTE:  Had to reset 'enhancedPerformanceImpact' for fresh load,
                           // (when routing back-forth from summary-funds page, somehow enhanceSTATS remains in performanceImpact)
                           performanceImpact: {
                              ..._enState.performanceImpact,
                              enhancedPerformanceImpact: {},
                           },
                        })
                     );
                     // NOTE: Step 2: execute `callback` function
                     if (callback && typeof callback == "function") {
                        callback({
                           portfolioName: filename,
                           portfolioValue,
                           tickers: ticks,
                           onboardData: {
                              ...onboardData,
                              selectedPortfolioType: portfolioType,
                           },
                           summary: {
                              portfolioFunds,
                              invalidTickerFunds,
                              tickerNotSupported,
                              retData,
                              activeFund,
                              realTimePrice: fundsPrice,
                              checkPurchaseValues,
                              portfolioTickers: ticks,
                              portfolioRetData: retData[0].returns,
                           },
                           performanceImpact: {
                              ..._enState.performanceImpact,
                              enhancedPerformanceImpact: {},
                           },
                        });
                     }
                  }

                  yield call(delay, 600);

                  const enhancerState = yield select(getEnhancerState);
                  let selectedBenchmark =
                     enhancerState.performanceImpact.selectedBenchmark || "SPY";
                  let portfolioBenchmark =
                     getPortfolioBenchmarkOfSelectedTicker(
                        _upDownDataSet,
                        selectedBenchmark
                     );
                  let currentStats = yield getCurrentPfStats(
                     portfolioFunds,
                     "",
                     portfolioBenchmark
                  );

                  console.log(
                     "========== PLOTTING PERFORMANCE IMPACT DATA =========="
                  );

                  let portfolio_type =
                     portfolioType === "MarketPlace"
                        ? "model_portfolio"
                        : portfolioType === "Client Portfolio"
                        ? "custom_portfolio"
                        : "custom_portfolio";
                  // portfolio_type = 'custom_portfolio'; // api crashing so using 'custom_portfolio' for all type

                  yield put(
                     setEnhancerState({
                        performanceImpact: {
                           ...enhancerState.performanceImpact,
                           selectedBenchmark,
                           upDownDataSet: _upDownDataSet,
                        },
                     })
                  );

                  // get-target-portfolio api data for performance-impact
                  // do-not call api after 'FINALIZE' scenario
                  if (_for !== "FINALIZE") {
                     yield put(
                        getCurrentPortfolioStatsRequest(
                           {
                              filename,
                              portfolio_type,
                              portfolioBenchmark,
                              ui_currentStats: currentStats,
                              portfolioSource: portfolioType,
                              portfolioId: enhancerState.portfolioId,
                           },
                           _currentStats => {
                              // console.log('==== _currentStats ====');
                              // console.log(_currentStats);
                              // console.log('=======================');
                           }
                        )
                     );
                  }
               } else {
                  throw new Error("-- Funds price & Returns api error --");
               }
            } else {
               // console.log('==== PORTOLIO LIST EMPTY ====');
               // console.log(portfolioFunds);
               // console.log('=============================');
               yield put(
                  getPortfolioDataByIDSuccess({
                     summary: {
                        portfolioFunds: [],
                     },
                  })
               );
            }
         } else {
            yield put(
               getPortfolioDataByIDSuccess({
                  summary: {
                     portfolioFunds: [],
                  },
               })
            );
         }
      } else {
         console.log("---- filename not present ----");
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(getPortfolioDataByIDSuccess());
   }
}

// function* getRealTimePriceForTickers(action) {
//   try {
//     console.log(action)
//     const { payload, callback } = action
//     yield put(getRealTimePriceForTickersFetch())
//     const realTimeTickerPrice = yield call(fetchRealTimePricesNav, {
//       tickers: payload
//     })
//     callback(realTimeTickerPrice)
//     yield put(getRealTimePriceForTickersSuccess())
//   } catch (error) {
//     console.log(error)
//   }
// }

function* getClientListData(action) {
   const { callback } = action;
   try {
      yield put(getClientListFetch());
      const response = yield call(getClientList);
      if (response && !Array.isArray(response)) {
         throw new Error("Something went wrong while loading client accounts");
      } else if (response && Array.isArray(response)) {
         yield put(
            getClientListSuccess({
               clientList: response,
            })
         );
      } else {
         yield put(
            getClientListSuccess({
               clientList: [],
            })
         );
      }
      if (callback && typeof callback === "function") {
         callback(response);
      }
   } catch (error) {
      console.log(error);
      message.warning("Something went wrong while loading client accounts", 5);
      yield put(
         getClientListSuccess({
            clientList: [],
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* getPortfolioListData() {
   try {
      yield put(getPortfolioListFetch());
      const response = yield call(fetchPortfolioList);
      if (response) {
         yield put(
            getPortfolioListSuccess({
               portfolioList: response,
            })
         );
      } else {
         yield put(
            getPortfolioListSuccess({
               portfolioList: [],
            })
         );
      }
   } catch (error) {
      console.log(error);
      yield put(
         getPortfolioListSuccess({
            portfolioList: [],
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* getModelsListData() {
   try {
      yield put(getModelsListFetch());
      const response = yield call(fetchMarketPlaceList);
      if (response) {
         yield put(
            getModelsListSuccess({
               marketPlaceList: response,
            })
         );
      } else {
         yield put(
            getModelsListSuccess({
               marketPlaceList: [],
            })
         );
      }
   } catch (error) {
      console.log(error);
      yield put(
         getModelsListSuccess({
            marketPlaceList: [],
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* loadCPMListData(action) {
   let clientList = [],
      portfolioList = [],
      marketPlaceList = [],
      _upDownDataSet = [];
   const { payload, callback } = action;
   yield put(loadCPMListFetch());

   try {
      // clientList = yield call(getClientList);
      if (clientList && !Array.isArray(clientList)) {
         throw new Error("Something went wrong while loading client accounts");
      }
   } catch (e) {
      console.log(e);
      clientList = [];
      message.warning("Something went wrong while loading client accounts", 5);
      console.log("-- Client list api error --");
   }

   try {
      portfolioList = yield call(fetchPortfolioList);
   } catch (e) {
      console.log(e);
      portfolioList = [];
      message.warning("Something went wrong while loading portfolio", 5);
      console.log("-- Portfolio list api error --");
   }

   try {
      // marketPlaceList = yield call(fetchMarketPlaceList);
   } catch (e) {
      console.log(e);
      marketPlaceList = [];
      message.warning("Something went wrong while loading sponsor models", 5);
      console.log("-- MarketPlace list api error --");
   }

   try {
      // _upDownDataSet = yield call(fetchUpAndDownChart);
   } catch (e) {
      console.log(e);
      _upDownDataSet = [];
      message.warning("Something went wrong while loading my models", 5);
      console.log("-- Up/Down data api error --");
   }

   try {
      // ;[
      //   clientList = [],
      //   portfolioList = [],
      //   marketPlaceList = [],
      //   _upDownDataSet = []
      // ] = yield all([
      //   call(getClientList),
      //   call(fetchPortfolioList),
      //   call(fetchMarketPlaceList),
      //   call(fetchUpAndDownChart)
      // ]);

      _upDownDataSet.forEach(v => {
         if (v.ticker === "0 ACWI/100 AGG") {
            v.ticker = "AGG";
         }
         if (v.ticker === "100 ACWI/0 AGG") {
            v.ticker = "ACWI";
         }
      });

      _upDownDataSet = [
         ..._upDownDataSet.slice(0, 2),
         _upDownDataSet[_upDownDataSet.length - 1],
         ..._upDownDataSet.slice(2, _upDownDataSet.length - 1),
      ];

      // NOTE: need to remove below binding
      const benchmark = yield select(getPortfolioSelectedBenchmark);
      let selectedBenchmark = benchmark || "SPY";
      yield put(
         setPortfolioState({ selectedBenchmark, upDownDataSet: _upDownDataSet })
      );

      // if (clientList && portfolioList && marketPlaceList) {
      let portfolioId = null;
      if (payload) {
         const { portfolioName, portfolioType } = payload;
         if (
            portfolioName &&
            portfolioType &&
            portfolioName !== "" &&
            portfolioType !== ""
         ) {
            if (portfolioType === "MarketPlace") {
               console.log("==== MISSING PORTFOLIO ID ====");
            } else if (portfolioType === "Client Portfolio") {
               //load Client Portfolio Data
               portfolioId =
                  clientList &&
                  Array.isArray(clientList) &&
                  clientList.find(ck => ck.original_portfolio === portfolioName)
                     ? clientList.find(
                          ck => ck.original_portfolio === portfolioName
                       ).original_portfolio_id
                     : null;
            } else {
               portfolioId =
                  portfolioList &&
                  Array.isArray(portfolioList) &&
                  portfolioList.find(ck => ck.portfolio_name === portfolioName)
                     ? portfolioList.find(
                          ck => ck.portfolio_name === portfolioName
                       ).id
                     : null;
            }
         }
         yield put(
            loadCPMListSuccess({
               portfolioId,
               clientList:
                  clientList &&
                  Array.isArray(clientList) &&
                  clientList.length &&
                  clientList.length > 0
                     ? clientList
                     : [],
               portfolioList:
                  portfolioList &&
                  Array.isArray(portfolioList) &&
                  portfolioList.length &&
                  portfolioList.length > 0
                     ? portfolioList
                     : [],
               marketPlaceList:
                  marketPlaceList &&
                  Object.keys(marketPlaceList) &&
                  Object.keys(marketPlaceList).length > 0
                     ? marketPlaceList
                     : [],
            })
         );
      } else {
         yield put(
            loadCPMListSuccess({
               clientList:
                  clientList &&
                  Array.isArray(clientList) &&
                  clientList.length &&
                  clientList.length > 0
                     ? clientList
                     : [],
               portfolioList:
                  portfolioList &&
                  Array.isArray(portfolioList) &&
                  portfolioList.length &&
                  portfolioList.length > 0
                     ? portfolioList
                     : [],
               marketPlaceList:
                  marketPlaceList &&
                  Object.keys(marketPlaceList) &&
                  Object.keys(marketPlaceList).length > 0
                     ? marketPlaceList
                     : [],
            })
         );
      }
      if (portfolioList && portfolioList.length) {
         let communityPortfolioWillBe = portfolioList.find(
            k =>
               k.source === "SHARINGALPHA" &&
               k.portfolio_name === "Ranked portfolio"
         );
         if (communityPortfolioWillBe) {
            yield put(
               selectPortfolioToLoad({
                  portfolioType: "My Models",
                  portfolioId: communityPortfolioWillBe.id,
                  selectedPortfolio: communityPortfolioWillBe,
                  portfolioName: communityPortfolioWillBe.portfolio_name,
               })
            );
         }
      }
      if (callback && typeof callback === "function") {
         callback();
      }
   } catch (error) {
      console.log(error);
      message.warning("Something went wrong.", 5);
      yield put(
         loadCPMListSuccess({
            clientList:
               clientList &&
               Array.isArray(clientList) &&
               clientList.length &&
               clientList.length > 0
                  ? clientList
                  : [],
            portfolioList:
               portfolioList &&
               Array.isArray(portfolioList) &&
               portfolioList.length &&
               portfolioList.length > 0
                  ? portfolioList
                  : [],
            marketPlaceList:
               marketPlaceList &&
               Object.keys(marketPlaceList) &&
               Object.keys(marketPlaceList).length > 0
                  ? marketPlaceList
                  : [],
            pageCrash: "Failed to load CPM LIST.",
         })
      );
   }
}

function* getAllTickersValidate(action) {
   try {
      if (action.payload) {
         // console.log(action.payload);
         const { tickers } = action.payload;
         if (tickers) {
            yield put(checkTickerFetch());
            const response = yield call(checkTickerExist, { tickers });
            // console.log(response);
            if (response) {
               if (
                  typeof response.tickers_not_exist !== "undefined" ||
                  typeof response.ticker_not_supported !== "undefined"
               ) {
                  yield put(
                     checkTickerSuccess({
                        tickerNotExist: response.tickers_not_exist,
                        tickerNotSupported: response.ticker_not_supported,
                        tickerNamesForCSVPreview:
                           response.tickers_name &&
                           typeof response.tickers_name !== "undefined"
                              ? response.tickers_name
                              : [],
                     })
                  );
               } else {
                  yield put(
                     checkTickerSuccess({
                        error: "`tickers_not_exist` not present in response",
                     })
                  );
                  throw new Error("------ `tickers_not_exist` not present in response -------");
               }

               if (action.callback && typeof action.callback == "function") {
                  action.callback();
               }
            } else {
               yield put(
                  checkTickerSuccess({
                     error: "failed to response",
                  })
               );
               throw new Error("------ failed to response -------");
            }
         } else {
            console.log("---- NO PAYLOAD -----");
            yield put(
               checkTickerSuccess({
                  error: "---- NO PAYLOAD -----",
               })
            );
         }
      } else {
         console.log("---- NO PAYLOAD -----");
      }
   } catch (error) {
      console.log(error);
   }
}

function* verifyCSVTickerPrice(action) {
   try {
      if (action.payload) {
         const { callback, payload } = action;
         const { tickers } = payload;
         if (tickers) {
            yield put(verifyCSVTickerAndPriceFetch());
            const ticksArr = tickers.split(",");
            const [validateTicker, realTimeTickerPrice] = yield all([
               call(checkTickerExist, {
                  tickers: ticksArr,
                  market: "international",
               }),
               call(fetchRealTimePricesNav, { tickers: tickers }),
            ]);
            console.log(
               "CSVVV verify Ticker PRice",
               validateTicker,
               realTimeTickerPrice
            );

            if (realTimeTickerPrice && validateTicker) {
               if (
                  typeof validateTicker.tickers_not_exist !== "undefined" ||
                  typeof validateTicker.ticker_not_supported !== "undefined"
               ) {
                  // Current Design for International only requires unsupported tickers
                  yield put(
                     verifyCSVTickerAndPriceSuccess({
                        tickerNotExist: [],
                        tickerNotSupported: [
                           ...validateTicker.tickers_not_exist,
                           ...validateTicker.ticker_not_supported,
                        ],
                        tickerNamesForCSVPreview:
                           validateTicker.tickers_name &&
                           typeof validateTicker.tickers_name !== "undefined"
                              ? validateTicker.tickers_name
                              : [],
                     })
                  );
               } else {
                  yield put(
                     verifyCSVTickerAndPriceSuccess({
                        error: "`tickers_not_exist` or `ticker_not_supported` not present in response",
                     })
                  );
                  console.log(
                     "------ `tickers_not_exist` or `ticker_not_supported` not present in response -------"
                  );
               }
               if (callback && typeof callback == "function") {
                  callback(realTimeTickerPrice, validateTicker.tickers_name);
               }
            } else {
               throw new Error("real-time Ticker's  price or validating Ticker failed");
            }
         } else {
            throw new Error("ticker missing");
         }
      } else {
         throw new Error("payload missing");
      }
   } catch (error) {
      console.log(error);
      yield put(
         verifyCSVTickerAndPriceSuccess({
            portfolioList: [],
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* resetSummaryAndReturnToOnBoardScreen(action) {
   try {
      if (action.callback) {
         yield put(verifyCSVTickerAndPriceFetch());
         const { callback } = action;
         yield put(resetSummaryDataSuccess());
         if (callback && typeof callback == "function") {
            callback();
         }
      } else {
         throw new Error("callback missing");
      }
   } catch (error) {
      console.log(error);
      yield put(
         resetSummaryDataSuccess({
            portfolioList: [],
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* checkSingleTickerValidity(action) {
   try {
      let { updatedTicker, selectedRecord } = action.payload;
      const { callback } = action;
      yield put(checkSingleTickerValidityFetch());
      // const response = yield call(checkTickerExist, { tickers: [updatedTicker] })
      // if (response) {
      // eslint-disable-next-line no-unused-vars
      let updateStatus = false;
      // if (!response.tickers_not_exist.length) {
      //   // && !response.ticker_not_supported.length
      //   // let foundInTickersNotExist = response.tickers_not_exist.find((item) => item === updatedTicker);
      //   let foundInTickersNotSupported = response.ticker_not_supported.find(
      //     item => item === updatedTicker
      //   )
      //   if (
      //     foundInTickersNotSupported &&
      //     typeof foundInTickersNotSupported !== 'undefined'
      //   ) {
      //     // console.log(selectedRecord);
      //     selectedRecord.invalid = true
      //     selectedRecord.invalidType = 'unsupported'
      //   } else {
      //     updateStatus = true
      //   }
      //   // console.log(selectedRecord);
      //   if (updatedTicker === '$CASH') {
      //     selectedRecord.invalid = true
      //     selectedRecord.invalidType = 'unsupported'
      //     updateStatus = false
      //   }
      //   // else {
      //   //   const realTimePrice = yield call(fetchRealTimePricesNav, { tickers: updatedTicker });
      //   //   if(realTimePrice && realTimePrice.funds[0]){
      //   //     selectedRecord.realTimePrice = realTimePrice.funds[0].price;
      //   //   }
      //   //   updateStatus = true
      //   // }
      //   const realTimePrice = yield call(fetchRealTimePricesNav, {
      //     tickers: updatedTicker
      //   })
      //   if (realTimePrice && realTimePrice.funds[0]) {
      //     selectedRecord.realTimePrice = realTimePrice.funds[0].price
      //   }
      // }

      if (selectedRecord && selectedRecord.ticker) {
         const realTimePrice = yield call(fetchRealTimePricesNav, {
            tickers: updatedTicker,
         });
         if (realTimePrice && realTimePrice.funds[0]) {
            selectedRecord.realTimePrice = realTimePrice.funds[0].price;
         }

         const oldTicker = selectedRecord.ticker;
         selectedRecord.ticker = updatedTicker;

         yield put(checkSingleTickerValiditySuccess());

         if (callback) {
            callback(oldTicker, selectedRecord, (updateStatus = true));
         }
      }

      // } else {
      //   throw 'Failed to response'
      // }
   } catch (error) {
      console.log(error);
      yield put(
         checkSingleTickerValiditySuccess({
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* getFundsPortfolioDataById(action) {
   const { payload } = action;
   try {
      yield put(getFundsPortfolioBatchFetch(payload));

      // NOTE: call update-portfolio with filename for reason to use 'OLD TICKER' which we get while clicking on dropdown for batch search.

      const {
         query,
         similarityMeasures,
         searchTypeMode,
         fundUniverse,
         portfolioName,
         filename,
         portfolioType,
         tickers,
         redirect,
      } = action.payload;

      if (
         query &&
         searchTypeMode &&
         fundUniverse &&
         similarityMeasures &&
         portfolioName &&
         filename &&
         portfolioType &&
         tickers
      ) {
         let payloadRequest = {
               query,
               q: tickers,
               universe: fundUniverse,
               similarity_measure: similarityMeasures,
            },
            readableQuery = QUERY_MAP[query] || query;

         let _portfolioFunds = [],
            invalidTickerFunds = [],
            tickerNotSupported = [];

         const enhancerState = yield select(getEnhancerState);
         let {
            performanceImpact,
            summary,
            enhanceTickerFilter,
            portfolioValue,
         } = enhancerState;

         // if (enhancerState && enhancerState.selectedWeightOptions && query === 'user_criteria') {
         //   readableQuery = QUERY_MAP[query] || query;
         //   if (enhancerState.selectedWeightOptions.code === "active_fund_selector") {
         //     readableQuery = QUERY_MAP['user_criteria_active_fund_selector'];
         //   } else if (enhancerState.selectedWeightOptions.code === "passive_fund_selector") {
         //     readableQuery = QUERY_MAP['user_criteria_passive_fund_selector'];
         //   }
         //   const fundSelectorCode = enhancerState.selectedWeightOptions.value;
         //   payloadRequest.calculator_name = fundSelectorCode;
         // }

         if (
            enhancerState &&
            enhancerState.activeSelectorUniverse &&
            enhancerState.activeSelectorUniverse.length > 0 &&
            enhancerState.activeSelectorUniverse[0] !== ""
         ) {
            readableQuery = QUERY_MAP[query] || query;
            if (
               enhancerState.selectedWeightOptions.code ===
               "active_fund_selector"
            ) {
               readableQuery = QUERY_MAP["user_criteria_active_fund_selector"];
            } else if (
               enhancerState.selectedWeightOptions.code ===
               "passive_fund_selector"
            ) {
               readableQuery = QUERY_MAP["user_criteria_passive_fund_selector"];
            }
            if (enhancerState.selectedWeightOptions.value) {
               const fundSelectorCode =
                  enhancerState.selectedWeightOptions.value;
               payloadRequest.calculator_name = fundSelectorCode;
            }
         }

         let _profileState = yield select(state => state.profile.profile);
         let restricted_universe =
            window.localStorage.getItem("restricted_universe") === "true" ||
            (_profileState && _profileState.restricted_universe) ||
            false;
         if (restricted_universe) {
            payloadRequest.skip_cache = true;
         }

         let response,
            _upDownDataSet = performanceImpact.upDownDataSet;

         _portfolioFunds = summary.portfolioFunds;
         invalidTickerFunds = summary.invalidTickerFunds || [];
         tickerNotSupported = summary.tickerNotSupported || [];

         // NOTE:  summary.portfolioFunds.length Logic for case when Quantiy is missing in Target Card
         if (performanceImpact && _upDownDataSet.length > 0) {
            // || (_portfolioFunds && _portfolioFunds.length !== 0) // removed this condition coz benchmark api was not triggering
            [response] = yield all([call(getBatchSearch, payloadRequest)]);
         } else {
            if (filename === "Watchlist") {
               //load Watchlist Data
               let [_summary] = yield all([
                  // call(getBatchSearch, payloadRequest),
                  call(fetchAccountSummary),
                  // call(fetchUpAndDownChart)
               ]);

               _portfolioFunds =
                  _summary && _summary.results ? _summary.results : [];
               // positions = (_positions && _positions.positions) ? _positions.positions : [];
            } else if (portfolioType === "MarketPlace") {
               //load MarketPlace Data
               // portfolioFunds = yield call(getMarketResultAPI, filename);
               [response, _portfolioFunds, _upDownDataSet] = yield all([
                  call(getBatchSearch, payloadRequest),
                  call(getMarketResultAPI, filename),
                  call(fetchUpAndDownChart),
               ]);
            } else {
               // portfolioFunds = yield call(getPortfolioResultAPI, filename);
               [response, _portfolioFunds, _upDownDataSet] = yield all([
                  call(getBatchSearch, payloadRequest),
                  call(getPortfolioResultAPI, filename),
                  call(fetchUpAndDownChart),
               ]);
            }

            _upDownDataSet.forEach(v => {
               if (v.ticker === "0 ACWI/100 AGG") {
                  v.ticker = "AGG";
               }
               if (v.ticker === "100 ACWI/0 AGG") {
                  v.ticker = "ACWI";
               }
            });

            _upDownDataSet = [
               ..._upDownDataSet.slice(0, 2),
               _upDownDataSet[_upDownDataSet.length - 1],
               ..._upDownDataSet.slice(2, _upDownDataSet.length - 1),
            ];
         }

         if (
            (summary.invalidTickerFunds &&
               summary.invalidTickerFunds.length === 0) ||
            (summary.tickerNotSupported &&
               summary.tickerNotSupported.length === 0)
         ) {
            _portfolioFunds.forEach(e => {
               e.ticker = e.ticker || e.script || e.symbol;
               e.price = e.price || e.current_price;
               e.transaction_date = e.transaction_date || e.purchase_date;
               e.nav = e.nav || e.fees;
               e._realTimePrice = e.price; // realTimePrice mapped to 'price' key by executing 'accountsPageUpdatePrice' func
               e.quantity = e.quantity || e.shares;
               e.market_value = e.price * e.quantity;
               e.original_weight = Number(e.original_weight) || e.weight || 0;
               if (
                  !e.invalid &&
                  (portfolioValue === null ||
                     portfolioValue === "" ||
                     !portfolioValue)
               ) {
                  portfolioValue += e.price * e.quantity;
               }
            });
            if (
               summary.invalidTickerFunds &&
               summary.invalidTickerFunds.length === 0 &&
               _portfolioFunds &&
               _portfolioFunds.length > 0
            ) {
               invalidTickerFunds = _portfolioFunds.filter(
                  gh => gh.invalid && gh.invalid_type === "unrecognized"
               );
            }
            if (
               summary.tickerNotSupported &&
               summary.tickerNotSupported.length === 0 &&
               _portfolioFunds &&
               _portfolioFunds.length > 0
            ) {
               tickerNotSupported = _portfolioFunds.filter(
                  gh => gh.invalid && gh.invalid_type === "unsupported"
               );
            }
         }

         _portfolioFunds = _portfolioFunds.filter(gh => !gh.invalid);
         // console.log(enhanceTickerFilter);
         let _enhanceTickerFilter = JSON.parse(
            JSON.stringify(enhanceTickerFilter)
         );

         if (response) {
            const _mappedData = yield replicaDataMapForEnhancer(
               response,
               query,
               _portfolioFunds,
               _enhanceTickerFilter
            );
            const query_uuid = response.query_uuid;

            const {
               data,
               // categories,
               // updated_at,
               checkboxToggleState,
               swapTickerData,
               useSuggestedFunds,
               usingSuggestedFunds,
               enhanceTickerFilter,
               guidanceMessage,
               preserveEnhanceTickerFilter,
               preserveSwapTickerData,
            } = _mappedData;
            // console.log(data);
            if (data) {
               let _data = data.sort(
                  (a, b) =>
                     b.target_card.funds[0].market_value -
                     a.target_card.funds[0].market_value
               );
               const __enhanceTickerFilter =
                  preserveEnhanceTickerFilter &&
                  preserveEnhanceTickerFilter.length
                     ? preserveEnhanceTickerFilter
                     : enhanceTickerFilter;
               const __preserveSwapTickerData =
                  preserveSwapTickerData && preserveSwapTickerData.length
                     ? preserveSwapTickerData
                     : swapTickerData;

               let resultsToBeLoadFirst = data.filter(
                  kai =>
                     __enhanceTickerFilter &&
                     __enhanceTickerFilter.length > 0 &&
                     __enhanceTickerFilter.find(di => di === kai.ticker)
               );

               yield put(
                  getFundsPortfolioBatchSuccess({
                     query,
                     redirect,
                     portfolioValue,
                     useSuggestedFunds,
                     usingSuggestedFunds,
                     guidanceMessage,
                     tickerNotSupported,
                     fundPortfolios: resultsToBeLoadFirst.slice(0, 4), // using '_data' and commented '_data.slice(0, 30),' bcoz of preserving enhancerFilter logic
                     // fundPortfolios: _data, //_data.slice(0, 4), // using '_data' and commented '_data.slice(0, 30),' bcoz of preserving enhancerFilter logic
                     replacePortfolioToggle: checkboxToggleState,
                     enhanceReplace: __preserveSwapTickerData, // swapTickerData,
                     query_english: readableQuery,
                     totalTargetCardTickers: __enhanceTickerFilter,
                     enhanceTickerFilter: __enhanceTickerFilter,
                     summary: {
                        ...summary,
                        invalidTickerFunds,
                        tickerNotSupported,
                        portfolioFunds: _portfolioFunds,
                     },
                     query_uuid,
                     performanceImpact: {
                        ...performanceImpact,
                        upDownDataSet: _upDownDataSet,
                     },
                  })
               );

               if (swapTickerData.length > 0) {
                  yield call(delay, 600);
                  console.log(
                     "========== CALCULATING PERFORMANCE IMPACT =========="
                  );
                  // console.log(_upDownDataSet);
                  // console.log(performanceImpact.selectedBenchmark);
                  let portfolioBenchmark =
                     getPortfolioBenchmarkOfSelectedTicker(
                        _upDownDataSet,
                        performanceImpact.selectedBenchmark
                     );

                  // some data calculations can only be calculated by portfolio holdings
                  const stats = yield replicaStatsForPerformanceImpact({
                     portfolioEnhanceReplace: __preserveSwapTickerData,
                     portfolioFunds: _data,
                     portfolioQuery: query,
                     portfolioBenchmark,
                  });

                  console.log(
                     "========== PLOTTING PERFORMANCE IMPACT DATA =========="
                  );
                  if (stats && stats.currentPerformanceImpact) {
                     const ___currentStats = stats.currentPerformanceImpact;
                     const _enhancerState = yield select(getEnhancerState);
                     // const { performanceImpact } = _enhancerState;
                     // if (performanceImpact && performanceImpact.currentPerformanceImpact && Object.keys(performanceImpact.currentPerformanceImpact).length === 0) {
                     let portfolio_type =
                        portfolioType === "MarketPlace"
                           ? "model_portfolio"
                           : portfolioType === "Client Portfolio"
                           ? "custom_portfolio"
                           : "custom_portfolio";

                     let enhancedRequestData = __preserveSwapTickerData
                        .filter(k => k.selectedPortfolioFunds)
                        .map(item => {
                           if (
                              item.selectedPortfolioFunds &&
                              item.selectedPortfolioFunds.ticker
                           ) {
                              return {
                                 ticker: item.selectedPortfolioFunds.ticker,
                                 weight: item.selectedPortfolioFunds.weight,
                              };
                           }
                        });

                     if (
                        enhancedRequestData &&
                        enhancedRequestData.length > 0 &&
                        typeof stats.enhancedPerformanceImpact !== "undefined"
                     ) {
                        yield put(
                           getEnhanceStatsForSelectedTickerRequest(
                              {
                                 filename,
                                 portfolio_type,
                                 portfolioBenchmark,
                                 enhancedRequestData,
                                 ui_currentStats: ___currentStats,
                                 portfolioSource: portfolioType,
                                 portfolioId:
                                    _enhancerState.portfolioId || filename, // when page refreshed -> portfolioId is 'null', need to fix this!
                                 ui_enhancedStats:
                                    stats.enhancedPerformanceImpact,
                              },
                              res => {
                                 // console.log('------- getEnhanceStatsForSelectedTickerRequest res -------');
                                 // console.log(res);
                                 // console.log('-----------------------------------------------------------');
                              }
                           )
                        );
                     }

                     yield put(
                        setEnhancerState({
                           fundPortfolios: _data.slice(0, 30), // using '_data' and commented '_data.slice(0, 30),' bcoz of preserving enhancerFilter logic
                           enhanceTickerFilter: __enhanceTickerFilter,
                        })
                     );

                     // console.log(_data);
                     // console.log(__preserveSwapTickerData);
                  }
               }
            } else {
               yield put(
                  getFundsPortfolioBatchSuccess({
                     fundPortfolios: [],
                     query_english: readableQuery,
                  })
               );
            }

            if (
               query &&
               addEventToAnalytics &&
               typeof query_uuid !== "undefined"
            ) {
               console.log(" --- addEventToAnalytics triggers --- ");
               addEventToAnalytics(
                  "Enhancer Query",
                  "Enhancer Query",
                  "ENHANCER_QUERY",
                  { query, query_uuid },
                  false
               );
            }
         } else {
            yield put(
               getFundsPortfolioBatchSuccess({
                  fundPortfolios: [],
                  query_english: readableQuery,
                  query,
               })
            );
         }
      } else {
         yield put(
            getFundsPortfolioBatchSuccess({
               fundPortfolios: [],
               query,
            })
         );
      }
   } catch (error) {
      console.log(error);
      yield put(
         getFundsPortfolioBatchSuccess({
            fundPortfolios: [],
            pageCrash: "Failed to load query.",
            guidanceMessage: ["Failed to load query."],
         })
      );
   }
}

function* resetFundsPortfoliosAndRTO(action) {
   try {
      if (action.callback) {
         yield put(resetFundsPortfolioFetch());
         const { callback } = action;
         yield put(resetFundsPortfolioSuccess());
         if (callback && typeof callback == "function") {
            callback();
         }
      } else {
         throw new Error("callback missing");
      }
   } catch (error) {
      console.log(error);
      yield put(
         resetFundsPortfolioSuccess({
            portfolioList: [],
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* getAllTickers() {
   try {
      // const { filename } = action
      const result = yield call(getAllTickersAPI);
      if (result) {
         yield put(saveAllTickersData(result));
      } else {
         //yield put(saveEnhancedTransactionData(result));
      }
   } catch (error) {
      console.log(error);
   }
}

function* getCustomTickerData(action) {
   try {
      const { ticker, overlap, query_var } = action.payload;
      if (ticker && overlap) {
         yield put(getTickerDataFetch());
         const response = yield call(getEnhancedCardAPI, {
            ticker,
            overlap,
            query_var,
         });
         if (response) {
            const enhancerState = yield select(state => state.enhancer);
            const { fundPortfolios } = enhancerState;
            if (fundPortfolios && fundPortfolios.length > 0) {
               const foundRow = fundPortfolios.filter(
                  item => item.ticker === overlap
               );
               if (
                  foundRow &&
                  foundRow.length > 0 &&
                  foundRow[0].result &&
                  foundRow[0].result.query_var
               ) {
                  const foundQueryVar = foundRow[0].result.query_var;
                  const targetCardQuery = foundRow[0].query;
                  const fundObject = prepareFundObject({
                     fundsArr: response.result.funds,
                     queryVar: foundQueryVar,
                     query: targetCardQuery,
                     ticker: overlap,
                     targetCard: foundRow[0].result.target_card,
                  });
                  console.log(fundObject);
                  if (
                     fundObject &&
                     fundObject.data &&
                     fundObject.data.length > 0
                  ) {
                     const { data } = fundObject;
                     yield put(
                        getTickerDataSuccess({
                           overlap,
                           targetCardQuery,
                           fundsData: data[0],
                        })
                     );

                     yield call(delay, 600);
                     console.log(
                        "========== PLOTTING PERFORMANCE IMPACT DATA =========="
                     );
                     const { performanceImpact, enhanceReplace, query } =
                        enhancerState;
                     let portfolioBenchmark =
                        getPortfolioBenchmarkOfSelectedTicker(
                           performanceImpact.upDownDataSet,
                           performanceImpact.selectedBenchmark
                        );
                     let updatedArray = enhanceReplace.map(item => {
                        return item.selectedPortfolioFunds;
                     });

                     if (portfolioBenchmark && updatedArray) {
                        const STATS = getCalculatedEnhanceStats(
                           updatedArray,
                           query,
                           portfolioBenchmark
                        );
                        console.log(
                           "========== PLOTTING PERFORMANCE IMPACT DATA =========="
                        );
                        let enhancedRequestData = updatedArray.map(item => {
                           return {
                              ticker: item.ticker,
                              weight: item.weight,
                           };
                        });
                        // console.log(enhancedRequestData);
                        if (
                           enhancedRequestData &&
                           enhancedRequestData.length > 0 &&
                           portfolioBenchmark &&
                           STATS
                        ) {
                           yield put(
                              getEnhanceStatsForSelectedTickerRequest(
                                 {
                                    portfolioBenchmark,
                                    enhancedRequestData,
                                    ui_enhancedStats: STATS,
                                 },
                                 res => {
                                    console.log(
                                       "------- getEnhanceStatsForSelectedTickerRequest res -------"
                                    );
                                    console.log(res);
                                    console.log(
                                       "-----------------------------------------------------------"
                                    );
                                 }
                              )
                           );
                        }
                     } else {
                        throw new Error("-- Failed to calculate portfolio benchmark data for specify custom funds --");
                     }
                  } else {
                     throw new Error("-- Failed to prepare fund object --");
                  }
               }
            } else {
               throw new Error("-- fundPortfolios missing --");
            }
         } else {
            throw new Error("-- Failed to response --");
         }
         if (action.callback && typeof action.callback == "function") {
            action.callback(response);
         }
      } else {
         throw new Error("-- bad request --");
      }
   } catch (error) {
      console.log(error);
      yield put(
         getTickerDataSuccess({
            pageCrash: "Failed to process",
         })
      );
      if (action.callback && typeof action.callback == "function") {
         action.callback();
      }
   }
}

function* addFundForEnhance(action) {
   try {
      const { payload } = action;
      if (payload) {
         yield put(addFundForEnhanceFetch(payload));

         yield put(addFundForEnhanceSuccess());

         const res = yield select(getEnhancerState);
         const {
            performanceImpact,
            fundPortfolios,
            query,
            enhanceReplace,
            totalTargetCardTickers,
            enhanceTickerFilter,
         } = res;

         if (res && res.enhanceReplace && res.fundPortfolios && res.query) {
            let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(
               performanceImpact.upDownDataSet,
               performanceImpact.selectedBenchmark
            );

            // let enhanceTickers = []

            let filteredArray = _.xor(
               totalTargetCardTickers,
               enhanceTickerFilter
            );

            let updatedArray = enhanceReplace.map(item => {
               return item.selectedPortfolioFunds;
            });

            let _uncheckedTickerDataArray = [];
            if (fundPortfolios && fundPortfolios.length > 0) {
               fundPortfolios.map(hg => {
                  if (hg.ticker && enhanceTickerFilter.length > 0) {
                     // NOTE: DO NOT CHANGE THIS
                     const _foundTicker = filteredArray.find(
                        ds => ds === hg.ticker
                     );
                     if (
                        _foundTicker &&
                        hg.target_card &&
                        hg.target_card.funds &&
                        hg.target_card.funds.length > 0
                     ) {
                        const _targetCardData = hg.target_card.funds[0];
                        _uncheckedTickerDataArray.push(_targetCardData);
                     }
                  }
               });
            }

            updatedArray = _.union(
               [...updatedArray],
               [..._uncheckedTickerDataArray]
            );
            // console.log(updatedArray);
            // console.log(query);
            console.log(portfolioBenchmark);
            if (portfolioBenchmark) {
               const stats = yield getCalculatedEnhanceStats(
                  updatedArray,
                  query,
                  portfolioBenchmark
               );
               // console.log(stats);
               console.log(
                  "========== PLOTTING PERFORMANCE IMPACT DATA =========="
               );
               // if (stats && typeof stats.pfRet1y !== 'undefined') {
               //   yield put(
               //     setEnhancerState({
               //       performanceImpact: {
               //         ...performanceImpact,
               //         enhancedPerformanceImpact: stats
               //       }
               //       // enhanceTickerFilter: enhanceTickers, // NOTE: CAREFUL UNCOMMENTING, it will hid others row.
               //     })
               //   )
               // }
               let enhancedRequestData = updatedArray.map(item => {
                  return {
                     ticker: item.ticker,
                     weight: item.weight,
                  };
               });
               if (
                  enhancedRequestData &&
                  enhancedRequestData.length > 0 &&
                  portfolioBenchmark &&
                  stats
               ) {
                  yield put(
                     getEnhanceStatsForSelectedTickerRequest(
                        {
                           portfolioBenchmark,
                           enhancedRequestData,
                           ui_enhancedStats: stats,
                        },
                        res => {
                           console.log(
                              "------- getEnhanceStatsForSelectedTickerRequest res -------"
                           );
                           console.log(res);
                           console.log(
                              "-----------------------------------------------------------"
                           );
                        }
                     )
                  );
               } else {
                  throw new Error(`-- failed to trigger enhance stats api is missing --`);
               }
            } else {
               throw new Error(`-- portfolioBenchmark data ${portfolioBenchmark} is missing --`);
            }
         }
      } else {
         throw new Error("callback missing");
      }
   } catch (error) {
      console.log(error);
      yield put(
         addFundForEnhanceSuccess({
            pageCrash: "Failed to perform action.",
         })
      );
   }
}

function* deleteFundForEnhance(action) {
   try {
      const { payload } = action;
      if (payload) {
         yield put(deleteFundForEnhanceFetch(payload));

         yield put(deleteFundForEnhanceSuccess());

         const res = yield select(getEnhancerState);
         const {
            performanceImpact,
            fundPortfolios,
            query,
            enhanceReplace,
            totalTargetCardTickers,
            enhanceTickerFilter,
         } = res;

         if (res && res.enhanceReplace && res.fundPortfolios && res.query) {
            let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(
               performanceImpact.upDownDataSet,
               performanceImpact.selectedBenchmark
            );
            // console.log(portfolioBenchmark);
            // let enhanceTickers = []

            let filteredArray = _.xor(
               totalTargetCardTickers,
               enhanceTickerFilter
            );

            let updatedArray = enhanceReplace.map(item => {
               // enhanceTickers.push(item.ticker);
               return item.selectedPortfolioFunds;
            });

            let _uncheckedTickerDataArray = [];
            if (fundPortfolios && fundPortfolios.length > 0) {
               fundPortfolios.map(hg => {
                  if (hg.ticker && enhanceTickerFilter.length > 0) {
                     // NOTE: DO NOT CHANGE THIS
                     const _foundTicker = filteredArray.find(
                        ds => ds === hg.ticker
                     );
                     if (
                        _foundTicker &&
                        hg.target_card &&
                        hg.target_card.funds &&
                        hg.target_card.funds.length > 0
                     ) {
                        const _targetCardData = hg.target_card.funds[0];
                        _uncheckedTickerDataArray.push(_targetCardData);
                     }
                  }
               });
            }

            // console.log(_uncheckedTickerDataArray);
            updatedArray = _.union(
               [...updatedArray],
               [..._uncheckedTickerDataArray]
            );
            // console.log(updatedArray);

            const stats = yield getCalculatedEnhanceStats(
               updatedArray,
               query,
               portfolioBenchmark
            );

            // console.log(stats);
            console.log(
               "========== PLOTTING PERFORMANCE IMPACT DATA =========="
            );
            // if (stats && typeof stats.pfRet1y !== 'undefined') {
            //   yield put(
            //     setEnhancerState({
            //       performanceImpact: {
            //         ...performanceImpact,
            //         enhancedPerformanceImpact: stats
            //       }
            //       // enhanceTickerFilter: enhanceTickers, // NOTE: CAREFUL UNCOMMENTING, it will hid others row.
            //     })
            //   )
            // }

            let enhancedRequestData = updatedArray.map(item => {
               return {
                  ticker: item.ticker,
                  weight: item.weight,
               };
            });
            // console.log(enhancedRequestData);
            if (
               enhancedRequestData &&
               enhancedRequestData.length > 0 &&
               portfolioBenchmark &&
               stats
            ) {
               yield put(
                  getEnhanceStatsForSelectedTickerRequest(
                     {
                        portfolioBenchmark,
                        enhancedRequestData,
                        ui_enhancedStats: stats,
                     },
                     res => {
                        console.log(
                           "------- getEnhanceStatsForSelectedTickerRequest res -------"
                        );
                        console.log(res);
                        console.log(
                           "-----------------------------------------------------------"
                        );
                     }
                  )
               );
            }
         }
      } else {
         throw new Error("callback missing");
      }
   } catch (error) {
      console.log(error);
      yield put(
         deleteFundForEnhanceSuccess({
            pageCrash: "Failed to perform action.",
         })
      );
   }
}

function* finalizePortfolio(action) {
   try {
      const { payload } = action;
      if (payload) {
         const {
            portfolio_name,
            replace,
            enhanceCTA,
            portfolioType,
            portfolio_overwrite,
            data,
            replacementNotChosen,
            replacementNotFound,
         } = payload;
         if (portfolio_name && replace && enhanceCTA && portfolioType) {
            yield put(finalizePortfolioFetch());
            const payloadReq = portfolio_overwrite
               ? {
                    portfolio_overwrite,
                    portfolio_name,
                    replace,
                    enhanceCTA,
                    data,
                 }
               : { portfolio_name, replace, enhanceCTA, data };
            const response = yield call(setPortfolioOptsApi, payloadReq);
            if (response && response.message === "Success") {
               if (response.portfolio_name && response.portfolio_id) {
                  const requestToFetchSummary = {
                     filename: response.portfolio_name,
                     portfolioType: portfolioType,
                     _for: "FINALIZE", // DO NOT DELETE THIS !
                  };
                  if (portfolioType === "MarketPlace") {
                     requestToFetchSummary.finalizedSponsorModel = true;
                  }
                  yield put(
                     getPortfolioDataByIDRequest(requestToFetchSummary, res => {
                        if (res) {
                           console.log(res);
                           let _resp = {
                              ...res,
                              enhancedPortfolioId: response.portfolio_id,
                              replacementNotChosen,
                              replacementNotFound,
                           };
                           if (
                              action.callback &&
                              typeof action.callback == "function"
                           ) {
                              action.callback(_resp);
                           }
                        }
                     })
                  );
               } else {
                  throw new Error("-----------> portfolio_id or portfolio_name is missing <-----------");
               }
            } else {
               throw new Error("-- Failed to response --");
            }
         } else {
            throw new Error("-- bad request --");
         }
      } else {
         throw new Error("-- payload missing --");
      }
   } catch (error) {
      console.log(error);
      yield put(
         finalizePortfolioSuccess({
            finalizeData: {},
            pageCrash: "Failed to process",
         })
      );
   }
}

function* finalizeModelPortfolio(action) {
   try {
      console.log("finalizeModelPortfolio");
      if (action.payload) {
         const { request } = action.payload;
         if (request) {
            yield put(finalizeModelPortfolioFetch());
            const response = yield call(finalizeModelPortfolioAPI, {
               ...request,
            });
            // console.log(response)
            if (response && response.message === "Success") {
               if (response.portfolio_name && response.portfolio_id) {
                  const requestToFetchSummary = {
                     filename: response.portfolio_name,
                     portfolioType: request.replace_portfolio_type,
                     _for: "FINALIZE", // DO NOT DELETE THIS !
                  };
                  if (request.replace_portfolio_type === "MarketPlace") {
                     requestToFetchSummary.finalizedSponsorModel = true;
                  }
                  yield put(
                     getPortfolioDataByIDRequest(requestToFetchSummary, res => {
                        if (res) {
                           // console.log(res);
                           let _resp = {
                              ...res,
                              enhancedPortfolioId: response.portfolio_id,
                           };
                           if (
                              action.callback &&
                              typeof action.callback == "function"
                           ) {
                              action.callback(_resp);
                           }
                        }
                     })
                  );
               } else {
                  throw new Error("-----------> portfolio_id or portfolio_name is missing <-----------");
               }
            } else {
               throw new Error("-- Failed to response --");
            }
         } else {
            console.log("---- NO PAYLOAD -----");
            yield put(
               finalizeModelPortfolioSuccess({
                  error: "---- NO PAYLOAD -----",
               })
            );
         }
      } else {
         console.log("---- NO PAYLOAD -----");
      }
   } catch (error) {
      console.log(error);
   }
}

function* savePortfolio(action) {
   try {
      const { payload } = action;
      if (payload) {
         const {
            portfolio_name,
            portfolio_id,
            portfolioType,
            is_client = false,
         } = payload;
         if (portfolio_name && portfolio_id && portfolioType) {
            yield put(
               savePortfolioFetch({
                  savingPortfolioError: false,
                  savingPortfolioErrorText: "",
               })
            );

            const response = yield call(setProposalPortfolioApi, {
               portfolio_name,
               portfolio_id,
               is_client,
            });

            if (
               response &&
               response.message === "Portfolio saved successfully!"
            ) {
               let clientListResponse = yield call(getClientList);
               if (clientListResponse) {
                  yield put(
                     setEnhancerState({
                        clientList: clientListResponse,
                     })
                  );
               }

               yield call(delay, 500);

               // const portfolioName = response.portfolio_name

               // NOTE: assuming after Saving portfolio it will become 'My Portfolio', so portfolioType: 'My Portfolio'.
               const jObj = {
                  portfolioType: "My Portfolio", // portfolioType,
                  portfolioId: portfolio_name,
               };
               // yield put(clearFundPortfolioState());

               // clear FundPortfolio State
               yield put(
                  savePortfolioSuccess({
                     fundPortfolios: [],
                     guidanceMessage: "",
                     enhanceReplace: [],
                     enhanceTickerFilter: [],
                     totalTargetCardTickers: [],
                     finalizeData: {},
                     query: "",
                     query_english: "",
                     tickers: [],
                     performanceImpact: {
                        selectedBenchmark: "SPY",
                        upDownDataSet: [],
                        currentPerformanceImpact: {},
                        enhancedPerformanceImpact: {},
                     },
                  })
               );

               // yield put(push(newPath));
               if (action.callback && typeof action.callback == "function") {
                  action.callback(jObj);
               }
            } else {
               if (response && response.message) {
                  throw response.message;
               } else {
                  throw new Error("-- Failed to response --");
               }
            }
         } else {
            throw new Error("-- bad request --");
         }
      } else {
         throw new Error("-- payload missing --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails.status === 400) {
         yield put(
            savePortfolioSuccess({
               savingPortfolioError: true,
               savingPortfolioErrorText: errorDetails.data.message,
            })
         );
         if (
            action.payload &&
            action.payload.errorNotification &&
            action.callback &&
            typeof action.callback == "function"
         ) {
            action.callback({ notify: true });
         }
      } else {
         yield put(
            savePortfolioSuccess({
               savingPortfolioError: true,
               pageCrash: "Failed to process",
            })
         );
         if (
            action.payload &&
            action.payload.errorNotification &&
            action.callback &&
            typeof action.callback == "function"
         ) {
            action.callback({ notify: true, error });
         }
      }
   }
}

function* clearFinalizeData(action) {
   try {
      yield call(delay, 500);

      yield put(clearFinalizeDataFetch());

      yield call(delay, 1000);

      const { callback } = action;

      // const _enhancerState = yield select(state => state.enhancer)

      // const {
      //   performanceImpact,
      //   enhanceReplace: __enhanceReplace,
      //   query,
      //   fundPortfolios: _data
      // } = _enhancerState
      //
      // if (
      //   _data &&
      //   __enhanceReplace &&
      //   performanceImpact.upDownDataSet &&
      //   performanceImpact.selectedBenchmark
      // ) {
      //   console.log('========== CALCULATING PERFORMANCE IMPACT ==========')
      //   // console.log(_upDownDataSet);
      //   // console.log(performanceImpact.selectedBenchmark);
      //   let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(
      //     performanceImpact.upDownDataSet,
      //     performanceImpact.selectedBenchmark
      //   )
      //   // console.log(_data)
      //
      //   let updatedArray = __enhanceReplace.map((item) => {
      //     return item.selectedPortfolioFunds
      //   });
      //
      //   if (portfolioBenchmark && updatedArray) {
      //
      //     // const stats = yield replicaStatsForPerformanceImpact({
      //     //   portfolioEnhanceReplace: __enhanceReplace,
      //     //   portfolioFunds: _data,
      //     //   portfolioQuery: query,
      //     //   portfolioBenchmark
      //     // })
      //
      //     console.log('========== PLOTTING PERFORMANCE IMPACT DATA ==========')
      //     if (
      //       stats &&
      //       typeof stats.currentPerformanceImpact !== 'undefined' &&
      //       typeof stats.enhancedPerformanceImpact !== 'undefined'
      //     ) {
      //       // console.log(stats);
      //       // yield put(
      //       //   setEnhancerState({
      //       //     performanceImpact: {
      //       //       ...performanceImpact,
      //       //       currentPerformanceImpact: stats.currentPerformanceImpact,
      //       //       enhancedPerformanceImpact: stats.enhancedPerformanceImpact
      //       //     }
      //       //   })
      //       // )
      //
      //       const stats = yield getCalculatedEnhanceStats(updatedArray, query, portfolioBenchmark);
      //       // console.log('========== PLOTTING PERFORMANCE IMPACT DATA ==========');
      //
      //       let enhancedRequestData = updatedArray.map((item) => {
      //         return {
      //           ticker: item.ticker,
      //           weight: item.weight,
      //         };
      //       });
      //       // console.log(enhancedRequestData);
      //       if (stats &&
      //         enhancedRequestData &&
      //         enhancedRequestData.length > 0 &&
      //         portfolioBenchmark &&
      //         getEnhanceStatsForSelectedTickerRequest
      //       ) {
      //
      //         yield put(
      //           getEnhanceStatsForSelectedTickerRequest({
      //             portfolioBenchmark,
      //             enhancedRequestData,
      //             ui_enhancedStats: stats,
      //           }, (res) => {
      //             console.log('------- getEnhanceStatsForSelectedTickerRequest res -------');
      //             console.log(res);
      //             console.log('-----------------------------------------------------------');
      //           })
      //         )
      //
      //       } else {
      //         console.log(`=== FAILED to execute getEnhanceStatsForSelectedTickerRequest function after clearing finalize data ===`)
      //       }
      //
      //     } else {
      //       console.log(`=== FAILED to calculate performanceImpact data invalid using UI Method ===`)
      //     }
      //
      //   } else {
      //     console.log(`=== FAILED to calculate performanceImpact data invalid => portfolioBenchmark ===`)
      //   }
      // } else {
      //   console.log(`=== FAILED to calculate performanceImpact data invalid => fundPortfolios.length || __enhanceReplace.length ||  performanceImpact.upDownDataSet || performanceImpact.selectedBenchmark ===`)
      // }

      yield put(clearFinalizeDataSuccess());

      if (action.callback) {
         if (callback && typeof callback == "function") {
            callback();
         }
      } else {
         throw new Error("callback missing");
      }
   } catch (error) {
      console.log(error);
      yield put(
         clearFinalizeDataSuccess({
            portfolioList: [],
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* downloadEnhancerReportPDF(action) {
   const { payload } = action;
   try {
      if (payload) {
         yield put(
            setEnhancerState({
               pdfReportLoader: true,
            })
         );
         const currDate = moment().format("MMMM YYYY");
         const reportName = "Enhancer Proposal – " + currDate;
         const entity = payload.portfolioName
            ? payload.portfolioName
            : payload.portfolio.enhanceReplace[0].ticker;
         const section = "Enhancer";
         const report_type = "Finalized Proposal";
         const response = yield call(downloadEnhancerReportPDFAPI, {
            reports: JSON.stringify(payload),
            reportName,
            entity,
            section,
            report_type,
         });
         if (response) {
            // console.log('!!!!! REPORT API RESPONSE !!!!!!');
            // console.log(response);
            const newBlob = new Blob([response.data], {
               type: response.headers["content-type"],
            });
            // const firstName = yield select(state => state.auth.user.firstName) || '';
            // const lastName = yield select(state => state.auth.user.lastName) || '';
            // const fullName = firstName + ' ' + lastName;
            FileSaver.saveAs(
               newBlob,
               "Enhancer Proposal – " + currDate + ".pdf"
            );

            yield put(
               setEnhancerState({
                  pdfReportDownloadStatus: {
                     message: "PDF Downloaded successfully",
                     downloadStatus: "success",
                  },
                  pdfReportLoader: false,
               })
            );
         }
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         setEnhancerState({
            pdfReportLoader: false,
            pdfReportDownloadStatus: {
               message:
                  "Failed to download Report PDF file. Please email us at: help@magnifi.com",
               downloadStatus: "danger",
            },
         })
      );
   }
}

function* downloadRecommendationEnhancerPDF(action) {
   const { payload } = action;
   // console.log(payload)
   try {
      if (payload) {
         yield put(
            setEnhancerState({
               pdfReportLoader: true,
            })
         );
         const currDate = moment().format("MMMM YYYY");
         const reportName = "Recommendation Enhancer Proposal – " + currDate;
         const entity = payload.portfolioName
            ? payload.portfolioName
            : payload.modelEnhancement.current.portfolioName;
         const section = "Enhancer";
         const report_type = "Recommendation Proposal";
         // console.log(payload)
         const response = yield call(downloadRecEnhancerReportPDFAPI, {
            reports: JSON.stringify(payload),
            reportName,
            entity,
            section,
            report_type,
         });
         if (response) {
            // console.log('!!!!! REPORT API RESPONSE !!!!!!');
            // console.log(response);

            const newBlob = new Blob([response.data], {
               type: response.headers["content-type"],
            });
            // const firstName = yield select(state => state.auth.user.firstName) || '';
            // const lastName = yield select(state => state.auth.user.lastName) || '';
            // const fullName = firstName + ' ' + lastName;
            FileSaver.saveAs(
               newBlob,
               "Recommendation Enhancer Proposal – " + currDate + ".pdf"
            );

            yield put(
               setEnhancerState({
                  pdfReportDownloadStatus: {
                     message: "PDF Downloaded successfully",
                     downloadStatus: "success",
                  },
                  pdfReportLoader: false,
               })
            );
         }
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         setEnhancerState({
            pdfReportLoader: false,
            pdfReportDownloadStatus: {
               message:
                  "Failed to download Report PDF file. Please email us at: help@magnifi.com",
               downloadStatus: "danger",
            },
         })
      );
   }
}

function* downloadCurrentPortfolioReportPDF(action) {
   const { payload } = action;

   try {
      if (payload) {
         yield put(
            setEnhancerState({
               pdfReportLoader: true,
            })
         );
         const currDate = moment().format("MMMM YYYY");
         const reportName = "Current Portfolio – " + currDate;
         const entity = payload.portfolioName;
         const section = "Enhancer";
         const report_type = "Portfolio Overview Proposal";

         const response = yield call(downloadRecEnhancerReportPDFAPI, {
            reports: JSON.stringify(payload),
            reportName,
            entity,
            section,
            report_type,
         });
         if (response) {
            // console.log('!!!!! REPORT API RESPONSE !!!!!!');
            // console.log(response);

            const newBlob = new Blob([response.data], {
               type: response.headers["content-type"],
            });

            FileSaver.saveAs(
               newBlob,
               "Current Portfolio  – " + currDate + ".pdf"
            );

            yield put(
               setEnhancerState({
                  pdfReportDownloadStatus: {
                     message: "PDF Downloaded successfully",
                     downloadStatus: "success",
                  },
                  pdfReportLoader: false,
               })
            );
         }
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         setEnhancerState({
            pdfReportLoader: false,
            pdfReportDownloadStatus: {
               message:
                  "Failed to download Report PDF file. Please email us at: help@magnifi.com",
               downloadStatus: "danger",
            },
         })
      );
   }
}

function* saveEnhancedTransaction(action) {
   try {
      // const { filename } = action
      // console.log(filename)
      const result = yield call(portfolioEnhanceTransAPI, action.payload);
      if (
         result &&
         (result.message === "Failed" || result.message === "No File Present.")
      ) {
         console.log("response", result);
      } else {
         yield put(saveEnhancedTransactionData(result));
      }
   } catch (error) {
      console.log(error);
   }
}

function* getReplacePortfoliosOptionsRequest(action) {
   try {
      if (action.payload) {
         const {
            query,
            tickers,
            similarityMeasures,
            searchTypeMode,
            modelUniverse,
            portfolioName,
            portfolioType,
            myCurrentPortfolio,
            redirect,
            repeated,
         } = action.payload;

         // NOTE: Lets show CUSTOM REPLACEMENT options for MODELS, without firing a "Query"
         // ------------------------------------------------------------------------------------
         if (!query) {
            const currentState = yield select(state => state.enhancer);

            let NEW_QUERY_VAR_OBJECT = currentState.modelPortfolios
               .myCurrentPortfolio._NEW_QUERY_VAR_OBJECT || {
               _defaultFRR: ["fees", "risk", "returns"],
               _originalQueryVar: [],
               _relevanceChartTabs: ["BurstChart"],
            };

            let TARGET_CARD_REGION_DATA = [],
               OVERLAP_DATA = [],
               TARGET_CARD_OBJECT = {};

            if (
               currentState.currentTargetCard &&
               currentState.currentTargetCard.length &&
               currentState.currentTargetCard[0]
            ) {
               TARGET_CARD_OBJECT = {
                  ...currentState.currentTargetCard[0],
                  chartData: [],
                  Region: TARGET_CARD_REGION_DATA,
                  overlap_data: OVERLAP_DATA,
                  ...NEW_QUERY_VAR_OBJECT,
               };

               if (
                  currentState.currentTargetCard[0]
                     .target_overlap_burst_chart &&
                  currentState.currentTargetCard[0].target_overlap_burst_chart
                     .length &&
                  isBurstChartDataValid(
                     currentState.currentTargetCard[0]
                        .target_overlap_burst_chart
                  )
               ) {
                  TARGET_CARD_REGION_DATA =
                     currentState.currentTargetCard[0]
                        .target_overlap_burst_chart;
                  TARGET_CARD_OBJECT = {
                     ...TARGET_CARD_OBJECT,
                     Region: TARGET_CARD_REGION_DATA,
                  };
               }
            }

            // NOTE: Remove duplicate 'myCurrentPortfolio' from enhancer state
            // ------------------------------------------------------------------------------------
            let _filteredPayload = action.payload;
            if (_filteredPayload.myCurrentPortfolio)
               delete _filteredPayload.myCurrentPortfolio;
            // ------------------------------------------------------------------------------------

            yield put(
               getModelPortfoliosByIdSuccess({
                  ..._filteredPayload,
                  redirect,
                  modelPortfolios: {
                     ...currentState.modelPortfolios,
                     myCurrentPortfolio: {
                        ...currentState.modelPortfolios.myCurrentPortfolio,
                        ...myCurrentPortfolio,
                        targetCard: TARGET_CARD_OBJECT,
                        _NEW_QUERY_VAR_OBJECT: NEW_QUERY_VAR_OBJECT,
                     },
                     replacePortfolioWith: {},
                     customReplaceMPOptions: {},
                  },
                  customReplacementMPData: {},
                  customReplacementTickersData: [],
                  enhanceReplace: [],
                  guidanceMessage: [],
               })
            );
         } else {
            const MODEL_QUERY = TO_MODEL_QUERY[query] || query;

            if (
               MODEL_QUERY &&
               tickers &&
               similarityMeasures &&
               searchTypeMode &&
               modelUniverse &&
               portfolioName &&
               portfolioType &&
               myCurrentPortfolio
            ) {
               if (
                  MODEL_QUERY === "user_criteria" ||
                  MODEL_QUERY === "outperform"
               ) {
                  yield put(
                     getModelPortfoliosByIdSuccess({
                        enhanceReplace: [],
                        redirect,
                        modelPortfolios: {
                           myCurrentPortfolio: {
                              portfolioDetails: {
                                 name: "",
                              },
                              holdings: [],
                           },
                           replacePortfolioOptions: [],
                           replacePortfolioWith: {},
                           customReplaceMPOptions: {},
                        },
                     })
                  );
               } else {
                  const currentState = yield select(state => state.enhancer);

                  let TARGET_CARD_REGION_DATA = [],
                     OVERLAP_DATA = [];

                  yield put(
                     getModelPortfoliosByIdFetch({
                        ...action.payload,
                        redirect,
                        modelPortfolios: {
                           ...currentState.modelPortfolios,
                           ...action.payload,
                        },
                        guidanceMessage: [],
                     })
                  );

                  const payloadRequest = {
                     query: MODEL_QUERY,
                     portfolio_id: portfolioName,
                     portfolio_type:
                        portfolioType === "MarketPlace"
                           ? "model_portfolio"
                           : portfolioType !== "Your Watchlist"
                           ? "custom_portfolio"
                           : "",
                     universe: modelUniverse,
                     // similarity_measure: similarityMeasures,
                  };

                  let _profileState = yield select(
                     state => state.profile.profile
                  );
                  let restricted_universe =
                     window.localStorage.getItem("restricted_universe") ===
                        "true" ||
                     (_profileState && _profileState.restricted_universe) ||
                     false;
                  if (restricted_universe) {
                     payloadRequest.skip_cache = true;
                  }

                  let response = yield call(getModelPortfolio, payloadRequest);
                  try {
                     const query_uuid = response.query_uuid;

                     if (response) {
                        let guidanceMessage = response.messages || [];
                        let _defaultFRR = ["fees", "risk", "returns"];

                        // const FRR_DATA_ATTRS = getFRRAttrDataForModels().map(
                        //   kj => kj.attr
                        // )
                        let NEW_QUERY_VAR_OBJECT = {
                           _defaultFRR,
                           _originalQueryVar: response.query_var || [],
                           _relevanceChartTabs: [],
                        };

                        // response.query_var = response.query_var.filter(item => item.view_name != 'Highlight')

                        //get query var from current
                        response._NEW_QUERY_VAR_OBJECT = NEW_QUERY_VAR_OBJECT;

                        const portfolioValue = (yield select(
                           state => state.enhancer
                        )).portfolioValue;

                        // Tabs section: 'Tabs' view name => ['Overlap', 'Risk', 'Returns']
                        // NOTE: default active View index [1, 2, 3]; whereas 1 for Overlap chart
                        // and 2, 3 ... N for remaining 'Risk', 'Return' ... etc.

                        // NOTE: default initial values for 'NEW_QUERY_VAR_OBJECT'
                        NEW_QUERY_VAR_OBJECT._viewIndex = "1"; // 1 ALWAYS stands for chart
                        NEW_QUERY_VAR_OBJECT._defaultFRR = [
                           "fees",
                           "risk",
                           "returns",
                        ];
                        const excludeNodesForFRR = [
                           "correlation",
                           "overlap",
                           "similarity",
                           "fees",
                           "risk",
                           "returns",
                        ];
                        NEW_QUERY_VAR_OBJECT._relevanceChartTabs = [
                           "BurstChart",
                        ];
                        NEW_QUERY_VAR_OBJECT._originalQueryVar = [];

                        if (
                           typeof response.query_var !== "undefined" &&
                           response.query_var.length > 0
                        ) {
                           // // Tabs section: 'Tabs' view name => ['Overlap', 'Risk', 'Returns']
                           // // NOTE: default active View index [1, 2, 3]; whereas 1 for Overlap chart
                           // // and 2, 3 ... N for remaining 'Risk', 'Return' ... etc.
                           // NEW_QUERY_VAR_OBJECT._viewIndex = '1' // 1 ALWAYS stands for chart
                           //
                           // NEW_QUERY_VAR_OBJECT._defaultFRR = ['fees', 'risk', 'returns']
                           // const excludeNodesForFRR = [
                           //   'correlation',
                           //   'overlap',
                           //   'similarity',
                           //   'fees',
                           //   'risk',
                           //   'returns'
                           // ]
                           NEW_QUERY_VAR_OBJECT._originalQueryVar =
                              response.query_var.reverse();

                           const _nodes = NEW_QUERY_VAR_OBJECT._originalQueryVar
                              .filter(
                                 s =>
                                    s.view_name !== "" &&
                                    s.node !== "" &&
                                    !excludeNodesForFRR.includes(s.node)
                              )
                              // .filter((l) => FRR_DATA_ATTRS.includes(l.node))
                              .filter(w => !w.view_name.includes("Highlight"))
                              .slice(0, 2)
                              .map(k => k.node);

                           // uncomment below for dynamic query_var, when Data is ready from backend side
                           // console.log(_nodes);
                           if (_nodes && _nodes.length > 0) {
                              _nodes.map((l, li) => {
                                 if (
                                    typeof NEW_QUERY_VAR_OBJECT._defaultFRR[
                                       li + 1
                                    ] === "undefined"
                                 ) {
                                    NEW_QUERY_VAR_OBJECT._defaultFRR.push(l);
                                 } else {
                                    NEW_QUERY_VAR_OBJECT._defaultFRR[li + 1] =
                                       l; // NEW_QUERY_VAR_OBJECT._defaultFRR: ['Fees', 'Yield', 'Assets'];
                                 }
                              });
                           }

                           const filtr = ["fees", "risk", "returns"].filter(
                              j => !NEW_QUERY_VAR_OBJECT._defaultFRR.includes(j)
                           );

                           NEW_QUERY_VAR_OBJECT._relevanceChartTabs = [
                              // ...chartNodes, // for model case, find among => ['fees', 'risk', 'returns'] which is 'odd-one' out, and then show that 'odd-one' as odometer chart
                              "BurstChart",
                              ...filtr,
                           ];
                           // console.log(NEW_QUERY_VAR_OBJECT);
                           response._NEW_QUERY_VAR_OBJECT =
                              NEW_QUERY_VAR_OBJECT;
                        } else {
                           // NOTE:  when 'query_var' is missing in api use default value
                           response._NEW_QUERY_VAR_OBJECT =
                              NEW_QUERY_VAR_OBJECT;
                           response.query_var = [];
                        }

                        let _highlights = {
                           fee: response.query_var.find(
                              vc => vc.node === "cost"
                           )
                              ? true
                              : response.Highlights
                              ? response.Highlights.feesHighLightEnable
                              : MODEL_QUERY.includes("cost"), // response.Highlights ? response.Highlights.feesHighLightEnable : false,
                           return: response.query_var.find(
                              vc => vc.node === "returns"
                           )
                              ? true
                              : response.Highlights
                              ? response.Highlights.returnHighLightEnable
                              : MODEL_QUERY.includes("returns"),
                           risk: response.query_var.find(
                              vc => vc.node === "risk"
                           )
                              ? true
                              : response.Highlights
                              ? response.Highlights.riskHighLightEnable
                              : MODEL_QUERY.includes("risk"), //response.Highlights ? response.Highlights.returnHighLightEnable : false,
                        };

                        // NOTE: check & handle missing keys from API response for. e.g: 'ESG' query
                        let requiredKeysForModelEnhancement = {
                           date: {},
                           didumean: [],
                           filter_options: {},
                           funds: [],
                           messages: [],
                           overlap: {},
                           overlap_burst_chart: {},
                           q: "",
                           query_log: {},
                           query_uuid: "",
                           query_var: [],
                           query_var_implicit: [],
                           ranking_count: 0,
                           ratio: {},
                           related_queries: [],
                           sponsors_tickers: {},
                           target_card: [],
                           target_overlap_burst_chart: [],
                           themes_used: [],
                        };

                        // console.log('===== response before check & handle condition =====');
                        // console.log(response);
                        // console.log('====================================================');
                        if (
                           response &&
                           Object.keys(response) &&
                           Object.keys(response).length > 0
                        ) {
                           Object.keys(requiredKeysForModelEnhancement).map(
                              reqKey => {
                                 if (
                                    reqKey &&
                                    typeof response[`${reqKey}`] === "undefined"
                                 ) {
                                    response[`${reqKey}`] =
                                       requiredKeysForModelEnhancement[reqKey];
                                 }
                              }
                           );
                        }
                        // console.log('===== response after check & handle condition =====');
                        // console.log(response);
                        // console.log('====================================================');

                        if (response.funds && response.funds.length > 0) {
                           const replacePortfolioWith = response.funds[0];
                           //shares = 	(total*(percent/100))/fund price
                           //total = investment.portfolioValue;
                           //percent = holdings[0].weight
                           //fund price = holdings[0].nav
                           // if shares < 1 then shares = 1
                           // console.log(response.query_var);

                           response.funds.map(item => {
                              item._originalQueryVar =
                                 NEW_QUERY_VAR_OBJECT._originalQueryVar;
                              item._defaultFRR =
                                 NEW_QUERY_VAR_OBJECT._defaultFRR;
                              item._relevanceChartTabs =
                                 NEW_QUERY_VAR_OBJECT._relevanceChartTabs;
                              if (
                                 typeof response.query_var !== "undefined" &&
                                 response.query_var.length > 0
                              ) {
                                 // item._originalQueryVar = NEW_QUERY_VAR_OBJECT._originalQueryVar
                                 // item._defaultFRR = NEW_QUERY_VAR_OBJECT._defaultFRR
                                 // item._relevanceChartTabs = NEW_QUERY_VAR_OBJECT._relevanceChartTabs
                                 const overlapQueryVar =
                                    response.query_var.filter(
                                       v =>
                                          v.chart_type === "odometer" &&
                                          v.variable.includes("overlap")
                                    );
                                 // console.log(overlapQueryVar);
                                 if (overlapQueryVar.length > 0) {
                                    item._queryVar = overlapQueryVar[0];
                                 }
                                 // console.log(item._queryVar);
                              }

                              // NOTE: Looks like both 'response.overlap' and 'response.overlap_burst_chart' data are same
                              // but both are placed under diff keys 'Region' & 'overlap_data'
                              // sometimes 'response.overlap' is empty, when 'response.overlap_burst_chart' is present
                              // which cause 'Overlap' chart to show 'N.A'
                              // ------------------------------------------------------------------------------------------------
                              if (response.overlap) {
                                 // OVERLAP_DATA = response.overlap;
                                 const OVERLAP = response.overlap;
                                 if (
                                    OVERLAP &&
                                    Object.keys(OVERLAP).length > 0
                                 ) {
                                    item["overlap_data"] =
                                       OVERLAP[cn(item, "Ticker")]; //REGIONS_DATA[Object.keys(REGIONS_DATA)[0]];
                                 }
                              }

                              if (response.overlap_burst_chart) {
                                 const REGIONS_DATA =
                                    response.overlap_burst_chart;
                                 if (
                                    REGIONS_DATA &&
                                    Object.keys(REGIONS_DATA).length > 0
                                 ) {
                                    if (
                                       isBurstChartDataValid(
                                          REGIONS_DATA[cn(item, "Ticker")]
                                       )
                                    ) {
                                       item["Region"] =
                                          REGIONS_DATA[cn(item, "Ticker")]; //REGIONS_DATA[Object.keys(REGIONS_DATA)[0]];

                                       // // NOTE: For case -> sometimes 'response.overlap' is empty, when 'response.overlap_burst_chart' is present
                                       // // ----------------------------------------------------------------------------------------------------------------------
                                       // if (response.overlap && Object.keys(response.overlap).length === 0) {
                                       //   item['overlap_data'] = REGIONS_DATA[cn(item, 'Ticker')]
                                       // }
                                       // // ----------------------------------------------------------------------------------------------------------------------
                                    } else {
                                       console.log(
                                          "Invalid Burst Chart data format for portfolio ticker ==>",
                                          cn(item, "Ticker")
                                       );
                                    }

                                    // chart
                                    // console.log(item._relevanceChartTabs);
                                    if (
                                       item._relevanceChartTabs &&
                                       typeof item._relevanceChartTabs !==
                                          "undefined" &&
                                       item._relevanceChartTabs.length > 0
                                    ) {
                                       item.odometerChart =
                                          getOdometerChart({
                                             data: item,
                                             highlights: _highlights,
                                             ticker: item.ticker,
                                             query: MODEL_QUERY,
                                             chartTabs:
                                                item._relevanceChartTabs,
                                             originalQueryVar:
                                                response.query_var,
                                             forModel: true,
                                          }) || null;
                                    }
                                 }
                              }

                              // NOTE: 'asset_alloc' key is required in summary-page after finalize portfolio workflow
                              // currently 'asset_alloc' key is missing in Funds[] in 'get-mp' api, it is coming as 'burst-chart' data not 'overlap' chart data
                              if (
                                 !item["asset_alloc"] ||
                                 typeof item["asset_alloc"] === "undefined"
                              ) {
                                 console.log(
                                    " ---- asset_alloc is missing insuggested portfolio ---- "
                                 );
                                 console.log(item["overlap_data"]);

                                 let assetAllocationDataWillBe = {};

                                 if (
                                    Object.prototype.toString.call(
                                       item["overlap_data"]
                                    ) === "[object Array]" &&
                                    item["overlap_data"].length > 0 &&
                                    item["overlap_data"][0]
                                 ) {
                                    assetAllocationDataWillBe =
                                       item["overlap_data"][0];
                                 } else if (
                                    typeof item["overlap_data"] === "object"
                                 ) {
                                    assetAllocationDataWillBe =
                                       item["overlap_data"];
                                 }

                                 item["asset_alloc"] =
                                    assetAllocationDataWillBe;
                              }
                              // ------------------------------------------------------------------------------------------------

                              if (response.target_overlap_burst_chart) {
                                 TARGET_CARD_REGION_DATA =
                                    response.target_overlap_burst_chart;
                              }

                              if (item.holdings && item.holdings.length > 0) {
                                 item._calculateEnhanceStatsFlag = true;
                                 item.weight = 0;
                                 item.holdings.map(h => {
                                    item.weight += h.weight;
                                    const total = portfolioValue;
                                    const percent = h.weight;
                                    const fundPrice = h.nav || 1;
                                    // console.log(total, percent, fundPrice);
                                    let shares =
                                       (total * (percent / 100)) / fundPrice;
                                    if (shares < 1) {
                                       shares = 1;
                                    }
                                    h._h_quantity = Math.round(shares) || 1;
                                    h._pF = {
                                       quantity: Math.round(shares),
                                       _realTimePrice: h.nav || 1,
                                    };
                                 });

                                 item.holdings = _.orderBy(
                                    item.holdings,
                                    ["weight"],
                                    ["desc"]
                                 );

                                 const total = portfolioValue;
                                 const percent = item.weight;
                                 const fundPrice = item.nav || 1;
                                 // console.log(total, percent, fundPrice);

                                 let shares =
                                    (total * (percent / 100)) / fundPrice;
                                 // console.log(shares);
                                 if (shares < 1) {
                                    shares = 1;
                                 }

                                 item.yield =
                                    item["attribute/fundamental/yield"] ||
                                    item["yield"];
                                 item.track_record =
                                    item["attribute/track_record"] ||
                                    item["track_record"];
                                 item["attribute/track_record"] =
                                    item["attribute/track_record"] ||
                                    item["track_record"];

                                 item._pF = {
                                    quantity: Math.round(shares) || 1,
                                    _realTimePrice: item.nav || 1,
                                 };

                                 if (typeof item.returns !== "undefined") {
                                    item._calculateEnhanceStatsFlag = false;
                                 }
                              }
                           });

                           try {
                              yield put(
                                 getModelPortfoliosByIdSuccess({
                                    query_uuid,
                                    // performanceImpact: {
                                    //   ...(yield select(state => state.enhancer)).performanceImpact,
                                    //   enhancedPerformanceImpact: enhanceStats
                                    // },
                                    enhanceReplace: [
                                       {
                                          query,
                                          replace: replacePortfolioWith.ticker,
                                          selectedPortfolioFunds:
                                             replacePortfolioWith,
                                          ticker: (yield select(
                                             state => state.enhancer
                                          )).modelPortfolios.myCurrentPortfolio
                                             .portfolioDetails.name,
                                       },
                                    ],
                                    // summaryDrawer: true,
                                    modelPortfolios: {
                                       ...(yield select(
                                          state => state.enhancer
                                       )).modelPortfolios,
                                       requestQuery: query,
                                       replacePortfolioOptions: response,
                                       replacePortfolioWith,
                                       myCurrentPortfolio: {
                                          ...(yield select(
                                             state => state.enhancer
                                          )).modelPortfolios.myCurrentPortfolio,
                                          targetCard: {
                                             Region:
                                                TARGET_CARD_REGION_DATA,
                                             chartData: [],
                                             overlap_data: OVERLAP_DATA,
                                             _NEW_QUERY_VAR_OBJECT:
                                                response._NEW_QUERY_VAR_OBJECT,
                                             ...(response.target_card &&
                                                response.target_card[0]),
                                          },
                                          // targetCardData: response.target_card && response.target_card[0]
                                       },
                                    },
                                    guidanceMessage,
                                 })
                              );

                              yield call(delay, 500);

                              // const investmentStates = yield select(getAllInvestmentState);
                              const _enhancerState = yield select(
                                 state => state.enhancer
                              );
                              const _portfolioValue =
                                 _enhancerState.portfolioValue;
                              const {
                                 performanceImpact,
                                 modelPortfolios: _modelPortfolios,
                              } = _enhancerState;
                              let LET_PERFORMANCE_IMPACT_WILL_BE = {
                                 ...performanceImpact,
                              };

                              let portfolioBenchmark =
                                 getPortfolioBenchmarkOfSelectedTicker(
                                    performanceImpact.upDownDataSet,
                                    performanceImpact.selectedBenchmark
                                 );

                              console.log(
                                 "===== currentTargetCard =====>",
                                 _enhancerState.currentTargetCard
                              );
                              // Recalculating currentStats fir performanceImpact based on target_card value from api response
                              // let targetCardData = response.target_card && response.target_card;
                              if (
                                 response.target_card &&
                                 response.target_card.length > 0
                              ) {
                                 let ___currentStats =
                                    yield getUploadedPortfolioStats(
                                       response.target_card,
                                       "",
                                       _portfolioValue,
                                       portfolioBenchmark
                                    );
                                 // console.log("====== ___currentStats ======");
                                 // console.log(___currentStats);
                                 // console.log("=============================");
                                 let _expenseRatio =
                                    _enhancerState.performanceImpact
                                       .currentPerformanceImpact &&
                                    typeof _enhancerState.performanceImpact
                                       .currentPerformanceImpact
                                       .expenseRatio !== "undefined"
                                       ? _enhancerState.performanceImpact
                                            .currentPerformanceImpact
                                            .expenseRatio
                                       : null;
                                 let _fee =
                                    _enhancerState.performanceImpact
                                       .currentPerformanceImpact &&
                                    typeof _enhancerState.performanceImpact
                                       .currentPerformanceImpact.fee !==
                                       "undefined"
                                       ? _enhancerState.performanceImpact
                                            .currentPerformanceImpact.fee
                                       : null;
                                 LET_PERFORMANCE_IMPACT_WILL_BE = {
                                    ...LET_PERFORMANCE_IMPACT_WILL_BE,
                                    currentPerformanceImpact: {
                                       ...___currentStats,
                                       fee: _fee, // annual fee is missing in api response
                                       expenseRatio: _expenseRatio, // expenseRatio is missing in api response
                                    },
                                 };
                              } else {
                                 // if target_card is missing in api response then use current-reducer-state for currentStats value
                                 console.log(
                                    "===== TARGET_CARD is missing in response for current stats calc, using existing state ====="
                                 );
                                 LET_PERFORMANCE_IMPACT_WILL_BE = {
                                    ...LET_PERFORMANCE_IMPACT_WILL_BE,
                                    currentPerformanceImpact:
                                       _enhancerState.performanceImpact
                                          .currentPerformanceImpact,
                                 };
                              }

                              const enhanceStats =
                                 getCalculatedModelPortfolioEnhanceStats(
                                    [replacePortfolioWith],
                                    query,
                                    _portfolioValue,
                                    portfolioBenchmark
                                 );

                              if (
                                 _modelPortfolios &&
                                 _modelPortfolios.myCurrentPortfolio
                              ) {
                                 // const { myCurrentPortfolio: _myCurrentPortfolio } = _modelPortfolios
                                 // const currentPortfolioHoldings = _myCurrentPortfolio.holdings || []

                                 // commenting UI CurrentPFStats calculation for performanceImpact based on holdings
                                 // const currentStats = yield getCurrentPfStats(
                                 //   currentPortfolioHoldings,
                                 //   query,
                                 //   portfolioBenchmark
                                 // )
                                 // console.log("currentStats", currentStats);
                                 // if (currentStats) {
                                 //   LET_PERFORMANCE_IMPACT_WILL_BE = {
                                 //     ...LET_PERFORMANCE_IMPACT_WILL_BE,
                                 //     currentPerformanceImpact: currentStats
                                 //   };
                                 // }

                                 if (enhanceStats) {
                                    console.log(
                                       "models performance enhanceStats -->",
                                       enhanceStats
                                    );
                                    console.log(
                                       "replacePortfolioWith -->",
                                       replacePortfolioWith
                                    );
                                    let assetAllocationDataWillBe = {};
                                    if (
                                       replacePortfolioWith &&
                                       typeof replacePortfolioWith !==
                                          "undefined" &&
                                       typeof replacePortfolioWith.asset_alloc !==
                                          "undefined"
                                    ) {
                                       if (
                                          Object.prototype.toString.call(
                                             replacePortfolioWith.asset_alloc
                                          ) === "[object Array]" &&
                                          replacePortfolioWith.asset_alloc
                                             .length > 0 &&
                                          replacePortfolioWith.asset_alloc[0]
                                       ) {
                                          assetAllocationDataWillBe =
                                             replacePortfolioWith
                                                .asset_alloc[0];
                                       } else if (
                                          typeof replacePortfolioWith.asset_alloc ===
                                          "object"
                                       ) {
                                          assetAllocationDataWillBe =
                                             replacePortfolioWith.asset_alloc;
                                       }
                                    }

                                    let showAsteriskFor =
                                       enhanceStats.showAsteriskFor
                                          ? _.uniqWith(
                                               enhanceStats.showAsteriskFor,
                                               _.isEqual
                                            )
                                          : [];
                                    LET_PERFORMANCE_IMPACT_WILL_BE = {
                                       ...LET_PERFORMANCE_IMPACT_WILL_BE,
                                       enhancedPerformanceImpact: {
                                          ...enhanceStats,
                                          showAsteriskFor,
                                          asset_alloc:
                                             assetAllocationDataWillBe,
                                       },
                                    };
                                 }

                                 let ___modelPortfolios = yield select(
                                    state => state.enhancer.modelPortfolios
                                 );
                                 let __myCurrentPortfolio = yield select(
                                    state =>
                                       state.enhancer.modelPortfolios
                                          .myCurrentPortfolio
                                 );
                                 let __portfolioDetails =
                                    __myCurrentPortfolio.portfolioDetails;

                                 if (
                                    LET_PERFORMANCE_IMPACT_WILL_BE &&
                                    LET_PERFORMANCE_IMPACT_WILL_BE.currentPerformanceImpact
                                 ) {
                                    ___modelPortfolios = {
                                       ...___modelPortfolios,
                                       myCurrentPortfolio: {
                                          ...__myCurrentPortfolio,
                                          portfolioDetails: {
                                             ...__portfolioDetails,
                                             riskFeeReturn:
                                                LET_PERFORMANCE_IMPACT_WILL_BE.currentPerformanceImpact,
                                          },
                                       },
                                    };

                                    // // saw this in redux state.. so adding updated value
                                    // __myCurrentPortfolio = {
                                    //   ...__myCurrentPortfolio,
                                    //   portfolioDetails: {
                                    //     ...__portfolioDetails,
                                    //     riskFeeReturn: LET_PERFORMANCE_IMPACT_WILL_BE.currentPerformanceImpact,
                                    //   },
                                    // };
                                 }

                                 yield put(
                                    setEnhancerState({
                                       performanceImpact:
                                          LET_PERFORMANCE_IMPACT_WILL_BE,
                                       modelPortfolios: ___modelPortfolios,
                                       // myCurrentPortfolio: __myCurrentPortfolio, // saw this in redux state.. so adding updated value
                                    })
                                 );
                              }
                           } catch (e) {
                              console.log(e);
                           }
                        } else {
                           if (response.target_overlap_burst_chart) {
                              TARGET_CARD_REGION_DATA =
                                 response.target_overlap_burst_chart;
                           }
                           console.log("---------- NO FUNDS FOUND ----------");

                           let ___performanceImpact = yield select(
                              state => state.enhancer.performanceImpact
                           );
                           // let ___modelPortfolios = yield select(state => state.enhancer.modelPortfolios);
                           let __myCurrentPortfolio = yield select(
                              state =>
                                 state.enhancer.modelPortfolios
                                    .myCurrentPortfolio
                           );
                           let __portfolioDetails =
                              __myCurrentPortfolio.portfolioDetails;
                           let LET_PERFORMANCE_IMPACT_WILL_BE = {
                              ...___performanceImpact,
                           };

                           if (
                              LET_PERFORMANCE_IMPACT_WILL_BE &&
                              LET_PERFORMANCE_IMPACT_WILL_BE.currentPerformanceImpact
                           ) {
                              // saw this in redux state.. so adding updated value
                              __myCurrentPortfolio = {
                                 ...__myCurrentPortfolio,
                                 portfolioDetails: {
                                    ...__portfolioDetails,
                                    riskFeeReturn:
                                       LET_PERFORMANCE_IMPACT_WILL_BE.currentPerformanceImpact,
                                 },
                              };
                           }

                           yield put(
                              getModelPortfoliosByIdSuccess({
                                 query_uuid,
                                 enhanceReplace: [],
                                 // summaryDrawer: true,
                                 modelPortfolios: {
                                    ...(yield select(state => state.enhancer))
                                       .modelPortfolios,
                                    requestQuery: query,
                                    replacePortfolioOptions: response,
                                    myCurrentPortfolio: {
                                       ...__myCurrentPortfolio,
                                       targetCard: {
                                          Region: TARGET_CARD_REGION_DATA,
                                          chartData: [],
                                          overlap_data: OVERLAP_DATA,
                                          ...(response.target_card &&
                                             response.target_card[0]),
                                       },
                                       // targetCardData: response.target_card && response.target_card[0]
                                    },
                                 },
                                 guidanceMessage,
                              })
                           );
                        }
                     } else {
                        throw new Error("------ failed to response -------");
                     }

                     if (
                        MODEL_QUERY &&
                        addEventToAnalytics &&
                        typeof query_uuid !== "undefined" &&
                        !repeated
                     ) {
                        console.log(" --- addEventToAnalytics triggers --- ");
                        addEventToAnalytics(
                           "Enhancer Query",
                           "Enhancer Query",
                           "ENHANCER_QUERY",
                           { query: MODEL_QUERY, query_uuid },
                           false
                        );
                     }
                  } catch (e) {
                     console.log(e);
                     throw e;
                  }
               }
            } else {
               throw new Error("------ failed to response, Bad request -------");
            }
         }
         // ------------------------------------------------------------------------------------
      } else {
         throw new Error("---- NO PAYLOAD -----");
      }
      if (action.callback && typeof action.callback === "function") {
         action.callback();
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails.status === 400) {
         yield put(
            getModelPortfoliosByIdSuccess({
               pageCrash: errorDetails.data.message,
               enhanceReplace: [],
               modelPortfolios: {
                  myCurrentPortfolio: {
                     portfolioDetails: {
                        name: "",
                     },
                     holdings: [],
                  },
                  replacePortfolioOptions: [],
                  replacePortfolioWith: {},
                  customReplaceMPOptions: {},
               },
            })
         );
      } else {
         yield put(
            getModelPortfoliosByIdSuccess({
               pageCrash: "Failed to process",
               enhanceReplace: [],
               modelPortfolios: {
                  myCurrentPortfolio: {
                     portfolioDetails: {
                        name: "",
                     },
                     holdings: [],
                  },
                  replacePortfolioOptions: [],
                  replacePortfolioWith: {},
                  customReplaceMPOptions: {},
               },
            })
         );
      }
   }
   if (action.callback) {
      action.callback();
   }
}

function* updateCurrentModelPortfolioWith(action) {
   try {
      const { payload } = action;
      if (payload) {
         yield put(replaceCurrentModelPortfolioWithFetch(payload));

         yield put(replaceCurrentModelPortfolioWithSuccess());

         // yield call(delay, 600);
         const res = yield select(getEnhancerState);

         if (res && res.enhanceReplace && res.fundPortfolios && res.query) {
            const {
               performanceImpact,
               // enhanceReplace,
               modelPortfolios,
               query,
               portfolioValue,
            } = res;

            let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(
               performanceImpact.upDownDataSet,
               performanceImpact.selectedBenchmark
            );

            if (
               modelPortfolios.replacePortfolioWith &&
               Object.keys(modelPortfolios.replacePortfolioWith).length === 0
            ) {
               // for de-selecting or no replacement choosen then [hack]
               yield call(delay, 1000);
               yield put(
                  setEnhancerState({
                     enhanceReplace: [],
                     performanceImpact: {
                        ...performanceImpact,
                        enhancedPerformanceImpact: {},
                     },
                  })
               );
            } else {
               const enhanceStats = getCalculatedModelPortfolioEnhanceStats(
                  [modelPortfolios.replacePortfolioWith],
                  query,
                  portfolioValue,
                  portfolioBenchmark
               );
               console.log(
                  "========== PLOTTING PERFORMANCE IMPACT DATA =========="
               );
               console.log(enhanceStats);
               if (enhanceStats) {
                  console.log(
                     "models performance enhanceStats -->",
                     enhanceStats
                  );
                  console.log(
                     "replacePortfolioWith -->",
                     modelPortfolios.replacePortfolioWith
                  );

                  let assetAllocationDataWillBe = {};
                  if (
                     modelPortfolios.replacePortfolioWith &&
                     typeof modelPortfolios.replacePortfolioWith !==
                        "undefined" &&
                     typeof modelPortfolios.replacePortfolioWith.asset_alloc !==
                        "undefined"
                  ) {
                     if (
                        Object.prototype.toString.call(
                           modelPortfolios.replacePortfolioWith.asset_alloc
                        ) === "[object Array]" &&
                        modelPortfolios.replacePortfolioWith.asset_alloc
                           .length > 0 &&
                        modelPortfolios.replacePortfolioWith.asset_alloc[0]
                     ) {
                        assetAllocationDataWillBe =
                           modelPortfolios.replacePortfolioWith.asset_alloc[0];
                     } else if (
                        typeof modelPortfolios.replacePortfolioWith
                           .asset_alloc === "object"
                     ) {
                        assetAllocationDataWillBe =
                           modelPortfolios.replacePortfolioWith.asset_alloc;
                     }
                     enhanceStats.asset_alloc = assetAllocationDataWillBe;
                  }

                  yield put(
                     setEnhancerState({
                        performanceImpact: {
                           ...performanceImpact,
                           enhancedPerformanceImpact: enhanceStats,
                        },
                     })
                  );
               }
            }
         }
      } else {
         throw new Error("callback missing");
      }
   } catch (error) {
      console.log(error);
      yield put(
         deleteFundForEnhanceSuccess({
            pageCrash: "Failed to perform action.",
         })
      );
   }
}

function* deleteClient(data) {
   const { payload } = data;
   try {
      if (payload) {
         yield put(clientDeleteFetch());
         const reponse = yield call(deleteSelectedClients, payload);
         // console.log(reponse);
         if (reponse.status === 200) {
            yield put(getClientListRequest());
            // NOTE: Toast a message on UI
            openNotificationWithIcon({
               duration: 5,
               type: "success",
               message: "Success",
               className: "api-response-notification-class",
               description: "Client Deleted Successfully.",
            });
            yield put(clientDeleteSuccess());
         } else {
            throw new Error("-- Failed to delete client. Check api response. --");
         }
      }
   } catch (error) {
      console.log(error);
      message.success("Something went wrong.", 5);
      yield put(clientDeleteSuccess());
   }
}

function* deletePortfolio(data) {
   const { payload, callback } = data;
   try {
      if (payload) {
         yield put(deletePortfolioByIdFetch());
         const response = yield call(deletePortfolioAPI, payload);
         if (response.message === "File deleted successfully") {
            yield put(getPortfolioListRequest());
            yield put(deletePortfolioByIdSuccess());
         }
         if (callback && typeof callback === "function") {
            callback();
         }
      } else {
         throw new Error("-- payload is missing --");
      }
   } catch (error) {
      if (error.stack) {
         const errorDetails = get(error, "response.data", error.stack);
         console.log(errorDetails);
         yield put(
            deletePortfolioByIdSuccess({
               pageCrash: errorDetails,
            })
         );
         // yield put(actions.failedToPerform(errorDetails))
      } else {
         console.log(error);
         yield put(
            deletePortfolioByIdSuccess({
               pageCrash: error,
            })
         );
      }
   }
}

function* updateEvaluateCheckbox(action) {
   try {
      const { payload } = action;
      if (payload) {
         yield put(updateEvaluateCheckboxFetch(payload));

         // yield call(delay, 600);

         const res = yield select(getEnhancerState);
         const {
            performanceImpact,
            fundPortfolios,
            query,
            enhanceReplace,
            totalTargetCardTickers,
            enhanceTickerFilter,
         } = res;

         if (res && res.enhanceReplace && res.fundPortfolios && res.query) {
            let portfolioBenchmark = getPortfolioBenchmarkOfSelectedTicker(
               performanceImpact.upDownDataSet,
               performanceImpact.selectedBenchmark
            );
            // console.log(portfolioBenchmark);
            // let enhanceTickers = []

            let filteredArray = _.xor(
               totalTargetCardTickers,
               enhanceTickerFilter
            );

            let updatedArray = enhanceReplace.map(item => {
               return item.selectedPortfolioFunds;
            });

            let _uncheckedTickerDataArray = [];
            if (fundPortfolios && fundPortfolios.length > 0) {
               fundPortfolios.map(hg => {
                  if (hg.ticker && enhanceTickerFilter.length > 0) {
                     // NOTE: DO NOT CHANGE THIS
                     const _foundTicker = filteredArray.find(
                        ds => ds === hg.ticker
                     );
                     if (
                        _foundTicker &&
                        hg.target_card &&
                        hg.target_card.funds &&
                        hg.target_card.funds.length > 0
                     ) {
                        const _targetCardData = hg.target_card.funds[0];
                        _uncheckedTickerDataArray.push(_targetCardData);
                     }
                  }
               });
            }

            // console.log(_uncheckedTickerDataArray);
            updatedArray = _.union(
               [...updatedArray],
               [..._uncheckedTickerDataArray]
            );
            // console.log(updatedArray);

            const stats = yield getCalculatedEnhanceStats(
               updatedArray,
               query,
               portfolioBenchmark
            );

            // console.log(stats);
            console.log(
               "========== PLOTTING PERFORMANCE IMPACT DATA =========="
            );
            // if (stats && typeof stats.pfRet1y !== 'undefined') {
            //   yield put(
            //     setEnhancerState({
            //       performanceImpact: {
            //         ...performanceImpact,
            //         enhancedPerformanceImpact: stats
            //       }
            //       // enhanceTickerFilter: enhanceTickers, // NOTE: CAREFUL UNCOMMENTING, it will hid others row.
            //     })
            //   )
            // }

            let enhancedRequestData = updatedArray.map(item => {
               return {
                  ticker: item.ticker,
                  weight: item.weight,
               };
            });
            // console.log(enhancedRequestData);
            if (
               enhancedRequestData &&
               enhancedRequestData.length > 0 &&
               stats
            ) {
               yield put(
                  getEnhanceStatsForSelectedTickerRequest(
                     {
                        portfolioBenchmark,
                        enhancedRequestData,
                        ui_enhancedStats: stats,
                     },
                     res => {
                        console.log(
                           "------- getEnhanceStatsForSelectedTickerRequest res -------"
                        );
                        console.log(res);
                        console.log(
                           "-----------------------------------------------------------"
                        );
                     }
                  )
               );
            }
         }

         yield put(updateEvaluateCheckboxSuccess());
      } else {
         throw new Error("payload missing");
      }
      if (action.callback) {
         const { callback } = action;
         if (callback && typeof callback === "function") {
            callback();
         }
      }
   } catch (error) {
      console.log(error);
      yield put(
         addFundForEnhanceSuccess({
            pageCrash: "Failed to perform action.",
         })
      );
      if (action.callback) {
         const { callback } = action;
         if (callback && typeof callback == "function") {
            callback();
         }
      }
   }
}

// function* getTotumScore() {
//   try {
//     console.log(' --- getTotumScore --- ');
//     const enhancerState = yield select(state => state.enhancer);
//     const { enhanceReplace, totumScore, usingSuggestedFunds, summary } = enhancerState;
//     if (summary && totumScore && totumScore.scoreMeter) {
//       const { portfolioFunds, tickerNotSupported } = summary;
//       const { scoreMeter } = totumScore;
//       let orderedScoreMeter = _.orderBy(scoreMeter.filter((item) => item.value !== null), ['value'],['asc']);
//       let scores = orderedScoreMeter.map((item) => item.value);
//       console.log(scores);
//       if (portfolioFunds && enhanceReplace) {
//         // NOTE: check for 'current' score
//         if (scores.length === 0) {
//           // call request for 'current' score
//           let allData = [...portfolioFunds, ...tickerNotSupported];
//           const requestData = allData.map((item) => {
//             return {
//               ticker: item.ticker,
//               weight: item.weight,
//             };
//           });
//           if (requestData && requestData.length > 0) {
//             yield put(getCurrentPortfolioScoreRequest({
//               requestData,
//               For: 'current',
//             }))
//           }
//         }
//         // NOTE: get for 'enhanced' score
//         let enhancedSelectedFunds = enhanceReplace.map((item) => item.selectedPortfolioFunds) || [];
//         if (enhancedSelectedFunds && enhancedSelectedFunds.length > 0 && usingSuggestedFunds) {
//           let enhanceAllData = [...enhancedSelectedFunds, ...tickerNotSupported];
//           let requestData = enhanceAllData.map((k) => {
//             return {
//               ticker: k.ticker,
//               weight: k.weight,
//             }
//           }) || [];
//           if (requestData && requestData.length > 0) {
//             yield put(getEnhancedPortfolioScoreRequest({
//               requestData,
//               For: 'enhanced',
//             }));
//           }
//         } else {
//           console.log('--- NO ENHANCED REPLACEMENT TICKER FOUND FOR SCORE ---');
//         }
//       }
//     } else {
//       console.log('---  scoreMeter is missing ---');
//     }
//   } catch (error) {
//     console.log(error)
//   }
// }

function* resetFinalizeData(action) {
   try {
      const { payload } = action;
      console.log(payload);
      yield put(resetFinalizeDataFetch(payload));
      yield put(resetFinalizeDataSuccess(payload));
      if (action.callback) {
         const { callback } = action;
         if (callback && typeof callback == "function") {
            callback();
         }
      }
   } catch (error) {
      console.log(error);
      yield put(
         resetFinalizeDataSuccess({
            portfolioList: [],
            pageCrash: "Failed to reset FinalizeData.",
         })
      );
   }
}

function* getMPDetailsCustomReplace({ payload }) {
   try {
      const {
         portfolio_id,
         isSponsored,
         portfolio_name,
         target_pf_id,
         query_var,
      } = payload;
      // console.log(payload)
      yield put(getMPDetailsCustomReplaceFetch());
      let payloadData = {
         portfolio_id,
         query_var,
         pf_type: isSponsored ? `mp` : `up`,
         target_portfolio: target_pf_id,
      };
      // console.log(payloadData)
      const result = yield call(getMPDetailsCustomReplaceAPI, payloadData);
      if (result) {
         // console.log(result)
         yield put(saveMPDetailsCustomReplace(result));

         const currentState = yield select(state => state.enhancer);
         // console.log(currentState)

         // const {
         //   query,
         //   query_var,
         // } = currentState.modelPortfolios.replacePortfolioOptions;
         let query = null,
            query_var = [];

         if (
            currentState.modelPortfolio &&
            currentState.modelPortfolio.replacePortfolioOptions &&
            currentState.modelPortfolios.replacePortfolioOptions.query
         ) {
            query = currentState.modelPortfolios.replacePortfolioOptions.query;
            query_var =
               currentState.modelPortfolios.replacePortfolioOptions.query_var;
         }

         // let TARGET_CARD_REGION_DATA = [], OVERLAP_DATA = [];

         try {
            let response = result;
            let guidanceMessage = response.messages || [];
            let _defaultFRR = ["fees", "risk", "returns"];

            if (response.funds && response.funds.length > 0) {
               response.funds[0].custom_replace_type = isSponsored
                  ? "sponsored"
                  : "user";
               response.funds[0].custom_replace_pf_name = portfolio_name;
            }
            // const FRR_DATA_ATTRS = getFRRAttrDataForModels().map(kj => kj.attr)

            let NEW_QUERY_VAR_OBJECT = {
               _defaultFRR,
               _originalQueryVar: query_var || [],
               _relevanceChartTabs: [],
            };
            //get query var from current
            response._NEW_QUERY_VAR_OBJECT = NEW_QUERY_VAR_OBJECT;

            const portfolioValue = (yield select(state => state.enhancer))
               .portfolioValue;

            // console.log("=========> portfolioValue <=========");
            // console.log(portfolioValue);
            // console.log("=====================================");

            if (response.funds && response.funds.length > 0) {
               const replacePortfolioWith = response.funds[0];
               //shares = 	(total*(percent/100))/fund price
               //total = investment.portfolioValue;
               //percent = holdings[0].weight
               //fund price = holdings[0].nav
               // if shares < 1 then shares = 1

               if (typeof query_var !== "undefined" && query_var.length > 0) {
                  // Tabs section: 'Tabs' view name => ['Overlap', 'Risk', 'Returns']
                  // NOTE: default active View index [1, 2, 3]; whereas 1 for Overlap chart
                  // and 2, 3 ... N for remaining 'Risk', 'Return' ... etc.
                  NEW_QUERY_VAR_OBJECT._viewIndex = "1"; // 1 ALWAYS stands for chart

                  NEW_QUERY_VAR_OBJECT._defaultFRR = [
                     "fees",
                     "risk",
                     "returns",
                  ];
                  const excludeNodesForFRR = [
                     "correlation",
                     "overlap",
                     "similarity",
                     "fees",
                     "risk",
                     "returns",
                  ];
                  NEW_QUERY_VAR_OBJECT._originalQueryVar = query_var.reverse();

                  const _nodes = NEW_QUERY_VAR_OBJECT._originalQueryVar
                     .filter(
                        s =>
                           s.view_name !== "" &&
                           s.node !== "" &&
                           !excludeNodesForFRR.includes(s.node)
                     )
                     // .filter((l) => FRR_DATA_ATTRS.includes(l.node))
                     .filter(w => !w.view_name.includes("Highlight"))
                     .slice(0, 2)
                     .map(k => k.node);
                  // console.log('_nodes', _nodes); // _nodes: ['Yield', 'Assets'];
                  const chartNodes = NEW_QUERY_VAR_OBJECT._originalQueryVar
                     .filter(
                        hg =>
                           hg.variable.includes("overlap") ||
                           hg.variable.includes("correlation")
                     )
                     .filter(w => !w.view_name.includes("Highlight"))
                     .map(k => k.view_name);
                  // console.log('chart Nodes', chartNodes);

                  // uncomment below for dynamic query_var, when Data is ready from backend side

                  if (_nodes && _nodes.length > 0) {
                     _nodes.map((l, li) => {
                        if (
                           typeof NEW_QUERY_VAR_OBJECT._defaultFRR[li + 1] ===
                           "undefined"
                        ) {
                           NEW_QUERY_VAR_OBJECT._defaultFRR.push(l);
                        } else {
                           NEW_QUERY_VAR_OBJECT._defaultFRR[li + 1] = l; // NEW_QUERY_VAR_OBJECT._defaultFRR: ['Fees', 'Yield', 'Assets'];
                        }
                     });
                  }

                  const filtr = ["fees", "risk", "returns"].filter(
                     j => !NEW_QUERY_VAR_OBJECT._defaultFRR.includes(j)
                  );

                  NEW_QUERY_VAR_OBJECT._relevanceChartTabs = [
                     ...chartNodes,
                     ...filtr,
                  ];
                  // console.log(NEW_QUERY_VAR_OBJECT)
                  response._NEW_QUERY_VAR_OBJECT = NEW_QUERY_VAR_OBJECT;
               }

               // console.log('======= NEW_QUERY_VAR_OBJECT =======');
               // console.log(NEW_QUERY_VAR_OBJECT);
               // console.log('====================================');

               response.funds.map(item => {
                  item._originalQueryVar =
                     NEW_QUERY_VAR_OBJECT._originalQueryVar || [];
                  item._defaultFRR = NEW_QUERY_VAR_OBJECT._defaultFRR || [];
                  item._relevanceChartTabs =
                     NEW_QUERY_VAR_OBJECT._relevanceChartTabs || [];

                  if (query_var !== "undefined" && query_var.length > 0) {
                     // item._originalQueryVar = NEW_QUERY_VAR_OBJECT._originalQueryVar
                     // item._defaultFRR = NEW_QUERY_VAR_OBJECT._defaultFRR
                     // item._relevanceChartTabs = NEW_QUERY_VAR_OBJECT._relevanceChartTabs
                     const overlapQueryVar = query_var.filter(
                        v =>
                           v.chart_type === "odometer" &&
                           v.variable.includes("overlap")
                     );
                     // console.log(overlapQueryVar);
                     if (overlapQueryVar.length > 0) {
                        item._queryVar = overlapQueryVar[0];
                     }
                     // console.log(item._queryVar);
                  }

                  if (response.overlap_burst_chart) {
                     const REGIONS_DATA = response.overlap_burst_chart;
                     if (REGIONS_DATA && Object.keys(REGIONS_DATA).length > 0) {
                        item["Region"] = REGIONS_DATA[cn(item, "Ticker")]; //REGIONS_DATA[Object.keys(REGIONS_DATA)[0]];
                     }
                  }

                  // if (response.target_overlap_burst_chart) {
                  //   TARGET_CARD_REGION_DATA = response.target_overlap_burst_chart
                  // }

                  if (response.overlap) {
                     // OVERLAP_DATA = response.overlap;
                     const OVERLAP = response.overlap;
                     if (OVERLAP && Object.keys(OVERLAP).length > 0) {
                        item["overlap_data"] = OVERLAP[cn(item, "Ticker")]; //REGIONS_DATA[Object.keys(REGIONS_DATA)[0]];
                     }
                  }

                  if (item.holdings && item.holdings.length > 0) {
                     item._calculateEnhanceStatsFlag = true;
                     item.weight = 0;
                     item.holdings.map(h => {
                        item.weight += h.weight;
                        const total = portfolioValue;
                        const percent = h.weight;
                        const fundPrice = h.nav || 1;
                        // console.log(total, percent, fundPrice);
                        let shares = (total * (percent / 100)) / fundPrice;
                        if (shares < 1) {
                           shares = 1;
                        }
                        h._h_quantity = Math.round(shares) || 1;
                        h._pF = {
                           quantity: Math.round(shares),
                           _realTimePrice: h.nav || 1,
                        };
                     });

                     item.holdings = _.orderBy(
                        item.holdings,
                        ["weight"],
                        ["desc"]
                     );

                     const total = portfolioValue;
                     const percent = item.weight;
                     const fundPrice = item.nav || 1;
                     // console.log(total, percent, fundPrice);

                     let shares = (total * (percent / 100)) / fundPrice;
                     // console.log(shares);
                     if (shares < 1) {
                        shares = 1;
                     }

                     item._pF = {
                        quantity: Math.round(shares) || 1,
                        _realTimePrice: item.nav || 1,
                     };

                     if (typeof item.returns !== "undefined") {
                        item._calculateEnhanceStatsFlag = false;
                     }
                  }
               });

               try {
                  yield put(getMPDetailsCustomReplaceSuccess());
                  yield put(
                     getModelPortfoliosByIdSuccess({
                        performanceImpact: {
                           ...(yield select(state => state.enhancer))
                              .performanceImpact,
                           // enhancedPerformanceImpact: enhanceStats,
                        },
                        enhanceReplace: [
                           {
                              query,
                              replace: replacePortfolioWith.ticker,
                              selectedPortfolioFunds: replacePortfolioWith,
                              ticker: (yield select(state => state.enhancer))
                                 .modelPortfolios.myCurrentPortfolio
                                 .portfolioDetails.name,
                           },
                        ],
                        // summaryDrawer: true,
                        modelPortfolios: {
                           ...(yield select(state => state.enhancer))
                              .modelPortfolios,
                           requestQuery: query,
                           // replacePortfolioOptions: response,
                           customReplaceMPOptions: response,
                           replacePortfolioWith,
                           isC_Replace_Sponsored: isSponsored,
                           cReplace_portfolio_name: portfolio_name,
                        },
                        guidanceMessage,
                     })
                  );

                  yield call(delay, 600);

                  // const investmentStates = yield select(getAllInvestmentState);
                  const _enhancerState = yield select(state => state.enhancer);
                  const _portfolioValue = _enhancerState.portfolioValue;

                  const { performanceImpact } = _enhancerState;

                  let portfolioBenchmark =
                     getPortfolioBenchmarkOfSelectedTicker(
                        performanceImpact.upDownDataSet,
                        performanceImpact.selectedBenchmark
                     );

                  const enhanceStats = getCalculatedModelPortfolioEnhanceStats(
                     [replacePortfolioWith],
                     query,
                     _portfolioValue,
                     portfolioBenchmark
                  );
                  // console.log(enhanceStats)

                  if (enhanceStats) {
                     let assetAllocationDataWillBe = {};
                     if (
                        replacePortfolioWith &&
                        typeof replacePortfolioWith !== "undefined" &&
                        typeof replacePortfolioWith.asset_alloc !== "undefined"
                     ) {
                        if (
                           Object.prototype.toString.call(
                              replacePortfolioWith.asset_alloc
                           ) === "[object Array]" &&
                           replacePortfolioWith.asset_alloc.length > 0 &&
                           replacePortfolioWith.asset_alloc[0]
                        ) {
                           assetAllocationDataWillBe =
                              replacePortfolioWith.asset_alloc[0];
                        } else if (
                           typeof replacePortfolioWith.asset_alloc === "object"
                        ) {
                           assetAllocationDataWillBe =
                              replacePortfolioWith.asset_alloc;
                        }
                        enhanceStats.asset_alloc = assetAllocationDataWillBe;
                     }
                     yield put(
                        setEnhancerState({
                           performanceImpact: {
                              ...performanceImpact,
                              enhancedPerformanceImpact: enhanceStats,
                           },
                        })
                     );
                  }
               } catch (e) {
                  console.log(e);
                  yield put(getMPDetailsCustomReplaceSuccess());
               }
            } else {
               // if (response.target_overlap_burst_chart) {
               //   TARGET_CARD_REGION_DATA = response.target_overlap_burst_chart
               // }
               yield put(getMPDetailsCustomReplaceSuccess());
               console.log("---------- NO FUNDS FOUND ----------");
               yield put(
                  getModelPortfoliosByIdSuccess({
                     enhanceReplace: [],
                     // summaryDrawer: true,
                     customReplaceMPOptions: {},
                  })
               );
            }
         } catch (e) {
            yield put(getMPDetailsCustomReplaceSuccess());
            console.log(e);
            throw e;
         }
      }
   } catch (error) {
      yield put(getMPDetailsCustomReplaceSuccess());
      console.log(error);
      // if (error.stack) {
      //   const errorDetails = get(error, 'response.data', error.stack)
      //   console.log(errorDetails)
      //   yield put(deleteMPPortfolioByIdSuccess({
      //     pageCrash: errorDetails
      //   }));
      //   // yield put(actions.failedToPerform(errorDetails))
      // } else {
      //   console.log(error)
      //   yield put(deletePortfolioByIdSuccess({
      //     pageCrash: error
      //   }));
      // }
   }
}

function* usingSuggestedFunds(action) {
   try {
      const { payload } = action;
      if (payload) {
         yield put(usingSuggestedFundsFetch());
         yield put(usingSuggestedFundsSuccess(payload));
      } else {
         throw new Error("callback missing");
      }
   } catch (error) {
      console.log(error);
      yield put(
         usingSuggestedFundsSuccess({
            pageCrash: "Failed to perform action.",
         })
      );
   }
}

function* checkTotumAdvisor() {
   try {
      yield put(checkTotumAdvisorFetch());
      const result = yield call(addTotumAdvisorApi);
      if (result) {
         yield put(checkTotumAdvisorSuccess());
      } else {
         throw new Error("-- totum api failed to respond --");
      }
   } catch (error) {
      console.log(error);
      yield put(
         checkTotumAdvisorSuccess({
            pageCrash: "Failed to perform action.",
         })
      );
   }
}

function* sendEmailTotumAdvisor(action) {
   try {
      yield put(submitTotumAdvisorFetch());
      const { payload, callback } = action;
      if (payload) {
         const { email, first_name, last_name, client_id } = payload;
         if (email && first_name && last_name && client_id) {
            const response = yield call(sendEmailAdvisorApi, payload);
            if (response) {
               yield put(submitTotumAdvisorSuccess(response));
               if (callback) {
                  callback();
               }
            } else {
               throw new Error("-- totum advisor email api failed to respond --");
            }
         } else {
            throw new Error("-- email, name or client_id missing for totum advisor email api");
         }
      } else {
         throw new Error("-- payload missing for totum advisor email api --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         yield put(
            submitTotumAdvisorSuccess({
               pageCrash: errorDetails.data.message,
            })
         );
      } else {
         yield put(
            submitTotumAdvisorSuccess({
               pageCrash: error,
            })
         );
      }
   }
   if (action.callback) {
      action.callback();
   }
}

// function* getPortfolioScore(action) {
//   try {
//     yield put(getPortfolioScoreFetch());
//     const { payload, callback } = action
//     if (payload) {
//       const { requestData, For = 'current' } = payload;
//       if (requestData) {
//         const response = yield call(portfolioScoreApi, requestData);
//         const totumScoreState = yield select(state => state.enhancer.totumScore);
//         if (response && response.result) {
//           let scoreMeter = totumScoreState.scoreMeter;
//           scoreMeter.map((item) => {
//             if (For === item.key) {
//               item.value = typeof response.result.score !== 'undefined' ? response.result.score : item.value;
//             }
//           });
//           yield put(getPortfolioScoreSuccess({
//             totumScore: {
//               ...totumScoreState,
//               scoreMeter,
//               // ...response.result,
//             }
//           }))
//           if(callback) {
//             callback();
//           }
//         } else {
//           throw '-- totum score api failed to respond / result key is missing in api response --'
//         }
//       } else {
//         throw '-- portfolio_id for totum score api'
//       }
//     } else {
//       throw '-- payload missing for totum score api --'
//     }
//   } catch (error) {
//     console.log(error)
//     const errorDetails = get(error, 'response', error.stack)
//     if (errorDetails && errorDetails.status == 400) {
//       yield put(
//         getPortfolioScoreSuccess({
//           pageCrash: errorDetails.data.message,
//         })
//       )
//     } else {
//       yield put(
//         getPortfolioScoreSuccess({
//           pageCrash: error,
//         })
//       )
//     }
//   }
//   if(action.callback) {
//     action.callback();
//   }
// }

function* getCurrentPortfolioScore(action) {
   try {
      const { payload, callback } = action;
      if (payload) {
         const { requestData, For = "current" } = payload;
         if (requestData) {
            yield put(getCurrentPortfolioScoreFetch());
            const response = yield call(portfolioScoreApi, requestData);
            if (
               response &&
               response.result &&
               typeof response.result.score !== "undefined"
            ) {
               yield put(
                  getCurrentPortfolioScoreSuccess({
                     For,
                     score: response.result.score,
                  })
               );
               // yield call(delay, 400);
               // calling extra dispatch action -> to trigger componentDidUpdate() in ToTumScore component [hack]
               const totumScoreState = yield select(
                  state => state.enhancer.totumScore
               );
               yield put(
                  setEnhancerState({
                     totumScore: totumScoreState,
                  })
               );
               if (callback) {
                  callback();
               }
            } else {
               throw new Error("-- totum score api failed to respond / result key is missing in api response --");
            }
         } else {
            throw new Error("-- portfolio_id for totum score api");
         }
      } else {
         throw new Error("-- payload missing for totum score api --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         yield put(
            getCurrentPortfolioScoreSuccess({
               pageCrash: errorDetails.data.message,
            })
         );
      } else {
         yield put(
            getCurrentPortfolioScoreSuccess({
               pageCrash: error,
            })
         );
      }
   }
   if (action.callback) {
      action.callback();
   }
}

function* getEnhancedPortfolioScore(action) {
   try {
      const { payload, callback } = action;
      if (payload) {
         const { requestData, For = "enhanced" } = payload;
         if (requestData) {
            yield put(getEnhancedPortfolioScoreFetch());
            const response = yield call(portfolioScoreApi, requestData);
            if (
               response &&
               response.result &&
               typeof response.result.score !== "undefined"
            ) {
               yield put(
                  getEnhancedPortfolioScoreSuccess({
                     For,
                     score: response.result.score,
                  })
               );
               // yield call(delay, 400);
               // calling extra dispatch action -> to trigger componentDidUpdate() in ToTumScore component [hack]
               const totumScoreState = yield select(
                  state => state.enhancer.totumScore
               );
               yield put(
                  setEnhancerState({
                     totumScore: totumScoreState,
                  })
               );
               if (callback) {
                  callback();
               }
            } else {
               throw new Error("-- totum score api failed to respond / result key is missing in api response --");
            }
         } else {
            throw new Error("-- portfolio_id for totum score api");
         }
      } else {
         throw new Error("-- payload missing for totum score api --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         yield put(
            getEnhancedPortfolioScoreSuccess({
               pageCrash: errorDetails.data.message,
            })
         );
      } else {
         yield put(
            getEnhancedPortfolioScoreSuccess({
               pageCrash: error,
            })
         );
      }
   }
   if (action.callback) {
      action.callback();
   }
}

function* getCurrentPortolioStats(action) {
   const { payload, callback } = action;
   const enhancerState = yield select(getEnhancerState);
   try {
      if (payload) {
         yield put(getCurrentPortfolioStatsFetch());
         // console.log(payload)
         const {
            filename,
            portfolio_type,
            portfolioBenchmark,
            ui_currentStats,
            portfolioSource,
            portfolioId,
         } = payload;
         if (filename && portfolio_type) {
            let [portfolioStats] = yield all([
               call(getPortfolioStats, {
                  portfolio_name: filename,
                  portfolio_type,
               }),
            ]);
            if (portfolioStats && portfolioStats.length > 0) {
               // const currentDate = moment.utc(moment()).format(); // moment.utc(moment()).format('YYYY-MM-DD'); // NOTE: DO NOT CHANGE 'YYYY-MM-DD' format
               // const portfolioLastUpdated = (portfolioStats[0].perf_last_updated !== null) ? moment.utc(portfolioStats[0].perf_last_updated).format('YYYY-MM-DD') : null; // NOTE: DO NOT CHANGE 'YYYY-MM-DD' format
               // const loadPortfolio = portfolioLastUpdated !== null ? moment(currentDate).isAfter(portfolioLastUpdated) : null;

               // const loadPortfolio = (
               //   moment.duration(
               //     moment.utc(
               //       moment()
               //     ).format()
               //   ).diff(
               //     moment.utc(portfolioStats[0].perf_last_updated).format()
               //   )
               // ).asHours() > 24

               // console.log(enhancerState.portfolioType, portfolioSource, enhancerState.portfolioId, portfolioId);

               const currentDate = moment.utc(moment()).format();
               const portfolioLastUpdated =
                  portfolioStats[0].perf_last_updated !== null
                     ? moment.utc(portfolioStats[0].perf_last_updated).format()
                     : null;
               const loadPortfolio =
                  moment
                     .duration(moment(currentDate).diff(portfolioLastUpdated))
                     .asHours() > 24;

               // console.log('currentDate --> ', currentDate);
               // console.log('portfolioLastUpdated --> ', portfolioLastUpdated);

               // check if portfolio_id and portfolio_type changed or not? to stop recursive api interval call.
               if (
                  enhancerState.portfolioType !== portfolioSource ||
                  enhancerState.portfolioId !== portfolioId
               ) {
                  // NOTE: Terminating recursive interval api call, coz portfolio_id and portfolio_type has changed
                  yield put(
                     getCurrentPortfolioStatsSuccess({
                        notification:
                           "Terminating recursive interval api call, coz portfolio_id and portfolio_type has changed",
                     })
                  );
                  if (
                     enhancerState.portfolioType &&
                     enhancerState.portfolioId &&
                     enhancerState.portfolioName
                  ) {
                     yield call(delay, 1000);
                     yield getCurrentPortolioStats(action);
                  } else {
                     if (callback && typeof callback === "function") {
                        callback();
                     }
                  }
               } else if (
                  (portfolioStats[0] &&
                     portfolioStats[0].perf_last_updated === null) ||
                  loadPortfolio ||
                  loadPortfolio === null
               ) {
                  yield call(delay, 1000);
                  yield getCurrentPortolioStats(action);
               } else {
                  let { portfolioValue } = enhancerState;
                  let _currentStats = yield getUploadedPortfolioStats(
                     portfolioStats,
                     "",
                     portfolioValue,
                     portfolioBenchmark
                  );
                  portfolioStats =
                     portfolioStats &&
                     portfolioStats.length > 0 &&
                     portfolioStats[0]
                        ? [
                             {
                                ...portfolioStats[0],
                                region: portfolioStats[0]["Region"] || [],
                                assetTypeData:
                                   portfolioStats[0].asset_alloc || {},
                             },
                          ]
                        : portfolioStats;
                  yield put(
                     getCurrentPortfolioStatsSuccess({
                        currentTargetCard: portfolioStats,
                        performanceImpact: {
                           ...enhancerState.performanceImpact,
                           currentPerformanceImpact: {
                              ..._currentStats,
                              // some data calculations can only be calculated by portfolio holdings
                              averageCostAdjRet10Yr:
                                 ui_currentStats.averageCostAdjRet10Yr,
                           },
                        },
                     })
                  );
                  if (callback && typeof callback === "function") {
                     callback(_currentStats);
                  }
               }
            } else {
               throw new Error("-- portfolioStats api issue, calculating performance by using Holdings --");
            }
         } else {
            throw new Error("-- filename and portfolio_type missing, calculating performance by using Holdings --");
         }
      } else {
         throw new Error("-- payload missing --");
      }
   } catch (error) {
      let errorDetails = error;
      console.log(errorDetails);
      if (get(error, "response.data", error.stack)) {
         errorDetails = get(error, "response.data", error.stack);
      }
      yield put(
         getCurrentPortfolioStatsSuccess({
            pageCrash: errorDetails,
            performanceImpact: {
               ...enhancerState.performanceImpact,
               currentPerformanceImpact: payload.ui_currentStats
                  ? payload.ui_currentStats
                  : enhancerState.performanceImpact.currentPerformanceImpact,
            },
         })
      );
      if (callback && typeof callback === "function") {
         callback();
      }
   }
}

function* getEnhancedStatsForSelectedTicker(action) {
   const { payload, callback } = action;
   let enhancerState = yield select(getEnhancerState);
   let { performanceImpact, portfolioValue } = enhancerState;
   let lastUpdateCheckAllow = true;
   try {
      if (payload) {
         yield put(getEnhanceStatsForSelectedTickerFetch());

         // NOTE: CHECK for currentPerformanceImpact data; If not present then call an API and calculate data points.
         // NOTE: And also check is there any on-going api call for fetching target-card stats data ?? if yes then wait for its response then proceed.
         let getTargetCardAPIExecutionState = yield select(
            state => state.loading["GET_CURRENT_PORTFOLIO_STATS"]
         );

         if (getTargetCardAPIExecutionState) {
            // NOTE: On-going api call for fetching target-card stats data! wait for it to respond.
            console.log(
               "-------------------> On-going api call for fetching target-card stats data! wait for it to respond. <-------------------"
            );
            yield call(delay, 1000);
            yield getEnhancedStatsForSelectedTicker(action);
            return;
         }

         enhancerState = yield select(getEnhancerState);
         performanceImpact = enhancerState.performanceImpact;

         let {
            filename,
            portfolioId,
            portfolio_type,
            portfolioSource,
            ui_currentStats,
            ui_enhancedStats,
            portfolioBenchmark,
            enhancedRequestData,
         } = payload;

         if (portfolioBenchmark && enhancedRequestData) {
            let _currentStats = performanceImpact.currentPerformanceImpact;
            let _portfolioStatsAPIResponse =
               enhancerState.currentTargetCard || null;

            if (
               filename &&
               portfolioId &&
               portfolio_type &&
               portfolioSource &&
               ui_currentStats &&
               performanceImpact &&
               performanceImpact.currentPerformanceImpact &&
               Object.keys(performanceImpact.currentPerformanceImpact)
                  .length === 0 &&
               !getTargetCardAPIExecutionState
            ) {
               let [currentPortStats] = yield all([
                  call(getPortfolioStats, {
                     portfolio_name: filename,
                     portfolio_type,
                  }),
               ]);
               if (currentPortStats && currentPortStats.length > 0) {
                  // const currentDate = moment().format('YYYY-MM-DD');
                  // const portfolioLastUpdated = (currentPortStats[0].perf_last_updated !== null) ? moment(currentPortStats[0].perf_last_updated).format('YYYY-MM-DD') : null;
                  // const loadPortfolio = portfolioLastUpdated !== null ? moment(currentDate).isAfter(portfolioLastUpdated, 'day') : null;

                  // const currentDate =  moment.utc(moment()).format(); // moment.utc(moment()).format('YYYY-MM-DD'); // NOTE: DO NOT CHANGE 'YYYY-MM-DD' format
                  // // const portfolioLastUpdated = (currentPortStats[0].perf_last_updated !== null) ? moment.utc(currentPortStats[0].perf_last_updated).format('YYYY-MM-DD') : null; // NOTE: DO NOT CHANGE 'YYYY-MM-DD' format
                  // // const loadPortfolio = portfolioLastUpdated !== null ? moment(currentDate).isAfter(portfolioLastUpdated) : null;
                  // const portfolioLastUpdated = (currentPortStats[0].perf_last_updated !== null) ? moment.utc(currentPortStats[0].perf_last_updated).format() : null;
                  // const loadPortfolio = portfolioLastUpdated !== null ? currentDate.diff(portfolioLastUpdated).asHours() > 24 : null;

                  // const loadPortfolio = (
                  //   moment.duration(
                  //     moment.utc(
                  //       moment()
                  //     ).format()
                  //   ).diff(
                  //     moment.utc(currentPortStats[0].perf_last_updated).format()
                  //   )
                  // ).asHours() > 24

                  const currentDate = moment.utc(moment()).format();
                  const portfolioLastUpdated =
                     currentPortStats[0].perf_last_updated !== null
                        ? moment
                             .utc(currentPortStats[0].perf_last_updated)
                             .format()
                        : null;
                  const loadPortfolio =
                     moment
                        .duration(
                           moment(currentDate).diff(portfolioLastUpdated)
                        )
                        .asHours() > 24;

                  // check if portfolio_id and portfolio_type changed or not? to stop recursive api interval call.
                  if (
                     enhancerState.portfolioType !== portfolioSource ||
                     enhancerState.portfolioId !== portfolioId
                  ) {
                     // NOTE: Terminating recursive interval api call, coz portfolio_id and portfolio_type has changed
                     yield put(
                        getCurrentPortfolioStatsSuccess({
                           notification:
                              "Terminating recursive interval api call, coz portfolio_id and portfolio_type has changed",
                        })
                     );
                     if (
                        enhancerState.portfolioType &&
                        enhancerState.portfolioId &&
                        enhancerState.portfolioName
                     ) {
                        lastUpdateCheckAllow = false;
                        yield call(delay, 1000);
                        yield getEnhancedStatsForSelectedTicker(action);
                        return;
                     } else {
                        if (callback && typeof callback === "function") {
                           callback();
                           return;
                        }
                     }
                  } else if (
                     (currentPortStats[0] &&
                        currentPortStats[0].perf_last_updated === null) ||
                     loadPortfolio ||
                     loadPortfolio === null
                  ) {
                     yield call(delay, 1000);
                     yield getEnhancedStatsForSelectedTicker(action);
                     return;
                  }
                  _portfolioStatsAPIResponse = currentPortStats;
                  console.log(
                     "=== currentPortStats from api ===> ",
                     _portfolioStatsAPIResponse
                  );
                  _currentStats = yield getUploadedPortfolioStats(
                     currentPortStats,
                     "",
                     portfolioValue,
                     portfolioBenchmark
                  );
               } else {
                  console.log(
                     "-- portfolioStats api issue, calculating performance by using Holdings --"
                  );
                  _currentStats = ui_currentStats;
               }
            } else {
               // _currentStats (currentPerformanceImpact) already present from API
               console.log(
                  "--- Issue on getting current Performance impact data from API ---",
                  performanceImpact.currentPerformanceImpact
               );
               // console.log(filename ,
               //   portfolioId ,
               //   portfolio_type ,
               //   portfolioSource ,
               //   ui_currentStats ,
               //   performanceImpact ,
               //   performanceImpact.currentPerformanceImpact ,
               //   Object.keys(performanceImpact.currentPerformanceImpact).length === 0 ,
               //   !getTargetCardAPIExecutionState);
               _currentStats = performanceImpact.currentPerformanceImpact;
            }

            yield call(delay, 500);
            // NOTE: check for Missing targetCard in enhancedRequestData.
            // console.log('enhancedRequestData --> ', enhancedRequestData);
            let ___enhancedRequestData = JSON.parse(
               JSON.stringify(enhancedRequestData)
            );
            let __portfolioFunds =
               enhancerState &&
               enhancerState.summary &&
               enhancerState.summary.portfolioFunds
                  ? enhancerState.summary.portfolioFunds
                  : [];
            // console.log('__portfolioFunds --> ', __portfolioFunds);
            if (
               enhancerState &&
               enhancerState.enhanceTickerFilter &&
               ___enhancedRequestData.length !== __portfolioFunds.length
            ) {
               __portfolioFunds.forEach(item => {
                  if (
                     !enhancerState.enhanceTickerFilter.includes(item.ticker)
                  ) {
                     console.log(
                        " --  found missing ticker --> ",
                        item.ticker,
                        item.weight
                     );
                     ___enhancedRequestData = [
                        ...___enhancedRequestData,
                        {
                           ticker: item.ticker,
                           weight: item.weight,
                        },
                     ];
                  }
               });
            }
            // console.log('enhancedRequestData --> ', ___enhancedRequestData);
            yield call(delay, 500);

            // NOTE: CHECK for enhancedPerformanceImpact data; If not present then call an API and calculate data points.
            let _enhancerStatsAPIResponse = null;
            if (lastUpdateCheckAllow) {
               _enhancerStatsAPIResponse = yield call(
                  getStatsForSelectedTicker,
                  { holdings: ___enhancedRequestData }
               );
            } else {
               _enhancerStatsAPIResponse = null;
            }
            // console.log(_enhancerStatsAPIResponse);

            if (
               _enhancerStatsAPIResponse &&
               Object.keys(_enhancerStatsAPIResponse).length > 0
            ) {
               _enhancerStatsAPIResponse = [_enhancerStatsAPIResponse];
               let __enhancedStats = yield getUploadedPortfolioStats(
                  _enhancerStatsAPIResponse,
                  "",
                  portfolioValue,
                  portfolioBenchmark
               );
               // console.log('=== ui_currentStats ===>', ui_currentStats);
               // console.log('=== ui_enhancedStats ===>', ui_enhancedStats);
               // console.log('=== __enhancedStats ===>', __enhancedStats);
               if (__enhancedStats) {
                  if (ui_currentStats) {
                     if (ui_enhancedStats) {
                        const enhaState = yield select(getEnhancerState);
                        let { enhanceReplace } = enhaState;
                        // console.log(enhanceReplace, enhancedRequestData, 'data is here man')
                        let enahcedChangeStatus = false;
                        enhancedRequestData.forEach(rnR => {
                           let isAvail = enhanceReplace.filter(
                              enhR => enhR.replaceTicker === rnR.ticker
                           );
                           // console.log(isAvail)
                           if (isAvail.length === 0) {
                              enahcedChangeStatus = true;
                           }
                        });
                        if (enahcedChangeStatus) {
                           yield put(
                              getEnhanceStatsForSelectedTickerSuccess({
                                 currentTargetCard: _portfolioStatsAPIResponse,
                                 performanceImpact: {
                                    ...enhaState.performanceImpact,
                                    currentPerformanceImpact: {
                                       ..._currentStats,
                                       // some data calculations can only be calculated by portfolio holdings
                                       averageCostAdjRet10Yr:
                                          ui_currentStats.averageCostAdjRet10Yr ||
                                          null,
                                       // fee: ui_currentStats.fee || null,
                                       // expenseRatio: ui_currentStats.expenseRatio || null,
                                    },
                                 },
                              })
                           );
                        } else {
                           yield put(
                              getEnhanceStatsForSelectedTickerSuccess({
                                 currentTargetCard: _portfolioStatsAPIResponse,
                                 performanceImpact: {
                                    ...enhancerState.performanceImpact,
                                    currentPerformanceImpact: {
                                       ..._currentStats,
                                       // some data calculations can only be calculated by portfolio holdings
                                       averageCostAdjRet10Yr:
                                          ui_currentStats.averageCostAdjRet10Yr ||
                                          null,
                                       // fee: ui_currentStats.fee || null,
                                       // expenseRatio: ui_currentStats.expenseRatio || null,
                                    },
                                    enhancedPerformanceImpact: {
                                       ...__enhancedStats, // api value
                                       // some data calculations can only be calculated by portfolio holdings
                                       averageCostAdjRet10Yr:
                                          ui_enhancedStats.averageCostAdjRet10Yr ||
                                          null,
                                       // fee: ui_enhancedStats.fee || null,
                                       // expenseRatio: ui_enhancedStats.expenseRatio || null,
                                    },
                                 },
                              })
                           );
                        }
                     } else {
                        yield put(
                           getEnhanceStatsForSelectedTickerSuccess({
                              currentTargetCard: _portfolioStatsAPIResponse,
                              performanceImpact: {
                                 ...enhancerState.performanceImpact,
                                 currentPerformanceImpact: {
                                    ..._currentStats,
                                    // some data calculations can only be calculated by portfolio holdings
                                    averageCostAdjRet10Yr:
                                       ui_currentStats.averageCostAdjRet10Yr ||
                                       null,
                                    // fee: ui_currentStats.fee || null,
                                    // expenseRatio: ui_currentStats.expenseRatio || null,
                                 },
                                 enhancedPerformanceImpact: __enhancedStats,
                              },
                           })
                        );
                     }
                  } else {
                     if (ui_enhancedStats) {
                        yield put(
                           getEnhanceStatsForSelectedTickerSuccess({
                              currentTargetCard: _portfolioStatsAPIResponse,
                              performanceImpact: {
                                 ...enhancerState.performanceImpact,
                                 currentPerformanceImpact: _currentStats,
                                 enhancedPerformanceImpact: {
                                    ...__enhancedStats, // api value
                                    // some data calculations can only be calculated by portfolio holdings
                                    averageCostAdjRet10Yr:
                                       ui_enhancedStats.averageCostAdjRet10Yr ||
                                       null,
                                    // fee: ui_enhancedStats.fee || null,
                                    // expenseRatio: ui_enhancedStats.expenseRatio || null,
                                 },
                              },
                           })
                        );
                     } else {
                        yield put(
                           getEnhanceStatsForSelectedTickerSuccess({
                              currentTargetCard: _portfolioStatsAPIResponse,
                              performanceImpact: {
                                 ...enhancerState.performanceImpact,
                                 currentPerformanceImpact: _currentStats,
                                 enhancedPerformanceImpact: __enhancedStats,
                              },
                           })
                        );
                     }
                  }
               } else {
                  throw new Error("-- failed to calculate enhance stats from api data --");
               }
               if (callback && typeof callback === "function") {
                  // yield put(resetTotumScore());
                  // yield call(delay, 600);
                  // yield getTotumScore();
                  callback(__enhancedStats);
               }
            } else {
               throw new Error("-- portfolioStats api issue, calculating performance by using Holdings --");
            }
         } else {
            throw new Error("-- filename and portfolio_type missing, calculating performance by using Holdings --");
         }
      } else {
         throw new Error("-- payload missing --");
      }
   } catch (error) {
      let errorDetails = error;
      console.log(errorDetails);
      if (get(error, "response.data", error.stack)) {
         errorDetails = get(error, "response.data", error.stack);
      }
      // yield put(getEnhanceStatsForSelectedTickerSuccess({
      //   pageCrash: errorDetails,
      //   performanceImpact: {
      //     ...enhancerState.performanceImpact,
      //     currentPerformanceImpact: payload.ui_currentStats ? payload.ui_currentStats : enhancerState.performanceImpact.currentPerformanceImpact,
      //   }
      // }));
      if (callback && typeof callback === "function") {
         // yield put(resetTotumScore());
         // yield call(delay, 600);
         // yield getTotumScore();
         callback();
      }
   }
}

function* resendTotumEmail(action) {
   try {
      yield put(resendTotumInviteFetch());
      const { payload, callback } = action;
      if (payload) {
         const { client_id } = payload;
         if (client_id) {
            const response = yield call(resendInviteEmail, payload);
            if (response) {
               yield put(resendTotumInviteSuccess(response));
               if (callback) {
                  callback(response);
               }
            } else {
               throw new Error("-- totum advisor email api failed to respond --");
            }
         } else {
            throw new Error("-- client_id missing for totum advisor email api");
         }
      } else {
         throw new Error("-- payload missing for totum advisor email api --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         yield put(
            resendTotumInviteSuccess({
               pageCrash: errorDetails.data.message,
            })
         );
      } else {
         yield put(
            resendTotumInviteSuccess({
               pageCrash: error,
            })
         );
      }
   }
   if (action.callback) {
      action.callback();
   }
}

function* checkQuestionnaireStatus(action) {
   try {
      yield put(getTotumQuestionnaireStatusFetch());
      const { payload, callback } = action;
      if (payload) {
         const { clientId, portfolioName } = payload;
         if (clientId && portfolioName) {
            let clientListResponse = yield call(getClientList);
            // console.log('-- clientListResponse -->', clientListResponse);
            if (clientListResponse) {
               let filterClientList = clientListResponse.find(
                  kl => kl.original_portfolio === portfolioName
               );
               // console.log('--- filterClientList ---', filterClientList);
               yield put(
                  getTotumQuestionnaireStatusSuccess({
                     clientList: clientListResponse,
                  })
               );
               if (callback) {
                  callback(filterClientList);
               }
            } else {
               throw new Error("-- totum advisor email api failed to respond --");
            }
         } else {
            throw new Error("-- client_id missing for totum advisor email api");
         }
      } else {
         throw new Error("-- payload missing for totum advisor email api --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         yield put(
            getTotumQuestionnaireStatusSuccess({
               pageCrash: errorDetails.data.message,
            })
         );
      } else {
         yield put(
            getTotumQuestionnaireStatusSuccess({
               pageCrash: error,
            })
         );
      }
   }
   if (action.callback) {
      action.callback();
   }
}

function* realTimePriceForTicker(action) {
   try {
      if (action.payload) {
         const { callback, payload } = action;
         const { ticker } = payload;
         if (ticker) {
            yield put(getTickerRealTimePriceFetch());
            const [realTimeTickerPrice] = yield all([
               call(fetchRealTimePricesNav, { tickers: ticker }),
            ]);
            // console.log('---- realTimeTickerPrice ----');
            // console.log(realTimeTickerPrice);
            // console.log('-----------------------------');
            if (
               realTimeTickerPrice &&
               realTimeTickerPrice.funds &&
               realTimeTickerPrice.funds.length > 0
            ) {
               let tickerPriceObj =
                  realTimeTickerPrice.funds.find(
                     item => item.ticker === ticker
                  ) || null;
               yield put(getTickerRealTimePriceSuccess());
               if (callback && typeof callback === "function") {
                  callback(tickerPriceObj);
               }
            } else {
               callback({ error: "Unable to add this security." });
               throw new Error("-- realTimeTickerPrice api failed to respond --");
            }
         } else {
            throw new Error("-- ticker is missing for realTimePrice --");
         }
      } else {
         throw new Error("-- payload is missing --");
      }
   } catch (error) {
      console.log(error);
      yield put(
         getTickerRealTimePriceSuccess({
            pageCrash: "Failed to load query.",
         })
      );
   }
}

function* uploadPortfolioCSVFile(action) {
   try {
      if (action.payload) {
         const { callback, payload } = action;
         yield put(uploadPortfolioCSVFetch());

         const [uploadResponse] = yield all([
            call(uploadPortfolioFile, payload),
         ]);

         console.log("---- CSVVV uploadResponse ----", uploadResponse);
         // console.log(uploadResponse);
         // console.log('-----------------------------');

         if (uploadResponse && uploadResponse.length) {
            let mapResponseData = uploadResponse.map(item => {
               return {
                  ticker: item.ticker || "",
                  amount:
                     item.amount ||
                     item.allocation_in_dollar ||
                     item.values ||
                     0,
                  format_value: item.format_value || 0,
                  invalidType:
                     item.invalid_type || item.invalidType || "supported",
                  isin: item.isin,
                  ticker_display: item.ticker_display,
                  name: item.name || "",
                  price: item.price || item.current_price || 0,
                  realTimePrice: item.price || item.current_price || 0,
                  shares: item.shares || 0,
                  validTicker:
                     item.invalid_type && item.invalid_type === "supported",
                  values:
                     item.values ||
                     item.amount ||
                     item.allocation_in_dollar ||
                     0,
                  weight: item.weight || item.allocation_in_percentage || 0,
                  "weight(%)":
                     item.weight || item.allocation_in_percentage || 0,
                  original_weight:
                     item.original_weight ||
                     item.allocation_in_percentage ||
                     item.weight ||
                     0,
                  weight_calculated_format: item.weight_calculated_format || "",
               };
            });

            // console.log('mapResponseData -->', mapResponseData);

            yield put(uploadPortfolioCSVSuccess());

            if (callback && typeof callback === "function") {
               callback(mapResponseData);
            }
         } else {
            if (
               uploadResponse &&
               uploadResponse.message === "Invalid File Type"
            ) {
               // NOTE: Toast a message on UI
               openNotificationWithIcon({
                  duration: 5,
                  type: "error",
                  message: "Invalid File Type",
                  className: "api-response-notification-class",
                  description:
                     "Uploaded file is invalid type. Download and follow the sample file.",
               });
            }
            throw new Error("-- uploadResponse api failed to respond --");
         }
      } else {
         throw new Error("-- payload is missing --");
      }
   } catch (error) {
      console.log(error);
      yield put(
         uploadPortfolioCSVSuccess({
            pageCrash: "Failed to process.",
         })
      );
   }
}

function* clientAccountName(action) {
   try {
      if (action.payload) {
         const { For, callback, payload } = action;
         yield put(updateClientAccountNameFetch());
         const [response] = yield all([call(updateClientAccountName, payload)]);
         if (response) {
            if (response.message === "Client data saved successfully.") {
               // NOTE: Refresh client list after updating.
               // --------------------------------------------------------------------------------------------
               if (For && For === "pagination") {
                  yield put(
                     accountPageClientListRequest({
                        count: 20,
                        page_number: 1,
                     })
                  );
               } else {
                  yield put(getClientListRequest());
               }
               yield call(delay, 600);
               // --------------------------------------------------------------------------------------------
               yield put(updateClientAccountNameSuccess());
               // NOTE: Toast a message on UI
               openNotificationWithIcon({
                  duration: 5,
                  type: "success",
                  message: "Done",
                  className: "api-response-notification-class",
                  description: "Client name updated successfully.",
               });
            } else if (response.message === "Name already exists.") {
               yield put(updateClientAccountNameSuccess());
               // NOTE: Toast a message on UI
               openNotificationWithIcon({
                  duration: 5,
                  type: "error",
                  message: "Already Exists",
                  className: "api-response-notification-class",
                  description:
                     "Failed to update. Client name already exists, try different name.",
               });
            } else if (response.message) {
               // Unexpected API case
               yield put(updateClientAccountNameSuccess());
               // NOTE: Toast a message on UI
               openNotificationWithIcon({
                  duration: 5,
                  type: "error",
                  message: response.message,
                  className: "api-response-notification-class",
                  description: null,
               });
            }
         } else {
            throw new Error("-- uploadResponse api failed to respond --");
         }
         if (callback && typeof callback === "function") {
            callback(response);
         }
      } else {
         throw new Error("-- payload is missing --");
      }
   } catch (error) {
      console.log(error);
      // NOTE: Toast a message on UI
      openNotificationWithIcon({
         duration: null,
         type: "error",
         message: "Failed",
         className: "api-response-notification-class",
         description: "Something went wrong, failed to update client name.",
      });
      yield put(
         updateClientAccountNameSuccess({
            pageCrash: "Failed to process.",
         })
      );
   }
}

function* investmentSaga() {
   yield all([
      fork(takeEvery, ActionTypes.UPLOAD_CSV_REQUEST, uploadPortfolio),
      fork(
         takeEvery,
         ActionTypes.GET_PORTFOLIO_BY_ID_REQUEST,
         getPortfolioDataById
      ),
      fork(takeEvery, ActionTypes.DELETE_CLIENT_LIST_REQUEST, deleteClient),
      fork(takeEvery, ActionTypes.GET_CLIENT_LIST_REQUEST, getClientListData),
      fork(
         takeEvery,
         ActionTypes.GET_PORTFOLIO_LIST_REQUEST,
         getPortfolioListData
      ),
      fork(takeEvery, ActionTypes.GET_MODELS_LIST_REQUEST, getModelsListData),
      fork(takeEvery, ActionTypes.LOAD_CPM_LIST_REQUEST, loadCPMListData),
      fork(
         takeEvery,
         ActionTypes.VERIFY_CSV_TICKER_AND_PRICE_REQUEST,
         verifyCSVTickerPrice
      ),
      fork(
         takeEvery,
         ActionTypes.VALIDATE_TICKER_REQUEST,
         getAllTickersValidate
      ),
      fork(
         takeEvery,
         ActionTypes.RESET_SUMMARY_STATS_RETURN_TO_ONBOARDSCREEN_REQUEST,
         resetSummaryAndReturnToOnBoardScreen
      ),
      fork(
         takeEvery,
         ActionTypes.CHECK_SINGLE_TICKER_EXIST_REQUEST,
         checkSingleTickerValidity
      ),
      fork(
         takeEvery,
         ActionTypes.GET_FUNDS_PORTFOLIO_DATA_BY_ID_REQUEST,
         getFundsPortfolioDataById
      ),
      fork(
         takeEvery,
         ActionTypes.RESET_FUNDS_PORTFOLIOS_RTO_REQUEST,
         resetFundsPortfoliosAndRTO
      ),
      fork(takeEvery, ActionTypes.GET_ALL_TICKER, getAllTickers),
      fork(
         takeEvery,
         ActionTypes.ADD_FUND_FOR_ENHANCE_REQUEST,
         addFundForEnhance
      ),
      fork(
         takeEvery,
         ActionTypes.DELETE_FUND_FROM_ENHANCE_REQUEST,
         deleteFundForEnhance
      ),
      fork(
         takeEvery,
         ActionTypes.FINALIZE_PORTFOLIO_REQUEST,
         finalizePortfolio
      ),
      fork(
         takeEvery,
         ActionTypes.FINALIZE_MODEL_PORTFOLIO_REQUEST,
         finalizeModelPortfolio
      ),
      fork(takeEvery, ActionTypes.SAVE_PORTFOLIO_REQUEST, savePortfolio),
      fork(
         takeEvery,
         ActionTypes.CLEAR_FINALIZE_DATA_REQUEST,
         clearFinalizeData
      ),
      fork(
         takeEvery,
         ActionTypes.DOWNLOAD_RECOMMENDATION_ENHANCER_REPORT,
         downloadRecommendationEnhancerPDF
      ),
      fork(
         takeEvery,
         ActionTypes.DOWNLOAD_ENHANCER_REPORT,
         downloadEnhancerReportPDF
      ),
      fork(
         takeEvery,
         ActionTypes.SET_ENHANCED_TRANSACTIONS_TRADE,
         saveEnhancedTransaction
      ),
      // fork(takeEvery, ActionTypes.GET_CURRENT_PORTFOLIO_REQUEST, getCurrentPortfolioDetailsRequest),
      fork(
         takeEvery,
         ActionTypes.GET_MODEL_PORTFOLIOS_BY_ID_REQUEST,
         getReplacePortfoliosOptionsRequest
      ),
      fork(takeEvery, ActionTypes.GET_TICKER_DATA_REQUEST, getCustomTickerData),
      fork(
         takeEvery,
         ActionTypes.DELETE_PORTFOLIO_BY_ID_REQUEST,
         deletePortfolio
      ),
      fork(
         takeEvery,
         ActionTypes.REPLACE_CURRENT_MODEL_PORTFOLIO_REQUEST,
         updateCurrentModelPortfolioWith
      ),
      fork(
         takeEvery,
         ActionTypes.UPDATE_EVALUATE_FILTER_REQUEST,
         updateEvaluateCheckbox
      ),
      fork(
         takeEvery,
         ActionTypes.GET_MP_DETAILS_CUSTOM_REPLACE_REQUEST,
         getMPDetailsCustomReplace
      ),
      fork(
         takeEvery,
         ActionTypes.RESET_FINALIZEDATA_STATE_REQUEST,
         resetFinalizeData
      ),
      fork(
         takeEvery,
         ActionTypes.USE_SUGGESTED_FUNDS_REQUEST,
         usingSuggestedFunds
      ),
      fork(
         takeEvery,
         ActionTypes.UPLOAD_PORTFOLIO_DATA_BY_ID_REQUEST,
         uploadPortfolioDataById
      ),
      fork(
         takeEvery,
         ActionTypes.CHECK_TOTUM_ADVISOR_REQUEST,
         checkTotumAdvisor
      ),
      fork(
         takeEvery,
         ActionTypes.SUBMIT_TOTUM_ADVISOR_REQUEST,
         sendEmailTotumAdvisor
      ),
      // fork(takeEvery, ActionTypes.GET_PORTFOLIO_SCORE_REQUEST, getPortfolioScore),
      fork(
         takeEvery,
         ActionTypes.GET_CURRENT_PORTFOLIO_SCORE_REQUEST,
         getCurrentPortfolioScore
      ),
      fork(
         takeEvery,
         ActionTypes.GET_ENHANCED_PORTFOLIO_SCORE_REQUEST,
         getEnhancedPortfolioScore
      ),
      fork(
         takeEvery,
         ActionTypes.GET_CURRENT_PORTFOLIO_STATS_REQUEST,
         getCurrentPortolioStats
      ),
      fork(
         takeEvery,
         ActionTypes.GET_ENHANCED_STATS_FOR_SELECTED_TICKER_REQUEST,
         getEnhancedStatsForSelectedTicker
      ),
      fork(
         takeEvery,
         ActionTypes.RESEND_TOTUM_INVITE_EMAIL_REQUEST,
         resendTotumEmail
      ),
      fork(
         takeEvery,
         ActionTypes.TOTUM_QUESTIONNAIRE_STATUS_REQUEST,
         checkQuestionnaireStatus
      ),
      fork(
         takeEvery,
         ActionTypes.DOWNLOAD_CURRENT_PORTFOLIO_REPORT,
         downloadCurrentPortfolioReportPDF
      ),
      fork(
         takeEvery,
         ActionTypes.GET_TICKER_REAL_TIME_PRICE_REQUEST,
         realTimePriceForTicker
      ),
      fork(
         takeEvery,
         ActionTypes.UPLOAD_PORTFOLIO_CSV_REQUEST,
         uploadPortfolioCSVFile
      ),
      fork(
         takeEvery,
         ActionTypes.UPDATE_CLIENT_ACCOUNT_NAME_REQUEST,
         clientAccountName
      ),
   ]);
}

export default investmentSaga;
