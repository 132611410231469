/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
// import moment from 'moment'
import { get } from "lodash";

import { Layout, Badge } from "antd";
// import { Tooltip } from "reactstrap";
// import { HomeOutlined } from "@ant-design/icons";
// import { Tooltip } from 'reactstrap'
// import {
//   DownOutlined,
//   RightOutlined,
// } from '@ant-design/icons'

import { searchQuery } from "actions/funds";
import { searchQueryRequest, resetDiscover } from "actions/discovery";
import { resetCommunityState } from "actions/community";
// import { resetAccountsStateRequest } from 'actions/accounts'
import { logout } from "actions/auth";
import {
   // setGlobalState,
   clearFundsApiLoadPercent,
   toggleGlobalMarketRegion,
} from "actions/global";
import { setSFMState } from "actions/sfm";
// import { uploadClientListV2 } from 'actions/clientportfolioV2'

// import { submitFeedbackApi } from 'apis/buildportfolio'
import PremiumButton from "components/PButton";
import ProtectedButton from "components/ProtectedButton";
import CustomAlert from "components/CustomAlert";
import { UserMenu } from "components/user/UserMenu";
import CountryAndCurrency from "components/CountryAndCurrency";
import { getTredingSearches } from "actions/trendingsearches";

import { getQueryItems } from "layouts/utils";
// import { getQueryItems } from 'layouts/utils'
// import { REDIRECT_DOMAIN_PATH } from "DataSet";
import { addEventToAnalytics, searchUrlV3, clearWindowUtmSession } from "Utils";

// import { mapCsvToJSON } from './ClientPortfolio/Utils'

const { Header } = Layout;

class MHeader extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showFileUpload: true,
         selectorTooltip: false,
         feedback: "",
         feedbackValue: "",
         feedbackLoading: false,
         csvFormatErrorMessage: null,
      };
   }

   componentDidMount() {
      // document.addEventListener('mousedown', this.handleClickOutside)
      const { setHeaderHeight } = this.props;
      if (setHeaderHeight) {
         setHeaderHeight();
      }
   }

   // componentWillUnmount() {
   //   // document.removeEventListener('mousedown', this.handleClickOutside)
   // }

   // handleClickOutside = event => {
   //   let feedbackForm = document.getElementById('feedback_form')
   //   if (feedbackForm && !feedbackForm.contains(event.target)) {
   //     if (this.state.feedback) {
   //       this.setState({ feedback: '' })
   //     }
   //   }
   // }

   redirect = () => {
      this.props.history.push("/settings/referral?invite=true");
      // <ReferalCodeContainer premiumModalToggle={this.premiumModalToggle}/>
   };

   // redirectToAccount = () => {
   //   addEventToAnalytics(
   //     'Header Enhancer',
   //     'Header Enhancer',
   //     'HEADER_ENHANCER',
   //     { url: window.location.href },
   //     false
   //   )
   //   if (!this.props.loggedIn) {
   //     this.props.onHeaderButtonsClick()
   //     return
   //   }
   //   this.props.history.push('/investments')
   // }

   // redirectToEnhancerV2 = () => {
   //   addEventToAnalytics(
   //     'Header Enhancer',
   //     'Header Enhancer',
   //     'HEADER_ENHANCER',
   //     { url: window.location.href },
   //     false
   //   )
   //   if (!this.props.loggedIn) {
   //     this.props.onHeaderButtonsClick()
   //     return
   //   }
   //   // console.log('--- Enhancer Dashboard Unmounted ---');
   //   const { resetSummaryDataRequest, history } = this.props
   //   if (resetSummaryDataRequest) {
   //     resetSummaryDataRequest('', () => {
   //       const newPath = `/enhancer`
   //       history.push(newPath)
   //     })
   //   }
   // }

   redirectToCommunity = () => {
      const { history, loggedIn, openLoginWall } = this.props;
      addEventToAnalytics(
         "Header Community",
         "Header Community",
         "HEADER_COMMUNITY",
         { url: window.location.href },
         false
      );
      // FOR UAT
      if (!loggedIn && openLoginWall) {
         openLoginWall();
      } else {
         if (history) {
            history.push(`/community/dashboard`, { from: "discovery_page" });
         }
      }
   };

   onSelectorClick = () => {
      console.log("CLICKEDDD SELECTOR");
      addEventToAnalytics(
         "Header Selector",
         "Header Selector",
         "HEADER_SELECTOR",
         {
            url: window.location.href,
            buttonDisabled: this.props.selectorCount === 0,
         },
         false
      );
      const { setSFMState } = this.props;
      if (this.props.selectorCount > 0 && setSFMState) {
         if (window.location.pathname.includes("/securities")) {
            if (this.props.loggedIn) {
               setSFMState({
                  modal: true,
                  autoOpenModal: true,
                  modalToggleState: true,
               });
            } else {
               const query = getQueryItems(this.props);
               setSFMState({
                  modalToggleState: true,
                  autoOpenModal: true,
                  modal: true,
               });
               window.sessionStorage.setItem(
                  "postLoginRedirection",
                  `/securities?query=${encodeURIComponent(query)}`
               );
               this.props.history.push({ pathname: "/login" });
            }
         } else {
            const _q = getQueryItems(this.props); // window.sessionStorage.getItem('query');
            if (
               (_q && _q !== null) ||
               !window.location.pathname.includes("/accounts")
            ) {
               // console.log('=== redirectToSearchResult ===');
               this.redirectToSearchResult();
            } else {
               setSFMState({
                  modalToggleState: true,
                  modal: true,
               });
            }
         }
         const { sfmModalToggle } = this.props;
         if (sfmModalToggle) {
            sfmModalToggle();
         }
      }
   };

   // redirectToBuildPortfolio = () => {
   //   addEventToAnalytics(
   //     'Header Builder',
   //     'Header Builder',
   //     'HEADER_BUILDER',
   //     { url: window.location.href },
   //     false
   //   )
   //   if (!this.props.loggedIn) {
   //     this.props.onHeaderButtonsClick()
   //     return
   //   }
   //   this.props.history.push('/build-portfolio')
   // }

   redirectToHomePage = () => {
      addEventToAnalytics(
         "Header Home",
         "Header Home",
         "HEADER_HOME",
         { url: window.location.href },
         false
      );
      const { history } = this.props;
      history.push("/");
   };
   redirectToSearchResult = (clearRecentSearch = false) => {
      addEventToAnalytics(
         "Header Discovery",
         "Header Discovery",
         "HEADER_DISCOVERY",
         { url: window.location.href },
         false
      );
      if (clearRecentSearch) {
         window.sessionStorage.removeItem("query");
         window.sessionStorage.removeItem("search_type");
      }
      this.checkRoutes();
   };

   checkRoutes = (query = "", clearUTMSession) => {
      const { history } = this.props;
      if (history) {
         if (clearUTMSession) {
            clearWindowUtmSession();
         }
         history.push(searchUrlV3(query), { from: "discovery_page" });
      }
   };

   // demoUpload = () => {
   //   // Read data from CSV instead of json dump
   //   fetch('/assets/data/demo_client_accounts.csv')
   //     .then(response => response.text())
   //     .then(data => {
   //       // const demoClients = csvToJSON(data);
   //       // const { uploadClientList } = this.props;
   //       // const { uploadClientListV2 }=this.props;
   //       // if (uploadClientList) {
   //       //   uploadClientList(demoClients);
   //       //   this.redirectToAccounts();
   //       // }
   //       // if(uploadClientListV2) {
   //       //   uploadClientListV2(demoClients);
   //       //   this.redirectToAccountsV2();
   //       //}
   //       const filters = {
   //         headersArray: [
   //           'name',
   //           'account_number',
   //           'custodian',
   //           'ticker',
   //           'shares'
   //         ],
   //         maxHeadersCount: 8,
   //         addHeader: ['price', 'lot_date', 'cost_basis']
   //       }
   //       const demoClients = JSON.parse(mapCsvToJSON(data, filters))
   //
   //       if (demoClients && typeof demoClients.error !== 'undefined') {
   //         this.setState({
   //           csvFormatErrorMessage: demoClients.error || ''
   //         })
   //         setTimeout(() => {
   //           this.setState({
   //             csvFormatErrorMessage: null
   //           })
   //         }, 2000)
   //       } else if (demoClients && demoClients.length > 0) {
   //         const { uploadClientListV2 } = this.props
   //         if (uploadClientListV2) {
   //           uploadClientListV2(demoClients)
   //           //this.redirectToAccounts();
   //           this.redirectToAccountsV2()
   //         }
   //       }
   //     })
   //     .catch(error => console.error(error))
   // }
   //
   // clientPortfolioClickHandler = (type) => {
   //   // if (!this.props.loggedIn && process.env.REACT_APP_SUB_DOMAIN === 'retail') {
   //   //   if (!this.props.surveyModal) {
   //   //     this.props.setGlobalState({ surveyModal: true, replacePremiumWithSurvey: true })
   //   //   }
   //   //   return
   //   // }
   //   if (!this.props.loggedIn) {
   //     this.props.onHeaderButtonsClick()
   //     return
   //   }
   //   if (type === 'uploadFile') {
   //     document.getElementById('uploadCustomPortfolio').click()
   //   } else if (type === 'demo') {
   //     addEventToAnalytics(
   //       'Client Add Demo',
   //       'Client Add Demo',
   //       'CLIENT_ADD_DEMO',
   //       {},
   //       false
   //     )
   //     this.demoUpload()
   //   }
   // }
   //
   // redirectToAccountsV2 = () => {
   //   addEventToAnalytics(
   //     'Header Client Portfolio',
   //     'Header Client Portfolio',
   //     'HEADER_CLIENT_PORTFOLIO',
   //     { url: window.location.href },
   //     false
   //   )
   //   // this.props.history.push('/advisor/accountV2')
   //   this.props.history.push({
   //     pathname: '/advisor/accountV2',
   //     state: { id: (Math.random() * 1000).toFixed(0) }
   //   })
   // }
   //
   // redirectToAccounts = () => {
   //   addEventToAnalytics(
   //     'Header Client Portfolio',
   //     'Header Client Portfolio',
   //     'HEADER_CLIENT_PORTFOLIO',
   //     { url: window.location.href },
   //     false
   //   )
   //   this.props.history.push('/advisor/account')
   // }
   //
   // downloadSampleFile = () => {
   //   addEventToAnalytics(
   //     'Client Add Download',
   //     'Client Add Download',
   //     'CLIENT_ADD_DOWNLOAD',
   //     {},
   //     false
   //   )
   //   document.getElementById('mock-file').click()
   // }

   // submitFeedback = () => {
   //   this.setState({ feedbackLoading: true })
   //   addEventToAnalytics(
   //     'Builder Feedback Submit',
   //     'Builder Feedback Submit',
   //     'BUILDER_FEEDBACK_SUBMIT',
   //     { email: window.localStorage.getItem('email') },
   //     false
   //   )
   //   submitFeedbackApi(
   //     {
   //       email: window.localStorage.getItem('email'),
   //       feedback: this.state.feedbackValue,
   //       timestamp: moment().toISOString()
   //     },
   //     response => {
   //       this.setState({ feedbackLoading: false })
   //       if (response.error) {
   //         return
   //       }
   //       this.setState({ feedback: 'submit' }, () => {
   //         setTimeout(() => {
   //           this.setState({ feedback: '' })
   //         }, 2000)
   //       })
   //     }
   //   )
   // }

   // onSelectCurrency = (currency) => {
   //   console.log('currency --> ', currency);
   // }

   onSelectCountry = (country, isRegion) => {
      console.log("country --> ", country);
      const { toggleGlobalMarketRegion, getTredingSearches } = this.props;
      if (toggleGlobalMarketRegion) {
         if (getTredingSearches) {
            getTredingSearches();
         }
         toggleGlobalMarketRegion({
            market: country,
         });
         const { query, clearFundsApiLoadPercent } = this.props;
         if (query) {
            clearFundsApiLoadPercent();
            this.checkRoutes(query);
         }
         // else {
         //   const foundRegion = regions.find(item => item.code === country)
         //   const regionName =
         //     foundRegion && foundRegion.name ? foundRegion.name : country
         //   message.success(`Region changed to ${regionName}`)
         // }
      }
   };

   render() {
      const {
         regions,
         location,
         // sfmSelectedItems,
         height,
         loggedIn,
         loggingIn,
         // resultPageCart,
         // client_exist,
         // user_advisor_menu,
         // uploadClientListV2,
         // profileDetails,
         globalMarket,
      } = this.props;
      // const count = buildportfolio.resultPageCart && buildportfolio.resultPageCart.length;
      // const selectorCount = sfmSelectedItems.filter(
      //    v => v._selector === true && v._selected === true
      // ).length;
      // const builderCount = sfmItems.filter((v) => v._builder === true).length;
      // const builderCount = resultPageCart.length
      // const { showFileUpload } = this.state
      const { pathname } = location;

      // let profile_self_role = window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';

      let menuOptionStyles = {};

      // const readCSV = event => {
      //   var reader = new FileReader()
      //   reader.onload = () => {
      //     const filters = {
      //       headersArray: [
      //         'name',
      //         'account_number',
      //         'custodian',
      //         'ticker',
      //         'shares'
      //       ],
      //       maxHeadersCount: 8,
      //       addHeader: ['price', 'lot_date', 'cost_basis']
      //     }
      //     const clientList = JSON.parse(mapCsvToJSON(reader.result, filters))
      //     if (uploadClientListV2) {
      //       if (clientList && typeof clientList.error !== 'undefined') {
      //         this.setState({
      //           csvFormatErrorMessage: clientList.error || ''
      //         })
      //         setTimeout(() => {
      //           this.setState({
      //             csvFormatErrorMessage: null
      //           })
      //         }, 2000)
      //       } else if (clientList && clientList.length > 0) {
      //         uploadClientListV2(clientList)
      //         addEventToAnalytics(
      //           'Client Add Upload',
      //           'Client Add Upload',
      //           'CLIENT_ADD_UPLOAD',
      //           {},
      //           false
      //         )
      //         this.props.history.push('/advisor/accountV2')
      //       }
      //     }
      //     this.setState({ showFileUpload: false })
      //   }
      //   // start reading the file. When it is done, calls the onload event defined above.
      //   reader.readAsBinaryString(event.target.files[0])
      // }

      // const menu = (
      //   <Menu>
      //     <Menu.Item
      //       key="uploadFile"
      //       icon={
      //         <img
      //           className="icon prefix-icon"
      //           src="/assets/images/upload-pf.svg"
      //         />
      //       }
      //     >
      //       {showFileUpload && (
      //         <>
      //           <Input
      //             type="file"
      //             name="file"
      //             id="uploadCustomPortfolio"
      //             accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      //             onChange={e => readCSV(e)}
      //             style={{ zIndex: 1 }}
      //           />
      //           <span style={{ paddingRight: 20 }}>Upload File</span>
      //         </>
      //       )}
      //       <img
      //         className="icon suffix-icon"
      //         src="/assets/images/download-pf.svg"
      //         onClick={this.downloadSampleFile}
      //       />
      //       <a href="/assets/data/mock_client_accounts.csv" id="mock-file" />
      //     </Menu.Item>
      //     <Menu.Item
      //       key="demo"
      //       onClick={() => this.clientPortfolioClickHandler('demo')}
      //       icon={
      //         <img
      //           className="icon prefix-icon"
      //           src="/assets/images/UseDemoFile-pf.svg"
      //         />
      //       }
      //     >
      //       <span style={{ paddingRight: 20 }}>Use Demo File</span>
      //       <RightOutlined className="suffix-icon" />
      //     </Menu.Item>
      //   </Menu>
      // );
      // const accountHeader = process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? "Accounts" : "My Account";

      // const renderClientPortfolioV2MenuButton = (!this.props.loggedIn || this.props.planName !== 'Premium Plan') ? (
      //   <Fragment>
      //     <PButton
      //       type="button"
      //       featureName="client_portfolio"
      //       premiumAction={true}
      //       onClick={() => this.clientPortfolioClickHandler('demo')}
      //       className={`d-flex justify-content-center client-portfolio-menu-button ${pathname.includes('/advisor/accountV2') ? 'client-portfolio-menu-active' : ''}`}
      //     >
      //       <i className="far fa-fw fa-list mr-2"></i>
      //       <span className="button-label">{accountHeader}</span>
      //       <DownOutlined style={{ fontSize: 10 }} />
      //     </PButton>
      //   </Fragment>
      // ) : ((user_advisor_menu && !client_exist) ? (
      //   <Fragment>
      //     <Dropdown
      //       getPopupContainer={() => document.getElementById('client-portfolio-menu')}
      //       overlayClassName="client-portfolio-menu-dropdown"
      //       overlay={menu}
      //       trigger={['click']}
      //       onVisibleChange={() => this.setState({ showFileUpload: true })}
      //     >
      //       <PButton type="button" className={`d-flex justify-content-end client-portfolio-menu-button ${pathname.includes('/advisor/accountV2') ? 'client-portfolio-menu-active' : ''}`}>
      //         <i className="far fa-fw fa-list mr-2"></i> <span className="button-label">{accountHeader}</span> <DownOutlined />
      //       </PButton>
      //     </Dropdown>
      //   </Fragment>
      // ) : (
      //   <PButton
      //     type="button"
      //     featureName="client_portfolio"
      //     premiumAction={true}
      //     className={`d-flex justify-content-center client-portfolio-menu-button ${pathname.includes('/advisor/accountV2') ? 'client-portfolio-menu-active' : ''}`}
      //     onClick={this.redirectToAccountsV2}
      //   >
      //     <i className="far fa-fw fa-list mr-2"></i> <span className="button-label">{accountHeader}</span>
      //   </PButton>
      // ));

      // let LOGO_WILL_BE = "";
      // if (profileDetails && profileDetails.restrict_sponsor === "blackrock") {
      //    LOGO_WILL_BE =
      //       "/assets/images/MagnifiLogo/BlackRock-PoweredbyMagnifi.png";
      // } else if (
      //    profileDetails &&
      //    profileDetails.restrict_sponsor === "invesco"
      // ) {
      //    LOGO_WILL_BE =
      //       "/assets/images/MagnifiLogo/Invesco-PoweredbyMagnifi.png";
      // } else if (
      //    profileDetails &&
      //    profileDetails.restrict_sponsor === "fidelity"
      // ) {
      //    LOGO_WILL_BE =
      //       "/assets/images/MagnifiLogo/Fidelity-PoweredbyMagnifi.png";
      // } else {
      //    LOGO_WILL_BE =
      //       "/assets/images/MagnifiLogo/Magnifi_navbar_logo_new.svg";
      // }

      let navBarContents = [
         // <PremiumButton
         //    type="anchor"
         //    className="tools-link"
         //    onClick={() => this.redirectToHomePage()}
         // >
         //    <Badge
         //       key="Home"
         //       className={`tool-badge search-result-tool-badge-home ${
         //          pathname === "/" ? "selected" : ""
         //       }`}
         //    >
         //       <HomeOutlined />
         //       Home
         //    </Badge>
         // </PremiumButton>,
         <PremiumButton
            type="anchor"
            className="tools-link"
            onClick={() => this.redirectToSearchResult(true)}
         >
            <Badge
               key="Discovery"
               className={`tool-badge search-result-tool-badge ${
                  pathname.includes("/securities") ? "selected" : ""
               }`}
            >
               {/* <img
                  width={17}
                  height={17}
                  src={`${
                     pathname.includes("/securities")
                        ? "/assets/images/icons/Discover-White.svg"
                        : "/assets/images/icons/Discover.svg"
                  }`}
                  alt=""
               /> */}
               Discover
            </Badge>
         </PremiumButton>,
         // <Badge
         //    key="Selector"
         //    count={selectorCount}
         //    className={
         //       selectorCount === 0
         //          ? "tool-badge disabled-selector"
         //          : "tool-badge"
         //    }
         //    id="Selector"
         // >
         //    <PremiumButton
         //       type="anchor"
         //       className="tools-link"
         //       onClick={this.onSelectorClick}
         //    >
         //       {selectorCount === 0 ? (
         //          <img
         //             src="/assets/images/icons/shopping-cart-Gray.svg"
         //             alt=""
         //          />
         //       ) : (
         //          <img
         //             src="/assets/images/icons/shopping-cart-Blue.svg"
         //             alt=""
         //          />
         //       )}
         //       {"Selector"}
         //    </PremiumButton>
         // {selectorCount === 0 && (
         //    <Tooltip
         //       placement="bottom"
         //       isOpen={this.state.selectorTooltip}
         //       autohide={false}
         //       target="Selector"
         //       toggle={() =>
         //          this.setState({
         //             selectorTooltip: !this.state.selectorTooltip,
         //          })
         //       }
         //       className="selector-btn-tooltip"
         //    >
         //       Select Funds To Add To The Selector
         //    </Tooltip>
         // )}
         // </Badge>,
         // <PremiumButton
         //    type="anchor"
         //    className="tools-link"
         //    protectedAction={true}
         //    onClick={this.onSelectorClick}
         // >
         //    <Badge
         //       key="Selector"
         //       count={selectorCount}
         //       id="Selector"
         //       className={`tool-badge selector-tool-badge ${
         //          selectorCount ? "" : "unselectable"
         //       }`}
         //    >
         //       {"Selector"}
         //       <Tooltip
         //          placement="bottom"
         //          isOpen={this.state.selectorTooltip && selectorCount === 0}
         //          autohide={false}
         //          target="Selector"
         //          toggle={() =>
         //             this.setState({
         //                selectorTooltip: !this.state.selectorTooltip,
         //             })
         //          }
         //          className="selector-btn-tooltip"
         //       >
         //          Select Funds To Add To The Selector
         //       </Tooltip>
         //    </Badge>
         // </PremiumButton>,
         <ProtectedButton
            type="anchor"
            className="tools-link"
            sourceUrl="community"
            protectedAction={true}
            onClick={this.redirectToCommunity}
         >
            <Badge
               key="Community"
               className={`tool-badge search-result-tool-badge-communtiy ${
                  pathname.includes("/community") ? "selected" : ""
               }`}
            >
               {/* <img
                  src={`${
                     pathname.includes("/community")
                        ? "/assets/images/icons/Community-White.svg"
                        : "/assets/images/icons/Community.svg"
                  }`}
                  alt=""
               /> */}
               Community
            </Badge>
         </ProtectedButton>,
      ];

      // const feebackDesign = this.props.location.pathname && this.props.location.pathname === '/build-portfolio/scratch' ? (
      //   <div style={{ position: 'relative' }} className="mr-3">
      //     <a onClick={() => {
      //       if (this.state.feedback) {
      //         return
      //       }
      //       this.setState({ feedback: 'pending', feedbackValue: '' })
      //     }} href="javascript:void(0)" style={{ textDecoration: 'underline' }}>Your Feedback!</a>
      //     {this.state.feedback ? <div className="builder_feedback_form" id="feedback_form">
      //       <Row>
      //         <Col sm={8}><span>Provide your feedback below</span></Col>
      //         <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} className="text-right" sm={4}><CloseCircleOutlined onClick={() => this.setState({ feedback: '' })} className="close_icon" style={{ fontSize: 16 }} /></Col>
      //       </Row>
      //       {this.state.feedback === 'pending' ? <textarea onChange={(e) => this.setState({ feedbackValue: e.target.value })} placeholder="Type here..." rows="6"></textarea> : <div className="textarea_dummy">
      //         <div className="w-100 text-center">
      //           <CheckCircleOutlined style={{ fontSize: 30 }} />
      //           <p>Your feedback has been submitted</p>
      //         </div>
      //       </div>}
      //       {this.state.feedback === 'pending' ? <PButton disabled={!this.state.feedbackValue || this.state.feedbackLoading} onClick={() => this.submitFeedback()} type="anchor" className={!this.state.feedbackValue || this.state.feedbackLoading ? 'feedback_submit disable' : 'feedback_submit'}>
      //         SUBMIT
      //       </PButton> : null}
      //     </div> : null}
      //   </div>
      // ) : null;

      // const NOT_ALLOW_COUNTRY_DROPDOWN = ["/", "/settings/profile"];
      return (
         <React.Fragment>
            <Header
               id="magnifi-header-wrapper"
               className={
                  "magnifi-header-wrapper " +
                  (pathname === "/" ? "homepage" : "")
               }
            >
               <nav className="navbar navbar-expand-md navbar-dark bg-c5 py-0 shadow-0 navBarWrapper">
                  <div className="container py-1">
                     <a
                        className="navbar-brand logoWrapper"
                        style={{ marginRight: "unset" }}
                        // href={REDIRECT_DOMAIN_PATH}
                        onClick={() => this.props.history.push("/")}
                     >
                        <img
                           src={
                              "/assets/images/MagnifiLogo/Magnifi_navbar_logo_new.svg"
                           }
                           width={height ? "" : "162"}
                           height={height}
                           alt="magnifi"
                        />
                     </a>
                     <div
                        id="header-menus-wrapper-style"
                        className="portfolio-tools-wrap"
                        style={menuOptionStyles}
                     >
                        {navBarContents}
                     </div>
                  </div>
                  <CountryAndCurrency
                     regions={regions}
                     selectedCountry={globalMarket}
                     onSelectCountry={this.onSelectCountry}
                     hideDropDown={!pathname.includes("/securities")}
                     history={this.props.history}
                     location={this.props.location}
                  />
                  <UserMenu
                     className="user-menu-popover"
                     onLogout={this.props.logoutHandler}
                     isLoggedIn={loggedIn || loggingIn}
                     showSinginIcon={loggedIn}
                  />
               </nav>
            </Header>
            {this.state.csvFormatErrorMessage &&
               this.state.csvFormatErrorMessage.length > 0 && (
                  <CustomAlert className="alert-windown-wrapper" color="danger">
                     {this.state.csvFormatErrorMessage}
                  </CustomAlert>
               )}
         </React.Fragment>
      );
   }
}

const mapStateToProps = ({
   discovery,
   global,
   sfm,
   auth,
   // register,
   router,
   buildportfolio,
   profile,
}) => ({
   buildportfolio,
   user_advisor_menu: auth.user_advisor_menu,
   client_exist: auth.client_exist,
   premiumUser: auth.user.premiumUser,
   planName: auth.user.planName,
   clearBuilderFunds: buildportfolio.clearBuilderFunds,
   useremail: auth.user.email,
   firstName: auth.user.firstName,
   lastName: auth.user.lastName,
   isTermsAgreed: auth.termsAgreed,
   isNewUser: auth.newUser,
   loggedIn: auth.loggedIn,
   loggingIn: auth.loggingIn,
   location: router.location,
   query: discovery.query,
   self_role: profile.profile.self_role,
   account_no: get(profile.profile, "apex.account[0].account_no"),
   // selectorCount: sfm.selectorCount,
   sfmSelectedItems: sfm.items,
   resultPageCart: buildportfolio.resultPageCart,
   profileDetails: profile.profile,
   selectorCount:
      sfm.items.filter(k => k._selector || k._selected).length ||
      sfm.selectorCount,
   surveyModal: global.surveyModal,
   globalMarket: global.globalMarket,
   regions: global.regions,
   userCountry: profile.profile.country,
   pathname: router.location.pathname,
});

const mapDispatchToProps = {
   logoutHandler: logout,
   clearFundsApiLoadPercent,
   // setFundsState,
   searchQuery,
   setSFMState,
   //uploadClientList,
   // uploadClientListV2,
   resetCommunityState,
   // resetSummaryDataRequest,
   // resetAccountsStateRequest,
   // setGlobalState,
   toggleGlobalMarketRegion,
   searchQueryRequest,
   getTredingSearches,
   resetDiscover,
};

export default connect(mapStateToProps, mapDispatchToProps)(MHeader);
