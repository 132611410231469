/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import { connect } from "react-redux";
import {
   profilePhotoUploadRequest,
   profilePhotoHideUnhideRequest,
   profileBrokenImageUpdate,
} from "actions/profile";
import { isEmpty } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
   ProfileContainer,
   ProfileImage,
   ProfileUploadedAvatar,
   ProfileUploadedDefaultAvatar,
   HideEdit,
   ProfileIcon,
   ProfileLabel,
   CenterContainer,
   FallBackImageContainer,
} from "../profile/profileStyle";
import ProfilePhotoUploadModel from "../profile/profilePhotoUploadModel";

class ProfilePhotoContainer extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         showSuspendedAccount: null,
         accountStatus: "",
         editInput: false,
         apexStatus: null,
         activeTab: "1",
         modeOpen: false,
         displayHideText: "Hide",
         avatar: "/assets/images/icons/avatar.svg",
         isProfileEdit: false,
         profileEditUrl: [],
         isUserProfileHide: false,
         isShowSpinner: false,
      };
   }

   onUploadProfile = () => {
      this.setState({ modeOpen: true, isProfileEdit: false });
   };

   onProfileEdit = () => {
      const { avatarUrl, avatarExt, profile } = this.props;
      if (avatarUrl && avatarExt) {
         // const profileURL = config.avatarURL + avatarUrl + '.' + avatarExt + '?id=' + (Math.random() * 100).toFixed(0)
         const profileURL =
            avatarUrl + "?id=" + (Math.random() * 100).toFixed(0);
         const fileName = profile
            ? profile.first_name +
              "_" +
              profile.last_name +
              "_" +
              new Date().getTime() +
              ".jpeg"
            : "newFile" + new Date().getTime() + ".jpeg";
         try {
            fetch(profileURL)
               .then(
                  async response => {
                     const contentType = response.headers.get("content-type");
                     const blob = await response.blob();
                     const file = new File([blob], fileName, {
                        type: "image/jpg",
                        contentType,
                     });
                     this.setState({
                        modeOpen: true,
                        isProfileEdit: true,
                        profileEditUrl: file,
                     });
                  },
                  error => {
                     if (error) {
                        console.log(error, "edit profile");
                     }
                  }
               )
               .catch(err => {
                  console.log(err);
               });
         } catch (error) {
            console.log(error);
         }
      }
   };

   onModelClose = () => {
      this.setState({ modeOpen: false });
   };

   onHideUnHideSuccess = response => {
      this.setState({ isShowSpinner: false });
   };

   onHideUnhide = async e => {
      let value = e.target.text;
      const { profilePhotoHideUnhideRequest } = this.props;
      if (value && profilePhotoHideUnhideRequest) {
         this.setState({ isShowSpinner: true });
         let isAvatarHide = value === "Hide" ? false : true;
         profilePhotoHideUnhideRequest(
            { is_avatar_visible: isAvatarHide },
            this.onHideUnHideSuccess
         );
      }
   };

   ShortName = () => {
      const { first_name, last_name } = this.props.profile;
      if (first_name && last_name) {
         return (first_name.slice(0, 1) + last_name.slice(0, 1)).toUpperCase();
      }
   };

   render() {
      const { profile, profilePictureData } = this.props;
      const { avatar, isShowSpinner } = this.state;
      const isUserprofileURL =
         !isEmpty(profilePictureData) && !isEmpty(profilePictureData.avatar_url)
            ? profilePictureData.avatar_url
            : profile.avatar_url && profile.avatar_url;
      const isUserProfileHide = !isEmpty(profilePictureData)
         ? profilePictureData.avatar_visible
         : profile.avatar_visible === 1
         ? true
         : false;
      const isShowHideText = isUserProfileHide ? "Hide" : "Unhide";
      return (
         <React.Fragment>
            {!isEmpty(isUserprofileURL) ? (
               <>
                  <ProfileContainer>
                     <ProfileImage uploaded>
                        {isUserProfileHide
                           ? !isShowSpinner &&
                             (!this.props.isBrokenImage ? (
                                <ProfileUploadedAvatar
                                   src={isUserprofileURL}
                                   onError={() =>
                                      this.props.profileBrokenImageUpdate(true)
                                   }
                                />
                             ) : (
                                <FallBackImageContainer>
                                   <p>{this.ShortName()}</p>
                                </FallBackImageContainer>
                             ))
                           : !isShowSpinner && (
                                <ProfileUploadedDefaultAvatar src={avatar} />
                             )}
                        {isShowSpinner && (
                           <CenterContainer>
                              <Spin
                                 indicator={
                                    <LoadingOutlined
                                       style={{ fontSize: 24 }}
                                       spin
                                    />
                                 }
                              />
                           </CenterContainer>
                        )}
                     </ProfileImage>
                     <HideEdit>
                        <a
                           href="javascript:void(0)"
                           onClick={this.onHideUnhide}
                           style={{ paddingRight: 10 }}
                        >
                           {isShowHideText}
                        </a>
                        {isUserprofileURL && (
                           <>
                              <span>|</span>{" "}
                              <a
                                 // eslint-disable-next-line no-use-before-define
                                 href="javascript:void(0)"
                                 onClick={this.onUploadProfile}
                                 style={{ paddingLeft: 10 }}
                              >
                                 Edit
                              </a>
                           </>
                        )}
                     </HideEdit>
                  </ProfileContainer>
               </>
            ) : (
               <ProfileContainer onClick={this.onUploadProfile}>
                  <ProfileImage>
                     <ProfileIcon src="/assets/images/upload_profile.svg" />
                     <ProfileLabel>Upload Photo</ProfileLabel>
                  </ProfileImage>
               </ProfileContainer>
            )}
            <ProfilePhotoUploadModel
               modalStatus={this.state.modeOpen}
               modelClose={this.onModelClose}
               isProfileEdit={this.state.isProfileEdit}
               profileEditUrl={this.state.profileEditUrl}
            ></ProfilePhotoUploadModel>
         </React.Fragment>
      );
   }
}

const mapStateToProps = ({ profile, auth }) => ({
   avatarUrl: profile.profile.avatar_url,
   avatarVisible: profile.profile.avatar_visible,
   avatarExt: profile.profile.avatar_ext,
   profile: profile.profile,
   profilePictureData: profile.profilePictureData,
   isBrokenImage: profile.isBrokenImage,
});

const mapDispatchToProps = {
   profilePhotoUploadRequest,
   profilePhotoHideUnhideRequest,
   profileBrokenImageUpdate,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ProfilePhotoContainer);
