import { uniqBy, union } from "lodash";
import moment from "moment";
import { ScoreAttrs, DateRange } from "../DataSet";
import { monthToDate } from "Utils";
import ActionTypes from "../action-types";
import { UNASSIGNED } from "layouts/WebDashboard/SFM/utils";
import { numberCheck } from "layouts/utils";
import { cn } from "DataSet";

const cleanState = {
   funds: [],
   sponsoredFunds: [],
   failedToPerform: "",
};

const initialState = {
   ...cleanState,
   range: "",
   dateRange: "",
   scoreAttrs: [...ScoreAttrs],
   loading: false,
   stage: "",
   importedAccounts: "",
   brokers: "",
   modal: false,
   open: false,
   alert: true,
   showFunDetails: false,
   selectedFunDetails: "",
   setSFMState: "",
   sfmDateRange: "",
   sfmCatData: "",
   modalToggleState: false,
   clearAllFunds: false,
   trackFundsCompareOrder: [],
   resetTrackFundsState: false,
   reports: {},
   reportLoader: false,
   reportPDFDownloadStatus: {
      message: "",
      downloadStatus: "",
   },
   reportLoaderCompareOnly: false,
   reportPDFDownloadStatusCompareOnly: {
      message: "",
      downloadStatus: "",
   },
   sfmTrackYear: "",
   selectorCount: 0,

   //sfm pdf report bg download
   pdfReportBlob: "",
   bgDownloadStatus: UNASSIGNED,
   isCompareOnlyBG: false,

   // V-2
   selectedItemTickers: [],
   items: [],
   commonActiveSFMReturnsRange: {},
   sfmTradeOrderResponse: {},
   myportfolio: null,
   enhacementScore: [],
};

export function sfm(state = initialState, action) {
   const { payload } = action;

   switch (action.type) {
      case ActionTypes.REFRESH_STATE:
         return {
            funds: [],
            sponsoredFunds: [],
            failedToPerform: "",
            range: "",
            dateRange: "",
            scoreAttrs: [...ScoreAttrs],
            loading: false,
            stage: "",
            importedAccounts: "",
            brokers: "",
            modal: false,
            open: false,
            alert: true,
            showFunDetails: false,
            selectedFunDetails: "",
            setSFMState: "",
            sfmDateRange: "",
            sfmCatData: "",
            modalToggleState: false,
            clearAllFunds: false,
            trackFundsCompareOrder: [],
            resetTrackFundsState: false,
            reports: {},
            reportLoader: false,
            reportPDFDownloadStatus: {
               message: "",
               downloadStatus: "",
            },
            reportLoaderCompareOnly: false,
            reportPDFDownloadStatusCompareOnly: {
               message: "",
               downloadStatus: "",
            },
            sfmTrackYear: "",

            // V-2
            selectedItemTickers: [],
            items: [],
            commonActiveSFMReturnsRange: {},
            sfmTradeOrderResponse: {},
         };
      case ActionTypes.UPDATE_SFM_TRACK_YEAR:
         return {
            ...cleanState,
            ...state,
            sfmTrackYear: payload.sfmTrackYear,
         };
      case ActionTypes.UPDATE_FUNDS_TRACKING_REPORT:
         return {
            ...cleanState,
            ...state,
            reports: payload.reports,
         };
      case ActionTypes.CLEAR_TRACK_FUNDS:
         return {
            ...cleanState,
            ...state,
            trackFundsCompareOrder: [],
            resetTrackFundsState: true,
            scoreAttrs: [...ScoreAttrs],
            sfmTrackYear: "",
         };
      case ActionTypes.TRACK_COMAPRE_ORDER:
         return {
            ...cleanState,
            ...state,
            trackFundsCompareOrder: payload.trackFundsCompareOrder,
         };
      case ActionTypes.GENERATE_FUNDS_REPORT:
         return {
            ...cleanState,
            ...state,
            reports: payload.reports,
         };
      case ActionTypes.SCORE_ATTRS_HANDLER:
         return {
            ...cleanState,
            ...state,
            scoreAttrs: payload.scoreAttrs,
         };
      case ActionTypes.SET_SFM_CATDATA:
         let { sfmCatData } = state;
         if (!sfmCatData.length) {
            sfmCatData = [];
         }
         const arr = uniqBy(sfmCatData.concat(payload), "name");
         return {
            ...cleanState,
            ...state,
            sfmCatData: arr,
         };
      case ActionTypes.SET_SFM_ITEMS:
         return {
            ...cleanState,
            ...state,
            items: payload.items,
         };
      case ActionTypes.SET_SFM_STATE:
         return {
            ...cleanState,
            ...state,
            ...payload,
         };
      case ActionTypes.ADD_FUND_TO_SFM:
         return {
            ...cleanState,
            ...state,
            ...payload,
         };
      case ActionTypes.CLEAR_HANDLER:
         return {
            ...cleanState,
            ...state,
         };
      case ActionTypes.RANGE_HANDLER:
         return {
            ...cleanState,
            ...state,
         };
      case ActionTypes.SELECT_HANDLER:
         return {
            ...cleanState,
            ...state,
         };
      case ActionTypes.ACTION_HANDLER:
         return {
            ...cleanState,
            ...state,
         };
      case ActionTypes.BUY_HANDLER:
         return {
            ...cleanState,
            ...state,
         };
      case ActionTypes.BACK_HANDLER:
         return {
            ...cleanState,
            ...state,
         };
      case ActionTypes.TOGGLE_MODAL:
         return {
            ...cleanState,
            ...state,
            modal: action.payload,
         };
      case ActionTypes.GET_SELECTED_FUNDS:
         return {
            ...cleanState,
            ...state,
            items: action.payload,
         };
      case ActionTypes.GET_FUNDS:
         return {
            ...state,
            funds: action.payload,
         };
      case ActionTypes.FAILED_TO_PERFORM:
         return {
            ...state,
            failedToPerform: action.payload,
         };

      case ActionTypes.SFM_FUND_ADD: {
         if (state.items.find(e => e.ticker === action.fund.ticker)) {
            return state;
         }

         let items = state.items.slice();
         action.index !== undefined
            ? items.splice(action.index, 0, action.fund)
            : items.push(action.fund);

         return {
            ...state,
            items,
         };
      }

      case ActionTypes.SFM_FUND_DELETE: {
         if (!state.items.find(e => e.ticker === action.fund.ticker)) {
            return state;
         }

         let items = state.items.filter(e => e.ticker !== action.fund.ticker);
         let modal = items.length > 0;
         let modalToggleState = modal;

         return {
            ...state,
            items,
            modal,
            modalToggleState,
         };
      }

      // V - 2
      case ActionTypes.ADD_SFM_ITEM_TICKERS_SUCCESS:
         const { items: _items, selectedItemTickers: _selItemTickers } = state;
         let _selectedFund = _items;
         let _updatedListSfmTickers = _selItemTickers;

         if (payload.selectedFund) {
            _selectedFund = union(_selectedFund, payload.selectedFund);
            _selectedFund.forEach(e => {
               e.ticker_short = e.ticker_short || e.ticker;
               e._selected = true;
               e._selector = true;
               e._checkedToExecute = true;

               let price = cn(e, "Nav Price");
               let minInvestment = e.minimum_investment;
               let _minInvestment = numberCheck(minInvestment, 5) || 5; // minimum investment is $5
               let _price = numberCheck(price, 5);
               // if minInvestment is 0 || null then -> check _price with $5
               if (!numberCheck(minInvestment) && _price < _minInvestment) {
                  _minInvestment = _price;
               }
               e.minimum_investment = _minInvestment;
            });
         }
         if (payload.selectedItemTickers) {
            _updatedListSfmTickers = union(_selItemTickers, [
               payload.selectedItemTickers,
            ]);
            _selectedFund.forEach(e => {
               if (e.ticker === payload.selectedItemTickers) {
                  // console.log('payload.selectedType  ====>>', payload.selectedType, payload.selectedItemTickers);
                  e._selectedType = payload.selectedType || "buy"; // 'buy' or 'sell';
                  let price = cn(e, "Nav Price");
                  let minInvestment = e.minimum_investment;
                  let _minInvestment = numberCheck(minInvestment, 5) || 5; // minimum investment is $5
                  let _price = numberCheck(price, 5);

                  // if minInvestment is 0 || null then -> check _price with $5
                  if (!numberCheck(minInvestment) && _price < _minInvestment) {
                     _minInvestment = _price;
                  }

                  e.minimum_investment = _minInvestment;
               }
            });
         }

         let _updatedList = uniqBy(_selectedFund, "ticker");
         // SFM common start-end range
         let commonStartRange = moment.max(
            _updatedList.filter(e => e._start).map(e => monthToDate(e._start))
         );
         let defaultStartRange = moment(DateRange.start),
            activeStartRange = moment.max(defaultStartRange, commonStartRange);

         let commonEndRange = moment.max(
            _updatedList.filter(e => e._end).map(e => monthToDate(e._end))
         );
         let defaultEndRange = moment(DateRange.end),
            activeEndRange = moment.max(defaultEndRange, commonEndRange);

         return {
            ...state,
            ...payload,
            selectedItemTickers: _updatedListSfmTickers,
            items: _updatedList,
            commonActiveSFMReturnsRange: {
               start: activeStartRange,
               end: activeEndRange,
            },
            // modal: (_updatedList.length > 0),
         };

      case ActionTypes.REMOVE_SFM_ITEM_TICKERS_SUCCESS:
         const {
            selectedItemTickers: _selectedItemTickers,
            items: _ItemsList,
            modal: _modalState,
         } = state;
         const { removeTicker: _removeTicker } = payload;
         let _updatedItemsList = _ItemsList.filter(
            jk => jk.ticker !== _removeTicker
         );

         // SFM common start-end range
         let _commonStartRange = moment.max(
            _updatedItemsList
               .filter(e => e._start)
               .map(e => monthToDate(e._start))
         );
         let _defaultStartRange = moment(DateRange.start),
            _activeStartRange = moment.max(
               _defaultStartRange,
               _commonStartRange
            );

         let _commonEndRange = moment.max(
            _updatedItemsList.filter(e => e._end).map(e => monthToDate(e._end))
         );
         let _defaultEndRange = moment(DateRange.end),
            _activeEndRange = moment.max(_defaultEndRange, _commonEndRange);

         return {
            ...state,
            selectedItemTickers: _selectedItemTickers.filter(
               h => h !== _removeTicker
            ),
            items: _updatedItemsList,
            commonActiveSFMReturnsRange: {
               start: _activeStartRange,
               end: _activeEndRange,
            },
            modalToggleState: _modalState
               ? _updatedItemsList.length > 0
               : false,
         };

      case ActionTypes.UPDATE_HRS_DATA_SUCCESS:
         const { items: _SFMItemsList } = state;
         const { HRSdata, forTicker } = payload;

         if (HRSdata) {
            _SFMItemsList.forEach(jk => {
               if (jk.ticker === forTicker) {
                  jk._holdingData = HRSdata.holdingData;
                  jk._sectorData = HRSdata.sectorData;
                  jk._regionData = HRSdata.regionData;
               }
            });
         }

         // SFM common start-end range
         let _commonStartRange_ = moment.max(
            _SFMItemsList.filter(e => e._start).map(e => monthToDate(e._start))
         );
         let _defaultStartRange_ = moment(DateRange.start),
            _activeStartRange_ = moment.max(
               _defaultStartRange_,
               _commonStartRange_
            );

         let _commonEndRange_ = moment.max(
            _SFMItemsList.filter(e => e._end).map(e => monthToDate(e._end))
         );
         let _defaultEndRange_ = moment(DateRange.end),
            _activeEndRange_ = moment.max(_defaultEndRange_, _commonEndRange_);

         return {
            ...state,
            autoOpenModal: undefined, // DO NOT REMOVE THIS
            items: _SFMItemsList,
            commonActiveSFMReturnsRange: {
               start: _activeStartRange_,
               end: _activeEndRange_,
            },
         };

      case ActionTypes.GET_SFM_ITEM_DETAILS_SUCCESS:
         const {
            items: _SFMITEMS,
            sfmCatData: _sfmCatData,
            selectedItemTickers: _SELECTED_ITEM_TICKERS,
         } = state;
         const { categories: _categories } = payload;

         let _sel_sfm_items = _SFMITEMS;
         let _sel_item_tick = _SELECTED_ITEM_TICKERS;

         if (payload.funds) {
            _sel_sfm_items = union(_SFMITEMS, payload.funds);
         }
         if (payload.selectedItemTickers) {
            _sel_item_tick = union(_SELECTED_ITEM_TICKERS, [
               payload.selectedItemTickers,
            ]);
         }

         let _updated_SFM_ITEMS_List = uniqBy(_sel_sfm_items, "ticker");

         let _SFM_CAT_ARR = _sfmCatData;
         if (typeof _categories !== "undefined") {
            _SFM_CAT_ARR = uniqBy(_sfmCatData.concat(_categories), "name");
         }

         // SFM common start-end range
         let _commonStartRange__ = moment.max(
            _updated_SFM_ITEMS_List.map(e => monthToDate(e._start))
         );
         let _defaultStartRange__ = moment(DateRange.start),
            _activeStartRange__ = moment.max(
               _defaultStartRange__,
               _commonStartRange__
            );

         let _commonEndRange__ = moment.max(
            _updated_SFM_ITEMS_List.map(e => monthToDate(e._end))
         );
         let _defaultEndRange__ = moment(DateRange.end),
            _activeEndRange__ = moment.max(
               _defaultEndRange__,
               _commonEndRange__
            );

         return {
            ...state,
            sfmCatData: _SFM_CAT_ARR,
            selectedItemTickers: _sel_item_tick,
            items: _updated_SFM_ITEMS_List,
            commonActiveSFMReturnsRange: {
               start: _activeStartRange__,
               end: _activeEndRange__,
            },
         };

      case ActionTypes.DELETE_ALL_SFM_ITEM_TICKERS_SUCCESS:
         const {
            sfmCatData: _CatData,
            // selectedItemTickers: ___SELECTED_ITEM_TICKERS,
            myportfolio,
         } = state;

         return {
            ...state,
            ...initialState,
            ...payload,
            autoOpenModal: undefined, // DO NOT REMOVE THIS
            sfmCatData: _CatData,
            myportfolio,
         };

      case ActionTypes.SET_SFM_FUND_SELECTION_TYPE_SUCCESS:
         const { items: _iTs } = state;
         // let _selectedFFFund = _iTs;

         if (payload.selectedFund && payload.selectedFund.ticker) {
            // console.log(payload.selectedFund.ticker);
            _iTs.forEach(e => {
               if (e.ticker === payload.selectedFund.ticker) {
                  e.ticker_short = e.ticker_short || e.ticker;
                  e._selectedType = payload.selectedType; // 'buy' or 'sell';
               }
            });
         }

         let _updatedLLList = uniqBy(_iTs, "ticker");

         return {
            ...state,
            ...payload,
            items: _updatedLLList,
         };

      case ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_SUCCESS: {
         return {
            ...state,
            ...payload,
         };
      }

      case ActionTypes.SFM_REAL_TIME_PRICE_SOCKET: {
         const { items: __SFM_ITEMS } = state;
         // console.log(payload.realTimeData);
         if (
            payload.realTimeData &&
            typeof payload.realTimeData.price !== "undefined" &&
            typeof payload.realTimeData.symbol !== "undefined"
         ) {
            __SFM_ITEMS.forEach(uE => {
               // removed below if not testing
               uE.nav = payload.realTimeData.price || uE.nav;
               if (uE.ticker === payload.realTimeData.symbol) {
                  uE.nav = payload.realTimeData.price || uE.nav; // cn(uE, 'Nav Price')
               }
            });
         }

         return {
            ...state,
            ...payload,
            items: __SFM_ITEMS,
         };
      }

      case ActionTypes.ADD_TICKERS_TO_CART_SUCCESS:
         const { items: _ITEMS, selectedItemTickers: _SEL_ITEM_TICKERS } =
            state;
         const { buyFromURL } = payload;

         let _SEL_FF = _ITEMS;
         let _UPDATED_LIST_SFM_TICKS = _SEL_ITEM_TICKERS;

         if (payload.selectedFund) {
            _SEL_FF = union(_SEL_FF, payload.selectedFund);
            _SEL_FF.forEach(e => {
               e.ticker_short = e.ticker_short || e.ticker;
               e._selected = true;
               e._selector = true;
               e._checkedToExecute = true;
               let price = cn(e, "Nav Price");
               let minInvestment = e.minimum_investment;
               let _minInvestment = numberCheck(minInvestment, 5) || 5; // minimum investment is $5
               let _price = numberCheck(price, 5);

               // if minInvestment is 0 || null then -> check _price with $5
               if (!numberCheck(minInvestment) && _price < _minInvestment) {
                  _minInvestment = _price;
               }

               e.minimum_investment = _minInvestment;
            });
         }
         if (payload.selectedItemTickers) {
            _UPDATED_LIST_SFM_TICKS = union(
               _SEL_ITEM_TICKERS,
               payload.selectedItemTickers
            );

            _SEL_FF.forEach(e => {
               if (e.ticker === payload.selectedItemTickers) {
                  e._selectedType = payload.selectedType || "buy"; // 'buy' or 'sell';
                  let price = cn(e, "Nav Price");
                  let minInvestment = e.minimum_investment;
                  let _minInvestment = numberCheck(minInvestment, 5) || 5; // minimum investment is $5
                  let _price = numberCheck(price, 5);

                  // if minInvestment is 0 || null then -> check _price with $5
                  if (!numberCheck(minInvestment) && _price < _minInvestment) {
                     _minInvestment = _price;
                  }

                  e.minimum_investment = _minInvestment;
               }
            });
         }

         let _UPD_LIST = uniqBy(_SEL_FF, "ticker");
         // SFM common start-end range
         let cSR = moment.max(_UPD_LIST.map(e => monthToDate(e._start)));
         let dSR = moment(DateRange.start),
            aSR = moment.max(dSR, cSR);

         let cER = moment.max(_UPD_LIST.map(e => monthToDate(e._end)));
         let dER = moment(DateRange.end),
            aER = moment.max(dER, cER);

         return {
            ...state,
            ...payload,
            buyFromURL,
            autoOpenModal: true,
            items: _UPD_LIST,
            selectedFund: _UPD_LIST,
            selectedItemTickers: _UPDATED_LIST_SFM_TICKS,
            commonActiveSFMReturnsRange: { start: aSR, end: aER },
            // modal: (_updatedList.length > 0),
         };

      case ActionTypes.GET_USER_PORTFOLIO_SUCCESS:
         return {
            ...state,
            myportfolio: payload,
         };

      case ActionTypes.GET_PORTFOLIO_DETAILS_FOR_SFM_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.GET_COMBINE_SCORE_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      default:
         return state;
   }
}
