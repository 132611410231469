const internationalConfig = {
   apiBaseUrl: "https://international.magnifi.com/api",
   tdAmeritradeCallbackUrl:
      "https://auth.tdameritrade.com/oauth?client_id=RFAVCVHFFQXHBK5AQ5RX83GM1DEO0DUW@AMER.OAUTHAP&response_type=code&redirect_uri=https://advisor.magnifi.com/callbacks/td/td-auth-code",
   tradeItOrigin: "https://www.trade.it",
   tradingTicketorigin: "https://ems.tradingticket.com",
   googleAnalyticsCode: "",
   googleTaManagerCode: "",
   googleGTAGUAConfig: {},
   universeShare: true,
   heapAnalyticsCode: "2082602943",
   relatedInsight: true,
   impressionTag: true,
   tdCallbackPath: "/callbacks/tdameritrade",
   sponsoredTicker: true,
   PtarmiganTag: false,
   advisorRetailRedirect: true,
   advisor_redirect: "https://global.magnifi.com",
   retail_redirect: "https://magnifi.com",
   stripePublishableKey: "pk_live_Fbw7tmcssU5kLiVpqndAvr5F00rkoboPsx",
   version: "2.0.0",
   sentryEnv: "production",
   appleSSORedirectURL: "https://global.magnifi.com/register",
   transferRequest: false,
   googleManagerTag: true,
   vendorConversionPixel: true,
   // To be added after international prod instance is created
   REACT_APP_OKTA_IDP: "0oa1m1w5if9xYSwaf697",
   REACT_APP_OKTA_ISSUER: "magnifi.okta.com",
   REACT_APP_OKTA_CLIENT_ID: "0oa1m1yl1txz8Zyyn697",
   // REACT_APP_OKTA_IDP: '0oa1k4ywmmhmfXmOT697',
   // REACT_APP_OKTA_ISSUER: 'magnifi-dev.okta.com', // 'dev.auth.tifinwealth.com',
   // REACT_APP_OKTA_CLIENT_ID: '0oa1k4uyv06twnAW8697',
   feedbackGooglesheetKey:
      "https://script.google.com/macros/s/AKfycbwFxb9keSNi0njT5NLptIy8T8U9Oy-Ksgxjcg6dGQRd-OyZ1D-j/exec",
   segmentWriteKey: "Q2qja7npmQKGMKkMtyM14LADi5CxSTke",
   avatarURL: "https://avatar.magnifi.com/",
   googleClientId:
      "326538060414-5vqrgaosddu857h2siom011r796tfl4j.apps.googleusercontent.com",
};

export default internationalConfig;
