import { createAPIInstance } from "./api";

export function sendClientListData(payload) {
   return createAPIInstance()
      .post("/user/upload-client-portfolios", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function fetchClientListData() {
   return createAPIInstance()
      .get("/user/client-list")
      .then(response => response);
}

export function fetchClientListByPageNumber(payload) {
   return createAPIInstance()
      .get("/v1/user/client-list", {
         params: payload,
      })
      .then(response => response.data);
}

export function deleteSelectedClients(payload) {
   return createAPIInstance()
      .post("/user/delete-client", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response);
}

// export function fetchClientHoldingsData(payload) {
//   return createAPIInstance().post('v1/user/client-portfolio-details',{client_id : payload.client_id ,query_var: payload.query_var},{
//     headers: { 'Content-Type': 'application/json' },
//   })
//   .then(response => response)
// }

export function fetchClientHoldingsData(payload) {
   return createAPIInstance()
      .post("/v1/user/client-portfolio-details", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function saveClientBenchMarkData(payload) {
   return createAPIInstance()
      .post(
         "/user/add/client/benchmark",
         { client_id: payload.client_id, benchmark: payload.benchmark },
         {
            headers: { "Content-Type": "application/json" },
         }
      )
      .then(response => response);
}

export function removeClientBenchMarkData(payload) {
   createAPIInstance()
      .delete("/user/delete/client/benchmark", {
         headers: { "Content-Type": "application/json" },
         data: { client_ids: payload.client_id },
      })
      .then(response => response);
}

// export function fetchClientChartDetails(payload){
//   return createAPIInstance().post('/user/get-account-value-data', payload ,{
//     headers: { 'Content-Type': 'application/json' },
//   })
//   .then(response => response)
// }

export function fetchClientChartDetails(payload) {
   return createAPIInstance()
      .post("/user/get-account-value-data", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function fetchModelPfHoldingsData(payload) {
   return createAPIInstance()
      .get("/model-portfolio-details", {
         params: payload,
      })
      .then(response => response);
}

export function fetchClientPortfolioHoldingsById(payload) {
   return createAPIInstance()
      .get("/user/upload-portfolio-result", {
         params: payload,
      })
      .then(response => response.data);
}

export function fetchCustomPfHoldingsData(payload) {
   return createAPIInstance()
      .get("/user/upload-portfolio-result", {
         params: payload,
      })
      .then(response => response);
}

export function sendFinalizeAllocation(payload) {
   return createAPIInstance()
      .post("/user/modal-allocation", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response);
}

export function fetchTradelistData(payload) {
   return createAPIInstance()
      .post("/user/generate-tradelist", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response);
}

export function fetchSearchAccFundsData(payload) {
   return createAPIInstance()
      .get("/user/search-account-funds", {
         params: payload,
      })
      .then(response => response);
}

export function fetchModelSearchData(payload) {
   return createAPIInstance()
      .get("/computed-search", {
         params: payload,
      })
      .then(response => response);
}

export function sendRestrictedTickers(payload) {
   return createAPIInstance()
      .post("/user/accounts/restricted-tickers", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response);
}

export function sendUpdatedTaxRates(payload) {
   return createAPIInstance()
      .post("/user/accounts/update-tax-details", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response);
}

export function fetchTaxBillsData(payload) {
   return createAPIInstance()
      .get("/user/accounts/tax-bills", {
         params: payload,
      })
      .then(response => response);
}

export function sendSelectedTaxBill(payload) {
   return createAPIInstance()
      .post("/user/accounts/update-tax-details", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response);
}

// NOTE: 'Search Bar' in client accounts page
// '/v1/user/search-account-funds?q=holds+ibm&page_number=1&count=30'
export function searchQueryAndGetClientList(payload) {
   return createAPIInstance()
      .get("/v1/user/search-account-funds", {
         params: payload,
      })
      .then(response => response.data);
}
