import zipObject from "lodash/zipObject";

const actions = [
   "UPLOAD_CLIENT_LIST",
   "UPLOAD_CLIENT_LIST_SUCCESS",
   "SEND_CLIENT_LIST",
   "REQUEST_CLIENT_LIST",
   "CLIENT_LIST_LOADER",
   "CLIENT_LIST_SUCCESS",
   "CLIENT_LIST_FAILURE",

   "REQUEST_CLIENT_DELETE",
   "CLIENT_DELETE_SUCCESS",
   "CLIENT_DELETE_FAILURE",

   "REQUEST_CLIENT_DETAILS",
   "REQUEST_CHART_DETAILS",
   "CHART_DETAIL_SUCCESS",
   "CLIENT_DETAILS_SUCCESS",
   "CLIENT_DETAILS_FAILURE",

   "REQUEST_CLIENT_HOLDINGS",
   "CLIENT_HOLDINGS_SUCCESS",
   "CLIENT_HOLDINGS_FAILURE",

   "REQUEST_PF_HOLDINGS",
   "PF_HOLDINGS_SUCCESS",
   "PF_HOLDINGS_FAILURE",

   "REQUEST_CLEAR_HOLDINGS",

   "REQUEST_PORTFOLIO_OPTIONS",
   "PORTFOLIO_OPTIONS_SUCCESS",
   "PORTFOLIO_OPTIONS_FAILURE",

   "SET_SELECTED_PORTFOLIO",
   "UPDATE_CLIENT_LIST_SUCCESS",
   "RESET_CLIENT_LIST",

   "CREATE_FINALIZE_OBJECT",
   "REQUEST_FINALIZE_ALLOCATION",
   "FINALIZE_ALLOCATION_SUCCESS",
   "FINALIZE_ALLOCATION_FAILURE",

   "REQUEST_GENERATE_TRADELIST",
   "GENERATE_TRADELIST_SUCCESS",
   "GENERATE_TRADELIST_FAILURE",

   "REQUEST_SEARCH_ACCOUNT_FUNDS",
   "SEARCH_ACCOUNT_FUNDS_SUCCESS",
   "SEARCH_ACCOUNT_FUNDS_SUCCESS_QUERY",
   "SEARCH_ACCOUNT_FUNDS_FAILURE",
   "SEARCH_ACCOUNT_FUNDS_RESET",

   "REQUEST_MODEL_SEARCH_RESULTS",
   "MODEL_SEARCH_RESULTS_SUCCESS",
   "MODEL_SEARCH_RESULTS_FAILURE",
   "MODEL_SEARCH_NO_RESULTS",

   "UPDATE_RESTRICTED_TICKERS",
   "RESTRICTED_TICKERS_UPDATE_SUCCESS",
   "RESTRICTED_TICKERS_UPDATE_FAILURE",

   "UPDATE_TAX_RATES",
   "TAX_RATES_UPDATE_SUCCESS",
   "TAX_RATES_UPDATE_FAILURE",

   "REQUEST_TAX_BILLS",
   "TAX_BILLS_SUCCESS",
   "TAX_BILLS_FAILURE",

   "UPDATE_SELECTED_TAX_BILL",
   "SELECTED_TAX_BILL_SUCCESS",
   "SELECTED_TAX_BILL_FAILURE",

   "FILTER_CLIENT_LIST",

   "UPLOAD_SINGLE_CLIENT_REQUEST",
   "UPLOAD_SINGLE_CLIENT_FETCH",
   "UPLOAD_SINGLE_CLIENT_SUCCESS",
   "SET_INITIAL_CLIENT_LIST",

   "REQUEST_CLIENT_BENCHMARK",
   "REMOVE_CLIENT_BENCHMARK",

   "ACCOUNTPAGE_CLIENT_LIST_REQUEST",
   "ACCOUNTPAGE_CLIENT_LIST_FETCH",
   "ACCOUNTPAGE_CLIENT_LIST_SUCCESS",

   "RESET_PAGINATION_AND_DOWNLOADED_LIST",

   "GET_CLIENT_LIST_BY_QUERY_REQUEST",
   "GET_CLIENT_LIST_BY_QUERY_FETCH",
   "GET_CLIENT_LIST_BY_QUERY_SUCCESS",

   "DOWNLOAD_MORE_DATA_REQUEST",
   "DOWNLOAD_MORE_DATA_FETCH",
   "DOWNLOAD_MORE_DATA_SUCCESS",

   "GET_SELECTED_CLIENT_DETAILS_REQUEST",
   "GET_SELECTED_CLIENT_DETAILS_FETCH",
   "GET_SELECTED_CLIENT_DETAILS_SUCCESS",

   "UPDATE_RESTRICTED_TICKERS_REQUEST",
   "UPDATE_RESTRICTED_TICKERS_FETCH",
   "UPDATE_RESTRICTED_TICKERS_SUCCESS",

   "DELETE_CLIENT_ACCOUNT_REQUEST",
   "DELETE_CLIENT_ACCOUNT_FETCH",
   "DELETE_CLIENT_ACCOUNT_SUCCESS",

   "FILTER_CLIENT_LIST_REQUEST",
   "FILTER_CLIENT_LIST_FETCH",
   "FILTER_CLIENT_LIST_SUCCESS",

   "RESET_FILTER_CLIENT_LIST_REQUEST",
   "RESET_FILTER_CLIENT_LIST_FETCH",
   "RESET_FILTER_CLIENT_LIST_SUCCESS",

   "SET_SEARCH_ACCOUNT_TICKER_INDEX",
   "FINALIZE_ALLOCATION_REQUEST",
   "FINALIZE_ALLOCATION_FETCH",
   "FINALIZE_ALLOCATION_SUCCESS",
   "FINALIZE_ALLOCATION_FAILURE",
];

export default zipObject(actions, actions);
