import axios from "axios";

// valid for 12 months
const cloutToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2FwaS5teWNsb3V0LmNvbS8iLCJpYXQiOjE2NjU0MTA4MDIsIm5iZiI6MTY2NTQxMDgwMiwiZXhwIjoxNjk2OTQ2NTY3LCJ1aWQiOiJrM3ZEekRxUSJ9.sQOOeQC7Uk_KOhEO0So0aH49x3eooguswttu1g9CFOI`;

export function getRelatedInsightsData(payload) {
   const { id_hash } = payload;
   const api = axios.create({
      headers: {
         Accept: "application/json",
         Authorization: `Bearer ${cloutToken}`,
      },
   });

   api.interceptors.response.use(
      response => {
         return response;
      },
      error => {
         if (error && error.response && error.response.status === 401) {
            return null;
         }
         return Promise.reject(error);
      }
   );

   // return api.get(`https://apialpha.marketinsightout.com/v1.1/user/search/content/{"search":"${payload.query}"}`)
   //   .then(response => response.data)
   //  return api.get(`https://api.marketinsightout.com/v1.3/widget/content/${id_hash}`)
   return api
      .get(`https://api.myclout.com/v1.3/widget/content/${id_hash}`)
      .then(response => response.data);
}
