import { createAPIInstance } from "./api";

export function requestCancelSubscription(payload) {
   console.log(payload);
   return createAPIInstance()
      .get(
         "/user/cancel/subscription/send_email",
         { params: { subscription_detail: payload } },
         {
            headers: { "Content-Type": "application/json" },
         }
      )
      .then(response => response);
}

export function cancelAchRelation(payload) {
   const api = createAPIInstance();
   return api.post("/user/apex/ach_relation/cancel", payload, {
      headers: { "Content-Type": "application/json" },
   });
}

export function subscriptionPlan() {
   return createAPIInstance()
      .get("/user/subscription-plan", {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function inviteFriends(payload) {
   return createAPIInstance()
      .post("/refer/invite", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function fetchProfile(payload) {
   const api = createAPIInstance();
   // return api.get('user/profile', { params: { apex } }, payload)
   return api.get("user/profile", payload).then(response => {
      if (response.data) {
         // reponse.data.profile is missing in the current api
         const self_role = response.data.self_role; // || response.data.profile.self_role;
         window.localStorage.setItem("profile_self_role", self_role);
      }
      return response;
   });
}

export function fetchProfileTradeHistory(payload, page_number) {
   const api = createAPIInstance();
   return api.get(
      "user/apex/get_apex_trade_history",
      { params: { page_number } },
      payload
   );
}

export function fetchProfileApexDetails(payload) {
   const api = createAPIInstance();
   return api.get("user/apex/get_apex_details_for_user", payload);
}

export function updateProfile(payload) {
   const api = createAPIInstance();
   return api.post("user/profile", payload);
}

export function changePassword(payload) {
   const api = createAPIInstance();
   return api.post("user/changepwd", payload);
}

export function referral(payload) {
   return createAPIInstance()
      .get("/referred-users", payload)
      .then(response => response.data);
}

export function createSubscriptionRequest(payload) {
   const api = createAPIInstance();
   return api.post("/payment-initialization", payload, {
      headers: { "Content-Type": "application/json" },
   });
}

export function unsubscribeUserRequest(payload) {
   const api = createAPIInstance();
   return api.post("/cancel-subscription", payload);
}

export function reactivateSubcriptionRequest(payload) {
   const api = createAPIInstance();
   return api.post("/reactivate-subscription", payload);
}

export async function uploadCustomLogo(payload) {
   const api = createAPIInstance();
   return api.post("/user/upload-profile-logo", payload);
}

export function updateTradingAccount(payload) {
   const form = new FormData();
   for (let key in payload) {
      form.append(key, payload[key]);
   }
   const api = createAPIInstance();
   return api.post("user/apex/account_requests_update", form, {
      headers: { "Content-Type": "multipart/form-data" },
   });
}

export function cancelAccount(payload) {
   const api = createAPIInstance();
   return api.post(
      "/user/apex/create/request?request_type=CLOSE_ACCOUNT",
      payload,
      {
         headers: { "Content-Type": "application/json" },
      }
   );
}

export function transferAccount(payload) {
   const api = createAPIInstance();
   return api.post(
      "/user/apex/create/request?request_type=TRANSFER_ACCOUNT",
      payload,
      {
         headers: { "Content-Type": "application/json" },
      }
   );
}

export function cancelDeposits(payload) {
   const api = createAPIInstance();
   return api.post("/user/apex/ach/cash/cancel", payload, {
      headers: { "Content-Type": "application/json" },
   });
}

export function cancelTrades(payload) {
   const api = createAPIInstance();
   return api.post("/user/apex/trade/cancel", payload, {
      headers: { "Content-Type": "application/json" },
   });
}

export function saveSFMReportPrintSelection(payload) {
   return createAPIInstance().post("/user/report-preferences", payload, {
      headers: { "Content-Type": "application/json" },
   });
}

export function getDownloadedProposalsList() {
   return createAPIInstance()
      .get(`get-user-report-filter`)
      .then(res => res.data);
}

export function downloadPortfolioPDF(payload) {
   return createAPIInstance().get("v1/download-user-report", {
      params: payload,
      responseType: "arraybuffer",
   });
}

export function getTotalProposalsCount() {
   return createAPIInstance()
      .get(`get-user-report-filter?report_type=count`)
      .then(res => res.data);
}

export function fetchApexData(payload) {
   return createAPIInstance()
      .get(`user/profile`, payload)
      .then(res => res.data);
}

/* set similar searches flag */
export function setSimilarSearchesFlag(payload) {
   return createAPIInstance()
      .post(
         "user/activate/show-similar-user-queries",
         JSON.stringify(payload),
         {
            headers: { "Content-Type": "application/json" },
         }
      )
      .then(res => res.data);
}

export function deleteAccountEmail(payload) {
   return createAPIInstance()
      .post("user/unregister", JSON.stringify(payload), {
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res.data);
}

export function updateBasketAgreement() {
   return createAPIInstance()
      .post(
         "user/accept-basket-agreement",
         JSON.stringify({ basket_agreement: true }),
         {
            headers: { "Content-Type": "application/json" },
         }
      )
      .then(res => res.data);
}

export function deleteAccount(payload) {
   return createAPIInstance()
      .get(`user/unregister_verification?token=${payload.token}`, {
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res.data);
}

export function tokenExpiryCheck(payload) {
   return createAPIInstance()
      .get(`user/token_validity?query=${payload.token}`, {
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res.data);
}

// curl --location --request GET 'https://dev.magnifi.com/api/user/change-region?market=IN' \
// --header 'Cookie: csrftoken=wiAwCAj2pXzjIxE9iHAkdBq5tlR6dgvGE1e7Dr0r6bcd7ML6JSwrdCaTHSRF95gT'

export function changeRegion(payload) {
   const { market = "USA", isRegion = false } = payload;
   return createAPIInstance()
      .get(`user/change-region`, {
         headers: { "Content-Type": "application/json" },
         params: { market: market, is_region: isRegion },
      })
      .then(res => res.data);
}

export function uploadProfilePicture(payload) {
   return createAPIInstance()
      .post("user/upload-user-avatar", payload)
      .then(response => response.data);
}

export function profilePictureVisible(payload) {
   const form = new FormData();
   for (let key in payload) {
      form.append(key, payload[key]);
   }
   return createAPIInstance()
      .post("user/change-avatar-visible", form, {
         headers: { "Content-Type": "multipart/form-data" },
      })
      .then(response => response.data);
}
