import AuthActionTypes from "./auth";
import SfmActionTypes from "./sfm";
import RegisterActionTypes from "./register";
import ProfileActionTypes from "./profile";
import FundsActionTypes from "./funds";
import BrokerActionTypes from "./broker";
import InvestmentActionTypes from "./investment";
import PluginsActionTypes from "./plugins";
import WeightsActionTypes from "./weights";
import GlobalActionTypes from "./global";
import PortfolioActionTypes from "./portfolio";
import BuildPortfolioActionTypes from "./buildportfolio";
// import ClientPortfolioActionTypes from './clientportfolio';
import ClientPortfolioV2ActionTypes from "./clientportfolioV2";
import VideoPlayerActionTypes from "./videoplayer";
import reportActionTypes from "./reports";
import EnhancerActionTypes from "./enhancer";
import AccountsActionTypes from "./accounts";
import DiscoveryActionTypes from "./discovery";
import CommunityActionTypes from "./community";
import QuickTradeActionTypes from "./quicktrade";
import TrendingSearchesActionTypes from "./trendingsearches";
import AlertActionTypes from "./alert";
import GlobalModals from "./globalModals";

export default {
   ...SfmActionTypes,
   ...AuthActionTypes,
   ...RegisterActionTypes,
   ...ProfileActionTypes,
   ...FundsActionTypes,
   ...BrokerActionTypes,
   ...InvestmentActionTypes,
   ...PluginsActionTypes,
   ...WeightsActionTypes,
   ...GlobalActionTypes,
   ...PortfolioActionTypes,
   ...BuildPortfolioActionTypes,
   // ...ClientPortfolioActionTypes,
   ...ClientPortfolioV2ActionTypes,
   ...VideoPlayerActionTypes,
   ...reportActionTypes,
   ...EnhancerActionTypes,
   ...AccountsActionTypes,
   ...DiscoveryActionTypes,
   ...TrendingSearchesActionTypes,
   ...AlertActionTypes,
   ...QuickTradeActionTypes,
   ...CommunityActionTypes,
   ...GlobalModals,
};
