import { takeEvery, fork, call, put, all, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import { find, uniqBy } from "lodash";
import ActionTypes from "../action-types";
import {
   setInitialWeightsData,
   setWeightsData,
   saveScoreAttrs,
   setScoreAttrs,
   updateCalculatorName,
   updateCustomCalculatorList,
   changeCalculator,
   setCalculator,
   updateAllCalculatorList,
   updateAllSelectorAttrs,
   updateDefaultCalculator,
} from "../actions/weights";
import * as weigthsAPI from "../apis/weights";
import { calculatorOptions, reverseMapCodeKeys } from "DataSet";
import { immutableObject } from "Utils";

function* fetchWeightsData(data) {
   const { callback } = data;
   try {
      let response = yield call(weigthsAPI.fetchWeightsDataV1);
      let allSelectorAttrs = yield call(weigthsAPI.getSelectorAttributes);
      // Get all selector attributes for create custom selector
      if (allSelectorAttrs) {
         // Remove this for loop once API is corrected
         allSelectorAttrs = uniqBy(allSelectorAttrs, "col");
         for (let i = 0; i < allSelectorAttrs.length; i++) {
            allSelectorAttrs[i].yearDropdown =
               allSelectorAttrs[i]["year_dropdown"];
            allSelectorAttrs[i].default = allSelectorAttrs[i]["default_value"];
            allSelectorAttrs[i].colOff = allSelectorAttrs[i]["col_off"];
            delete allSelectorAttrs[i].year_dropdown;
            delete allSelectorAttrs[i].default_value;
            delete allSelectorAttrs[i].col_off;
            // Delete from here
            // Replace new code key with the old code
            const reverseKey = reverseMapCodeKeys[allSelectorAttrs[i].code];
            if (reverseKey) {
               allSelectorAttrs[i].code = reverseKey;
            }
            // To here
         }
         yield put(updateAllSelectorAttrs(allSelectorAttrs));
      }
      if (response) {
         const isPremiumUser = yield select(
            state => state.auth.user.premiumUser
         );
         let calculatorWithAttrs = {};
         let allCalculators = [];
         let defaultCalculator = response.find(item => item.default_calculator);
         const isScreener =
            isPremiumUser &&
            defaultCalculator &&
            defaultCalculator.default_screener;
         defaultCalculator =
            isPremiumUser && defaultCalculator
               ? defaultCalculator.calculator_name
               : "Magnifi fund selector";
         if (isScreener) {
            defaultCalculator = defaultCalculator.replace(
               "selector",
               "screener"
            );
         }
         response.forEach(item => {
            // Delete from here
            // Replace new code key with the old code
            item.weights.forEach(attr => {
               const reverseKey = reverseMapCodeKeys[attr.weight_name];
               if (reverseKey) {
                  attr.weight_name = reverseKey;
               }
            });
            // To here
            calculatorWithAttrs[item.calculator_name] = item.weights;
            allCalculators.push({
               calculator_id: item.calculator_id,
               calculator_name: item.calculator_name,
            });
         });
         const initializeCalculator = {
            calculatorWithAttrs,
            allCalculators,
            defaultCalculator,
         };
         yield put(setInitialWeightsData(initializeCalculator));
         yield put(setCalculator(defaultCalculator));
      }
      if (callback && typeof callback == "function") {
         callback();
      }
   } catch (error) {
      console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
   }
}

function* postWeightsFormData(data) {
   const { payload } = data;
   try {
      const updatedWeights = [];

      payload.forEach(weight => {
         if (weight.weight > 0) {
            updatedWeights.push({
               weight_name: weight.name,
               weight_value: weight.weight,
            });
         }
         // Delete from here
         // Replace old code key with the new code
         const reverseKey = Object.keys(reverseMapCodeKeys).find(
            key => reverseMapCodeKeys[key] === weight.name
         );
         if (reverseKey) {
            weight.name = reverseKey;
         }
         // To here
      });

      yield put(
         setWeightsData({ loading: true, weightSuccess: false, error: "" })
      );
      let selectedCalculator = yield select(
         state => state.weights.selectedCalculator
      );
      if (selectedCalculator === "create custom selector") {
         const customCalcName = yield select(
            state => state.weights.customSelectorName
         );
         // Adding this block to update the calculator name in the
         // compare drppdown before saving the data
         yield put(updateCalculatorName(customCalcName));
         selectedCalculator = customCalcName;
      }
      const updatedData = {
         calculator_name: selectedCalculator,
         weights: payload,
      };

      let location = yield select(state => state.router.location);
      let sfmModalToggle = yield select(state => state.sfm.modalToggleState);
      // let sfmItems = yield select(state => state.sfm.items);

      if (
         location &&
         location.pathname === "/investments" &&
         sfmModalToggle === false
      ) {
         // for "/investments" ==>  calculator_name : "passive fund selector",
         // updatedData.calculator_name = "passive fund selector";
      }

      const response = yield call(
         weigthsAPI.postWeightsFormDataV1,
         updatedData
      );
      if (response) {
         if (response.message && response.message === "Successfully Updated") {
            let allCalculators = yield select(
               state => state.weights.allCalculators
            );
            allCalculators.push({
               calculator_id: response.calculator_id,
               calculator_name: selectedCalculator,
            });
            yield put(updateAllCalculatorList(allCalculators));
            yield put(
               setWeightsData({ weightScore: updatedWeights, loading: false })
            );
            const scoreAttrs = yield select(
               ({ weights }) => weights.scoreAttrs
            );
            const finalAttrs = [...scoreAttrs];
            const attrs = [];
            payload.forEach(({ name, weight, lock }) => {
               // const rest = find(finalAttrs, x => x.code === name);
               // Uncomment the previous line
               // and Delete from here
               const reverseKey = reverseMapCodeKeys[name];
               const oldCode = reverseKey ? reverseKey : name;
               const rest = find(finalAttrs, x => x.code === oldCode);
               // To here
               attrs.push({
                  ...rest,
                  weight,
                  lock,
               });
            });
            yield put(saveScoreAttrs(attrs));
         } else throw new Error("Something went wrong. Please try again");
         // yield put(setWeightsData({ loading : false }));
      }
   } catch (error) {
      yield put(
         setWeightsData({ loading: false, weightSuccess: false, error })
      );
      yield delay(2000);
      yield put(setWeightsData({ error: "" }));
      console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
   }
}

// Separating the screener logic here,
// will have to revisit this later
function* postScreenerData(data) {
   const { payload } = data;
   try {
      const postData = payload.map(attr => {
         return {
            name: attr.col,
            weight: attr.weight,
            screener: attr.screener,
         };
      });

      yield put(
         setWeightsData({ loading: true, weightSuccess: false, error: "" })
      );

      let selectedCalculator = yield select(
         state => state.weights.selectedCalculator
      );
      if (selectedCalculator === calculatorOptions.magnifiactivescreener) {
         selectedCalculator = calculatorOptions.magnifiactivecalculator;
      }

      const updatedPayload = {
         calculator_name: selectedCalculator,
         weights: postData,
      };

      const response = yield call(
         weigthsAPI.postWeightsFormDataV1,
         updatedPayload
      );

      if (response) {
         if (response.message && response.message === "Successfully Updated") {
            yield put(setWeightsData({ loading: false }));
            yield put(setScoreAttrs(payload));
         } else throw new Error("Something went wrong. Please try again");
      }
   } catch (error) {
      yield put(
         setWeightsData({ loading: false, weightSuccess: false, error })
      );
      yield delay(2000);
      yield put(setWeightsData({ error: "" }));
      console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
   }
}

function* deleteCalculator(data) {
   const { payload, callback } = data;
   try {
      const response = yield call(weigthsAPI.deleteCalculator, payload);
      if (response.message === "File deleted successfully") {
         let customCalculators = immutableObject(
            yield select(state => state.weights.customCalculators)
         );
         const selectedCalculator = yield select(
            state => state.weights.selectedCalculator
         );
         const defaultCalculator = yield select(
            state => state.weights.defaultCalculator
         );
         if (payload in customCalculators) {
            delete customCalculators[payload];
            yield put(updateCustomCalculatorList(customCalculators));
            if (defaultCalculator === payload) {
               yield put(
                  changeCalculator({
                     name: "Magnifi fund selector",
                     setDefault: true,
                  })
               );
            } else if (selectedCalculator === payload) {
               yield put(
                  changeCalculator({
                     name: "Magnifi fund selector",
                     setDefault: false,
                  })
               );
            }
            if (callback && typeof callback === "function") {
               callback("success");
            }
         }
      }
   } catch (error) {
      if (callback && typeof callback === "function") {
         callback("failure");
      }
      console.log("error --->", error);
   }
}

function* switchCalculator(data) {
   const { payload } = data;
   try {
      const setDefault = typeof payload !== "string" && payload.setDefault;
      const newCalculator =
         typeof payload !== "string" && payload.name ? payload.name : payload;
      if (setDefault) {
         // const allCalculators = yield select(
         //    state => state.weights.allCalculators
         // );
         // const availableCalculators = Object.values(calculatorOptions);
         // const isScreener =
         //    newCalculator.includes("screener") &&
         //    availableCalculators.includes(newCalculator);
         // const calculatorName = isScreener
         //    ? newCalculator.replace("screener", "selector")
         //    : newCalculator;
         // const selectedCalculator = allCalculators.find(
         //    item => item.calculator_name === calculatorName
         // );
         // const calculator_id = selectedCalculator
         //    ? selectedCalculator.calculator_id
         //    : 0;
         yield put(updateDefaultCalculator(newCalculator));
         yield put(setCalculator(newCalculator));
         if (newCalculator !== "create custom selector") {
            // const response = yield call(weigthsAPI.setDefaultCalculator, {
            //    id: calculator_id,
            //    default_screener: isScreener,
            // });
         }
      } else {
         yield put(setCalculator(newCalculator));
      }
   } catch (error) {
      console.log("error --->", error);
   }
}

function* weightSaga() {
   yield all([
      fork(takeEvery, ActionTypes.FETCH_WEIGHTS_DATA, fetchWeightsData),
      fork(takeEvery, ActionTypes.CHANGE_CALCULATOR, switchCalculator),
      fork(takeEvery, ActionTypes.POST_WEIGHTS_FORM_DATA, postWeightsFormData),
      fork(takeEvery, ActionTypes.POST_SCREENER_DATA, postScreenerData),
      fork(takeEvery, ActionTypes.DELETE_CALCULATOR, deleteCalculator),
   ]);
}

export default weightSaga;
