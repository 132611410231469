import { takeEvery, fork, call, put, all, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import qs from "query-string";
import get from "lodash/get";
import dateFns from "date-fns";
// import CryptoJS from 'crypto-js';
import ActionTypes from "action-types";
import * as actions from "actions/profile";
import * as authAction from "actions/auth";
import { setProfileGlobalMarketRegion } from "actions/global";
// import * as sfmAction from 'actions/sfm';
import { setPortfolioState } from "actions/portfolio";
// import {
//   getSimilarSearchListRequest,
// } from 'actions/discovery';
import { getAuthState } from "selectors/auth-selector";
import * as ProfileApi from "apis/profile";
import * as AuthApi from "apis/auth";
import * as AuthUtils from "common/auth-utils";
import * as testDriveApis from "apis/test-drive";
import { openNotificationWithIcon } from "layouts/utils";
import { searchUrlV3 } from "Utils";

const DEFAULT_PROFILE_VALUE = {
   email: "",
   first_name: "",
   last_name: "",
   address: "",
   city: "",
   state: "",
   zipcode: "",
   phone: "",
   country: "",
   gender: "",
   date_of_birth: "",
   hear_aboutus: "",
   investable_assets: "",
   self_role: "",
   securityquestion: "",
   securityquestionanswer: "",
   investing_exp: "",
   ssn_tin: "",
   street: "",
};

function* updateTradAccDisclosure(action) {
   const { payload } = action;
   try {
      yield put(actions.updateTradAccDisclosureLoading(true));
      const response = yield call(ProfileApi.updateTradingAccount, payload);
      if (response.status === 200) {
         yield put(actions.fetchProfile());
         yield put(actions.updateTradAccDisclosureLoading(false));
         yield put(actions.updateTradAccDisclosureSuccess(response.data));
      }
   } catch (error) {
      yield put(actions.updateTradAccDisclosureLoading(false));
      yield put(actions.updateTradAccDisclosureError(error));
   }
}

function* cancelAchRelation(action) {
   const { payload } = action;
   try {
      yield put(actions.cancelAchRelationLoading());
      const response = yield call(ProfileApi.cancelAchRelation, payload);
      if (response.status === 200) {
         yield put(actions.cancelAchRelationSuccess(payload.id));
      }
   } catch (error) {
      console.log(error);
   }
}

function* subscriptionPlan(action) {
   try {
      yield put(actions.subscriptionPlanFetch());
      const response = yield call(ProfileApi.subscriptionPlan);
      const authState = yield select(getAuthState);
      const user = authState.user;
      if (response && response.length) {
         yield put(
            actions.subscriptionPlanSuccess({
               user: {
                  ...user,
                  premiumUser: response[0]["is_premium"] === "true",
                  planName: response[0]["plan_name"] || "",
                  plan_name: response[0]["plan_name"] || "",
               },
               premiumFeatures: response[0]["feature_allowed"] || [],
            })
         );
         yield put(
            authAction.updatePremiumState({
               user: {
                  ...user,
                  premiumUser: response[0]["is_premium"] === "true",
                  plan_name: response[0]["plan_name"],
                  plan_start_date: response[0]["plan_start_date"],
                  plan_end_date: response[0]["plan_end_date"],
                  subscriber_since: response[0]["subscriber_since"],
                  subscription_id: response[0]["subscription_id"],
                  renews_on: response[0]["renews_on"],
                  cancellation_request_status:
                     response[0]["cancellation_request_status"],
                  cancels_on: response[0]["cancels_on"],
                  planName: response[0]["plan_name"] || "",
                  feature_allowed: response[0]["feature_allowed"] || [],
               },
            })
         );
      } else {
         yield put(
            authAction.updatePremiumState({
               user: {
                  ...user,
                  premiumUser: false,
               },
            })
         );
         yield put(
            actions.subscriptionPlanSuccess({
               user: {
                  ...user,
                  premiumUser: false,
               },
            })
         );
      }
   } catch (error) {
      console.log(error);
      // yield put(actions.failedToInviteFriends({
      //   inviteFailed: false,
      //   inviteSentTo: payload.filter((item) => item.Email),
      //   inviteMessage: error,
      // }))
   }
}

function* inviteFriends(action) {
   const { payload } = action;
   try {
      if (payload) {
         const emailArr = payload.map(item => item.Email);
         const response = yield call(ProfileApi.inviteFriends, payload);
         if (response && response.message === "Invite sent.") {
            yield put(
               actions.successfullySentInvite({
                  inviteSuccess: true,
                  inviteSentTo: emailArr,
                  inviteReferralCode: response.referral_code,
                  inviteMessage: response.message,
               })
            );
         } else {
            yield put(
               actions.failedToInviteFriends({
                  inviteFailed: false,
                  inviteSentTo: emailArr,
                  inviteMessage: response.message,
               })
            );
         }
      }
   } catch (error) {
      console.log(error);
      yield put(
         actions.failedToInviteFriends({
            inviteFailed: false,
            inviteSentTo: payload.filter(item => item.Email),
            inviteMessage: error,
         })
      );
   }
}

function* referal(action) {
   const { payload } = action;
   try {
      const response = yield call(ProfileApi.referral, qs.stringify(payload));
      if (response && response.message !== "Failed") {
         const { referral_code, users_list } = response;
         yield put(
            actions.setReferralCode({
               referralCode: referral_code || "",
               usersList: users_list || [],
            })
         );
      } else if (response.message && response.message === "Failed") {
         yield put(
            actions.setReferralCode({
               referralStatusFailedMessage:
                  "Failed to fetch details. Please try again later.",
            })
         );
      }
   } catch (error) {
      yield put(actions.failedFetchProfile(error.response));
   }
}

function* profile(action) {
   const { payload, callback } = action;
   let newResponse;
   try {
      // let history = {}
      // const is_root = /^https?\:\/\/[^\/]+\/?$/.test(window.location.href)
      //
      // let history = {}
      // let apex = {}
      let profile = {};

      // NOTE: Removed apex api call
      // if (is_root) {
      profile = yield call(
         ProfileApi.fetchProfile,
         qs.stringify(payload),
         true
      );
      profile["data"] = { ...profile["data"] };
      // } else {
      //   history = yield call(ProfileApi.fetchProfileTradeHistory, {}, 1)
      //   apex = yield call(ProfileApi.fetchProfileApexDetails, qs.stringify(payload))
      //   profile = yield call(ProfileApi.fetchProfile, qs.stringify(payload), false)
      //   if(Object.keys(apex["data"]).length){
      //     apex["data"] = {
      //       ...apex["data"],
      //       orders: history.data.apex.orders,
      //       orders_total_count: history.data.count
      //     }
      //   }
      //   profile["data"] = { ...profile["data"], apex: apex["data"] }
      //
      // }

      const response = profile;

      // Set advisor data to localstorage and reducer
      const {
         client_exist,
         user_advisor_menu,
         restricted_universe,
         country: userCountry = "",
      } = response.data;
      let advisorData = {
         client_exist,
         user_advisor_menu,
         restricted_universe,
      };

      // const loadingState = yield select(state => state.loading);
      // const fetchingUploadPortfolioList = createLoadingSelector(['GET_PORTFOLIO_DETAILS_FOR_SFM'])({ loadingState });
      // if ((fetchingUploadPortfolioList === 'false' || fetchingUploadPortfolioList === false) && response && response.data && get(response.data , "apex.account[0].account_no")) {
      //   yield put(sfmAction.getPortfolioDetailsForSFMRequest({ accountNumber: get(response.data , "apex.account[0].account_no") }));
      // }

      // const myportfolio = yield select(state => state.sfm.myportfolio);
      // // if(!myportfolio && get(response.data , "apex.account[0].account_no")){ // commented coz myPortfolio was old data
      // if(get(response.data , "apex.account[0].account_no")){
      //   yield put(sfmAction.getUserPortfolio(`MAGNIFI_${get(response.data , "apex.account[0].account_no")}`)); // non-blocking api call
      // }

      window.localStorage.setItem("client_exist", client_exist);
      window.localStorage.setItem("user_advisor_menu", user_advisor_menu);
      window.localStorage.setItem("restricted_universe", restricted_universe);

      yield put(authAction.successfulSetAdvisorData(advisorData));

      const responseDetails = Object.assign(
         {},
         DEFAULT_PROFILE_VALUE,
         response.data
      );

      if (responseDetails) {
         newResponse = JSON.parse(JSON.stringify(responseDetails), (k, v) => {
            if (k === "date_of_birth" && v) {
               return new Date(v);
            }
            return v === null ? "" : v;
         });
      }

      // If the user is new and no subscription is sent in the response
      // We'll add the subscription key with null values to maintain the initial state in the reducer
      if (
         !newResponse.hasOwnProperty("subscription") ||
         !newResponse.subscription.subscription_id
      ) {
         newResponse.subscription = {
            subscription_id: "",
            subscriber_since: "",
            renews_on: "",
            cancels_on: "",
            cancellation_request_status: false,
         };
      }

      if (newResponse && newResponse.profile) {
         const { share_class } = newResponse.profile;
         if (share_class && typeof share_class !== "undefined") {
            yield put(setPortfolioState({ shareClass: share_class }));
         } else {
            console.log("======= SHARE CLASS NOT PRESENT =======");
         }
      }

      let logo = "/assets/images/MagnifiLogo/Magnifi_byTIFIN-darkgrey.svg";
      if (response && response.data && response.data.restrict_sponsor) {
         if (response.data.restrict_sponsor === "blackrock") {
            logo = "/assets/images/MagnifiLogo/BlackRock-PoweredbyMagnifi.png";
         } else if (response.data.restrict_sponsor === "invesco") {
            logo = "/assets/images/MagnifiLogo/Invesco-PoweredbyMagnifi.png";
         } else if (response.data.restrict_sponsor === "fidelity") {
            logo = "/assets/images/MagnifiLogo/Fidelity-PoweredbyMagnifi.png";
         }
      }

      yield put(actions.setProfileState({ logo }));

      yield put(actions.successfulFetchProfile(newResponse));

      // SET DROPDOWN IF UNSET BEFORE LOGIN
      const toggledRegionDropdown = window.sessionStorage.getItem(
         "toggledRegionDropdown"
      );
      if (!toggledRegionDropdown) {
         yield put(setProfileGlobalMarketRegion({ countryName: userCountry }));
      }

      // // if response.data.show_similar_user_queries: true, then => call to get List of Similar Searches for search-results page
      // if (response && response.data && response.data.show_similar_user_queries) {
      //   yield put(getSimilarSearchListRequest());
      // }

      if (callback && typeof callback === "function") {
         callback(response.data);
      }
   } catch (error) {
      yield put(actions.failedFetchProfile(error.response));
   }
}

function* getProfileOrders(action) {
   const { payload: pageNumber } = action;
   try {
      const profile = yield select(state => state.profile.profile);
      const history = yield call(
         ProfileApi.fetchProfileTradeHistory,
         {},
         pageNumber
      );

      let formattedOrders = history.data.apex.orders.map(el => {
         Object.keys(el).forEach(key => {
            el[key] = el[key] === null ? "" : el[key];
         });
         return el;
      });

      profile["apex"] = { ...profile["apex"], orders: formattedOrders };

      yield put(actions.successfulFetchProfile(profile));
   } catch (err) {
      console.log("getProfileOrders fail", err);
   } finally {
      yield put(actions.getProfileOrdersSuccess());
   }
}

function* updateProfile(action) {
   const { payload } = action;
   try {
      payload.date_of_birth =
         payload.date_of_birth &&
         dateFns.format(payload.date_of_birth, "YYYY-MM-DD");
      delete payload.custom_report_logo;
      const response = yield call(
         ProfileApi.updateProfile,
         qs.stringify(payload)
      );

      if (response) {
         if (response && response.data) {
            const { share_class } = response.data;
            if (share_class && typeof share_class !== "undefined") {
               yield put(setPortfolioState({ shareClass: share_class }));
            } else {
               console.log("======= SHARE CLASS NOT PRESENT =======");
            }
         }
         yield put(
            actions.successfulUpdateProfile({
               ...response.data,
               ...payload,
               default_portfolio_value: payload.default_portfolio_value,
               advisory_fees: payload.advisory_fees,
            })
         );
      }
   } catch (error) {
      yield put(actions.failedUpdateProfile(error));
   }
}

function* changePassword(action) {
   const { payload } = action;
   try {
      // console.log(payload);
      if (payload && typeof payload.password !== "undefined") {
         yield put(actions.profileChangePasswordFetch());
         const response = yield call(AuthApi.changePassword, payload);
         console.log("update password response", response);
         if (response && response.data && response.status === 200) {
            yield put(
               actions.profileChangePasswordSuccess({
                  // changePasswordSuccess: true, // old-logic
                  // changePasswordFailed: false, // old-logic
               })
            );
            AuthUtils.updateToken(response.data.token);
            // NOTE: Toast a message on UI
            openNotificationWithIcon({
               duration: 5,
               type: "success",
               message: "Success",
               className: "api-response-notification-class",
               description: "Password updated successfully.",
            });
         } else {
            console.log("update password response", response);
            throw new Error("--- Api failed to respond ---");
         }
      } else {
         throw new Error(
            "---- Payload is missing for password change request ----"
         );
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      let errorMessage =
         "Something went wrong, unable to update your password.";
      if (errorDetails.error) {
         errorMessage = errorDetails.error;
      }
      // NOTE: Toast a message on UI
      openNotificationWithIcon({
         duration: null,
         type: "error",
         message: "Failed",
         className: "api-response-notification-class",
         description: errorMessage,
      });

      yield put(
         actions.profileChangePasswordSuccess({
            error: "Unable to update the your password.",
            // changePasswordSuccess: false, // old-logic
            // changePasswordFailed: true, // old-logic
         })
      );
   }
}

function* createSubscriptionRequest(action) {
   try {
      // Send CallbackUrl to server
      let successUrl = new URL(window.location.href);
      let success_params = new URLSearchParams(successUrl.search);
      success_params.append("paymentStatus", 1);
      successUrl.search = success_params.toString();

      let cancelUrl = new URL(window.location.href);
      let cancel_params = new URLSearchParams(cancelUrl.search);
      cancel_params.append("paymentStatus", 0);
      cancelUrl.search = cancel_params.toString();

      let pathname = window.location.pathname.split("/")[1];

      // NOTE: Get query from discovery reducer's state
      // --------------------------------------------------------------------------------------------
      const discoveryQuery = yield select(state => state.discovery.query) || "";
      // --------------------------------------------------------------------------------------------

      // const payloadRequest = {
      //   success_url: (pathname !== 'register' && pathname !== 'register-profile' && pathname !== 'join') ? successUrl.href : window.location.origin + '/securities?paymentStatus=1',
      //   cancel_url: (pathname !== 'register' && pathname !== 'register-profile' && pathname !== 'join') ? cancelUrl.href : window.location.origin + '/securities?paymentStatus=0',
      //   plan_name: (action.payload && action.payload.plan_name) || 'Premium Lite',
      // }

      const payloadRequest = {
         success_url:
            pathname !== "register" &&
            pathname !== "register-profile" &&
            pathname !== "join"
               ? successUrl.href
               : window.location.origin + searchUrlV3(discoveryQuery, 1),
         cancel_url:
            pathname !== "register" &&
            pathname !== "register-profile" &&
            pathname !== "join"
               ? cancelUrl.href
               : window.location.origin + searchUrlV3(discoveryQuery, 0),
         plan_name:
            (action.payload && action.payload.plan_name) || "Premium Lite",
      };

      if (
         action.payload &&
         action.payload.planType &&
         action.payload.plan_name === "Premium Lite" &&
         action.payload.planType !== "" &&
         action.payload.planType === "enhancer"
      ) {
         payloadRequest.feature_allowed = [
            action.payload.planType,
            "sma_switch",
            "cef_switch",
            "switches",
            "mp_switch",
            "download_excel",
         ];
      }
      if (
         action.payload &&
         action.payload.plan_name &&
         action.payload.plan_name !== ""
      ) {
         payloadRequest.plan_name = action.payload.plan_name || "Premium Lite";
      }

      // console.log(payloadRequest);

      const response = yield call(
         ProfileApi.createSubscriptionRequest,
         payloadRequest
      );
      if (response && response.status === 200) {
         yield put(actions.successfulSubscriptionRequest(response.data));
         if (action.callback) {
            console.log(response.data.id);
            action.callback(response.data.id);
         }
      }
   } catch (error) {
      yield put(actions.failedSubscriptionRequest(error));
   }
}

function* unsubscribeUserRequest(action) {
   const { payload } = action;
   try {
      const response = yield call(
         ProfileApi.unsubscribeUserRequest,
         qs.stringify(payload)
      );
      if (
         response.data.status === "canceled" ||
         response.data.cancel_at_period_end
      ) {
         yield put(actions.successfulUnsubscribeUser(response.data));
         yield put(actions.subscriptionPlan());
         // Action to allow the user to reactivate again upon successful unsubscription
         yield put(actions.failedReactivateSubcription());
      } else {
         yield put(actions.failedUnsubscribeUser(response.data));
      }
   } catch (error) {
      yield put(actions.failedSubscriptionRequest(error));
   }
}

function* reactivateSubcriptionRequest(action) {
   const { payload } = action;
   try {
      const response = yield call(
         ProfileApi.reactivateSubcriptionRequest,
         qs.stringify(payload)
      );
      if (
         response.data.status === "active" &&
         !response.data.cancel_at_period_end
      ) {
         yield put(actions.successfulReactivateSubcription(response.data));
         yield put(actions.subscriptionPlan());
         // Action to allow the user to unsubscribe again upon successful reactivation
         yield put(actions.failedUnsubscribeUser());
      } else {
         yield put(actions.failedReactivateSubcription(response.data));
      }
   } catch (error) {
      yield put(actions.failedReactivateSubcription(error));
   }
}

// function* checkPremiumUser(action) {
// try {
// const { payload, callback } = action;
// yield put(actions.deleteAllSFMItemTickersFetch());
// yield put(fundActions.removeAllFundsSelectorState());
// yield put(actions.deleteAllSFMItemTickersSuccess());
// if (callback && typeof callback === 'function') {
//   callback();
// }
// } catch (error) {
// console.log(error);
// yield put(actions.deleteAllSFMItemTickersSuccess({
//   pageCrash: '-- Failed to process --',
// }));
// }
// }

function* premiumAccessRequest(action) {
   try {
      // NOTE: check for a feature USER has a premium access or not ?
      // or user has Test_Drive feature available ?
      yield put(actions.premiumFeatureAccessFetch({ access: "" }));
      const { payload, callback } = action; // payload: 'print_report_compare'
      if (payload && payload !== "") {
         const profileState = yield select(state => state.profile);
         const { premiumFeatures } = profileState;
         const authState = yield select(state => state.auth);
         const { premiumUser } = authState.user;

         if (
            callback &&
            typeof callback !== "undefined" &&
            typeof callback === "function"
         ) {
            // if (premiumUser) { // check for a feature USER has a premium access or not ?
            //   // if user is premium check Access for feature,
            //   if (premiumFeatures && premiumFeatures.length > 0 && premiumFeatures.includes(payload)) {
            //      // user allowed to access this feature
            //     yield put(actions.premiumFeatureAccessSuccess({
            //       premiumNoticeModalOpen: false,
            //       activeFeatureName: payload,
            //       access: 'Granted',
            //     }));
            //     callback({
            //       premiumNoticeModalOpen: false,
            //       activeFeatureName: payload,
            //       access: 'Granted',
            //     })
            //   } else {
            //     // user not allowed to access this feature
            //     yield put(actions.premiumFeatureAccessSuccess({
            //       premiumNoticeModalOpen: true,
            //       activeFeatureName: payload,
            //       access: 'Denied',
            //     }));
            //     callback({
            //       premiumNoticeModalOpen: true,
            //       activeFeatureName: payload,
            //       access: 'Denied',
            //     });
            //   }
            // } else {
            //   // Call test-drive api to check user has Test_Drive feature available ?
            //   // testDriveApis.getPremiumFeatureDetails
            //   // testDriveApis.allowTestDrive
            //
            //   const [ testDriveResponse ] = yield all([
            //     call(testDriveApis.getPremiumFeatureDetails, payload),
            //     // call(testDriveApis.allowTestDrive, payload),
            //   ]);
            //
            //   if (testDriveResponse && typeof testDriveResponse.test_drive !== 'undefined' && (testDriveResponse.test_drive === 'true' || testDriveResponse.test_drive === true)) {
            //     // user has test drive option
            //     yield put(actions.premiumFeatureAccessSuccess({
            //       premiumNoticeModalOpen: true,
            //       activeFeatureName: payload,
            //       access: 'Test Drive',
            //     }));
            //     callback({
            //       premiumNoticeModalOpen: true,
            //       activeFeatureName: payload,
            //       access: 'Test Drive',
            //     });
            //   } else {
            //     yield put(actions.premiumFeatureAccessSuccess({
            //       premiumNoticeModalOpen: true,
            //       activeFeatureName: payload,
            //       access: 'Denied',
            //     }));
            //     callback({
            //       premiumNoticeModalOpen: true,
            //       activeFeatureName: payload,
            //       access: 'Denied',
            //     });
            //   }
            // }

            if (authState.loggedIn) {
               if (
                  premiumFeatures &&
                  premiumFeatures.length > 0 &&
                  premiumFeatures.includes(payload) &&
                  premiumUser
               ) {
                  // user allowed to access this feature
                  yield put(
                     actions.premiumFeatureAccessSuccess({
                        premiumNoticeModalOpen: false,
                        activeFeatureName: payload,
                        access: "Granted",
                     })
                  );
                  callback({
                     premiumNoticeModalOpen: false,
                     activeFeatureName: payload,
                     access: "Granted",
                  });
               } else {
                  console.log(
                     `-- Due to limited Plan, User not allowed to Access ${payload} feature --`
                  );
                  // Call test-drive api to check user has Test_Drive feature available ?
                  // testDriveApis.getPremiumFeatureDetails
                  // testDriveApis.allowTestDrive

                  const [testDriveResponse] = yield all([
                     call(testDriveApis.getPremiumFeatureDetails, payload),
                     // call(testDriveApis.allowTestDrive, payload),
                  ]);

                  if (
                     testDriveResponse &&
                     typeof testDriveResponse.test_drive !== "undefined" &&
                     (testDriveResponse.test_drive === "true" ||
                        testDriveResponse.test_drive === true)
                  ) {
                     // user has test drive option
                     yield put(
                        actions.premiumFeatureAccessSuccess({
                           premiumNoticeModalOpen: true,
                           activeFeatureName: payload,
                           access: "Test Drive",
                        })
                     );
                     callback({
                        premiumNoticeModalOpen: true,
                        activeFeatureName: payload,
                        access: "Test Drive",
                     });
                  } else {
                     yield put(
                        actions.premiumFeatureAccessSuccess({
                           premiumNoticeModalOpen: true,
                           activeFeatureName: payload,
                           access: "Denied",
                        })
                     );
                     callback({
                        premiumNoticeModalOpen: true,
                        activeFeatureName: payload,
                        access: "Denied",
                     });
                  }
               }
            } else {
               throw new Error("-- Not Logged In --");
            }
         } else {
            throw new Error("--- Callback function is missing ---");
         }
      } else {
         throw new Error("--- Payload missing for premium access check ---");
      }
   } catch (error) {
      console.log(error);
      yield put(
         actions.premiumFeatureAccessSuccess({
            pageCrash: "-- Failed to process --",
         })
      );
   }
}

function* getDownloadedProposalsList(action) {
   try {
      yield put(actions.proposalsListLoader());
      yield put(actions.getProposalsListFetch());
      const response = yield call(ProfileApi.getDownloadedProposalsList);
      if (response) {
         yield put(
            actions.getProposalsListSuccess({
               proposalsList: response,
            })
         );
      } else {
         yield put(
            actions.getProposalsListSuccess({
               proposalsList: [],
            })
         );
      }
   } catch (error) {
      console.log(error);
      yield put(
         actions.getProposalsListSuccess({
            proposalsList: [],
            pageCrash: "Failed to load proposals.",
         })
      );
   }
}

function* getTotalProposals(action) {
   try {
      const response = yield call(ProfileApi.getTotalProposalsCount);

      if (response) {
         yield put(
            actions.getTotalProposalsSuccess({
               totalProposals: response.result,
            })
         );
      } else {
         yield put(
            actions.getTotalProposalsSuccess({
               totalProposals: 0,
            })
         );
      }
   } catch (error) {
      console.log(error);
      yield put(
         actions.getTotalProposalsSuccess({
            totalProposals: 0,
            pageCrash: "Failed to load proposals count.",
         })
      );
   }
}

function* activateSimilarSearch(action) {
   const { payload, callback } = action;
   // console.log(payload)
   try {
      if (
         payload &&
         typeof payload.show_similar_user_queries !== "undefined" &&
         process.env.REACT_APP_SUB_DOMAIN === "retail"
      ) {
         yield put(actions.activateSimilarSearchFetch());
         let [similarSearchFlagRes] = yield all([
            call(ProfileApi.setSimilarSearchesFlag, payload),
         ]);
         console.log(similarSearchFlagRes);
         if (
            similarSearchFlagRes &&
            similarSearchFlagRes.status === "Activated"
         ) {
            yield put(
               actions.activateSimilarSearchSuccess({
                  show_similar_user_queries: true,
               })
            );
         } else {
            yield put(actions.activateSimilarSearchSuccess());
         }
         if (callback && typeof callback === "function") {
            callback(similarSearchFlagRes);
         }
      } else {
         throw new Error("-- Payload missing --");
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      if (errorDetails) {
         console.log(errorDetails);
         yield put(actions.activateSimilarSearchSuccess());
      } else {
         console.log(error);
         yield put(actions.activateSimilarSearchSuccess());
      }
      if (callback && typeof callback === "function") {
         callback();
      }
   }
}

function* deleteAccountEmail(action) {
   const { payload, callback } = action;
   try {
      yield put(actions.deleteAccountEmailFetch());
      let [data] = yield all([call(ProfileApi.deleteAccountEmail, payload)]);

      yield put(actions.deleteAccountEmailSuccess());
      if (callback && typeof callback === "function") {
         callback(data);
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      if (errorDetails) {
         yield put(actions.deleteAccountEmailSuccess());
      } else {
         yield put(actions.deleteAccountEmailSuccess());
      }
      if (callback && typeof callback === "function") {
         callback();
      }
   }
}

function* deleteAccount(action) {
   const { payload, callback } = action;
   try {
      if (payload && payload.token) {
         yield put(actions.deleteAccountFetch());
         let [data] = yield all([call(ProfileApi.deleteAccount, payload)]);

         yield put(actions.deleteAccountSuccess());
         if (callback && typeof callback === "function") {
            callback({ success: true, data: data });
         }
      } else {
         throw new Error("-- Payload missing --");
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      if (errorDetails) {
         yield put(actions.deleteAccountSuccess());
      } else {
         yield put(actions.deleteAccountSuccess());
      }
      if (callback && typeof callback === "function") {
         callback({ success: false, data: errorDetails });
      }
   }
}

function* tokenExpiryCheck(action) {
   const { payload, callback } = action;
   try {
      if (payload && payload.token) {
         yield put(actions.tokenExpiryFetch());
         let [data] = yield all([call(ProfileApi.tokenExpiryCheck, payload)]);
         yield put(actions.tokenExpirySuccess());
         if (callback && typeof callback === "function") {
            callback({ success: true, data: data });
         }
      } else {
         throw new Error("-- Payload missing --");
      }
   } catch (error) {
      yield put(actions.tokenExpirySuccess());
      const errorDetails = get(error, "response.data", error.stack);
      callback({ success: false, data: errorDetails });
   }
}

function* profileData(action) {
   const { callback } = action;
   try {
      yield put(actions.profileDataFetch());
      const response = yield call(ProfileApi.fetchProfile);
      if (response && response.data) {
         yield put(actions.profileDataSuccess(response.data));
         if (callback && typeof callback === "function") {
            callback(response);
         }
      } else {
         throw new Error("-- profile api failed to respond --");
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         yield put(
            actions.profileDataSuccess({
               pageCrash: errorDetails.data.message,
            })
         );
      } else {
         yield put(
            actions.profileDataSuccess({
               pageCrash: error,
            })
         );
      }
      if (callback) {
         callback();
      }
   }
}

function* saveDisclosures(action) {
   const { payload, callback } = action;
   try {
      yield put(actions.saveDisclosuresOrdersFetch());
      // console.log(payload);
      const response = yield call(
         ProfileApi.updateProfile,
         qs.stringify(payload)
      );
      if (response && response.data) {
         yield put(actions.saveDisclosuresOrdersSuccess(payload));
         yield call(delay, 600);
         openNotificationWithIcon({
            duration: 0,
            type: "success",
            message: "Success",
            className: "proposals-notification-class",
            description: "Disclosures updated.",
         });

         if (callback && typeof callback === "function") {
            callback(response);
         }
      } else {
         throw new Error("-- profile api failed to respond --");
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         yield put(
            actions.saveDisclosuresOrdersSuccess({
               pageCrash: errorDetails.data.message,
            })
         );
      } else {
         yield put(
            actions.saveDisclosuresOrdersSuccess({
               pageCrash: error,
            })
         );
      }
      if (callback) {
         callback();
      }
   }
}

function* activeSubscriptionsCancellation(action) {
   const { payload, callback } = action;
   try {
      yield put(actions.activeSubscriptionsCancellationFetch());
      const response = yield call(
         ProfileApi.requestCancelSubscription,
         JSON.stringify(payload)
      );

      if (response && response.status === 200) {
         yield put(actions.activeSubscriptionsCancellationSuccess());
         yield call(delay, 600);

         openNotificationWithIcon({
            duration: 0,
            type: "success",
            message: "Success",
            className: "proposals-notification-class",
            description:
               "Your cancellation request has been successfully sent.",
         });

         if (callback && typeof callback === "function") {
            callback(response);
         }
      } else {
         throw new Error("-- profile api failed to respond --");
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      if (errorDetails && errorDetails.status === 400) {
         yield put(
            actions.activeSubscriptionsCancellationSuccess({
               pageCrash: errorDetails.data.message,
            })
         );
      } else {
         yield put(
            actions.activeSubscriptionsCancellationSuccess({
               pageCrash: error,
            })
         );
      }
      if (callback) {
         callback();
      }
   }
}

function* uploadProfilePhoto(action) {
   const { payload, callback } = action;
   const profileData = yield select(state => state.profile);

   try {
      if (payload) {
         yield put(actions.profilePhotoUploadFetch(payload));
         const response = yield call(ProfileApi.uploadProfilePicture, payload);

         if (response && typeof response !== "undefined") {
            yield put(
               actions.profilePhotoUploadSuccess({
                  ...payload,
                  isBrokenImage: false,
                  profilePictureData: {
                     ...profileData.profilePictureData,
                     ...response,
                  },
               })
            );
            if (callback && typeof callback === "function") {
               callback(response);
            }
         }
      }
   } catch (error) {
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Error",
         className: "api-response-notification-class",
         description: `Failed to upload Profile Picture`,
      });
      if (callback && typeof callback === "function") {
         callback(error);
      }
      yield put(actions.profilePhotoUploadSuccess(error.response));
   }
}
function* hideUnHideProfilePhoto(action) {
   const { payload, callback } = action;
   const profileData = yield select(state => state.profile);

   try {
      if (payload) {
         yield put(actions.profilePhotoHideUnhideFetch(payload));
         const response = yield call(ProfileApi.profilePictureVisible, payload);
         if (response && typeof response !== "undefined") {
            yield put(
               actions.profilePhotoHideUnhideSuccess({
                  ...payload,
                  profilePictureData: {
                     ...profileData.profilePictureData,
                     ...response,
                  },
               })
            );
            if (callback && typeof callback === "function") {
               callback(response);
            }
         }
      }
   } catch (error) {
      yield put(actions.profilePhotoHideUnhideSuccess(error.response));
      if (callback && typeof callback === "function") {
         callback(error);
      }
   }
}

function* profileSaga() {
   yield all([
      fork(
         takeEvery,
         ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE,
         updateTradAccDisclosure
      ),
      fork(takeEvery, ActionTypes.CANCEL_ACH_TRANSACTION, cancelAchRelation),
      fork(
         takeEvery,
         ActionTypes.GET_SUBSCRIPTION_PLAN_REQUEST,
         subscriptionPlan
      ),
      fork(takeEvery, ActionTypes.INVITE_FRIENDS, inviteFriends),
      fork(takeEvery, ActionTypes.REFERRAL_REQUEST, referal),
      fork(takeEvery, ActionTypes.PROFILE_REQUEST, profile),
      fork(takeEvery, ActionTypes.PROFILE_UPDATE_REQUEST, updateProfile),
      fork(takeEvery, ActionTypes.CHANGE_PASSWORD_REQUEST, changePassword),
      fork(
         takeEvery,
         ActionTypes.CREATE_SUBSCRIPTION_REQUEST,
         createSubscriptionRequest
      ),
      fork(
         takeEvery,
         ActionTypes.UNSUBSCRIBE_USER_REQUEST,
         unsubscribeUserRequest
      ),
      fork(
         takeEvery,
         ActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST,
         reactivateSubcriptionRequest
      ),
      // fork(
      //    takeEvery,
      //    ActionTypes.CHECK_PREMIUM_STATE_REQUEST,
      //    checkPremiumUser
      // ),
      fork(
         takeEvery,
         ActionTypes.PREMIUM_FEATURE_ACCESS_REQUEST,
         premiumAccessRequest
      ),
      fork(
         takeEvery,
         ActionTypes.GET_DWNLD_PORTFOLIO_LIST_REQUEST,
         getDownloadedProposalsList
      ),
      fork(takeEvery, ActionTypes.GET_PROPOSALS_COUNT, getTotalProposals),
      fork(
         takeEvery,
         ActionTypes.ACTIVATE_SIMILAR_SEARCH_REQUEST,
         activateSimilarSearch
      ),
      fork(takeEvery, ActionTypes.DELETE_ACCOUNT_REQUEST, deleteAccount),
      fork(
         takeEvery,
         ActionTypes.EMAIL_DELETE_ACCOUNT_REQUEST,
         deleteAccountEmail
      ),
      fork(takeEvery, ActionTypes.TOKEN_EXPIRY_CHECK_REQUEST, tokenExpiryCheck),
      fork(takeEvery, ActionTypes.PROFILE_DATA_REQUEST, profileData),
      fork(takeEvery, ActionTypes.GET_PROFILE_ORDERS_REQUEST, getProfileOrders),
      fork(takeEvery, ActionTypes.SAVE_DISCLOSURES_REQUEST, saveDisclosures),
      fork(
         takeEvery,
         ActionTypes.ACTIVE_SUBSCRIPTIONS_CANCELLATION_REQUEST,
         activeSubscriptionsCancellation
      ),
      fork(
         takeEvery,
         ActionTypes.PROFILE_PHOTO_UPLOAD_REQUEST,
         uploadProfilePhoto
      ),
      fork(
         takeEvery,
         ActionTypes.PROFILE_PHOTO_HIDE_UN_HIDE_REQUEST,
         hideUnHideProfilePhoto
      ),
   ]);
}

export default profileSaga;
