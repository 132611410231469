import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Nav, Button } from "reactstrap";
import {
   UncontrolledDropdown,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
   // Tooltip,
   // FormGroup,
   // Label,
   // Input
} from "reactstrap";
// import { UserMenuPopover } from './UserMenuPopover'
import * as AuthUtils from "common/auth-utils";
import { addEventToAnalytics } from "Utils";
//import { uploadClientList } from 'actions/clientportfolio'
import { uploadClientListV2 } from "actions/clientportfolioV2";
// import PremiumNoticeModal from 'components/PremiumNoticeModal'
// import { getPremiumFeatureDetails } from 'apis/test-drive'
import { Badge } from "antd";
import { RETAIL_USERS_ROLES } from "../../layouts/WebDashboard/Constant/FormData";

function UserMenuComponent({
   onLogout,
   isLoggedIn,
   // showSinginIcon,
   className,
   // uploadClientList,
   // uploadClientListV2,
   // user_advisor_menu,
   // client_exist,
   // premiumUser,
   alert_count,
   profile = {},
}) {
   const changeRoute = profile => {
      if (profile === "Profile") {
         addEventToAnalytics(
            "User Menu Profile",
            "User Menu Profile",
            "USER_MENU_PROFILE",
            { url: window.location.href },
            true
         );
      } else if (profile === "Connected Broker") {
         addEventToAnalytics(
            "User Menu Brokers",
            "User Menu Brokers",
            "USER_MENU_BROKERS",
            { url: window.location.href },
            true
         );
      } else if (profile === "Sign-out") {
         addEventToAnalytics(
            "User Menu Signout",
            "User Menu Signout",
            "USER_MENU_SIGNOUT",
            { url: window.location.href },
            true
         );
      } else {
         addEventToAnalytics("User Profile", "User Profile", "USER_PROFILE", {
            url: window.location.href,
            profile,
         });
      }
   };

   // const inputFile = useRef(null)
   const getWidth = () =>
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
   let [width, setWidth] = useState(getWidth());
   // const [tooltipOpen, setTooltipOpen] = useState(false)
   // const [accountsRedirect, setAccountsRedirect] = useState(false)
   // const [premiumFeature, setPremiumFeature] = useState('false')
   // const [premiumModal, setPremiumModal] = useState(false)
   // const [notify, setNotify] = useState(false)
   // const [clientOption, setClientOption] = useState('')
   useEffect(() => {
      const resizeListener = () => {
         setWidth(getWidth());
      };
      window.addEventListener("resize", resizeListener);
      return () => {
         window.removeEventListener("resize", resizeListener);
      };
   }, []);
   // const redirectToAccounts = val => setAccountsRedirect(val)

   // const toggle = () => setTooltipOpen(!tooltipOpen)

   // const class_type = window.localStorage.getItem('self_role')
   const {
      firstName: authFirstName = "",
      lastName: authLastName = "",
      email: authEmail = "",
   } = AuthUtils.getAuthToken();
   const {
      profile: {
         first_name: profileFirstName = "",
         last_name: profileLastName = "",
         email: profileEmail = "",
      } = {},
   } = profile;

   // Fallback values since validate okta user api fails intermittently
   const firstName = authFirstName || profileFirstName;
   const lastName = authLastName || profileLastName;
   const email = authEmail || profileEmail;

   let userName = "";
   let shortName = "";
   if (email) {
      userName = `${firstName} ${lastName}`.trim() || email;
      shortName = userName
         .match(/\b(\w)/g)
         .join("")
         .slice(0, 2)
         .toUpperCase();
   }

   // const portalLink = 'https://portal.magnifi.us?at=' + window.localStorage.getItem('token')

   // const readCSV = file => {
   //   var reader = new FileReader()
   //   reader.onload = () => {
   //     const clientList = csvToJSON(reader.result)
   //     //uploadClientList(clientList)
   //     uploadClientListV2(clientList)
   //     redirectToAccounts(true)
   //   }
   //   // start reading the file. When it is done, calls the onload event defined above.
   //   reader.readAsBinaryString(file[0])
   // }

   // const demoUpload = () => {
   //   // Read data from CSV instead of json dump
   //   fetch('/assets/data/demo_client_accounts.csv')
   //     .then(response => response.text())
   //     .then(data => {
   //       const demoClients = csvToJSON(data)
   //       //uploadClientList(demoClients)
   //       uploadClientListV2(demoClients)
   //       redirectToAccounts(true)
   //     })
   //     .catch(error => console.error(error))
   // }

   // const closePremiumModal = () => {
   //   setPremiumModal(false)
   // }

   // const premiumModalToggle = () => {
   //   setPremiumModal(!premiumModal)
   // }

   // const openNotify = () => {
   //   setNotify(true)
   // }

   // const notifyToggle = () => {
   //   setNotify(!notify)
   // }

   // const checkPremiumUserForClientPortfolio = type => {
   //   addEventToAnalytics(
   //     'User Menu Client Portfolio',
   //     'User Menu Client Portfolio',
   //     'USER_MENU_CLIENT_PORTFOLIO',
   //     { url: window.location.href },
   //     true
   //   )
   //   let testDrive = null
   //   setClientOption(type)
   //   if (!premiumUser) {
   //     getPremiumFeatureDetails('client_portfolio').then(res => {
   //       testDrive = res.test_drive
   //       console.log(testDrive)
   //       setPremiumFeature(testDrive)
   //       if (!premiumUser || !testDrive) {
   //         premiumModalToggle()
   //       } else if (type === 'default') {
   //         redirectToAccounts(true)
   //       }
   //     })
   //   } else {
   //     if (type === 'uploadFile') {
   //       document.getElementById('uploadCustomPortfolio').click()
   //     } else if (type === 'demo') {
   //       demoUpload()
   //     } else if (type === 'default') {
   //       redirectToAccounts(true)
   //     } else {
   //       document.getElementById('mock-file').click()
   //     }
   //   }
   // }

   // const modalAction = () => {
   //   if (clientOption === 'uploadFile') {
   //     document.getElementById('uploadCustomPortfolio').click()
   //   } else if (clientOption === 'demo') {
   //     demoUpload()
   //   } else {
   //     document.getElementById('mock-file').click()
   //   }
   // }

   // if (accountsRedirect) {
   //   return <Redirect to="/advisor/account" />
   // }

   // const renderClientPortfolioMenuButton =
   //   user_advisor_menu && !client_exist ? (
   //     <UncontrolledDropdown className="nav-sub-menu user-menu__item dropdown-item">
   //       <DropdownToggle tag="a" className="user-menu__link spaced-icon">
   //         <span>
   //           <i className="far fa-fw fa-list mr-2" />
   //           Add Client Portfolios
   //         </span>
   //         <i class="fas fa-chevron-right chev"></i>
   //       </DropdownToggle>
   //       <DropdownMenu tag="ul" className="sub-menu-dd">
   //         <div className="user-menu__item" tag="li">
   //           <div className="user-menu__link spaced-icon">
   //             <FormGroup className="mb-0">
   //               <Label for="" className="mb-0">
   //                 <span
   //                   className="file-opt"
   //                   onClick={() =>
   //                     checkPremiumUserForClientPortfolio('uploadFile')
   //                   }
   //                 >
   //                   <img src="/assets/images/upload-pf.svg" />
   //                   Upload File
   //                 </span>
   //               </Label>
   //               <Input
   //                 type="file"
   //                 name="file"
   //                 ref={inputFile}
   //                 id="uploadCustomPortfolio"
   //                 onChange={e => readCSV(e.target.files)}
   //                 style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
   //               />
   //             </FormGroup>
   //             <span>
   //               <DropdownItem>
   //                 <img
   //                   src="/assets/images/download-pf.svg"
   //                   onClick={() =>
   //                     checkPremiumUserForClientPortfolio('downloadPf')
   //                   }
   //                   style={{ cursor: 'pointer' }}
   //                 />
   //                 <a
   //                   href="/assets/data/mock_client_accounts.csv"
   //                   id="mock-file"
   //                 />
   //               </DropdownItem>
   //             </span>
   //           </div>
   //         </div>
   //         {/*<div className="user-menu__item" tag="li">
   //           <a href="/assets/data/mock_client_accounts.csv" className="user-menu__link spaced-icon">
   //             <span className="file-opt">
   //               <img src="/assets/images/download-pf.svg" />
   //               Download Demo File
   //             </span>
   //           </a>
   //         </div>*/}
   //         <div className="user-menu__item" tag="li">
   //           <Link
   //             to="#"
   //             className="user-menu__link spaced-icon"
   //             onClick={() => checkPremiumUserForClientPortfolio('demo')}
   //           >
   //             <span className="file-opt">
   //               <img src="/assets/images/UseDemoFile-pf.svg" />
   //               Use Demo File
   //             </span>
   //             {/*<i class="fas fa-chevron-right chev"></i>*/}
   //           </Link>
   //         </div>
   //       </DropdownMenu>
   //     </UncontrolledDropdown>
   //   ) : user_advisor_menu && client_exist ? (
   //     <DropdownItem className="user-menu__item" tag="li">
   //       <div
   //         className="user-menu__link"
   //         onClick={() => checkPremiumUserForClientPortfolio('default')}
   //       >
   //         <i className="far fa-fw fa-list mr-2" />
   //         Client Portfolio
   //       </div>
   //     </DropdownItem>
   //   ) : (
   //     ''
   //   )

   const profile_self_role = window.localStorage.getItem("profile_self_role")
      ? window.localStorage.getItem("profile_self_role")
      : "loggedOut";
   return (
      <div className={className}>
         {/*
        <PremiumNoticeModal
          open={premiumModal}
          premiumUser={premiumUser}
          onClickCancel={() => closePremiumModal()}
          onToggle={() => premiumModalToggle()}
          notify={notify}
          openNotify={() => openNotify()}
          notifyToggle={() => notifyToggle()}
          premiumFeature={premiumFeature}
          modalAction={
            user_advisor_menu && !client_exist
              ? modalAction
              : () => redirectToAccounts(true)
          }
          modalActionType={'client_portfolio'}
          featureName="client_portfolio"
        />
      */}
         <Nav className="ml-auto" navbar>
            {!isLoggedIn ? (
               <div className="navbar-rhs">
                  <Link className="navbar-brand mr-3 h2" to="/login">
                     <Button
                        color="Link"
                        className="btn-sign-in"
                        title="sign-in"
                     >
                        Sign in
                     </Button>
                  </Link>
                  <Link className="navbar-brand mx-auto" to="/register">
                     <Button
                        color="Link"
                        className="btn-register"
                        title="Register"
                        // onClick={() => {
                        //   addEventToAnalytics(
                        //     'Sign In Result Page Button',
                        //     'Sign In Result Page Button',
                        //     'SIGN_IN_RESULT_PAGE_BUTTON',
                        //     { clickBtn: 'Sign In' }
                        //   )
                        // }}
                     >
                        Create an Account
                     </Button>
                  </Link>
               </div>
            ) : (
               <>
                  <UncontrolledDropdown className="user-profile">
                     <DropdownToggle tag="a">
                        <span className="user-menu__name-badge">
                           {width > 856 &&
                           RETAIL_USERS_ROLES.includes(profile_self_role) &&
                           alert_count ? (
                              <Badge
                                 className="notification-count"
                                 count={alert_count}
                              />
                           ) : (
                              ""
                           )}
                           {shortName && shortName.toUpperCase()}
                        </span>
                     </DropdownToggle>
                     <DropdownMenu tag="ul" className=" ">
                        <DropdownItem
                           header
                           tag="li"
                           className="user-menu__item user-menu__item--user-info"
                        >
                           <span className="user-menu__user-name">
                              {userName}
                           </span>
                        </DropdownItem>
                        <DropdownItem
                           className="user-menu__item"
                           tag="li"
                           onClick={() => changeRoute("Profile")}
                        >
                           <Link
                              to="/settings/profile"
                              className="user-menu__link"
                              style={{ textAlign: "left" }}
                           >
                              <i className="far fa-fw fa-user mr-2" />
                              Profile
                           </Link>
                        </DropdownItem>

                        {/* mobile view condition */}
                        {/*width > 856 &&
                  !RETAIL_USERS_ROLES.includes(profile_self_role) && (
                    <DropdownItem
                      className="user-menu__item"
                      tag="li"
                      onClick={() => changeRoute('Connected Broker')}
                    >
                      <Link to="/settings/broker" className="user-menu__link">
                        <i className="far fa-fw fa-cog mr-2" />
                        Brokers
                      </Link>
                    </DropdownItem>
                  )*/}

                        {/*width > 856 && RETAIL_USERS_ROLES.includes(profile_self_role) && (
                  <DropdownItem
                    className="user-menu__item"
                    tag="li"
                    onClick={() => changeRoute('Connected Broker')}
                  >
                    <Link
                      to="/settings/tradingAccount"
                      className="user-menu__link"
                    >
                      <i className="far fa-fw fa-cog mr-2" />
                      Investment Account
                    </Link>
                  </DropdownItem>
                )*/}

                        {/*process.env.REACT_APP_SUB_DOMAIN === 'advisor' && (
                  <DropdownItem
                    className="user-menu__item"
                    tag="li"
                    onClick={() => changeRoute('Connected Broker')}
                  >
                    <Link
                      to="/settings/subscriptionPlan"
                      className="user-menu__link"
                    >
                      <i class="far fa-fw fa-usd-circle mr-2" />
                      Subscription
                    </Link>
                  </DropdownItem>
                )*/}
                        {/*width > 856 && process.env.REACT_APP_SUB_DOMAIN === 'advisor' && (
                  <DropdownItem
                    className="user-menu__item"
                    tag="li"
                    onClick={() => changeRoute('Connected Broker')}
                  >
                    <Link to="/settings/proposals" className="user-menu__link">
                      <img
                        style={{ margin: `0 14px 0 6px` }}
                        width={14}
                        src="/assets/images/DownloadPDFBlack.svg"
                      />
                      Proposals
                    </Link>
                  </DropdownItem>
                )*/}
                        {/*RETAIL_USERS_ROLES.includes(profile_self_role) && width > 856 && (
                  <DropdownItem
                    className="user-menu__item"
                    tag="li"
                    onClick={() => changeRoute('Connected Broker')}
                  >
                    <Link to="/settings/alert" className="user-menu__link">
                      <i
                        className="far fa-fw fa-bell mr-2"
                        style={{ position: 'relative' }}
                      >
                        {alert_count ? (
                          <Badge
                            className="alert-notification"
                            count={alert_count}
                          />
                        ) : (
                          ''
                        )}
                      </i>
                      Alerts
                    </Link>
                  </DropdownItem>
                )*/}
                        <DropdownItem
                           className="user-menu__item"
                           tag="li"
                           onClick={() => changeRoute("Sign-out")}
                        >
                           <Link
                              to="#"
                              className="user-menu__link"
                              style={{ textAlign: "left" }}
                              onClick={onLogout}
                           >
                              <i className="far fa-fw fa-sign-out-alt mr-2" />
                              Sign-out
                           </Link>
                        </DropdownItem>
                     </DropdownMenu>
                  </UncontrolledDropdown>
               </>
            )}
         </Nav>
      </div>
   );
}

const mapStateToProps = ({
   auth: { user_advisor_menu, client_exist },
   profile,
}) => ({
   user_advisor_menu,
   client_exist,
   premiumUser: profile.user && profile.user.premiumUser,
   alert_count: profile.profile.apex && profile.profile.apex.alert_count,
   profile: profile,
});

const mapDispatchToProps = dispatch => ({
   uploadClientListV2: clientList => dispatch(uploadClientListV2(clientList)),
});

export const UserMenu = connect(
   mapStateToProps,
   mapDispatchToProps
)(UserMenuComponent);

UserMenu.propTypes = {
   showSinginIcon: PropTypes.bool,
};

UserMenu.defaultProps = {
   showSinginIcon: true,
};
