import moment from "moment";
import { delay } from "redux-saga";
import {
   all,
   call,
   fork,
   put,
   select,
   takeEvery,
   takeLatest,
} from "redux-saga/effects";
// import qs from 'query-string';
import { lowerCase, uniqBy } from "lodash";
import get from "lodash/get";
// import { push } from 'connected-react-router';

// import * as fundsAPI from 'apis/funds'
import { getBuilderItems } from "../selectors/buildportfolio";
import { getSFMItems } from "../selectors/sfm";
// import { getAuthState } from '../selectors/auth-selector';
import ActionTypes from "../action-types";
import * as actions from "../actions/discovery";
import * as globalActions from "../actions/global";
import * as pluginActions from "../actions/plugins";
// import { setSFMState } from '../actions/sfm'
// import * as sfmActions from '../actions/sfm';
// import * as pluginsAPI from '../apis/plugins';
import { AfsRegionCodeMapper, AFS_COUNTRY_LIST } from "constants/global";
import * as discoveryAPI from "../apis/discovery";
// import { setPopOverOptionState  } from '../actions/profile';
import { getAssetSponsorOptRequest } from "actions/community";
import { DateRange, DefaultSortAttr, Universe, ViewNames } from "DataSet";
import {
   executeDiscoverDataMapping,
   getFundsUpdatedPrice,
   prepareHRSDataForFund,
} from "layouts/utils";
import {
   addEventToAnalytics,
   buildAllFundsData,
   buildComputedSearchFundsData,
   deepMergeObjects,
   getSearchResultForAnalytics,
   updateAllFundsData,
} from "Utils";
// import { dummyDiscoveryResponse } from 'layouts/Seeder';
// import { removeAllFundsSelectorState, setFundsState as setFundsStateAction } from 'actions/funds'
// import config from 'env';

export function* searchQuery(data) {
   const { payload } = data;
   try {
      if (payload.query) {
         let { query, search_type, suggestion_query } = payload;
         // let relatedInsightsResponse = {
         //   relatedInsights: [],
         //   message: '',
         // };
         let currentPage = payload.page;
         let requestPayload = {
            q: query,
            universe: Universe,
            suggestion_query: suggestion_query,
            is_international: true,
         };
         if (payload.oldQuery) {
            requestPayload.did_you_mean = payload.oldQuery;
         }

         // NOTE: When query is selected from dropdown (Investments section), pass query as -> query: `ticker ${query}` in api payload
         // --------------------------------------------------------------------------------------------
         if (
            payload.extra &&
            payload.extra.dropdownSection &&
            payload.extra.dropdownSection === "Investments"
         ) {
            // NOTE: remove suggestions text which comes under -> '( )' from query
            // -----------------------------------------------------------------------------------------
            // let BEFORE_CLIPPING_REQUEST_QUERY = requestPayload.q; // inorder to preserve 'requestPayload.q'
            if (requestPayload && requestPayload.q) {
               // console.log('before removing () -->', requestPayload.q);
               requestPayload.q = requestPayload.q.replace(
                  / *\([^)]*\) */g,
                  ""
               );
               // console.log('after removing () -->', requestPayload.q);
            }

            // Below Rohit's commit, removed because of conflicts
            // let BEFORE_CLIPPING_REQUEST_QUERY = requestPayload.q; // inorder to preserve 'requestPayload.q'
            // if (requestPayload && requestPayload.q) {
            //   // console.log('before removing () -->', requestPayload.q);
            //   const [formattedText] = requestPayload.q.split('(')
            //   requestPayload.q = formattedText;
            // }

            // -----------------------------------------------------------------------------------------
            requestPayload.q = `ticker ${requestPayload.q}`;
         }
         // --------------------------------------------------------------------------------------------

         if (query !== sessionStorage.getItem("old_query")) {
            sessionStorage.setItem("old_query", query);
            // Lock the region post login
            sessionStorage.setItem("toggledRegionDropdown", true);
            currentPage = 1;
         }
         if (payload.page) {
            requestPayload.page = payload.page;
         }
         if (search_type) {
            requestPayload.search_type = search_type;
            sessionStorage.setItem("search_type", search_type);
         } else if (sessionStorage.getItem("search_type")) {
            requestPayload.search_type = sessionStorage.getItem("search_type");
         } else {
            // remove search_type if not present in payload
            sessionStorage.removeItem("search_type");
         }
         let _requestPayloadSort = yield select(
            state => state.discovery.requestPayloadSort
         );
         let _requestPayloadFilters = yield select(
            state => state.discovery.requestPayloadFilters
         );
         if (
            _requestPayloadFilters.filterKeys &&
            _requestPayloadFilters.filterKeys.length > 0
         ) {
            requestPayload.filter_name =
               _requestPayloadFilters.filterKeys.toString();
         }
         if (
            _requestPayloadFilters.filterValues &&
            _requestPayloadFilters.filterValues.length > 0
         ) {
            requestPayload.filter_value =
               _requestPayloadFilters.filterValues.toString();
         }
         if (
            _requestPayloadSort &&
            _requestPayloadSort.name !== "" &&
            _requestPayloadSort.value !== ""
         ) {
            if (_requestPayloadSort.name.includes("_rank")) {
               requestPayload.sort_by = _requestPayloadSort.name;
            } else {
               requestPayload.sort_name = lowerCase(_requestPayloadSort.name);
            }
            requestPayload.sort_value = _requestPayloadSort.value;
         }

         // for changing-region logic
         let _globalMarketState = yield select(
            state => state.global.globalMarket
         );
         if (_globalMarketState) {
            requestPayload.market = _globalMarketState;
         }

         // for UDC logic - starts
         // NOTE: before removing UDC logic, do uncomment api/funds => getFunds() -> universe 'else'
         let _universe = yield select(state => state.discovery.universe);

         // NOTE: 'Showing Stocks Only' message based on universe selection
         // --------------------------------------------------------------------------------------------
         let SHOWING_ALL_STOCKS =
            _universe && _universe.length === 1 && _universe[0] === "share";
         // --------------------------------------------------------------------------------------------

         // if ((window.innerWidth <= 1023) || (process.env.REACT_APP_SUB_DOMAIN === 'advisor')) {
         if (window.innerWidth <= 1023) {
            _universe = _universe.filter(
               item => item !== "share" || item !== "basket"
            );
         }
         // else if (config.universeShare) {
         //   _universe.push('share');
         //   _universe = uniqBy(_universe);
         // }

         // NOTE: Do Not pass 'basket' when only 'Stock' universe is selected
         if (_universe.length === 1 && _universe[0] === "share") {
            _universe = _universe.filter(item => item !== "basket");
         } else {
            // _universe.push('share'); // remove this 'share', when share toggle is enable in UI
            // Not needed for international
            _globalMarketState === "USA" && _universe.push("basket");
         }

         _universe = uniqBy(_universe);

         if (_universe.includes("udc")) {
            _universe = _universe.filter(item => item !== "udc");
            requestPayload.universe = uniqBy(_universe).join();
            requestPayload.using_default_criteria = true;
         } else {
            requestPayload.universe = uniqBy(_universe).join();
            requestPayload.using_default_criteria = false;
         }
         // for UDC logic - ends
         let _profileState = yield select(state => state.profile.profile);
         let restricted_universe =
            window.localStorage.getItem("restricted_universe") === "true" ||
            (_profileState && _profileState.restricted_universe) ||
            false;
         if (restricted_universe) {
            requestPayload.skip_cache = true;
         }

         yield put(
            pluginActions.clearRelatedInsightData({
               relatedInsights: "",
            })
         );

         yield put(
            globalActions.updateFundsApiProgress({
               fundsApiCallPercent: 25,
            })
         );

         // NOTE: !IMPORTANT STEP
         // --------------------------------------------------------------------------------------------
         // NOTE: when new query is searched, then try to reset all or selective state to initial state.
         let CURRENT_QUERY = yield select(state => state.discovery.query);
         let STATE_NEEDS_TO_RESET = {};
         if (CURRENT_QUERY !== query) {
            STATE_NEEDS_TO_RESET = {
               requestPayloadFilters: {
                  filterKeys: [],
                  filterValues: [],
               },
               requestPayloadSort: {
                  name: "",
                  value: "",
               },
               // // NOTE: resetting stocks preserve query/universe logic when new query searched
               // previousSearch: {
               //   universe: null,
               // },
               // showGoBackSection: false,
               // isMoreStocksClicked: false,
               // showGoBackSectionClicked: false,
            };
         }
         // --------------------------------------------------------------------------------------------
         yield put(
            actions.searchQueryFetch({
               ...STATE_NEEDS_TO_RESET,
               query,
               suggestion_query: suggestion_query,
               currentPage: currentPage || 1,
               sort_by: requestPayload.sort_by,
               sortAttr: DefaultSortAttr,
            })
         );

         // NOTE: Save 'query' in session storage
         // --------------------------------------------------------------------------------------------
         window.sessionStorage.setItem("query", query);
         // --------------------------------------------------------------------------------------------

         let response;
         try {
            response = yield call(discoveryAPI.discoverSearch, requestPayload);
            // const response = yield call(discoveryAPI.getComputedSearchResults, requestPayload);
            const authReducerState = yield select(state => state.auth);
            if (
               authReducerState &&
               authReducerState.loggedIn &&
               process.env.REACT_APP_SUB_DOMAIN === "retail"
            ) {
               // const profileReducerState = yield select(state => state.profile);
               // const sfmReducerState = yield select(state => state.sfm);
               // if response.data.show_similar_user_queries: true, then => call to get List of Similar Searches for search-results page
               // if (profileReducerState && profileReducerState.profile && profileReducerState.profile.show_similar_user_queries) {
               yield put(actions.getSimilarSearchListRequest(query));
               // }
            }
            if (response) {
               if (response.query_uuid) {
                  window.sessionStorage.setItem(
                     "query_uuid",
                     response.query_uuid
                  );
               }
               if (response.filter_options) {
                  yield put(
                     actions.setFundsState({
                        filterAttrData: response.filter_options,
                     })
                  );
               }

               const reducerSfmItems = yield select(getSFMItems);
               const reducerBuilderItems = yield select(getBuilderItems);
               // Start date issue
               const bakedData = yield executeDiscoverDataMapping(
                  response,
                  query,
                  reducerSfmItems,
                  reducerBuilderItems
               );

               const flexedData = bakedData; // dummyDiscoveryResponse; // yield executeDiscoverDataMapping(response, query);
               if (flexedData) {
                  const cardsCount = flexedData.funds.filter(
                     e => !e._hidden
                  ).length;
                  let _all_funds = flexedData.funds || [];
                  let stockFunds = flexedData.stockFunds || [];
                  let sponsoredFunds =
                     flexedData.funds.filter(kl => kl.is_sponsored) || [];
                  let filteredStockFunds = response.funds.filter(
                     kl => kl.type === "SHARE"
                  );
                  console.log("sponsoredFunds --> ", sponsoredFunds);
                  console.log("filteredStockFunds --> ", filteredStockFunds);

                  /* Check sponsor opt for Ticker before login */
                  const checkSponsorOptTicker =
                     sessionStorage.getItem("sponsorOptCheck");
                  if (
                     sponsoredFunds &&
                     sponsoredFunds.length > 0 &&
                     checkSponsorOptTicker
                  ) {
                     yield put(
                        getAssetSponsorOptRequest({
                           tickers: [{ ticker: checkSponsorOptTicker }],
                           source: "discoverySearch",
                        })
                     );
                     sessionStorage.removeItem("sponsorOptCheck");
                  }
                  /*-----------------------------------------------*/

                  if (
                     filteredStockFunds.length > 0 &&
                     (response.total_funds === 1 ||
                        response.total_funds === 0) &&
                     response.total_stock_funds > 1 &&
                     response.total_stock_funds > response.total_funds
                  ) {
                     stockFunds = filteredStockFunds;
                     _all_funds = [...sponsoredFunds, ...stockFunds];
                     _all_funds = uniqBy(_all_funds, "ticker");
                     SHOWING_ALL_STOCKS = true;
                     flexedData.resultCount = response.total_stock_funds;
                  } else {
                     if (
                        flexedData.stockFunds &&
                        flexedData.stockFunds.length > 0
                     ) {
                        if (requestPayload.universe === "share") {
                           // NOTE: when 'Stocks' universe is selected.
                           _all_funds = flexedData.stockFunds;
                        } else if (requestPayload.universe.includes("share")) {
                           // NOTE: when universe includes 'Stocks' in it.
                           let filteredFunds = flexedData.funds.filter(
                              kl => !kl.is_sponsored
                           );
                           _all_funds = [
                              ...sponsoredFunds,
                              flexedData.stockFunds[0],
                              ...filteredFunds,
                           ];
                           _all_funds = uniqBy(_all_funds, "ticker");
                        }
                     }
                  }
                  console.log("stockFunds ->", stockFunds);
                  // // NOTE: 'Showing Stocks Only' message based on stocks funds only
                  // // --------------------------------------------------------------------------------------------
                  // SHOWING_ALL_STOCKS = (_all_funds.length === _all_funds.filter((kl) => kl.type === 'SHARE').length);
                  // // --------------------------------------------------------------------------------------------
                  yield put(
                     actions.searchQuerySuccess({
                        stockFunds,
                        firstPop: false,
                        loading: false,
                        actions: false,
                        sortDesc: false,
                        SHOWING_ALL_STOCKS,
                        cardsCount: cardsCount,
                        fundsResponse: response,
                        logId: flexedData.log_id || "",
                        data: _all_funds || [],
                        // sponsoredData: sponsoredFunds, // for mobile view using this 'stocks_data' - old logic
                        // stocks_data: _all_funds.filter((item) => item.type === 'SHARE') || [], // for mobile view using this 'stocks_data' - old logic
                        ticks: flexedData.ticks || "",
                        catData: flexedData.categories || [],
                        related: flexedData.related || [],
                        didYouMean: flexedData.didYouMean || [],
                        dateRangeSelected: flexedData.dateRangeSelected || {},
                        correctedQuery: flexedData.corrected_query || "",
                        messages: flexedData.messages || [],
                        chartData: flexedData.chartData || "",
                        outPerfReturnsData: flexedData.outPerfReturnsData,
                        view: flexedData.relevantViewNames || ViewNames[0].name,
                        itemCardView: flexedData.itemCardView || "",
                        highlightText: flexedData.highlightText || {
                           feesHighLightEnable: false,
                           returnHighLightEnable: false,
                           riskHighLightEnable: false,
                        },
                        query_var: flexedData.query_var,
                        selectedCards: reducerSfmItems,
                        queryUUID: flexedData.query_uuid,
                        resultCount: flexedData.resultCount,
                        filterAttrData: flexedData.filterAttrData,
                        commonActiveFundsReturnsRange:
                           flexedData.commonActiveFundsReturnsRange,
                        dateRange: flexedData.range || {
                           start: moment(DateRange.start),
                           end: moment(DateRange.end),
                        },
                     })
                  );
                  // SEND ANALYTICS EVENTS start
                  const utmUsageLogId =
                     window.sessionStorage.getItem("utm_usage_log_id");

                  addEventToAnalytics(
                     "Search Api",
                     "Search Api",
                     "SEARCH_API",
                     {
                        query: query,
                        logId: false,
                        usage_log_id:
                           window.sessionStorage.getItem("utm_usage_log_id"),
                        region: _globalMarketState || "USA",
                        topSearchResults:
                           getSearchResultForAnalytics(_all_funds),
                        ...(utmUsageLogId
                           ? { usage_log_id: utmUsageLogId }
                           : {}),
                     },
                     true
                  );

                  if (response.data && response.data.length === 0) {
                     addEventToAnalytics(
                        "Search Api No result",
                        "Search Api No result",
                        "SEARCH_API_NO_RESULT",
                        {
                           query: query,
                           logId: false,
                           response: response,
                           region: _globalMarketState || "USA",
                        },
                        true
                     );
                  }
                  // SEND ANALYTICS EVENTS end

                  yield put(
                     globalActions.updateFundsApiProgress({
                        fundsApiCallPercent: 100,
                     })
                  );

                  if (
                     flexedData.sponsors_tickers &&
                     flexedData.sponsors_tickers.length > 0
                  ) {
                     yield put(
                        actions.setSponsoredValue({
                           sponsorsTickers: flexedData.sponsors_tickers,
                        })
                     );
                  } else {
                     yield put(
                        actions.setSponsoredValue({
                           sponsorsTickers: [],
                        })
                     );
                  }

                  // NOTE: after loading search-results data, initiate related-insight-api call
                  // ------------------------------------------------------
                  yield call(delay, 600);
                  // ------------------------------------------------------

                  yield put(
                     pluginActions.relatedInsightRequest({
                        chartData: flexedData.chartData,
                        searchResultsData: _all_funds || [],
                        sponsor_tickers: flexedData.sponsors_tickers,
                     })
                  );

                  // if (flexedData.relatedInsightsResponse && flexedData.relatedInsightsResponse.relatedInsights && flexedData.relatedInsightsResponse.relatedInsights.length > 0) {
                  //   const { relatedInsights } = flexedData.relatedInsightsResponse;
                  //   yield put(pluginActions.relatedInsightSuccess({
                  //     relatedInsights,
                  //   }));
                  // } else {
                  //   yield put(pluginActions.relatedInsightSuccess({
                  //     relatedInsights:[],
                  //   }));
                  // }

                  if (flexedData.ticks && flexedData.ticks.length > 0) {
                     let selectorAttrsData = yield call(
                        discoveryAPI.getSelectorAttrsData,
                        { tickers: flexedData.ticks, is_international: true }
                     );
                     // if (flexedData.funds && flexedData.funds.length > 0) {
                     //   flexedData.funds.forEach((e, i) => {
                     //     if (selectorAttrsData.find(item => item[e.ticker])) {
                     //       const currentSelectorData = selectorAttrsData.find(item => item[e.ticker]);
                     //       e.selectorAttrsData = currentSelectorData && currentSelectorData[e.ticker] && currentSelectorData[e.ticker].length ? currentSelectorData[e.ticker][0] : {};
                     //     }
                     //   });
                     // yield put(actions.getSelectorDataSuccess({
                     //   data: flexedData.funds,
                     // }))
                     // }
                     if (_all_funds && _all_funds.length > 0) {
                        _all_funds.forEach((e, i) => {
                           if (selectorAttrsData.find(item => item[e.ticker])) {
                              const currentSelectorData =
                                 selectorAttrsData.find(item => item[e.ticker]);
                              e.selectorAttrsData =
                                 currentSelectorData &&
                                 currentSelectorData[e.ticker] &&
                                 currentSelectorData[e.ticker].length
                                    ? currentSelectorData[e.ticker][0]
                                    : {};
                           }
                        });
                     }
                     // yield put(actions.getSelectorDataSuccess({
                     //   data: _all_funds,
                     // }));
                  }
               } else {
                  yield put(
                     actions.getSelectorDataSuccess({
                        data: [],
                     })
                  );
               }
            } else {
               throw new Error("-- Failed to response, check api --");
            }
            // yield put(actions.setFundsState({
            //   pageLoader: false,
            // }))
         } catch (error) {
            console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
            addEventToAnalytics(
               "Search Api No result",
               "Search Api No result",
               "SEARCH_API_NO_RESULT",
               { query: payload.query, logId: false, response: error.message }
            );
            const errorDetails = get(error, "response.data", error.stack);
            console.log(errorDetails);
            yield put(
               actions.searchQuerySuccess({
                  data: [],
                  pageCrash: errorDetails,
               })
            );
         }
      }
   } catch (error) {
      console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
      addEventToAnalytics(
         "Search Api No result",
         "Search Api No result",
         "SEARCH_API_NO_RESULT",
         { query: payload.query, logId: false, response: error.message }
      );
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.searchQuerySuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
      yield put(
         pluginActions.relatedInsightSuccess({
            relatedInsights: [],
         })
      );
      yield put(
         actions.getSelectorDataSuccess({
            data: [],
         })
      );
   }
}

export function* fetchFundsPrice(data) {
   const { payload } = data;
   try {
      if (payload.ticks && payload.data) {
         const { data, ticks } = payload;
         const response = yield call(discoveryAPI.fetchRealTimePrices, {
            tickers: ticks,
         });
         if (response) {
            const updatedData = yield getFundsUpdatedPrice(response, data);
            if (updatedData) {
               yield put(
                  actions.updateFundsData({
                     data: updatedData.funds,
                     updated_at: updatedData.updated_at,
                  })
               );
            }
         }
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      // yield put(actions.failedToPerform(errorDetails))
   }
}

export function* dymLogs({ payload }) {
   try {
      console.log(payload);
      if (
         payload.originalQuery &&
         payload.queryUUID &&
         payload.dymQueryClicked
      ) {
         const { originalQuery, dymQueryClicked, queryUUID } = payload;
         const requestPayload = {
            original_query: originalQuery,
            query_uuid: queryUUID,
            dym_query_clicked: dymQueryClicked,
         };
         // console.log(requestPayload);

         const response = yield call(discoveryAPI.getDymLogs, requestPayload);
         // console.log(response);
         if (response) {
            // yield put(
            //   actions.updateFundsData({
            //     data: updatedData.funds,
            //     updated_at: updatedData.updated_at,
            //   })
            // );
         }
      } else {
         throw new Error("missing DYM Logs payload");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      // yield put(actions.failedToPerform(errorDetails))
   }
}

export function* searchAnalyticsAPI(data) {
   const { payload } = data;
   try {
      if (payload) {
         yield call(discoveryAPI.searchAnalyticsAPI, payload);
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      // yield put(actions.failedToPerform(errorDetails))
   }
}

function* getTrendingQueries() {
   try {
      yield put(actions.fetchTrendingFeeds());
      const response = yield call(discoveryAPI.getTrendingQueries);
      if (response) {
         yield put(
            actions.trendingFeedsResponse({
               trendingQueries: response,
            })
         );
      } else {
         console.log("Trending Feeds failed to load response");
         yield put(
            actions.trendingFeedsResponse({
               trendingQueries: [],
            })
         );
      }
   } catch (error) {
      console.log("Trending Feeds failed to load response");
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      // yield put(actions.failedToPerform(errorDetails))
   }
}

function* getChartDataForAllFunds(action) {
   try {
      const currentChartReportData = yield select(
         state => state.discovery.chartReportData
      );
      const crdQueryArray = currentChartReportData.allFunds.correctedQuery;
      const currentCorrectedQuery = yield select(
         state => state.discovery.correctedQuery
      );
      // const crdUniverse = currentChartReportData.initialAllFunds.universe || "";
      // const currentUniverse = yield select(
      //    state => state.discovery.fundsResponse.universe
      // );
      // const hasUniverseChanged = crdUniverse !== currentUniverse;
      // if(!crdQueryArray || !crdQueryArray.includes(currentCorrectedQuery) || hasUniverseChanged){
      if (!crdQueryArray || !crdQueryArray.includes(currentCorrectedQuery)) {
         const feeRiskReturnParams = yield select(state => ({
            tickers: state.discovery.data.map(item => item.ticker),
            date_range:
               state.discovery.dateRange &&
               state.discovery.dateRange.start &&
               state.discovery.dateRange.end
                  ? {
                       start: moment(state.discovery.dateRange.start).format(
                          "MMM YYYY"
                       ),
                       end: moment(state.discovery.dateRange.end).format(
                          "MMM YYYY"
                       ),
                    }
                  : state.discovery.dateRangeSelected,
         }));
         // console.log(feeRiskReturnParams);
         const getFeeRiskReturn = yield call(
            discoveryAPI.fetchfeeRiskReturn,
            feeRiskReturnParams
         );
         const feeRiskReturn = getFeeRiskReturn ? getFeeRiskReturn : [];
         const allFunds = yield select(state => ({
            ...state.discovery.fundsResponse,
            date_range: state.discovery.fundsResponse.date,
            related: state.discovery.fundsResponse.related_queries,
            funds: buildComputedSearchFundsData(
               state.discovery.data,
               feeRiskReturn
            ),
            correctedQuery: [state.discovery.correctedQuery],
            queryFunds: [
               {
                  query: state.discovery.query,
                  correctedQuery: state.discovery.correctedQuery,
                  funds: state.discovery.data.map(item => item.ticker),
               },
            ],
            Highlights: state.discovery.highlightText,
         }));
         const chartReportData = {
            allFunds,
         };
         const itemCardView = yield select(
            state => state.discovery.itemCardView
         );
         const updatedChartReportData = buildAllFundsData(
            chartReportData,
            itemCardView
         );
         let finalChartReportData = {};
         finalChartReportData.allFunds = deepMergeObjects(
            currentChartReportData.allFunds,
            updatedChartReportData.allFunds
         );
         finalChartReportData.initialAllFunds =
            updatedChartReportData.initialAllFunds;
         finalChartReportData.selectedFunds =
            currentChartReportData.selectedFunds;
         yield put(actions.updateChartReportData(finalChartReportData));
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      // yield put(actions.failedToPerform(errorDetails))
   }
}

function* updateSelFundsInCrd(action) {
   // CRD => Chart Report Data
   try {
      let chartReportData = yield select(
         state => state.discovery.chartReportData
      );
      let updatedChartReportData;
      if (
         typeof action.payload !== "undefined" &&
         chartReportData &&
         chartReportData.allFunds &&
         chartReportData.selectedFunds &&
         chartReportData.allFunds.funds
      ) {
         // Check if the user has deselected a fund and remove it from selectedFunds
         // If it is not in the top10funds then remove it from allFunds
         const currentSelectedFund = chartReportData.selectedFunds.find(
            item => item.ticker === action.payload.ticker
         );
         const isSelectedFundExist = typeof currentSelectedFund !== "undefined";
         let updatedSelectedFund = currentSelectedFund;
         // const topTenFunds = [...chartReportData.allFunds.funds].splice(0, 10);
         const selectedFundInAllFunds = chartReportData.allFunds.funds.find(
            item => item.ticker === action.payload.ticker
         );
         if (isSelectedFundExist) {
            // if(!selectedFundInAllFunds){
            //   chartReportData.allFunds.funds = chartReportData.allFunds.funds.filter(item => item.ticker !== action.payload.ticker);
            // }
            chartReportData.selectedFunds =
               chartReportData.selectedFunds.filter(
                  item => item.ticker !== action.payload.ticker
               );
         } else if (!isSelectedFundExist && !selectedFundInAllFunds) {
            const feeRiskReturnParams = yield select(state => ({
               tickers: [action.payload.ticker],
               date_range: state.discovery.fundsResponse.date,
            }));
            const getFeeRiskReturn = yield call(
               discoveryAPI.fetchfeeRiskReturn,
               feeRiskReturnParams
            );
            const feeRiskReturn = getFeeRiskReturn ? getFeeRiskReturn : [];
            updatedSelectedFund = buildComputedSearchFundsData(
               [action.payload],
               feeRiskReturn
            )[0];
            chartReportData.selectedFunds.push(updatedSelectedFund);
         } else {
            chartReportData.selectedFunds.push(selectedFundInAllFunds);
         }
         const itemCardView = yield select(
            state => state.discovery.itemCardView
         );
         // Rebuild the object only if the selected fund is not in exisiting selectedFunds object
         // And is not in the top 10 funds
         const query_var = yield select(
            state => state.discovery.fundsResponse.query_var
         );
         updatedChartReportData = !selectedFundInAllFunds
            ? updateAllFundsData(
                 chartReportData,
                 itemCardView,
                 isSelectedFundExist,
                 updatedSelectedFund,
                 query_var
              )
            : chartReportData;
      } else {
         updatedChartReportData = {
            ...chartReportData,
            allFunds: chartReportData.initialAllFunds,
            selectedFunds: [],
         };
      }
      yield put(actions.updateChartReportData(updatedChartReportData));
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      console.log(errorDetails);
      // yield put(actions.failedToPerform(errorDetails))
   }
}

export function* downloadCSVFor(data) {
   const { payload, callback } = data;
   try {
      if (payload && payload.query) {
         const { query, universe, requestCount } = payload;
         let requestPayload = {
            q: query,
            universe: universe,
            count: requestCount,
         };

         let _profileState = yield select(state => state.profile.profile);
         let restricted_universe =
            window.localStorage.getItem("restricted_universe") === "true" ||
            (_profileState && _profileState.restricted_universe) ||
            false;
         if (restricted_universe) {
            requestPayload.skip_cache = true;
         }

         yield put(actions.downloadSearchedResultsFetch());
         const response = yield call(
            discoveryAPI.getComputedSearchResults,
            requestPayload
         );
         if (response) {
            let holdings = response["Top Holdings"];
            response.funds.forEach(card => {
               const ticker = card.ticker;
               if (holdings && holdings.funds) {
                  let _holdings = holdings.funds.find(e => e.ticker === ticker);
                  //// NOTE: By default LEVEL 1 holdings mapping `_holdings.holdings` or level 2 ?
                  // _holdings = _holdings.holdings ? _holdings.holdings : _holdings.holdings2;
                  // console.log('=== _holdings ===', _holdings, ticker);
                  if (_holdings) {
                     let mappedData = prepareHRSDataForFund({
                        holdings: _holdings,
                        card,
                     });
                     if (mappedData && mappedData.holdingData) {
                        card.holdingData = mappedData.holdingData;
                     } else {
                        card.holdingData = [];
                     }
                  } else {
                     card.holdingData = [];
                  }
               }
            });

            yield put(
               actions.downloadSearchedResultsSuccess({
                  searchedResultsForCSV: response,
               })
            );
            if (callback && typeof callback === "function") {
               callback(response);
            }
         } else {
            yield put(
               actions.downloadSearchedResultsSuccess({
                  searchedResultsForCSV: [],
                  searchedResultsForCSVError: response,
               })
            );
            if (callback && typeof callback === "function") {
               callback(response);
            }
         }
      }
   } catch (error) {
      console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
      yield put(
         actions.downloadSearchedResultsSuccess({
            searchedResultsForCSV: [],
            searchedResultsForCSVError: error,
         })
      );
   }
}

export function* getTickerTimeFrameData(data) {
   const { payload, callback } = data;
   try {
      if (
         payload &&
         payload.tickers &&
         payload.end_date &&
         payload.start_date
      ) {
         // const { start_date, end_date, tickers } = payload;
         yield put(actions.selectorWithTimeFrameFetch());
         const response = yield call(discoveryAPI.getTimeFrame, payload);
         if (response) {
            // yield put(actions.selectorWithTimeFrameSuccess());
            if (callback && typeof callback === "function") {
               callback(response);
            }
         }
      } else {
         throw new Error("-- Payload missing --");
      }
   } catch (error) {
      console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
      yield put(
         actions.selectorWithTimeFrameSuccess({
            pageCrash: "Failed to process",
         })
      );
   }
}

// export function* setPopoverOption(data) {
//    const { payload } = data;
// console.log(data)
// try {
//    if (payload) {
//       const { returns, risk } = payload;
//       let body = new FormData();
//       if (returns) {
//          body.append("user_return_prefer", returns);
//       }
//       if (risk) {
//          body.append("user_risk_prefer", risk);
//       }
// const response = yield call(discoveryAPI.saveDiscoverPref, body);
// console.log(response)
//       } else {
//          throw new Error("-- Payload missing --");
//       }
//    } catch (error) {
//       console.log("error -------->>>>>>>>>>>>>>>>>>>>>>>>>>", error);
//    }
// }

function* getFundDetailData(action) {
   const { payload, callback } = action;
   console.log(payload);
   try {
      if (payload && payload.basket_ticker) {
         yield put(actions.getFundDataForDrawerFetch());

         const response = yield call(discoveryAPI.getBasketData, payload);
         console.log(response);

         if (response) {
            yield put(
               actions.getFundDataForDrawerSuccess({
                  openFundDetailDrawerFor: {
                     response,
                     payload,
                  },
               })
            );
         } else {
            yield put(
               actions.getFundDataForDrawerSuccess({
                  openFundDetailDrawerFor: {
                     response,
                     payload,
                     error: true,
                  },
               })
            );
         }
         if (callback && typeof callback === "function") {
            callback();
         }
      } else {
         throw new Error("-- Payload missing --");
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      if (errorDetails) {
         console.log(errorDetails);
         yield put(
            actions.getFundDataForDrawerSuccess({
               openFundDetailDrawerFor: {
                  response: errorDetails,
                  payload,
                  error: true,
               },
               pageCrash: errorDetails,
            })
         );
      } else {
         console.log(error);
         yield put(
            actions.getFundDataForDrawerSuccess({
               openFundDetailDrawerFor: {
                  response: error,
                  payload,
                  error: true,
               },
               pageCrash: "-- Failed to process --",
            })
         );
      }
   }
}

// commenting below logic because its related to SFM saga, so executing from there.
// function* executeMagnifiBrokerOrders(payload){
//   console.log(payload)
//   yield put(
//     actions.setFundsState({
//       magnifiExecuteOrderLoader:true
//     })
//   )
//
//   try{
//     yield put(actions.executeMagnifiOrderFetch())
//     const response = yield call(fundsAPI.executeTradeOrder, (payload.payload))
//     console.log(response)
//     yield put (
//       actions.setFundsState({
//         magnifiExecuteOrderLoader:false
//       })
//     )
//     if(response.status==200){
//       let data = {
//         response:response.data,
//         payload:payload.payload,
//         error:false
//       }
//       yield put(
//         removeAllFundsSelectorState()
//       )
//       yield put(
//         setSFMState({
//           items:[],
//           selectedFund:[],
//           selectedItemTickers:[]
//         })
//       )
//       yield put(
//         actions.executeMagnifiOrderSuccess({
//           sfmTradeOrderResponse: data
//         })
//       )
//     }
//   }
//   catch (e) {
//     console.log(e)
//     let data = {
//       response: e.response.data,
//       payload:payload.payload,
//       error:true
//     }
//     yield  put( actions.setFundsState({
//       magnifiExecuteOrderLoader:false
//     }))
//     yield put(
//       actions.executeMagnifiOrderSuccess({
//         sfmTradeOrderResponse: data,
//       })
//     );
//   }
// }

function* getSimilarSearchList(action) {
   const { payload, callback } = action;
   // console.log(payload)
   try {
      // if (payload) {
      yield put(actions.getSimilarSearchListFetch());
      let [response] = yield all([
         call(discoveryAPI.getSimilarSearches, payload),
      ]);
      console.log(response);
      if (
         response &&
         response.users_like_you_searched &&
         response.users_like_you_searched.length
      ) {
         yield put(
            actions.getSimilarSearchListSuccess({
               similarSearches: response.users_like_you_searched,
            })
         );
         if (callback && typeof callback === "function") {
            callback(response.users_like_you_searched);
         }
      } else {
         let tempArr = []; // yield select(state => state.discovery.trendingQueries) || [];
         // tempArr = tempArr.map((kl) => kl.query);
         yield put(
            actions.getSimilarSearchListSuccess({
               similarSearches: tempArr,
            })
         );
         if (callback && typeof callback === "function") {
            callback(tempArr);
         }
      }
      // } else {
      //   throw '-- Payload missing --'
      // }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      if (errorDetails) {
         console.log(errorDetails);
         yield put(
            actions.getSimilarSearchListSuccess({
               similarSearches: [],
               pageCrash: errorDetails,
            })
         );
      } else {
         console.log(error);
         yield put(
            actions.getSimilarSearchListSuccess({
               similarSearches: [],
               pageCrash: "-- Failed to process --",
            })
         );
      }
      if (callback && typeof callback === "function") {
         callback([]);
      }
   }
}

function* getTrendingSearchTheme(action) {
   const { payload } = action;
   try {
      yield put(
         actions.getTrendingSearchThemeFetch({ loadingTrendingTheme: true })
      );
      const response = yield call(
         discoveryAPI.getTrendingSearchThemeData,
         payload
      );
      if (response && typeof response === "object") {
         yield put(
            actions.getTrendingSearchThemeSuccess({
               trendingSearchTheme: response.data || [],
               loadingTrendingTheme: false,
            })
         );
      } else {
         yield put(
            actions.getTrendingSearchThemeSuccess({
               trendingSearchTheme: [],
               loadingTrendingTheme: false,
            })
         );
      }
   } catch (e) {
      console.log("Getting some error in fetching trending search theme data.");
   }
}

function* setUniverseByRegion(action) {
   const { payload } = action;
   if (payload.market)
      yield put(actions.setUniverseByRegion({ region: payload.market }));
   else if (payload.countryName) {
      const foundRegion = AFS_COUNTRY_LIST.find(
         region =>
            region.name.toLowerCase() === payload.countryName.toLowerCase()
      );
      foundRegion &&
         (yield put(
            actions.setUniverseByRegion({
               region: AfsRegionCodeMapper[foundRegion.continentCode] || "USA",
            })
         ));
   }
}

function* fundsSaga() {
   yield all([
      fork(takeLatest, ActionTypes.SEARCH_QUERY_REQUEST, searchQuery),
      fork(takeEvery, ActionTypes.DYM_LOGS, dymLogs),
      fork(takeEvery, ActionTypes.UPDATE_DISCOVERY_DATA, fetchFundsPrice),
      fork(takeEvery, ActionTypes.SEARCH_ANALYTICS_API, searchAnalyticsAPI),
      fork(takeEvery, ActionTypes.TRENDING_QUERIES_REQUEST, getTrendingQueries),
      fork(
         takeEvery,
         ActionTypes.GET_CHART_DATA_FOR_ALL_DISCOVERY_FUNDS,
         getChartDataForAllFunds
      ),
      fork(takeEvery, ActionTypes.UPDATE_SEL_FUNDS_IN_CRD, updateSelFundsInCrd),
      fork(
         takeEvery,
         ActionTypes.DOWNLOAD_SEARCHED_RESULTS_REQUEST,
         downloadCSVFor
      ),
      fork(
         takeEvery,
         ActionTypes.SELECTOR_WITH_TIME_FRAME_REQUEST,
         getTickerTimeFrameData
      ),
      // fork(takeEvery, ActionTypes.EXECUTE_MAGNIFI_BROKER_ORDERS_REQUEST, executeMagnifiBrokerOrders),
      // fork(takeEvery, ActionTypes.SAVE_POPOVER_OPTION, setPopoverOption),
      fork(
         takeEvery,
         ActionTypes.GET_FUND_DATA_FOR_DRAWER_REQUEST,
         getFundDetailData
      ),
      fork(
         takeEvery,
         ActionTypes.GET_SIMILAR_SEARCH_LIST_REQUEST,
         getSimilarSearchList
      ),
      fork(
         takeEvery,
         ActionTypes.GET_TRENDING_SEARCH_THEME_REQUEST,
         getTrendingSearchTheme
      ),
      fork(
         takeEvery,
         [
            ActionTypes.TOGGLE_GLOBAL_MARKET_REGION,
            ActionTypes.SET_PROFILE_GLOBAL_MARKET_REGION,
         ],
         setUniverseByRegion
      ),
   ]);
}

export default fundsSaga;
