const uatConfig = {
   // apiBaseUrl: 'https://uat.magnifi.com/api',
   // tdAmeritradeCallbackUrl:
   //   'https://auth.tdameritrade.com/oauth?client_id=LOCALTD5@AMER.OAUTHAP&response_type=code&redirect_uri=https://test.opensesafi.com/callbacks/tdameritrade/td-auth-code',
   // tradeItOrigin: 'https://www.trade.it',
   // tradingTicketorigin: 'https://ems.tradingticket.com',
   // googleAnalyticsCode: 'AIzaSyC2_hXo2574jMnsCi2FKJZLbT2LSInreac',
   // googleTaManagerCode: 'UA-153849204-1',
   // heapAnalyticsCode: '4262087573',
   // krakenId: '5df2a89236c054a5e077c7e5',
   // stripePublishableKey: 'pk_test_AEQFGPFVy9AERNCjkr8AU5gc00K1DdiLbk'
   apiBaseUrl: "https://uat.magnifi.com/api",
   tdAmeritradeCallbackUrl:
      "https://auth.tdameritrade.com/oauth?client_id=LOCALTD5@AMER.OAUTHAP&response_type=code&redirect_uri=https://test.opensesafi.com/callbacks/tdameritrade/td-auth-code",
   tradeItOrigin: "https://www.trade.it",
   tradingTicketorigin: "https://ems.tradingticket.com",
   googleAnalyticsCode: "AIzaSyC2_hXo2574jMnsCi2FKJZLbT2LSInreac",
   googleTaManagerCode: "UA-153849204-1",
   googleGTAGUAConfig: {},
   universeShare: true,
   heapAnalyticsCode: "4262087573",
   krakenId: "5df2a89236c054a5e077c7e5",
   relatedInsight: true,
   impressionTag: false,
   tdCallbackPath: "/callbacks/tdameritrade",
   sponsoredTicker: true,
   PtarmiganTag: false,
   advisorRetailRedirect: true,
   advisor_redirect: "https://uat-advisor.magnifi.com",
   retail_redirect: "https://uat-retail.magnifi.com",
   stripePublishableKey: "pk_test_bnUyP0ez8RUw3uggjKjjXZDR008XvxROs0",
   version: "2.0.0",
   sentryEnv: "uat",
   appleSSORedirectURL: "https://dev2.magnifi.com/register",
   transferRequest: false,
   googleManagerTag: false,
};

export default uatConfig;
