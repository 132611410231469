import { createAPIInstance } from "./api";

export function getBatchSearch(payload) {
   return createAPIInstance()
      .get("/batch-search", {
         params: payload,
      })
      .then(response => response.data);
}

// export function getBatchSearch(payload) {
//   // console.log(payload);
//   const authToken = window.localStorage.getItem('token');
//   const { id_hash } = payload;
//   const api = axios.create({
//     headers: {
//       'Accept': 'application/json',
//       Authorization: `Token ${authToken}`
//     }
//   })

//   api.interceptors.response.use(
//     response => {
//       return response
//     },
//     error => {
//       if (error && error.response && error.response.status === 401) {
//         return null
//       }
//       return Promise.reject(error)
//     }
//   )

//   // return api.get(`https://apialpha.marketinsightout.com/v1.1/user/search/content/{"search":"${payload.query}"}`)
//   //   .then(response => response.data)
//   return api.get(`https://discover.magnifi.com/api/batch-search`,  {
//     params: payload,
//   })
//   .then(response => response.data)
// }

export function setPortfolioOptsApi(payload) {
   return createAPIInstance()
      .post("/enhance-portfolio", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function setProposalPortfolioApi(payload) {
   return createAPIInstance()
      .post("/enhance-portfolio-save", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function portfolioEnhanceTranssApi(name) {
   return createAPIInstance()
      .get(
         `/portfolio-enhancement-transactions?filename=${encodeURIComponent(
            name
         )}`
      )
      .then(res => res.data);
}

export function geReplaceTickerApi(payload) {
   return createAPIInstance()
      .post("/get-all-tickers", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function getEnhancedCardApi(name) {
   return createAPIInstance()
      .get(
         `/enhancement-card-data?ticker=${encodeURIComponent(
            name.ticker
         )}&overlap=${encodeURIComponent(name.overlap)}`
      )
      .then(res => res.data);
}

// ------ csv preflight request, get all tickers validate -------
export function checkTickerExist(payload) {
   return createAPIInstance()
      .post("/user/check-ticker-exist", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

//----- Get model portfolio -----
//https://api.magnifi.com/api/user/get-mp-for-portfolio?query=low_risk_alternative&portfolio_id=3
export function getModelPortfolio(payload) {
   return createAPIInstance()
      .get(`user/get-mp-for-portfolio`, {
         params: payload,
      })
      .then(res => res.data);
}

// ----- FINALIZE MODEL PORTFOLIO API ------

export function finalizeModelPortfolio(payload) {
   return createAPIInstance()
      .post("/enhance-model-portfolio", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}
