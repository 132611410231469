import zipObject from "lodash/zipObject";

const actions = [
   "RESET_COMMUNITY_STATE",
   "RESET_FUND_RATING",

   "SELECT_PORTFOLIO_TO_LOAD",

   "GET_ASSET_ALLOCATION_DATA_REQUEST",
   "GET_ASSET_ALLOCATION_DATA_FETCH",
   "GET_ASSET_ALLOCATION_DATA_SUCCESS",

   "GET_YOUR_PORFOLIO_REQUEST",
   "GET_YOUR_PORFOLIO_FETCH",
   "GET_YOUR_PORFOLIO_SUCCESS",

   "GET_TOP_RANKED_MEMBERS_REQUEST",
   "GET_TOP_RANKED_MEMBERS_FETCH",
   "GET_TOP_RANKED_MEMBERS_SUCCESS",

   "SET_UPDATED_PORTFOLIO_DATA_REQUEST",
   "SET_UPDATED_PORTFOLIO_DATA_SUBMIT",
   "SET_UPDATED_PORTFOLIO_DATA_SUCCESS",

   "GET_FUND_DISCOVERY_SEARCH_REQUEST",
   "GET_FUND_DISCOVERY_SEARCH_FETCH",
   "GET_FUND_DISCOVERY_SEARCH_SUCCESS",
   "GET_FUND_DISCOVERY_SEARCH_RESET",

   "GET_USER_RANKING_HISTORY_REQUEST",
   "GET_USER_RANKING_HISTORY_FETCH",
   "GET_USER_RANKING_HISTORY_SUCCESS",

   "GET_USER_RATINGS_REQUEST",
   "GET_USER_RATINGS_FETCH",
   "GET_USER_RATINGS_SUCCESS",

   "GET_USER_FUND_RATING_REQUEST",
   "GET_USER_FUND_RATING_FETCH",
   "GET_USER_FUND_RATING_SUCCESS",

   "ADD_NEW_FUND_RATING_REQUEST",
   "ADD_NEW_FUND_RATING_FETCH",
   "ADD_NEW_FUND_RATING_SUCCESS",

   "UPDATE_USER_FUND_RATING_REQUEST",
   "UPDATE_USER_FUND_RATING_FETCH",
   "UPDATE_USER_FUND_RATING_SUCCESS",

   "REMOVE_USER_FUND_RATING_REQUEST",
   "REMOVE_USER_FUND_RATING_FETCH",
   "REMOVE_USER_FUND_RATING_SUCCESS",

   "GET_USER_RANKING_DATA_REQUEST",
   "GET_USER_RANKING_DATA_FETCH",
   "GET_USER_RANKING_DATA_SUCCESS",

   "GET_USER_SEARCH_FUND_RATING_REQUEST",
   "GET_USER_SEARCH_FUND_RATING_FETCH",
   "GET_USER_SEARCH_FUND_RATING_SUCCESS",

   "UPDATE_ALLOCATION_FUND_DATA",

   "GET_FUND_COMMENTS_DATA_REQUEST",
   "GET_FUND_COMMENTS_DATA_FETCH",
   "GET_FUND_COMMENTS_DATA_SUCCESS",

   "GET_USER_COMMENTS_REQUEST",
   "GET_USER_COMMENTS_FETCH",
   "GET_USER_COMMENTS_SUCCESS",

   "POST_FUND_COMMENT_SUCCESS",

   "POST_FUND_REPLY_SUCCESS",

   "DELETE_FUND_COMMENT_SUCCESS",

   "DELETE_FUND_REPLY_SUCCESS",

   "ADD_FUND_COMMENT_VOTE_REQUEST",
   "ADD_FUND_COMMENT_VOTE_FETCH",
   "ADD_FUND_COMMENT_VOTE_SUCCESS",
   "ADD_FUND_COMMENT_VOTE_ERROR",

   // Sponsor Opt
   "GET_SPONSOR_OPT_REQUEST",
   "GET_SPONSOR_OPT_FETCH",
   "GET_SPONSOR_OPT_SUCCESS",

   "POST_SPONSOR_OPT_REQUEST",
   "POST_SPONSOR_OPT_FETCH",
   "POST_SPONSOR_OPT_SUCCESS",
];

export default zipObject(actions, actions);
