import React, { Component, Fragment } from "react";
import moment from "moment";
import {
   // Container,
   // Col,
   // Row,
   // Form,
   FormGroup,
   Label,
   Input,
   // Collapse
} from "reactstrap";
import { CustomFormFeedback } from "shared-components";
import "cleave.js/dist/addons/cleave-phone.i18n.js";
// import Cleave from 'cleave.js/react'
// import DatePicker from 'react-datepicker'
import CountrySelect from "./CountrySelect";
// import StateSelect from "./StateSelect";
// import ChangePassword from './ChangePassword'
import style from "./Profile.module.scss";
import {
   Radio,
   Select,
   Checkbox,
   Dropdown,
   Menu,
   Tooltip,
   Modal,
   Button,
} from "antd";
import { connect } from "react-redux";
import {
   fetchProfile,
   updateProfile,
   profileChangePasswordRequest,
} from "actions/profile";
import { createLoadingSelector } from "selectors/loading-selector";
import { whereDidYouHear } from "../../Constant/FormData";
import PhoneNumberInput from "../../../../components/PhoneNumberInput";
import { isEqual, keys } from "lodash";
import { numFmt } from "Utils";
import { getAllCountryRequest } from "actions/register";
import { oktaAuth } from "oktaAuth";

import { openNotificationWithIcon } from "layouts/utils";

import { FieldValue } from "shared-components";

const PRIMARY_CLASS = "Primary Class";
const INSTITUTIONAL_CLASS = "Institutional Class";
const RETAIL_CLASS = "Retail Class";

const SHARE_CLASSES = [
   { bucket: "primary", key: PRIMARY_CLASS },
   { bucket: "inst", key: INSTITUTIONAL_CLASS },
   { bucket: "inst", key: RETAIL_CLASS },
   { bucket: "alphabets", key: "A Class" },
   { bucket: "alphabets", key: "B Class" },
   { bucket: "alphabets", key: "C Class" },
   { bucket: "alphabets", key: "D Class" },
   { bucket: "alphabets", key: "E Class" },
   { bucket: "alphabets", key: "F Class" },
   { bucket: "alphabets", key: "G Class" },
   { bucket: "alphabets", key: "H Class" },
   { bucket: "alphabets", key: "J Class" },
   { bucket: "alphabets", key: "K Class" },
   { bucket: "alphabets", key: "L Class" },
   { bucket: "alphabets", key: "M Class" },
   { bucket: "alphabets", key: "N Class" },
   { bucket: "alphabets", key: "P Class" },
   { bucket: "alphabets", key: "S Class" },
   { bucket: "alphabets", key: "T Class" },
   { bucket: "alphabets", key: "W Class" },
   { bucket: "alphabets", key: "Admin Class" },
   { bucket: "alphabets", key: "Advisor Class" },
   { bucket: "alphabets", key: "Inst Class" },
   { bucket: "alphabets", key: "Investor Class" },
   { bucket: "alphabets", key: "No Load Class" },
   { bucket: "alphabets", key: "Retirement Class" },
   { bucket: "alphabets", key: "Premier Class" },
   { bucket: "alphabets", key: "Other" },
];

class EditableProfileForm extends Component {
   state = {
      showGenderSelection: false,
      showPhoneEdit: false,
      showAddressEdit: false,
      showBDayEdit: false,
      showHearAboutUs: false,
      showIncomeDropdown: false,
      showChangePassword: false,
      tooltipOpenMPT: false,
      tooltipOpenCAT: false,
      MFvisible: false,
      showSelfRoleModal: false,
      isEdit: false,
   };

   toggleGender = () => {
      this.setState({
         showGenderSelection: !this.state.showGenderSelection,
      });
   };

   selectGender = e => {
      this.props.formProps.setFieldValue("gender", e.target.value);
      this.setState({
         showGenderSelection: !this.state.showGenderSelection,
      });
   };

   handleCountrySelection = (value, item) => {
      this.props.formProps.setFieldValue("country", value);
   };

   handleHearAboutUs = (value, item) => {
      this.props.formProps.setFieldValue("hear_aboutus", value);
   };
   investableAssets = (value, item) => {
      this.props.formProps.setFieldValue("investable_assets", value);
   };
   handleAreU = (value, item) => {
      this.props.formProps.setFieldValue("self_role", value);
   };

   handleMFMenuClick = value => {
      let key = value.key.split(",")[0];
      // let bucket = value.key.split(',')[1]
      // console.log(!!this.props.formProps.values.share_class)
      let oldArray = this.props.formProps.values.share_class
         ? this.props.formProps.values.share_class.split(",")
         : [];
      let isPresent = oldArray.includes(key);
      // console.log(isPresent)
      let final;
      if (!isPresent) {
         // console.log('adding')
         if (key === PRIMARY_CLASS) {
            final = [key];
         } else if (key === INSTITUTIONAL_CLASS || key === RETAIL_CLASS) {
            final = oldArray.filter(
               item => item === INSTITUTIONAL_CLASS || item === RETAIL_CLASS
            );
            final = [...final, key];
         } else {
            final = oldArray.filter(item =>
               SHARE_CLASSES.map(temp =>
                  temp.bucket === "alphabets" ? temp.key : ""
               ).includes(item)
            );
            final = [...final, key];
         }
      } else {
         // console.log('removing')
         final = oldArray.filter(item => item !== key);
      }
      // console.log(final.join(','))
      this.props.formProps.setFieldValue("share_class", final.join(","));
   };

   handleVisibleChange = flag => {
      this.setState({ MFvisible: flag });
   };

   handleAssetsUnderManag = (value, item) => {
      this.props.formProps.setFieldValue("estimated_net_worth", value);
   };

   handleBenchmark = value => {
      this.props.formProps.setFieldValue("mpt_benchmark", value);
   };

   toggleAddress = () => {
      !this.state.showAddressEdit &&
         this.setState({
            showAddressEdit: !this.state.showAddressEdit,
         });
   };
   handleAccInvestor = value => {
      this.props.formProps.setFieldValue("accredited_investor", !value);
   };

   numberWithCommas(x) {
      return numFmt(x);
      // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
   }

   componentDidUpdate = prev => {
      if (!isEqual(prev.formProps.errors, this.props.formProps.errors)) {
         let filtered = keys(this.props.formProps.errors).filter(key =>
            ["address", "state", "country", "city"].includes(key)
         );
         if (filtered.length) {
            this.toggleAddress();
         }
      }
      // if(!this.props.isFetching && this.props.profile.first_name === "" && this.props.profile.last_name === "" && !this.state.isEdit){
      //   this.setState({
      //     isEdit: true
      //   });
      // }
      // onupdate close edit
      if (this.props.profileUpdateSuccess !== prev.profileUpdateSuccess) {
         this.setState({
            isEdit: false,
         });
      }
   };

   handleCancelSelfRole = () => {
      this.setState(prevState => ({
         showSelfRoleModal: !prevState.showSelfRoleModal,
      }));
   };

   _getDOBElements = () => {
      const { values } = this.props.formProps;
      // const condtion =
      //   !values.isSubmitted ||
      //   !values.date_of_birth ||
      //   values.date_of_birth === '' ||
      //   typeof values.date_of_birth.getMonth !== 'function'
      // console.log(!values.isSubmitted, condtion, '???')
      return values.date_of_birth &&
         typeof values.date_of_birth.getMonth === "function"
         ? moment(values.date_of_birth).utc().format("YYYY-MM-DD")
         : "-";
      // if (condtion) {
      //   return (
      //     <DatePicker
      //       id="date_of_birth"
      //       selected={
      //         values.date_of_birth &&
      //         typeof values.date_of_birth.getMonth === 'function'
      //           ? values.date_of_birth
      //           : null
      //       }
      //       peekNextMonth
      //       showMonthDropdown
      //       showYearDropdown
      //       dropdownMode="select"
      //       placeholderText={'DD/MM/YYYY'}
      //       maxDate={new Date()}
      //       autoComplete="off"
      //       onChange={(dateInstance, dateString) => {
      //         setFieldValue('date_of_birth', dateInstance || null)
      //       }}
      //       className={`${style.input} ${style.DOB}`}
      //     />
      //   )
      // } else {
      //   return moment(values.date_of_birth)
      //     .utc()
      //     .format('YYYY-MM-DD')
      // }
   };

   toggleEdit = () => {
      this.setState({
         isEdit: !this.state.isEdit,
      });
   };

   oktaChangePassword = (event, email) => {
      console.log("email --> ", email);
      if (event) {
         event.preventDefault();
         event.stopPropagation();
      }
      if (email && oktaAuth) {
         oktaAuth
            .forgotPassword({
               username: email,
               factorType: "EMAIL",
            })
            .then(response => {
               console.log("response --> ", response);
               if (
                  response &&
                  response.data &&
                  response.data.status === "RECOVERY_CHALLENGE"
               ) {
                  openNotificationWithIcon({
                     duration: 5,
                     type: "success",
                     message: "Sent",
                     className: "api-response-notification-class",
                     description:
                        "Change password email has been sent successfully",
                  });
               }
            })
            .catch(err => {
               console.error(err);
            });
      }
   };

   render() {
      const {
         values,
         touched,
         errors,
         handleChange,
         handleBlur,
         setFieldValue,
      } = this.props.formProps;
      // console.log(this.props.formProps, 'profile----')
      const { Option } = Select;
      const {
         email,
         lastName,
         firstName,
         isSSOUser,
         togglePortfolio,
         showAdvisoryEdit,
         showPortfolioValue,
         toggleAdvisoryField,
         // togglePasswordChangeModal,
      } = this.props;
      const { isEdit, MFvisible, showGenderSelection } = this.state;
      //eslint-disable-next-line no-lone-blocks
      {
         values.address === "" &&
            values.city === "" &&
            values.state === "" &&
            values.zipcode === "" &&
            values.country === "" &&
            this.toggleAddress();
      }
      const share_class = values.share_class
         ? values.share_class.split(",")
         : [];
      const MFClassMenu = (
         <Menu
            className={style.mfDropdownContainer}
            onClick={this.handleMFMenuClick}
         >
            {SHARE_CLASSES.map((item, i) => {
               let data = [];
               data.push(
                  <Menu.Item
                     className={style.menu}
                     key={`${item.key},${item.bucket}`}
                  >
                     {item.key}
                     <Checkbox checked={share_class.includes(item.key)} />
                  </Menu.Item>
               );
               if (
                  i < SHARE_CLASSES.length - 1 &&
                  item.bucket !== SHARE_CLASSES[i + 1].bucket
               )
                  data.push(<Menu.Divider />);
               return data;
            })}
         </Menu>
      );

      let isRetailUser =
         ["", "Other/Curious", "Individual Investor"].includes(
            values.self_role
         ) === true || values.self_role === null;

      return (
         <div className={`newProfile ${style.editForm}`}>
            {/*name*/}
            <FormGroup className={style.formGroup}>
               {!isEdit && firstName && lastName ? (
                  <Fragment>
                     {/* {!firstName && !lastName ?
              (
                <span className={style.nameEditBox}>
                  <span className={style.key}>Name </span>
                  <span className={style.name}>
                    <Input
                      type="text"
                      className={style.editInput}
                      placeholder="First Name"
                      readOnly
                    />
                    <Input
                      className={style.editInput}
                      placeholder="Last Name"
                      readOnly
                    />
                    <i className="fas fa-pencil"  onClick={this.toggleEdit}></i>
                  </span>
                </span>
              ) */}
                     <div className={style.nameEditBox}>
                        <span className={style.key}>Name </span>
                        <div
                           className={`${style.name} ${style.hoverableValue}`}
                           onClick={this.toggleEdit}
                        >
                           {firstName} {lastName}
                        </div>
                     </div>
                  </Fragment>
               ) : (
                  <div
                     className={style.nameEditBox}
                     style={{ marginBottom: "10px" }}
                  >
                     <span className={style.key}>Name </span>
                     <div
                        className={style.name}
                        style={{
                           display: "flex",
                           width: "80%",
                        }}
                     >
                        <div className={style.editableName}>
                           <Input
                              type="text"
                              className={style.editInput}
                              placeholder={"First Name"}
                              name="first_name"
                              id="first_name"
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                           />
                           {errors.first_name && touched.first_name && (
                              <div className={style.formInvalidField}>
                                 {errors["first_name"]}
                              </div>
                           )}
                        </div>
                        <div className={style.editableName}>
                           <Input
                              className={style.editInput}
                              type="text"
                              placeholder={"Last Name"}
                              name="last_name"
                              id="last_name"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                           />
                           {/* <i class="far fa-times" onClick={this.toggleEdit}></i> */}
                           {errors.last_name && touched.last_name && (
                              <div className={style.formInvalidField}>
                                 {errors["last_name"]}
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               )}
            </FormGroup>
            {/*email*/}
            <FormGroup className={style.formGroup}>
               <Label className={style.key}>E-mail</Label>
               <div className={`${style.value} ${style.cursorNotAllowed}`}>
                  <FieldValue className={style.value} dashIfEmpty>
                     {email}
                  </FieldValue>
               </div>
            </FormGroup>
            {/*password*/}
            {!isSSOUser && (
               <div className={style.formGroup}>
                  <div className={style.key}>Password</div>
                  <div
                     onClick={event => this.oktaChangePassword(event, email)}
                     className={`${style.cursorPointer} ${style.value}`}
                  >
                     Change Password
                  </div>
                  {/*
              <Modal
                title={null}
                centered={true}
                className={'antModal'}
                footer={null}
                onCancel={togglePasswordChangeModal}
                width={`470px`}
                destroyOnClose={true}
                style={{ borderRadius: `30px` }}
                visible={showChangePassword}
              >
                <ChangePassword
                  onCancel={handleCancelPassword}
                  // handleChangePasswordSubmit={handleChangePasswordSubmit}
                  profileChangePasswordRequest={profileChangePasswordRequest}
                />
              </Modal>
            */}
               </div>
            )}
            {/*ADDRESS*/}
            <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
               <Label className={style.key} for="address">
                  Address
               </Label>
               {/* {!showAddressEdit && (
            <div
              onClick={this.toggleAddress}
              className={`${style.cursorPointer} ${style.value}`}
            >
              {values.address} {values.city} {values.state} {values.country}{' '}
              {values.zipcode}
            </div>
          )} */}
               {
                  <div className={style.addressInfo}>
                     <div className={style.firstInfo}>
                        {/* <div className={style.errorWithFeedback}>
                  <CountrySelect
                    dropdownClassName={style.dropdownANT}
                    className={`${style.input} ${style.antSelect}`}
                    value={values.country}
                    onChange={this.handleCountrySelection}
                    bordered={false}
                    suffixIcon={null}
                    dropdownMatchSelectWidth={false}
                    onBlur={handleBlur}
                  />
                  {errors.country && touched.country && (
                    <div className={style.formInvalidField}>
                      {errors['country']}
                    </div>
                  )}
                </div> */}
                        <>
                           <div className={style.errorWithFeedback}>
                              {values.country !== "United States" ? (
                                 <>
                                    <Input
                                       type="text"
                                       name="state"
                                       id="state"
                                       placeholder={"State"}
                                       value={values.state}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       className={
                                          errors.state && touched.state
                                             ? style.inputInvalid
                                             : style.input
                                       }
                                    />
                                    {/*  <CustomFormFeedback formProps={formProps} fieldName="state" />*/}
                                 </>
                              ) : (
                                 <Input
                                    type="text"
                                    name="state"
                                    id="state"
                                    placeholder={"State"}
                                    value={values.state}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                       errors.state && touched.state
                                          ? style.inputInvalid
                                          : style.input
                                    }
                                 />
                                 // <StateSelect
                                 //   dropdownClassName={style.dropdownANT}
                                 //   className={`${style.input} ${style.antSelect}`}
                                 //   value={values.state}
                                 //   onChange={value => setFieldValue('state', value)}
                                 //   bordered={false}
                                 //   suffixIcon={null}
                                 //   dropdownMatchSelectWidth={false}
                                 //   onBlur={handleBlur}
                                 // />
                              )}
                              {errors.state && touched.state && (
                                 <div className={style.formInvalidField}>
                                    {errors["state"]}
                                 </div>
                              )}
                           </div>
                        </>

                        <div className={style.errorWithFeedback}>
                           <Input
                              type="text"
                              placeholder={"Street Address"}
                              name="address"
                              id="address"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                 errors.address && touched.address
                                    ? style.inputInvalid
                                    : style.input
                              }
                           />
                           {errors.address && touched.address && (
                              <div className={style.formInvalidField}>
                                 {errors["address"]}
                              </div>
                           )}
                        </div>
                     </div>
                     <div className={style.secInfo}>
                        <Input
                           type="text"
                           placeholder={"City"}
                           name="city"
                           id="city"
                           value={values.city}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           className={
                              errors.city && touched.city
                                 ? style.inputInvalid
                                 : style.input
                           }
                        />
                        <div className={style.errorWithFeedback}>
                           <Input
                              type="text"
                              name="zipcode"
                              id="zipcode"
                              placeholder={"Zipcode"}
                              value={values.zipcode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                 errors.zipcode && touched.zipcode
                                    ? `is-invalid ${style.inputInvalid}`
                                    : style.input
                              }
                           />
                           <CustomFormFeedback
                              formProps={this.props.formProps}
                              fieldName="zipcode"
                           />
                        </div>
                     </div>
                  </div>
               }
            </FormGroup>

            {/*Country*/}
            <FormGroup className={`${style.addressGroup} ${style.formGroup} `}>
               <Label className={style.key} for="Country">
                  Country
               </Label>
               {
                  <div className={style.addressInfo}>
                     <div className={style.firstInfo}>
                        <div className={style.errorWithFeedback}>
                           <CountrySelect
                              dropdownClassName={style.dropdownANT}
                              className={`${style.input} ${style.antSelect}`}
                              value={values.country}
                              onChange={this.handleCountrySelection}
                              bordered={false}
                              suffixIcon={null}
                              dropdownMatchSelectWidth={false}
                              onBlur={handleBlur}
                              countryList={this.props.countryList}
                              getAllCountryRequest={
                                 this.props.getAllCountryRequest
                              }
                           />
                           {errors.country && touched.country && (
                              <div className={style.formInvalidField}>
                                 {errors["country"]}
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               }
            </FormGroup>

            {/*PHONE NUMBER*/}
            <FormGroup className={style.formGroup}>
               <Label className={style.key} for="phone">
                  Phone
               </Label>
               <div
                  className={`${style.errorWithFeedback} ${style.phoneContainer} phoneContainer`}
               >
                  <PhoneNumberInput
                     hideCode={true}
                     onChangePhoneCode={val =>
                        setFieldValue("country_code", val)
                     }
                     errors={errors}
                     touched={touched}
                     handleChange={handleChange}
                     handleBlur={handleBlur}
                     values={values}
                     phoneCodeName="country_code"
                     phoneName="phone"
                  />
                  {/* <Cleave
                placeholder="### ### ####"
                options={{
                  phone: true,
                  phoneRegionCode: 'US'
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                className={errors.phone && touched.phone ? `is-invalid ${style.inputInvalid}` : style.input}
                id="phoneNumber"
                name="phone"
              /> */}
                  {errors.phone && touched.phone && (
                     <div className={style.formInvalidField}>
                        {errors["phone"]}
                     </div>
                  )}
                  {/* <CustomFormFeedback formProps={this.props.formProps} fieldName="phone"/> */}
               </div>
            </FormGroup>
            {/*GENDER*/}
            <FormGroup className={style.formGroup}>
               <Label className={style.key} for="gender">
                  Gender
               </Label>
               {!showGenderSelection && (
                  <div
                     onClick={this.toggleGender}
                     className={`${style.cursorPointer} ${style.value}`}
                  >
                     {values.gender === "M"
                        ? "Male"
                        : values.gender === "F"
                        ? "Female"
                        : "Not Specified"}
                  </div>
               )}
               {showGenderSelection && (
                  <div
                     className={style.genderSelection}
                     onChange={this.selectGender}
                  >
                     <Radio.Group
                        id={"gender"}
                        onChange={this.selectGender}
                        value={values.gender}
                     >
                        <Radio value={"M"}>Male</Radio>
                        <Radio value={"F"}>Female</Radio>
                        <Radio value={""}>Not Specified</Radio>
                     </Radio.Group>
                  </div>
               )}
            </FormGroup>
            {/*DOB*/}
            <FormGroup className={style.formGroup}>
               <Label className={style.key} for="date_of_birth">
                  Date of Birth
               </Label>
               <div className={`${style.cursorNotAllowed} ${style.value}`}>
                  {this._getDOBElements()}
               </div>

               {/* <CustomFormFeedback
            formProps={this.props.formProps}
            fieldName="date_of_birth"
          /> */}
            </FormGroup>
            {/*HEAR ABOUT US*/}
            <FormGroup className={`profileCustomScroll ${style.formGroup}`}>
               <Label className={style.key} for="hear_aboutus">
                  Where did you hear about us?
               </Label>
               <Select
                  type="select"
                  name="hear_aboutus"
                  id="hear_aboutus"
                  value={values.hear_aboutus}
                  onChange={this.handleHearAboutUs}
                  onBlur={handleBlur}
                  bordered={false}
                  suffixIcon={null}
                  dropdownMatchSelectWidth={false}
                  dropdownClassName={style.dropdownANT}
                  className={
                     errors.hear_aboutus && touched.hear_aboutus
                        ? style.inputInvalid
                        : `${style.input} ${style.antSelect}`
                  }
               >
                  <Option value="">Select</Option>
                  {whereDidYouHear.map(item => (
                     <option value={item}>{item}</option>
                  ))}
               </Select>
               <CustomFormFeedback
                  formProps={this.props.formProps}
                  fieldName="hear_aboutus"
               />
            </FormGroup>
            {/*divider*/}
            <div className={style.divider} />
            {/*investment profile*/}
            <div className={style.subHeader}> Investment Profile</div>
            {/*are you an*/}
            <FormGroup className={style.formGroup}>
               <Label className={style.key} for="self_role">
                  Are you an
               </Label>
               <div className={`${style.cursorPointer} ${style.value}`}>
                  {values.self_role}
                  {values.self_role && (
                     <span className={style.toggleSelfRole}>
                        <i
                           className="fas fa-pencil"
                           onClick={() => this.handleCancelSelfRole()}
                        ></i>
                     </span>
                  )}
               </div>

               <Modal
                  visible={this.state.showSelfRoleModal}
                  closable={false}
                  centered={true}
                  footer={null}
                  width="auto"
                  title={null}
                  style={{ height: "auto" }}
                  maskStyle={true}
                  bodyStyle={{ minHeight: 212 }}
                  wrapClassName={`select-domain-popup-container self-role-popup-container`}
               >
                  <div className="context-wrapper">
                     <h3>Attention!</h3>
                     <p>You are registered as {values.self_role}.</p>
                     <p style={{ marginBottom: 5 }}>
                        {isRetailUser
                           ? "Would you like to register as an advisor?"
                           : "Would you like to register for a retail investment account?"}
                     </p>
                  </div>
                  <div className="btn-wrap d-flex w-100 justify-content-center">
                     <Button
                        className="primary-btn"
                        onClick={() => this.handleCancelSelfRole()}
                     >
                        <a
                           id="mail-to-magnifi"
                           href="mailto:support@magnifi.com"
                        >
                           Yes,
                           <br />
                           Contact support
                        </a>
                     </Button>
                     <Button
                        className="secondary-btn"
                        onClick={() => this.handleCancelSelfRole()}
                     >
                        NO,
                        <br />
                        CANCEL
                     </Button>
                  </div>
               </Modal>
               <CustomFormFeedback
                  formProps={this.props.formProps}
                  fieldName="self_role"
               />
            </FormGroup>
            {/*Investable Assets*/}
            {(values.self_role === "Individual Investor" ||
               values.self_role === "Other/Curious") && (
               <FormGroup className={style.formGroup}>
                  <Label className={style.key} for="investable_assets">
                     Investable Assets
                  </Label>
                  <Select
                     type="select"
                     name="investable_assets"
                     id="investable_assets"
                     value={values.investable_assets || ""}
                     onChange={this.investableAssets}
                     onBlur={handleBlur}
                     bordered={false}
                     suffixIcon={null}
                     dropdownMatchSelectWidth={false}
                     dropdownClassName={style.dropdownANT}
                     className={
                        errors.investable_assets && touched.investable_assets
                           ? style.inputInvalid
                           : `${style.input} ${style.antSelect}`
                     }
                  >
                     <Option value="">Select</Option>
                     {/* <option value={values.investable_assets}>{values.investable_assets === '' ? 'Select' : values.investable_assets}</option> */}
                     <Option value="Under $250,000">Under $250,000</Option>
                     <Option value="$250,000 to $500,000">
                        $250,000 to $500,000
                     </Option>
                     <Option value="$500,000 to $2,000,000">
                        $500,000 to $2,000,000
                     </Option>
                     <Option value="More than $2,000,000">
                        More than $2,000,000
                     </Option>
                  </Select>
                  <CustomFormFeedback
                     formProps={this.props.formProps}
                     fieldName="investable_assets"
                  />
               </FormGroup>
            )}
            {/*AUM*/}
            {!(
               values.self_role === "Individual Investor" ||
               values.self_role === "Other/Curious"
            ) && (
               <FormGroup className={style.formGroup}>
                  <Label className={style.key} for="estimated_net_worth">
                     Assets Under Management/ Advice
                  </Label>
                  <Select
                     type="select"
                     name="estimated_net_worth"
                     id="estimated_net_worth"
                     value={values.estimated_net_worth}
                     onChange={this.handleAssetsUnderManag}
                     bordered={false}
                     suffixIcon={null}
                     dropdownMatchSelectWidth={false}
                     dropdownClassName={style.dropdownANT}
                     onBlur={handleBlur}
                     className={
                        errors.estimated_net_worth &&
                        touched.estimated_net_worth
                           ? style.inputInvalid
                           : `${style.input} ${style.antSelect}`
                     }
                  >
                     <Option value="">Select</Option>
                     {/* <option value={values.estimated_net_worth}>{values.estimated_net_worth === '' ? 'Select' : values.estimated_net_worth}</option> */}
                     <Option value="Under $10 million">
                        Under $10 million
                     </Option>
                     <Option value="$10 million to $50 million">
                        $10 million to $50 million
                     </Option>
                     <Option value="$50 million to $100 million">
                        $50 million to $100 million
                     </Option>
                     <Option value="$100 million to $500 million">
                        $100 million to $500 million
                     </Option>
                     <Option value="$500 million to $1 billion">
                        $500 million to $1 billion
                     </Option>
                     <Option value="$1 billion to $2.5 billion">
                        $1 billion to $2.5 billion
                     </Option>
                     <Option value="$2.5 billion to $5 billion">
                        $2.5 billion to $5 billion
                     </Option>
                     <Option value="$5 billion to $10 billion">
                        $5 billion to $10 billion
                     </Option>
                     <Option value="More than $10 billion">
                        More than $10 billion
                     </Option>
                  </Select>
                  <CustomFormFeedback
                     formProps={this.props.formProps}
                     fieldName="estimated_net_worth"
                  />
               </FormGroup>
            )}
            {/*shared class*/}
            <FormGroup className={style.formGroup}>
               <Label className={style.key} for="share_class">
                  Preferred MF Share Class
               </Label>
               <div className={style.errorWithFeedback}>
                  <div
                     name="share_class"
                     id="share_class"
                     className={`d-flex align-items-center justify-content-center ${style.mfDropdown}`}
                  >
                     <Dropdown
                        id="share_class"
                        name="share_class"
                        overlay={MFClassMenu}
                        visible={MFvisible}
                        trigger={["click"]}
                        className={
                           errors.share_class && touched.share_class
                              ? style.inputInvalid
                              : `${style.input} ${style.mFinput}`
                        }
                        dropdownClassName={style.dropdownANT}
                        onVisibleChange={this.handleVisibleChange}
                        getPopupContainer={() =>
                           document.getElementById("share_class")
                        }
                     >
                        <div>
                           {share_class.length ? (
                              <ReturnWithAnd arr={share_class} />
                           ) : (
                              "Select"
                           )}
                        </div>
                     </Dropdown>
                  </div>
                  {errors.share_class && touched.share_class && (
                     <p>{errors.share_class}</p>
                  )}
               </div>
            </FormGroup>

            {/*/!*shared class*!/*/}
            {/*<div className={style.formGroup}>*/}
            {/*  <div className={style.key}>Shared Class</div>*/}
            {/*  <div className={style.value}>{values.share_class}</div>*/}
            {/*</div>*/}
            {/*Benchmark type*/}
            {process.env.REACT_APP_SUB_DOMAIN !== "retail" && (
               <FormGroup className={style.formGroup}>
                  <Label className={style.key} for="estimated_net_worth">
                     Preferred Benchmark Type
                  </Label>
                  <Select
                     type="select"
                     name="mpt_benchmark"
                     id="mpt_benchmark"
                     value={values.mpt_benchmark || "CATEGORY"}
                     onChange={this.handleBenchmark}
                     bordered={false}
                     suffixIcon={null}
                     dropdownMatchSelectWidth={false}
                     dropdownClassName={style.dropdownANT}
                     onBlur={handleBlur}
                     className={
                        errors.mpt_benchmark && touched.mpt_benchmark
                           ? style.inputInvalid
                           : `${style.input} ${style.antSelect}`
                     }
                  >
                     <Option value="MPT">
                        <div
                           style={{ width: `200px` }}
                           className={style.benchmarkRow}
                        >
                           MPT Benchmark
                           <Tooltip
                              className={style.tooltipContainer}
                              overlayClassName={style.tooltipContainer}
                              color="#4A4A4A"
                              title={
                                 "MPT Benchmark is the primary benchmark assigned to investments based on their Broad Morningstar category. This broad category-level benchmark is used to calculate Modern Portfolio Theory statistics."
                              }
                              autoAdjustOverflow={false}
                              placement="right"
                              trigger="hover"
                              target="MPT"
                              toggle={() =>
                                 this.setState({
                                    tooltipOpenMPT: !this.state.tooltipOpenMPT,
                                 })
                              }
                           >
                              <i
                                 id="MPT"
                                 className={`${style.tooltipIcon} fal fa-info-circle`}
                              />
                           </Tooltip>
                        </div>
                     </Option>
                     <Option value="CATEGORY">
                        <div
                           style={{ width: `200px` }}
                           className={style.benchmarkRow}
                        >
                           Category Benchmark
                           <Tooltip
                              className={style.tooltipContainer}
                              overlayClassName={style.tooltipContainer}
                              color="#4A4A4A"
                              title={
                                 "Category Benchmark is assigned to investments based on the Morningstar category. The category benchmark is a proxy ETF for each category defined by Morningstar against which relative statistics are calculated for the investment."
                              }
                              autoAdjustOverflow={false}
                              trigger="hover"
                              placement="right"
                              isOpen={this.state.tooltipOpenCAT}
                              target="CATEGORY"
                              toggle={() =>
                                 this.setState({
                                    tooltipOpenCAT: !this.state.tooltipOpenCAT,
                                 })
                              }
                           >
                              <i
                                 id="CATEGORY"
                                 className={`${style.tooltipIcon} fal fa-info-circle`}
                              />
                           </Tooltip>
                        </div>
                     </Option>
                  </Select>
               </FormGroup>
            )}

            {/*accredited*/}
            <div className={style.formGroup}>
               <div className={style.key}>Are you an accredited investor?</div>
               <div
                  id="accredited_investor"
                  onClick={e => {
                     e.preventDefault();
                     this.handleAccInvestor(values.accredited_investor);
                  }}
                  className={`${style.AccIve} ${style.value}`}
               >
                  {values.accredited_investor ? "Yes" : "No"}
               </div>
            </div>
            {process.env.REACT_APP_SUB_DOMAIN !== "retail" && (
               <FormGroup className={style.formGroup}>
                  <Label className={style.key} for="phone">
                     Default Portfolio Value
                  </Label>
                  <div className={style.errorWithFeedback}>
                     {showPortfolioValue ? (
                        <Input
                           type="text"
                           name="default_portfolio_value"
                           id="state"
                           placeholder="Portfolio Value"
                           value={values.default_portfolio_value}
                           onChange={e => {
                              const { value } = e.target;
                              const regex =
                                 /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                              if (
                                 value === "" ||
                                 regex.test(value.toString())
                              ) {
                                 handleChange(e);
                              }
                           }}
                           onBlur={handleBlur}
                           className={
                              errors.default_portfolio_value &&
                              touched.default_portfolio_value
                                 ? `is-invalid ${style.inputInvalid}`
                                 : style.input
                           }
                        />
                     ) : (
                        <span
                           className={`${style.AccIve} ${style.value}`}
                           onClick={togglePortfolio}
                        >{`$${this.numberWithCommas(
                           parseFloat(
                              parseFloat(
                                 values.default_portfolio_value
                              ).toFixed(2)
                           )
                        )}`}</span>
                     )}
                     <CustomFormFeedback
                        formProps={this.props.formProps}
                        fieldName="default_portfolio_value"
                     />
                  </div>
               </FormGroup>
            )}
            {process.env.REACT_APP_SUB_DOMAIN !== "retail" && (
               <FormGroup className={style.formGroup}>
                  <Label className={style.key} for="phone">
                     Advisory Fees
                  </Label>
                  <div className={style.errorWithFeedback}>
                     {showAdvisoryEdit ? (
                        <Input
                           type="text"
                           name="advisory_fees"
                           id="state"
                           placeholder="Advisory Fees(%)"
                           value={values.advisory_fees}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           className={
                              errors.advisory_fees && touched.advisory_fees
                                 ? `is-invalid ${style.inputInvalid}`
                                 : style.input
                           }
                        />
                     ) : (
                        <span
                           className={`${style.AccIve} ${style.value}`}
                           onClick={toggleAdvisoryField}
                        >
                           {values.advisory_fees && !isNaN(values.advisory_fees)
                              ? `${numFmt(parseFloat(values.advisory_fees))}%`
                              : "-"}
                        </span>
                     )}
                     <CustomFormFeedback
                        formProps={this.props.formProps}
                        fieldName="advisory_fees"
                     />
                  </div>
               </FormGroup>
            )}
         </div>
      );
   }
}

const mapStateToProps = state => ({
   profile: state.profile.profile,
   user: state.auth.user,
   auth: state.auth,
   // TODO: Currently profile api does not return isGoogleLogin
   isSSOUser:
      state.auth.isGoogleLogin ||
      window.localStorage.getItem("isGoogleLogin") === "true",
   profileUpdateSuccess: state.profile.profileUpdateSuccess,
   profileUpdateFailed: state.profile.profileUpdateFailed,
   firstName: state.profile.profile.first_name,
   lastName: state.profile.profile.last_name,
   countryList: state.register.countryList || [],
   isFetching: createLoadingSelector([
      "PROFILE",
      "CHANGE_PASSWORD",
      "PROFILE_UPDATE",
   ])(state),
});

const mapDispatchToProps = {
   fetchProfile,
   updateProfile,
   profileChangePasswordRequest,
   getAllCountryRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(EditableProfileForm);

const ReturnWithAnd = ({ arr }) => {
   return arr.map((item, i) => {
      if (arr.length > 2) {
         if (i === arr.length - 1) return `and ${item} `;
         if (i === arr.length - 2) return `${item} `;
         else return `${item}, `;
      } else {
         if (i === arr.length - 1) return `${item} `;
         if (i === arr.length - 2) return `${item} and `;
         else return `${item}, `;
      }
   });
};
