import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { NavLink, Switch } from "react-router-dom";
import { get } from "lodash";
import {
   Alert,
   Nav,
   TabContent,
   TabPane,
   NavItem,
   NavLink as TabNav,
} from "reactstrap";
import { logout } from "actions/auth";
import {
   changeFname,
   changeLname,
   toggleEdit,
   setUpdate,
   profilePhotoHideUnhideRequest,
} from "actions/profile";
import { PrivateRoute } from "shared-components/";
import { InnerPageFooter } from "shared-components/";
import Profile from "./Profile/Profile";
import style from "./Settings.module.scss";
import ProfilePhotoContainer from "components/profile/profilePhotoContainer";

class Settings extends Component {
   state = {
      showSuspendedAccount: null,
      accountStatus: "",
      editInput: false,
      apexStatus: null,
      activeTab: "1",
   };

   async componentDidMount() {
      document.body.classList.add("newProfile");
   }

   componentWillUnmount() {
      document.body.classList.remove("newProfile");
   }

   render() {
      const { firstName, lastName, apex, location } = this.props;
      const { accountStatus, apexStatus } = this.state;
      // const profile_self_role = window.localStorage.getItem('profile_self_role') ? window.localStorage.getItem('profile_self_role') : 'loggedOut';
      // const mailTo = process.env.REACT_APP_SUB_DOMAIN === 'retail' ? 'tradingaccountsupport@magnifi.com' : 'support@magnifi.com';

      return (
         <>
            <div
               className="homeWrapper"
               style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minHeight: "calc(100vh - 60px)",
               }}
            >
               <div id="settingsContainer" className={style.container}>
                  <div className={style.leftNavigation}>
                     <ProfilePhotoContainer />
                     <NavLink
                        to="/settings/profile"
                        activeClassName={style.active}
                        className={`${style.menuItem}`}
                     >
                        Profile
                     </NavLink>
                  </div>

                  <div
                     className={`${style.rightPages} ${
                        location.pathname === "/settings/profile" &&
                        get(apex, "account[0].is_active") === false
                           ? style.inActiveSettings
                           : ""
                     }`}
                  >
                     {location.pathname === "/settings/tradingAccount" &&
                        this._getACHReason(apex)}
                     {location.pathname === "/settings/tradingAccount" &&
                        this._getDipositeReasonWarning(apex)}
                     {location.pathname === "/settings/tradingAccount" &&
                        apexStatus &&
                        apexStatus.status === "SUSPENDED" &&
                        apexStatus.investigations &&
                        apexStatus.investigations[0].sketchId.result &&
                        apexStatus.investigations[0].sketchId.result
                           .equifaxResult && (
                           <Fragment>
                              {apexStatus.investigations[0].sketchId.result
                                 .equifaxResult.results.reject && (
                                 <Alert color="danger">
                                    <b>Reasons for rejection:</b>
                                    <Nav
                                       tabs
                                       className="reject_tabs_container"
                                       style={{ marginTop: 10 }}
                                    >
                                       {apexStatus.investigations[1] &&
                                       apexStatus.investigations[1].sketchId
                                          .result &&
                                       apexStatus.investigations[1].sketchId
                                          .result.equifaxResult &&
                                       apexStatus.investigations[1].sketchId
                                          .result.equifaxResult.results
                                          .reject &&
                                       Object.keys(
                                          apexStatus.investigations[1].sketchId
                                             .result.equifaxResult.results
                                             .reject
                                       ).length ? (
                                          <NavItem>
                                             <TabNav
                                                className={
                                                   this.state.activeTab === "1"
                                                      ? "active reject_tabs"
                                                      : "reject_tabs"
                                                }
                                                onClick={() => {
                                                   this.setState({
                                                      activeTab: "1",
                                                   });
                                                }}
                                             >
                                                {this.state.activeTab ===
                                                "1" ? (
                                                   <b>Primary</b>
                                                ) : (
                                                   "Primary"
                                                )}
                                             </TabNav>
                                          </NavItem>
                                       ) : null}
                                       {apexStatus.investigations[1] &&
                                       apexStatus.investigations[1].sketchId
                                          .result &&
                                       apexStatus.investigations[1].sketchId
                                          .result.equifaxResult &&
                                       apexStatus.investigations[1].sketchId
                                          .result.equifaxResult.results
                                          .reject &&
                                       Object.keys(
                                          apexStatus.investigations[1].sketchId
                                             .result.equifaxResult.results
                                             .reject
                                       ).length ? (
                                          <NavItem>
                                             <TabNav
                                                className={
                                                   this.state.activeTab === "2"
                                                      ? "active reject_tabs"
                                                      : "reject_tabs"
                                                }
                                                onClick={() => {
                                                   this.setState({
                                                      activeTab: "2",
                                                   });
                                                }}
                                             >
                                                {this.state.activeTab ===
                                                "2" ? (
                                                   <b>Secondary</b>
                                                ) : (
                                                   "Secondary"
                                                )}
                                             </TabNav>
                                          </NavItem>
                                       ) : null}
                                    </Nav>
                                    <TabContent
                                       activeTab={this.state.activeTab}
                                       style={{ padding: 10 }}
                                    >
                                       <TabPane tabId="1">
                                          {apexStatus.investigations[0].sketchId
                                             .result.equifaxResult.results
                                             .reject &&
                                          Object.keys(
                                             apexStatus.investigations[0]
                                                .sketchId.result.equifaxResult
                                                .results.reject
                                          ).length
                                             ? Object.keys(
                                                  apexStatus.investigations[0]
                                                     .sketchId.result
                                                     .equifaxResult.results
                                                     .reject
                                               ).map(key => (
                                                  <p>
                                                     {
                                                        apexStatus
                                                           .investigations[0]
                                                           .sketchId.result
                                                           .equifaxResult
                                                           .results.reject[key]
                                                     }
                                                  </p>
                                               ))
                                             : " Not available"}
                                       </TabPane>
                                       {apexStatus.investigations[1] &&
                                       apexStatus.investigations[1].sketchId
                                          .result &&
                                       apexStatus.investigations[1].sketchId
                                          .result.equifaxResult &&
                                       apexStatus.investigations[1].sketchId
                                          .result.equifaxResult.results
                                          .reject &&
                                       Object.keys(
                                          apexStatus.investigations[1].sketchId
                                             .result.equifaxResult.results
                                             .reject
                                       ).length ? (
                                          <TabPane tabId="2">
                                             {apexStatus.investigations[1]
                                                .sketchId.result.equifaxResult
                                                .results.reject &&
                                             Object.keys(
                                                apexStatus.investigations[1]
                                                   .sketchId.result
                                                   .equifaxResult.results.reject
                                             ).length
                                                ? Object.keys(
                                                     apexStatus
                                                        .investigations[1]
                                                        .sketchId.result
                                                        .equifaxResult.results
                                                        .reject
                                                  ).map(key => (
                                                     <p>
                                                        {
                                                           apexStatus
                                                              .investigations[1]
                                                              .sketchId.result
                                                              .equifaxResult
                                                              .results.reject[
                                                              key
                                                           ]
                                                        }
                                                     </p>
                                                  ))
                                                : " Not available"}
                                          </TabPane>
                                       ) : null}
                                    </TabContent>
                                 </Alert>
                              )}
                           </Fragment>
                        )}

                     <div className="d-flex justify-content-between align-item-center">
                        {location.pathname !== "/settings/profile" &&
                           accountStatus && (
                              <span className={style.name}>
                                 {firstName} {lastName}
                              </span>
                           )}
                     </div>
                     <Switch>
                        <PrivateRoute
                           path="/settings/profile"
                           component={Profile}
                        />
                     </Switch>
                  </div>
               </div>
               <InnerPageFooter
                  style={{
                     width: "100%",
                     background: "inherit",
                     borderTop: "1px solid #d5cbcb",
                     textAlign: "center",
                     fontFamily: "Open Sans",
                     fontSize: "12px",
                     padding: ".4em",
                     zIndex: 1000,
                  }}
               />
            </div>
         </>
      );
   }
}

const mapStateToProps = state => ({
   location: state.router.location,
   isTermsAgreed: state.auth.termsAgreed,
   isNewUser: state.auth.newUser,
   loggedIn: state.auth.loggedIn,
   planName: state.auth.user.planName,
   loggingIn: state.auth.loggingIn,
   useremail: state.auth.user.email,
   firstName: state.profile.profile.first_name,
   lastName: state.profile.profile.last_name,
   logo: state.profile.logo,
   apex: state.profile.profile.apex,
   f_name: state.profile.update.fname,
   l_name: state.profile.update.lname,
   isEdit: state.profile.update.isEdit,
});

const mapDispatchToProps = {
   logoutHandler: logout,
   changeFname,
   changeLname,
   toggleEdit,
   setUpdate,
   profilePhotoHideUnhideRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
