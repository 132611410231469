import React, { useState } from "react";
import { Col } from "antd";
import {
   StyledModal,
   StyledHeading,
   ButtonCol,
   CancelButton,
   SubmitButton,
   StyledBody,
   StyledCard,
   StyledCheckBox,
} from "./styles";
import { Checkbox } from "antd";
import { AssetSubscriptionModalType } from "types/globalModal";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useDispatch } from "react-redux";
import { updateAssetSponsorOptRequest } from "actions/community";
import { addNewEventsToAnalytics } from "Utils";

type AssetSubscriptionModalProps = {
   show: boolean;
   hideModal: Function;
   modalProps: AssetSubscriptionModalType;
};

const modalTitle = {
   fundRating: "Rating saved!",
   portfolio: "Changes saved!",
   sponsoredArticles: "Sponsored Insights",
   discoverySearch: "Sponsored Insights",
   fundComments: "Sponsored Insights",
};

const AssetSubscriptionModal = ({
   show,
   hideModal,
   modalProps,
}: AssetSubscriptionModalProps) => {
   const dispatch = useDispatch();
   const { tickers, source } = modalProps;
   const isConsent = tickers && tickers.length > 0 && tickers[0].is_consent;

   const allTickers = tickers.map(({ ticker }) => ticker);
   const [checkedValues, setCheckedValues] =
      useState<CheckboxValueType[]>(allTickers);
   const isSingleSponsor = tickers.length === 1;

   const onCancelClick = () => {
      const data = allTickers.map(ticker => ({
         ticker,
         consent: false,
      }));
      dispatch(updateAssetSponsorOptRequest({ data }));
      data.forEach(item =>
         addNewEventsToAnalytics("User Consent", {
            ticker: item.ticker,
            consent: item.consent,
            source,
         })
      );

      hideModal();
   };

   const onSubmitClick = () => {
      const data = allTickers.map(ticker => ({
         ticker,
         consent: checkedValues.includes(ticker),
      }));
      dispatch(updateAssetSponsorOptRequest({ data }));
      data.forEach(item =>
         addNewEventsToAnalytics("User Consent", {
            ticker: item.ticker,
            consent: item.consent,
            source,
         })
      );
      hideModal();
   };

   const onCheckBoxClick = (checkedValues: CheckboxValueType[]) => {
      setCheckedValues(checkedValues);
   };
   const CheckBoxGroupProps = {
      defaultValue: allTickers,
      onChange: onCheckBoxClick,
   };
   return (
      <StyledModal
         centered
         visible={show}
         footer={null}
         closable={false}
         wrapClassName={"remove-popup"}
      >
         <Col xs={24} style={{ display: "flex" }}>
            <StyledHeading>{modalTitle[source]}</StyledHeading>
         </Col>
         <Col xs={24}>
            <StyledBody>
               {!isConsent
                  ? `Would you like to receive more information from ${
                       isSingleSponsor ? "this" : "these"
                    } asset manager${isSingleSponsor ? "" : "s"}?`
                  : "You have already subscribed to this asset manager"}
            </StyledBody>
         </Col>
         <Col xs={24}>
            <StyledCard>
               {isSingleSponsor ? (
                  <span>{tickers[0].sponsor}</span>
               ) : (
                  <Checkbox.Group {...CheckBoxGroupProps}>
                     {tickers.map(({ sponsor, ticker }) => (
                        <Col span={24} style={{ marginBottom: "7px" }}>
                           <StyledCheckBox defaultChecked={true} value={ticker}>
                              {sponsor}
                           </StyledCheckBox>
                        </Col>
                     ))}
                  </Checkbox.Group>
               )}
            </StyledCard>
         </Col>
         <ButtonCol xs={24}>
            {!isConsent ? (
               <>
                  <CancelButton
                     className="mr-4"
                     onClick={onCancelClick}
                     disabled={
                        !isSingleSponsor &&
                        checkedValues.length < allTickers.length &&
                        checkedValues.length !== 0
                     }
                  >
                     Not Right Now
                  </CancelButton>
                  <SubmitButton
                     onClick={onSubmitClick}
                     disabled={checkedValues.length === 0}
                  >
                     Yes, Receive Information
                  </SubmitButton>
               </>
            ) : (
               <CancelButton className="mr-4" onClick={() => hideModal()}>
                  Close
               </CancelButton>
            )}
         </ButtonCol>
      </StyledModal>
   );
};

export default AssetSubscriptionModal;
