import React from "react";
import {
   Dropdown,
   DropdownToggle,
   DropdownMenu,
   DropdownItem,
} from "reactstrap";
import { countryList } from "../layouts/WebDashboard/Constant/FormData";
import { toLower } from "lodash";
import "cleave.js/dist/addons/cleave-phone.i18n.js";
import Cleave from "cleave.js/react";
import InputMask from "react-input-mask";
import "../style/components/_phone-number-input.scss";

export default class PhoneNumberInput extends React.Component {
   state = {
      phone_code_input: this.props.values[this.props.phoneCodeName]
         ? this.props.values[this.props.phoneCodeName]
         : "+1",
      phoneCodeState: false,
   };

   componentDidUpdate = (prevProps, prevState) => {
      if (
         this.props.values[this.props.phoneCodeName] &&
         prevProps.values[this.props.phoneCodeName] !==
            this.props.values[this.props.phoneCodeName]
      ) {
         this.setState({
            phone_code_input: this.props.values[this.props.phoneCodeName],
         });
      }
   };

   phoneCodeChange = e => {
      this.setState({
         [e.target.name]: e.target.value,
      });
   };

   togglePhoneCode = () => {
      this.setState({
         phoneCodeState: !this.state.phoneCodeState,
      });
   };

   handleClick = code => {
      const { onChangePhoneCode } = this.props;
      this.setState({
         phone_code_input: code,
      });
      onChangePhoneCode(code);
   };

   render() {
      // console.log(this.props, '>>>>>>')
      const {
         errors,
         touched,
         handleChange,
         handleBlur,
         values,
         phoneCodeName,
         phoneName,
         hideCode,
         phoneId,
      } = this.props;
      return (
         <div className="phone-input-container">
            {hideCode ? null : (
               <Dropdown
                  className={
                     errors[phoneCodeName] && touched[phoneCodeName]
                        ? "is-invalid dropdown-wrapper code-input"
                        : "dropdown-wrapper code-input"
                  }
                  isOpen={this.state.phoneCodeState}
                  toggle={this.togglePhoneCode}
               >
                  <DropdownToggle
                     value={values[phoneCodeName]}
                     style={{ backgroundColor: "#fff" }}
                     className={
                        errors[phoneCodeName] && touched[phoneCodeName]
                           ? "is-invalid input-area"
                           : "input-area"
                     }
                     caret
                  >
                     <input
                        type="search"
                        placeholder="Select"
                        name="phone_code_input"
                        value={this.state.phone_code_input}
                        onChange={this.phoneCodeChange}
                        className={
                           errors[phoneName] && touched[phoneName]
                              ? "is-invalid input-area form-control phone-input"
                              : "input-area form-control phone-input"
                        }
                        // className="typeahead-field"
                        autocomplete="off"
                        autoComplete={false}
                        style={{ padding: 0, height: `20px` }}
                     />
                  </DropdownToggle>
                  <DropdownMenu>
                     {countryList.map(item => {
                        if (
                           !this.state.phone_code_input ||
                           toLower(item.dial_code).includes(
                              toLower(this.state.phone_code_input)
                           )
                        ) {
                           return (
                              <DropdownItem
                                 value={item.dial_code}
                                 onClick={() =>
                                    this.handleClick(item.dial_code)
                                 }
                              >
                                 {item.flag}
                                 {item.dial_code}
                              </DropdownItem>
                           );
                        }
                        return null;
                     })}
                  </DropdownMenu>
               </Dropdown>
            )}
            {hideCode ? (
               <InputMask
                  mask="(999) 999-9999"
                  maskChar={null}
                  // placeholder="Phone Number"
                  value={values[phoneName]}
                  onBlur={handleBlur}
                  onChange={e => {
                     let value = e.target.value;
                     value = value.replace("(", "");
                     value = value.replace(")", "");
                     value = value.replace("-", " ");
                     e.target.value = value;
                     handleChange(e);
                  }}
                  id={phoneId ? phoneId : "phoneNumber"}
                  name={phoneName}
                  className={
                     errors[phoneName] && touched[phoneName]
                        ? `is-invalid input-area form-control`
                        : `input-area form-control`
                  }
               />
            ) : (
               <Cleave
                  placeholder="Phone Number"
                  options={{
                     phone: true,
                     phoneRegionCode: "US",
                  }}
                  value={values[phoneName]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                     errors[phoneName] && touched[phoneName]
                        ? `is-invalid input-area form-control phone-input`
                        : `input-area form-control phone-input`
                  }
                  id={phoneId ? phoneId : "phoneNumber"}
                  name={phoneName}
               />
            )}
         </div>
      );
   }
}
