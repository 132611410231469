import { createSelector } from "reselect";
import get from "lodash/get";
import orderBy from "lodash/orderBy";

export const getAllBuilPortfolioStates = state => state.buildportfolio;
export const getEquity = state => state.buildportfolio.equityTab;
export const getBonds = state => state.buildportfolio.bondTab;
export const getThematic = state => state.buildportfolio.thematicTab;
export const getEquitySelectedFunds = buildportfolio =>
   buildportfolio.equityTab.selectedFunds;
export const getBondSelectedFunds = buildportfolio =>
   buildportfolio.bondTab.selectedFunds;
export const getThemeSelectedFunds = buildportfolio =>
   buildportfolio.thematicTab.selectedFunds;

export const getEquityTabStates = createSelector([getEquity], equityTab => {
   return equityTab;
});

export const getBondsTabStates = createSelector([getBonds], bondTab => {
   return bondTab;
});

export const getThematicTabStates = createSelector(
   [getThematic],
   thematicTab => {
      return thematicTab;
   }
);

export const getSelectedFundList = createSelector(
   [getEquitySelectedFunds, getBondSelectedFunds, getThemeSelectedFunds],
   (equitySelectedFunds, bondSelectedFunds, themeSelectedFunds) => {
      let fundList = [
         { ...equitySelectedFunds },
         { ...bondSelectedFunds },
         { ...themeSelectedFunds },
      ];

      const list = [];
      fundList.forEach(item => {
         if (item) {
            Object.keys(item).forEach(v => {
               list.push(...item[v]);
            });
         }
      });
      return orderBy(list, ["_sliderValue"], ["desc"]);
   }
);

export const createLoadingSelector = actions => state => {
   return actions.some(action => get(state, `loading.${action}`));
};

export const getBuilderItems = state => state.buildportfolio.resultPageCart;

// usage
// const mapStateToProps = ({ loading }) => ({
//   isLogoutFetching: createLoadingSelector(['LOGOUT'])({ loading }),
// });
