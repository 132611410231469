import ActionTypes from "../action-types";

export function updatePremiumState(payload) {
   return {
      type: ActionTypes.UPDATE_PREMIUM_STATE,
      payload,
   };
}

export function setAuthState(payload) {
   return {
      type: ActionTypes.SET_AUTH_STATE,
      payload,
   };
}

export function auth(payload, callback) {
   return {
      type: ActionTypes.AUTH_REQUEST,
      payload,
      callback,
   };
}

export function authFetch(payload) {
   return {
      type: ActionTypes.AUTH_FETCH,
      payload,
   };
}

export function successfulAuth(payload) {
   return {
      type: ActionTypes.AUTH_SUCCESS,
      payload,
   };
}

export function failedAuth(error) {
   return {
      type: ActionTypes.AUTH_FAILURE,
      error,
   };
}

export function authGoogle(payload) {
   return {
      type: ActionTypes.GOOGLE_AUTH_REQUEST,
      payload,
   };
}

export function authApple(payload) {
   return {
      type: ActionTypes.APPLE_AUTH_REQUEST,
      payload,
   };
}

export function successfulGoogleAuth(response) {
   return {
      type: ActionTypes.GOOGLE_AUTH_SUCCESS,
      response,
   };
}

export function successfulAppleAuth(response) {
   return {
      type: ActionTypes.APPLE_AUTH_SUCCESS,
      response,
   };
}

export function failedGoogleAuth(error) {
   return {
      type: ActionTypes.GOOGLE_AUTH_FAILURE,
      error,
   };
}

export function failedAppleAuth(error) {
   return {
      type: ActionTypes.APPLE_AUTH_FAILURE,
      error,
   };
}

export function termsAgreementRequest(payload) {
   return {
      type: ActionTypes.TERMS_AGREEMENT_REQUEST,
      payload,
   };
}

export function termsAgreementFetch(payload) {
   return {
      type: ActionTypes.TERMS_AGREEMENT_FETCH,
      payload,
   };
}

export function termsAgreementSuccess(payload) {
   return {
      type: ActionTypes.TERMS_AGREEMENT_SUCCESS,
      payload,
   };
}

export function termsAgreementFailed(error) {
   return {
      type: ActionTypes.TERMS_AGREEMENT_FAILURE,
      error,
   };
}

export function logout(payload) {
   return {
      type: ActionTypes.LOGOUT_REQUEST,
      payload,
   };
}

export function successfulLogout(response) {
   return {
      type: ActionTypes.LOGOUT_SUCCESS,
      response,
   };
}

export function failedLogout(error) {
   return {
      type: ActionTypes.LOGOUT_FAILURE,
      error,
   };
}

export function sendChangePwdEmail(payload, callback) {
   return {
      type: ActionTypes.SEND_CHANGE_PWD_EMAIL,
      payload,
      callback,
   };
}

export function verifyChangePwdToken(payload, callback) {
   return {
      type: ActionTypes.VERIFY_CHANGE_PWD_TOKEN,
      payload,
      callback,
   };
}

export function setOldPassword(payload) {
   return {
      type: ActionTypes.SET_OLD_PASSWORD,
      payload,
   };
}

export function changePassword(payload) {
   return {
      type: ActionTypes.FORGOT_CHANGE_PASSWORD,
      payload,
   };
}

export function saveverifyChangePwdToken(payload) {
   return {
      type: ActionTypes.SAVE_VERIFY_CHANGE_PWD_TOKEN,
      payload,
   };
}

export function successfulSetPassword(response) {
   return {
      type: ActionTypes.SAVE_SET_PASSWORD,
      response,
   };
}

export function successfulSetAdvisorData(response) {
   return {
      type: ActionTypes.SET_ADVISOR_DATA,
      response,
   };
}

export function selectDomainToRedirect(payload) {
   return {
      type: ActionTypes.SELECT_DOMAIN_TO_REDIRECT,
      payload,
   };
}

/* SHARING ALPHA ACTIONS */
// --------------------------------------------------------------------------------

// CHECK IF URL IS EXPIRED OR NOT
export function validateLinkRequest(payload, callback) {
   return {
      type: ActionTypes.VALIDATE_LINK_REQUEST,
      payload,
      callback,
   };
}

// OKTA AUTH LOGIN
export function oktaAuthLoginRequest(payload, callback) {
   return {
      type: ActionTypes.OKTA_AUTH_LOGIN_REQUEST,
      payload,
      callback,
   };
}

export function validateLinkFetch(payload) {
   return {
      type: ActionTypes.VALIDATE_LINK_FETCH,
      payload,
   };
}

export function validateLinkSuccess(payload) {
   return {
      type: ActionTypes.VALIDATE_LINK_SUCCESS,
      payload,
   };
}

// CHECK IF URL IS EXPIRED OR NOT
export function sendEmailInviteRequest(payload, callback) {
   return {
      type: ActionTypes.SEND_EMAIL_INVITE_REQUEST,
      payload,
      callback,
   };
}

export function sendEmailInviteFetch(payload) {
   return {
      type: ActionTypes.SEND_EMAIL_INVITE_FETCH,
      payload,
   };
}

export function sendEmailInviteSuccess(payload) {
   return {
      type: ActionTypes.SEND_EMAIL_INVITE_SUCCESS,
      payload,
   };
}

// CHECK IF URL IS EXPIRED OR NOT
export function autoLoginUserRequest(payload, callback) {
   return {
      type: ActionTypes.AUTO_LOGIN_USER_REQUEST,
      payload,
      callback,
   };
}

export function autoLoginUserFetch(payload) {
   return {
      type: ActionTypes.AUTO_LOGIN_USER_FETCH,
      payload,
   };
}

export function oktaAuthLoginFetch(payload) {
   return {
      type: ActionTypes.OKTA_AUTH_LOGIN_FETCH,
      payload,
   };
}

export function oktaAuthLoginSuccess(payload) {
   return {
      type: ActionTypes.OKTA_AUTH_LOGIN_SUCCESS,
      payload,
   };
}

export function autoLoginUserSuccess(payload) {
   return {
      type: ActionTypes.AUTO_LOGIN_USER_SUCCESS,
      payload,
   };
}

export function setOktaAuthLoader(payload) {
   return {
      type: ActionTypes.SET_OKTA_AUTH_LOADER,
      payload,
   };
}

export function setCalendarInviteModalState(payload) {
   return {
      type: ActionTypes.SET_CALENDAR_INVITE_MODAL_STATE,
      payload,
   };
}

// AUTO-LOGIN BY TOKEN
export function autoLoginByTokenRequest(payload, callback) {
   return {
      type: ActionTypes.AUTO_LOGIN_BY_TOKEN_REQUEST,
      payload,
      callback,
   };
}

export function autoLoginByTokenFetch(payload) {
   return {
      type: ActionTypes.AUTO_LOGIN_BY_TOKEN_FETCH,
      payload,
   };
}

export function autoLoginByTokenSuccess(payload) {
   return {
      type: ActionTypes.AUTO_LOGIN_BY_TOKEN_SUCCESS,
      payload,
   };
}

// REFRESH AUTH STATE
export function refreshAuthState(payload) {
   return {
      type: ActionTypes.REFRESH_AUTH_STATE,
      payload,
   };
}

// AUTO-LOGIN BY TOKEN
export function oktaChangePasswordRequest(payload, callback) {
   return {
      type: ActionTypes.OKTA_CHANGE_PASSWORD_REQUEST,
      payload,
      callback,
   };
}

export function oktaChangePasswordFetch(payload) {
   return {
      type: ActionTypes.OKTA_CHANGE_PASSWORD_FETCH,
      payload,
   };
}

export function oktaChangePasswordSuccess(payload) {
   return {
      type: ActionTypes.OKTA_CHANGE_PASSWORD_SUCCESS,
      payload,
   };
}

// RESEND FORGET PASSWORD EMAIL
export function resendPasswordResetEmailRequest(payload, callback) {
   return {
      type: ActionTypes.RESEND_PASSWORD_RESET_EMAIL_REQUEST,
      payload,
      callback,
   };
}

export function resendPasswordResetEmailFetch(payload) {
   return {
      type: ActionTypes.RESEND_PASSWORD_RESET_EMAIL_FETCH,
      payload,
   };
}

export function resendPasswordResetEmailSuccess(payload) {
   return {
      type: ActionTypes.RESEND_PASSWORD_RESET_EMAIL_SUCCESS,
      payload,
   };
}

export function userSourceCheckRequest(payload) {
   return {
      type: ActionTypes.USER_SOURCE_CHECK_REQUEST,
      payload,
   };
}

export function userSourceCheckSuccess(payload) {
   return {
      type: ActionTypes.USER_SOURCE_CHECK_SUCCESS,
      payload,
   };
}

export function userSourceCheckReset() {
   return {
      type: ActionTypes.USER_SOURCE_CHECK_RESET,
   };
}
