import { createAPIInstance } from "./api";

export function fetchAlertsList(query) {
   return createAPIInstance().get(`user/apex/get/notification?${query}`);
}

export function updateAlertApi(query, payload) {
   return createAPIInstance().post(
      `user/apex/set/notification/state?${query}`,
      payload,
      {
         headers: { "Content-Type": "application/json" },
      }
   );
}
