import ActionTypes from "../action-types";

/* SELECT PORTFOLIO TO LOAD */
export function selectPortfolioToLoad(payload) {
   return {
      type: ActionTypes.SELECT_PORTFOLIO_TO_LOAD,
      payload,
   };
}

/* SELECTOR WITH TIME FRAME */
export function getFundDiscoverySearchRequest(payload, callback) {
   return {
      type: ActionTypes.GET_FUND_DISCOVERY_SEARCH_REQUEST,
      payload,
      callback,
   };
}
export function getFundDiscoverySearchReset() {
   return {
      type: ActionTypes.GET_FUND_DISCOVERY_SEARCH_RESET,
   };
}

export function getFundDiscoverySearchFetch(payload) {
   return {
      type: ActionTypes.GET_FUND_DISCOVERY_SEARCH_FETCH,
      payload,
   };
}

export function getFundDiscoverySearchSuccess(payload) {
   return {
      type: ActionTypes.GET_FUND_DISCOVERY_SEARCH_SUCCESS,
      payload,
   };
}

/* GET 'YOUR PORTFOLIO' FOR ASSET ALLOCATION PAGE */
export function getYourPortfolioRequest(payload, callback) {
   return {
      type: ActionTypes.GET_YOUR_PORFOLIO_REQUEST,
      payload,
      callback,
   };
}

export function getYourPortfolioFetch(payload) {
   return {
      type: ActionTypes.GET_YOUR_PORFOLIO_FETCH,
      payload,
   };
}

export function getYourPortfolioSuccess(payload) {
   return {
      type: ActionTypes.GET_YOUR_PORFOLIO_SUCCESS,
      payload,
   };
}

/* GET TOP RANKED MEMBERS DATA */
export function getTopRankedMembersRequest(payload) {
   return {
      type: ActionTypes.GET_TOP_RANKED_MEMBERS_REQUEST,
      payload,
   };
}

export function getTopRankedMembersFetch(payload) {
   return {
      type: ActionTypes.GET_TOP_RANKED_MEMBERS_FETCH,
      payload,
   };
}

export function getTopRankedMembersSuccess(payload) {
   return {
      type: ActionTypes.GET_TOP_RANKED_MEMBERS_SUCCESS,
      payload,
   };
}

// Edit portfolio OnSubmit Click
export function setUpdatedPortfolioDataRequest(payload, callback) {
   return {
      type: ActionTypes.SET_UPDATED_PORTFOLIO_DATA_REQUEST,
      payload,
      callback,
   };
}
export function setUpdatedPortfolioDataSubmit(payload) {
   return {
      type: ActionTypes.SET_UPDATED_PORTFOLIO_DATA_SUBMIT,
      payload,
   };
}
export function setUpdatedPortfolioDataSuccess(payload) {
   return {
      type: ActionTypes.SET_UPDATED_PORTFOLIO_DATA_SUCCESS,
      payload,
   };
}

/* GET USER RANKING HISTORY DATA */
export function getUserRankingHistoryRequest(payload, callback) {
   return {
      type: ActionTypes.GET_USER_RANKING_HISTORY_REQUEST,
      payload,
      callback,
   };
}

export function getUserRankingHistoryFetch(payload) {
   return {
      type: ActionTypes.GET_USER_RANKING_HISTORY_FETCH,
      payload,
   };
}

export function getUserRankingHistorySuccess(payload) {
   return {
      type: ActionTypes.GET_USER_RANKING_HISTORY_SUCCESS,
      payload,
   };
}

/* GET USER RATING */
export function getUserRatingsRequest(payload, callback) {
   return {
      type: ActionTypes.GET_USER_RATINGS_REQUEST,
      payload,
      callback,
   };
}

export function getUserRatingsFetch(payload) {
   return {
      type: ActionTypes.GET_USER_RATINGS_FETCH,
      payload,
   };
}

export function getUserRatingsSuccess(payload) {
   return {
      type: ActionTypes.GET_USER_RATINGS_SUCCESS,
      payload,
   };
}

/*ADD NEW FUND RATING */
export function addNewFundRatingRequest(payload, callback) {
   return {
      type: ActionTypes.ADD_NEW_FUND_RATING_REQUEST,
      payload,
      callback,
   };
}

export function addNewFundRatingFetch(payload) {
   return {
      type: ActionTypes.ADD_NEW_FUND_RATING_FETCH,
      payload,
   };
}

export function addNewFundRatingSuccess(payload) {
   return {
      type: ActionTypes.ADD_NEW_FUND_RATING_SUCCESS,
      payload,
   };
}

/*GET USER FUND RATING */
export function getUserFundRatingRequest(payload, callback) {
   return {
      type: ActionTypes.GET_USER_FUND_RATING_REQUEST,
      payload,
      callback,
   };
}

export function getUserFundRatingFetch(payload, callback) {
   return {
      type: ActionTypes.GET_USER_FUND_RATING_FETCH,
      payload,
      callback,
   };
}

export function getUserFundRatingSuccess(payload) {
   return {
      type: ActionTypes.GET_USER_FUND_RATING_SUCCESS,
      payload,
   };
}

/*UPDATE USER FUND RATING */
export function updateUserFundRatingFetch(payload, callback) {
   return {
      type: ActionTypes.UPDATE_USER_FUND_RATING_FETCH,
      payload,
      callback,
   };
}
export function updateUserFundRatingRequest(payload, callback) {
   return {
      type: ActionTypes.UPDATE_USER_FUND_RATING_REQUEST,
      payload,
      callback,
   };
}

export function updateUserFundRatingSuccess(payload) {
   return {
      type: ActionTypes.UPDATE_USER_FUND_RATING_SUCCESS,
      payload,
   };
}

/*DELETE USER FUND RATING */
export function removeUserFundRatingFetch(payload, callback) {
   return {
      type: ActionTypes.REMOVE_USER_FUND_RATING_FETCH,
      payload,
      callback,
   };
}

export function removeUserFundRatingRequest(payload, callback) {
   return {
      type: ActionTypes.REMOVE_USER_FUND_RATING_REQUEST,
      payload,
      callback,
   };
}

export function removeUserFundRatingSuccess(payload) {
   return {
      type: ActionTypes.REMOVE_USER_FUND_RATING_SUCCESS,
      payload,
   };
}

//data from performance card
export function getYourRankDataRequest(payload) {
   return {
      type: ActionTypes.GET_USER_RANKING_DATA_REQUEST,
      payload,
   };
}

export function getYourRankDataFetch(payload) {
   return {
      type: ActionTypes.GET_USER_RANKING_DATA_FETCH,
      payload,
   };
}

export function getYourRankDataSuccess(payload) {
   return {
      type: ActionTypes.GET_USER_RANKING_DATA_SUCCESS,
      payload,
   };
}

/*GET USER SEARCH FUND RATING */
export function getUserSearchFundRatingRequest(payload, callback) {
   return {
      type: ActionTypes.GET_USER_SEARCH_FUND_RATING_REQUEST,
      payload,
      callback,
   };
}

export function getUserSearchFundRatingFetch(payload, callback) {
   return {
      type: ActionTypes.GET_USER_SEARCH_FUND_RATING_FETCH,
      payload,
      callback,
   };
}

export function getUserSearchFundRatingSuccess(payload) {
   return {
      type: ActionTypes.GET_USER_SEARCH_FUND_RATING_SUCCESS,
      payload,
   };
}
// RESET FUND RATING
export function resetFundRating() {
   return {
      type: ActionTypes.RESET_FUND_RATING,
   };
}

// RESET COMMUNITY STATE TO INITIAL STATE
export function resetCommunityState(payload, callback) {
   return {
      type: ActionTypes.RESET_COMMUNITY_STATE,
      payload,
      callback,
   };
}

export function updateAllocationFundData(payload, callback) {
   return {
      type: ActionTypes.UPDATE_ALLOCATION_FUND_DATA,
      payload,
      callback,
   };
}

/* Commentary  */
export function getUserCommentsRequest(payload, callback) {
   return {
      type: ActionTypes.GET_USER_COMMENTS_REQUEST,
      payload,
      callback,
   };
}

export function getUserCommentsFetch(payload) {
   return {
      type: ActionTypes.GET_USER_COMMENTS_FETCH,
      payload,
   };
}

export function getUserCommentsSuccess(payload) {
   return {
      type: ActionTypes.GET_USER_COMMENTS_SUCCESS,
      payload,
   };
}

export function getFundCommentsDataRequest(payload) {
   return {
      type: ActionTypes.GET_FUND_COMMENTS_DATA_REQUEST,
      payload,
   };
}

export function getFundCommentDataFetch(payload) {
   return {
      type: ActionTypes.GET_FUND_COMMENTS_DATA_FETCH,
      payload,
   };
}

export function getFundCommentDataSuccess(payload) {
   return {
      type: ActionTypes.GET_FUND_COMMENTS_DATA_SUCCESS,
      payload,
   };
}

export function postFundCommentRequest(payload) {
   return {
      type: ActionTypes.POST_FUND_COMMENT_REQUEST,
      payload,
   };
}

export function postFundCommentFetch(payload) {
   return {
      type: ActionTypes.POST_FUND_COMMENT_FETCH,
      payload,
   };
}

export function postFundCommentSuccess(payload) {
   return {
      type: ActionTypes.POST_FUND_COMMENT_SUCCESS,
      payload,
   };
}

export function deleteFundReplySuccess(payload) {
   return {
      type: ActionTypes.DELETE_FUND_REPLY_SUCCESS,
      payload,
   };
}

export function deleteFundCommentSuccess(payload) {
   return {
      type: ActionTypes.DELETE_FUND_COMMENT_SUCCESS,
      payload,
   };
}

export function postFundReplySuccess(payload) {
   return {
      type: ActionTypes.POST_FUND_REPLY_SUCCESS,
      payload,
   };
}

export function voteFundCommentRequest(payload) {
   return {
      type: ActionTypes.ADD_FUND_COMMENT_VOTE_REQUEST,
      payload,
   };
}

export function voteFundCommentFetch(payload) {
   return {
      type: ActionTypes.ADD_FUND_COMMENT_VOTE_FETCH,
      payload,
   };
}

export function voteFundCommentSuccess(payload) {
   return {
      type: ActionTypes.ADD_FUND_COMMENT_VOTE_SUCCESS,
      payload,
   };
}

export function voteFundCommentError(payload) {
   return {
      type: ActionTypes.ADD_FUND_COMMENT_VOTE_ERROR,
      payload,
   };
}

/* Asset Sponsor Opt  */
export function getAssetSponsorOptRequest(payload, callback) {
   return {
      type: ActionTypes.GET_SPONSOR_OPT_REQUEST,
      payload,
      callback,
   };
}

export function getAssetSponsorOptFetch() {
   return {
      type: ActionTypes.GET_SPONSOR_OPT_FETCH,
   };
}
export function getAssetSponsorOptSuccess(payload) {
   return {
      type: ActionTypes.GET_SPONSOR_OPT_SUCCESS,
      payload,
   };
}

export function updateAssetSponsorOptRequest(payload) {
   return {
      type: ActionTypes.POST_SPONSOR_OPT_REQUEST,
      payload,
   };
}
export function updateAssetSponsorOptFetch(payload) {
   return {
      type: ActionTypes.POST_SPONSOR_OPT_FETCH,
      payload,
   };
}
export function updateAssetSponsorOptSuccess(payload) {
   return {
      type: ActionTypes.POST_SPONSOR_OPT_SUCCESS,
      payload,
   };
}
