import { createAPIInstance } from "./api";
import axios from "axios";
import config from "../env";

export function submitFeedbackApi(payload, callback) {
   return axios({
      method: "GET",
      url: `${config.feedbackGooglesheetKey}`,
      params: payload,
   })
      .then(function (response) {
         return callback({ error: false, data: response.data });
      })
      .catch(function (err) {
         return callback({ error: err });
      });
}

//portfolio-builder-batch-search?query=pf_builder_bonds'
export function buildPortfolioBatchSearch(payload) {
   return createAPIInstance()
      .get("/portfolio-builder-batch-search", {
         params: payload,
      })
      .then(response => response.data);
}

export function getAllThemes(payload) {
   return createAPIInstance()
      .get("/get-all-themes", {
         params: payload,
      })
      .then(response => response.data);
}

export function thematicBatchSearch(payload) {
   return createAPIInstance()
      .get("/portfolio-builder-batch-search", {
         params: payload,
      })
      .then(response => response.data);
}

export function getAllTickers(payload) {
   return createAPIInstance()
      .post("/get-all-tickers", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function getFundData(name) {
   return createAPIInstance()
      .get(
         `/enhancement-card-data?ticker=${encodeURIComponent(
            name.ticker
         )}&overlap=${encodeURIComponent(name.overlap)}`
      )
      .then(res => res.data);
}

export function getFundDataCommaSeparated(tickers) {
   if (tickers && tickers !== "") {
      return createAPIInstance()
         .get(`/card-data?ticker=${encodeURIComponent(tickers)}`)
         .then(res => res.data);
   }
}

export function savePortfolio(payload) {
   return createAPIInstance()
      .post("/pf-builder-portfolio-save", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

// portfolio-builder-batch-search?query=pf_builder_query&q=ai%2Cfintech%2Cem%20no%20china
export function searchNewBatchQuery(payload) {
   return createAPIInstance()
      .get("/portfolio-builder-batch-search", {
         params: payload,
      })
      .then(response => response.data);
}

export function fetchPortfolioData(payload) {
   return createAPIInstance()
      .get("/pf-builder-ticker-category", {
         params: payload,
      })
      .then(response => response.data);
}

export function editPortfolio(payload) {
   return createAPIInstance()
      .patch("/user/edit-portfolio", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function uploadBuilderPortfolio(payload) {
   return createAPIInstance()
      .post("v1/pf-builder-ticker-category", payload, {
         headers: { "Content-Type": "application/json" },
      })
      .then(response => response.data);
}

export function downloadBuilderReport(payload) {
   return createAPIInstance()
      .post("/print-builder-report", payload, {
         responseType: "blob",
         headers: { "Content-Type": "application/json" },
      })
      .then(res => res);
}
