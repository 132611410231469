import { createAPIInstance } from "./api";
import qs from "query-string";

export function fetchBrokerList() {
   return createAPIInstance().get("v1/user/broker/list");
}

export function setupBroker(payload) {
   return createAPIInstance().post("user/broker/etrade/setup", payload);
}

export function setupTDBroker(payload, code) {
   return createAPIInstance().get(
      "user/broker/tdameritrade/setup?code=" + payload
   );
}

export function completeBrokerSetup(payload) {
   return createAPIInstance().get("user/broker/etrade/complete-setup", payload);
}

export function fetchBrokerAccounts() {
   return createAPIInstance().get("user/broker/etrade/accounts");
}

export function fetchTDBrokerAccounts() {
   return createAPIInstance().get("user/broker/tdameritrade/accounts");
}

export function fetchTradeItBrokerAccounts(brokerName) {
   return createAPIInstance().get(
      `user/broker/tradeit/accounts?broker=${brokerName}`
   );
}

export function importBrokerAccounts(payload, brokerName) {
   return createAPIInstance().post(
      `user/broker/${brokerName}/accounts/import`,
      payload,
      {
         headers: { "Content-Type": "application/json" },
      }
   );
}

export function fetchImportedAccounts() {
   return createAPIInstance().get("user/broker/accounts");
}

export function deleteImportedAccount(payload, brokerName) {
   return createAPIInstance().post(
      `user/broker/${brokerName}/accounts/remove`,
      qs.stringify(payload),
      {
         headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
   );
}

export function setDefaultAccount(payload, broker) {
   return createAPIInstance().post(
      `user/broker/${broker}/accounts/default`,
      qs.stringify(payload),
      {
         headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
   );
}

export function completeTDAmeritradeBrokerSetup(payload) {
   return createAPIInstance().get("user/broker/tdameritrade/setup", payload);
}

export function setupTradeItBroker(payload) {
   return createAPIInstance().get("user/broker/tradeit/setup" + payload);
}

export function completeTradeItBroker(payload) {
   return createAPIInstance().post(
      "user/broker/tradeit/complete-setup",
      qs.stringify(payload),
      {
         headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
   );
}

export function verifyTradeItSecurityQuestions(payload) {
   return createAPIInstance().post(
      "user/broker/tradeit/securityques",
      payload,
      {
         headers: { "Content-Type": "application/json" },
      }
   );
}
