import { takeEvery, fork, call, put, all } from "redux-saga/effects";
import qs from "query-string";
import get from "lodash/get";
import ActionTypes from "action-types";
import * as actions from "actions/alerts";
import * as accountsActions from "actions/accounts";
import * as AlertApis from "apis/alert";

function* getAlerts(action) {
   const { payload } = action;
   yield put(actions.setAlertLoading(true));
   try {
      const response = yield call(
         AlertApis.fetchAlertsList,
         qs.stringify(payload)
      );
      if (Array.isArray(response.data.result)) {
         yield put(actions.setAlertSuccess(response.data.result));
      } else {
         yield put(actions.setAlertError("No Alerts Yet!"));
      }
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      yield put(actions.setAlertError(errorDetails));
   }
   yield put(actions.setAlertLoading(false));
}

function* updateAlert(action) {
   const { payload } = action;
   yield put(actions.updateAlertLoading(true));
   try {
      // const response = yield call(
      //    AlertApis.updateAlertApi,
      //    qs.stringify(action.qs),
      //    payload
      // );
      yield put(actions.updateAlertSuccess(payload));
      yield put(accountsActions.refreshApexStateRequest());
   } catch (error) {
      const errorDetails = get(error, "response.data", error.stack);
      yield put(actions.updateAlertError(errorDetails));
   }
   yield put(actions.updateAlertLoading(false));
}

function* alertSaga() {
   yield all([
      // fork(takeEvery, ActionTypes.GOOGLE_AUTH_SUCCESS, googleAuth),
      fork(takeEvery, ActionTypes.GET_ALERTS, getAlerts),
      fork(takeEvery, ActionTypes.UPDATE_ALERT, updateAlert),
   ]);
}

export default alertSaga;
