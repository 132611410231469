// import React from "react";
import moment from "moment";

import { categoryCol, cn } from "DataSet";
import {
   getQuintile,
   getNest,
   arrayToHash,
   monthToDate,
   dateToMonth,
   // getNestDefault,
} from "Utils";
import { calcStats } from "Stats";
import { lineChartStartDate } from "layouts/utils";

const dateRangeMonthList = range => {
   let out = []; // List of months
   let rangeEnd = moment(range.end);
   for (
      let m = moment(range.start);
      m.isSameOrBefore(rangeEnd);
      m.add(1, "months")
   ) {
      out.push(dateToMonth(m));
   }
   return out;
};

export const getFilteredListByRange = ({ items, catData, range }) => {
   const dd = moment(range.start);
   if (!dd.isValid()) {
      range = {
         start: moment(
            "01 " + moment(range.start, "MMM YYYY")._i,
            "DD MMM YYYY"
         ),
         end: moment("01 " + moment(range.end, "MMM YYYY")._i, "DD MMM YYYY"),
      };
   }

   const rangeFilter = e => {
      let dt = monthToDate(e.d);
      return (
         dt.isAfter(moment(range.start).subtract(1, "seconds")) &&
         dt.isBefore(moment(range.end).add(1, "seconds"))
      );
   };
   // const valTraslate = v => Math.log10((v+100)/100);
   const valTraslate = v =>
      v < 0 ? -1 * Math.log10((100 - v) / 100) : Math.log10((100 + v) / 100);

   let x = dateRangeMonthList(range);

   let catSum = {};
   catData.forEach(e => {
      let out = {},
         ysum = 0;
      e.returns.filter(rangeFilter).forEach(d => {
         ysum = (1 + ysum) * (1 + d.v) - 1;
         out[d.d] = ysum * 100;
      });
      catSum[e.name] = out;
   });

   // let rMin = 1e6,
   //    rMax = -1e6;
   let catDataH =
      catData && catData.length > 0 ? arrayToHash(catData, "name") : null;

   items = items.filter(e => !e._hidden);
   items.forEach(e => {
      if (e.returns.length > 0 && typeof e._stats === "undefined") {
         let points = e.returns.filter(rangeFilter);
         if (points.length <= 0) {
            e._hidden = true;
            return;
         }

         if (catDataH && catDataH !== null) {
            let cat = catDataH[categoryCol(cn(e, "Category"))];
            let cindex = cat.returns.findIndex(e => e.d === points[0].d);
            e._categorySeries = calcStats(cat.returns.slice(cindex));
            [
               "fees",
               "liquidity",
               "assets",
               "liquidity_min",
               "assets_min",
               "liquidity_max",
               "assets_max",
               "esgScore",
               "esgScore_min",
               "esgScore_max",
            ].forEach(a => (e._categorySeries[a] = cat[a] || 0.0));
         }

         const stats = calcStats(points);
         if (typeof stats.series !== "undefined") {
            let series = x.map(d => {
               let val = getNest([d, "cuml"], stats.series),
                  ival = val !== undefined ? valTraslate(val) : val,
                  cval =
                     ival !== undefined &&
                     e._categorySeries &&
                     e._categorySeries.series
                        ? valTraslate(
                             getNest([d, "cuml"], e._categorySeries.series)
                          )
                        : 0.0,
                  // opval = ival !== undefined ? valTraslate(getNest(['_opstats', 'series', d, 'cuml'], e)) : 0.0,
                  ival2 =
                     ival !== undefined
                        ? getNest([d, "ret"], stats.series)
                        : "Inactive";

               // const dyn = val !== undefined ? getNest(['_opstats', 'series', d, 'cuml'], e) : 0.0;

               return { x: d, y1: ival, y2: cval, y3: ival2, y4: val };
               // return { x: d, y1: ival, y2: cval, y3: ival2, y4: opval, label: ival2, val, dyn };
            });
            let stats2 = {
               cumlQ: e._categorySeries
                  ? getQuintile(stats.cuml, e._categorySeries.cuml)
                  : getQuintile(stats.cuml, stats.cuml),
               yRetQ: e._categorySeries
                  ? getQuintile(stats.yRet, e._categorySeries.yRet)
                  : getQuintile(stats.yRet, stats.yRet),
               voltQ: e._categorySeries
                  ? getQuintile(stats.volt, e._categorySeries.volt)
                  : getQuintile(stats.volt, stats.volt),
               dMaxQ: e._categorySeries
                  ? getQuintile(stats.dMax, e._categorySeries.dMax)
                  : getQuintile(stats.dMax, stats.dMax),
               start: x[0],
               end: x[x.length - 1],
            };
            e._stats = { ...stats, ...stats2, series };
            e._returnsChart = { ...stats2, series };
            if (
               e.start_date &&
               lineChartStartDate(e) &&
               lineChartStartDate(e)._tickerStartDate
            ) {
               e._tickerStartDate = lineChartStartDate(e)._tickerStartDate;
            }
         }
      }
   });

   return items;
};

export const variablesWithoutDateRange = [
   "Assets",
   "Fee",
   "Volume",
   "Tracking Error",
   "Yield",
   "Bid Ask spread",
   "Track record",
   // 'Premium to NAV',
   // 'Trend',
   // 'Fund Flows'
];

export const COMPLETED = "completed";
export const PENDING = "pending";
export const CANCELLED = "cancelled";
export const ERROR = "error";
export const UNASSIGNED = "unassigned";

export const dateRangeActiveObj = {
   return: "active", // 'l3y'
   risk: "active", // 'l3y'
   fees: "active", // 'l3y'
   trkerror: "active", // 'l3y'
   drawdown: "active", // 'l3y'
   weight_trend: "active", // 'l3y'
   weight_fund_flows: "active", // 'l3y'
   weight_premium_to_nav: "active", // 'l3y'
};

export default getFilteredListByRange;
