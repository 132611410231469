/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../env";
import { uniqBy, startCase } from "lodash";

export const API_ROOT = config.apiBaseUrl;

const headings = {
   investment_suggestions: "Investments",
   query_suggestions: "Searches",
};

const SearchSuggetions = props => {
   const [show, setShow] = useState(false);
   const [results, setResults] = useState(false);
   const [interval, changeInterval] = useState(null);

   useEffect(() => {
      if (interval) {
         clearTimeout(interval);
      }
      if (props.search) {
         let apiTocall = setTimeout(() => {
            suggestionApiCaller(props.search);
         }, 450);
         changeInterval(apiTocall);
      } else {
         setShow(false);
      }
   }, [props.search]);

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const handleClickOutside = event => {
      let wrapperRef = document.getElementById("search_container");
      if (wrapperRef && !wrapperRef.contains(event.target)) {
         setShow(false);
      }
   };

   const getUniverse = () => {
      let _universe = props.universe;

      // if ((window.innerWidth <= 1023) || (process.env.REACT_APP_SUB_DOMAIN === 'advisor')) {
      if (window.innerWidth <= 1023) {
         _universe = _universe.filter(
            item => item !== "share" || item !== "basket"
         );
      } else if (config.universeShare) {
         _universe.push("share");
         _universe = uniqBy(_universe);
      }
      _universe.push("basket");
      _universe = uniqBy(_universe);

      if (_universe.includes("udc")) {
         _universe = _universe.filter(item => item !== "udc");
         _universe = uniqBy(_universe).join();
      } else {
         _universe = uniqBy(_universe).join();
      }

      return _universe;
   };

   const suggestionApiCaller = async searchText => {
      axios({
         method: "GET",
         url: API_ROOT + `/search-queries-by-frequency`,
         params: {
            q: searchText,
            universe: getUniverse(),
         },
      })
         .then(function (response) {
            //handle success
            let hideAll = true;
            if (response.data && Object.keys(response.data).length) {
               let finalData = Object.keys(response.data).map(key => {
                  if (response.data[key].length) {
                     hideAll = false;
                  }
                  return {
                     category: headings[key]
                        ? headings[key]
                        : key.includes("_")
                        ? key
                             .split("_")
                             .map(string => startCase(string))
                             .join(" ")
                        : startCase(key),
                     items: response.data[key].map(el => {
                        return { text: el };
                     }),
                  };
               });
               if (!hideAll) {
                  setResults(finalData);
                  return setShow(true);
               }
               setResults([]);
               return setShow(false);
            }
         })
         .catch(function (err) {
            //handle error
            console.log(err, "handle error");
         });
   };

   const escapeRegExp = string => {
      return string.replace(/([.*+?^${}()|[\]\\])/g, "\\$1");
   };

   const handleElFocus = el => {
      let element = el.target;
      let dataAttr = +element.getAttribute("data-index");
      let parentEl = element.parentElement.parentElement.parentElement;
      let childElCount = element.parentElement.childElementCount;
      let currentElCount = Number(element.getAttribute("data-count"));

      if (el.key === "ArrowDown") {
         if (childElCount <= 6 || childElCount - currentElCount >= 6) {
            element.previousElementSibling &&
               element.previousElementSibling.scrollIntoView(false);
         }

         if (element.nextElementSibling) {
            element.nextElementSibling.focus();
         } else if (
            dataAttr + 1 < parentEl.children.length &&
            parentEl.children[dataAttr + 1].getElementsByClassName("child-item")
               .length
         ) {
            parentEl.children[dataAttr + 1]
               .getElementsByClassName("child-item")[0]
               .focus();
         }
      } else if (el.key === "ArrowUp") {
         if (childElCount <= 6 || childElCount - currentElCount >= 6) {
            element.nextElementSibling &&
               element.nextElementSibling.scrollIntoView(false);
         }

         if (
            element.previousElementSibling.className !== "heading" &&
            element.previousElementSibling
         ) {
            element.previousElementSibling.focus();
         } else if (
            dataAttr - 1 < parentEl.children.length &&
            dataAttr - 1 >= 0 &&
            parentEl.children.length > 1
         ) {
            parentEl.children[dataAttr - 1]
               .getElementsByClassName("child-item")
               [
                  parentEl.children[dataAttr - 1].getElementsByClassName(
                     "child-item"
                  ).length - 1
               ].focus();
         } else {
            document.getElementsByClassName("search-el")[0].focus();
         }
      } else if (el.key === "Enter") {
         props.queryHandler(el.target.innerText);
         setShow(false);
      }
   };

   if (!show) {
      return null;
   }

   return (
      <div className="suggestion_box" id="search_container">
         <div className="suggestion_container">
            {results.map((el, index) => {
               if (el.items.length === 0) {
                  return null;
               }
               return (
                  <div className="w-100">
                     <div className="list_item" tabIndex="0">
                        <p className="heading">{el.category}</p>
                        {el.items.map((item, j) => {
                           return (
                              <p
                                 className="child-item"
                                 tabIndex="-1"
                                 data-index={index}
                                 data-count={j}
                                 onKeyDown={handleElFocus}
                                 onClick={() => {
                                    props.queryHandler(item.text);
                                    setShow(false);
                                 }}
                                 dangerouslySetInnerHTML={{
                                    __html: item.text.replace(
                                       new RegExp(
                                          `\\b${escapeRegExp(props.search)}\\b`,
                                          "gi"
                                       ),
                                       str => "<b>" + str + "</b>"
                                    ),
                                 }}
                              ></p>
                           );
                        })}
                     </div>
                     {index !== results.length - 1 && (
                        <div className="w-100" style={{ padding: "0 10px" }}>
                           <div className="separator"></div>
                        </div>
                     )}
                  </div>
               );
            })}
         </div>
      </div>
   );
};

export default SearchSuggetions;
