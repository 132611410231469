import ActionTypes from "../action-types";

const initialState = {
   profile: {
      email: "",
      first_name: "",
      last_name: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      phone: "",
      country: "",
      gender: "",
      date_of_birth: null,
      hear_aboutus: "",
      investable_assets: "",
      self_role: "",
      securityquestion: null,
      securityquestionanswer: "",
      investing_exp: null,
      ssn_tin: null,
      apex: null,
      subscription: {
         subscription_id: "",
         subscriber_since: "",
         renews_on: "",
         cancels_on: "",
         cancellation_request_status: false,
      },
      isLoading: false,
      user_return_prefer: null,
      user_risk_prefer: null,
      show_similar_user_queries: true,
      non_vetted_user: true,
   },
   error: {},
   profileUpdateSuccess: false,
   profileUpdateFailed: false,
   changePasswordSuccess: false,
   changePasswordFailed: false,
   referralCode: "",
   usersList: [],
   inviteFriendsModal: false,
   stripeSessionStatus: false,
   stripeObject: "",
   stripeSessionID: "",
   unSubscribeUserStatus: false,
   unSubscriptionError: "",
   reactivateSubscriptionStatus: false,
   reactivateSubscriptionError: "",
   logo: "",

   // premium logic
   premiumFeatures: [], //['print_report_combine', 'download_excel'],
   activeFeatureName: "", // 'print_report_combine'
   premiumNoticeModalOpen: false,
   access: "", // 'Access' or 'Denied' or 'Test Drive'
   portfolioValue: 1000000, // default value,
   portfolioListLoading: false,
   initialProposalList: [],
   proposalsfilteredColumn: "",
   proposalsfilteredText: "",
   proposalsClearAllFilter: false,
   update: {
      isEdit: false,
      fname: "",
      lname: "",
   },
   updateDisclosure: {
      data: null,
      error: null,
   },
   profilePictureData: {},
   isBrokenImage: false,
};

export function profile(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      // case ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE_LOADING:
      //   return {
      //     ...state,
      //     profile: {
      //       ...state.profile,
      //       isLoading:true,
      //     }
      //   }
      case ActionTypes.SETUPDATE:
         return {
            ...state,
            update: {
               ...state.update,
               fname: payload.fname,
               lname: payload.lname,
            },
         };
      case ActionTypes.TOGGLEEDIT:
         return {
            ...state,
            update: {
               ...state.update,
               isEdit: !state.update.isEdit,
            },
         };
      case ActionTypes.CHANGE_FNAME:
         return {
            ...state,
            update: {
               ...state.update,
               fname: payload,
            },
         };
      case ActionTypes.CHANGE_LNAME:
         return {
            ...state,
            update: {
               ...state.update,
               lname: payload,
            },
         };
      case ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE_LOADING:
         return {
            ...state,
            profile: {
               ...state.profile,
               isLoading: action.payload,
            },
            updateDisclosure: {
               data: null,
               error: null,
            },
         };
      case ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE_SUCCESS:
         return {
            ...state,
            profile: {
               ...state.profile,
            },
            isLoading: false,
            updateDisclosure: {
               ...state.updateDisclosure,
               data: payload.result,
            },
         };
      case ActionTypes.UPDATE_TRADING_ACCOUNT_DISCLOSURE_ERROR:
         return {
            ...state,
            profile: {
               ...state.profile,
            },
            isLoading: false,
            updateDisclosure: {
               data: null,
               error:
                  payload.response &&
                  payload.response.data &&
                  payload.response.data.result
                     ? payload.response.data.result.errorDescription
                     : "Internal Server Error",
            },
         };
      case ActionTypes.CANCEL_ACH_LOADING:
         return {
            ...state,
            profile: {
               ...state.profile,
               isLoading: true,
            },
         };

      case ActionTypes.CANCEL_ACH_TRANSACTION_SUCCESS:
         return {
            ...state,
            profile: {
               ...state.profile,
               apex: {
                  ...state.profile.apex,
                  ach: state.profile.apex.ach.filter(
                     item => item.id !== payload
                  ),
               },
               isLoading: false,
            },
         };
      case ActionTypes.GET_SUBSCRIPTION_PLAN_SUCCESS:
         if (payload.user && payload.user.premiumUser) {
            window.localStorage.setItem("isPremium", 1);
         } else {
            window.localStorage.setItem("isPremium", 0);
         }
         return {
            ...state,
            ...action.payload,
         };
      case ActionTypes.SUCCESS_TO_INVITE_FRIENDS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.INVITE_FRIENDS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.FAILED_TO_INVITE_FRIENDS:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SET_PROFILE_STATE:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.SET_REFERRAL_CODE:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.PROFILE_REQUEST:
         return {
            ...state,
         };
      case ActionTypes.PROFILE_SUCCESS:
         let { portfolioValue: _portfolioValue } = state;
         if (
            action.profile &&
            action.profile.default_portfolio_value &&
            typeof action.profile.default_portfolio_value !== "undefined"
         ) {
            // console.log('current portfolio value =>', _portfolioValue);
            const {
               profile: { default_portfolio_value },
            } = action;
            // console.log(default_portfolio_value);
            _portfolioValue = parseFloat(default_portfolio_value);
            // console.log(_portfolioValue);
         }

         // NOTE: for testing purpose adding -> 'non_vetted_user: true' for sharing-alpha user
         // NOTE: remove -> 'non_vetted_user: true' when api start giving this key in response
         // ------------------------------------------------------------------------------------
         if (action.profile) {
            action.profile.non_vetted_user = true;
         }
         // ------------------------------------------------------------------------------------

         return {
            ...state,
            profile: {
               ...action.profile,
            },
            portfolioValue: _portfolioValue,
         };
      case ActionTypes.PROFILE_FAILURE:
         return {
            ...initialState,
            error: action.error,
         };
      case ActionTypes.PROFILE_LOAD:
         return {
            ...state,
            profile: {
               ...action.profile,
            },
         };
      case ActionTypes.CHANGE_PASSWORD_REQUEST:
         return {
            ...state,
            ...payload,
            // changePasswordSuccess: false,
            // changePasswordFailed: false,
            // profileUpdateSuccess: false,
            // profileUpdateFailed: false
         };

      case ActionTypes.CHANGE_PASSWORD_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.CHANGE_PASSWORD_FAILURE:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.PROFILE_UPDATE_REQUEST:
         return {
            ...state,
            profileUpdateSuccess: false,
            profileUpdateFailed: false,
            changePasswordSuccess: false,
            changePasswordFailed: false,
         };
      case ActionTypes.PROFILE_UPDATE_SUCCESS:
         let { portfolioValue: _pValue } = state;
         if (
            action.response &&
            action.response.default_portfolio_value &&
            typeof action.response.default_portfolio_value !== "undefined"
         ) {
            // console.log('current portfolio value =>', _pValue);
            let { response: _response } = action;
            let { default_portfolio_value: _dPV } = _response;
            // console.log(_dPV);
            _pValue = parseFloat(_dPV);
            // console.log(_portfolioValue);
         }

         // NOTE: for testing purpose adding -> 'non_vetted_user: true' for sharing-alpha user
         // NOTE: remove -> 'non_vetted_user: true' when api start giving this key in response
         // ------------------------------------------------------------------------------------
         if (action.response) {
            action.response.non_vetted_user = true;
         }
         // ------------------------------------------------------------------------------------

         return {
            ...state,
            profileUpdateSuccess: true,
            profileUpdateFailed: false,
            profile: {
               ...state.profile,
               ...action.response,
            },
            update: {
               ...state.update,
               isEdit: false,
            },
            portfolioValue: _pValue,
         };
      case ActionTypes.PROFILE_UPDATE_FAILURE:
         return {
            ...state,
            profileUpdateSuccess: false,
            profileUpdateFailed: true,
            error: action.error,
         };
      case ActionTypes.CREATE_SUBSCRIPTION_SUCCESS:
         return {
            ...state,
            stripeSessionStatus: true,
            stripeObject: payload,
            stripeSessionID: payload.id,
         };
      case ActionTypes.CREATE_SUBSCRIPTION_FAILURE:
         return {
            ...state,
            error: payload,
            stripeSessionStatus: false,
         };
      case ActionTypes.UNSUBSCRIBE_USER_SUCCESS:
         return {
            ...state,
            unSubscribeUserStatus: true,
         };
      case ActionTypes.UNSUBSCRIBE_USER_FAILURE:
         return {
            ...state,
            unSubscribeUserStatus: false,
            unSubscriptionError: action.error,
         };
      case ActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS:
         return {
            ...state,
            reactivateSubscriptionStatus: true,
         };
      case ActionTypes.REACTIVATE_SUBSCRIPTION_FAILURE:
         return {
            ...state,
            reactivateSubscriptionStatus: false,
            reactivateSubscriptionError: action.error,
         };

      case ActionTypes.CHECK_PREMIUM_STATE_SUCCES:
         return {
            ...state,
            ...action.payload,
         };

      case ActionTypes.PREMIUM_FEATURE_ACCESS_SUCCESS:
         return {
            ...state,
            ...action.payload,
         };

      case ActionTypes.PREMIUM_FEATURE_ACCESS_FETCH:
         return {
            ...state,
            ...action.payload,
         };
      case ActionTypes.SET_PREMIUM_STATE:
         return {
            ...state,
            ...action.payload,
         };
      case ActionTypes.SET_POPOVER_OPTION_STATE:
         return {
            ...state,
            profile: {
               ...state.profile,
               ...action.payload,
            },
         };
      case ActionTypes.GET_DWNLD_PORTFOLIO_LIST_SUCCESS:
         return {
            ...state,
            ...payload,
            portfolioListLoading: false,
            initialProposalList: payload.proposalsList,
         };

      case ActionTypes.PROPOSALS_LIST_LOADER:
         return {
            ...state,
            portfolioListLoading: true,
         };

      case ActionTypes.FILTER_PROPOSALS_LIST:
         return {
            ...state,
            proposalsList: payload.proposalsList || [],
            proposalsfilteredColumn: payload.proposalsfilteredColumn,
            proposalsfilteredText: payload.proposalsfilteredText,
            proposalsClearAllFilter: payload.proposalsClearAllFilter,
         };

      case ActionTypes.GET_PROPOSALS_COUNT:
         return {
            ...state,
            portfolioCountLoading: true,
         };

      case ActionTypes.GET_PROPOSALS_COUNT_SUCCESS:
         return {
            ...state,
            ...payload,
            portfolioCountLoading: false,
            proposalsCount: payload.totalProposals,
         };

      case ActionTypes.REFRESH_APEX_STATE_SUCCESS:
         let { profile: _currProfileState } = state;
         return {
            ...state,
            profile: {
               ..._currProfileState,
               ...payload,
            },
         };

      case ActionTypes.ACTIVATE_SIMILAR_SEARCH_SUCCESS: {
         let { profile: _currProState } = state;
         return {
            ...state,
            profile: {
               ..._currProState,
               ...payload,
            },
         };
      }

      case ActionTypes.PROFILE_DATA_SUCCESS: {
         let { profile: ___PROFILE_DATA } = state;
         return {
            ...state,
            profile: {
               ...___PROFILE_DATA,
               ...payload,
            },
         };
      }

      case ActionTypes.SAVE_DISCLOSURES_SUCCESS:
         let { profile: ___PROFILE___DATA } = state;
         return {
            ...state,
            ...payload,
            profile: {
               ...___PROFILE___DATA,
               disclosures: payload.disclosures || "",
            },
         };

      case ActionTypes.ACTIVE_SUBSCRIPTIONS_CANCELLATION_SUCCESS:
         return {
            ...state,
            ...payload,
         };

      case ActionTypes.PROFILE_PHOTO_UPLOAD_SUCCESS:
         return {
            ...state,
            profilePictureData: payload.profilePictureData,
            isBrokenImage: payload.isBrokenImage,
         };
      case ActionTypes.PROFILE_PHOTO_HIDE_UN_HIDE_SUCCESS:
         return {
            ...state,
            profilePictureData: payload.profilePictureData,
         };
      case ActionTypes.PROFILE_BROKEN_IMAGE_UPDATE:
         return {
            ...state,
            isBrokenImage: payload,
         };

      default:
         return state;
   }
}
