import React from "react";
import classNames from "classnames";

export default function CloseButton(props) {
   const { className, ...rest } = props;
   return (
      <span
         role="button"
         className={classNames("ssf-modal__close-btn", props.className)}
         {...rest}
      >
         <i className="fal fa-times-circle" />
      </span>
   );
}
