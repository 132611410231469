import ActionTypes from "../action-types";

const initialState = {
   searches: [],
   isLoading: false,
   error: null,
   violatorSearches: {
      data: null,
      isLoading: false,
      error: null,
   },
};

export function trendingSearches(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.GET_TRENDING_SEARCHES:
         return {
            ...state,
            isLoading: true,
         };
      // case ActionTypes.GET_TRENDING_SEARCHES_LOADING:
      //    return {
      //       ...state,
      //       isLoading: payload,
      //    };
      case ActionTypes.GET_TRENDING_SEARCHES_SUCCESS:
         return {
            ...state,
            searches: payload,
            isLoading: false,
         };
      case ActionTypes.GET_TRENDING_SEARCHES_ERROR:
         return {
            ...state,
            error: payload,
            isLoading: false,
         };

      case ActionTypes.GET_VIOLATOR_SEARCHES_LOADING: {
         const { violatorSearches } = state;
         violatorSearches.isLoading = payload;
         return {
            ...state,
            violatorSearches,
         };
      }
      case ActionTypes.GET_VIOLATOR_SEARCHES_SUCCESS: {
         const { violatorSearches } = state;
         violatorSearches.data = payload;
         return {
            ...state,
            violatorSearches,
         };
      }
      // case ActionTypes.GET_TRENDING_SEARCHES_ERROR: {
      //    const { violatorSearches } = state;
      //    violatorSearches.error = payload;
      //    return {
      //       ...state,
      //       violatorSearches,
      //    };
      // }
      default:
         return state;
   }
}
