import ActionTypes from "../action-types";

// LIST ALERT
export function setAlertSuccess(payload) {
   return {
      type: ActionTypes.GET_ALERTS_SUCCESS,
      payload,
   };
}

export function getListAlerts(payload) {
   return {
      type: ActionTypes.GET_ALERTS,
      payload,
   };
}

export function setAlertError(payload) {
   return {
      type: ActionTypes.GET_ALERTS_FAILURE,
      payload,
   };
}

export function setAlertLoading(payload) {
   return {
      type: ActionTypes.GET_ALERTS_LOADING,
      payload,
   };
}

// UPDATE ALERT
export function updateAlertSuccess(payload) {
   return {
      type: ActionTypes.UPDATE_ALERT_SUCCESS,
      payload,
   };
}

export function updateAlert(qs, payload) {
   return {
      type: ActionTypes.UPDATE_ALERT,
      payload,
      qs,
   };
}

export function updateAlertError(payload) {
   return {
      type: ActionTypes.UPDATE_ALERT_FAILURE,
      payload,
   };
}

export function updateAlertLoading(payload) {
   return {
      type: ActionTypes.UPDATE_ALERT_LOADING,
      payload,
   };
}
