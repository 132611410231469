import { subscribe, unsubscribe } from "./helper";
import Emitter from "./emitter";
import { io } from "socket.io-client";
//import { ContentScroller } from 'modal-components/NewsModal/style';

const API_KEY = "3ecc3bc5963046b5b90b5af208021e74";
const SOCKET_URL = `https://realtimeprice.magnifi.com`;

let socket = null;

Emitter.on("SUBSCRIBE_TO_STREAM", symbols => subscribeToStream(symbols));
Emitter.on("UNSUBSCRIBE_STREAM", symbols => unsubscribeToStream(symbols));
Emitter.on("RECONECT_TO_STREAM", symbols => reconnectToStream());
// setTimeout(
//     ()=>{
//       console.log('emitting');
//       Emitter.emit('UNSUBSCRIBE_STREAM', [])
//     }
//     ,20000);

export const initRealTimePrice = () => {
   socket = io(SOCKET_URL, {
      auth: {
         token: API_KEY,
      },
   });

   //alert('in socket')
   socket.on("connect_error", err => {
      if (err.message === "invalid token") {
         console.log("please check your token");
      }
   });

   // socket.onAny((eventName, ...args) => {
   //     // ...
   //     console.log(eventName, args);
   //   });

   socket.on("price", (...args) => {
      // console.log(args);
      Emitter.emit("GET_LIVE_PRICES", args[0]);
   });

   socket.on("connect", () => {
      console.log("=====CONNECTED=====");
      Emitter.emit("RECONECT_TO_STREAM");
   });

   return () => {
      socket.close();
   };
};

export const subscribeToStream = symbols => {
   subscribe(socket, symbols);
};

export const reconnectToStream = () => {
   subscribe(socket, null, true);
};

export const unsubscribeToStream = symbols => {
   unsubscribe(socket, symbols);
};
