import zipObject from "lodash/zipObject";

const actions = [
   "UPDATE_SFM_TRACK_YEAR",
   "UPDATE_FUNDS_TRACKING_REPORT",
   "CLEAR_TRACK_FUNDS",
   "TRACK_COMAPRE_ORDER",
   "GENERATE_FUNDS_REPORT",
   "SET_SFM_ITEMS",
   "SET_SFM_CATDATA",
   "ADD_FUND_TO_SFM",
   "REFRESH_STATE",
   "SET_SFM_STATE",
   "TOGGLE_MODAL",
   "GET_SELECTED_FUNDS",
   "GET_FUNDS",
   "FAILED_TO_PERFORM",
   "SCORE_ATTRS_HANDLER",
   "RANGE_HANDLER",
   "SELECT_HANDLER",
   "ACTION_HANDLER",
   "BUY_HANDLER",
   "BACK_HANDLER",
   "SFM_FUND_ADD",
   "SFM_FUND_DELETE",
   "SAVE_BG_DOWNLOAD",

   "ADD_SFM_ITEM_TICKERS_REQUEST",
   "ADD_SFM_ITEM_TICKERS_FETCH",
   "ADD_SFM_ITEM_TICKERS_SUCCESS",

   "REMOVE_SFM_ITEM_TICKERS_REQUEST",
   "REMOVE_SFM_ITEM_TICKERS_FETCH",
   "REMOVE_SFM_ITEM_TICKERS_SUCCESS",

   "GET_SFM_ITEM_DETAILS_REQUEST",
   "GET_SFM_ITEM_DETAILS_FETCH",
   "GET_SFM_ITEM_DETAILS_SUCCESS",

   "UPDATE_HRS_DATA_REQUEST",
   "UPDATE_HRS_DATA_FETCH",
   "UPDATE_HRS_DATA_SUCCESS",

   "DELETE_ALL_SFM_ITEM_TICKERS_REQUEST",
   "DELETE_ALL_SFM_ITEM_TICKERS_FETCH",
   "DELETE_ALL_SFM_ITEM_TICKERS_SUCCESS",

   "SET_SFM_FUND_SELECTION_TYPE_REQUEST",
   "SET_SFM_FUND_SELECTION_TYPE_FETCH",
   "SET_SFM_FUND_SELECTION_TYPE_SUCCESS",

   "EXECUTE_MAGNIFI_BROKER_ORDERS_REQUEST",
   "EXECUTE_MAGNIFI_BROKER_ORDERS_FETCH",
   "EXECUTE_MAGNIFI_BROKER_ORDERS_SUCCESS",

   "ADD_TICKERS_TO_CART_REQUEST",
   "ADD_TICKERS_TO_CART_FETCH",
   "ADD_TICKERS_TO_CART_SUCCESS",
   "SFM_REAL_TIME_PRICE_SOCKET",

   "GET_USER_PORTFOLIO",
   "GET_USER_PORTFOLIO_SUCCESS",
   "GET_USER_PORTFOLIO_ERROR",

   "GET_PORTFOLIO_DETAILS_FOR_SFM_REQUEST",
   "GET_PORTFOLIO_DETAILS_FOR_SFM_FETCH",
   "GET_PORTFOLIO_DETAILS_FOR_SFM_SUCCESS",

   "GET_COMBINE_SCORE_REQUEST",
   "GET_COMBINE_SCORE_FETCH",
   "GET_COMBINE_SCORE_SUCCESS",
];

export default zipObject(actions, actions);
