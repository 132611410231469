import React from "react";
import { Route } from "react-router-dom";
import { REDIRECT_DOMAIN_PATH } from "DataSet";

export default function PrivateRoute({
   component: Component,
   bypass,
   ...rest
}) {
   // <Redirect
   //   to={{ pathname: '/login', state: { from: props.location } }}
   // />
   // console.log('...',{...rest}.path)
   const profile_self_role = window.localStorage.getItem("profile_self_role")
      ? window.localStorage.getItem("profile_self_role")
      : "loggedOut";

   return (
      <Route
         {...rest}
         render={props =>
            ["/build-portfolio", "/enhancer"].includes({ ...rest }.path) &&
            ["", "Other/Curious", "Individual Investor"].includes(
               profile_self_role
            ) === true ? (
               (window.location = "/404")
            ) : bypass ||
              (localStorage.getItem("token") &&
                 (localStorage.getItem("termsAgreed") === "1" ||
                    localStorage.getItem("termsAgreed") === 1)) ? (
               <Component {...props} />
            ) : (
               <RedirectToMagnifiDiscover />
            )
         }
      />
   );
}

export class RedirectToMagnifiDiscover extends React.Component {
   componentWillMount() {
      if (
         !localStorage.getItem("token") &&
         (localStorage.getItem("termsAgreed") !== "1" ||
            localStorage.getItem("termsAgreed") !== 1)
      ) {
         window.location = REDIRECT_DOMAIN_PATH;
         return null;
      }
      if (
         localStorage.getItem("token") &&
         (localStorage.getItem("termsAgreed") !== "1" ||
            localStorage.getItem("termsAgreed") !== 1)
      ) {
         window.location = REDIRECT_DOMAIN_PATH;
         return null;
      }
   }

   render() {
      if (
         !localStorage.getItem("token") &&
         (localStorage.getItem("termsAgreed") !== "1" ||
            localStorage.getItem("termsAgreed") !== 1)
      ) {
         window.location = REDIRECT_DOMAIN_PATH;
         return null;
      }
      if (
         localStorage.getItem("token") &&
         (localStorage.getItem("termsAgreed") !== "1" ||
            localStorage.getItem("termsAgreed") !== 1)
      ) {
         window.location = REDIRECT_DOMAIN_PATH;
         return null;
      }

      return null;
   }
}
