import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import AssetSubscriptionModal from "./AssetSubscriptionModal";
import { GlobalModalTypes } from "types/globalModal";
import { clearGlobalModalState } from "actions/globalModal";

const MODAL_COMPONENTS: any = {
   [GlobalModalTypes.AssetSubscriptionModal]: AssetSubscriptionModal,
};

const GlobalModal = () => {
   const dispatch = useDispatch();
   const { show, modalType, modalProps } = useSelector(
      (state: RootStateOrAny) => state.globalModals
   );

   if (!modalType) {
      return null;
   }

   const ModalComponent = MODAL_COMPONENTS[modalType];

   const hideModal = () => {
      dispatch(clearGlobalModalState());
   };

   return (
      <ModalComponent
         show={show}
         hideModal={hideModal}
         modalProps={modalProps}
      />
   );
};

export default GlobalModal;
