var _ = require("lodash");
let ticker_list = [];

export let subscribe = (socket, symbols, reconnect) => {
   if (symbols && symbols.length > 0) {
      console.log("====symbols====", symbols);
      let diff = _.difference(symbols, ticker_list);
      if (diff.length > 0) {
         ticker_list = ticker_list.concat(diff);
         ticker_list = [...new Set(ticker_list)];
         diff.flat();
         let timer = 0;
         if (!socket && socket.readyState !== 1) {
            console.log("waiting");
            timer = 300;
         }
         setTimeout(() => {
            // let payload =
            // {
            // action: "subscribe",
            // params: {
            //     symbols:  diff.toString(),
            //   }
            // }

            // socket.send(JSON.stringify(payload))
            socket.emit("subscribeTicker", {
               symbols: diff.toString(),
            });
         }, timer);
      }
   } else if (reconnect && ticker_list.length > 0) {
      let timer = 0;
      if (!socket && socket.readyState !== 1) {
         console.log("waiting");
         timer = 500;
      }
      setTimeout(() => {
         // let payload =
         // {
         // action: "subscribe",
         // params: {
         //     symbols:  diff.toString(),
         //   }
         // }

         // socket.send(JSON.stringify(payload))
         socket.emit("subscribeTicker", {
            symbols: ticker_list.toString(),
         });
      }, timer);
   }
};

export let unsubscribe = (socket, symbols) => {
   if (symbols.length === 0) {
      ticker_list.flat();
      if (socket.readyState === socket.OPEN) {
         // let payload =
         //   {
         //   action: "unsubscribe",
         //   params: {
         //       symbols:  ticker_list.toString()
         //     }
         //   }

         //   socket.send(JSON.stringify(payload));
         socket.emit("unsubscribeTicker", {
            symbols: ticker_list.toString(),
         });
      }
      ticker_list = [];
   } else {
      let diff = _.difference(symbols, ticker_list);
      if (diff.length === 0) {
         symbols.flat();
         if (socket.readyState === socket.OPEN) {
            // let payload =
            //   {
            //   action: "unsubscribe",
            //   params: {
            //       symbols:  symbols.toString()
            //     }
            //   }

            //   socket.send(JSON.stringify(payload));
            socket.emit("unsubscribeTicker", {
               symbols: symbols.toString(),
            });
         }
         ticker_list = _.remove(ticker_list, function (n) {
            return !symbols.includes(n);
         });
      }
   }
};
