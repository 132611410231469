import { call, put } from "redux-saga/effects";
import qs from "query-string";
import { get, lowerCase, pick } from "lodash";

// import ActionTypes from "../action-types";
import * as actions from "../actions/broker";
import * as BrokerApi from "../apis/broker";
import { TradeItSetupStatus } from "../DataSet";
import { closeTraditWindow } from "Utils";

export function* setupETradeBroker(action) {
   const { payload } = action;
   try {
      const response = yield call(BrokerApi.setupBroker, qs.stringify(payload));
      yield call(window.open, response.data.redirect_endpoint);
      // yield call(window.open, response.data.redirect_endpoint)
      yield put(actions.successfulSetupBroker(response.data));
   } catch (error) {
      yield put(actions.failedSetupBroker(error.response));
   }
}

export function* setupTDAmeritradeBroker(action) {
   // const { payload } = action;
   // const requestUri = `${window.origin}/settings/broker/tdameritrade/setup`; //account
   //To test
   // yield window.location.href = `${requestUri}?code=j2C8ZnlH1LIUBFSyShuwBrC4DOeFxFehy76NkCM%2Fo2qSDpE1X6VrUJVROjZoLTXZ8PR6UVliGSxYm8S3LGf9%2Fb31qF9fh2UOKqAPwTJQUxAhZCsjJlMs2bMeLcAug0zJuREzjyTryqkcm0qZJJiAcBvZ3VLddYv5kNC8Bc4JC896brRGT%2BZ03XXg%2Bt4nPPT7Ql%2BmiKvXh1KAzmJBa766TaZ7nWb%2FgVsj6lxwlDSnQZi1dFPZsgmJKh3nqj3VpHpwBUPOcMcAn%2BiLkpD6MmpZW%2FS9LjhyLQbilcFKMn7ahzspm%2BY9u4LX2Lyc4d0yzeY%2BZaLih%2BX%2BtBjR5ZZBvwUGe7uAT353q5VV5l2rL%2FSZV13356f7zf7Vo81slSUSQdgLsU%2BVmzKWdY8yfFdNsf8lqxrHxJsDDpCIemukSTei1yDhYwHxg6Kp7raxIVu100MQuG4LYrgoVi%2FJHHvl0MaQRzPiOoQrdxnSJXUmQm7VMzosXvbpbdPN%2BHb0LgEADFol3P2nXPExpXuxiXicAOyA7cUXid4lfiAANcf52NXbPws%2F%2B88zvHMscQ6HAGDUOxuv9OMBmOvw2VidrdMDjwPbfLf2%2BYztd55%2BxxucwU6tuK64fJw7E8pqDPwfhUKfSMuaDAvLVUlcesEKJW9HzT%2B%2B5hhjn%2BhXk1dQBEuE1Ae0Qp0FawuZQN1KnuU%2B5inIhxlrtpRDox1ZW0UP0Qtlo8NJHgRtUiDscin0d2%2FziX8rZlXgV6ck%2BKOtxSQkQ7Ax%2FAx%2B%2FBpYEqlaGaiOL38gED2cTN08M2adTrVOdWZ23zzEI3Lk6YpJWvp0XHGhwWg3lXXx2SqcbYHpJ9LgoAHD3cgTnvlNgkBDQevvjK8e4pKJVhUY%2BsXz8u2Bh6%2Bm3Y71PwFexV8uulh5RmU%3D212FD3x19z9sWBHDJACbC00B75E`;
   // yield window.location.href = `https://auth.tdameritrade.com/oauth?client_id=LOCALTD5@AMER.OAUTHAP&response_type=code&redirect_uri=${requestUri}`
   //'https://auth.tdameritrade.com/oauth?client_id=LOCALTD5@AMER.OAUTHAP&response_type=code&redirect_uri=https://test.opensesafi.com/callbacks/tdameritrade/td-auth-code'
   try {
      const response = yield call(
         BrokerApi.setupTDBroker,
         encodeURIComponent(action.received_code)
      );
      yield put(
         actions.successfulCompleteBrokerSetup({
            ...response.data,
            brokerName: "tdameritrade",
         })
      );
      yield put(actions.successfulSetupBroker(response.data));
   } catch (error) {
      yield put(actions.failedSetupBroker(error.response));
   }
}

window._tradeItWindow = null;
export function* setupTradeItBroker(action) {
   // const { payload } = action;
   const input = "?" + qs.stringify(action.received_code);
   try {
      const height = 450;
      const width = 400;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;
      const windowProps =
         "dialog=yes,centerscreen=yes,chrome=yes,height=" +
         height +
         ",width=" +
         width +
         ",left=" +
         left +
         ",top=" +
         top;
      const response = yield call(BrokerApi.setupTradeItBroker, input);
      closeTraditWindow();
      window._tradeItWindow = yield call(
         window.open,
         response.data.oAuthURL,
         "TradeIt",
         windowProps
      );
      yield put(actions.successfulSetupBroker(response.data));
   } catch (error) {
      yield put(actions.failedSetupBroker(error.response));
   }
}

export function* completeTradeItBrokerSetup(action) {
   const { payload, callback } = action;
   try {
      const { token, brokerName } = pick(payload, ["token", "brokerName"]);
      const response = yield call(BrokerApi.completeTradeItBroker, { token });
      const responseStatus = get(response, "data.status", "");
      if (lowerCase(responseStatus) === lowerCase(TradeItSetupStatus.SUCCESS)) {
         yield put(
            actions.successfulCompleteBrokerSetup({
               ...response.data,
               brokerName,
            })
         );
         callback && typeof callback === "function" && callback();
      } else if (
         lowerCase(responseStatus) ===
         lowerCase(TradeItSetupStatus.INFORMATION_NEEDED)
      ) {
         yield put(
            actions.displayTradeItVerificationScreen({
               ...response.data,
               brokerName,
            })
         );
      }
   } catch (error) {
      yield put(actions.failedSetupBroker(error.response));
   }
}

export function* tradeItVerificationRequest(action) {
   const { payload } = action;
   const { brokerName, ...rest } = payload;
   try {
      const response = yield call(
         BrokerApi.verifyTradeItSecurityQuestions,
         rest
      );
      const responseStatus = get(response, "data.status", "");
      if (lowerCase(responseStatus) === lowerCase(TradeItSetupStatus.SUCCESS)) {
         yield put(actions.tradeItVerificationSuccess());
         yield put(
            actions.successfulCompleteBrokerSetup({
               brokerName,
            })
         );
      } else if (
         lowerCase(responseStatus) ===
         lowerCase(TradeItSetupStatus.INFORMATION_NEEDED)
      ) {
         yield put(actions.displayTradeItVerificationScreen(response.data));
      }
   } catch (error) {
      yield put(
         actions.tradeItVerificationError({ message: "Verification failed" })
      );
   }
}
