import zipObject from "lodash/zipObject";

const actions = [
   "SET_ACCOUNTS_STATE",

   "UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID_REQUEST",
   "UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID_FETCH",
   "UPLOAD_ACCOUNTS_PORTFOLIO_DATA_BY_ID_SUCCESS",

   "GET_ACCOUNTS_PORTFOLIO_BY_ID_REQUEST",
   "GET_ACCOUNTS_PORTFOLIO_BY_ID_FETCH",
   "GET_ACCOUNTS_PORTFOLIO_BY_ID_SUCCESS",

   "RESET_ACCOUNTS_STATE_REQUEST",
   "RESET_ACCOUNTS_STATE_FETCH",
   "RESET_ACCOUNTS_STATE_SUCCESS",

   "GET_ALERT_COUNT",
   "GET_ALERT_COUNT_LOADING",
   "GET_ALERT_COUNT_SUCCESS",
   "GET_ALERT_COUNT_FAILURE",

   "GET_ALERT_COUNT_REQUEST",
   "GET_ALERT_COUNT_FETCH",
   "GET_ALERT_COUNT_SUCCESS",

   "GET_ACCOUNTS_TRENDING_SEARCHES_REQUEST",
   "GET_ACCOUNTS_TRENDING_SEARCHES_FETCH",
   "GET_ACCOUNTS_TRENDING_SEARCHES_SUCCESS",

   "ADD_SFM_FUND_STATE_TO_ACCOUNTS_STATE",
   "REMOVE_ALL_SFM_FUNDS_FROM_ACCOUNTS_STATE",
   "REMOVE_SFM_FUND_FROM_ACCOUNTS_STATE",
   "SET_ACCOUNTS_HOLDINGS_FUND_SELECTION_TYPE",

   "CANCEL_DEPOSITS_REQUEST",
   "CANCEL_DEPOSITS_FETCH",
   "CANCEL_DEPOSITS_SUCCESS",

   "CANCEL_TRADES_REQUEST",
   "CANCEL_TRADES_FETCH",
   "CANCEL_TRADES_SUCCESS",

   "REFRESH_APEX_STATE_REQUEST",
   "REFRESH_APEX_STATE_FETCH",
   "REFRESH_APEX_STATE_SUCCESS",

   "GET_APEX_AMOUNT_DETAILS_REQUEST",
   "GET_APEX_AMOUNT_DETAILS_FETCH",
   "GET_APEX_AMOUNT_DETAILS_SUCCESS",

   "GET_PORTFOLIO_GAINS_REQUEST",
   "GET_PORTFOLIO_GAINS_FETCH",
   "GET_PORTFOLIO_GAINS_SUCCESS",
];

export default zipObject(actions, actions);
