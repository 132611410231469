import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ButtonGroup, Form, Modal, ModalBody } from "reactstrap";

import { PrimaryButton } from "shared-components";
import { logout, termsAgreementRequest } from "actions/auth";
import { isNewUser } from "selectors/auth-selector";
import { createLoadingSelector } from "selectors/loading-selector";
import { Spinner } from "shared-components/";

const TermsNConditions = props => {
   const { loading, loggedIn, isTermsAgreed, is_sharing_alpha_user } = props;
   console.log(
      "loggedIn --> ",
      loggedIn,
      "isTermsAgreed --> ",
      isTermsAgreed,
      "is_sharing_alpha_user --> ",
      is_sharing_alpha_user
   );
   if (!loggedIn || (loggedIn && isTermsAgreed) || !is_sharing_alpha_user)
      return null;

   // const handleDisagree = () => {
   //   const { logout } = props;
   //   if (logout) {
   //     props.logout();
   //   }
   // }

   const handleAgree = () => {
      const { termsAgreementRequest } = props;
      if (termsAgreementRequest) {
         termsAgreementRequest();
      }
   };

   return (
      <Modal
         centered
         isOpen={true}
         className="registrations-process-modal sharing-aplha-tc-modal"
         backdropClassName="registrations-process-modal-backdrop"
      >
         <Spinner isFetching={loading} />
         <Form className="register-form" noValidate={true}>
            <ModalBody className="ssf-modal__body">
               <div className="registrations-process-modal-container">
                  <div className="header-wrapper">
                     <h1 className="text-align-left">Terms & Conditions</h1>
                  </div>
                  <p className="content-wrapper">
                     By clicking agree, you confirm that you have read,
                     understood & agree to our{" "}
                     <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/terms_of_use.pdf"
                     >
                        terms and conditions
                     </a>
                     .
                  </p>
                  <ButtonGroup className="cta-wrapper justify-content-left">
                     <PrimaryButton
                        className="btn secondary-btn"
                        type="button"
                        onClick={handleAgree}
                     >
                        Agree & continue
                     </PrimaryButton>
                  </ButtonGroup>
               </div>
            </ModalBody>
         </Form>
      </Modal>
   );
};

const mapStateToProps = ({ auth, loading, profile }) => ({
   loggedIn: auth.loggedIn,
   isNewUser: isNewUser(auth),
   isTermsAgreed: profile.profile.terms_agreed,
   is_sharing_alpha_user: profile.profile
      ? profile.profile.is_sharing_alpha_user
      : false,
   loading: createLoadingSelector([
      "AUTH",
      "PROFILE",
      "TERMS_AGREEMENT",
      "LOGOUT",
   ])({ loading }),
});

const mapDispatchToProps = {
   logout,
   termsAgreementRequest,
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(withRouter(TermsNConditions));
