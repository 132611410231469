/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import InputMask from "react-input-mask";
import moment from "moment";

export default function SSFDatePicker(props) {
   // const [dateopen, setDateOpen] = React.useState(false);
   const [dateval, setDateValue] = React.useState("");
   const [focused, setFocused] = React.useState(false);
   const { id } = props;

   React.useEffect(() => {
      if (!dateval && props.selected) {
         if (moment(props.selected).isValid()) {
            setDateValue(moment(props.selected).format("MMDDYYYY"));
         }
      }
   }, [props.selected]);

   return (
      <div className={`ssf-datepicker hidden_datepicker`}>
         {/* <DatePicker placeholderText="mm/dd/yyyy" onClickOutside={() => {
        if (!dateopen)
          return
        setDateOpen(false)
      }} onSelect={() => {
        if (!dateopen)
          return
        setDateOpen(false)
      }} id={id} name={name} {...rest} customInput={<InputMask
        mask="99/99/9999"
        maskChar={null}
      />} />
      <label onClick={() => setDateOpen(!dateopen)} htmlFor={id} className="ssf-datepicker__label">
        <CalendarIcon className="ssf-datepicker__calender-icon" />
      </label> */}
         <InputMask
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={e => {
               if (props.onKeyDown) {
                  props.onKeyDown(e);
               }
            }}
            id={id}
            placeholder={focused ? "mm/dd/yyyy" : ""}
            mask="99/99/9999"
            autocomplete="off"
            value={dateval}
            maskChar={null}
            disabled={props.disabled}
            className={props.className}
            onChange={e => {
               if (e.target.value) {
                  let currentYear = moment().format("YYYY");
                  if (
                     e.target.value.length === 1 &&
                     parseInt(e.target.value[0]) > 1
                  ) {
                     return;
                  } else if (
                     e.target.value.length === 3 &&
                     !moment(e.target.value, "MM/", true).isValid()
                  ) {
                     return;
                  } else if (
                     e.target.value.length === 4 &&
                     parseInt(e.target.value[3]) > 3
                  ) {
                     return;
                  } else if (
                     e.target.value.length === 6 &&
                     !moment(e.target.value, "MM/DD/", true).isValid()
                  ) {
                     return;
                  } else if (
                     e.target.value.length === 7 &&
                     parseInt(e.target.value[6]) > parseInt(currentYear[0])
                  ) {
                     return;
                  } else if (
                     e.target.value.length === 8 &&
                     parseInt(e.target.value[6] + e.target.value[7]) >
                        parseInt(currentYear[0] + currentYear[1])
                  ) {
                     return;
                  } else if (
                     e.target.value.length === 9 &&
                     parseInt(
                        e.target.value[6] +
                           e.target.value[7] +
                           e.target.value[8]
                     ) >
                        parseInt(
                           currentYear[0] + currentYear[1] + currentYear[2]
                        )
                  ) {
                     return;
                  } else if (
                     e.target.value.length === 10 &&
                     parseInt(
                        e.target.value[6] +
                           e.target.value[7] +
                           e.target.value[8] +
                           e.target.value[9]
                     ) >
                        parseInt(
                           currentYear[0] +
                              currentYear[1] +
                              currentYear[2] +
                              currentYear[3]
                        )
                  ) {
                     return;
                  }
               }
               setDateValue(e.target.value);
               if (moment(e.target.value, "MM/DD/YYYY", true).isValid()) {
                  props.onChange(
                     moment(e.target.value, "MM/DD/YYYY").toDate(),
                     e.target.value
                  );
               } else {
                  props.onChange(null, e.target.value);
               }
            }}
         />
      </div>
   );
}
