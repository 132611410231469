export const RegionChartMapping = [
   { chart_name: "australia", region_name: "Australia", display_name: "Aus" },
   { chart_name: "austria", region_name: "Austria", display_name: "Austria" },
   { chart_name: "belgium", region_name: "Belgium", display_name: "Belgium" },
   { chart_name: "denmark", region_name: "Denmark", display_name: "Denmark" },
   { chart_name: "finland", region_name: "Finland", display_name: "Finland" },
   { chart_name: "france", region_name: "France", display_name: "France" },
   { chart_name: "germany", region_name: "Germany", display_name: "Germany" },
   { chart_name: "hong_kong", region_name: "Hong Kong", display_name: "HK" },
   { chart_name: "ireland", region_name: "Ireland", display_name: "Ireland" },
   { chart_name: "israel", region_name: "Israel", display_name: "Israel" },
   { chart_name: "italy", region_name: "Italy", display_name: "Italy" },
   { chart_name: "japan", region_name: "Japan", display_name: "Japan" },
   {
      chart_name: "netherlands",
      region_name: "Netherlands",
      display_name: "Netherl.",
   },
   {
      chart_name: "new_zealand",
      region_name: "New Zealand",
      display_name: "NewZeal",
   },
   { chart_name: "norway", region_name: "Norway", display_name: "Norway" },
   {
      chart_name: "portugal",
      region_name: "Portugal",
      display_name: "Portugal",
   },
   {
      chart_name: "singapore",
      region_name: "Singapore",
      display_name: "Singapore",
   },
   { chart_name: "spain", region_name: "Spain", display_name: "Spain" },
   { chart_name: "sweden", region_name: "Sweden", display_name: "Sweden" },
   {
      chart_name: "switzerland",
      region_name: "Switzerland",
      display_name: "Switz.",
   },
   { chart_name: "united_kingdom", region_name: "U.K.", display_name: "U.K." },
   {
      chart_name: "emerging_markets",
      region_name: "Emerging Markets",
      display_name: "Em. Mkts",
   },
   { chart_name: "brazil", region_name: "Brazil", display_name: "Brazil" },
   { chart_name: "chile", region_name: "Chile", display_name: "Chile" },
   { chart_name: "china", region_name: "China", display_name: "China" },
   {
      chart_name: "colombia",
      region_name: "Colombia",
      display_name: "Colombia",
   },
   {
      chart_name: "czech_republic",
      region_name: "Czech Republic",
      display_name: "CzechRep",
   },
   { chart_name: "egypt", region_name: "Egypt", display_name: "Egypt" },
   { chart_name: "greece", region_name: "Greece", display_name: "Greece" },
   { chart_name: "hungary", region_name: "Hungary", display_name: "Hungary" },
   { chart_name: "india", region_name: "India", display_name: "India" },
   {
      chart_name: "indonesia",
      region_name: "Indonesia",
      display_name: "Indonesia",
   },
   { chart_name: "kuwait", region_name: "Kuwait", display_name: "Kuwait" },
   {
      chart_name: "malaysia",
      region_name: "Malaysia",
      display_name: "Malaysia",
   },
   { chart_name: "mexico", region_name: "Mexico", display_name: "Mexico" },
   {
      chart_name: "pakistan",
      region_name: "Pakistan",
      display_name: "Pakistan",
   },
   {
      chart_name: "philippines",
      region_name: "Philippines",
      display_name: "Pilippine",
   },
   { chart_name: "poland", region_name: "Poland", display_name: "Poland" },
   { chart_name: "qatar", region_name: "Qatar", display_name: "Qatar" },
   { chart_name: "russia", region_name: "Russia", display_name: "Russia" },
   {
      chart_name: "south_africa",
      region_name: "South Africa",
      display_name: "S Africa",
   },
   { chart_name: "taiwan", region_name: "Taiwan", display_name: "Taiwan" },
   {
      chart_name: "thailand",
      region_name: "Thailand",
      display_name: "Thailand",
   },
   { chart_name: "turkey", region_name: "Turkey", display_name: "Turkey" },
   {
      chart_name: "united_arab_emirates",
      region_name: "U.A.E.",
      display_name: "U.A.E.",
   },
   {
      chart_name: "north_america",
      region_name: "North America",
      display_name: "N.America",
   },
   { chart_name: "canada", region_name: "Canada", display_name: "Canada" },
   { chart_name: "united_states", region_name: "U.S.", display_name: "U.S." },
   // {'chart_name':'developed_markets','region_name':'Non U.S. Developed Markets','display_name':'Non U.S. Developed Markets'},
   {
      chart_name: "developed_markets",
      region_name: "Developed Markets",
      display_name: "Developed Markets",
   },
   { chart_name: "asia", region_name: "Asia", display_name: "Asia" },
   { chart_name: "europe", region_name: "Europe", display_name: "Europe" },
   {
      chart_name: "middle_east",
      region_name: "Middle East",
      display_name: "Middle East",
   },
   { chart_name: "bric", region_name: "BRIC", display_name: "BRIC" },
   {
      chart_name: "frontier_markets",
      region_name: "Frontier Markets",
      display_name: "Frontier Markets",
   },
   {
      chart_name: "eurozone",
      region_name: "Eurozone",
      display_name: "Eurozone",
   },
   { chart_name: "africa", region_name: "Africa", display_name: "Africa" },
   {
      chart_name: "asia_pacific",
      region_name: "Asia Pacific",
      display_name: "Asia Pacific",
   },
   // {'chart_name':'global','region_name':'Global','display_name':'Global'},
   {
      chart_name: "latin_america",
      region_name: "Latin America",
      display_name: "Latin America",
   },
   { chart_name: "nordic", region_name: "Nordic", display_name: "Nordic" },
   {
      chart_name: "non_us_markets",
      region_name: "Non US Markets",
      display_name: "Non US Markets",
   },
   {
      chart_name: "south_korea",
      region_name: "South Korea",
      display_name: "South Korea",
   },
   { chart_name: "peru", region_name: "Peru", display_name: "Peru" },
   { chart_name: "bick", region_name: "Bick", display_name: "Bick" },
   { chart_name: "korea", region_name: "Korea", display_name: "Korea" },
   {
      chart_name: "argentina",
      region_name: "Argentina",
      display_name: "Argentina",
   },
   {
      chart_name: "bangladesh",
      region_name: "Bangladesh",
      display_name: "Bangladesh",
   },
   { chart_name: "benin", region_name: "Benin", display_name: "Benin" },
   {
      chart_name: "bosnia_and_herzegovina",
      region_name: "Bosnia and Herzegovina",
      display_name: "Bosnia and Herzegovina",
   },
   {
      chart_name: "botswana",
      region_name: "Botswana",
      display_name: "Botswana",
   },
   {
      chart_name: "bulgaria",
      region_name: "Bulgaria",
      display_name: "Bulgaria",
   },
   {
      chart_name: "burkina_faso",
      region_name: "Burkina Faso",
      display_name: "Burkina Faso",
   },
   { chart_name: "croatia", region_name: "Croatia", display_name: "Croatia" },
   { chart_name: "estonia", region_name: "Estonia", display_name: "Estonia" },
   { chart_name: "ghana", region_name: "Ghana", display_name: "Ghana" },
   {
      chart_name: "ivory_coast",
      region_name: "Ivory Coast",
      display_name: "Ivory Coast",
   },
   { chart_name: "jamaica", region_name: "Jamaica", display_name: "Jamaica" },
   { chart_name: "jordan", region_name: "Jordan", display_name: "Jordan" },
   {
      chart_name: "kazakhstan",
      region_name: "Kazakhstan",
      display_name: "Kazakhstan",
   },
   { chart_name: "kenya", region_name: "Kenya", display_name: "Kenya" },
   { chart_name: "lebanon", region_name: "Lebanon", display_name: "Lebanon" },
   {
      chart_name: "lithuania",
      region_name: "Lithuania",
      display_name: "Lithuania",
   },
   { chart_name: "mali", region_name: "Mali", display_name: "Mali" },
   {
      chart_name: "mauritius",
      region_name: "Mauritius",
      display_name: "Mauritius",
   },
   { chart_name: "morocco", region_name: "Morocco", display_name: "Morocco" },
   { chart_name: "niger", region_name: "Niger", display_name: "Niger" },
   { chart_name: "nigeria", region_name: "Nigeria", display_name: "Nigeria" },
   {
      chart_name: "north_korea",
      region_name: "North Korea",
      display_name: "North Korea",
   },
   { chart_name: "oman", region_name: "Oman", display_name: "Oman" },
   {
      chart_name: "palestine",
      region_name: "Palestine",
      display_name: "Palestine",
   },
   { chart_name: "panama", region_name: "Panama", display_name: "Panama" },
   { chart_name: "romania", region_name: "Romania", display_name: "Romania" },
   {
      chart_name: "saudia_arabia",
      region_name: "Saudi Arabia",
      display_name: "Saudi Arabia",
   },
   { chart_name: "senegal", region_name: "Senegal", display_name: "Senegal" },
   { chart_name: "serbia", region_name: "Serbia", display_name: "Serbia" },
   {
      chart_name: "slovenia",
      region_name: "Slovenia",
      display_name: "Slovenia",
   },
   {
      chart_name: "sri_lanka",
      region_name: "Sri Lanka",
      display_name: "Sri Lanka",
   },
   {
      chart_name: "trinidad_and_tobago",
      region_name: "Trinidad and Tobago",
      display_name: "Trinidad and Tobago",
   },
   { chart_name: "tunisia", region_name: "Tunisia", display_name: "Tunisia" },
   { chart_name: "ukraine", region_name: "Ukraine", display_name: "Ukraine" },
   { chart_name: "vietnam", region_name: "Vietnam", display_name: "Vietnam" },
   {
      chart_name: "zimbabwe",
      region_name: "Zimbabwe",
      display_name: "Zimbabwe",
   },
   { chart_name: "bahrain", region_name: "Bahrain", display_name: "Bahrain" },
   {
      chart_name: "asean_countries",
      region_name: "Asean Countries",
      display_name: "Asean Countries",
   },
   {
      chart_name: "east_eu_em",
      region_name: "Eastern Europe",
      display_name: "Eastern Europe",
   },
   {
      chart_name: "international",
      region_name: "International",
      display_name: "International",
   },
   {
      chart_name: "armenita",
      region_name: "Armenita",
      display_name: "Armenita",
   },
];

export const SectorChartMapping = [
   // {'chart_name':'communication_services','sector_name':'Communications','display_name':'Comms.'},
   // {'chart_name':'consumer_discretionary','sector_name':'Consumer Discretionary','display_name':'ConsDisc'},
   // {'chart_name':'consumer_staples','sector_name':'Consumer Staples','display_name':'Staples'},
   // {'chart_name':'energy','sector_name':'Energy','display_name':'energy'},
   // {'chart_name':'financials','sector_name':'Financial','display_name':'Fin.'},
   // {'chart_name':'health_care','sector_name':'Health Care','display_name':'HealthC.'},
   // {'chart_name':'industrials','sector_name':'Industrial','display_name':'Indstr.'},
   // {'chart_name':'materials','sector_name':'Materials','display_name':'Materials'},
   // {'chart_name':'real_estate','sector_name':'Real Estate','display_name':'RealEst'},
   // {'chart_name':'information_technology','sector_name':'Technology','display_name':'Tech.'},
   // {'chart_name':'utilities','sector_name':'Utilities','display_name':'Utils.'},

   {
      chart_name: "Communications",
      sector_name: "Communications",
      display_name: "Communications",
   },
   {
      chart_name: "Consumer Discretionary",
      sector_name: "Consumer Discretionary",
      display_name: "Consumer Discretionary",
   },
   {
      chart_name: "Consumer Staples",
      sector_name: "Consumer Staples",
      display_name: "Consumer Staples",
   },
   {
      chart_name: "Diversified",
      sector_name: "Diversified",
      display_name: "Diversified",
   },
   { chart_name: "Energy", sector_name: "Energy", display_name: "Energy" },
   {
      chart_name: "Financial",
      sector_name: "Financial",
      display_name: "Financial",
   },
   {
      chart_name: "Health Care",
      sector_name: "Health Care",
      display_name: "Health Care",
   },
   {
      chart_name: "Industrial",
      sector_name: "Industrial",
      display_name: "Industrial",
   },
   {
      chart_name: "Materials",
      sector_name: "Materials",
      display_name: "Materials",
   },
   {
      chart_name: "Real Estate",
      sector_name: "Real Estate",
      display_name: "Real Estate",
   },
   {
      chart_name: "Technology",
      sector_name: "Technology",
      display_name: "Technology",
   },
   {
      chart_name: "Utilities",
      sector_name: "Utilities",
      display_name: "Utilities",
   },
   {
      chart_name: "Others Non Classified",
      sector_name: "Others",
      display_name: "Others",
   },

   { chart_name: "energy", sector_name: "Energy", display_name: "Energy" },
   {
      chart_name: "materials",
      sector_name: "Materials",
      display_name: "Materials",
   },
   {
      chart_name: "industrials",
      sector_name: "Industrials",
      display_name: "Industrials",
   },
   {
      chart_name: "consumer_discretionary",
      sector_name: "Consumer Discretionary",
      display_name: "Consumer Discretionary",
   },
   {
      chart_name: "consumer_staples",
      sector_name: "Consumer Staples",
      display_name: "Consumer Staples",
   },
   {
      chart_name: "health_care",
      sector_name: "Health Care",
      display_name: "Health Care",
   },
   {
      chart_name: "healthcare",
      sector_name: "Health Care",
      display_name: "Health Care",
   },
   {
      chart_name: "financials",
      sector_name: "Financials",
      display_name: "Financials",
   },
   {
      chart_name: "information_technology",
      sector_name: "Information Technology",
      display_name: "Information Technology",
   },
   {
      chart_name: "communication_services",
      sector_name: "Communication Services",
      display_name: "Communication Services",
   },
   {
      chart_name: "utilities",
      sector_name: "Utilities",
      display_name: "Utilities",
   },
   {
      chart_name: "real_estate",
      sector_name: "Real Estate",
      display_name: "Real Estate",
   },
   {
      chart_name: "energy_equipment_and_services",
      sector_name: "Energy Equipment",
      display_name: "Energy Equipment",
   },
   {
      chart_name: "oil_gas_and_consumable_fuels",
      sector_name: "Oil/Gas/Consumable Fuels",
      display_name: "Oil/Gas/Consumable Fuels",
   },
   {
      chart_name: "oil_and_gas_drilling",
      sector_name: "Oil & Gas Drilling",
      display_name: "Oil & Gas Drilling",
   },
   {
      chart_name: "oil_and_gas_equipment_and_services",
      sector_name: "Oil & Gas Equipment",
      display_name: "Oil & Gas Equipment",
   },
   {
      chart_name: "integrated_oil_and_gas",
      sector_name: "Integrated Oil & Gas",
      display_name: "Integrated Oil & Gas",
   },
   {
      chart_name: "oil_and_gas_exploration_and_production",
      sector_name: "Oil & Gas Production",
      display_name: "Oil & Gas Production",
   },
   {
      chart_name: "oil_and_gas_refining_and_marketing_opportunities",
      sector_name: "Oil & Gas Marketing",
      display_name: "Oil & Gas Marketing",
   },
   {
      chart_name: "oil_and_gas_storage_and_transportation",
      sector_name: "Oil & Gas Storage",
      display_name: "Oil & Gas Storage",
   },
   {
      chart_name: "coal_and_consumable_fuels",
      sector_name: "Coal & Consumable Fuels",
      display_name: "Coal & Consumable Fuels",
   },
   {
      chart_name: "chemicals",
      sector_name: "Chemicals",
      display_name: "Chemicals",
   },
   {
      chart_name: "construction_materials",
      sector_name: "Construction Materials",
      display_name: "Construction Materials",
   },
   {
      chart_name: "containers_and_packaging",
      sector_name: "Containers & Packaging",
      display_name: "Containers & Packaging",
   },
   {
      chart_name: "metals_and_mining",
      sector_name: "Metals & Mining",
      display_name: "Metals & Mining",
   },
   {
      chart_name: "paper_and_forest_products",
      sector_name: "Paper & Forest Products",
      display_name: "Paper & Forest Products",
   },
   {
      chart_name: "commodity_chemicals",
      sector_name: "Cmdty Chemicals",
      display_name: "Cmdty Chemicals",
   },
   {
      chart_name: "diversified_chemicals",
      sector_name: "Diverse Chemicals",
      display_name: "Diverse Chemicals",
   },
   {
      chart_name: "fertilizers_and_agricultural_chemicals",
      sector_name: "Fertilizers",
      display_name: "Fertilizers",
   },
   {
      chart_name: "industrial_gases",
      sector_name: "Industrial Gas",
      display_name: "Industrial Gas",
   },
   {
      chart_name: "specialty_chemicals",
      sector_name: "Spec Chemicals",
      display_name: "Spec Chemicals",
   },
   {
      chart_name: "metal_and_glass_containers",
      sector_name: "Metal & Glass Containers",
      display_name: "Metal & Glass Containers",
   },
   {
      chart_name: "paper_packaging",
      sector_name: "Paper Packaging",
      display_name: "Paper Packaging",
   },
   {
      chart_name: "aluminum",
      sector_name: "Aluminium",
      display_name: "Aluminium",
   },
   {
      chart_name: "diversified_metals_and_mining",
      sector_name: "Diverse Metals and Mining",
      display_name: "Diverse Metals and Mining",
   },
   { chart_name: "copper", sector_name: "Cooper", display_name: "Cooper" },
   { chart_name: "gold_sector", sector_name: "Gold", display_name: "Gold" },
   {
      chart_name: "precious_metals_and_minerals",
      sector_name: "Precious metals",
      display_name: "Precious metals",
   },
   { chart_name: "silver", sector_name: "Silver", display_name: "Silver" },
   { chart_name: "steel", sector_name: "Steel", display_name: "Steel" },
   {
      chart_name: "forest_products",
      sector_name: "Forest Products",
      display_name: "Forest Products",
   },
   {
      chart_name: "paper_products",
      sector_name: "Paper Products",
      display_name: "Paper Products",
   },
   {
      chart_name: "capital_goods",
      sector_name: "Capital Goods",
      display_name: "Capital Goods",
   },
   {
      chart_name: "commercial_and_professional_services",
      sector_name: "Comrc & Prof Services",
      display_name: "Comrc & Prof Services",
   },
   {
      chart_name: "transportation",
      sector_name: "Transportation",
      display_name: "Transportation",
   },
   {
      chart_name: "aerospace_and_defense",
      sector_name: "Aerospace & Defenece",
      display_name: "Aerospace & Defenece",
   },
   {
      chart_name: "building_products",
      sector_name: "Building Products",
      display_name: "Building Products",
   },
   {
      chart_name: "construction_and_engineering",
      sector_name: "Construction",
      display_name: "Construction",
   },
   {
      chart_name: "electrical_equipment",
      sector_name: "Electrical Equip",
      display_name: "Electrical Equip",
   },
   {
      chart_name: "industrial_conglomerates",
      sector_name: "Industrial Conglomerates",
      display_name: "Industrial Conglomerates",
   },
   {
      chart_name: "machinery",
      sector_name: "Machinery",
      display_name: "Machinery",
   },
   {
      chart_name: "trading_companies_and_distributors",
      sector_name: "Trading Companies",
      display_name: "Trading Companies",
   },
   {
      chart_name: "electrical_components_and_equipment",
      sector_name: "Electrical Components & Equip",
      display_name: "Electrical Components & Equip",
   },
   {
      chart_name: "heavy_electrical_equipment",
      sector_name: "Heavy Electrical Equp",
      display_name: "Heavy Electrical Equp",
   },
   {
      chart_name: "construction_machinery_and_heavy_trucks",
      sector_name: "Constr Machinery & Heavy Trucks",
      display_name: "Constr Machinery & Heavy Trucks",
   },
   {
      chart_name: "agricultural_and_farm_machinery",
      sector_name: "Agrc & Farm Machinery",
      display_name: "Agrc & Farm Machinery",
   },
   {
      chart_name: "industrial_machinery",
      sector_name: "Industrial Machinery",
      display_name: "Industrial Machinery",
   },
   {
      chart_name: "commercial_services_and_supplies",
      sector_name: "Cmmrc Services & Supplies",
      display_name: "Cmmrc Services & Supplies",
   },
   {
      chart_name: "professional_services",
      sector_name: "Prof Services",
      display_name: "Prof Services",
   },
   {
      chart_name: "commercial_printing",
      sector_name: "Commercial Printing",
      display_name: "Commercial Printing",
   },
   {
      chart_name: "environmental_and_facilities_services",
      sector_name: "Environmental Services",
      display_name: "Environmental Services",
   },
   {
      chart_name: "office_services_and_supplies",
      sector_name: "Office Services & Supplies",
      display_name: "Office Services & Supplies",
   },
   {
      chart_name: "diversified_support_services",
      sector_name: "Diversified Support Services",
      display_name: "Diversified Support Services",
   },
   {
      chart_name: "security_and_alarm_services",
      sector_name: "Security & Alarm Services",
      display_name: "Security & Alarm Services",
   },
   {
      chart_name: "human_resource_and_employment_services",
      sector_name: "HR and Employment",
      display_name: "HR and Employment",
   },
   {
      chart_name: "research_and_consulting_services",
      sector_name: "Research & Consulting",
      display_name: "Research & Consulting",
   },
   {
      chart_name: "air_freight_and_logistics",
      sector_name: "Air Freight & Logistics",
      display_name: "Air Freight & Logistics",
   },
   {
      chart_name: "airlines",
      sector_name: "Airlines",
      display_name: "Airlines",
   },
   { chart_name: "marine", sector_name: "Marine", display_name: "Marine" },
   {
      chart_name: "road_and_rail",
      sector_name: "Road & Rail",
      display_name: "Road & Rail",
   },
   {
      chart_name: "transportation_infrastructure",
      sector_name: "Transportation Infrastructure",
      display_name: "Transportation Infrastructure",
   },
   {
      chart_name: "railroads",
      sector_name: "Railroads",
      display_name: "Railroads",
   },
   {
      chart_name: "trucking",
      sector_name: "Trucking",
      display_name: "Trucking",
   },
   {
      chart_name: "airport_services",
      sector_name: "Airport Services",
      display_name: "Airport Services",
   },
   {
      chart_name: "highways_and_railtracks",
      sector_name: "Highways & Railtracks",
      display_name: "Highways & Railtracks",
   },
   {
      chart_name: "marine_ports_and_services",
      sector_name: "Marine Ports & Services",
      display_name: "Marine Ports & Services",
   },
   {
      chart_name: "automobiles_and_components",
      sector_name: "Automobile & Components",
      display_name: "Automobile & Components",
   },
   {
      chart_name: "consumer_durables_and_apparel",
      sector_name: "Consumer Durables & Apparel",
      display_name: "Consumer Durables & Apparel",
   },
   {
      chart_name: "consumer_services",
      sector_name: "Consumer Services",
      display_name: "Consumer Services",
   },
   { chart_name: "media", sector_name: "Media", display_name: "Media" },
   {
      chart_name: "retailing",
      sector_name: "Retailing",
      display_name: "Retailing",
   },
   {
      chart_name: "auto_components",
      sector_name: "Auto Components",
      display_name: "Auto Components",
   },
   {
      chart_name: "automobiles",
      sector_name: "Automobiles",
      display_name: "Automobiles",
   },
   {
      chart_name: "auto_parts_and_equipment",
      sector_name: "Auto Parts & Equipment",
      display_name: "Auto Parts & Equipment",
   },
   {
      chart_name: "tires_and_rubber",
      sector_name: "Tires & Rubber",
      display_name: "Tires & Rubber",
   },
   {
      chart_name: "automobile_manufacturers",
      sector_name: "Automobile Manufacturers",
      display_name: "Automobile Manufacturers",
   },
   {
      chart_name: "motorcycle_manufacturers",
      sector_name: "Motocycle Manufactirers",
      display_name: "Motocycle Manufactirers",
   },
   {
      chart_name: "household_durables",
      sector_name: "Household Durables",
      display_name: "Household Durables",
   },
   {
      chart_name: "leisure_products",
      sector_name: "Leisure Products",
      display_name: "Leisure Products",
   },
   {
      chart_name: "textiles_apparel_and_luxury_goods",
      sector_name: "Textiles Apparel & Luxury",
      display_name: "Textiles Apparel & Luxury",
   },
   {
      chart_name: "consumer_electronics",
      sector_name: "Consumer Electronics",
      display_name: "Consumer Electronics",
   },
   {
      chart_name: "home_furnishings",
      sector_name: "Home Furnishings",
      display_name: "Home Furnishings",
   },
   {
      chart_name: "homebuilding",
      sector_name: "Homebuilding",
      display_name: "Homebuilding",
   },
   {
      chart_name: "household_appliances",
      sector_name: "Household Appliances",
      display_name: "Household Appliances",
   },
   {
      chart_name: "housewares_and_specialties",
      sector_name: "Housewares & Specialties",
      display_name: "Housewares & Specialties",
   },
   {
      chart_name: "apparel_accessories_and_luxury_goods",
      sector_name: "Apparel Accessories & Luxury",
      display_name: "Apparel Accessories & Luxury",
   },
   {
      chart_name: "footwear",
      sector_name: "Footwear",
      display_name: "Footwear",
   },
   {
      chart_name: "textiles",
      sector_name: "Textiles",
      display_name: "Textiles",
   },
   {
      chart_name: "hotels_restaurants_and_leisure",
      sector_name: "Hotels/Restaurants/Leisure",
      display_name: "Hotels/Restaurants/Leisure",
   },
   {
      chart_name: "diversified_consumer_services",
      sector_name: "Diverse Consumer Services",
      display_name: "Diverse Consumer Services",
   },
   {
      chart_name: "casinos_and_gaming",
      sector_name: "Casinos & Gaming",
      display_name: "Casinos & Gaming",
   },
   {
      chart_name: "hotels_resorts_and_cruise_lines",
      sector_name: "Hotels Resorts & Cruises",
      display_name: "Hotels Resorts & Cruises",
   },
   {
      chart_name: "leisure_facilities",
      sector_name: "Leisure Facilities",
      display_name: "Leisure Facilities",
   },
   {
      chart_name: "restaurants",
      sector_name: "Restaurants",
      display_name: "Restaurants",
   },
   {
      chart_name: "education_services",
      sector_name: "Education Services",
      display_name: "Education Services",
   },
   {
      chart_name: "specialized_consumer_services",
      sector_name: "Specialized Consumer Services",
      display_name: "Specialized Consumer Services",
   },
   {
      chart_name: "advertising",
      sector_name: "Advertising",
      display_name: "Advertising",
   },
   {
      chart_name: "broadcasting",
      sector_name: "Broadcasting",
      display_name: "Broadcasting",
   },
   {
      chart_name: "cable_and_satellite",
      sector_name: "Cable & Satelite",
      display_name: "Cable & Satelite",
   },
   {
      chart_name: "movies_and_entertainment",
      sector_name: "Movies & Entertainment",
      display_name: "Movies & Entertainment",
   },
   {
      chart_name: "publishing",
      sector_name: "Publishing ",
      display_name: "Publishing ",
   },
   {
      chart_name: "distributors",
      sector_name: "Distributors",
      display_name: "Distributors",
   },
   {
      chart_name: "internet_and_direct_marketing_retail",
      sector_name: "Internet & Direct Marketing ",
      display_name: "Internet & Direct Marketing ",
   },
   {
      chart_name: "multiline_retail",
      sector_name: "Muliline Retail",
      display_name: "Muliline Retail",
   },
   {
      chart_name: "specialty_retail",
      sector_name: "Specialty Retail",
      display_name: "Specialty Retail",
   },
   {
      chart_name: "department_stores",
      sector_name: "Department Stores",
      display_name: "Department Stores",
   },
   {
      chart_name: "general_merchandise_stores",
      sector_name: "General Merchandise Stores",
      display_name: "General Merchandise Stores",
   },
   {
      chart_name: "apparel_retail",
      sector_name: "Apparel Retail",
      display_name: "Apparel Retail",
   },
   {
      chart_name: "computer_and_electronics_retail",
      sector_name: "Computer & Electronics Retail",
      display_name: "Computer & Electronics Retail",
   },
   {
      chart_name: "home_improvement_retail",
      sector_name: "Home Improvement Retail",
      display_name: "Home Improvement Retail",
   },
   {
      chart_name: "specialty_stores",
      sector_name: "Specialty Stores",
      display_name: "Specialty Stores",
   },
   {
      chart_name: "automotive_retail",
      sector_name: "Automotive Retail",
      display_name: "Automotive Retail",
   },
   {
      chart_name: "homefurnishing_retail",
      sector_name: "Homefurnishing Retail",
      display_name: "Homefurnishing Retail",
   },
   {
      chart_name: "food_and_staples_retailing",
      sector_name: "Food & Staples Retailing",
      display_name: "Food & Staples Retailing",
   },
   {
      chart_name: "food_beverage_and_tobacco",
      sector_name: "Food Beverage & Tobacco",
      display_name: "Food Beverage & Tobacco",
   },
   {
      chart_name: "household_and_personal_products",
      sector_name: "Household & Personal Products",
      display_name: "Household & Personal Products",
   },
   {
      chart_name: "drug_retail",
      sector_name: "Drug Retail",
      display_name: "Drug Retail",
   },
   {
      chart_name: "food_distributors",
      sector_name: "Food Distributors",
      display_name: "Food Distributors",
   },
   {
      chart_name: "food_retail",
      sector_name: "Food Retail",
      display_name: "Food Retail",
   },
   {
      chart_name: "hypermarkets_and_super_centers",
      sector_name: "Hypermarkets",
      display_name: "Hypermarkets",
   },
   {
      chart_name: "beverages",
      sector_name: "Beverages",
      display_name: "Beverages",
   },
   {
      chart_name: "food_products",
      sector_name: "Food Products",
      display_name: "Food Products",
   },
   { chart_name: "tobacco", sector_name: "Tobacco", display_name: "Tobacco" },
   { chart_name: "brewers", sector_name: "Brewers", display_name: "Brewers" },
   {
      chart_name: "distillers_and_vintners",
      sector_name: "Distillers and Vintners",
      display_name: "Distillers and Vintners",
   },
   {
      chart_name: "soft_drinks",
      sector_name: "Soft Drinks",
      display_name: "Soft Drinks",
   },
   {
      chart_name: "agricultural_products",
      sector_name: "Agricultural Products",
      display_name: "Agricultural Products",
   },
   {
      chart_name: "packaged_foods_and_meats",
      sector_name: "Packaged Foods",
      display_name: "Packaged Foods",
   },
   {
      chart_name: "household_products",
      sector_name: "Household Products",
      display_name: "Household Products",
   },
   {
      chart_name: "personal_products",
      sector_name: "Personal Products",
      display_name: "Personal Products",
   },
   {
      chart_name: "health_care_equipment_and_services",
      sector_name: "Healthcare Equip & Services",
      display_name: "Healthcare Equip & Services",
   },
   {
      chart_name: "pharmaceuticals_biotechnology_and_life_sciences",
      sector_name: "Pharma/Biotech/Life Science",
      display_name: "Pharma/Biotech/Life Science",
   },
   {
      chart_name: "health_care_equipment_and_supplies",
      sector_name: "Healthcare Equip & Supplies",
      display_name: "Healthcare Equip & Supplies",
   },
   {
      chart_name: "health_care_providers_and_services",
      sector_name: "Healthcare Providers & Services",
      display_name: "Healthcare Providers & Services",
   },
   {
      chart_name: "health_care_technology",
      sector_name: "Healthcare Technology",
      display_name: "Healthcare Technology",
   },
   {
      chart_name: "health_care_equipment",
      sector_name: "Healthcare Equip",
      display_name: "Healthcare Equip",
   },
   {
      chart_name: "health_care_supplies",
      sector_name: "Healthcare Supplies",
      display_name: "Healthcare Supplies",
   },
   {
      chart_name: "health_care_distributors",
      sector_name: "Healthcare Distributors",
      display_name: "Healthcare Distributors",
   },
   {
      chart_name: "health_care_services",
      sector_name: "Healthcare Services",
      display_name: "Healthcare Services",
   },
   {
      chart_name: "health_care_facilities",
      sector_name: "Healthcare Facilities",
      display_name: "Healthcare Facilities",
   },
   {
      chart_name: "managed_health_care",
      sector_name: "Managed Healthcare",
      display_name: "Managed Healthcare",
   },
   {
      chart_name: "biotechnology",
      sector_name: "Biotechnology",
      display_name: "Biotechnology",
   },
   {
      chart_name: "pharmaceuticals",
      sector_name: "Pharmaceuticals",
      display_name: "Pharmaceuticals",
   },
   {
      chart_name: "life_sciences_tools_and_services",
      sector_name: "Life Sciences Tools & Services",
      display_name: "Life Sciences Tools & Services",
   },
   {
      chart_name: "biopharma",
      sector_name: "Biopharma",
      display_name: "Biopharma",
   },
   { chart_name: "banks", sector_name: "Banks", display_name: "Banks" },
   {
      chart_name: "diversified_financials",
      sector_name: "Diversified Financials",
      display_name: "Diversified Financials",
   },
   {
      chart_name: "insurance",
      sector_name: "Insurance",
      display_name: "Insurance",
   },
   {
      chart_name: "diversified_banks",
      sector_name: "Diversified Banks",
      display_name: "Diversified Banks",
   },
   {
      chart_name: "regional_banks",
      sector_name: "Regional Banks",
      display_name: "Regional Banks",
   },
   {
      chart_name: "thrifts_and_mortgage_finance",
      sector_name: "Thrifts & Mortgage Finance",
      display_name: "Thrifts & Mortgage Finance",
   },
   {
      chart_name: "diversified_financial_services",
      sector_name: "Diverse Financial Services",
      display_name: "Diverse Financial Services",
   },
   {
      chart_name: "consumer_finance",
      sector_name: "Consumer Finance",
      display_name: "Consumer Finance",
   },
   {
      chart_name: "capital_markets",
      sector_name: "Capital Markets",
      display_name: "Capital Markets",
   },
   {
      chart_name: "mortgage_real_estate_investment_trusts",
      sector_name: "Mortgage REITs",
      display_name: "Mortgage REITs",
   },
   {
      chart_name: "other_diversified_financial_services",
      sector_name: "Other Diversified Financials",
      display_name: "Other Diversified Financials",
   },
   {
      chart_name: "multi_sector_holdings",
      sector_name: "Multi-Sector Holdings",
      display_name: "Multi-Sector Holdings",
   },
   {
      chart_name: "specialized_finance",
      sector_name: "Specialized Finance",
      display_name: "Specialized Finance",
   },
   {
      chart_name: "asset_management_and_custody_banks",
      sector_name: "Asset Mgmt & Custodians",
      display_name: "Asset Mgmt & Custodians",
   },
   {
      chart_name: "investment_banking_and_brokerage",
      sector_name: "Inv Banking & Brokerage",
      display_name: "Inv Banking & Brokerage",
   },
   {
      chart_name: "diversified_capital_markets",
      sector_name: "Diverse Capital Mkts",
      display_name: "Diverse Capital Mkts",
   },
   {
      chart_name: "financial_exchanges_and_data",
      sector_name: "Financial Exchanges",
      display_name: "Financial Exchanges",
   },
   {
      chart_name: "insurance_brokers",
      sector_name: "Insurance Brokers",
      display_name: "Insurance Brokers",
   },
   {
      chart_name: "life_and_health_insurance",
      sector_name: "Life & Health Insurance",
      display_name: "Life & Health Insurance",
   },
   {
      chart_name: "multi_line_insurance",
      sector_name: "Multi-Line Insurance",
      display_name: "Multi-Line Insurance",
   },
   {
      chart_name: "property_and_casualty_insurance",
      sector_name: "Prop & Caslty Insurance",
      display_name: "Prop & Caslty Insurance",
   },
   {
      chart_name: "reinsurance",
      sector_name: "Reinsurance",
      display_name: "Reinsurance",
   },
   {
      chart_name: "software_and_services",
      sector_name: "Software & Drinks",
      display_name: "Software & Drinks",
   },
   {
      chart_name: "technology_hardware_and_equipment",
      sector_name: "Tech Hardware & Equip",
      display_name: "Tech Hardware & Equip",
   },
   {
      chart_name: "semiconductors_and_semiconductor_equipment",
      sector_name: "Semiconductors/Equip",
      display_name: "Semiconductors/Equip",
   },
   {
      chart_name: "internet_software_and_services",
      sector_name: "Internet/Software/Services",
      display_name: "Internet/Software/Services",
   },
   {
      chart_name: "it_services",
      sector_name: "IT Services",
      display_name: "IT Services",
   },
   {
      chart_name: "software",
      sector_name: "Software",
      display_name: "Software",
   },
   {
      chart_name: "communications_equipment",
      sector_name: "Communications Equip",
      display_name: "Communications Equip",
   },
   {
      chart_name: "technology_hardware_storage_and_peripherals",
      sector_name: "Tech Hardware Storage",
      display_name: "Tech Hardware Storage",
   },
   {
      chart_name: "electronic_equipment_instruments_and_components",
      sector_name: "Elec Equip & Components",
      display_name: "Elec Equip & Components",
   },
   {
      chart_name: "semiconductor_equipment",
      sector_name: "Semiconductor Equip",
      display_name: "Semiconductor Equip",
   },
   {
      chart_name: "semiconductors",
      sector_name: "Semiconductors/Equip",
      display_name: "Semiconductors/Equip",
   },
   {
      chart_name: "it_consulting_and_other_services",
      sector_name: "IT Consulting ",
      display_name: "IT Consulting ",
   },
   {
      chart_name: "data_processing_and_outsourced_services",
      sector_name: "Data Processing/Outsrc",
      display_name: "Data Processing/Outsrc",
   },
   {
      chart_name: "application_software",
      sector_name: "Application Software",
      display_name: "Application Software",
   },
   {
      chart_name: "systems_software",
      sector_name: "Systems Software",
      display_name: "Systems Software",
   },
   {
      chart_name: "home_entertainment_software",
      sector_name: "Home Entertainment",
      display_name: "Home Entertainment",
   },
   {
      chart_name: "electronic_equipment_and_instruments",
      sector_name: "Electronic Equipment",
      display_name: "Electronic Equipment",
   },
   {
      chart_name: "electronic_components",
      sector_name: "Electronic Components",
      display_name: "Electronic Components",
   },
   {
      chart_name: "electronic_manufacturing_services",
      sector_name: "Electronic Manufacturing",
      display_name: "Electronic Manufacturing",
   },
   {
      chart_name: "technology_distributors",
      sector_name: "Tech Distributors",
      display_name: "Tech Distributors",
   },
   {
      chart_name: "diversified_telecommunication_services",
      sector_name: "Diversified Communication",
      display_name: "Diversified Communication",
   },
   {
      chart_name: "wireless_telecommunication_services",
      sector_name: "Wireless Communication",
      display_name: "Wireless Communication",
   },
   {
      chart_name: "alternative_carriers",
      sector_name: "Alternative Carriers",
      display_name: "Alternative Carriers",
   },
   {
      chart_name: "integrated_telecommunication_services",
      sector_name: "Integrated Communication",
      display_name: "Integrated Communication",
   },
   {
      chart_name: "electric_utilities",
      sector_name: "Electric Utilities",
      display_name: "Electric Utilities",
   },
   {
      chart_name: "gas_utilities",
      sector_name: "Gas Utilities",
      display_name: "Gas Utilities",
   },
   {
      chart_name: "multi_utilities",
      sector_name: "Multi-Utilities",
      display_name: "Multi-Utilities",
   },
   {
      chart_name: "water_utilities",
      sector_name: "Water Utilities",
      display_name: "Water Utilities",
   },
   {
      chart_name: "independent_power_and_renewable_electricity_producers",
      sector_name: "Independent Power",
      display_name: "Independent Power",
   },
   {
      chart_name: "independent_power_producers_and_energy_traders",
      sector_name: "Energy Traders",
      display_name: "Energy Traders",
   },
   {
      chart_name: "renewable_electricity",
      sector_name: "Renewable Electricity",
      display_name: "Renewable Electricity",
   },
   {
      chart_name: "equity_real_estate_investment_trusts_(reits)",
      sector_name: "Equity REITs",
      display_name: "Equity REITs",
   },
   {
      chart_name: "real_estate_management_and_development",
      sector_name: "Real Estate Mgmt",
      display_name: "Real Estate Mgmt",
   },
   {
      chart_name: "diversified_reits",
      sector_name: "Diversified REITs",
      display_name: "Diversified REITs",
   },
   {
      chart_name: "industrial_reits",
      sector_name: "Industrial REITs",
      display_name: "Industrial REITs",
   },
   {
      chart_name: "hotel_and_resort_reits",
      sector_name: "Hotel & Resort REITs",
      display_name: "Hotel & Resort REITs",
   },
   {
      chart_name: "office_reits",
      sector_name: "Office REITs",
      display_name: "Office REITs",
   },
   {
      chart_name: "health_care_reits",
      sector_name: "Healthcare REITs",
      display_name: "Healthcare REITs",
   },
   {
      chart_name: "residential_reits",
      sector_name: "Residential REITs",
      display_name: "Residential REITs",
   },
   {
      chart_name: "retail_reits",
      sector_name: "Retail REITs",
      display_name: "Retail REITs",
   },
   {
      chart_name: "specialized_reits",
      sector_name: "Specialized REITs",
      display_name: "Specialized REITs",
   },
   {
      chart_name: "diversified_real_estate_activities",
      sector_name: "Diverse Real Estate Activities",
      display_name: "Diverse Real Estate Activities",
   },
   {
      chart_name: "real_estate_operating_companies",
      sector_name: "Real Estate Operating Comps",
      display_name: "Real Estate Operating Comps",
   },
   {
      chart_name: "real_estate_development",
      sector_name: "Real Estate Development",
      display_name: "Real Estate Development",
   },
   {
      chart_name: "real_estate_services",
      sector_name: "Reale Estate Services",
      display_name: "Reale Estate Services",
   },

   {
      chart_name: "exposure/sector/energy",
      sector_name: "Energy",
      display_name: "Energy",
   },
   {
      chart_name: "exposure/sector/materials",
      sector_name: "Materials",
      display_name: "Materials",
   },
   {
      chart_name: "exposure/sector/industrials",
      sector_name: "Industrials",
      display_name: "Industrials",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary",
      sector_name: "Consumer Discretionary",
      display_name: "Consumer Discretionary",
   },
   {
      chart_name: "exposure/sector/consumer_staples",
      sector_name: "Consumer Staples",
      display_name: "Consumer Staples",
   },
   {
      chart_name: "exposure/sector/health_care",
      sector_name: "Health Care",
      display_name: "Health Care",
   },
   {
      chart_name: "exposure/sector/healthcare",
      sector_name: "Health Care",
      display_name: "Health Care",
   },
   {
      chart_name: "exposure/sector/financials",
      sector_name: "Financials",
      display_name: "Financials",
   },
   {
      chart_name: "exposure/sector/information_technology",
      sector_name: "Information Technology",
      display_name: "Information Technology",
   },
   {
      chart_name: "exposure/sector/communication_services",
      sector_name: "Communication Services",
      display_name: "Communication Services",
   },
   {
      chart_name: "exposure/sector/utilities",
      sector_name: "Utilities",
      display_name: "Utilities",
   },
   {
      chart_name: "exposure/sector/sector_real_estate",
      sector_name: "Real Estate",
      display_name: "Real Estate",
   },
   {
      chart_name: "exposure/sector/energy/energy_equipment_and_services",
      sector_name: "Energy Equipment",
      display_name: "Energy Equipment",
   },
   {
      chart_name: "exposure/sector/energy/oil_gas_and_consumable_fuels",
      sector_name: "Oil/Gas/Consumable Fuels",
      display_name: "Oil/Gas/Consumable Fuels",
   },
   {
      chart_name: "exposure/sector/energy/energy_equipment_and_services/oagd",
      sector_name: "Oil & Gas Drilling",
      display_name: "Oil & Gas Drilling",
   },
   {
      chart_name: "exposure/sector/energy/energy_equipment_and_services/oageas",
      sector_name: "Oil & Gas Equipment",
      display_name: "Oil & Gas Equipment",
   },
   {
      chart_name: "exposure/sector/energy/oil_gas_and_consumable_fuels/ioag",
      sector_name: "Integrated Oil & Gas",
      display_name: "Integrated Oil & Gas",
   },
   {
      chart_name: "exposure/sector/energy/oil_gas_and_consumable_fuels/oageap",
      sector_name: "Oil & Gas Production",
      display_name: "Oil & Gas Production",
   },
   {
      chart_name: "exposure/sector/energy/oil_gas_and_consumable_fuels/oagramo",
      sector_name: "Oil & Gas Marketing",
      display_name: "Oil & Gas Marketing",
   },
   {
      chart_name: "exposure/sector/energy/oil_gas_and_consumable_fuels/oagsat",
      sector_name: "Oil & Gas Storage",
      display_name: "Oil & Gas Storage",
   },
   {
      chart_name: "exposure/sector/energy/oil_gas_and_consumable_fuels/cacf",
      sector_name: "Coal & Consumable Fuels",
      display_name: "Coal & Consumable Fuels",
   },
   {
      chart_name: "exposure/sector/materials/chemicals",
      sector_name: "Chemicals",
      display_name: "Chemicals",
   },
   {
      chart_name: "exposure/sector/materials/construction_materials",
      sector_name: "Construction Materials",
      display_name: "Construction Materials",
   },
   {
      chart_name: "exposure/sector/materials/containers_and_packaging",
      sector_name: "Containers & Packaging",
      display_name: "Containers & Packaging",
   },
   {
      chart_name: "exposure/sector/materials/metals_and_mining",
      sector_name: "Metals & Mining",
      display_name: "Metals & Mining",
   },
   {
      chart_name: "exposure/sector/materials/paper_and_forest_products",
      sector_name: "Paper & Forest Products",
      display_name: "Paper & Forest Products",
   },
   {
      chart_name: "exposure/sector/materials/chemicals/commodity_chemicals",
      sector_name: "Cmdty Chemicals",
      display_name: "Cmdty Chemicals",
   },
   {
      chart_name: "exposure/sector/materials/chemicals/diversified_chemicals",
      sector_name: "Diverse Chemicals",
      display_name: "Diverse Chemicals",
   },
   {
      chart_name: "exposure/sector/materials/chemicals/faac",
      sector_name: "Fertilizers",
      display_name: "Fertilizers",
   },
   {
      chart_name: "exposure/sector/materials/chemicals/industrial_gases",
      sector_name: "Industrial Gas",
      display_name: "Industrial Gas",
   },
   {
      chart_name: "exposure/sector/materials/chemicals/specialty_chemicals",
      sector_name: "Spec Chemicals",
      display_name: "Spec Chemicals",
   },
   {
      chart_name: "exposure/sector/materials/containers_and_packaging/magc",
      sector_name: "Metal & Glass Containers",
      display_name: "Metal & Glass Containers",
   },
   {
      chart_name: "exposure/sector/materials/containers_and_packaging/pp",
      sector_name: "Paper Packaging",
      display_name: "Paper Packaging",
   },
   {
      chart_name: "exposure/sector/materials/metals_and_mining/aluminum",
      sector_name: "Aluminium",
      display_name: "Aluminium",
   },
   {
      chart_name: "exposure/sector/materials/metals_and_mining/dmam",
      sector_name: "Diverse Metals and Mining",
      display_name: "Diverse Metals and Mining",
   },
   {
      chart_name: "exposure/sector/materials/metals_and_mining/copper_sector",
      sector_name: "Cooper",
      display_name: "Cooper",
   },
   {
      chart_name: "exposure/sector/materials/metals_and_mining/gold_sector",
      sector_name: "Gold",
      display_name: "Gold",
   },
   {
      chart_name: "exposure/sector/materials/metals_and_mining/pmam",
      sector_name: "Precious metals",
      display_name: "Precious metals",
   },
   {
      chart_name: "exposure/sector/materials/metals_and_mining/silver_sector",
      sector_name: "Silver",
      display_name: "Silver",
   },
   {
      chart_name: "exposure/sector/materials/metals_and_mining/steel",
      sector_name: "Steel",
      display_name: "Steel",
   },
   {
      chart_name: "exposure/sector/materials/paper_and_forest_products/fp",
      sector_name: "Forest Products",
      display_name: "Forest Products",
   },
   {
      chart_name: "exposure/sector/materials/paper_and_forest_products/pp",
      sector_name: "Paper Products",
      display_name: "Paper Products",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods",
      sector_name: "Capital Goods",
      display_name: "Capital Goods",
   },
   {
      chart_name: "exposure/sector/industrials/caps",
      sector_name: "Comrc & Prof Services",
      display_name: "Comrc & Prof Services",
   },
   {
      chart_name: "exposure/sector/industrials/transportation",
      sector_name: "Transportation",
      display_name: "Transportation",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/aad",
      sector_name: "Aerospace & Defenece",
      display_name: "Aerospace & Defenece",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/building_products",
      sector_name: "Building Products",
      display_name: "Building Products",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/cae",
      sector_name: "Construction",
      display_name: "Construction",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/ee",
      sector_name: "Electrical Equip",
      display_name: "Electrical Equip",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/ic",
      sector_name: "Industrial Conglomerates",
      display_name: "Industrial Conglomerates",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/machinery",
      sector_name: "Machinery",
      display_name: "Machinery",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/tcad",
      sector_name: "Trading Companies",
      display_name: "Trading Companies",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/ee/ecae",
      sector_name: "Electrical Components & Equip",
      display_name: "Electrical Components & Equip",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/ee/hee",
      sector_name: "Heavy Electrical Equp",
      display_name: "Heavy Electrical Equp",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/machinery/cmaht",
      sector_name: "Constr Machinery & Heavy Trucks",
      display_name: "Constr Machinery & Heavy Trucks",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/machinery/aafm",
      sector_name: "Agrc & Farm Machinery",
      display_name: "Agrc & Farm Machinery",
   },
   {
      chart_name: "exposure/sector/industrials/capital_goods/machinery/im",
      sector_name: "Industrial Machinery",
      display_name: "Industrial Machinery",
   },
   {
      chart_name: "exposure/sector/industrials/caps/csas",
      sector_name: "Cmmrc Services & Supplies",
      display_name: "Cmmrc Services & Supplies",
   },
   {
      chart_name: "exposure/sector/industrials/caps/ps",
      sector_name: "Prof Services",
      display_name: "Prof Services",
   },
   {
      chart_name: "exposure/sector/industrials/caps/csas/cp",
      sector_name: "Commercial Printing",
      display_name: "Commercial Printing",
   },
   {
      chart_name: "exposure/sector/industrials/caps/csas/eafs",
      sector_name: "Environmental Services",
      display_name: "Environmental Services",
   },
   {
      chart_name: "exposure/sector/industrials/caps/csas/osas",
      sector_name: "Office Services & Supplies",
      display_name: "Office Services & Supplies",
   },
   {
      chart_name: "exposure/sector/industrials/caps/csas/dss",
      sector_name: "Diversified Support Services",
      display_name: "Diversified Support Services",
   },
   {
      chart_name: "exposure/sector/industrials/caps/csas/saas",
      sector_name: "Security & Alarm Services",
      display_name: "Security & Alarm Services",
   },
   {
      chart_name: "exposure/sector/industrials/commercial_and_ps/ps/hraes",
      sector_name: "HR and Employment",
      display_name: "HR and Employment",
   },
   {
      chart_name: "exposure/sector/industrials/commercial_and_ps/ps/racs",
      sector_name: "Research & Consulting",
      display_name: "Research & Consulting",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/afal",
      sector_name: "Air Freight & Logistics",
      display_name: "Air Freight & Logistics",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/airlines",
      sector_name: "Airlines",
      display_name: "Airlines",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/marine",
      sector_name: "Marine",
      display_name: "Marine",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/road_and_rail",
      sector_name: "Road & Rail",
      display_name: "Road & Rail",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/ti",
      sector_name: "Transportation Infrastructure",
      display_name: "Transportation Infrastructure",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/road_and_rail/r",
      sector_name: "Railroads",
      display_name: "Railroads",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/road_and_rail/t",
      sector_name: "Trucking",
      display_name: "Trucking",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/ti/as",
      sector_name: "Airport Services",
      display_name: "Airport Services",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/ti/har",
      sector_name: "Highways & Railtracks",
      display_name: "Highways & Railtracks",
   },
   {
      chart_name: "exposure/sector/industrials/transportation/ti/mpas",
      sector_name: "Marine Ports & Services",
      display_name: "Marine Ports & Services",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/aac",
      sector_name: "Automobile & Components",
      display_name: "Automobile & Components",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa",
      sector_name: "Consumer Durables & Apparel",
      display_name: "Consumer Durables & Apparel",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/consumer_services",
      sector_name: "Consumer Services",
      display_name: "Consumer Services",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/media",
      sector_name: "Media",
      display_name: "Media",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing",
      sector_name: "Retailing",
      display_name: "Retailing",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/aac/ac",
      sector_name: "Auto Components",
      display_name: "Auto Components",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/aac/a",
      sector_name: "Automobiles",
      display_name: "Automobiles",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/aac/ac/apae",
      sector_name: "Auto Parts & Equipment",
      display_name: "Auto Parts & Equipment",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cs/dcs/scs",
      sector_name: "Tires & Rubber",
      display_name: "Tires & Rubber",
   },
   {
      chart_name:
         "exposure/sector/consumer_discretionary/a_and_components/a/am",
      sector_name: "Automobile Manufacturers",
      display_name: "Automobile Manufacturers",
   },
   {
      chart_name:
         "exposure/sector/consumer_discretionary/a_and_components/a/mm",
      sector_name: "Motocycle Manufactirers",
      display_name: "Motocycle Manufactirers",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/hd",
      sector_name: "Household Durables",
      display_name: "Household Durables",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/lp",
      sector_name: "Leisure Products",
      display_name: "Leisure Products",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/taalg",
      sector_name: "Textiles Apparel & Luxury",
      display_name: "Textiles Apparel & Luxury",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/hd/ce",
      sector_name: "Consumer Electronics",
      display_name: "Consumer Electronics",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/hd/hf",
      sector_name: "Home Furnishings",
      display_name: "Home Furnishings",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/hd/h",
      sector_name: "Homebuilding",
      display_name: "Homebuilding",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/hd/ha",
      sector_name: "Household Appliances",
      display_name: "Household Appliances",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/hd/has",
      sector_name: "Housewares & Specialties",
      display_name: "Housewares & Specialties",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/taalg/aaalg",
      sector_name: "Apparel Accessories & Luxury",
      display_name: "Apparel Accessories & Luxury",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/taalg/f",
      sector_name: "Footwear",
      display_name: "Footwear",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cdaa/taalg/t",
      sector_name: "Textiles",
      display_name: "Textiles",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cs/hral",
      sector_name: "Hotels/Restaurants/Leisure",
      display_name: "Hotels/Restaurants/Leisure",
   },
   {
      chart_name:
         "exposure/sector/consumer_discretionary/consumer_services/dcs",
      sector_name: "Diverse Consumer Services",
      display_name: "Diverse Consumer Services",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cs/hral/cag",
      sector_name: "Casinos & Gaming",
      display_name: "Casinos & Gaming",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cs/hral/hracl",
      sector_name: "Hotels Resorts & Cruises",
      display_name: "Hotels Resorts & Cruises",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cs/hral/lf",
      sector_name: "Leisure Facilities",
      display_name: "Leisure Facilities",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cs/hral/r",
      sector_name: "Restaurants",
      display_name: "Restaurants",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cs/dcs/es",
      sector_name: "Education Services",
      display_name: "Education Services",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/cs/dcs/scs",
      sector_name: "Specialized Consumer Services",
      display_name: "Specialized Consumer Services",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/media/advertising",
      sector_name: "Advertising",
      display_name: "Advertising",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/media/broadcasting",
      sector_name: "Broadcasting",
      display_name: "Broadcasting",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/media/cas",
      sector_name: "Cable & Satelite",
      display_name: "Cable & Satelite",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/media/mae",
      sector_name: "Movies & Entertainment",
      display_name: "Movies & Entertainment",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/media/publishing",
      sector_name: "Publishing ",
      display_name: "Publishing ",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/d",
      sector_name: "Distributors",
      display_name: "Distributors",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/iadmr",
      sector_name: "Internet & Direct Marketing ",
      display_name: "Internet & Direct Marketing ",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/mr",
      sector_name: "Muliline Retail",
      display_name: "Muliline Retail",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/sr",
      sector_name: "Specialty Retail",
      display_name: "Specialty Retail",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/mr/ds",
      sector_name: "Department Stores",
      display_name: "Department Stores",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/mr/gms",
      sector_name: "General Merchandise Stores",
      display_name: "General Merchandise Stores",
   },
   {
      chart_name:
         "exposure/sector/consumer_discretionary/retailing/sr/apparelr",
      sector_name: "Apparel Retail",
      display_name: "Apparel Retail",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/sr/caer",
      sector_name: "Computer & Electronics Retail",
      display_name: "Computer & Electronics Retail",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/sr/hir",
      sector_name: "Home Improvement Retail",
      display_name: "Home Improvement Retail",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/sr/ss",
      sector_name: "Specialty Stores",
      display_name: "Specialty Stores",
   },
   {
      chart_name:
         "exposure/sector/consumer_discretionary/retailing/sr/autoretail",
      sector_name: "Automotive Retail",
      display_name: "Automotive Retail",
   },
   {
      chart_name: "exposure/sector/consumer_discretionary/retailing/sr/hr",
      sector_name: "Homefurnishing Retail",
      display_name: "Homefurnishing Retail",
   },
   {
      chart_name: "exposure/sector/consumer_staples/food_and_staples_retailing",
      sector_name: "Food & Staples Retailing",
      display_name: "Food & Staples Retailing",
   },
   {
      chart_name: "exposure/sector/consumer_staples/food_beverage_and_tobacco",
      sector_name: "Food Beverage & Tobacco",
      display_name: "Food Beverage & Tobacco",
   },
   {
      chart_name: "exposure/sector/consumer_staples/happ",
      sector_name: "Household & Personal Products",
      display_name: "Household & Personal Products",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fasr/dr",
      sector_name: "Drug Retail",
      display_name: "Drug Retail",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fasr/fd",
      sector_name: "Food Distributors",
      display_name: "Food Distributors",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fasr/fr",
      sector_name: "Food Retail",
      display_name: "Food Retail",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fasr/hasc",
      sector_name: "Hypermarkets",
      display_name: "Hypermarkets",
   },
   {
      chart_name:
         "exposure/sector/consumer_staples/food_beverage_and_tobacco/b",
      sector_name: "Beverages",
      display_name: "Beverages",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fbat/fp",
      sector_name: "Food Products",
      display_name: "Food Products",
   },
   {
      chart_name:
         "exposure/sector/consumer_staples/food_beverage_and_tobacco/t",
      sector_name: "Tobacco",
      display_name: "Tobacco",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fbat/b/b",
      sector_name: "Brewers",
      display_name: "Brewers",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fbat/b/dav",
      sector_name: "Distillers and Vintners",
      display_name: "Distillers and Vintners",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fbat/b/sd",
      sector_name: "Soft Drinks",
      display_name: "Soft Drinks",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fbat/fp/ap",
      sector_name: "Agricultural Products",
      display_name: "Agricultural Products",
   },
   {
      chart_name: "exposure/sector/consumer_staples/fbat/fp/pfam",
      sector_name: "Packaged Foods",
      display_name: "Packaged Foods",
   },
   {
      chart_name: "exposure/sector/consumer_staples/happ/happ/hp",
      sector_name: "Household Products",
      display_name: "Household Products",
   },
   {
      chart_name: "exposure/sector/consumer_staples/happ/happ/pp",
      sector_name: "Personal Products",
      display_name: "Personal Products",
   },
   {
      chart_name: "exposure/sector/health_care/hceas",
      sector_name: "Healthcare Equip & Services",
      display_name: "Healthcare Equip & Services",
   },
   {
      chart_name: "exposure/sector/health_care/pbals",
      sector_name: "Pharma/Biotech/Life Science",
      display_name: "Pharma/Biotech/Life Science",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hceas",
      sector_name: "Healthcare Equip & Supplies",
      display_name: "Healthcare Equip & Supplies",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hcpas",
      sector_name: "Healthcare Providers & Services",
      display_name: "Healthcare Providers & Services",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hct",
      sector_name: "Healthcare Technology",
      display_name: "Healthcare Technology",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hceas/hce",
      sector_name: "Healthcare Equip",
      display_name: "Healthcare Equip",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hceas/hcs",
      sector_name: "Healthcare Supplies",
      display_name: "Healthcare Supplies",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hcpas/hcd",
      sector_name: "Healthcare Distributors",
      display_name: "Healthcare Distributors",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hcpas/hcs",
      sector_name: "Healthcare Services",
      display_name: "Healthcare Services",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hcpas/hcf",
      sector_name: "Healthcare Facilities",
      display_name: "Healthcare Facilities",
   },
   {
      chart_name: "exposure/sector/health_care/hceas/hcpas/mhc",
      sector_name: "Managed Healthcare",
      display_name: "Managed Healthcare",
   },
   {
      chart_name: "exposure/sector/health_care/pbals/biotechnology",
      sector_name: "Biotechnology",
      display_name: "Biotechnology",
   },
   {
      chart_name: "exposure/sector/health_care/pbals/p",
      sector_name: "Pharmaceuticals",
      display_name: "Pharmaceuticals",
   },
   {
      chart_name: "exposure/sector/health_care/pbals/lstas",
      sector_name: "Life Sciences Tools & Services",
      display_name: "Life Sciences Tools & Services",
   },
   {
      chart_name: "exposure/sector/health_care/pbals/biopharma",
      sector_name: "Biopharma",
      display_name: "Biopharma",
   },
   {
      chart_name: "exposure/sector/financials/banks",
      sector_name: "Banks",
      display_name: "Banks",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials",
      sector_name: "Diversified Financials",
      display_name: "Diversified Financials",
   },
   {
      chart_name: "exposure/sector/financials/insurance",
      sector_name: "Insurance",
      display_name: "Insurance",
   },
   {
      chart_name: "exposure/sector/financials/banks/diversified_banks",
      sector_name: "Diversified Banks",
      display_name: "Diversified Banks",
   },
   {
      chart_name: "exposure/sector/financials/banks/regional_banks",
      sector_name: "Regional Banks",
      display_name: "Regional Banks",
   },
   {
      chart_name: "exposure/sector/financials/banks/tamf",
      sector_name: "Thrifts & Mortgage Finance",
      display_name: "Thrifts & Mortgage Finance",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/dfs",
      sector_name: "Diverse Financial Services",
      display_name: "Diverse Financial Services",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/cf",
      sector_name: "Consumer Finance",
      display_name: "Consumer Finance",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/cm",
      sector_name: "Capital Markets",
      display_name: "Capital Markets",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/mreit",
      sector_name: "Mortgage REITs",
      display_name: "Mortgage REITs",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/dfs/odfs",
      sector_name: "Other Diversified Financials",
      display_name: "Other Diversified Financials",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/dfs/msh",
      sector_name: "Multi-Sector Holdings",
      display_name: "Multi-Sector Holdings",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/dfs/sf",
      sector_name: "Specialized Finance",
      display_name: "Specialized Finance",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/cm/amacb",
      sector_name: "Asset Mgmt & Custodians",
      display_name: "Asset Mgmt & Custodians",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/cm/ibab",
      sector_name: "Inv Banking & Brokerage",
      display_name: "Inv Banking & Brokerage",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/cm/dcm",
      sector_name: "Diverse Capital Mkts",
      display_name: "Diverse Capital Mkts",
   },
   {
      chart_name: "exposure/sector/financials/diversified_financials/cm/fead",
      sector_name: "Financial Exchanges",
      display_name: "Financial Exchanges",
   },
   {
      chart_name: "exposure/sector/financials/insurance/insurance_brokers",
      sector_name: "Insurance Brokers",
      display_name: "Insurance Brokers",
   },
   {
      chart_name: "exposure/sector/financials/insurance/lahi",
      sector_name: "Life & Health Insurance",
      display_name: "Life & Health Insurance",
   },
   {
      chart_name: "exposure/sector/financials/insurance/multi_line_insurance",
      sector_name: "Multi-Line Insurance",
      display_name: "Multi-Line Insurance",
   },
   {
      chart_name: "exposure/sector/financials/insurance/paci",
      sector_name: "Prop & Caslty Insurance",
      display_name: "Prop & Caslty Insurance",
   },
   {
      chart_name: "exposure/sector/financials/insurance/reinsurance",
      sector_name: "Reinsurance",
      display_name: "Reinsurance",
   },
   {
      chart_name:
         "exposure/sector/information_technology/software_and_services",
      sector_name: "Software & Drinks",
      display_name: "Software & Drinks",
   },
   {
      chart_name: "exposure/sector/information_technology/thae",
      sector_name: "Tech Hardware & Equip",
      display_name: "Tech Hardware & Equip",
   },
   {
      chart_name: "exposure/sector/information_technology/sase",
      sector_name: "Semiconductors/Equip",
      display_name: "Semiconductors/Equip",
   },
   {
      chart_name: "exposure/sector/information_technology/sas/isas",
      sector_name: "Internet/Software/Services",
      display_name: "Internet/Software/Services",
   },
   {
      chart_name: "exposure/sector/information_technology/sas/is",
      sector_name: "IT Services",
      display_name: "IT Services",
   },
   {
      chart_name: "exposure/sector/information_technology/sas/s",
      sector_name: "Software",
      display_name: "Software",
   },
   {
      chart_name: "exposure/sector/information_technology/thae/ce",
      sector_name: "Communications Equip",
      display_name: "Communications Equip",
   },
   {
      chart_name: "exposure/sector/information_technology/thae/thsap",
      sector_name: "Tech Hardware Storage",
      display_name: "Tech Hardware Storage",
   },
   {
      chart_name: "exposure/sector/information_technology/thae/eeiac",
      sector_name: "Elec Equip & Components",
      display_name: "Elec Equip & Components",
   },
   {
      chart_name: "exposure/sector/information_technology/sase/se",
      sector_name: "Semiconductor Equip",
      display_name: "Semiconductor Equip",
   },
   {
      chart_name: "exposure/sector/information_technology/sase/s",
      sector_name: "Semiconductors/Equip",
      display_name: "Semiconductors/Equip",
   },
   {
      chart_name: "exposure/sector/information_technology/sas/is/icaos",
      sector_name: "IT Consulting ",
      display_name: "IT Consulting ",
   },
   {
      chart_name: "exposure/sector/information_technology/sas/is/dpaos",
      sector_name: "Data Processing/Outsrc",
      display_name: "Data Processing/Outsrc",
   },
   {
      chart_name: "exposure/sector/information_technology/s_and_services/s/as",
      sector_name: "Application Software",
      display_name: "Application Software",
   },
   {
      chart_name: "exposure/sector/information_technology/s_and_services/s/ss",
      sector_name: "Systems Software",
      display_name: "Systems Software",
   },
   {
      chart_name: "exposure/sector/information_technology/s_and_services/s/hes",
      sector_name: "Home Entertainment",
      display_name: "Home Entertainment",
   },
   {
      chart_name: "exposure/sector/information_technology/thae/eeiac/eeai",
      sector_name: "Electronic Equipment",
      display_name: "Electronic Equipment",
   },
   {
      chart_name: "exposure/sector/information_technology/thae/eeiac/ec",
      sector_name: "Electronic Components",
      display_name: "Electronic Components",
   },
   {
      chart_name: "exposure/sector/information_technology/thae/eeiac/ems",
      sector_name: "Electronic Manufacturing",
      display_name: "Electronic Manufacturing",
   },
   {
      chart_name: "exposure/sector/information_technology/thae/eeiac/td",
      sector_name: "Tech Distributors",
      display_name: "Tech Distributors",
   },
   {
      chart_name: "exposure/sector/communication_services/dts",
      sector_name: "Diversified Communication",
      display_name: "Diversified Communication",
   },
   {
      chart_name: "exposure/sector/communication_services/wts",
      sector_name: "Wireless Communication",
      display_name: "Wireless Communication",
   },
   {
      chart_name: "exposure/sector/communication_services/dts/ac",
      sector_name: "Alternative Carriers",
      display_name: "Alternative Carriers",
   },
   {
      chart_name: "exposure/sector/communication_services/dts/its",
      sector_name: "Integrated Communication",
      display_name: "Integrated Communication",
   },
   {
      chart_name: "exposure/sector/utilities/electric_utilities",
      sector_name: "Electric Utilities",
      display_name: "Electric Utilities",
   },
   {
      chart_name: "exposure/sector/utilities/gas_utilities",
      sector_name: "Gas Utilities",
      display_name: "Gas Utilities",
   },
   {
      chart_name: "exposure/sector/utilities/multi_utilities",
      sector_name: "Multi-Utilities",
      display_name: "Multi-Utilities",
   },
   {
      chart_name: "exposure/sector/utilities/water_utilities",
      sector_name: "Water Utilities",
      display_name: "Water Utilities",
   },
   {
      chart_name: "exposure/sector/utilities/iparep",
      sector_name: "Independent Power",
      display_name: "Independent Power",
   },
   {
      chart_name: "exposure/sector/utilities/iparep/ippaet",
      sector_name: "Energy Traders",
      display_name: "Energy Traders",
   },
   {
      chart_name: "exposure/sector/utilities/iparep/re",
      sector_name: "Renewable Electricity",
      display_name: "Renewable Electricity",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit(reits)",
      sector_name: "Equity REITs",
      display_name: "Equity REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/remad",
      sector_name: "Real Estate Mgmt",
      display_name: "Real Estate Mgmt",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit/sdr",
      sector_name: "Diversified REITs",
      display_name: "Diversified REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit/sir",
      sector_name: "Industrial REITs",
      display_name: "Industrial REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit/sharr",
      sector_name: "Hotel & Resort REITs",
      display_name: "Hotel & Resort REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit/sor",
      sector_name: "Office REITs",
      display_name: "Office REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit/shcr",
      sector_name: "Healthcare REITs",
      display_name: "Healthcare REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit/sresidentialr",
      sector_name: "Residential REITs",
      display_name: "Residential REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit/sretailr",
      sector_name: "Retail REITs",
      display_name: "Retail REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/ereit/ssr",
      sector_name: "Specialized REITs",
      display_name: "Specialized REITs",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/remad/drea",
      sector_name: "Diverse Real Estate Activities",
      display_name: "Diverse Real Estate Activities",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/remad/reoc",
      sector_name: "Real Estate Operating Comps",
      display_name: "Real Estate Operating Comps",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/remad/red",
      sector_name: "Real Estate Development",
      display_name: "Real Estate Development",
   },
   {
      chart_name: "exposure/sector/sector_real_estate/remad/res",
      sector_name: "Reale Estate Services",
      display_name: "Reale Estate Services",
   },
];

export default {
   RegionChartMapping,
   SectorChartMapping,
};
