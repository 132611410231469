import React from "react";
// import _ from "lodash";
import moment from "moment";
import ActionTypes from "../action-types";
import { AllWorldIcon } from "SvgComponent";
import {
   AFS_COUNTRY_LIST,
   regionConfig,
   AfsRegionCodeMapper,
} from "constants/global";

import { CURRENCY } from "DataSet";

// let regions = [
//   {
//     code: 'EU',
//     hash: 'eur',
//     textIcon: '€',
//     name: 'Europe',
//     currency: 'Euro',
//     symbol: CURRENCY['Euro'],
//     icon: <EuropeIcon className={'country-icon'} />
//   },
//   {
//     code: 'USA',
//     hash: 'usa',
//     textIcon: '$',
//     name: 'United States',
//     currency: 'US Dollar',
//     symbol: CURRENCY['US Dollar'],
//     icon: <USACountryIcon className={'country-icon'} />
//   },
//   {
//     code: 'AUS',
//     hash: 'aus',
//     textIcon: '$',
//     name: 'Australia',
//     currency: 'Australian Dollar',
//     symbol: CURRENCY['Australian Dollar'],
//     icon: <AusIcon className={'country-icon'} />
//   },
//   {
//     code: 'CAN',
//     hash: 'can',
//     textIcon: '$',
//     name: 'Canada',
//     currency: 'Canadian Dollar',
//     symbol: CURRENCY['Canadian Dollar'],
//     icon: <CanadaIcon className={'country-icon'} />
//   },
//   {
//     code: 'CHN',
//     hash: 'chn',
//     name: 'China',
//     textIcon: '¥',
//     currency: 'Yen',
//     symbol: CURRENCY['Yen'],
//     icon: <ChinaIcon className={'country-icon'} />
//   },
//   {
//     code: 'NZL',
//     hash: 'nzl',
//     textIcon: '$',
//     name: 'New Zealand',
//     currency: 'New Zealand Dollar',
//     symbol: CURRENCY['New Zealand Dollar'],
//     icon: <NewZealandIcon className={'country-icon'} />
//   },
//   {
//     code: 'GBR',
//     hash: 'gbr',
//     textIcon: '£',
//     name: 'United Kingdom',
//     currency: 'Pound Sterling',
//     symbol: CURRENCY['Pound Sterling'],
//     icon: <UKIcon className={'country-icon'} />
//   },
//   {
//     code: 'ALL',
//     hash: 'all',
//     name: 'All',
//     textIcon: '$',
//     currency: 'US Dollar',
//     symbol: CURRENCY['US Dollar'],
//     icon: <AllWorldIcon className={'country-icon'} />
//   }
// ]
const countryStyle = { height: "18px", width: "25px", borderRadius: "3px" };
const regions = [
   // Default Region is Always the First List Item
   {
      code: "USA",
      hash: "usa",
      textIcon: "$",
      name: "United States",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: false,
      icon: (
         <img
            src={`/assets/images/flags/usa.png`}
            style={countryStyle}
            alt="flag"
         />
      ),
   },
   {
      code: "GBR",
      hash: "gbr",
      textIcon: "£",
      name: "United Kingdom",
      currency: "Pound Sterling",
      symbol: CURRENCY["Pound Sterling"],
      isRegion: false,
      icon: (
         <img
            src={`/assets/images/flags/gbr.png`}
            style={countryStyle}
            alt="flag"
         />
      ),
   },
   {
      code: "EURP",
      hash: "eur",
      textIcon: "€",
      name: "Europe",
      currency: "Euro",
      symbol: CURRENCY["Euro"],
      isRegion: true,
      icon: (
         <img
            src={`/assets/images/flags/eur.png`}
            style={countryStyle}
            alt="flag"
         />
      ),
   },
   {
      code: "MIDE",
      hash: "mide",
      textIcon: "$",
      name: "Middle East",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: true,
      icon: (
         <img
            src={`/assets/images/flags/---.png`}
            style={countryStyle}
            alt="flag"
         />
      ),
   },
   {
      code: "AUS",
      hash: "aus",
      textIcon: "$",
      name: "Australia",
      currency: "Australian Dollar",
      symbol: CURRENCY["Australian Dollar"],
      isRegion: false,
      icon: (
         <img
            src={`/assets/images/flags/aus.png`}
            style={countryStyle}
            alt="flag"
         />
      ),
   },
   {
      code: "APAC",
      hash: "apac",
      textIcon: "$",
      name: "Asia Pacific",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: true,
      icon: (
         <img
            src={`/assets/images/flags/---.png`}
            style={countryStyle}
            alt="flag"
         />
      ),
   },
   {
      code: "AMERICAS",
      hash: "americas",
      textIcon: "$",
      name: "Americas",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: true,
      icon: (
         <img
            src={`/assets/images/flags/---.png`}
            style={countryStyle}
            alt="flag"
         />
      ),
   },
   {
      code: "AFRC",
      hash: "afrc",
      textIcon: "$",
      name: "Africa",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      isRegion: true,
      icon: (
         <img
            src={`/assets/images/flags/---.png`}
            style={countryStyle}
            alt="flag"
         />
      ),
   },
   {
      code: "ALL",
      hash: "all",
      name: "All",
      textIcon: "$",
      currency: "US Dollar",
      symbol: CURRENCY["US Dollar"],
      icon: <AllWorldIcon className={"country-icon"} style={countryStyle} />,
   },
];

const selectedRegionFromSessionStorage =
   window.sessionStorage.getItem("selectedGlobalMarket") || "USA";
const selectedRegionDataFromSessionStorage =
   regions.find(region => region.code === selectedRegionFromSessionStorage) ||
   regions[0];

const initialState = {
   mobileDevice: false,
   tabDevice: false,
   smallScreen: false, //only screen and (min-width: 1024px) and (max-width: 1280px)
   desktopScreen: false,
   bigScreen: false,
   fundApiCallTime: moment().format("x"),
   fundApiCallDuration: moment().format("x"),
   fundsApiCallPercent: 0,
   surveyModal: window.sessionStorage.getItem("counter") === 2 || false,
   replacePremiumWithSurvey: false,
   portfolioReport: [],
   magnifiTickers: [],
   showJoinModal: false,
   globalMarket: selectedRegionFromSessionStorage,
   showDivBasedOnRegions: ["USA"],
   selectedRegionData: selectedRegionDataFromSessionStorage,
   showTrendingSearches: ["USA", "GBR", "EURP"],
   showRelatedSearches: ["USA", "GBR", "EURP"],
   // regions: _.sortBy(regions, ['code'])
   regions,
};

export function global(state = initialState, action) {
   const { payload } = action;
   switch (action.type) {
      case ActionTypes.REFRESH_GLOBAL_STATE:
         return {
            mobileDevice: false,
            tabDevice: false,
            smallScreen: false,
            desktopScreen: false,
            bigScreen: false,
            fundApiCallTime: moment().format("x"),
            fundApiCallDuration: moment().format("x"),
            fundsApiCallPercent: 0,
            surveyModal: false,
            replacePremiumWithSurvey: false,
            showJoinModal: false,
         };
      case ActionTypes.CLEAR_FUNDS_API_LOAD_PERCENT:
         return {
            ...state,
            fundApiCallTime: moment().format("x"),
            fundApiCallDuration: moment().format("x"),
            fundsApiCallPercent: 0,
         };
      case ActionTypes.UPDATE_LOAD_PERCENT:
         return {
            ...state,
            fundsApiCallPercent: payload.fundsApiCallPercent,
         };
      case ActionTypes.FUNDS_API_CALL_DURATION:
         return {
            ...state,
            fundApiCallDuration: payload.fundApiCallDuration,
         };
      case ActionTypes.FUNDS_API_CALL_TIME:
         // console.log(payload);
         return {
            ...state,
            fundApiCallTime: payload.fundApiCallTime,
         };
      case ActionTypes.SHOW_JOIN_MODAL:
         // console.log(payload);
         return {
            ...state,
            showJoinModal: payload.showJoinModal,
         };
      case ActionTypes.SET_GLOBAL_STATE:
         return {
            ...state,
            ...payload,
         };
      case ActionTypes.GET_ALL_TICKER_LIST_SUCCESS:
         return {
            ...state,
            ...payload,
         };
      // case ActionTypes.SET_GLOBAL_MARKET_STATE:
      //   let { globalMarket: payloadGLobalMarket } = payload
      //   let { selectedRegionData, regions } = state
      //   let foundRegion = regions.find(k => k.code === payloadGLobalMarket)
      //   if (foundRegion) {
      //     selectedRegionData = foundRegion
      //   }
      //   return {
      //     ...state,
      //     selectedRegionData,
      //     globalMarket: payloadGLobalMarket
      //   }

      case ActionTypes.SET_PROFILE_GLOBAL_MARKET_REGION:
         const { countryName: payloadCountry = "United States" } =
            action.payload;
         const countryDetails = AFS_COUNTRY_LIST.find(
            countryItem =>
               countryItem.name.toLowerCase() === payloadCountry.toLowerCase()
         );
         const setRegion =
            AfsRegionCodeMapper[countryDetails.continentCode] || "USA";
         const selectedRegionData = regionConfig.find(
            region => region.code === setRegion
         );
         window.sessionStorage.setItem("selectedGlobalMarket", setRegion);
         return {
            ...state,
            globalMarket: setRegion,
            selectedRegionData,
         };
      case ActionTypes.TOGGLE_GLOBAL_MARKET_REGION:
         const { market = "USA" } = action.payload;
         const selectedRegion = regionConfig.find(
            region => region.code.toLowerCase() === market.toLowerCase()
         );
         window.sessionStorage.setItem("toggledRegionDropdown", true);
         window.sessionStorage.setItem("selectedGlobalMarket", market);
         return {
            ...state,
            globalMarket: market,
            selectedRegionData: selectedRegion,
         };
      case ActionTypes.GET_GLOBAL_MARKET_STATE_SUCCESS:
         const { globalMarket: _payloadGLobalMarket } = payload;
         let { selectedRegionData: __selGlobalRegion, regions: _regions } =
            state;
         let _foundRegion = _regions.find(k => k.code === _payloadGLobalMarket);
         if (_foundRegion) {
            __selGlobalRegion = _foundRegion;
         }
         return {
            ...state,
            ...payload,
            selectedRegionData: __selGlobalRegion,
         };

      default:
         return state;
   }
}
