import { profilePhotoUploadRequest } from "actions/profile";
import React from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { createLoadingSelector } from "selectors/loading-selector";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import DragDropFile from "../../components/DragDropFile";

const FILE_SIZE = 15; // mb
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

class UploadPhoto extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         uploadedFile: [],
         isFileUploaded: false,
         selectedFile: [],
         previewImage: "",
         src: null,
         onError: "",
         onMessage: "",
         crop: {
            unit: "%", // Can be 'px' or '%'
            x: 25,
            y: 25,
            width: 50,
            height: 50,
         },
         isDisabled: true,
      };
   }
   componentDidMount() {
      const { isProfileEdit, profileEditUrl } = this.props;
      if (isProfileEdit && typeof profileEditUrl !== undefined) {
         this.onProfileEdit(profileEditUrl);
      } else {
      }
   }
   onImageLoaded = image => {
      this.imageRef = image;
   };

   onCropComplete = crop => {
      this.makeClientCrop(crop);
   };

   onCropChange = (crop, percentCrop) => {
      this.setState({ crop });
   };
   onUploadSuccess = response => {
      this.props.modelClose();
      if (response) {
         this.setState({ isDisabled: false });
      }
   };

   onProfileUpload = async () => {
      const { profilePhotoUploadRequest } = this.props;
      try {
         if (
            this.state.selectedFile &&
            typeof this.state.selectedFile !== undefined
         ) {
            let uploadFileData = new FormData();
            console.log(this.state.croppedImageUrl, "croppedImageUrl");
            console.log(this.state.selectedFile, "selectedFile");
            uploadFileData.append("avatarLogo", this.state.croppedImageUrl);
            uploadFileData.append("is_avatar_visible", true);
            this.setState({ isDisabled: true });
            if (profilePhotoUploadRequest) {
               profilePhotoUploadRequest(uploadFileData, this.onUploadSuccess);
            }
         } else {
            this.setState({ onError: "Image not loaded.", isDisabled: false });
         }
      } catch (e) {
         console.log(e, "error");
      }
   };

   async makeClientCrop(crop) {
      if (this.imageRef && crop.width && crop.height) {
         const { profile } = this.props;
         const fileName = profile
            ? profile.first_name +
              "_" +
              profile.last_name +
              "_" +
              new Date().getTime() +
              ".jpeg"
            : "newFile" + new Date().getTime() + ".jpeg";
         const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            fileName
         );
         this.setState({ croppedImageUrl });
      }
   }

   getCroppedImg(image, crop, fileName) {
      //image.setAttribute("crossOrigin", 'anonymous')
      const canvas = document.createElement("canvas");
      const pixelRatio = window.devicePixelRatio;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");

      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
         image,
         crop.x * scaleX,
         crop.y * scaleY,
         crop.width * scaleX,
         crop.height * scaleY,
         0,
         0,
         crop.width * scaleX,
         crop.height * scaleY
      );

      return new Promise((resolve, reject) => {
         canvas.toBlob(
            blob => {
               if (!blob) {
                  console.error("Canvas is empty");
                  return;
               }
               blob.name = fileName;
               window.URL.revokeObjectURL(this.fileUrl);
               this.fileUrl = window.URL.createObjectURL(blob);

               this.file = new File([blob], fileName, { type: "image/png" });
               resolve(this.file);
            },
            "image/jpeg",
            1
         );
      });
   }

   onSelectFile = files => {
      if (this.OnFileValidation(files, "select")) {
         if (files && files.length > 0) {
            const reader = new FileReader();
            this.setState({ selectedFile: files[0] });
            reader.addEventListener("load", () =>
               this.setState({ src: reader.result, isFileUploaded: true })
            );
            reader.readAsDataURL(files[0]);
         }
      }
   };
   onProfileEdit = files => {
      if (files && !this.state.isFileUploaded) {
         if (this.OnFileValidation(files, "edit")) {
            const reader = new FileReader();
            this.setState({ selectedFile: files });
            reader.addEventListener("load", () =>
               this.setState({ src: reader.result, isFileUploaded: true })
            );
            reader.readAsDataURL(files);
         }
      }
   };

   OnFileValidation = (file, type) => {
      let isFileValid = false;
      let files = file.length > 0 ? file[0] : file;
      if (files) {
         let fileSize = files.size / 1024 / 1024;
         if (!SUPPORTED_FORMATS.includes(files.type)) {
            this.setState({
               onError: "Only .png,.jpg,.jpeg, image format are allowed",
            });
         } else if (fileSize > FILE_SIZE) {
            this.setState({
               onError: `The file ${files.name} is too big. (files must be under 15MB in size).`,
            });
         } else if (type === "select" && fileSize > 2) {
            this.setState({
               onError: "Please select image size less than 2 MB",
            });
         } else {
            isFileValid = true;
            this.setState({ onError: "", isDisabled: false });
         }
      }
      return isFileValid;
   };

   render() {
      const { crop, src, isFileUploaded, isDisabled } = this.state;
      const { loading, isProfileEdit } = this.props;
      return (
         <React.Fragment>
            <div className="upload-profile-photo-modal-container">
               <div className="header">
                  <h1 className="">Upload Image</h1>
               </div>
               <div className="body-wrapper text-align-center">
                  {
                     <Container>
                        {isFileUploaded && src && (
                           <ReactCrop
                              className="react__crop"
                              style={{ borderRadius: "5px" }}
                              src={src}
                              crop={crop}
                              ruleOfThirds
                              onImageLoaded={this.onImageLoaded}
                              onComplete={this.onCropComplete}
                              onChange={this.onCropChange}
                              keepSelection={true}
                           ></ReactCrop>
                        )}
                        {!isFileUploaded && !isProfileEdit && (
                           <DragDropFile
                              onUploaded={this.onSelectFile}
                              error={this.state.onError}
                           />
                        )}

                        {loading && (
                           <div
                              style={{
                                 color: "#56a9e8",
                                 textAlign: "left",
                                 padding: "5px",
                                 fontWeight: "bold",
                              }}
                           >
                              Uploading...
                           </div>
                        )}

                        {this.state.onError && (
                           <div
                              style={{
                                 color: "#721c24",
                                 textAlign: "left",
                                 padding: "5px",
                              }}
                           >
                              {this.state.onError}
                           </div>
                        )}

                        <Row gutter={16} style={{ paddingTop: "30px" }}>
                           <Col
                              className="gutter-row"
                              xs={6}
                              style={{ textAlign: "right" }}
                           >
                              <Button
                                 shape="rounded"
                                 className="details-button"
                                 onClick={() => {
                                    this.props.modelClose();
                                 }}
                              >
                                 CANCEL
                              </Button>
                           </Col>
                           <Col className="gutter-row" xs={6}>
                              <Button
                                 shape="rounded"
                                 className="details-button-primary"
                                 onClick={() => this.onProfileUpload()}
                                 disabled={isDisabled}
                              >
                                 UPLOAD
                              </Button>
                           </Col>
                        </Row>
                     </Container>
                  }
               </div>
            </div>
         </React.Fragment>
      );
   }
}

const mapStateToProps = ({ loading, profile }) => ({
   loading:
      createLoadingSelector(["PROFILE_PHOTO_UPLOAD"])({ loading }) || false,
   profile: profile.profile,
});

const mapDispatchToProps = {
   profilePhotoUploadRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto);
