export const Regex = {
   phone: /^\+?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
   zipCode: /^\d{5}(?:[-\s]\d{4})?$/,
   //eslint-disable-next-line
   names: /^\+?([A-Za-z0-9\-]+)$/,
   //eslint-disable-next-line
   pobox: /^ *(?!(#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|num)?\d+))|(p(ost)? *(o(ff(ice)?)?)? *((box|bin)|b)? *\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(number|num|#)? *\d+|(num|number|#)|(?![a-zA-Z0-9\x20'#,]) *\d+)/i,
   //eslint-disable-next-line
   fullName: /^[A-Za-z0-9][A-Za-z0-9\s\-]*$/,
   someCharacter: /\S/,
};
