import ActionTypes from "../action-types";

export function refreshWeightState() {
   return {
      type: ActionTypes.REFRESH_WEIGHT_STATE,
   };
}

export function fetchWeightsData(callback) {
   return {
      type: ActionTypes.FETCH_WEIGHTS_DATA,
      callback,
   };
}

export function setInitialWeightsData(payload) {
   return {
      type: ActionTypes.SET_INITIAL_WEIGHTS,
      payload,
   };
}

export function setWeightsData(payload) {
   return {
      type: ActionTypes.SET_WEIGHTS_DATA,
      payload,
   };
}

export function postWeightsFormData(payload) {
   return {
      type: ActionTypes.POST_WEIGHTS_FORM_DATA,
      payload,
   };
}

export function saveScoreAttrs(payload) {
   return {
      type: ActionTypes.SAVE_SCORE_ATTRS,
      payload,
   };
}

export function changeCalculator(payload) {
   return {
      type: ActionTypes.CHANGE_CALCULATOR,
      payload,
   };
}

export function setCalculator(payload) {
   return {
      type: ActionTypes.SET_CALCULATOR,
      payload,
   };
}

export function setScoreAttrs(payload) {
   return {
      type: ActionTypes.SET_SCORE_ATTRS,
      payload,
   };
}

export function postScreenerAttrs(payload) {
   return {
      type: ActionTypes.POST_SCREENER_DATA,
      payload,
   };
}

export function setCustomCalcScoreAttrs(payload) {
   return {
      type: ActionTypes.SET_CUSTOM_SELECTOR_SCORE_ATTRS,
      payload,
   };
}

export function setCustomSelectorName(payload) {
   return {
      type: ActionTypes.SET_CUSTOM_SELECTOR_NAME,
      payload,
   };
}

export function updateCalculatorName(payload) {
   return {
      type: ActionTypes.UPDATE_CALCULATOR_NAME,
      payload,
   };
}

export function deleteCalculator(payload, callback) {
   return {
      type: ActionTypes.DELETE_CALCULATOR,
      payload,
      callback,
   };
}

export function updateCustomCalculatorList(payload) {
   return {
      type: ActionTypes.UPDATE_CUSTOM_CALCULATOR_LIST,
      payload,
   };
}

export function updateAllCalculatorList(payload) {
   return {
      type: ActionTypes.UPDATE_ALL_CALCULATOR_LIST,
      payload,
   };
}

export function updateAllSelectorAttrs(payload) {
   return {
      type: ActionTypes.UPDATE_ALL_SELECTOR_ATTRS,
      payload,
   };
}

export function updateDefaultCalculator(payload) {
   return {
      type: ActionTypes.UPDATE_DEFAULT_CALCULATOR,
      payload,
   };
}
