export const countryList = [
   {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
      flag: "🇦🇫",
   },
   {
      name: "Albania",
      dial_code: "+355",
      code: "AL",
      flag: "🇦🇱",
   },
   {
      name: "Algeria",
      dial_code: "+213",
      code: "DZ",
      flag: "🇩🇿",
   },
   {
      name: "AmericanSamoa",
      dial_code: "+1684",
      code: "AS",
      flag: "🇦🇸",
   },
   {
      name: "Andorra",
      dial_code: "+376",
      code: "AD",
      flag: "🇦🇩",
   },
   {
      name: "Angola",
      dial_code: "+244",
      code: "AO",
      flag: "🇦🇴",
   },
   {
      name: "Anguilla",
      dial_code: "+1264",
      code: "AI",
      flag: "🇦🇮",
   },
   {
      name: "Antarctica",
      dial_code: "+672",
      code: "AQ",
      flag: "🇦🇶",
   },
   {
      name: "Antigua and Barbuda",
      dial_code: "+1268",
      code: "AG",
      flag: "🇦🇬",
   },
   {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
      flag: "🇦🇷",
   },
   {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
      flag: "🇦🇲",
   },
   {
      name: "Aruba",
      dial_code: "+297",
      code: "AW",
      flag: "🇦🇼",
   },
   {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
      preferred: true,
      flag: "🇦🇺",
   },
   {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
      flag: "🇦🇹",
   },
   {
      name: "Azerbaijan",
      dial_code: "+994",
      code: "AZ",
      flag: "🇦🇿",
   },
   {
      name: "Bahamas",
      dial_code: "+1242",
      code: "BS",
      flag: "🇧🇸",
   },
   {
      name: "Bahrain",
      dial_code: "+973",
      code: "BH",
      flag: "🇧🇭",
   },
   {
      name: "Bangladesh",
      dial_code: "+880",
      code: "BD",
      flag: "🇧🇩",
   },
   {
      name: "Barbados",
      dial_code: "+1246",
      code: "BB",
      flag: "🇧🇧",
   },
   {
      name: "Belarus",
      dial_code: "+375",
      code: "BY",
      flag: "🇧🇾",
   },
   {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
      flag: "🇧🇪",
   },
   {
      name: "Belize",
      dial_code: "+501",
      code: "BZ",
      flag: "🇧🇿",
   },
   {
      name: "Benin",
      dial_code: "+229",
      code: "BJ",
      flag: "🇧🇯",
   },
   {
      name: "Bermuda",
      dial_code: "+1441",
      code: "BM",
      flag: "🇧🇲",
   },
   {
      name: "Bhutan",
      dial_code: "+975",
      code: "BT",
      flag: "🇧🇹",
   },
   {
      name: "Bolivia, Plurinational State of",
      dial_code: "+591",
      code: "BO",
      flag: "🇧🇴",
   },
   {
      name: "Bosnia and Herzegovina",
      dial_code: "+387",
      code: "BA",
      flag: "🇧🇦",
   },
   {
      name: "Botswana",
      dial_code: "+267",
      code: "BW",
      flag: "🇧🇼",
   },
   {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
      flag: "🇧🇷",
   },
   {
      name: "British Indian Ocean Territory",
      dial_code: "+246",
      code: "IO",
      flag: "🇮🇴",
   },
   {
      name: "Brunei Darussalam",
      dial_code: "+673",
      code: "BN",
      flag: "🇧🇳",
   },
   {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
      flag: "🇧🇬",
   },
   {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
      flag: "🇧🇫",
   },
   {
      name: "Burundi",
      dial_code: "+257",
      code: "BI",
      flag: "🇧🇮",
   },
   {
      name: "Cambodia",
      dial_code: "+855",
      code: "KH",
      flag: "🇰🇭",
   },
   {
      name: "Cameroon",
      dial_code: "+237",
      code: "CM",
      flag: "🇨🇲",
   },
   {
      name: "Canada",
      dial_code: "+1",
      code: "CA",
      flag: "🇨🇦",
   },
   {
      name: "Cape Verde",
      dial_code: "+238",
      code: "CV",
      flag: "🇨🇻",
   },
   {
      name: "Cayman Islands",
      dial_code: "+345",
      code: "KY",
      flag: "🇰🇾",
   },
   {
      name: "Central African Republic",
      dial_code: "+236",
      code: "CF",
      flag: "🇨🇫",
   },
   {
      name: "Chad",
      dial_code: "+235",
      code: "TD",
      flag: "🇹🇩",
   },
   {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
      flag: "🇨🇱",
   },
   {
      name: "China",
      dial_code: "+86",
      code: "CN",
      flag: "🇨🇳",
   },
   {
      name: "Christmas Island",
      dial_code: "+61",
      code: "CX",
      flag: "🇨🇽",
   },
   {
      name: "Cocos (Keeling) Islands",
      dial_code: "+61",
      code: "CC",
      flag: "🇨🇨",
   },
   {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
      flag: "🇨🇴",
   },
   {
      name: "Comoros",
      dial_code: "+269",
      code: "KM",
      flag: "🇰🇲",
   },
   {
      name: "Congo",
      dial_code: "+242",
      code: "CG",
      flag: "🇨🇬",
   },
   {
      name: "Congo, The Democratic Republic of the",
      dial_code: "+243",
      code: "CD",
      flag: "🇨🇩",
   },
   {
      name: "Cook Islands",
      dial_code: "+682",
      code: "CK",
      flag: "🇨🇰",
   },
   {
      name: "Costa Rica",
      dial_code: "+506",
      code: "CR",
      flag: "🇨🇷",
   },
   {
      name: "Cote d'Ivoire",
      dial_code: "+225",
      code: "CI",
      flag: "🇨🇮",
   },
   {
      name: "Croatia",
      dial_code: "+385",
      code: "HR",
      flag: "🇭🇷",
   },
   {
      name: "Cuba",
      dial_code: "+53",
      code: "CU",
      flag: "🇨🇺",
   },
   {
      name: "Cyprus",
      dial_code: "+537",
      code: "CY",
      flag: "🇨🇾",
   },
   {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
      flag: "🇨🇿",
   },
   {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
      flag: "🇩🇰",
   },
   {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
      flag: "🇩🇯",
   },
   {
      name: "Dominica",
      dial_code: "+1767",
      code: "DM",
      flag: "🇩🇲",
   },
   {
      name: "Dominican Republic",
      dial_code: "+1849",
      code: "DO",
      flag: "🇩🇴",
   },
   {
      name: "Ecuador",
      dial_code: "+593",
      code: "EC",
      flag: "🇪🇨",
   },
   {
      name: "Egypt",
      dial_code: "+20",
      code: "EG",
      flag: "🇪🇬",
   },
   {
      name: "El Salvador",
      dial_code: "+503",
      code: "SV",
      flag: "🇸🇻",
   },
   {
      name: "Equatorial Guinea",
      dial_code: "+240",
      code: "GQ",
      flag: "🇬🇶",
   },
   {
      name: "Eritrea",
      dial_code: "+291",
      code: "ER",
      flag: "🇪🇷",
   },
   {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
      flag: "🇪🇪",
   },
   {
      name: "Ethiopia",
      dial_code: "+251",
      code: "ET",
      flag: "🇪🇹",
   },
   {
      name: "Falkland Islands (Malvinas)",
      dial_code: "+500",
      code: "FK",
      flag: "🇫🇰",
   },
   {
      name: "Faroe Islands",
      dial_code: "+298",
      code: "FO",
      flag: "🇫🇴",
   },
   {
      name: "Fiji",
      dial_code: "+679",
      code: "FJ",
      flag: "🇫🇯",
   },
   {
      name: "Finland",
      dial_code: "+358",
      code: "FI",
      flag: "🇫🇮",
   },
   {
      name: "France",
      dial_code: "+33",
      code: "FR",
      flag: "🇫🇷",
   },
   {
      name: "French Guiana",
      dial_code: "+594",
      code: "GF",
      flag: "🇬🇫",
   },
   {
      name: "French Polynesia",
      dial_code: "+689",
      code: "PF",
      flag: "🇵🇫",
   },
   {
      name: "Gabon",
      dial_code: "+241",
      code: "GA",
      flag: "🇬🇦",
   },
   {
      name: "Gambia",
      dial_code: "+220",
      code: "GM",
      flag: "🇬🇲",
   },
   {
      name: "Georgia",
      dial_code: "+995",
      code: "GE",
      flag: "🇬🇪",
   },
   {
      name: "Germany",
      dial_code: "+49",
      code: "DE",
      flag: "🇩🇪",
   },
   {
      name: "Ghana",
      dial_code: "+233",
      code: "GH",
      flag: "🇬🇭",
   },
   {
      name: "Gibraltar",
      dial_code: "+350",
      code: "GI",
      flag: "🇬🇮",
   },
   {
      name: "Greece",
      dial_code: "+30",
      code: "GR",
      flag: "🇬🇷",
   },
   {
      name: "Greenland",
      dial_code: "+299",
      code: "GL",
      flag: "🇬🇱",
   },
   {
      name: "Grenada",
      dial_code: "+1473",
      code: "GD",
      flag: "🇬🇩",
   },
   {
      name: "Guadeloupe",
      dial_code: "+590",
      code: "GP",
      flag: "🇬🇵",
   },
   {
      name: "Guam",
      dial_code: "+1671",
      code: "GU",
      flag: "🇬🇺",
   },
   {
      name: "Guatemala",
      dial_code: "+502",
      code: "GT",
      flag: "🇬🇹",
   },
   {
      name: "Guernsey",
      dial_code: "+44",
      code: "GG",
      flag: "🇬🇬",
   },
   {
      name: "Guinea",
      dial_code: "+224",
      code: "GN",
      flag: "🇬🇳",
   },
   {
      name: "Guinea-Bissau",
      dial_code: "+245",
      code: "GW",
      flag: "🇬🇼",
   },
   {
      name: "Guyana",
      dial_code: "+595",
      code: "GY",
      flag: "🇬🇾",
   },
   {
      name: "Haiti",
      dial_code: "+509",
      code: "HT",
      flag: "🇭🇹",
   },
   {
      name: "Holy See (Vatican City State)",
      dial_code: "+379",
      code: "VA",
      flag: "🇻🇦",
   },
   {
      name: "Honduras",
      dial_code: "+504",
      code: "HN",
      flag: "🇭🇳",
   },
   {
      name: "Hong Kong",
      dial_code: "+852",
      code: "HK",
      flag: "🇭🇰",
   },
   {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
      flag: "🇭🇺",
   },
   {
      name: "Iceland",
      dial_code: "+354",
      code: "IS",
      flag: "🇮🇸",
   },
   {
      name: "India",
      dial_code: "+91",
      code: "IN",
      preferred: true,
      flag: "🇮🇳",
   },
   {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
      flag: "🇮🇩",
   },
   {
      name: "Iran, Islamic Republic of",
      dial_code: "+98",
      code: "IR",
      flag: "🇮🇷",
   },
   {
      name: "Iraq",
      dial_code: "+964",
      code: "IQ",
      flag: "🇮🇶",
   },
   {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
      flag: "🇮🇪",
   },
   {
      name: "Isle of Man",
      dial_code: "+44",
      code: "IM",
      flag: "🇮🇲",
   },
   {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
      flag: "🇮🇱",
   },
   {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
      flag: "🇮🇹",
   },
   {
      name: "Jamaica",
      dial_code: "+1876",
      code: "JM",
      flag: "🇯🇲",
   },
   {
      name: "Japan",
      dial_code: "+81",
      code: "JP",
      flag: "🇯🇵",
   },
   {
      name: "Jersey",
      dial_code: "+44",
      code: "JE",
      flag: "🇯🇪",
   },
   {
      name: "Jordan",
      dial_code: "+962",
      code: "JO",
      flag: "🇯🇴",
   },
   {
      name: "Kazakhstan",
      dial_code: "+77",
      code: "KZ",
      flag: "🇰🇿",
   },
   {
      name: "Kenya",
      dial_code: "+254",
      code: "KE",
      flag: "🇰🇪",
   },
   {
      name: "Kiribati",
      dial_code: "+686",
      code: "KI",
      flag: "🇰🇮",
   },
   {
      name: "Korea, Democratic People's Republic of",
      dial_code: "+850",
      code: "KP",
      flag: "🇰🇵",
   },
   {
      name: "Korea, Republic of",
      dial_code: "+82",
      code: "KR",
      flag: "🇰🇷",
   },
   {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
      flag: "🇰🇼",
   },
   {
      name: "Kyrgyzstan",
      dial_code: "+996",
      code: "KG",
      flag: "🇰🇬",
   },
   {
      name: "Lao People's Democratic Republic",
      dial_code: "+856",
      code: "LA",
      flag: "🇱🇦",
   },
   {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
      flag: "🇱🇻",
   },
   {
      name: "Lebanon",
      dial_code: "+961",
      code: "LB",
      flag: "🇱🇧",
   },
   {
      name: "Lesotho",
      dial_code: "+266",
      code: "LS",
      flag: "🇱🇸",
   },
   {
      name: "Liberia",
      dial_code: "+231",
      code: "LR",
      flag: "🇱🇷",
   },
   {
      name: "Libyan Arab Jamahiriya",
      dial_code: "+218",
      code: "LY",
      flag: "🇱🇾",
   },
   {
      name: "Liechtenstein",
      dial_code: "+423",
      code: "LI",
      flag: "🇱🇮",
   },
   {
      name: "Lithuania",
      dial_code: "+370",
      code: "LT",
      flag: "🇱🇹",
   },
   {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
      flag: "🇱🇺",
   },
   {
      name: "Macao",
      dial_code: "+853",
      code: "MO",
      flag: "🇲🇴",
   },
   {
      name: "Macedonia, The Former Yugoslav Republic of",
      dial_code: "+389",
      code: "MK",
      flag: "🇲🇰",
   },
   {
      name: "Madagascar",
      dial_code: "+261",
      code: "MG",
      flag: "🇲🇬",
   },
   {
      name: "Malawi",
      dial_code: "+265",
      code: "MW",
      flag: "🇲🇼",
   },
   {
      name: "Malaysia",
      dial_code: "+60",
      code: "MY",
      flag: "🇲🇾",
   },
   {
      name: "Maldives",
      dial_code: "+960",
      code: "MV",
      flag: "🇲🇻",
   },
   {
      name: "Mali",
      dial_code: "+223",
      code: "ML",
      flag: "🇲🇱",
   },
   {
      name: "Malta",
      dial_code: "+356",
      code: "MT",
      flag: "🇲🇹",
   },
   {
      name: "Marshall Islands",
      dial_code: "+692",
      code: "MH",
      flag: "🇲🇭",
   },
   {
      name: "Martinique",
      dial_code: "+596",
      code: "MQ",
      flag: "🇲🇶",
   },
   {
      name: "Mauritania",
      dial_code: "+222",
      code: "MR",
      flag: "🇲🇷",
   },
   {
      name: "Mauritius",
      dial_code: "+230",
      code: "MU",
      flag: "🇲🇺",
   },
   {
      name: "Mayotte",
      dial_code: "+262",
      code: "YT",
      flag: "🇾🇹",
   },
   {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
      flag: "🇲🇽",
   },
   {
      name: "Micronesia, Federated States of",
      dial_code: "+691",
      code: "FM",
      flag: "🇫🇲",
   },
   {
      name: "Moldova, Republic of",
      dial_code: "+373",
      code: "MD",
      flag: "🇲🇩",
   },
   {
      name: "Monaco",
      dial_code: "+377",
      code: "MC",
      flag: "🇲🇨",
   },
   {
      name: "Mongolia",
      dial_code: "+976",
      code: "MN",
      flag: "🇲🇳",
   },
   {
      name: "Montenegro",
      dial_code: "+382",
      code: "ME",
      flag: "🇲🇪",
   },
   {
      name: "Montserrat",
      dial_code: "+1664",
      code: "MS",
      flag: "🇲🇸",
   },
   {
      name: "Morocco",
      dial_code: "+212",
      code: "MA",
      flag: "🇲🇦",
   },
   {
      name: "Mozambique",
      dial_code: "+258",
      code: "MZ",
      flag: "🇲🇿",
   },
   {
      name: "Myanmar",
      dial_code: "+95",
      code: "MM",
      flag: "🇲🇲",
   },
   {
      name: "Namibia",
      dial_code: "+264",
      code: "NA",
      flag: "🇳🇦",
   },
   {
      name: "Nauru",
      dial_code: "+674",
      code: "NR",
      flag: "🇳🇷",
   },
   {
      name: "Nepal",
      dial_code: "+977",
      code: "NP",
      flag: "🇳🇵",
   },
   {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
      flag: "🇳🇱",
   },
   {
      name: "Netherlands Antilles",
      dial_code: "+599",
      code: "AN",
      flag: "🇦🇳",
   },
   {
      name: "New Caledonia",
      dial_code: "+687",
      code: "NC",
      flag: "🇳🇨",
   },
   {
      name: "New Zealand",
      dial_code: "+64",
      code: "NZ",
      flag: "🇳🇿",
   },
   {
      name: "Nicaragua",
      dial_code: "+505",
      code: "NI",
      flag: "🇳🇮",
   },
   {
      name: "Niger",
      dial_code: "+227",
      code: "NE",
      flag: "🇳🇪",
   },
   {
      name: "Nigeria",
      dial_code: "+234",
      code: "NG",
      flag: "🇳🇬",
   },
   {
      name: "Niue",
      dial_code: "+683",
      code: "NU",
      flag: "🇳🇺",
   },
   {
      name: "Norfolk Island",
      dial_code: "+672",
      code: "NF",
      flag: "🇳🇫",
   },
   {
      name: "Northern Mariana Islands",
      dial_code: "+1670",
      code: "MP",
      flag: "🇲🇵",
   },
   {
      name: "Norway",
      dial_code: "+47",
      code: "NO",
      flag: "🇳🇴",
   },
   {
      name: "Oman",
      dial_code: "+968",
      code: "OM",
      flag: "🇴🇲",
   },
   {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
      flag: "🇵🇰",
   },
   {
      name: "Palau",
      dial_code: "+680",
      code: "PW",
      flag: "🇵🇼",
   },
   {
      name: "Palestinian Territory, Occupied",
      dial_code: "+970",
      code: "PS",
      flag: "🇵🇸",
   },
   {
      name: "Panama",
      dial_code: "+507",
      code: "PA",
      flag: "🇵🇦",
   },
   {
      name: "Papua New Guinea",
      dial_code: "+675",
      code: "PG",
      flag: "🇵🇬",
   },
   {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
      flag: "🇵🇾",
   },
   {
      name: "Peru",
      dial_code: "+51",
      code: "PE",
      flag: "🇵🇪",
   },
   {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
      flag: "🇵🇭",
   },
   {
      name: "Pitcairn",
      dial_code: "+872",
      code: "PN",
      flag: "🇵🇳",
   },
   {
      name: "Poland",
      dial_code: "+48",
      code: "PL",
      flag: "🇵🇱",
   },
   {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
      flag: "🇵🇹",
   },
   {
      name: "Puerto Rico",
      dial_code: "+1939",
      code: "PR",
      flag: "🇵🇷",
   },
   {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
      flag: "🇶🇦",
   },
   {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
      flag: "🇷🇴",
   },
   {
      name: "Russia",
      dial_code: "+7",
      code: "RU",
      flag: "🇷🇺",
   },
   {
      name: "Rwanda",
      dial_code: "+250",
      code: "RW",
      flag: "🇷🇼",
   },
   {
      name: "Réunion",
      dial_code: "+262",
      code: "RE",
      flag: "🇷🇪",
   },
   {
      name: "Saint Barthélemy",
      dial_code: "+590",
      code: "BL",
      flag: "🇧🇱",
   },
   {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "+290",
      code: "SH",
      flag: "🇸🇭",
   },
   {
      name: "Saint Kitts and Nevis",
      dial_code: "+1869",
      code: "KN",
      flag: "🇰🇳",
   },
   {
      name: "Saint Lucia",
      dial_code: "+1758",
      code: "LC",
      flag: "🇱🇨",
   },
   {
      name: "Saint Martin",
      dial_code: "+590",
      code: "MF",
      flag: "🇲🇫",
   },
   {
      name: "Saint Pierre and Miquelon",
      dial_code: "+508",
      code: "PM",
      flag: "🇵🇲",
   },
   {
      name: "Saint Vincent and the Grenadines",
      dial_code: "+1784",
      code: "VC",
      flag: "🇻🇨",
   },
   {
      name: "Samoa",
      dial_code: "+685",
      code: "WS",
      flag: "🇼🇸",
   },
   {
      name: "San Marino",
      dial_code: "+378",
      code: "SM",
      flag: "🇸🇲",
   },
   {
      name: "Sao Tome and Principe",
      dial_code: "+239",
      code: "ST",
      flag: "🇸🇹",
   },
   {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
      flag: "🇸🇦",
   },
   {
      name: "Senegal",
      dial_code: "+221",
      code: "SN",
      flag: "🇸🇳",
   },
   {
      name: "Serbia",
      dial_code: "+381",
      code: "RS",
      flag: "🇷🇸",
   },
   {
      name: "Seychelles",
      dial_code: "+248",
      code: "SC",
      flag: "🇸🇨",
   },
   {
      name: "Sierra Leone",
      dial_code: "+232",
      code: "SL",
      flag: "🇸🇱",
   },
   {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
      flag: "🇸🇬",
   },
   {
      name: "Slovakia",
      dial_code: "+421",
      code: "SK",
      flag: "🇸🇰",
   },
   {
      name: "Slovenia",
      dial_code: "+386",
      code: "SI",
      flag: "🇸🇮",
   },
   {
      name: "Solomon Islands",
      dial_code: "+677",
      code: "SB",
      flag: "🇸🇧",
   },
   {
      name: "Somalia",
      dial_code: "+252",
      code: "SO",
      flag: "🇸🇴",
   },
   {
      name: "South Africa",
      dial_code: "+27",
      code: "ZA",
      flag: "🇿🇦",
   },
   {
      name: "South Georgia and the South Sandwich Islands",
      dial_code: "+500",
      code: "GS",
      flag: "🇬🇸",
   },
   {
      name: "Spain",
      dial_code: "+34",
      code: "ES",
      flag: "🇪🇸",
   },
   {
      name: "Sri Lanka",
      dial_code: "+94",
      code: "LK",
      flag: "🇱🇰",
   },
   {
      name: "Sudan",
      dial_code: "+249",
      code: "SD",
      flag: "🇸🇩",
   },
   {
      name: "Suriname",
      dial_code: "+597",
      code: "SR",
      flag: "🇸🇷",
   },
   {
      name: "Svalbard and Jan Mayen",
      dial_code: "+47",
      code: "SJ",
      flag: "🇸🇯",
   },
   {
      name: "Swaziland",
      dial_code: "+268",
      code: "SZ",
      flag: "🇸🇿",
   },
   {
      name: "Sweden",
      dial_code: "+46",
      code: "SE",
      flag: "🇸🇪",
   },
   {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
      flag: "🇨🇭",
   },
   {
      name: "Syrian Arab Republic",
      dial_code: "+963",
      code: "SY",
      flag: "🇸🇾",
   },
   {
      name: "Taiwan, Province of China",
      dial_code: "+886",
      code: "TW",
      flag: "🇹🇼",
   },
   {
      name: "Tajikistan",
      dial_code: "+992",
      code: "TJ",
      flag: "🇹🇯",
   },
   {
      name: "Tanzania, United Republic of",
      dial_code: "+255",
      code: "TZ",
      flag: "🇹🇿",
   },
   {
      name: "Thailand",
      dial_code: "+66",
      code: "TH",
      flag: "🇹🇭",
   },
   {
      name: "Timor-Leste",
      dial_code: "+670",
      code: "TL",
      flag: "🇹🇱",
   },
   {
      name: "Togo",
      dial_code: "+228",
      code: "TG",
      flag: "🇹🇬",
   },
   {
      name: "Tokelau",
      dial_code: "+690",
      code: "TK",
      flag: "🇹🇰",
   },
   {
      name: "Tonga",
      dial_code: "+676",
      code: "TO",
      flag: "🇹🇴",
   },
   {
      name: "Trinidad and Tobago",
      dial_code: "+1868",
      code: "TT",
      flag: "🇹🇹",
   },
   {
      name: "Tunisia",
      dial_code: "+216",
      code: "TN",
      flag: "🇹🇳",
   },
   {
      name: "Turkey",
      dial_code: "+90",
      code: "TR",
      flag: "🇹🇷",
   },
   {
      name: "Turkmenistan",
      dial_code: "+993",
      code: "TM",
      flag: "🇹🇲",
   },
   {
      name: "Turks and Caicos Islands",
      dial_code: "+1649",
      code: "TC",
      flag: "🇹🇨",
   },
   {
      name: "Tuvalu",
      dial_code: "+688",
      code: "TV",
      flag: "🇹🇻",
   },
   {
      name: "Uganda",
      dial_code: "+256",
      code: "UG",
      flag: "🇺🇬",
   },
   {
      name: "Ukraine",
      dial_code: "+380",
      code: "UA",
      flag: "🇺🇦",
   },
   {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
      preferred: true,
      flag: "🇦🇪",
   },
   {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
      preferred: true,
      flag: "🇬🇧",
   },
   {
      name: "United States",
      dial_code: "+1",
      code: "US",
      preferred: true,
      flag: "🇺🇸",
   },
   {
      name: "Uruguay",
      dial_code: "+598",
      code: "UY",
      flag: "🇺🇾",
   },
   {
      name: "Uzbekistan",
      dial_code: "+998",
      code: "UZ",
      flag: "🇺🇿",
   },
   {
      name: "Vanuatu",
      dial_code: "+678",
      code: "VU",
      flag: "🇻🇺",
   },
   {
      name: "Venezuela, Bolivarian Republic of",
      dial_code: "+58",
      code: "VE",
      flag: "🇻🇪",
   },
   {
      name: "Viet Nam",
      dial_code: "+84",
      code: "VN",
      flag: "🇻🇳",
   },
   {
      name: "Virgin Islands, British",
      dial_code: "+1284",
      code: "VG",
      flag: "🇻🇬",
   },
   {
      name: "Virgin Islands, U.S.",
      dial_code: "+1340",
      code: "VI",
      flag: "🇻🇮",
   },
   {
      name: "Wallis and Futuna",
      dial_code: "+681",
      code: "WF",
      flag: "🇼🇫",
   },
   {
      name: "Yemen",
      dial_code: "+967",
      code: "YE",
      flag: "🇾🇪",
   },
   {
      name: "Zambia",
      dial_code: "+260",
      code: "ZM",
      flag: "🇿🇲",
   },
   {
      name: "Zimbabwe",
      dial_code: "+263",
      code: "ZW",
      flag: "🇿🇼",
   },
   {
      name: "Åland Islands",
      dial_code: "+358",
      code: "AX",
      flag: "🇦🇽",
   },
];

export const countryList1 = [
   {
      name: "United States",
      code: "US",
   },
];

export const areYouAnList =
   process.env.REACT_APP_SUB_DOMAIN === "advisor"
      ? [
           "RIA",
           "Hybrid Advisor",
           "Broker/Dealer/Wirehouse Advisor",
           "Asset Manager",
           "Hedge Fund Manager",
           "Portfolio Manager",
           "Corporate/Business Development",
           "Institutional Investor",
           // 'Other/Curious'
        ]
      : ["Individual Investor"];

export const investableAssetsList = [
   // 'Under $30,000',
   // '$30,000 to $60,000',
   // '$60,000 to $100,000',
   // '$100,000 to $150,000',
   // '$150,000 to $250,000',
   // 'More than $250,000',
   "Under $250,000",
   "$250,000 to $500,000",
   "$500,000 to $2,000,000",
   "More than $2,000,000",
];

export const assetUnderManagementList = [
   "Under $10 million",
   "$10 million to $50 million",
   "$50 million to $100 million",
   "$100 million to $500 million",
   "$500 million to $1 billion",
   "$1 billion to $2.5 billion",
   "$2.5 billion to $5 billion",
   "$5 billion to $10 billion",
   "More than $10 billion",
];

export const whereDidYouHear = [
   "Word of Mouth",
   "Google",
   "Bing",
   "E-Trade",
   "Fidelity",
   "Vanguard",
   "Robinhood",
   "Ally",
   "Tradestation",
   "Interactive Brokers",
   "Ameritrade",
   "Raymond James",
   "Charles Schwab",
   "Fi360 Broadridge Company",
];

export const ageGroupList = [
   "18 - 24",
   "25 - 34",
   "35 - 44",
   "45 - 54",
   "55 - 64",
   "65+",
];

export const ageGroupMapping = [
   { key: "0", value: "18 - 24" },
   { key: "1", value: "25 - 34" },
   { key: "2", value: "35 - 44" },
   { key: "3", value: "45 - 54" },
   { key: "4", value: "55 - 64" },
   { key: "5", value: "65+" },
];

export const getAgeMapping = varName => {
   const foundVar = ageGroupMapping.filter(i => i.value === varName);
   if (foundVar.length) {
      return foundVar[0].key;
   }
   return false;
};

export const accountType = [
   { key: "Individual", value: "Individual", disabled: false },
   { key: "Joint", value: "Joint" },
   { key: "Custodial", value: "Custodial", disabled: true },
];
export const employmentType = ["Student", "Employed", "Retired", "Unemployed"];
export const employmentTypeTradingAccount = [
   { name: "--", value: "", disabled: true },
   { name: "Student", value: "Student", disabled: false },
   { name: "Employed", value: "Employed", disabled: false },
   { name: "Retired", value: "Retired", disabled: false },
   { name: "Unemployed", value: "Unemployed", disabled: false },
];

export const maritalStatus = ["Single", "Married"];
export const uploadType = ["Upload 407", "Upload 3210"];
export const uploadTypeTradAcc = [
   { name: "Select", value: "", disabled: true },
   { name: "Upload 407", value: "upload_407", disabled: false },
   { name: "Upload 3210", value: "upload_3210", disabled: false },
];

export const tradingExperience = [
   { key: "NONE", value: "None" },
   { key: "LIMITED", value: "Limited" },
   { key: "GOOD", value: "Good" },
   { key: "EXTENSIVE", value: "Extensive" },
];
export const transferType = [
   "PARTIAL", //'PARTIAL_TRANSFER_RECEIVE',
   "COMPLETE", //'FULL_TRANSFER',
];

export const occupation = ["Salaried", "Business", "Partner"];
export const occupationDisclosure = [
   { name: "--", value: "", disabled: true },
   { name: "Salaried", value: "Salaried", disabled: false },
   { name: "Business", value: "Business", disabled: false },
   { name: "Partner", value: "Partner", disabled: false },
];

export const relations = [
   "Father",
   "Mother",
   "Brother",
   "Sister",
   "Husband",
   "Wife",
];

export const financialGoals = [
   { name: "Short (Less than 3)", value: "Short" },
   { name: "Average (4 to 7 years)", value: "Average" },
   { name: "Longest (8 or more)", value: "Longest" },
];
export const financialGoalsTradingAccount = [
   { name: "--", value: "", disabled: true },
   { name: "Short (Less than 3)", value: "SHORT", disabled: false },
   { name: "Average (4 to 7 years)", value: "AVERAGE", disabled: false },
   { name: "Longest (8 or more)", value: "LONGEST", disabled: false },
];
export const liquidityTime = [
   "Very Important",
   "Somewhat Important",
   "Not Important",
];
export const liquidityTimeTradingAccount = [
   { name: "--", value: "", disabled: true },
   { name: "Very Important", value: "VERY_IMPORTANT", disabled: false },
   { name: "Somewhat Important", value: "SOMEWHAT_IMPORTANT", disabled: false },
   { name: "Not Important", value: "NOT_IMPORTANT", disabled: false },
];

export const usStates = [
   {
      name: "Alabama",
      abbreviation: "AL",
   },
   {
      name: "Alaska",
      abbreviation: "AK",
   },
   {
      name: "American Samoa",
      abbreviation: "AS",
   },
   {
      name: "Arizona",
      abbreviation: "AZ",
   },
   {
      name: "Arkansas",
      abbreviation: "AR",
   },
   {
      name: "California",
      abbreviation: "CA",
   },
   {
      name: "Colorado",
      abbreviation: "CO",
   },
   {
      name: "Connecticut",
      abbreviation: "CT",
   },
   {
      name: "Delaware",
      abbreviation: "DE",
   },
   {
      name: "District Of Columbia",
      abbreviation: "DC",
   },
   {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
   },
   {
      name: "Florida",
      abbreviation: "FL",
   },
   {
      name: "Georgia",
      abbreviation: "GA",
   },
   {
      name: "Guam Gu",
      abbreviation: "GU",
   },
   {
      name: "Hawaii",
      abbreviation: "HI",
   },
   {
      name: "Idaho",
      abbreviation: "ID",
   },
   {
      name: "Illinois",
      abbreviation: "IL",
   },
   {
      name: "Indiana",
      abbreviation: "IN",
   },
   {
      name: "Iowa",
      abbreviation: "IA",
   },
   {
      name: "Kansas",
      abbreviation: "KS",
   },
   {
      name: "Kentucky",
      abbreviation: "KY",
   },
   {
      name: "Louisiana",
      abbreviation: "LA",
   },
   {
      name: "Maine",
      abbreviation: "ME",
   },
   {
      name: "Marshall Islands",
      abbreviation: "MH",
   },
   {
      name: "Maryland",
      abbreviation: "MD",
   },
   {
      name: "Massachusetts",
      abbreviation: "MA",
   },
   {
      name: "Michigan",
      abbreviation: "MI",
   },
   {
      name: "Minnesota",
      abbreviation: "MN",
   },
   {
      name: "Mississippi",
      abbreviation: "MS",
   },
   {
      name: "Missouri",
      abbreviation: "MO",
   },
   {
      name: "Montana",
      abbreviation: "MT",
   },
   {
      name: "Nebraska",
      abbreviation: "NE",
   },
   {
      name: "Nevada",
      abbreviation: "NV",
   },
   {
      name: "New Hampshire",
      abbreviation: "NH",
   },
   {
      name: "New Jersey",
      abbreviation: "NJ",
   },
   {
      name: "New Mexico",
      abbreviation: "NM",
   },
   {
      name: "New York",
      abbreviation: "NY",
   },
   {
      name: "North Carolina",
      abbreviation: "NC",
   },
   {
      name: "North Dakota",
      abbreviation: "ND",
   },
   {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
   },
   {
      name: "Ohio",
      abbreviation: "OH",
   },
   {
      name: "Oklahoma",
      abbreviation: "OK",
   },
   {
      name: "Oregon",
      abbreviation: "OR",
   },
   {
      name: "Palau",
      abbreviation: "PW",
   },
   {
      name: "Pennsylvania",
      abbreviation: "PA",
   },
   {
      name: "Puerto Rico",
      abbreviation: "PR",
   },
   {
      name: "Rhode Island",
      abbreviation: "RI",
   },
   {
      name: "South Carolina",
      abbreviation: "SC",
   },
   {
      name: "South Dakota",
      abbreviation: "SD",
   },
   {
      name: "Tennessee",
      abbreviation: "TN",
   },
   {
      name: "Texas",
      abbreviation: "TX",
   },
   {
      name: "Utah",
      abbreviation: "UT",
   },
   {
      name: "Vermont",
      abbreviation: "VT",
   },
   {
      name: "Virgin Islands",
      abbreviation: "VI",
   },
   {
      name: "Virginia",
      abbreviation: "VA",
   },
   {
      name: "Washington",
      abbreviation: "WA",
   },
   {
      name: "West Virginia",
      abbreviation: "WV",
   },
   {
      name: "Wisconsin",
      abbreviation: "WI",
   },
   {
      name: "Wyoming",
      abbreviation: "WY",
   },
];

export const trustedContactCountry = [
   {
      name: "Afghanistan",
      "alpha-3": "AFG",
      "country-code": "004",
   },
   {
      name: "Åland Islands",
      "alpha-3": "ALA",
      "country-code": "248",
   },
   {
      name: "Albania",
      "alpha-3": "ALB",
      "country-code": "008",
   },
   {
      name: "Algeria",
      "alpha-3": "DZA",
      "country-code": "012",
   },
   {
      name: "American Samoa",
      "alpha-3": "ASM",
      "country-code": "016",
   },
   {
      name: "Andorra",
      "alpha-3": "AND",
      "country-code": "020",
   },
   {
      name: "Angola",
      "alpha-3": "AGO",
      "country-code": "024",
   },
   {
      name: "Anguilla",
      "alpha-3": "AIA",
      "country-code": "660",
   },
   {
      name: "Antarctica",
      "alpha-3": "ATA",
      "country-code": "010",
   },
   {
      name: "Antigua and Barbuda",
      "alpha-3": "ATG",
      "country-code": "028",
   },
   {
      name: "Argentina",
      "alpha-3": "ARG",
      "country-code": "032",
   },
   {
      name: "Armenia",
      "alpha-3": "ARM",
      "country-code": "051",
   },
   {
      name: "Aruba",
      "alpha-3": "ABW",
      "country-code": "533",
   },
   {
      name: "Australia",
      "alpha-3": "AUS",
      "country-code": "036",
   },
   {
      name: "Austria",
      "alpha-3": "AUT",
      "country-code": "040",
   },
   {
      name: "Azerbaijan",
      "alpha-3": "AZE",
      "country-code": "031",
   },
   {
      name: "Bahamas",
      "alpha-3": "BHS",
      "country-code": "044",
   },
   {
      name: "Bahrain",
      "alpha-3": "BHR",
      "country-code": "048",
   },
   {
      name: "Bangladesh",
      "alpha-3": "BGD",
      "country-code": "050",
   },
   {
      name: "Barbados",
      "alpha-3": "BRB",
      "country-code": "052",
   },
   {
      name: "Belarus",
      "alpha-3": "BLR",
      "country-code": "112",
   },
   {
      name: "Belgium",
      "alpha-3": "BEL",
      "country-code": "056",
   },
   {
      name: "Belize",
      "alpha-3": "BLZ",
      "country-code": "084",
   },
   {
      name: "Benin",
      "alpha-3": "BEN",
      "country-code": "204",
   },
   {
      name: "Bermuda",
      "alpha-3": "BMU",
      "country-code": "060",
   },
   {
      name: "Bhutan",
      "alpha-3": "BTN",
      "country-code": "064",
   },
   {
      name: "Bolivia (Plurinational State of)",
      "alpha-3": "BOL",
      "country-code": "068",
   },
   {
      name: "Bonaire, Sint Eustatius and Saba",
      "alpha-3": "BES",
      "country-code": "535",
   },
   {
      name: "Bosnia and Herzegovina",
      "alpha-3": "BIH",
      "country-code": "070",
   },
   {
      name: "Botswana",
      "alpha-3": "BWA",
      "country-code": "072",
   },
   {
      name: "Bouvet Island",
      "alpha-3": "BVT",
      "country-code": "074",
   },
   {
      name: "Brazil",
      "alpha-3": "BRA",
      "country-code": "076",
   },
   {
      name: "British Indian Ocean Territory",
      "alpha-3": "IOT",
      "country-code": "086",
   },
   {
      name: "Brunei Darussalam",
      "alpha-3": "BRN",
      "country-code": "096",
   },
   {
      name: "Bulgaria",
      "alpha-3": "BGR",
      "country-code": "100",
   },
   {
      name: "Burkina Faso",
      "alpha-3": "BFA",
      "country-code": "854",
   },
   {
      name: "Burundi",
      "alpha-3": "BDI",
      "country-code": "108",
   },
   {
      name: "Cabo Verde",
      "alpha-3": "CPV",
      "country-code": "132",
   },
   {
      name: "Cambodia",
      "alpha-3": "KHM",
      "country-code": "116",
   },
   {
      name: "Cameroon",
      "alpha-3": "CMR",
      "country-code": "120",
   },
   {
      name: "Canada",
      "alpha-3": "CAN",
      "country-code": "124",
   },
   {
      name: "Cayman Islands",
      "alpha-3": "CYM",
      "country-code": "136",
   },
   {
      name: "Central African Republic",
      "alpha-3": "CAF",
      "country-code": "140",
   },
   {
      name: "Chad",
      "alpha-3": "TCD",
      "country-code": "148",
   },
   {
      name: "Chile",
      "alpha-3": "CHL",
      "country-code": "152",
   },
   {
      name: "China",
      "alpha-3": "CHN",
      "country-code": "156",
   },
   {
      name: "Christmas Island",
      "alpha-3": "CXR",
      "country-code": "162",
   },
   {
      name: "Cocos (Keeling) Islands",
      "alpha-3": "CCK",
      "country-code": "166",
   },
   {
      name: "Colombia",
      "alpha-3": "COL",
      "country-code": "170",
   },
   {
      name: "Comoros",
      "alpha-3": "COM",
      "country-code": "174",
   },
   {
      name: "Congo",
      "alpha-3": "COG",
      "country-code": "178",
   },
   {
      name: "Congo, Democratic Republic of the",
      "alpha-3": "COD",
      "country-code": "180",
   },
   {
      name: "Cook Islands",
      "alpha-3": "COK",
      "country-code": "184",
   },
   {
      name: "Costa Rica",
      "alpha-3": "CRI",
      "country-code": "188",
   },
   {
      name: "Côte d'Ivoire",
      "alpha-3": "CIV",
      "country-code": "384",
   },
   {
      name: "Croatia",
      "alpha-3": "HRV",
      "country-code": "191",
   },
   {
      name: "Cuba",
      "alpha-3": "CUB",
      "country-code": "192",
   },
   {
      name: "Curaçao",
      "alpha-3": "CUW",
      "country-code": "531",
   },
   {
      name: "Cyprus",
      "alpha-3": "CYP",
      "country-code": "196",
   },
   {
      name: "Czechia",
      "alpha-3": "CZE",
      "country-code": "203",
   },
   {
      name: "Denmark",
      "alpha-3": "DNK",
      "country-code": "208",
   },
   {
      name: "Djibouti",
      "alpha-3": "DJI",
      "country-code": "262",
   },
   {
      name: "Dominica",
      "alpha-3": "DMA",
      "country-code": "212",
   },
   {
      name: "Dominican Republic",
      "alpha-3": "DOM",
      "country-code": "214",
   },
   {
      name: "Ecuador",
      "alpha-3": "ECU",
      "country-code": "218",
   },
   {
      name: "Egypt",
      "alpha-3": "EGY",
      "country-code": "818",
   },
   {
      name: "El Salvador",
      "alpha-3": "SLV",
      "country-code": "222",
   },
   {
      name: "Equatorial Guinea",
      "alpha-3": "GNQ",
      "country-code": "226",
   },
   {
      name: "Eritrea",
      "alpha-3": "ERI",
      "country-code": "232",
   },
   {
      name: "Estonia",
      "alpha-3": "EST",
      "country-code": "233",
   },
   {
      name: "Eswatini",
      "alpha-3": "SWZ",
      "country-code": "748",
   },
   {
      name: "Ethiopia",
      "alpha-3": "ETH",
      "country-code": "231",
   },
   {
      name: "Falkland Islands (Malvinas)",
      "alpha-3": "FLK",
      "country-code": "238",
   },
   {
      name: "Faroe Islands",
      "alpha-3": "FRO",
      "country-code": "234",
   },
   {
      name: "Fiji",
      "alpha-3": "FJI",
      "country-code": "242",
   },
   {
      name: "Finland",
      "alpha-3": "FIN",
      "country-code": "246",
   },
   {
      name: "France",
      "alpha-3": "FRA",
      "country-code": "250",
   },
   {
      name: "French Guiana",
      "alpha-3": "GUF",
      "country-code": "254",
   },
   {
      name: "French Polynesia",
      "alpha-3": "PYF",
      "country-code": "258",
   },
   {
      name: "French Southern Territories",
      "alpha-3": "ATF",
      "country-code": "260",
   },
   {
      name: "Gabon",
      "alpha-3": "GAB",
      "country-code": "266",
   },
   {
      name: "Gambia",
      "alpha-3": "GMB",
      "country-code": "270",
   },
   {
      name: "Georgia",
      "alpha-3": "GEO",
      "country-code": "268",
   },
   {
      name: "Germany",
      "alpha-3": "DEU",
      "country-code": "276",
   },
   {
      name: "Ghana",
      "alpha-3": "GHA",
      "country-code": "288",
   },
   {
      name: "Gibraltar",
      "alpha-3": "GIB",
      "country-code": "292",
   },
   {
      name: "Greece",
      "alpha-3": "GRC",
      "country-code": "300",
   },
   {
      name: "Greenland",
      "alpha-3": "GRL",
      "country-code": "304",
   },
   {
      name: "Grenada",
      "alpha-3": "GRD",
      "country-code": "308",
   },
   {
      name: "Guadeloupe",
      "alpha-3": "GLP",
      "country-code": "312",
   },
   {
      name: "Guam",
      "alpha-3": "GUM",
      "country-code": "316",
   },
   {
      name: "Guatemala",
      "alpha-3": "GTM",
      "country-code": "320",
   },
   {
      name: "Guernsey",
      "alpha-3": "GGY",
      "country-code": "831",
   },
   {
      name: "Guinea",
      "alpha-3": "GIN",
      "country-code": "324",
   },
   {
      name: "Guinea-Bissau",
      "alpha-3": "GNB",
      "country-code": "624",
   },
   {
      name: "Guyana",
      "alpha-3": "GUY",
      "country-code": "328",
   },
   {
      name: "Haiti",
      "alpha-3": "HTI",
      "country-code": "332",
   },
   {
      name: "Heard Island and McDonald Islands",
      "alpha-3": "HMD",
      "country-code": "334",
   },
   {
      name: "Holy See",
      "alpha-3": "VAT",
      "country-code": "336",
   },
   {
      name: "Honduras",
      "alpha-3": "HND",
      "country-code": "340",
   },
   {
      name: "Hong Kong",
      "alpha-3": "HKG",
      "country-code": "344",
   },
   {
      name: "Hungary",
      "alpha-3": "HUN",
      "country-code": "348",
   },
   {
      name: "Iceland",
      "alpha-3": "ISL",
      "country-code": "352",
   },
   {
      name: "India",
      "alpha-3": "IND",
      "country-code": "356",
   },
   {
      name: "Indonesia",
      "alpha-3": "IDN",
      "country-code": "360",
   },
   {
      name: "Iran (Islamic Republic of)",
      "alpha-3": "IRN",
      "country-code": "364",
   },
   {
      name: "Iraq",
      "alpha-3": "IRQ",
      "country-code": "368",
   },
   {
      name: "Ireland",
      "alpha-3": "IRL",
      "country-code": "372",
   },
   {
      name: "Isle of Man",
      "alpha-3": "IMN",
      "country-code": "833",
   },
   {
      name: "Israel",
      "alpha-3": "ISR",
      "country-code": "376",
   },
   {
      name: "Italy",
      "alpha-3": "ITA",
      "country-code": "380",
   },
   {
      name: "Jamaica",
      "alpha-3": "JAM",
      "country-code": "388",
   },
   {
      name: "Japan",
      "alpha-3": "JPN",
      "country-code": "392",
   },
   {
      name: "Jersey",
      "alpha-3": "JEY",
      "country-code": "832",
   },
   {
      name: "Jordan",
      "alpha-3": "JOR",
      "country-code": "400",
   },
   {
      name: "Kazakhstan",
      "alpha-3": "KAZ",
      "country-code": "398",
   },
   {
      name: "Kenya",
      "alpha-3": "KEN",
      "country-code": "404",
   },
   {
      name: "Kiribati",
      "alpha-3": "KIR",
      "country-code": "296",
   },
   {
      name: "Korea (Democratic People's Republic of)",
      "alpha-3": "PRK",
      "country-code": "408",
   },
   {
      name: "Korea, Republic of",
      "alpha-3": "KOR",
      "country-code": "410",
   },
   {
      name: "Kuwait",
      "alpha-3": "KWT",
      "country-code": "414",
   },
   {
      name: "Kyrgyzstan",
      "alpha-3": "KGZ",
      "country-code": "417",
   },
   {
      name: "Lao People's Democratic Republic",
      "alpha-3": "LAO",
      "country-code": "418",
   },
   {
      name: "Latvia",
      "alpha-3": "LVA",
      "country-code": "428",
   },
   {
      name: "Lebanon",
      "alpha-3": "LBN",
      "country-code": "422",
   },
   {
      name: "Lesotho",
      "alpha-3": "LSO",
      "country-code": "426",
   },
   {
      name: "Liberia",
      "alpha-3": "LBR",
      "country-code": "430",
   },
   {
      name: "Libya",
      "alpha-3": "LBY",
      "country-code": "434",
   },
   {
      name: "Liechtenstein",
      "alpha-3": "LIE",
      "country-code": "438",
   },
   {
      name: "Lithuania",
      "alpha-3": "LTU",
      "country-code": "440",
   },
   {
      name: "Luxembourg",
      "alpha-3": "LUX",
      "country-code": "442",
   },
   {
      name: "Macao",
      "alpha-3": "MAC",
      "country-code": "446",
   },
   {
      name: "Madagascar",
      "alpha-3": "MDG",
      "country-code": "450",
   },
   {
      name: "Malawi",
      "alpha-3": "MWI",
      "country-code": "454",
   },
   {
      name: "Malaysia",
      "alpha-3": "MYS",
      "country-code": "458",
   },
   {
      name: "Maldives",
      "alpha-3": "MDV",
      "country-code": "462",
   },
   {
      name: "Mali",
      "alpha-3": "MLI",
      "country-code": "466",
   },
   {
      name: "Malta",
      "alpha-3": "MLT",
      "country-code": "470",
   },
   {
      name: "Marshall Islands",
      "alpha-3": "MHL",
      "country-code": "584",
   },
   {
      name: "Martinique",
      "alpha-3": "MTQ",
      "country-code": "474",
   },
   {
      name: "Mauritania",
      "alpha-3": "MRT",
      "country-code": "478",
   },
   {
      name: "Mauritius",
      "alpha-3": "MUS",
      "country-code": "480",
   },
   {
      name: "Mayotte",
      "alpha-3": "MYT",
      "country-code": "175",
   },
   {
      name: "Mexico",
      "alpha-3": "MEX",
      "country-code": "484",
   },
   {
      name: "Micronesia (Federated States of)",
      "alpha-3": "FSM",
      "country-code": "583",
   },
   {
      name: "Moldova, Republic of",
      "alpha-3": "MDA",
      "country-code": "498",
   },
   {
      name: "Monaco",
      "alpha-3": "MCO",
      "country-code": "492",
   },
   {
      name: "Mongolia",
      "alpha-3": "MNG",
      "country-code": "496",
   },
   {
      name: "Montenegro",
      "alpha-3": "MNE",
      "country-code": "499",
   },
   {
      name: "Montserrat",
      "alpha-3": "MSR",
      "country-code": "500",
   },
   {
      name: "Morocco",
      "alpha-3": "MAR",
      "country-code": "504",
   },
   {
      name: "Mozambique",
      "alpha-3": "MOZ",
      "country-code": "508",
   },
   {
      name: "Myanmar",
      "alpha-3": "MMR",
      "country-code": "104",
   },
   {
      name: "Namibia",
      "alpha-3": "NAM",
      "country-code": "516",
   },
   {
      name: "Nauru",
      "alpha-3": "NRU",
      "country-code": "520",
   },
   {
      name: "Nepal",
      "alpha-3": "NPL",
      "country-code": "524",
   },
   {
      name: "Netherlands",
      "alpha-3": "NLD",
      "country-code": "528",
   },
   {
      name: "New Caledonia",
      "alpha-3": "NCL",
      "country-code": "540",
   },
   {
      name: "New Zealand",
      "alpha-3": "NZL",
      "country-code": "554",
   },
   {
      name: "Nicaragua",
      "alpha-3": "NIC",
      "country-code": "558",
   },
   {
      name: "Niger",
      "alpha-3": "NER",
      "country-code": "562",
   },
   {
      name: "Nigeria",
      "alpha-3": "NGA",
      "country-code": "566",
   },
   {
      name: "Niue",
      "alpha-3": "NIU",
      "country-code": "570",
   },
   {
      name: "Norfolk Island",
      "alpha-3": "NFK",
      "country-code": "574",
   },
   {
      name: "North Macedonia",
      "alpha-3": "MKD",
      "country-code": "807",
   },
   {
      name: "Northern Mariana Islands",
      "alpha-3": "MNP",
      "country-code": "580",
   },
   {
      name: "Norway",
      "alpha-3": "NOR",
      "country-code": "578",
   },
   {
      name: "Oman",
      "alpha-3": "OMN",
      "country-code": "512",
   },
   {
      name: "Pakistan",
      "alpha-3": "PAK",
      "country-code": "586",
   },
   {
      name: "Palau",
      "alpha-3": "PLW",
      "country-code": "585",
   },
   {
      name: "Palestine, State of",
      "alpha-3": "PSE",
      "country-code": "275",
   },
   {
      name: "Panama",
      "alpha-3": "PAN",
      "country-code": "591",
   },
   {
      name: "Papua New Guinea",
      "alpha-3": "PNG",
      "country-code": "598",
   },
   {
      name: "Paraguay",
      "alpha-3": "PRY",
      "country-code": "600",
   },
   {
      name: "Peru",
      "alpha-3": "PER",
      "country-code": "604",
   },
   {
      name: "Philippines",
      "alpha-3": "PHL",
      "country-code": "608",
   },
   {
      name: "Pitcairn",
      "alpha-3": "PCN",
      "country-code": "612",
   },
   {
      name: "Poland",
      "alpha-3": "POL",
      "country-code": "616",
   },
   {
      name: "Portugal",
      "alpha-3": "PRT",
      "country-code": "620",
   },
   {
      name: "Puerto Rico",
      "alpha-3": "PRI",
      "country-code": "630",
   },
   {
      name: "Qatar",
      "alpha-3": "QAT",
      "country-code": "634",
   },
   {
      name: "Réunion",
      "alpha-3": "REU",
      "country-code": "638",
   },
   {
      name: "Romania",
      "alpha-3": "ROU",
      "country-code": "642",
   },
   {
      name: "Russian Federation",
      "alpha-3": "RUS",
      "country-code": "643",
   },
   {
      name: "Rwanda",
      "alpha-3": "RWA",
      "country-code": "646",
   },
   {
      name: "Saint Barthélemy",
      "alpha-3": "BLM",
      "country-code": "652",
   },
   {
      name: "Saint Helena, Ascension and Tristan da Cunha",
      "alpha-3": "SHN",
      "country-code": "654",
   },
   {
      name: "Saint Kitts and Nevis",
      "alpha-3": "KNA",
      "country-code": "659",
   },
   {
      name: "Saint Lucia",
      "alpha-3": "LCA",
      "country-code": "662",
   },
   {
      name: "Saint Martin (French part)",
      "alpha-3": "MAF",
      "country-code": "663",
   },
   {
      name: "Saint Pierre and Miquelon",
      "alpha-3": "SPM",
      "country-code": "666",
   },
   {
      name: "Saint Vincent and the Grenadines",
      "alpha-3": "VCT",
      "country-code": "670",
   },
   {
      name: "Samoa",
      "alpha-3": "WSM",
      "country-code": "882",
   },
   {
      name: "San Marino",
      "alpha-3": "SMR",
      "country-code": "674",
   },
   {
      name: "Sao Tome and Principe",
      "alpha-3": "STP",
      "country-code": "678",
   },
   {
      name: "Saudi Arabia",
      "alpha-3": "SAU",
      "country-code": "682",
   },
   {
      name: "Senegal",
      "alpha-3": "SEN",
      "country-code": "686",
   },
   {
      name: "Serbia",
      "alpha-3": "SRB",
      "country-code": "688",
   },
   {
      name: "Seychelles",
      "alpha-3": "SYC",
      "country-code": "690",
   },
   {
      name: "Sierra Leone",
      "alpha-3": "SLE",
      "country-code": "694",
   },
   {
      name: "Singapore",
      "alpha-3": "SGP",
      "country-code": "702",
   },
   {
      name: "Sint Maarten (Dutch part)",
      "alpha-3": "SXM",
      "country-code": "534",
   },
   {
      name: "Slovakia",
      "alpha-3": "SVK",
      "country-code": "703",
   },
   {
      name: "Slovenia",
      "alpha-3": "SVN",
      "country-code": "705",
   },
   {
      name: "Solomon Islands",
      "alpha-3": "SLB",
      "country-code": "090",
   },
   {
      name: "Somalia",
      "alpha-3": "SOM",
      "country-code": "706",
   },
   {
      name: "South Africa",
      "alpha-3": "ZAF",
      "country-code": "710",
   },
   {
      name: "South Georgia and the South Sandwich Islands",
      "alpha-3": "SGS",
      "country-code": "239",
   },
   {
      name: "South Sudan",
      "alpha-3": "SSD",
      "country-code": "728",
   },
   {
      name: "Spain",
      "alpha-3": "ESP",
      "country-code": "724",
   },
   {
      name: "Sri Lanka",
      "alpha-3": "LKA",
      "country-code": "144",
   },
   {
      name: "Sudan",
      "alpha-3": "SDN",
      "country-code": "729",
   },
   {
      name: "Suriname",
      "alpha-3": "SUR",
      "country-code": "740",
   },
   {
      name: "Svalbard and Jan Mayen",
      "alpha-3": "SJM",
      "country-code": "744",
   },
   {
      name: "Sweden",
      "alpha-3": "SWE",
      "country-code": "752",
   },
   {
      name: "Switzerland",
      "alpha-3": "CHE",
      "country-code": "756",
   },
   {
      name: "Syrian Arab Republic",
      "alpha-3": "SYR",
      "country-code": "760",
   },
   {
      name: "Taiwan, Province of China",
      "alpha-3": "TWN",
      "country-code": "158",
   },
   {
      name: "Tajikistan",
      "alpha-3": "TJK",
      "country-code": "762",
   },
   {
      name: "Tanzania, United Republic of",
      "alpha-3": "TZA",
      "country-code": "834",
   },
   {
      name: "Thailand",
      "alpha-3": "THA",
      "country-code": "764",
   },
   {
      name: "Timor-Leste",
      "alpha-3": "TLS",
      "country-code": "626",
   },
   {
      name: "Togo",
      "alpha-3": "TGO",
      "country-code": "768",
   },
   {
      name: "Tokelau",
      "alpha-3": "TKL",
      "country-code": "772",
   },
   {
      name: "Tonga",
      "alpha-3": "TON",
      "country-code": "776",
   },
   {
      name: "Trinidad and Tobago",
      "alpha-3": "TTO",
      "country-code": "780",
   },
   {
      name: "Tunisia",
      "alpha-3": "TUN",
      "country-code": "788",
   },
   {
      name: "Turkey",
      "alpha-3": "TUR",
      "country-code": "792",
   },
   {
      name: "Turkmenistan",
      "alpha-3": "TKM",
      "country-code": "795",
   },
   {
      name: "Turks and Caicos Islands",
      "alpha-3": "TCA",
      "country-code": "796",
   },
   {
      name: "Tuvalu",
      "alpha-3": "TUV",
      "country-code": "798",
   },
   {
      name: "Uganda",
      "alpha-3": "UGA",
      "country-code": "800",
   },
   {
      name: "Ukraine",
      "alpha-3": "UKR",
      "country-code": "804",
   },
   {
      name: "United Arab Emirates",
      "alpha-3": "ARE",
      "country-code": "784",
   },
   {
      name: "United Kingdom of Great Britain and Northern Ireland",
      "alpha-3": "GBR",
      "country-code": "826",
   },
   {
      name: "United States",
      "alpha-3": "USA",
      "country-code": "840",
   },
   {
      name: "United States Minor Outlying Islands",
      "alpha-3": "UMI",
      "country-code": "581",
   },
   {
      name: "Uruguay",
      "alpha-3": "URY",
      "country-code": "858",
   },
   {
      name: "Uzbekistan",
      "alpha-3": "UZB",
      "country-code": "860",
   },
   {
      name: "Vanuatu",
      "alpha-3": "VUT",
      "country-code": "548",
   },
   {
      name: "Venezuela (Bolivarian Republic of)",
      "alpha-3": "VEN",
      "country-code": "862",
   },
   {
      name: "Viet Nam",
      "alpha-3": "VNM",
      "country-code": "704",
   },
   {
      name: "Virgin Islands (British)",
      "alpha-3": "VGB",
      "country-code": "092",
   },
   {
      name: "Virgin Islands (U.S.)",
      "alpha-3": "VIR",
      "country-code": "850",
   },
   {
      name: "Wallis and Futuna",
      "alpha-3": "WLF",
      "country-code": "876",
   },
   {
      name: "Western Sahara",
      "alpha-3": "ESH",
      "country-code": "732",
   },
   {
      name: "Yemen",
      "alpha-3": "YEM",
      "country-code": "887",
   },
   {
      name: "Zambia",
      "alpha-3": "ZMB",
      "country-code": "894",
   },
   {
      name: "Zimbabwe",
      "alpha-3": "ZWE",
      "country-code": "716",
   },
];

export const contraPartyList = [
   { value: "0005", name: "GOLDMAN SACHS & CO. LLC", disabled: false },
   {
      value: "0010",
      name: "BROWN BROTHERS HARRIMAN & CO. 0013 SANFORD C. BERNSTEIN & CO., LLC 0015 MORGAN STANLEY SMITH BARNEY LLC 0017 INTERACTIVE BROKERS LLC",
      disabled: false,
   },
   { value: "0019", name: "JEFFERIES LLC", disabled: false },
   { value: "0031", name: "NATIXIS SECURITIES AMERICAS LLC", disabled: false },
   {
      value: "0032",
      name: "DEUTSCHE BANK SECURITIES INC.- STOCK LOAN",
      disabled: false,
   },
   {
      value: "0033",
      name: "COMMERZ MARKETS LLC/FIXED INC. REPO & COMM. PAPER",
      disabled: false,
   },
   { value: "0045", name: "BMO CAPITAL MARKETS CORP.", disabled: false },
   { value: "0046", name: "PHILLIP CAPITAL INC./STOCK LOAN", disabled: false },
   {
      value: "0050",
      name: "MORGAN STANLEY & CO. LLC 0052 AXOS CLEARING LLC",
      disabled: false,
   },
   { value: "0057", name: "EDWARD D. JONES & CO.", disabled: false },
   { value: "0062", name: "VANGUARD MARKETING CORPORATION", disabled: false },
   {
      value: "0063",
      name: "VIRTU AMERICAS LLC/VIRTU FINANCIAL BD LLC",
      disabled: false,
   },
   {
      value: "0065",
      name: "ZIONS DIRECT, INC. 0067 INSTINET, LLC",
      disabled: false,
   },
   { value: "0075", name: "LPL FINANCIAL LLC", disabled: false },
   { value: "0076", name: "MUFG SECURITIES AMERICAS INC.", disabled: false },
   { value: "0083", name: "TRADEBOT SYSTEMS, INC.", disabled: false },
   { value: "0096", name: "SCOTIA CAPITAL (USA) INC.", disabled: false },
   { value: "0099", name: "VIRTU AMERICAS LLC/VIRTU ITG LLC", disabled: false },
   { value: "0100", name: "COWEN AND COMPANY LLC", disabled: false },
   {
      value: "0101",
      name: "MORGAN STANLEY & CO LLC/SL CONDUIT",
      disabled: false,
   },
   { value: "0103", name: "WEDBUSH SECURITIES INC.", disabled: false },
   {
      value: "0109",
      name: "BROWN BROTHERS HARRIMAN & CO./ETF 0114 MACQUARIE CAPITAL (USA) INC.",
      disabled: false,
   },
   { value: "0124", name: "INGALLS & SNYDER, LLC", disabled: false },
   { value: "0126", name: "COMMERZ MARKETS LLC", disabled: false },
   {
      value: "0136",
      name: "INTESA SANPAOLO IMI SECURITIES CORP. 0141 WELLS FARGO CLEARING SERVICES, LLC 0148 ICAP CORPORATES LLC",
      disabled: false,
   },
   { value: "0158", name: "APEX CLEARING CORPORATION", disabled: false },
   { value: "0161", name: "BOFA SECURITIES, INC.", disabled: false },
   { value: "0163", name: "NASDAQ BX, INC.", disabled: false },
   { value: "0164", name: "CHARLES SCHWAB & CO., INC.", disabled: false },
   { value: "0166", name: "ARCOLA SECURITIES, INC.", disabled: false },
   {
      value: "0180",
      name: "NOMURA SECURITIES INTERNATIONAL, INC.",
      disabled: false,
   },
   { value: "0181", name: "GUGGENHEIM SECURITIES, LLC", disabled: false },
   { value: "0187", name: "J.P. MORGAN SECURITIES LLC", disabled: false },
   { value: "0188", name: "TD AMERITRADE CLEARING, INC.", disabled: false },
   { value: "0189", name: "STATE STREET GLOBAL MARKETS, LLC", disabled: false },
   {
      value: "0197",
      name: "CANTOR FITZGERALD & CO. / CANTOR CLEARING SERVICES",
      disabled: false,
   },
   {
      value: "0202",
      name: "FHN FINANCIAL SECURITIES CORP. 0221 UBS FINANCIAL SERVICES INC.",
      disabled: false,
   },
   { value: "0229", name: "BARCLAYS CAPITAL INC./LE", disabled: false },
   {
      value: "0226",
      name: "NATIONAL FINANCIAL SERVICES LLC 0235 RBC CAPITAL MARKETS, LLC",
      disabled: false,
   },
   { value: "0237", name: "NASDAQ PHLX LLC", disabled: false },
   { value: "0250", name: "WELLS FARGO SECURITIES, LLC", disabled: false },
   { value: "0270", name: "ING FINANCIAL MARKETS LLC", disabled: false },
   { value: "0271", name: "TRADESTATION SECURITIES, INC.", disabled: false },
   {
      value: "0274",
      name: "CITIGROUP GLOBAL MARKETS INC./SALOMON BROTHERS",
      disabled: false,
   },
   { value: "0279", name: "HILLTOP SECURITIES INC.", disabled: false },
   { value: "0280", name: "U.S. BANCORP INVESTMENTS, INC.", disabled: false },
   { value: "0283", name: "WILSON-DAVIS & CO., INC.", disabled: false },
   { value: "0284", name: "TD PRIME SERVICES LLC", disabled: false },
   { value: "0286", name: "SG AMERICAS SECURITIES, LLC", disabled: false },
   { value: "0287", name: "MARSCO INVESTMENT CORPORATION", disabled: false },
   { value: "0294", name: "VELOCITY CLEARING, LLC", disabled: false },
   { value: "0295", name: "VIRTU AMERICAS LLC", disabled: false },
   {
      value: "0349",
      name: "ABN AMRO SECURITIES (USA) LLC 0352 J.P. MORGAN SECURITIES LLC/JPMC 0355 CREDIT SUISSE SECURITIES (USA) LLC 0361 D. A. DAVIDSON & CO.",
      disabled: false,
   },
   { value: "0369", name: "HRT FINANCIAL LLC", disabled: false },
   {
      value: "0370",
      name: "TRADITION SECURITIES & DERIVATIVES INC.",
      disabled: false,
   },
   { value: "0374", name: "JANNEY MONTGOMERY SCOTT LLC", disabled: false },
   { value: "0385", name: "E*TRADE SECURITIES LLC", disabled: false },
   {
      value: "0388",
      name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA FINANCIAL SERVICES, LLC/CLEARANCE 0390 RAYMOND JAMES & ASSOCIATES, INC/FI",
      disabled: false,
   },
   { value: "0395", name: "CITADEL CLEARING LLC", disabled: false },
   { value: "0413", name: "AMHERST PIERPONT SECURITIES LLC", disabled: false },
   {
      value: "0418",
      name: "CITIGROUP GLOBAL MARKETS, INC./CORRESPONDENT CLEARING 0425 NATWEST MARKETS SECURITIES INC.",
      disabled: false,
   },
   { value: "0430", name: "HOLD BROTHERS CAPITAL LLC", disabled: false },
   { value: "0436", name: "ARCHIPELAGO SECURITIES, L.L.C.", disabled: false },
   { value: "0438", name: "CIBC WORLD MARKETS CORP.", disabled: false },
   { value: "0443", name: "PERSHING LLC", disabled: false },
   { value: "0445", name: "MURIEL SIEBERT & CO., INC.", disabled: false },
   { value: "0448", name: "NUVEEN SECURITIES, LLC", disabled: false },
   {
      value: "0486",
      name: "HSBC SECURITIES (USA) INC. (FIXED INCOME)",
      disabled: false,
   },
   { value: "0505", name: "CITIGROUP GLOBAL MARKETS INC.", disabled: false },
   { value: "0512", name: "LEK SECURITIES CORPORATION", disabled: false },
   {
      value: "0520",
      name: "NASDAQ EXECUTION SERVICES, LLC/OPTIONS 0526 GUGGENHEIM FUNDS DISTRIBUTORS, LLC.",
      disabled: false,
   },
   {
      value: "0534",
      name: "INTERACTIVE BROKERS RETAIL EQUITY CLEARING",
      disabled: false,
   },
   {
      value: "0536",
      name: "JEFFERIES LLC/JEFFERIES EXECUTION SERVICES, INC./SERVICE BUREAU",
      disabled: false,
   },
   { value: "0537", name: "BGC FINANCIAL, L.P.", disabled: false },
   {
      value: "0547",
      name: "ROBERT W. BAIRD & CO. INCORPORATED",
      disabled: false,
   },
   { value: "0549", name: "TIMBER HILL LLC", disabled: false },
   {
      value: "0551",
      name: "MERRILL LYNCH PROFESSIONAL CLEARING CORP. 0557 KEYBANK SAFEKEEPING",
      disabled: false,
   },
   {
      value: "0563",
      name: "CITICORP SECURITIES SERVICES, INC.",
      disabled: false,
   },
   { value: "0568", name: "NASDAQ EXECUTION SERVICES, LLC", disabled: false },
   { value: "0571", name: "OPPENHEIMER & CO. INC.", disabled: false },
   { value: "0573", name: "DEUTSCHE BANK SECURITIES INC.", disabled: false },
   {
      value: "0587",
      name: "SOUTH STATE BANK, NATIONAL ASSOCIATION",
      disabled: false,
   },
   { value: "0594", name: "RJ DEALER STOCK LOAN", disabled: false },
   { value: "0595", name: "VISION FINANCIAL MARKETS LLC", disabled: false },
   {
      value: "0608",
      name: "SG AMERICAS SECURITIES LLC/ SUB 608 624 TULLETT PREBON FINANCIAL SERVICES LLC",
      disabled: false,
   },
   {
      value: "0627",
      name: "DASH FINANCIAL TECHNOLOGIES LLC 0630 BNP PARIBAS SECURITIES CORP.",
      disabled: false,
   },
   { value: "0642", name: "UBS SECURITIES LLC", disabled: false },
   {
      value: "0647",
      name: "DAIWA CAPITAL MARKETS AMERICA INC.",
      disabled: false,
   },
   {
      value: "0651",
      name: "CREDIT AGRICOLE SECURITIES (USA) INC",
      disabled: false,
   },
   { value: "0652", name: "UBS SECURITIES LLC/CMO", disabled: false },
   { value: "0670", name: "CSS, LLC", disabled: false },
   {
      value: "0671",
      name: "MERRILL LYNCH, PIERCE, FENNER & SMITH INCORPORATED",
      disabled: false,
   },
   { value: "0692", name: "INVESCO CAPITAL MARKETS, INC.", disabled: false },
   { value: "0695", name: "ABN AMRO CLEARING CHICAGO LLC", disabled: false },
   { value: "0696", name: "CANTOR FITZGERALD & CO.", disabled: false },
   { value: "0701", name: "CETERA INVESTMENT SERVICES LLC", disabled: false },
   { value: "0702", name: "BB&T SECURITIES, LLC", disabled: false },
   {
      value: "0709",
      name: "WACHTEL & CO., INC. 0712 KOONCE SECURITIES LLC",
      disabled: false,
   },
   { value: "0715", name: "DAVENPORT & COMPANY LLC", disabled: false },
   { value: "0725", name: "RAYMOND JAMES & ASSOCIATES, INC.", disabled: false },
   { value: "0728", name: "FOLIO INVESTMENTS, INC.", disabled: false },
   { value: "0734", name: "THE NASDAQ STOCK MARKET LLC", disabled: false },
   { value: "0750", name: "STONEX FINANCIAL INC.", disabled: false },
   {
      value: "0756",
      name: "AMERICAN ENTERPRISE INVESTMENT SERVICES INC. 0759 NASDAQ STOCK MARKET LLC/OMNIBUS ACCOUNT 0771 WILLIAM BLAIR & COMPANY, L.L.C.",
      disabled: false,
   },
   {
      value: "0773",
      name: "BOFA SECURITIES, INC./FIXED INCOME",
      disabled: false,
   },
   {
      value: "0793",
      name: "STIFEL, NICOLAUS & COMPANY, INCORPORATED",
      disabled: false,
   },
   { value: "0794", name: "CF SECURED, LLC", disabled: false },
   { value: "0816", name: "HSBC SECURITIES (USA) INC.", disabled: false },
   {
      value: "0824",
      name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA FINANCIAL SERVICES, LLC 0873 ELECTRONIC TRANSACTION CLEARING, INC.",
      disabled: false,
   },
   { value: "0892", name: "MIZUHO SECURITIES USA LLC", disabled: false },
   { value: "0901", name: "THE BANK OF NEW YORK MELLON", disabled: false },
   {
      value: "0902",
      name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
      disabled: false,
   },
   { value: "0908", name: "CITIBANK, N.A.", disabled: false },
   {
      value: "0912",
      name: "OCC INTERNAL NON-PROPRIETARY CROSS MARGIN CFTC 1.20 FUTURES CUSTOMER SEGREGATED OMNIBUS ACC 0923 JPMORGAN CHASE BANK - ADR",
      disabled: false,
   },
   {
      value: "0933",
      name: "COWEN AND COMPANY LLC/FULLY PAID FOR LENDING",
      disabled: false,
   },
   {
      value: "0939",
      name: "OCC CFTC 1.20 FUTURES CUSTOMER SEGREGATED OMNIBUS ACCOUNT",
      disabled: false,
   },
   { value: "0950", name: "CITIBANK, N.A. - DEALER", disabled: false },
   { value: "0953", name: "ADR-CITI", disabled: false },
   {
      value: "0954",
      name: "THE BANK OF NEW YORK MELLON/MELLON TRUST OF NEW ENGLAND, NATIONAL ASSOCIATION",
      disabled: false,
   },
   {
      value: "0955",
      name: "BANK OF AMERICA, NA/GWIM TRUST OPERATIONS",
      disabled: false,
   },
   { value: "0958", name: "DESERET TRUST COMPANY", disabled: false },
   {
      value: "0963",
      name: "BNYMELLON/RE ETF - UIT DTC/NSCC 0963 0979 UBS AG, STAMFORD BRANCH",
      disabled: false,
   },
   {
      value: "0981",
      name: "OPTIONS CLEARING CORPORATION (THE)",
      disabled: false,
   },
   {
      value: "0982",
      name: "THE OPTIONS CLEARING CORPORATION/OCC MARKET LOAN PROGRAM ACCOUNT - AQS",
      disabled: false,
   },
   { value: "0987", name: "FIDUCIARY SSB", disabled: false },
   {
      value: "0990",
      name: "MANUFACTURERS AND TRADERS TRUST COMPANY 0992 BMO HARRIS BANK NA/TRUST",
      disabled: false,
   },
   {
      value: "0997",
      name: "STATE STREET BANK AND TRUST COMPANY",
      disabled: false,
   },
   {
      value: "1006",
      name: "CREDIT SUISSE AG-NEW YORK BRANCH/DTC I.D. CONFIRMATION 1014 BNP PARIBAS, NEW YORK BRANCH/BNP PARIBAS LONDON ALM",
      disabled: false,
   },
   { value: "1030", name: "PERSHING LLC/CLIENT FINANCING", disabled: false },
   {
      value: "1043",
      name: "MIRAE ASSET SECURITIES (USA), INC.",
      disabled: false,
   },
   {
      value: "1051",
      name: "E*TRADE SECURITIES LLC/ETS SECURITIES LENDING",
      disabled: false,
   },
   {
      value: "1121",
      name: "MANUFACTURERS AND TRADERS TRUST COMPANY/COMMERCIAL LOANS 1186 AXOS CLEARING LLC/ CORRESPONDENT FLIP FACILITATION ACCOUNT",
      disabled: false,
   },
   {
      value: "1385",
      name: "MIRAE ASSET SECURITIES (USA) INC./STOCK LOAN CONDUIT ACCOUNT",
      disabled: false,
   },
   {
      value: "1405",
      name: "BNP PARIBAS, NEW YORK BRANCH/MERLION/CLIENT ASSETS 1414 BNYMELLON/RE DE SHAW US BROAD MC ALP E PORT LLC 1416 BNYMELLON/RE DE SHAW US L CAP C ALP EX PORT LLC",
      disabled: false,
   },
   { value: "1419", name: "BNYMELLON/RE NAB MELBOURNE", disabled: false },
   {
      value: "1420",
      name: "BNYMELLON/RE DE SHAW BMCAE SPECIAL FUND LP",
      disabled: false,
   },
   {
      value: "1421",
      name: "BNYMELLON/RE DES US BMC ALP E S PORT II MA LLC",
      disabled: false,
   },
   { value: "1501", name: "CITIBANK/CP/IPA", disabled: false },
   {
      value: "1503",
      name: "DEUTSCHE BANK TRUST COMPANY AMERICAS",
      disabled: false,
   },
   {
      value: "1506",
      name: "JPMORGAN CHASE BANK/CHEMICAL/COMMERCIAL PAPER/IPA",
      disabled: false,
   },
   {
      value: "1507",
      name: "MANUFACTURERS AND TRADERS TRUST CO/WILMINGTON TRUST/IPA",
      disabled: false,
   },
   { value: "1510", name: "U.S. BANK N.A./CP", disabled: false },
   { value: "1515", name: "PNC BANK, N.A./IPA", disabled: false },
   { value: "1518", name: "TRUIST BANK/CT CF IPA", disabled: false },
   {
      value: "1526",
      name: "STATE STREET BANK AND TRUST COMPANY/IPA 1530 BMO HARRIS BANK NA/M&I BANK IPA",
      disabled: false,
   },
   {
      value: "1538",
      name: "WELLS FARGO BANK, N.A. ISSUING/PAYING AGENT",
      disabled: false,
   },
   {
      value: "1540",
      name: "UBS AG, STAMFORD BRANCH/IPA ACCOUNT",
      disabled: false,
   },
   { value: "1541", name: "THE BANK OF NEW YORK MELLON/IPA", disabled: false },
   {
      value: "1542",
      name: "BANK OF NOVA SCOTIA, NEW YORK AGENCY/IPA (THE)",
      disabled: false,
   },
   {
      value: "1544",
      name: "HSBC BANK USA, NATIONAL ASSOCIATION/IPA",
      disabled: false,
   },
   {
      value: "1545",
      name: "MANUFACTURERS AND TRADERS TRUST COMPANY/IPA",
      disabled: false,
   },
   {
      value: "1546",
      name: "SOCIETE GENERALE, NEW YORK BRANCH",
      disabled: false,
   },
   { value: "1560", name: "NORTHERN TRUST COMPANY/IPA", disabled: false },
   { value: "1562", name: "HUNTINGTON NATIONAL BANK/IPA", disabled: false },
   { value: "1563", name: "COMPASS BANK/IPA", disabled: false },
   { value: "1569", name: "BNP PARIBAS, NEW YORK BRANCH", disabled: false },
   { value: "1571", name: "REGIONS BANK/IPA", disabled: false },
   {
      value: "1573",
      name: "JPMORGAN CHASE BANK/J.P.MORGAN CHASE & CO./CERTIFICATE OF DEPOSIT/IPA 1577 MIZUHO BANK LTD. NEW YORK BRANCH/IPA",
      disabled: false,
   },
   {
      value: "1581",
      name: "BANK OF AMERICA/LASALLE BANK NA/IPA, DTC #1581",
      disabled: false,
   },
   { value: "1582", name: "BMO HARRIS BANK NA/IPA", disabled: false },
   {
      value: "1584",
      name: "TEXAS TREASURY SAFEKEEPING TRUST COMPANY/IPA",
      disabled: false,
   },
   {
      value: "1586",
      name: "ZIONS BANCORPORATION, NATIONAL ASSOCIATION/CT ISSUE & PAY A/C/IPA",
      disabled: false,
   },
   {
      value: "1587",
      name: "CREDIT SUISSE AG - NEW YORK BRANCH",
      disabled: false,
   },
   { value: "1601", name: "BNP PARIBAS, NEW YORK BRANCH/IPA", disabled: false },
   {
      value: "1603",
      name: "BNYMELLON/RE ROYAL BANK OF CANADA",
      disabled: false,
   },
   {
      value: "1605",
      name: "VELOX CLEARING LLC/SECURITIES LENDING",
      disabled: false,
   },
   {
      value: "1607",
      name: "MUFG UNION BANK, N.A./MMI/PIMS/IPA",
      disabled: false,
   },
   {
      value: "1620",
      name: "ASSOCIATED BANK, N.A./ASSOCIATED TRUST COMPANY/IPA",
      disabled: false,
   },
   {
      value: "1724",
      name: "BNYMELLON/RE CITADEL GLOBAL FIXED INCOME MASTER FUND 1749 REGIONS BANK/CORPORATE TRUST OPS/IPA",
      disabled: false,
   },
   {
      value: "1821",
      name: "MORGAN STANLEY & CO. LLC/OTC DERIVATIVE MARGIN COLLATERAL",
      disabled: false,
   },
   { value: "1944", name: "STONEX FINANCIAL INC./BD RATES", disabled: false },
   {
      value: "1950",
      name: "HSBC BANK USA, NA/HSBC CUSTODY & CLEARING SERVICES FOR STOCK LOAN & BORROW",
      disabled: false,
   },
   {
      value: "1951",
      name: "BNYMELLON/RE ELLINGTON QUANT MACRO MSTR FD LTD",
      disabled: false,
   },
   {
      value: "1959",
      name: "BNYMELLON/DEDICATED PARTICIPANT #84",
      disabled: false,
   },
   {
      value: "1961",
      name: "THE BANK OF NEW YORK MELLON/HH ELLINGTON MASTER FUND LTD",
      disabled: false,
   },
   { value: "1970", name: "EUROCLEAR BANK SA/NV", disabled: false },
   {
      value: "1982",
      name: "MITSUBISHI UFJ TRUST AND BANKING CORPORATION, NEW YORK BRANCH/SECURITIES LENDING - NON AFFILIATE",
      disabled: false,
   },
   { value: "2000", name: "CLEARSTREAM BANKING AG", disabled: false },
   { value: "2002", name: "BNYMELLON/RE BGC BROKERS LP", disabled: false },
   {
      value: "2003",
      name: "UBS AG, STAMFORD BRANCH/AC PB CLIENTS-NO UBS LIEN",
      disabled: false,
   },
   { value: "2008", name: "MONTE TITOLI - S.P.A.", disabled: false },
   { value: "2011", name: "CAVALI ICLV S.A.", disabled: false },
   {
      value: "2012",
      name: "CREST INTERNATIONAL NOMINEES LIMITED",
      disabled: false,
   },
   {
      value: "2015",
      name: "THE TEL-AVIV STOCK EXCHANGE CLEARING HOUSE LTD 2023 THE BANK OF NEW YORK MELLON/FMSBONDS, INC.",
      disabled: false,
   },
   { value: "2026", name: "BNYMELLON/RE BNYMSANV RE FIRM", disabled: false },
   {
      value: "2027",
      name: "WELLS FARGO BANK, NATIONAL ASSOCIATION",
      disabled: false,
   },
   {
      value: "2032",
      name: "CITIBANK/THE CITIGROUP PRIVATE BANK/TRUST",
      disabled: false,
   },
   {
      value: "2035",
      name: "JP MORGAN CHASE/JP MORGAN INTERNATIONAL",
      disabled: false,
   },
   {
      value: "2037",
      name: "MITSUBISHI UFJ TRUST & BANKING CORPORATION, NEW YORK BRANCH/AFFILIATE",
      disabled: false,
   },
   { value: "2039", name: "SEI PRIVATE TRUST COMPANY", disabled: false },
   { value: "2040", name: "WELLS FARGO BANK, N.A./LENDING", disabled: false },
   {
      value: "2041",
      name: "DBTC AMERICAS/CTAG/PUTS & DEMANDS",
      disabled: false,
   },
   { value: "2042", name: "RELIANCE TRUST COMPANY/SWMS1", disabled: false },
   {
      value: "2046",
      name: "THE BANK OF NEW YORK MELLON/HBK MASTER FUND LP",
      disabled: false,
   },
   { value: "2047", name: "SECURITIES FINANCE TRUST COMPANY", disabled: false },
   { value: "2053", name: "FROST BANK", disabled: false },
   {
      value: "2057",
      name: "BNYMELLON/RE DBTCA-DB AG LDN PB - CLIENT ACCOUNT 2059 BNYMELLON/RE DBTCA/DB AG LDN PB-DEUTSCHE BANK NY 2065 PNC BANK, N.A./OTTA",
      disabled: false,
   },
   { value: "2067", name: "UNION BANK & TRUST COMPANY", disabled: false },
   {
      value: "2068",
      name: "FEDERAL HOME LOAN MORTGAGE CORPORATION/RETAINED",
      disabled: false,
   },
   {
      value: "2070",
      name: "BNYMELLON/RE BNP PARIBAS TRI-PARTY ACCOUNT 2072 WELLS FARGO BANK, N.A./SIG",
      disabled: false,
   },
   {
      value: "2074",
      name: "BNYMELLON/RE BNYMSANVAMS RE FIRM LAB",
      disabled: false,
   },
   {
      value: "2075",
      name: "MUFG SECURITIES AMERICAS INC./STOCK LOAN",
      disabled: false,
   },
   {
      value: "2079",
      name: "BNYMELLON/RE DBTCA/DB AG LDN PB CHEYNE VALUE FUND LP 2085 RELIANCE TRUST COMPANY/SWMS2",
      disabled: false,
   },
   {
      value: "2090",
      name: "BNYMELLON/RE DB AG LON PB POLGON GL OP M/FD",
      disabled: false,
   },
   {
      value: "2091",
      name: "BNYMELLON/RE RABOBANK INTERNATIONAL UNEF",
      disabled: false,
   },
   {
      value: "2093",
      name: "BNYMELLON/RE SUNTRUST BANK 2095 TRUIST SECURITIES, INC.",
      disabled: false,
   },
   {
      value: "2100",
      name: "BNYMELLON/RE SUNTRUST BANK PORTFOLIO",
      disabled: false,
   },
   {
      value: "2103",
      name: "BNYMELLON/RE BARCLAYS CAPITAL SECURITIES LTD.",
      disabled: false,
   },
   {
      value: "2104",
      name: "ZIONS BANCORPORATION, NATIONAL ASSOCIATION",
      disabled: false,
   },
   {
      value: "2106",
      name: "BNYMELLON/RE ALLSTATE MARK TO MARKETS",
      disabled: false,
   },
   {
      value: "2107",
      name: "THE BANK OF NEW YORK MELLON/COMMERCIAL LOANS",
      disabled: false,
   },
   { value: "2108", name: "COMERICA BANK", disabled: false },
   {
      value: "2109",
      name: "BNYMELLON/RE ICBC STANDARD BANK PLC",
      disabled: false,
   },
   { value: "2114", name: "TRUIST BANK/SUNTRUST BANK RETAIL", disabled: false },
   {
      value: "2116",
      name: "FIFTH THIRD BANK, NATIONAL ASSOCIATION 2118 DESERET TRUST COMPANY - D",
      disabled: false,
   },
   { value: "2122", name: "HSBC BANK USA, N.A.-IPB", disabled: false },
   {
      value: "2126",
      name: "FIDUCIARY TRUST COMPANY OF BOSTON 2131 BNYMELLON/RE NOMURA PB NOMINEES LTD 2136 THE BANK OF NEW YORK MELLON/IVORS",
      disabled: false,
   },
   {
      value: "2138",
      name: "THE BANK OF NEW YORK MELLON/ELLINGTON SPECIAL OPPORTUNITIES FUND, LTD.",
      disabled: false,
   },
   {
      value: "2139",
      name: "GLENMEDE TRUST COMPANY, N.A. (THE)",
      disabled: false,
   },
   { value: "2145", name: "MUFG UNION BANK, N.A.", disabled: false },
   {
      value: "2147",
      name: "BNP PARIBAS, NEW YORK BRANCH/BNP PARIBAS PRIME BROKERAGE CUSTODIAN 2151 BNYMELLON/RE FIRM INVESTMENT ACCOUNT",
      disabled: false,
   },
   {
      value: "2154",
      name: "BNP PARIBAS SECURITIES CORP./PRIME BROKERAGE",
      disabled: false,
   },
   {
      value: "2155",
      name: "THE BANK OF NEW YORK MELLON/DEUTSCHE BANK LONDON AG LONDON/GLOBAL MARKET #2",
      disabled: false,
   },
   {
      value: "2156",
      name: "BNYMELLON/RE DBTCA/DB AG LDN PB MULTI SEG CLEARANCE",
      disabled: false,
   },
   {
      value: "2160",
      name: "BNYMELLON/RE DBTCA/DB AG LDN B CHEYNE SPEC'L SIT FD",
      disabled: false,
   },
   {
      value: "2161",
      name: "MIZUHO SECURITIES USA LLC/SECURITIES FINANCE",
      disabled: false,
   },
   {
      value: "2164",
      name: "JPMORGAN CHASE BANK/CORRESPONDENCE CLEARING SERVICES 2",
      disabled: false,
   },
   {
      value: "2165",
      name: "HSBC BANK USA, NATIONAL ASSOCIATION",
      disabled: false,
   },
   {
      value: "2166",
      name: "PNC BANK/PNC MUNICIPAL STRATEGY - BLK",
      disabled: false,
   },
   {
      value: "2167",
      name: "PNC BANK, N.A./PNC CAPITAL MARKETS LLC MSFTA",
      disabled: false,
   },
   {
      value: "2169",
      name: "BNYMELLON/RE D E SHAW HELIANT CAPITAL LLC",
      disabled: false,
   },
   { value: "2170", name: "COMMERCE BANK", disabled: false },
   {
      value: "2176",
      name: "THE BANK OF NEW YORK MELLON/CDC MORTGAGE CAPITAL INC. 2187 MORGAN STANLEY BANK, N.A.",
      disabled: false,
   },
   { value: "2193", name: "SSB-PHYSICAL CUSTODY SERVICES", disabled: false },
   {
      value: "2196",
      name: "THE BANK OF NEW YORK MELLON/BARCLAYS BANK PLC",
      disabled: false,
   },
   { value: "2205", name: "KEYBANK NATIONAL ASSOCIATION", disabled: false },
   {
      value: "2206",
      name: "BANK NEW YORK MELLON/FIRM ITC-INVESTMENT DEALER",
      disabled: false,
   },
   {
      value: "2207",
      name: "BNYMELLON/RE RABOBANK INTERNATIONAL LONDON EQUITY FINANCE",
      disabled: false,
   },
   { value: "2209", name: "BNYMELLON/RE MIDCAP SPDRS", disabled: false },
   {
      value: "2219",
      name: "HUNTINGTON NATIONAL BANK/FBO OHIO POLICE AND PENSION FUND",
      disabled: false,
   },
   {
      value: "2220",
      name: "THE BANK OF NEW YORK MELLON/EF SECURITIES LLC",
      disabled: false,
   },
   {
      value: "2224",
      name: "THE BANK OF NEW YORK MELLON/NATIXIS FINANCIAL PRODUCTS INC",
      disabled: false,
   },
   {
      value: "2225",
      name: "THE BANK OF NEW YORK MELLON/E-TRADE BANK",
      disabled: false,
   },
   { value: "2234", name: "U.S. BANK N.A./SAFEKEEPING WEST", disabled: false },
   {
      value: "2236",
      name: "BANK OF AMERICA, NATIONAL ASSOCIATION",
      disabled: false,
   },
   {
      value: "2243",
      name: "BNYMELLON/RE NATIXIS FIXED INCOME",
      disabled: false,
   },
   { value: "2244", name: "BNYMELLON/RE NATIXIS", disabled: false },
   { value: "2251", name: "BANK OF AMERICA NA/CLIENT ASSETS", disabled: false },
   { value: "2257", name: "ASSOCIATED BANK, N.A.", disabled: false },
   { value: "2262", name: "TRUIST BANK/SUNTRUST BANK DEALER", disabled: false },
   {
      value: "2267",
      name: "MORGAN STANLEY PRIVATE BANK, NATIONAL ASSOCIATION 2271 WESBANCO BANK, INC.",
      disabled: false,
   },
   { value: "2281", name: "BNY MELLON/NOMURA INT'L PLC REPO", disabled: false },
   { value: "2305", name: "THE HUNTINGTON NATIONAL BANK", disabled: false },
   { value: "2306", name: "FANNIE MAE", disabled: false },
   {
      value: "2307",
      name: "BNYMELLON/RE ING BANK NV LONDON BRANCH",
      disabled: false,
   },
   { value: "2308", name: "BNYMELLON/RE BOA NA", disabled: false },
   { value: "2309", name: "BANK OF MONTREAL, CHICAGO BRANCH", disabled: false },
   {
      value: "2312",
      name: "JPMORGAN CHASE BANK NA/DBTC AMERICAS/DEUTSCHE BANK AG (LONDON BRANCH) 2314 JPMORGAN CHASE BANK NA/DBTC AMERICAS/DB UK BANK LIMITED",
      disabled: false,
   },
   { value: "2319", name: "SSB - TRUST CUSTODY", disabled: false },
   { value: "2322", name: "BNP PARIBAS, NY BRANCH/ BNPP SA", disabled: false },
   {
      value: "2324",
      name: "BNYMELLON/RE BARCLAYS BANK PLC - PLEDGE ACCOUNT",
      disabled: false,
   },
   {
      value: "2328",
      name: "BNY MELLON/ANWORTH MORTGAGE ASSET CORP.",
      disabled: false,
   },
   {
      value: "2330",
      name: "COMPUTERSHARE TRUST COMPANY, N.A./OPTIONS",
      disabled: false,
   },
   { value: "2333", name: "CITIBANK, N.A./ETF", disabled: false },
   { value: "2334", name: "JPMORGAN CHASE-ADR MAX", disabled: false },
   {
      value: "2336",
      name: "BNYMELLON/RE CHARLES STANLEY AND COMPANY, LIMITED",
      disabled: false,
   },
   {
      value: "2338",
      name: "HONG KONG SECURITIES CLEARING COMPANY LIMITED",
      disabled: false,
   },
   {
      value: "2339",
      name: "BNYMELLON/RE ITC - DEALERS CLEARANCE SPECIAL",
      disabled: false,
   },
   {
      value: "2342",
      name: "THE BANK OF NEW YORK MELLON/ELLINGTON MORTGAGE FUND SC, LTD. 2347 THE BANK OF NOVA SCOTIA, NEW YORK AGENCY",
      disabled: false,
   },
   { value: "2352", name: "AMALGAMATED BANK", disabled: false },
   { value: "2357", name: "JPMORGAN CHASE BANK/IA", disabled: false },
   {
      value: "2359",
      name: "BNYMELLON/RE HSBC BANK PLC PARIS BRANCH",
      disabled: false,
   },
   {
      value: "2361",
      name: "BNYMELLON/RE FIRM SECURITIES FINANCE",
      disabled: false,
   },
   {
      value: "2362",
      name: "THE BANK OF NEW YORK MELLON/CRESCENT II FUND L.P.",
      disabled: false,
   },
   {
      value: "2363",
      name: "BNYMELLON/RE HSBC BANK PLC EQD USBR",
      disabled: false,
   },
   {
      value: "2366",
      name: "BNYMELLON/RE BARCLAYS CAP SEC LTD PB SEG 1",
      disabled: false,
   },
   {
      value: "2367",
      name: "BNYMELLON/RE BARCLAYS CAP SEC LTD PB SEG 2",
      disabled: false,
   },
   {
      value: "2375",
      name: "STATE STREET BANK & TRUST COMPANY / ISHARES EUROPE 2379 JPMORGAN CHASE BANK/JPMORGAN PPB",
      disabled: false,
   },
   { value: "2381", name: "BNYMELLON/RE FIRM TRADE INS", disabled: false },
   {
      value: "2382",
      name: "MANUFACTURERS AND TRADERS TRUST COMPANY/BANK PORTFOLIO",
      disabled: false,
   },
   {
      value: "2386",
      name: "STATE STREET BANK AND TRUST COMPANY, N.A.",
      disabled: false,
   },
   {
      value: "2391",
      name: "FEDERAL HOME LOAN MORTGAGE CORPORATION",
      disabled: false,
   },
   {
      value: "2393",
      name: "HSBC BANK USA, NATIONAL ASSOCIATION/OMNIBUS",
      disabled: false,
   },
   {
      value: "2396",
      name: "MIZUHO SECURITIES USA LLC/FIXED INCOME",
      disabled: false,
   },
   {
      value: "2399",
      name: "STATE STREET BANK AND TRUST COMPANY/DEUTSCHE BANK FRANKFURT",
      disabled: false,
   },
   {
      value: "2415",
      name: "COMPUTERSHARE TRUST COMPANY, N.A.",
      disabled: false,
   },
   {
      value: "2416",
      name: "FIFTH THIRD BANK, NATIONAL ASSOCIATION/STATE TEACHERS RETIREMENT OF OHIO",
      disabled: false,
   },
   {
      value: "2417",
      name: "THE BANK OF NEW YORK MELLON/ELLINGTON MORTGAGE OPPORTUNITIES MASTER FUND LTD.",
      disabled: false,
   },
   {
      value: "2424",
      name: "JPMORGAN CHASE BANK, N.A./CUSTODIAL TRUST COMPANY",
      disabled: false,
   },
   {
      value: "2425",
      name: "HOME FEDERAL BANK OF TENNESSEE, F.S.B.",
      disabled: false,
   },
   {
      value: "2426",
      name: "CITIBANK, N.A./CORPORATE AGENCY & TRUST",
      disabled: false,
   },
   {
      value: "2427",
      name: "THE BANK OF NEW YORK MELLON / BOFA SECURITIES INC",
      disabled: false,
   },
   {
      value: "2428",
      name: "BNYMELLON/RE DBTC AMERICAS/DEUTSCHE BANK LONDON PRIME",
      disabled: false,
   },
   {
      value: "2433",
      name: "JPMORGAN CHASE BANK/VANGUARD LOANET",
      disabled: false,
   },
   {
      value: "2434",
      name: "JPMORGAN CHASE BANK/GNPH MIDDLE MARKET",
      disabled: false,
   },
   { value: "2436", name: "SSB - BANK PORTFOLIO", disabled: false },
   {
      value: "2438",
      name: "THE BANK OF NEW YORK MELLON TRUST COMPANY, NATIONAL ASSOCIATION",
      disabled: false,
   },
   {
      value: "2443",
      name: "BNYMELLON/RE CACEIS BANK GERMANY RE ORCA",
      disabled: false,
   },
   { value: "2444", name: "THE BANK OF NEW YORK MELLON/FSA", disabled: false },
   { value: "2445", name: "FIRST HORIZON BANK", disabled: false },
   {
      value: "2447",
      name: "HOME FEDERAL BANK/HOME FINANCIAL SERVICES, INC.",
      disabled: false,
   },
   { value: "2450", name: "UMB BANK, NATIONAL ASSOCIATION", disabled: false },
   { value: "2451", name: "UMB BANK, INVESTMENT DIVISION", disabled: false },
   {
      value: "2452",
      name: "BNYMELLON/RE DBTC AMERICAS/DEUTSCHE BK LONDON PRIME SEG 15/00",
      disabled: false,
   },
   {
      value: "2453",
      name: "BNYMELLON/RE DBTC/DEUTSCHE BK LONDON PRIME SEG 1",
      disabled: false,
   },
   {
      value: "2454",
      name: "BNYMELLON/RE DBTC/DEUTSCHE BK LONDON PRIME SEG 2",
      disabled: false,
   },
   { value: "2462", name: "BNYMELLON/RE HSBC BANK PLC", disabled: false },
   {
      value: "2468",
      name: "BNYMELLON/RE DEUTSCHE BANK AG FRANKFURT",
      disabled: false,
   },
   {
      value: "2469",
      name: "THE BANK OF NEW YORK MELLON/MIZUHO CORPORATE BANK LTD., NEW YORK BRANCH",
      disabled: false,
   },
   { value: "2472", name: "BNYMELLON/RE DR CUSTODY ACCOUNT", disabled: false },
   { value: "2474", name: "BNYMELLON/RE MILLENNIUM PARTNERS", disabled: false },
   {
      value: "2475",
      name: "THE BANK OF NEW YORK MELLON/ANNALY COMMERCIAL REAL ESTATE GR.",
      disabled: false,
   },
   {
      value: "2478",
      name: "THE BANK OF NEW YORK MELLON/DBAG LONDON GLOBAL MARKETS (CLIENT ACCT)",
      disabled: false,
   },
   {
      value: "2479",
      name: "THE BANK OF NEW YORK MELLON/DBAG FRANKFURT GLOBAL MARKET",
      disabled: false,
   },
   {
      value: "2480",
      name: "WELLS FARGO SECURITIES, LLC/SECURITIES FINANCE",
      disabled: false,
   },
   {
      value: "2481",
      name: "DEUTSCHE BANK AG, NEW YORK BRANCH",
      disabled: false,
   },
   { value: "2483", name: "COMPASS BANK", disabled: false },
   {
      value: "2485",
      name: "THE BANK OF NEW YORK MELLON/DBAG LONDON GLOBAL MARKET",
      disabled: false,
   },
   {
      value: "2486",
      name: "BNYMELLON/RE RABOBANK UTRECHT FIXED INCOME",
      disabled: false,
   },
   {
      value: "2488",
      name: "BNYMELLON/RE DBAG PB UCITS CLIENTS",
      disabled: false,
   },
   {
      value: "2490",
      name: "BNYMELLON/RE RABOBANK INT'L UTRECHT EQUITY FIN",
      disabled: false,
   },
   { value: "2491", name: "TD SECURITIES (USA) LLC/TD BANK", disabled: false },
   {
      value: "2492",
      name: "MIZUHO BANK (USA)/SECURITY LENDING",
      disabled: false,
   },
   {
      value: "2494",
      name: "BNYMELLON/RE BOA SECURITIES LTD. (BASL) 2497 DESERET TRUST COMPANY - I",
      disabled: false,
   },
   {
      value: "2499",
      name: "BNYMELLON/RE NOMURA CL SETT NOM LTD",
      disabled: false,
   },
   {
      value: "2502",
      name: "THE BANK OF NEW YORK MELLON/ANNALY MORTGAGE",
      disabled: false,
   },
   {
      value: "2504",
      name: "BNYMELLON/RE DEPOSITARY RECEIPT SERVICES/MERRILL LYNCH REDEM.",
      disabled: false,
   },
   {
      value: "2507",
      name: "UBS AG STAMFORD BRANCH/AS CUSTODIAN FOR UBS AG LONDON BRANCH",
      disabled: false,
   },
   {
      value: "2508",
      name: "JPMORGAN CHASE BANK/CORPORATE MUNICIPAL DEALER",
      disabled: false,
   },
   {
      value: "2510",
      name: "BNYMELLON/RE THE PRUDENTIAL INVESTMENT 2517 JPMORGAN CHASE BANK/PRUDENTIAL",
      disabled: false,
   },
   {
      value: "2522",
      name: "MORGAN STANLEY PRIVATE BANK, NATIONAL ASSOCIATION/#2",
      disabled: false,
   },
   { value: "2523", name: "BNY MELLON CAPITAL MARKETS, LLC", disabled: false },
   {
      value: "2531",
      name: "THE BANK OF NOVA SCOTIA, NEW YORK AGENCY/RATES DESK",
      disabled: false,
   },
   { value: "2533", name: "HOME FEDERAL BANK/HF PORTFOLIO", disabled: false },
   {
      value: "2534",
      name: "HOME FEDERAL BANK OF TENNESSEE, FSB/TRUST DEPARTMENT CUSTOMERS",
      disabled: false,
   },
   { value: "2539", name: "MIZUHO BANK LTD. NEW YORK BRANCH", disabled: false },
   { value: "2545", name: "LAKESIDE BANK", disabled: false },
   {
      value: "2546",
      name: "STATE STREET BANK AND TRUST COMPANY/DB RESIDUAL PROCESSING ACCOUNT",
      disabled: false,
   },
   {
      value: "2553",
      name: "BNYMELLON/RE DAVY SECURITIES LIMITED 2555 BANK OF CHINA, NEW YORK BRANCH",
      disabled: false,
   },
   { value: "2557", name: "BANKERS' BANK", disabled: false },
   { value: "2559", name: "BMO HARRIS BANK NA/DEALER", disabled: false },
   { value: "2562", name: "CITIBANK NA/DEALER SAFEKEEPING", disabled: false },
   { value: "2563", name: "ASCENSUS TRUST COMPANY", disabled: false },
   {
      value: "2565",
      name: "THE BANK OF NEW YORK MELLON/BAKERGROUP",
      disabled: false,
   },
   { value: "2566", name: "BNYMELLON/RE ANCHORAGE CAPITAL", disabled: false },
   { value: "2567", name: "AMALGAMATED BANK OF CHICAGO", disabled: false },
   { value: "2568", name: "TD SECURITIES (USA) LLC", disabled: false },
   {
      value: "2570",
      name: "MITSUBISHI UFJ TRUST & BANKING CORPORATION, NEW YORK BRANCH/STOCK LOAN 2573 BNYMELLON/RE RABOBANK INTERNATIONAL NY",
      disabled: false,
   },
   { value: "2578", name: "SYNOVUS BANK", disabled: false },
   { value: "2579", name: "SYNOVUS BANK/SYNOVUS 2", disabled: false },
   { value: "2580", name: "U.S. BANK N.A./ETF", disabled: false },
   {
      value: "2582",
      name: "BNYMELLON/RE DEUTSCHE BANK AG LONDON PRIME BROKERAGE 2586 COMPUTERSHARE TRUST COMPANY, N.A./DRP",
      disabled: false,
   },
   { value: "2590", name: "BNYMELLON/RE BANCO SANTANDER SLB", disabled: false },
   {
      value: "2595",
      name: "BNYMELLON/RE FIRM INVESTMENT PORTFOLIO",
      disabled: false,
   },
   {
      value: "2598",
      name: "BNYMELLON/RE CACEIS BANK GERMANY RE HVB MIB",
      disabled: false,
   },
   {
      value: "2601",
      name: "BNYMELLON/RE TRADITION LONDON CLEARING LTD.",
      disabled: false,
   },
   {
      value: "2609",
      name: "JPMORGAN CHASE BANK/TREASURER OF STATE OF OHIO BWC 2612 JPMORGAN CHASE/US EQ TRP",
      disabled: false,
   },
   { value: "2616", name: "PNC BANK, NATIONAL ASSOCIATION", disabled: false },
   {
      value: "2621",
      name: "BNYMELLON/RE MIZUHO INTERNATIONAL",
      disabled: false,
   },
   {
      value: "2622",
      name: "TEXAS TREASURY SAFEKEEPING TRUST COMPANY",
      disabled: false,
   },
   { value: "2625", name: "SSB&T/SEC FIN AS PRINCIPAL", disabled: false },
   { value: "2630", name: "BNYMELLON/RE AIG", disabled: false },
   {
      value: "2634",
      name: "BNYMELLON/RE GOV & CO BANK OF ENGLAND 2641 BNYMELLON/RE BARCLAYS CAPITAL INC.",
      disabled: false,
   },
   {
      value: "2648",
      name: "BNYMELLON/RE GLOBAL PRIME PARTNERS",
      disabled: false,
   },
   { value: "2655", name: "DBTC AMERICAS/CTAG-GES", disabled: false },
   {
      value: "2656",
      name: "BANK OF CHINA NEW YORK BRANCH/CLIENT CUSTODY",
      disabled: false,
   },
   {
      value: "2658",
      name: "CITIBANK, N.A./SEGREGATED LENDING",
      disabled: false,
   },
   {
      value: "2660",
      name: "GOLDMAN SACHS BANK USA/GOLDMAN SACHS AGENCY LENDING",
      disabled: false,
   },
   {
      value: "2663",
      name: "SEI PRIVATE TRUST COMPANY/C/O GWP",
      disabled: false,
   },
   {
      value: "2667",
      name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA FINANCIAL SERVICES LLC/ CLEARING",
      disabled: false,
   },
   {
      value: "2668",
      name: "JPMORGAN CHASE BANK,N.A./JPMORGAN CHASE FUNDING INC.",
      disabled: false,
   },
   { value: "2669", name: "THE NORTHERN TRUST COMPANY", disabled: false },
   {
      value: "2677",
      name: "BNYMELLON/RE RABO CAPITAL SERVICES",
      disabled: false,
   },
   { value: "2678", name: "SSB&T CO/CLIENT CUSTODY SERVICES", disabled: false },
   {
      value: "2679",
      name: "TD SECURITIES (USA) LLC/ TDB UNENCUMBERED",
      disabled: false,
   },
   {
      value: "2680",
      name: "SOCIETE GENERALE NY/SOCIETE GENERALE PARIS",
      disabled: false,
   },
   {
      value: "2681",
      name: "THE BANK OF NEW YORK MELLON/ITC-DEALERS CLEARANCE GENERAL",
      disabled: false,
   },
   { value: "2683", name: "TD SECURITIES (USA) LLC/ TD NY", disabled: false },
   {
      value: "2684",
      name: "NORTHERN TRUST COMPANY - SAFEKEEPING",
      disabled: false,
   },
   { value: "2687", name: "CALDWELL TRUST COMPANY", disabled: false },
   {
      value: "2690",
      name: "DEUTSCHE BANK AG NY/US CUSTODY 2697 BMO HARRIS BANK NA",
      disabled: false,
   },
   {
      value: "2703",
      name: "TRUIST BANK/IP TRUIST FINANCIAL CORP 2705 TRUIST BANK/IP TRUIST BANK",
      disabled: false,
   },
   { value: "2709", name: "BNYMELLON/RE DE SHAW & CO.", disabled: false },
   { value: "2711", name: "BNYMELLON/RE ICAP LONDON", disabled: false },
   {
      value: "2714",
      name: "BNYMELLON/RE NATIONAL BANK OF AUSTRALIA",
      disabled: false,
   },
   {
      value: "2716",
      name: "BNYMELLON/RE D.E. SHAW KALON PORTFOLIOS, L.L.C.",
      disabled: false,
   },
   { value: "2717", name: "TRUIST BANK/SUNTRUST SAFEKEEPING", disabled: false },
   {
      value: "2718",
      name: "BNYMELLON/RE NOMURA CNS NOM RE: TFS DER",
      disabled: false,
   },
   {
      value: "2719",
      name: "BNYMELLON/RE DBLPB-DBX RISK ARBITAGE 8 FUND",
      disabled: false,
   },
   {
      value: "2720",
      name: "BNYMELLON/RE BARCLAYS BK PLC-BARC LUX SARL A/C 1",
      disabled: false,
   },
   {
      value: "2721",
      name: "BNYMELLON/RE BARCLAYS BK PLC-BARC LUX SARL A/C 2",
      disabled: false,
   },
   {
      value: "2724",
      name: "BNYMELLON/RE DBLPB-CENTAURUS PROXIMA FD 2726 BNYMELLON/RE DBLPB-DBX-ASIAN L/S EQUITY 2 FUND 2731 BNYMELLON/RE SCULPTOR CAPITAL MANAGEMENT",
      disabled: false,
   },
   {
      value: "2735",
      name: "DEPOSITO CENTRAL DE VALORES S.A., DEPOSITO DE VALORES",
      disabled: false,
   },
   {
      value: "2736",
      name: "ZIONS BANCORPORATION, NATIONAL ASSOCIATION/WESTERN NATIONAL",
      disabled: false,
   },
   {
      value: "2767",
      name: "SSB - BLACKROCK INSTITUTIONAL TRUST",
      disabled: false,
   },
   {
      value: "2769",
      name: "KEYBANK NA/FBO TREASURER OF STATE OF OHIO",
      disabled: false,
   },
   {
      value: "2773",
      name: "JPMORGAN CHASE BANK/MUNICIPAL DEALER",
      disabled: false,
   },
   {
      value: "2776",
      name: "THE BANK OF NEW YORK MELLON/ELLINGTON CREDIT OPPORTUNITIES LTD.",
      disabled: false,
   },
   {
      value: "2778",
      name: "NORTHERN TRUST COMPANY/FUTURE FUND ACCOUNTS",
      disabled: false,
   },
   {
      value: "2779",
      name: "SUMITOMO MITSUI TRUST BANK (U.S.A.) LIMITED",
      disabled: false,
   },
   {
      value: "2781",
      name: "U.S. BANK, N.A./U.S. BANK MUNICIPAL SECURITIES GROUP",
      disabled: false,
   },
   { value: "2782", name: "E*TRADE BANK", disabled: false },
   {
      value: "2784",
      name: "THE BANK OF NEW YORK MELLON/ST. BERNARD OPPORTUNITY FUND 1, LTD.",
      disabled: false,
   },
   {
      value: "2785",
      name: "BNYMELLON/RE MILLENNIUM FIXED INCOME LTD",
      disabled: false,
   },
   { value: "2786", name: "BBVA SECURITIES INC.", disabled: false },
   {
      value: "2787",
      name: "BNP PARIBAS, NEW YORK BRANCH/CUSTODY/CLIENT ASSETS",
      disabled: false,
   },
   {
      value: "2790",
      name: "CITIBANK, N.A. BOOK-ENTRY-ONLY MEDIUM TERM NOTE ACCOUNT",
      disabled: false,
   },
   {
      value: "2802",
      name: "BNYMELLON/RE BBPLC PB CAYMEN CLIENTS",
      disabled: false,
   },
   { value: "2803", name: "U.S. BANK N.A.", disabled: false },
   { value: "2808", name: "DBTC AMERICAS/CTAG-CDFP", disabled: false },
   {
      value: "2825",
      name: "BNYMELLON/RE BBPLC PB CANADIAN CLIENTS",
      disabled: false,
   },
   {
      value: "2828",
      name: "THE BANK OF NEW YORK MELLON/DEUTSCHE BANK AG 2830 BNYMELLON/RE HSBC BANK PLC",
      disabled: false,
   },
   {
      value: "2838",
      name: "BNYMELLON/RE SMBC NIKKO SECURITIES AMERICA INC 2843 BNYMELLON/RE SMBC NIKKO CAPITAL MARKETS LTD",
      disabled: false,
   },
   { value: "2834", name: "PNC BANK, N.A./PITTSBURGH", disabled: false },
   {
      value: "2835",
      name: "PNC BANK N.A./PNC CAPITAL MARKETS LLC",
      disabled: false,
   },
   {
      value: "2837",
      name: "U.S. BANK N.A./THIRD PARTY LENDING",
      disabled: false,
   },
   {
      value: "2841",
      name: "THE BANK OF NEW YORK MELLON/EF MORTGAGE, LLC",
      disabled: false,
   },
   {
      value: "2842",
      name: "THE BANK OF NEW YORK MELLON/EF CMO, LLC",
      disabled: false,
   },
   { value: "2844", name: "BNYMELLON/RE BBPLC PB UK CLIENTS", disabled: false },
   {
      value: "2846",
      name: "BNYMELLON/RE (AG) DESHAW OCULUS PORT LLC.PLGCOLL AC",
      disabled: false,
   },
   {
      value: "2851",
      name: "MUFG UNION BANK, N.A./CAPITAL MARKETS 2858 BNYMELLON/RE FIRM SMPT ASSETS",
      disabled: false,
   },
   {
      value: "2865",
      name: "JPMORGAN CHASE BANK/AG DEPOSITARY BANK 2868 BNYMELLON/RE FIRM SECURED FINANCE",
      disabled: false,
   },
   {
      value: "2871",
      name: "TRUIST BANK/IP BB&T COMMUNITY HOLDINGS",
      disabled: false,
   },
   {
      value: "2874",
      name: "THE BANK OF NEW YORK MELLON/CHIMERA INVESTMENT CORPORATION",
      disabled: false,
   },
   {
      value: "2875",
      name: "THE BANK OF NEW YORK MELLON/CHIMERA RMBS WHOLE POOL LLC (F/K/A CIM ASSET HOLDING)",
      disabled: false,
   },
   { value: "2880", name: "CENTRAL TRUST BANK (THE)", disabled: false },
   {
      value: "2884",
      name: "BNP PARIBAS, NEW YORK BRANCH/BNP PARIBAS PRIME BROKERAGE INTERNATIONAL",
      disabled: false,
   },
   {
      value: "2885",
      name: "BNP PARIBAS SECURITIES CORP./PRIME STOCK LENDING",
      disabled: false,
   },
   { value: "2888", name: "MIZUHO BANK (USA)", disabled: false },
   {
      value: "2893",
      name: "THE BANK OF NEW YORK MELLON/CHIMERA RMBS LLC (F/K/A CIM HOLDING)",
      disabled: false,
   },
   {
      value: "2894",
      name: "HSBC BANK USA, N.A./CORPORATE TRUST IPA",
      disabled: false,
   },
   { value: "2897", name: "U.S. BANK N.A./TRUST NY MTN", disabled: false },
   {
      value: "2898",
      name: "FIFTH THIRD BANK, NATIONAL ASSOCIATION/FBO SCHOOL EMPLOYEE RETIREMENT SYSTEM OF OHIO",
      disabled: false,
   },
   {
      value: "2899",
      name: "THE BANK OF NEW YORK MELLON/CHIMERA SECURITIES HOLDING LLC",
      disabled: false,
   },
   {
      value: "2901",
      name: "THE BANK OF NEW YORK MELLON/CHIMERA SPECIAL HOLDING LLC 2903 THE BANK OF NEW YORK MELLON/BAKER2",
      disabled: false,
   },
   {
      value: "2906",
      name: "THE BANK OF NEW YORK MELLON/CHIMERA TRADING COMPANY LLC",
      disabled: false,
   },
   { value: "2908", name: "BNYMELLON/RE ANZ MELBOURNE", disabled: false },
   {
      value: "2909",
      name: "BNYMELLON/RE BARCLAYS BANK IRELAND TREASURY ACCT",
      disabled: false,
   },
   {
      value: "2912",
      name: "BNYMELLON/RE ELLINGTON SPECIAL RELATIVE VALUE FUND LLC",
      disabled: false,
   },
   { value: "2919", name: "BNYMELLON/RE HSBC FRANCE", disabled: false },
   {
      value: "2920",
      name: "THE BANK OF NEW YORK MELLON/NATIXIS SECURITIES AMERICAS LLC",
      disabled: false,
   },
   {
      value: "2922",
      name: "BNYMELLON/RE BARCLAYS BANK IRELAND 2926 BNYMELLON/RE BNYMSANVFFT RE FIRM",
      disabled: false,
   },
   { value: "2931", name: "BNYMELLON/RE BARCLAYS BANK PLC", disabled: false },
   {
      value: "2932",
      name: "MITSUBISHI UFJ TRUST & BANKING CORPORATION, NEW YORK BRANCH",
      disabled: false,
   },
   { value: "2937", name: "PNC BANK, N.A./HPRS", disabled: false },
   {
      value: "2940",
      name: "THE BANK OF NEW YORK MELLON/VINNING SPARKS, IBG, L.P.",
      disabled: false,
   },
   { value: "2941", name: "GOLDMAN SACHS BANK USA", disabled: false },
   { value: "2945", name: "JPMORGAN CHASE-FIMAT CU", disabled: false },
   {
      value: "2947",
      name: "BNYMELLON/RE BARCLAYS BANK IRELAND PLC F",
      disabled: false,
   },
   {
      value: "2948",
      name: "BNYMELLON/RE MIZUHO SECURITIES EUROPE GMBH",
      disabled: false,
   },
   {
      value: "2950",
      name: "STATE STREET BANK & TRUST/STATE STREET TOTALETF",
      disabled: false,
   },
   { value: "2952", name: "CITIBANK N.A. LONDON/MTN", disabled: false },
   { value: "2971", name: "TRUIST BANK", disabled: false },
   {
      value: "2973",
      name: "JPMORGAN CHASE BANK/MET LIFE LOANET",
      disabled: false,
   },
   {
      value: "2975",
      name: "FIFTH THIRD BANK, NATIONAL ASSOCIATION/PUBLIC EMPLOYEES RETIREMENT SYSTEM 2985 BNYMELLON/RE RBC I&TS",
      disabled: false,
   },
   { value: "2993", name: "CHARLES SCHWAB TRUST BANK", disabled: false },
   {
      value: "3516",
      name: "THE BANK OF NEW YORK MELLON/WFC HOLDINGS CORPORATION 3593 BNYMELLON/RE NORDEA DK/SEC FINANCE SE",
      disabled: false,
   },
   { value: "3598", name: "CURVATURE SECURITIES, LLC", disabled: false },
   {
      value: "3622",
      name: "J.P. MORGAN CHASE BANK NA/FBO BLACKROCK CTF",
      disabled: false,
   },
   { value: "3664", name: "SOFI SECURITIES, LLC", disabled: false },
   {
      value: "3670",
      name: "MIZUHO SECURITIES USA LLC/MIZUHO MARKETS CAYMAN 3691 ROBINHOOD SECURITIES, LLC/ SECURITIES LENDING",
      disabled: false,
   },
   {
      value: "3821",
      name: "ELECTRONIC TRANSACTION CLEARING, INC./HOUSE",
      disabled: false,
   },
   { value: "3856", name: "VELOX CLEARING LLC", disabled: false },
   {
      value: "3884",
      name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION/INTERMEDIARY HOLDING COMPANY",
      disabled: false,
   },
   {
      value: "3915",
      name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION/FBO TEMASEK INTERNATIONAL PTE LTD",
      disabled: false,
   },
   {
      value: "3916",
      name: "BNYMELLON/NOMURA FINANCIAL PRODUCTS EUROPE GMBH",
      disabled: false,
   },
   {
      value: "3926",
      name: "CITIBANK, N.A./S.D. INDEVAL INSTITUCION PARA EL DEPOSITO DE VALORES, S.A. DE C.V. 3932 E D & F MAN CAPITAL MARKETS INC./ SECURITIES LENDING",
      disabled: false,
   },
   {
      value: "3935",
      name: "MORGAN STANLEY & CO. INTERNATIONAL PLC/MORGAN STANLEY BANK A.G.",
      disabled: false,
   },
   {
      value: "3944",
      name: "GOLDMAN SACHS BANK USA/GS & CO. LLC FCM CLEARED SWAPS CUST OMNIBUS ACCT CFTC REG 22.2 CUST 3951 NATIONAL FINANCIAL SERVICES/ FIDELITY AGENCY LENDING",
      disabled: false,
   },
   { value: "3961", name: "BUCKLER SECURITIES LLC", disabled: false },
   {
      value: "3967",
      name: "BNYMELLON/RE RBC CAPITAL MARKETS (EUROPE) GMBH",
      disabled: false,
   },
   {
      value: "3968",
      name: "BNYMELLON/RE HH ELLINGTON MASTER FUND II LTD",
      disabled: false,
   },
   { value: "3970", name: "BNYMELLON/RE FIRM SAM NA", disabled: false },
   {
      value: "3972",
      name: "BNYMELLON/RE BARCLAYS BANK DELAWARE",
      disabled: false,
   },
   {
      value: "3973",
      name: "BNYMELLON/RE SMBC CAPITAL MARKETS FRANKFURT",
      disabled: false,
   },
   { value: "3974", name: "CITIBANK, N.A./PROJECT BLUE", disabled: false },
   { value: "3975", name: "BOKF, NATIONAL ASSOCIATION", disabled: false },
   { value: "4006", name: "SOUTH STREET SECURITIES LLC/IMS", disabled: false },
   {
      value: "4024",
      name: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK",
      disabled: false,
   },
   { value: "4027", name: "CITIBANK, N.A./PUERTO RICO IBE", disabled: false },
   {
      value: "4048",
      name: "BROWN BROTHERS HARRIMAN & CO. /SECURITIES LENDING SPO ACCOUNT II 4053 TRUIST BANK/SUPPLY CHAIN FINANCE",
      disabled: false,
   },
   {
      value: "4056",
      name: "GOLDMAN SACHS INTERNATIONAL/ GOLDMAN SACHS BANK EUROPE SE 4077 BETA CAPITAL SECURITIES LLC",
      disabled: false,
   },
   { value: "4084", name: "BNYMELLON/RE FIRM NA", disabled: false },
   { value: "4085", name: "BNYMELLON/RE LME CLEAR", disabled: false },
   {
      value: "4086",
      name: "BNYMELLON/RE ELLINGTON MRTG RECOVERY MASTER F LP",
      disabled: false,
   },
   { value: "4087", name: "BNYMELLON/RE BCSL PB SEG AC 5", disabled: false },
   {
      value: "4088",
      name: "BNYMELLON/RE ARBEJDSMARKEDETS TILLAEGSPENSION",
      disabled: false,
   },
   {
      value: "4101",
      name: "JPMORGAN CHASE BANK, N.A./JPMORGAN CHASE HOLDINGS LLC",
      disabled: false,
   },
   {
      value: "4102",
      name: "JPMORGAN CHASE BANK, N.A/CHIEF INVESTMENT OFFICE 4",
      disabled: false,
   },
   {
      value: "4180",
      name: "DEPOSITO CENTRAL DE VALORES S.A., DEPOSITO DE VALORES/BVE",
      disabled: false,
   },
   { value: "4204", name: "REGIONS BANK", disabled: false },
   {
      value: "4209",
      name: "MORGAN STANLEY PRIVATE BANK, NATIONAL ASSOCIATION/ MSPBNA CIO 4215 TD SECURITIES (USA) LLC/PHYSICAL ACCOUNT",
      disabled: false,
   },
   { value: "4230", name: "TRADELINK SECURITIES LLC", disabled: false },
   {
      value: "4231",
      name: "CLEAR STREET LLC/SECURITIES LENDING",
      disabled: false,
   },
   {
      value: "4252",
      name: "BNP PARIBAS NEW YORK BRANCH / BNP PARIBAS SA (METLIFE)",
      disabled: false,
   },
   {
      value: "4254",
      name: "SCOTIA CAPITAL (USA) INC./AS AGENT FOR THE BANK OF NOVA SCOTIA,TORONTO BRANCH",
      disabled: false,
   },
   {
      value: "4264",
      name: "ICE SECURITIES EXECUTION & CLEARING, LLC",
      disabled: false,
   },
   { value: "4265", name: "MARKETAXESS CORPORATION", disabled: false },
   { value: "4272", name: "FUTU CLEARING INC.", disabled: false },
   {
      value: "4302",
      name: "BNP PARIBAS, NEW YORK BRANCH/MARKETAXESS/CLIENT ASSETS 4327 CITIBANK, N.A./CITIBANK MARGIN LOANS BRANCH",
      disabled: false,
   },
   { value: "4341", name: "BNYMELLON/RE HSBC UK BANK PLC", disabled: false },
   {
      value: "4404",
      name: "GOLDMAN SACHS & CO. LLC/CME HOUSE COLLATERAL ACCT",
      disabled: false,
   },
   {
      value: "4424",
      name: "VELOCITY CAPITAL LLC/PROPRIETARY ACCOUNT",
      disabled: false,
   },
   {
      value: "4439",
      name: "APEX CLEARING CORPORATION/APEX CLEARING MATCHBOOK",
      disabled: false,
   },
   {
      value: "4462",
      name: "BNYMELLON/RE NM PERA ELLINGTON ENHANCED INCOME FUND A LLC 4467 MIZUHO SECURITIES USA LLC/MIZUHO MUNICIPAL HOLDINGS, LLC",
      disabled: false,
   },
   { value: "4556", name: "BNYMELLON/RE FIRM RE SANV", disabled: false },
   {
      value: "4700",
      name: "THE BANK OF NOVA SCOTIA/BNS PRIME GLOSS/CDS",
      disabled: false,
   },
   {
      value: "4707",
      name: "ROYAL BANK OF CANADA-ROYAL TRUST/CDS**",
      disabled: false,
   },
   {
      value: "4712",
      name: "BMO NESBITT BURNS INC./BMO TRUST COMPANY/CDS**",
      disabled: false,
   },
   {
      value: "4717",
      name: "TORONTO-DOMINION BANK (THE)/TD GLOBAL FINANCE UNLIMITED COMPANY",
      disabled: false,
   },
   {
      value: "4794",
      name: "THE BANK OF NOVA SCOTIA/PRINCIPAL EQUITIES/CDS**",
      disabled: false,
   },
   {
      value: "4795",
      name: "THE BANK OF NOVA SCOTIA/SUB FIXED INCOME/IMPACT/CDS**",
      disabled: false,
   },
   {
      value: "4797",
      name: "BMO NESBITT BURNS/INSTITUTIONAL/CDS**",
      disabled: false,
   },
   {
      value: "4800",
      name: "CDS CLEARING AND DEPOSITORY SERVICES INC.",
      disabled: false,
   },
   {
      value: "4801",
      name: "RBC DOMINION SECURITIES INC./CDS**",
      disabled: false,
   },
   {
      value: "4802",
      name: "BANK OF NOVA SCOTIA/BNS LONDON/CDS**",
      disabled: false,
   },
   { value: "4805", name: "TORONTO-DOMINION BANK (THE)**", disabled: false },
   {
      value: "4808",
      name: "J.P. MORGAN SECURITIES CANADA INC. **",
      disabled: false,
   },
   {
      value: "4809",
      name: "BMO NESBITT BURNS INC./BMO NB Equity Finance CMLUK/CDS",
      disabled: false,
   },
   {
      value: "4810",
      name: "THE BANK OF NOVA SCOTIA/BNS TOR PRINCIPAL GLOSS/CDS",
      disabled: false,
   },
   { value: "4812", name: "THE BANK OF NOVA SCOTIA/CDS**", disabled: false },
   {
      value: "4814",
      name: "THE BANK OF NOVA SCOTIA/ SCE LTD./CDS**",
      disabled: false,
   },
   { value: "4816", name: "THE BANK OF NOVA SCOTIA/CLIENT A", disabled: false },
   { value: "4817", name: "BANK OF MONTREAL/ CHICAGO/CDS**", disabled: false },
   { value: "4818", name: "FIDUCIE DESJARDINS INC.**", disabled: false },
   { value: "4819", name: "BANK OF MONTREAL/ IRELAND/CDS**", disabled: false },
   { value: "4822", name: "BANK OF MONTREAL/ LONDON/CDS**", disabled: false },
   {
      value: "4838",
      name: "THE BANK OF NOVA SCOTIA/CLIENT B/CDS** 4845 BANK OF MONTREAL/US TRANSIT/CDS",
      disabled: false,
   },
   { value: "4855", name: "BANK OF MONTREAL**", disabled: false },
   {
      value: "4947",
      name: "BMO NESBITT BURNS INC./BMO NB Equity Finance BMIRE/CDS",
      disabled: false,
   },
   {
      value: "5001",
      name: "LAURENTIAN BANK SECURITIES INC./CDS",
      disabled: false,
   },
   {
      value: "5002",
      name: "RBC DOMINION SECURITIES INC./CDS**",
      disabled: false,
   },
   {
      value: "5003",
      name: "SOCIETE GENERALE CAPITAL CANADA INC./CDS**",
      disabled: false,
   },
   {
      value: "5008",
      name: "NATIONAL BANK FINANCIAL INC./CDS**",
      disabled: false,
   },
   { value: "5011", name: "SCOTIA CAPITAL INC./CDS**", disabled: false },
   { value: "5012", name: "EDWARD JONES/CDS**", disabled: false },
   { value: "5013", name: "CALDWELL SECURITIES LTD./CDS**", disabled: false },
   { value: "5014", name: "PETERS & CO. LIMITED/CDS**", disabled: false },
   { value: "5016", name: "RF SECURITIES CLEARING L.P./CDS", disabled: false },
   { value: "5017", name: "UBS SECURITIES CANADA INC./CDS**", disabled: false },
   {
      value: "5019",
      name: "CREDIT SUISSE SECURITIES CANADA INC./CDS**",
      disabled: false,
   },
   { value: "5027", name: "PICTET CANADA L.P./CDS**", disabled: false },
   { value: "5028", name: "DESJARDINS SECURITIES INC./CDS**", disabled: false },
   {
      value: "5029",
      name: "MACKIE RESEARCH CAPITAL CORPORATION/CDS**",
      disabled: false,
   },
   { value: "5030", name: "CIBC WORLD MARKETS INC./CDS**", disabled: false },
   {
      value: "5036",
      name: "TD WATERHOUSE CANADA INC./CDS** 5040 FIDELITY CLEARING CANADA ULC/CDS**",
      disabled: false,
   },
   { value: "5043", name: "BMO NESBITT BURNS INC./CDS**", disabled: false },
   {
      value: "5044",
      name: "ROYAL BANK OF CANADA-ROYAL TRUST 1/CDS**",
      disabled: false,
   },
   { value: "5046", name: "CANACCORD GENUITY CORP./CDS**", disabled: false },
   {
      value: "5047",
      name: "MANULIFE SECURITIES INCORPORATED/CDS**",
      disabled: false,
   },
   { value: "5058", name: "HAYWOOD SECURITIES INC./CDS**", disabled: false },
   {
      value: "5068",
      name: "CDS CLEARING AND DEPOSITORY SERVICES INC./CDS DEFAULT MANAGEMENT CONTROL ACCOUNT 5071 LEEDE JONES GABLE INC./CDS**",
      disabled: false,
   },
   { value: "5074", name: "ODLUM BROWN LIMITED/CDS**", disabled: false },
   { value: "5075", name: "PI FINANCIAL CORP./CDS**", disabled: false },
   { value: "5076", name: "RAYMOND JAMES LTD./CDS**", disabled: false },
   { value: "5078", name: "W.D. LATIMER CO LTD./CDS**", disabled: false },
   { value: "5083", name: "CREDENTIAL SECURITIES INC./CDS**", disabled: false },
   { value: "5084", name: "QUESTRADE INC./CDS**", disabled: false },
   { value: "5085", name: "BBS SECURITIES INC./CDS**", disabled: false },
   {
      value: "5099",
      name: "CDS CLEARING AND DEPOSITORY SERVICES INC.**",
      disabled: false,
   },
   { value: "5101", name: "BARCLAYS CAPITAL INC.", disabled: false },
   {
      value: "5104",
      name: "ING FINANCIAL MARKETS LLC/INTERNATIONAL 5127 MORGAN STANLEY & CO. LLC/II",
      disabled: false,
   },
   {
      value: "5143",
      name: "BOFA SECURITIES, INC./SECURITIES LENDING 5153 BNP PARIBAS NY BRANCH LONDON BONDS",
      disabled: false,
   },
   {
      value: "5157",
      name: "NATIONAL FINANCIAL SERVICES LLC/STOCK LOAN",
      disabled: false,
   },
   { value: "5158", name: "CREWS & ASSOCIATES, INC.", disabled: false },
   {
      value: "5162",
      name: "DEUTSCHE BANK SECURITIES INC.-INTERNATIONAL STOCK LOAN",
      disabled: false,
   },
   { value: "5163", name: "PERSHING LLC/SL", disabled: false },
   {
      value: "5166",
      name: "WEDBUSH SECURITIES INC./STOCK LOAN",
      disabled: false,
   },
   {
      value: "5170",
      name: "UBS FINANCIAL SERVICES INC./GOVERNMENT SECURITIES ACCOUNT #2",
      disabled: false,
   },
   {
      value: "5175",
      name: "TEMPER OF THE TIMES INVESTOR SERVICES, INC.",
      disabled: false,
   },
   {
      value: "5176",
      name: "BOFA SECURITIES, INC./FOREIGN SECURITY LENDING",
      disabled: false,
   },
   {
      value: "5179",
      name: "RAYMOND JAMES & ASSOCIATES, INC./RAYMOND JAMES TRUST COMPANY",
      disabled: false,
   },
   { value: "5180", name: "NSI STOCK LENDING", disabled: false },
   { value: "5196", name: "PERSHING LLC/SL INT'L", disabled: false },
   {
      value: "5198",
      name: "BOFA SECURITIES, INC./SAFEKEEPING",
      disabled: false,
   },
   {
      value: "5199",
      name: "WELLS FARGO SECURITIES, LLC/SIG WELLS FARGO SECURITIES INT'L, LTD",
      disabled: false,
   },
   { value: "5208", name: "GOLDMAN SACHS INTERNATIONAL", disabled: false },
   {
      value: "5213",
      name: "J.P. MORGAN SECURITIES LLC/JPMC LENDING",
      disabled: false,
   },
   {
      value: "5215",
      name: "CITIGROUP GLOBAL MARKETS INC./SALOMON BROTHERS/A.M.M. 5217 FMSBONDS, INC.",
      disabled: false,
   },
   { value: "5221", name: "BMO CAPITAL MARKETS CORP./PALOMA", disabled: false },
   { value: "5222", name: "NOMURA SECURITIES/FIXED INCOME", disabled: false },
   { value: "5224", name: "MORGAN STANLEY & CO. LLC/III", disabled: false },
   {
      value: "5225",
      name: "DEUTSCHE BANK SECURITIES INC.-FIXED INCOME STOCK LOAN",
      disabled: false,
   },
   {
      value: "5237",
      name: "WELLS FARGO CLEARING SERVICES, LLC/SECURITIES LENDING MATCH BOOK 5253 CANTOR FITZGERALD/STOCK LOAN",
      disabled: false,
   },
   { value: "5257", name: "BMOCM/BONDS", disabled: false },
   {
      value: "5263",
      name: "NATWEST MARKETS SECURITIES INC./FIXED INCOME",
      disabled: false,
   },
   {
      value: "5268",
      name: "ING FINANCIAL MARKETS LLC/INTERNATIONAL EQUITY FINANCE",
      disabled: false,
   },
   {
      value: "5271",
      name: "BGC FINANCIAL L.P./BGC BROKERS L.P.",
      disabled: false,
   },
   {
      value: "5284",
      name: "UBS SECURITIES LLC/SECURITIES LENDING",
      disabled: false,
   },
   {
      value: "5288",
      name: "SECURITIES LENDING SPO ACCOUNT/BBH",
      disabled: false,
   },
   {
      value: "5298",
      name: "TD AMERITRADE CLEARING, INC./SECURITIES LENDING",
      disabled: false,
   },
   { value: "5300", name: "NYSE ARCA, INC.", disabled: false },
   {
      value: "5385",
      name: "TRUIST BANK/BRANCH BANKING AND TRUST COMPANY",
      disabled: false,
   },
   {
      value: "5409",
      name: "RELIANCE TRUST COMPANY/FIS TRUSTDESK MKE",
      disabled: false,
   },
   {
      value: "5600",
      name: "JAPAN SECURITIES DEPOSITORY CENTER, INC. 5610 CAJA DE VALORES S.A.",
      disabled: false,
   },
   {
      value: "5700",
      name: "THE CENTRAL DEPOSITORY (PTE) LIMITED",
      disabled: false,
   },
   { value: "5855", name: "PAXOS TRUST COMPANY, LLC", disabled: false },
   {
      value: "5903",
      name: "MIZUHO SECURITIES USA LLC/MIZUHO MARKETS AMERICA 5954 MATRIX TRUST COMPANY",
      disabled: false,
   },
   { value: "5962", name: "RELIANCE TRUST COMPANY", disabled: false },
   { value: "5981", name: "E*TRADE SAVINGS BANK", disabled: false },
   { value: "6019", name: "VELOCITY CAPITAL LLC", disabled: false },
   {
      value: "6501",
      name: "BNYMELLON/RE ANZ BANKING GROUP LIMITED",
      disabled: false,
   },
   {
      value: "6582",
      name: "MERRILL LYNCH, PIERCE, FENNER & SMITH INCORPORATED/STOCK LOAN",
      disabled: false,
   },
   { value: "6769", name: "ROBINHOOD SECURITIES, LLC", disabled: false },
   {
      value: "7260",
      name: "AMERICAN ENTERPRISE INVESTMENT SERVICES INC./CONDUIT",
      disabled: false,
   },
   {
      value: "7263",
      name: "BARCLAYS BANK PLC NEW YORK BRANCH",
      disabled: false,
   },
   {
      value: "7268",
      name: "STATE STREET BANK & TRUST COMPANY/LENDING PASS-THROUGH",
      disabled: false,
   },
   {
      value: "7273",
      name: "ING FINANCIAL MARKETS LLC/INTERNATIONAL EQUITY FINANCE MATCH BOOK ACCOUNT 7276 INSTINET, LLC/STOCK LOAN",
      disabled: false,
   },
   {
      value: "7295",
      name: "CIBC WORLD MARKETS CORP./CIBC WORLD MARKETS CORP. REPO",
      disabled: false,
   },
   {
      value: "7305",
      name: "MERRILL LYNCH PIERCE FENNER & SMITH INC.-MLIM GLOBAL SECURITIES FINANCING INTERNATIONAL 7309 MORGAN STANLEY & CO. INTERNATIONAL PLC",
      disabled: false,
   },
   {
      value: "7311",
      name: "CANTOR FITZGERALD & CO./DEBT CAPITAL MARKETS",
      disabled: false,
   },
   {
      value: "7320",
      name: "JANNEY MONTGOMERY SCOTT LLC/STOCK LOAN",
      disabled: false,
   },
   {
      value: "7322",
      name: "CHARLES SCHWAB & CO., INC. STOCK LOAN CONDUIT ACCOUNT 7350 WOLVERINE EXECUTION SERVICES, LLC",
      disabled: false,
   },
   {
      value: "7359",
      name: "VELOCITY CLEARING, LLC/STOCK LOAN",
      disabled: false,
   },
   {
      value: "7360",
      name: "WELLS FARGO CLEARING SERVICES, LLC/SUB ACCOUNT WFA REPO",
      disabled: false,
   },
   {
      value: "7372",
      name: "CREDIT AGRICOLE SECURITIES (USA) INC/F/B/O CREDIT AGRICOLE NY BRANCH",
      disabled: false,
   },
   {
      value: "7380",
      name: "MIZUHO SECURITIES USA LLC/MIZUHO CAPITAL MARKETS LLC",
      disabled: false,
   },
   {
      value: "7381",
      name: "RELIANCE TRUST COMPANY/FIS GLOBAL PLUS",
      disabled: false,
   },
   {
      value: "7382",
      name: "BNP PARIBAS NY BRANCH/PARIS BONDS",
      disabled: false,
   },
   { value: "7387", name: "BNYMELLON/RE HSBC BANK HONG KONG", disabled: false },
   { value: "7408", name: "RBC CAPITAL MARKETS, LLC/RBCCM", disabled: false },
   {
      value: "7441",
      name: "JEFFERIES LLC/AS AGENT FOR JEFFERIES INTERNATIONAL LONDON 7451 SOUTH STREET SECURITIES LLC",
      disabled: false,
   },
   {
      value: "7507",
      name: "NOMURA SECURITIES INTERNATIONAL, INC./AFFILIATE CLEARING 7540 CREDIT AGRICOLE SECURITIES (USA) INC/STOCK LOAN CONDUIT",
      disabled: false,
   },
   {
      value: "7560",
      name: "BOFA SECURITIES, INC./STOCK LOAN HEDGE ACCOUNT",
      disabled: false,
   },
   {
      value: "7561",
      name: "DAIWA CAPITAL MARKETS AMERICA INC./DASAC",
      disabled: false,
   },
   { value: "7565", name: "JEFFERIES LLC/SECURITIES FINANCE", disabled: false },
   {
      value: "7568",
      name: "RAYMOND JAMES & ASSOCIATES, INC / RAYMOND JAMES BANK 7571 ABN AMRO SECURITIES (USA) LLC/A/C#2",
      disabled: false,
   },
   { value: "7576", name: "AXOS CLEARING LLC/STOCK LOAN", disabled: false },
   { value: "7578", name: "TD PRIME SERVICES LLC/STOCK LOAN", disabled: false },
   { value: "7582", name: "COMPASS BANK/INVESTMENTS", disabled: false },
   {
      value: "7583",
      name: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA FINANCIAL SERVICES LLC/ SECURITIES LENDING",
      disabled: false,
   },
   {
      value: "7587",
      name: "CHARLES SCHWAB & CO., INC./SCHWAB GLOBAL INVESTING ACCOUNT",
      disabled: false,
   },
   {
      value: "7590",
      name: "ABN AMRO SECURITIES (USA) LLC/REPO",
      disabled: false,
   },
   {
      value: "7591",
      name: "ABN AMRO SECURITIES (USA) LLC/ABN AMRO BANK NV REPO",
      disabled: false,
   },
   {
      value: "7595",
      name: "ING FINANCIAL MARKETS LLC/GLOBAL SECURITIES FINANCE NON-PURPOSE",
      disabled: false,
   },
   {
      value: "8020",
      name: "S.D. INDEVAL INSTITUCION PARA EL DEPOSITO DE VALORES S.A. DE C.V. 8035 TRADESTATION SECURITIES, INC./TRADESTATION SECURITIES, INC. 8043 BNYMELLON/WFB.NA WELLS FARGO BANK NA PI",
      disabled: false,
   },
   { value: "8072", name: "ALPINE SECURITIES CORPORATION", disabled: false },
   {
      value: "8077",
      name: "BNYMELLON/WF & CO WELLS FARGO & COMPANY 8082 ZIV INVESTMENT CO.",
      disabled: false,
   },
   { value: "8107", name: "BNYMELLON/RE FIRM BORROW PLUS", disabled: false },
   {
      value: "8112",
      name: "JPMORGAN CHASE BANK/OHIO POLICE AND FIRE PENSION FUND",
      disabled: false,
   },
   { value: "8113", name: "ITAU BBA USA SECURITIES, INC.", disabled: false },
   {
      value: "8115",
      name: "THE BANK OF NEW YORK MELLON/EARN SECURITIES LLC",
      disabled: false,
   },
   {
      value: "8116",
      name: "THE BANK OF NEW YORK MELLON/EARN CMO LLC",
      disabled: false,
   },
   {
      value: "8117",
      name: "THE BANK OF NEW YORK MELLON/EARN MORTGAGE LLC",
      disabled: false,
   },
   {
      value: "8118",
      name: "SCOTIA CAPITAL (USA) INC./STOCK LOAN",
      disabled: false,
   },
   {
      value: "8119",
      name: "SCOTIA CAPITAL (USA) INC./INTERNATIONAL STOCK LOAN",
      disabled: false,
   },
   {
      value: "8123",
      name: "THE BANK OF NEW YORK MELLON/NOMURA FIN. PRODUCTS & SERVICES INC.",
      disabled: false,
   },
   { value: "8132", name: "BNYMELLON/RE FIRM HOLDING CO.", disabled: false },
   { value: "8134", name: "BNYMELLON/RE BNYMSANVLB RE FIRM", disabled: false },
   { value: "8139", name: "BNYMELLON/RE BBPLC F LRCM REPO", disabled: false },
   {
      value: "8147",
      name: "STATE STREET BANK & TRUST COMPANY/EC, GMBH",
      disabled: false,
   },
   { value: "8150", name: "MID ATLANTIC TRUST COMPANY", disabled: false },
   { value: "8164", name: "CITIBANK N.A./PROPRIETARY ASSETS", disabled: false },
   { value: "8183", name: "BNP PARIBAS NEW YORK BRANCH/USAL", disabled: false },
   {
      value: "8185",
      name: "COWEN AND COMPANY LLC/STOCK LOAN CONDUIT",
      disabled: false,
   },
   {
      value: "8187",
      name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION/PUBLIC EMPLOYESS RETIREMENT SYSTEM OF OHIO (OPERS) 8197 GOLDMAN SACHS BANK USA/#2",
      disabled: false,
   },
   { value: "8199", name: "WEDBUSH SECURITIES INC./P3", disabled: false },
   {
      value: "8233",
      name: "ZIONS BANCORPORATION, NATIONAL ASSOCIATION/PORTFOLIO",
      disabled: false,
   },
   {
      value: "8237",
      name: "WEDBUSH SECURITIES INC./PC STOCK LOAN",
      disabled: false,
   },
   {
      value: "8238",
      name: "BNP PARIBAS SECURITIES CORP./PRIME ARBITRAGE SLAB",
      disabled: false,
   },
   { value: "8244", name: "FIRST TRUST PORTFOLIOS, L.P.", disabled: false },
   { value: "8275", name: "BNYMELLON/WEALTH MANAGEMENT", disabled: false },
   {
      value: "8308",
      name: "APEX CLEARING CORPORATION/APEX CLEARING STOCK LOAN",
      disabled: false,
   },
   { value: "8310", name: "BNYMELLON/RE BNYMLB RE FIRM SF", disabled: false },
   { value: "8311", name: "BNYMELLON/RE BNYMLB RE FIRM", disabled: false },
   { value: "8313", name: "BNYMELLON/RE CACEIS BANK", disabled: false },
   { value: "8314", name: "BNYMELLON/RE CBD BAADER AG", disabled: false },
   { value: "8317", name: "BNYMELLON/RE CBD STEUBING AG", disabled: false },
   {
      value: "8318",
      name: "BNYMELLON/RE WINTERFLOOD SECURITIES LTD",
      disabled: false,
   },
   {
      value: "8320",
      name: "BNYMELLON/RE CACEIS BANK LUXEMBOURG",
      disabled: false,
   },
   { value: "8321", name: "BNYMELLON/RE GFI SECURITIES LTD", disabled: false },
   {
      value: "8353",
      name: "NATIONAL BANK OF CANADA FINANCIAL INC.",
      disabled: false,
   },
   {
      value: "8355",
      name: "BNYMELLON/RE UIT NSCC CNS CLEARANCE",
      disabled: false,
   },
   {
      value: "8385",
      name: "STIFEL, NICOLAUS & COMPANY, INC./STIFEL SECURITIES LENDING 8396 HSBC BANK USA, NA/CLEARING",
      disabled: false,
   },
   {
      value: "8402",
      name: "HSBC BANK USA, NA/HTM 8404 HSBC BANK USA, NA/AFS",
      disabled: false,
   },
   {
      value: "8412",
      name: "BNYMELLON/EF CORPORATE HOLDINGS LLC 8414 BNYMELLON/RE BB RE FIRM",
      disabled: false,
   },
   { value: "8417", name: "BNYMELLON/RE FFT RE FIRM", disabled: false },
   { value: "8420", name: "BNYMELLON/RE NA-BANK CUSTODY", disabled: false },
   { value: "8421", name: "BNYMELLON/RE BNYMIL FIRM", disabled: false },
   {
      value: "8423",
      name: "BNYMELLON/RE RABOBANK LONDONBRANCH FIXED INCOME 8430 CITADEL SECURITIES LLC",
      disabled: false,
   },
   {
      value: "8434",
      name: "RELIANCE TRUST COMPANY/ FIS TRUSTDESK",
      disabled: false,
   },
   { value: "8445", name: "DEALERWEB INC.", disabled: false },
   { value: "8447", name: "JPMCB/DNT ASSET TRUST", disabled: false },
   {
      value: "8449",
      name: "JPMCB/J.P. MORGAN SECURITIES CANADA INC.",
      disabled: false,
   },
   { value: "8451", name: "EUROCLEAR BANK SA/NV/IMS ACCOUNT", disabled: false },
   {
      value: "8455",
      name: "BARCLAYS BANK PLC NEW YORK BRANCH/BARCLAYS BANK PLC-LNBR",
      disabled: false,
   },
   { value: "8456", name: "ICAP CORPORATES LLC/CROSSTRADE", disabled: false },
   { value: "8457", name: "SAFRA SECURITIES LLC", disabled: false },
   {
      value: "8459",
      name: "SG AMERICAS SECURITIES LLC/PARIS CLEARING",
      disabled: false,
   },
   { value: "8460", name: "PHILLIP CAPITAL INC.", disabled: false },
   {
      value: "8462",
      name: "BNP PARIBAS NEW YORK BRANCH/BNP PARIBAS PROPRIETARY ASSETS",
      disabled: false,
   },
   { value: "8471", name: "BNYMELLON/RE CBD ICF BANK AG", disabled: false },
   { value: "8472", name: "BNYMELLON/RE RBC BARBADOS", disabled: false },
   { value: "8475", name: "BNYMELLON/RE RBCEL FIXED INCOME", disabled: false },
   {
      value: "8476",
      name: "BNYMELLON/RE BARCLAYS BANK PLC LONDON",
      disabled: false,
   },
   {
      value: "8478",
      name: "BNYMELLON/RE BBPLC CLIENT COLL SEC LNBR SEG",
      disabled: false,
   },
   {
      value: "8479",
      name: "BNYMELLON/RE RBCLB EQUITY FINANCE",
      disabled: false,
   },
   {
      value: "8481",
      name: "BNYMELLON/RE BARCLAYS OVERSIGHT MANAGEMENT INC",
      disabled: false,
   },
   { value: "8482", name: "BNYMELLON/RE RBCLB FIXED INCOME", disabled: false },
   {
      value: "8483",
      name: "BNYMELLON/RE BGC FINANCIAL LP M/M",
      disabled: false,
   },
   { value: "8485", name: "DESERET TRUST COMPANY - A", disabled: false },
   {
      value: "8486",
      name: "TEXAS TREASURY SAFEKEEPING TRUST COMPANY/NUP",
      disabled: false,
   },
   {
      value: "8487",
      name: "BNYMELLON/RE RBCEL EQUITY FINANCE",
      disabled: false,
   },
   {
      value: "8488",
      name: "BNYMELLON/RE COOPERATIEVE RABOBANK U.A.",
      disabled: false,
   },
   { value: "8489", name: "BNYMELLON/RE GCM CLIENT ACCOUNTS", disabled: false },
   { value: "8490", name: "BNYMELLON/RE RBC BAHAMAS BRANCH", disabled: false },
   {
      value: "8491",
      name: "BNYMELLON/RE DESHAW US BMCAE CUSTOM PORT L.L.C",
      disabled: false,
   },
   {
      value: "8493",
      name: "VISION FINANCIAL MARKETS LLC/SECURITIES LENDING 8497 JANE STREET CAPITAL, LLC",
      disabled: false,
   },
   { value: "8513", name: "MURIEL SIEBERT & CO., INC./#2", disabled: false },
   { value: "8699", name: "GOLDMAN SACHS & CO. LLC/IMS", disabled: false },
   {
      value: "8846",
      name: "FEDERAL HOME LOAN MORTGAGE CORPORATION/MULTIFAMILY",
      disabled: false,
   },
   {
      value: "8857",
      name: "COWEN AND COMPANY LLC/SUSQUEHANNA",
      disabled: false,
   },
   { value: "8858", name: "PRECISION SECURITIES, LLC", disabled: false },
   { value: "8860", name: "BETHESDA SECURITIES, LLC", disabled: false },
   {
      value: "8861",
      name: "JPMORGAN CHASE BANK/JP MORGAN PROPRIETARY ASSET ACCOUNT",
      disabled: false,
   },
   {
      value: "8862",
      name: "MERRILL LYNCH, PIERCE, FENNER & SMITH INCORPORATED/8862 MLPF&S TS SUB",
      disabled: false,
   },
   {
      value: "8867",
      name: "JPMORGAN CHASE BANK/CHIEF INVESTMENT OFFICE",
      disabled: false,
   },
   {
      value: "8869",
      name: "JPMORGAN CHASE BANK/CHIEF INVESTMENT OFFICE 2",
      disabled: false,
   },
   { value: "8870", name: "STONEX FINANCIAL INC./STOCK LOAN", disabled: false },
   {
      value: "8871",
      name: "JPMORGAN CHASE BANK/CHIEF INVESTMENT OFFICE 3",
      disabled: false,
   },
   { value: "8873", name: "E D & F MAN CAPITAL MARKETS INC.", disabled: false },
   {
      value: "8875",
      name: "MORGAN STANLEY SMITH BARNEY LLC/SL CONDUIT",
      disabled: false,
   },
   { value: "9132", name: "CLEAR STREET LLC", disabled: false },
   {
      value: "9180",
      name: "GOLDMAN SACHS BANK USA/GS & CO. LLC FCM CUST OMNIBUS ACCT CFTC REG 1.20 CUST SEG ACCT UNDER SEC",
      disabled: false,
   },
   { value: "9186", name: "B. RILEY SECURITIES, INC.", disabled: false },
   {
      value: "9487",
      name: "U.S. BANK N.A./QUASAR DISTRIBUTORS, LLC DEALER CLEARING SERVICES",
   },
];

export const investMentObjectives = [
   "CAPITAL_PRESERVATION",
   "INCOME",
   "GROWTH_INCOME",
   "GROWTH",
   "SPECULATION",
   "MAXIMUM_GROWTH",
   "BALANCED",
   "OTHER",
];

export const annualIncomeRange = [
   "0-25000",
   "25001-50000",
   "50001-100000",
   "100001-200000",
   "200001-300000",
   "300001-500000",
   "500001-1200000",
   "1200001-9999999",
];

export const formattedRange = [
   { "0-25000": "$0 to $25,000" },
   { "25001-50000": "$25,001 to $50,000" },
   { "50001-100000": "$50,001 to $100,000" },
   { "100001-200000": "$100,001 to $200,000" },
   { "200001-300000": "$200,001 to $300,000" },
   { "300001-500000": "$300,001 to $500,000" },
   { "500001-1200000": "$500,001 to $1,200,000" },
   { "1200001-9999999": "$1,200,001 to $9,999,999" },
   { "0-50000": "$0 to $50,000" },
   { "200001-500000": "$200,001 to $500,000" },
   { "500001-1000000": "$500,001 to $1,000,000" },
   { "1000001-5000000": "$1,000,001 to $5,000,000" },
   { "5000001-9999999": "$5,000,001 to $9,999,999" },
];

export const liquidNetWorth = [
   "0-50000",
   "50001-100000",
   "100001-200000",
   "200001-500000",
   "500001-1000000",
   "1000001-5000000",
   "5000001-9999999",
];

export const totalNetWorth = [
   "0-50000",
   "50001-100000",
   "100001-200000",
   "200001-500000",
   "500001-1000000",
   "1000001-5000000",
   "5000001-9999999",
];

export const taxBracket = [
   "10%-20%",
   "21%-30%",
   "31%-40%",
   "41%-50%",
   "above 50%",
];

export const riskTolerance = ["LOW", "MEDIUM", "HIGH"];

export const dividentReinvestment = ["REINVEST", "DO_NOT_REINVEST"];

export const FILE_SIZE = 15; // mb
export const SUPPORTED_FORMATS = [
   "image/jpg",
   "image/jpeg",
   "image/gif",
   "image/png",
];

export const RETAIL_USERS_ROLES = [
   "",
   "Other/Curious",
   "Individual Investor",
   "loggedOut",
];

export const TEARM_LINKS = {
   form_crs:
      "https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/crs_309397.pdf",
   brochure:
      "https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20-%20Part%202A%20Brochure%207-26-2021.docx.pdf",
   investment_advisory:
      "https://campaign.magnifi.com/hubfs/Magnifi%20Email%20Signature/Magnifi%20-%20Discretinary%20Non-Discretionary%20IMA%20%5BSent%207-22-21%5D-270408129-v7%20-%20Final.docx.pdf",
};

export const POLITICAL_TOOLTIP_CONTENT = [
   "“politically exposed person” means",
   "(a) a person who is or has been entrusted with prominent public functions by a foreign country, for example a Head of State or of government, senior politician, senior government, judicial or military official, senior executive of a state owned corporation, and important political party official",
   "(b) a person who is or has been entrusted domestically with prominent public functions, for example a Head of State or of government, senior politician, senior government, judicial or military official, senior executives of a state owned corporation and important political party official",
   "(c) a person who is or has been entrusted with a prominent function by an international organization like a member of senior management, such as a director, a deputy director and a member of the board or equivalent functions",
];

export const ADVISOR_REGISTER_LHS = [
   "Unlock insights to 27,000+ investment products",
   "Research stocks, ETFs, mutual funds, SMAs and model portfolios",
   "Uncover investment opportunities with thematic scores",
   "Easily access data from providers like Morningstar and Fi360",
];

export default {
   countryList,
   areYouAnList,
   investableAssetsList,
   assetUnderManagementList,
   ageGroupList,
   getAgeMapping,
   maritalStatus,
   accountType,
   tradingExperience,
   occupation,
   occupationDisclosure,
   relations,
   usStates,
   trustedContactCountry,
   liquidityTimeTradingAccount,
   financialGoalsTradingAccount,
   employmentTypeTradingAccount,
   uploadTypeTradAcc,
   investMentObjectives,
   annualIncomeRange,
   formattedRange,
   liquidNetWorth,
   totalNetWorth,
   riskTolerance,
   dividentReinvestment,
};
