import React from "react";
import {
   hasDigitRegex,
   hasLowerCaseRegex,
   hasUpperCaseRegex,
   hasMinimumCharactersRegex,
   hasSpecialCharactersRegex,
} from "Utils";

export default function PasswordRequirement(props) {
   if (!props) return null;
   const {
      fieldName,
      formProps,
      label = "Password Requirements:",
      size,
   } = props;

   if (!formProps) return null;
   const { errors, touched, values } = formProps;

   const getPasswordRules = password => [
      {
         label: "At least eight characters long",
         isValid: hasMinimumCharactersRegex.test(password),
      },
      {
         label: "At least one lower case letter",
         isValid: hasLowerCaseRegex.test(password),
      },
      {
         label: "At least one upper case letter",
         isValid: hasUpperCaseRegex.test(password),
      },
      {
         label: "At least one number",
         isValid: hasDigitRegex.test(password),
      },
      {
         label: "At least one special character (!@#$%^&*?,.()_|<>)",
         isValid: hasSpecialCharactersRegex.test(password),
      },
   ];

   const getColorCode = isValid => {
      if (errors[fieldName] && touched[fieldName]) {
         if (isValid) {
            return "green";
         } else {
            return "red";
         }
      } else {
         if (isValid) {
            return "green";
         } else {
            return ""; // by-default its black
         }
      }
   };

   return (
      <div className="magnifi-password-requirement-container">
         <div className="magnifi-password-requirement-label">{label}</div>
         <ul className="magnifi-password-requirement-wrapper">
            {getPasswordRules(values[fieldName]).map(item => (
               <li
                  style={{
                     color: getColorCode(item.isValid),
                     fontSize: size === "small" ? "11px" : "12.8px",
                  }}
               >
                  {item.label}
               </li>
            ))}
         </ul>
      </div>
   );
}
