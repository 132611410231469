import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// import { Security, LoginCallback } from '@okta/okta-react'
// import { toRelativeUrl } from '@okta/okta-auth-js'
// import { oktaAuth } from 'oktaAuth'

// import SettingsContainer from "./pages/SettingsContainer";
// import FundsContainer from './pages/FundsContainer'
// import ReportsContainerV2 from "./pages/ReportsContainerV2";

// import CallbackTDAmeritrade from 'pages/CallbackTDAmeritrade'

// import FourZeroFourContainer from "pages/404Container";
// import Support from 'pages/Support'
// import WidgetLogin from 'layouts/WebDashboard/WidgetLogin'

// import LandingPage from 'layouts/WebDashboard/LandingPage'
// import JoinPage from 'layouts/WebDashboard/LandingPage/JoinPage'
// import SearchResultsV3 from "layouts/WebDashboard/SearchResultsV3/Page";

// import AccPage from 'layouts/WebDashboard/AccountsPage/Page'
// import Login from "layouts/WebDashboard/Login";
// import ExternalLogin from 'layouts/WebDashboard/SharingAlphaFlow/ExternalLogin'
// import RedirectLogin from 'layouts/WebDashboard/RedirectLogin'
// import RegisterProfile from "layouts/WebDashboard/RegisterProfile";
// import RegisterProfileApple from 'layouts/WebDashboard/RegisterProfileApple'
// import ForgotPassword from "layouts/WebDashboard/ForgotPassword";
// import ChangePassword from 'layouts/WebDashboard/ChangePassword'
// import ForgotPasswordThankYou from "layouts/WebDashboard/ForgotPassword/ThankYouMsg";
// import PasswordReset from 'layouts/WebDashboard/PasswordReset'
// import DeleteAccount from "layouts/WebDashboard/DeleteAccount";
// import InvalidTokenPage from 'layouts/WebDashboard/PasswordReset/InvalidToken'

// import PortfolioReportV2 from 'layouts/WebDashboard/PortfolioReportV2'
// import PortfolioOverviewReport from 'layouts/WebDashboard/PortfolioOverviewReport'
// import SignOut from "layouts/WebDashboard/Login/SignOut";

// import BuildPortfolio from 'layouts/WebDashboard/BuildPortfolio'
// import Enhancer from 'layouts/WebDashboard/Enhancer'
// import Community from "layouts/WebDashboard/Community";
//import ClientPortfolio from 'layouts/WebDashboard/ClientPortfolio'
// import ClientPortfolioV2 from 'layouts/WebDashboard/ClientPortfolioV2'
// import PdfPage from './layouts/WebDashboard/BuilderReport/PDFPage'
// import RecommendationReport from './layouts/WebDashboard/RecommendationReport/RecommendationReport'
// import EnhancerInterimReport from './layouts/WebDashboard/EnhancerInterimReport'
// import ArchivedTable from 'layouts/WebDashboard/Settings/Branding/ArchiveTable'
import { PrivateRoute } from "shared-components";

// import config from './env'

// import AccountsPageV2 from 'layouts/WebDashboard/AccountsPageV2/Page';
// import Trading from 'layouts/WebDashboard/Trading'
// import Validate from "layouts/WebDashboard/Validate";

import { routerPages } from "./routermenu";
// import VerifyEmail from 'pages/verifyEmail'
// import RenderRegisterPage from "./RenderRegisterPage";
const SettingsContainer = React.lazy(() => import("./pages/SettingsContainer"));
const Community = React.lazy(() => import("layouts/WebDashboard/Community"));
const Login = React.lazy(() => import("layouts/WebDashboard/Login"));
const SearchResultsV3 = React.lazy(() =>
   import("layouts/WebDashboard/SearchResultsV3/Page")
);
const ReportsContainerV2 = React.lazy(() =>
   import("./pages/ReportsContainerV2")
);
const HomePage = React.lazy(() => import("./pages/HomePage"));
class ChokidarRouter extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         width: window.innerWidth,
      };
   }
   updateDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
   };
   componentDidMount() {
      window.addEventListener("resize", this.updateDimensions);
   }
   componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions);
   }
   render() {
      const { Layout, location } = this.props;

      // UN_COMMENT ALL ROUTE WHEN YOU REMOVE MAINTENANCE PAGE
      let loadBody = <PrivateRoute path="/" bypass component={Layout} />;
      let defaultLoad = (
         <Switch>
            {/*process.env.REACT_APP_SUB_DOMAIN === 'advisor' ? <Redirect exact path="/" to="/securities" /> : null*/}
            <Route exact path="/" component={HomePage} />
            <Route
               exact
               path="/securities"
               // component={lazy(() =>
               //    import("layouts/WebDashboard/SearchResultsV3/Page")
               // )}
               component={SearchResultsV3}
            />
            {/*
          <PrivateRoute path="/investments" component={AccPage} />
          <PrivateRoute path="/build-portfolio" component={BuildPortfolio} />
          <PrivateRoute path="/enhancer" component={Enhancer} />
          <PrivateRoute path="/advisor/accountV2" component={ClientPortfolioV2} />
          <PrivateRoute path="/archived/proposals" component={ArchivedTable} />
          <PrivateRoute
            path={config.tdCallbackPath}
            component={CallbackTDAmeritrade}
          />
          {process.env.REACT_APP_ENV === 'prod' ? (
            <Route
              path="/join"
              component={() => {
                // quick hotfix
                window.location.href = 'https://advisor.magnifi.com/pricing/'
                return null
              }}
            />
          ) : (
            <Route path="/join" exact component={JoinPage} />
          )}
          <Route exact path="/register/widget" component={WidgetLogin} />
          <Route exact path="/trading/review" component={Trading} />
          <Route exact path="/trading/bank/add" component={Trading} />
          <Route path="/portfolio-reports-v2" component={PortfolioReportV2} />
          <Route
          path="/portfolio-overview-report"
          component={PortfolioOverviewReport}
          />
          <Route path="/builder-report" component={PdfPage} />
          
          <Route path="/rec-enhancer" component={EnhancerInterimReport} />
         */}
            <Route path="/reports-v2" component={ReportsContainerV2} />
            {/* For UAT */}
            {/* TODO: Lazy Loading Community is causing a rerender on MWEB*/}
            <PrivateRoute
               path="/community"
               // component={lazy(() => import("layouts/WebDashboard/Community"))}
               component={Community}
            />
            <PrivateRoute path="/settings" component={SettingsContainer} />

            {/* <Route path="/support" component={Support} /> */}
            {/* TODO: Lazy Loading Login is causing a rerender and hence resetting user source check */}
            <Route
               exact
               path="/login"
               // component={lazy(() => import("layouts/WebDashboard/Login"))}
               component={Login}
            />
            {/* Sharing Alpha Login */}
            {/* <Route exact path="/ext-login" component={ExternalLogin} /> */}

            {/* <Route path="/verify/email" component={VerifyEmail} /> */}

            {/* <Route exact path="/redirect-login" component={RedirectLogin} /> */}
            <Route
               exact
               path="/signout"
               component={lazy(() =>
                  import("layouts/WebDashboard/Login/SignOut")
               )}
            />
            {/* Post Google Sign up to collect Additional Info */}
            <Route
               exact
               path="/register-profile/:id"
               component={lazy(() =>
                  import("layouts/WebDashboard/RegisterProfile")
               )}
            />
            {/* <Route
          exact
          path="/register-profile/a/:id"
          component={RegisterProfileApple}
        /> */}
            <Route
               exact
               path="/register"
               component={lazy(() => import("./RenderRegisterPage"))}
            />
            {/* <Route exact path="/register/trading" component={Trading} /> */}
            <Route
               exact
               path="/forgotpwd"
               component={lazy(() =>
                  import("layouts/WebDashboard/ForgotPassword")
               )}
            />
            {/* <Route exact path="/changepwd" component={ChangePassword} /> */}
            <Route
               exact
               path="/forgotpwdthankyou"
               component={lazy(() =>
                  import("layouts/WebDashboard/ForgotPassword/ThankYouMsg")
               )}
            />
            {/* <Route exact path="/invalidtoken" component={InvalidTokenPage} /> */}
            {/* <Route exact path="/password-reset" component={PasswordReset} /> */}
            <Route
               exact
               path="/delete-account"
               component={lazy(() =>
                  import("layouts/WebDashboard/DeleteAccount")
               )}
            />
            {/* <Route path="/search-results" component={FundsContainer} /> */}
            <Route
               path="/auth/callback"
               component={lazy(() => import("layouts/WebDashboard/Validate"))}
            />
            <Route
               path="/404"
               component={lazy(() => import("pages/404Container"))}
            />
            <Redirect to="/404" />
         </Switch>
      );

      // console.log(location.pathname);
      // console.log(Object.keys(routerPages), location.pathname.split('/'));
      // console.log(Object.keys(routerPages).includes(location.pathname));

      // let primaryPath = location.pathname.split("/");
      // primaryPath = primaryPath[0] === "" ? primaryPath[1] : primaryPath[0];
      // console.log(primaryPath);
      // console.log(Object.keys(routerPages));
      // console.log(Object.keys(routerPages).find(e => e === location.pathname));

      const foundRoute = Object.keys(routerPages).find(
         e => e === location.pathname
      );
      // console.log(foundRoute);
      if (foundRoute === undefined || typeof foundRoute === "undefined") {
         loadBody = defaultLoad;
      }

      // if (location.pathname && typeof routerPages[location.pathname] === 'undefined') {
      //   loadBody = defaultLoad;
      // }
      const imgTagsArr = document.body.getElementsByTagName("img");
      if (imgTagsArr && imgTagsArr.length > 0) {
         for (var i = 0; i < imgTagsArr.length; i++) {
            if (imgTagsArr[i] && imgTagsArr[i].getAttribute) {
               let zemantaSRC = imgTagsArr[i].getAttribute("src");
               if (zemantaSRC && zemantaSRC.includes("zemanta")) {
                  // console.log('===== FOUND ZEMANTA =====')
                  // console.log(zemantaSRC, imgTagsArr[i])
                  // console.log('===== REMOVING ZEMANTA =====')
                  imgTagsArr[i].remove();
                  // console.log('=========================')
               }
            }
         }
      }

      return <>{this.state.width <= 991 ? defaultLoad : loadBody}</>;
   }
}

export default ChokidarRouter;
