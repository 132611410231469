import React, { Component } from "react";
import { addPageViewEvent } from "Utils.js";
import NewSettings from "./Settings.js";
class Renderer extends Component {
   componentDidMount() {
      addPageViewEvent("profile page");
   }
   render() {
      return (
         <div
            style={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "space-between",
            }}
         >
            <NewSettings {...this.props} />
         </div>
      );
   }
}

export default Renderer;
