import zipObject from "lodash/zipObject";

const actions = [
   "LOAD_VIDEO",
   "PLAY_PAUSE_VIDEO",
   "PIP_VIDEO",
   "STOP_VIDEO",
   "TOGGLE_LOOP_VIDEO",
   "SET_VOLUME_VIDEO",
   "TOGGLE_MUTED_VIDEO",
   "SET_PLAYBACK_RATE_VIDEO",
   "ON_PLAY_VIDEO",
   "ON_ENABLE_PIP_VIDEO",
   "ON_DISABLE_PIP_VIDEO",
   "ON_PAUSE_VIDEO",
   "ON_SEEK_MOUSE_DOWN_VIDEO",
   "ON_SEEK_CHANGE_VIDEO",
   "ON_SEEK_MOUSE_UP_VIDEO",
   "ON_PROGRESS_VIDEO",
   "ON_ENDED_VIDEO",
   "ON_DURATION_VIDEO",
   "REFRESH_VIDEO_STATE",
   "UPDATE_VIDEO_STATE",
];

export default zipObject(actions, actions);
