import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, FormGroup, Label } from "reactstrap";
import { Modal, notification } from "antd";
import moment from "moment";
import { Regex } from "common/regex";
import isEmpty from "lodash/isEmpty";
import { Formik } from "formik";
import * as Yup from "yup";
import style from "./Profile.module.scss";
import { Spinner } from "shared-components";
import { addEventToAnalytics } from "Utils";
import ChangePassword from "./ChangePassword";
import EditableProfileForm from "./EditableProfileForm";
import {
   fetchProfile,
   updateProfile,
   profileChangePasswordRequest,
   deleteAccountEmailRequest,
} from "actions/profile";
import { createLoadingSelector } from "selectors/loading-selector";
import { encryptPassword } from "layouts/utils";
import { Col, Row } from "antd";

class Profile extends Component {
   constructor(props) {
      super(props);
      this.state = {
         closeAlert: false,
         toggleChangePassword: false,
         mode: "edit", // 'view'
         profile: {
            ...this.defaultProfile,
            default_portfolio_value: props.portfolioValue || 1000000,
            isSubmitted: false,
            advisory_fees: props.advisory_fees || "",
         },
         showChangePassword: false,
         showPortfolioValue: false,
         showAdvisoryEdit: false,
         showDeleteAccountModal: false,
         showDeleteGoodbyeModal: true,
         unregisterInterval: null,
         unregiterRequests: localStorage.getItem("unregister_requests")
            ? JSON.parse(window.localStorage.getItem("unregister_requests"))
            : [],
      };
   }

   getProfileSchema = () => {
      function emptyStringToNull(value, originalValue) {
         if (typeof originalValue === "string" && originalValue === "") {
            return null;
         }
         return value;
      }
      return Yup.object().shape({
         first_name: Yup.string()
            .required("First name is a required field")
            .matches(Regex.someCharacter, "First name is a required field"),
         last_name: Yup.string()
            .required("Last name is a required field")
            .matches(Regex.someCharacter, "Last name is a required field"),
         phone: Yup.string().matches(
            Regex.phone,
            "Phone number is not valid. Sample format (515) 989-2899"
         ),
         country: Yup.string().required("Country is a required field"),
         // state: Yup.string().required('State is a required field'),
         zipcode: Yup.string().matches(Regex.zipCode, "zip code is not valid"),
         address: Yup.string()
            // .required('Street is a required field')
            .max(30, "Street must be at most 30 characters"),
         share_class: Yup.string().required(
            "At least one option should be selected"
         ),
         default_portfolio_value: Yup.number(
            "Default Portfolio Value must be a number"
         ).required("Default Portfolio Value is a required field"),
         advisory_fees: Yup.number()
            .typeError("Advisory Fees must be a number")
            .min(0, "Min value allowed is 0.")
            .max(100, "Max value allowed is 100.")
            .transform(emptyStringToNull)
            .nullable()
            .test(
               "is-decimal",
               "Please enter valid number with maximum 2 decimal places",
               value =>
                  !value ? true : (value + "").match(/^\d+(\.\d{0,2})?$/)
            ),
         // .matches(
         //   /^\d+(\.\d{0,2})?$/,
         //   'Please enter valid number with'
         // )
      });
   };

   handleProfileUpdateSubmit = (values, { setSubmitting }) => {
      // console.log('profile')
      // debugger
      const { update, profile, profilePictureData } = this.props;
      // const { profile } = this.state
      let data = values;
      if (update.isEdit) {
         data = {
            ...values,
            first_name: update.fname,
            last_name: update.lname,
         };
      }
      // console.log(data)
      // data.phone_number = data.phone;
      // delete data.phone;

      delete data.terms_agreed;
      addEventToAnalytics(
         "Profile Update",
         "Profile Update",
         "PROFILE_UPDATE",
         {},
         false
      );
      // debugger
      // AISA KYU KIYA BHAI ?
      data.date_of_birth =
         values.date_of_birth &&
         typeof values.date_of_birth.getMonth === "function"
            ? moment(data.date_of_birth).utc().format("YYYY-MM-DD")
            : null;
      // debugger
      data.termsCondition = profile.terms_agreed;
      data.isSubmitted = true;
      data.avatar_visible =
         profilePictureData.length !== 0
            ? profilePictureData.avatar_visible
               ? 1
               : 0
            : profile.avatar_visible
            ? 1
            : 0;
      data.has_gravatar = profile.has_gravatar;
      const { avatar_url, avatar_ext, avatar_uploaded, ...tempData } = data;
      this.setState(
         { profile: data, showPortfolioValue: false, showAdvisoryEdit: false },
         () => {
            this.props.updateProfile(tempData);
            setSubmitting(false);
         }
      );
   };

   componentDidMount() {
      document.body.style.paddingTop = "245px";
      this.props.fetchProfile();
      this.checkUnregisterRequests();
   }

   componentWillUnmount() {
      clearInterval(this.state.unregisterInterval);
   }

   checkUnregisterRequests = () => {
      let unregiterReq = [];
      this.state.unregiterRequests.forEach(re => {
         if (moment().diff(moment(re.time), "hours") < 1) {
            unregiterReq.push(re);
         }
      });
      this.setState({ unregiterRequests: unregiterReq });
      window.localStorage.setItem(
         "unregister_requests",
         JSON.stringify(unregiterReq)
      );
      this.setState({
         unregisterInterval: setTimeout(() => {
            this.checkUnregisterRequests();
         }, 5000),
      });
   };

   checkdeleteReqStatus = () => {
      if (
         this.state.unregiterRequests.filter(
            el =>
               el.email === this.props.user.email &&
               el.id === this.props.profile.user &&
               moment().diff(moment(el.time), "hours") < 1
         ).length > 0
      ) {
         return true;
      }
      return false;
   };

   componentDidUpdate(prevProps) {
      document.body.style.paddingTop = "";
      document.body.classList.remove("ht0");
      if (this.props.profile !== prevProps.profile) {
         this.setState(
            {
               profile: isEmpty(this.props.profile)
                  ? this.state.profile
                  : { ...this.state.profile, ...this.props.profile },
            },
            () => {
               const { date_of_birth } = this.props.profile;
               if (!isNaN(Date.parse(date_of_birth))) {
                  // debugger
                  this.setState({
                     profile: { ...this.state.profile, isSubmitted: true },
                  });
               }

               if (typeof this.props.profile.date_of_birth === "string") {
                  const { date_of_birth } = this.props.profile;
                  this.setState({
                     profile: {
                        ...this.state.profile,
                        date_of_birth:
                           date_of_birth === ""
                              ? null
                              : new Date(this.props.profile.date_of_birth),
                     },
                  });
               }
            }
         );
      }

      if (
         !this.state.closeAlert &&
         this.props.profileUpdateSuccess !== prevProps.profileUpdateSuccess
      ) {
         this.setState({
            mode: "edit",
            closeAlert: true,
         });
         notification.open({
            description: "Profile updated successfully!",
            className: style.toastSuccess,
            duration: 4,
            message: null,
            top: 84,
            onClose: () => {
               this.setState({
                  closeAlert: false,
               });
            },
         });
         window.scrollTo(0, 0); //for viewing alert
      }

      if (
         !this.state.closeAlert &&
         this.props.profileUpdateFailed !== prevProps.profileUpdateFailed
      ) {
         this.setState({
            mode: "edit",
            closeAlert: true,
         });
         notification.open({
            description: "Unable to update the profile info",
            className: style.toastFail,
            duration: 4,
            message: null,
            top: 84,
            onClose: () => {
               this.setState({
                  closeAlert: false,
               });
            },
         });
         // window.scrollTo(0, 0)  //for viewing alert
      }

      // if (
      //   !this.state.closeAlert &&
      //   this.props.changePasswordSuccess !== prevProps.changePasswordSuccess
      // ) {
      //   this.setState({
      //     closeAlert: true
      //   })
      //   notification.open({
      //     description: 'Change password updated successfully',
      //     className: style.toastSuccess,
      //     duration: 4,
      //     message: null,
      //     top: 84,
      //     onClose: () => {
      //       this.setState({
      //         closeAlert: false
      //       })
      //     }
      //   })
      // }

      // if (
      //   !this.state.closeAlert &&
      //   this.props.changePasswordFailed !== prevProps.changePasswordFailed
      // ) {
      //   this.setState({
      //     closeAlert: true
      //   })
      //   notification.open({
      //     description: 'Unable to update the Change password',
      //     className: style.toastFail,
      //     duration: 4,
      //     message: null,
      //     top: 84,
      //     onClose: () => {
      //       this.setState({
      //         closeAlert: false
      //       })
      //     }
      //   })
      // }

      // if (this.props.changePasswordSuccess !== prevProps.changePasswordSuccess) {
      //   if (this.props.changePasswordSuccess) {
      //     this.setState(prevState => ({
      //       toggleChangePassword: !prevState.toggleChangePassword
      //     }))
      //   }
      // }
   }

   handleCancelPassword = () => {
      this.setState(prevState => ({
         toggleChangePassword: !prevState.toggleChangePassword,
      }));
   };

   handleChangePasswordSubmit = values => {
      // console.log('handleChangePasswordSubmit -->', values)
      const {
         auth: {
            token,
            user: { email },
         },
         profileChangePasswordRequest,
      } = this.props;
      if (profileChangePasswordRequest) {
         this.togglePasswordChangeModal();
         addEventToAnalytics(
            "Password Change",
            "Password Change",
            "PASSWORD_CHANGE",
            {},
            false
         );
         let payloadRequest = {
            email,
            verifypwdtoken: token,
            password: encryptPassword(values.password),
            old_password: encryptPassword(values.current_password),
         };
         profileChangePasswordRequest(payloadRequest);
      }
   };

   togglePasswordChangeModal = () => {
      if (!this.state.showChangePassword) {
         document
            .getElementById("settingsContainer")
            .classList.add("blur-effect");
         // document.getElementById('headerWrapper').classList.add('blur-effect')
      } else {
         document
            .getElementById("settingsContainer")
            .classList.remove("blur-effect");
         // document.getElementById('headerWrapper').classList.remove('blur-effect')
      }
      this.setState({
         showChangePassword: !this.state.showChangePassword,
      });
   };

   togglePortfolio = () => {
      !this.state.showPortfolioValue &&
         this.setState({
            showPortfolioValue: !this.state.showPortfolioValue,
         });
   };

   toggleAdvisoryField = () => {
      !this.state.showAdvisoryEdit &&
         this.setState({
            showAdvisoryEdit: !this.state.showAdvisoryEdit,
         });
   };

   handleAccountDelete = () => {
      clearInterval(this.state.unregisterInterval);
      this.deleteAccountConfirm();
   };

   deleteAccountConfirm = () => {
      addEventToAnalytics(
         "Delete Account Request",
         "Delete Account Request",
         "DELETE_ACCOUNT_REQUEST",
         { email: this.props.user.email },
         true
      );
      this.props.deleteAccountEmailRequest({}, responseData => {
         let unregiterReq = this.state.unregiterRequests;
         unregiterReq.push({
            email: this.props.user.email,
            id: this.props.profile.user,
            time: moment().toDate(),
         });
         window.localStorage.setItem(
            "unregister_requests",
            JSON.stringify(unregiterReq)
         );
         this.setState(
            { showDeleteAccountModal: true, unregiterRequests: unregiterReq },
            () => {
               this.checkUnregisterRequests();
            }
         );
      });
   };

   render() {
      const { isFetching, user, isSSOUser, deleteAccountLoading, profile } =
         this.props;
      // console.log('profile props', this.props, this.state.profile)
      const { showPortfolioValue, showAdvisoryEdit } = this.state;
      return (
         <div className={style.container}>
            <Spinner isFetching={isFetching || deleteAccountLoading} />
            <span className={style.subHeader}>Account</span>
            {/*form*/}
            <Formik
               enableReinitialize
               initialValues={this.state.profile}
               onSubmit={this.handleProfileUpdateSubmit}
               validationSchema={this.getProfileSchema()}
               render={props => (
                  <Form
                     className={style.form}
                     onSubmit={props.handleSubmit}
                     noValidate={true}
                  >
                     <EditableProfileForm
                        formProps={props}
                        showPortfolioValue={showPortfolioValue}
                        showAdvisoryEdit={showAdvisoryEdit}
                        toggleAdvisoryField={this.toggleAdvisoryField}
                        togglePortfolio={this.togglePortfolio}
                        email={user.email || profile.email}
                        isSSOUser={isSSOUser}
                        togglePasswordChangeModal={
                           this.togglePasswordChangeModal
                        }
                        showChangePassword={this.state.showChangePassword}
                        handleChangePasswordSubmit={
                           this.handleChangePasswordSubmit
                        }
                        handleCancelPassword={this.handleCancelPassword}
                     />
                     <button
                        onClick={props.onProfileUpdate}
                        className={style.update}
                     >
                        Update
                     </button>

                     <div className={style.divider} />
                     <div>
                        <Row>
                           <Col span={12}>
                              <Label className={style.key} for="self_role">
                                 <div style={{ fontSize: "22px" }}>
                                    {" "}
                                    Professional Vetting Status
                                 </div>
                              </Label>
                           </Col>
                           <Col span={12}>
                              <div
                                 className={`${style.cursorPointer} ${style.value}`}
                              >
                                 <Label
                                    className={style.profileLabel}
                                    for="self_role"
                                 >
                                    {!isEmpty(profile.vetted_role)
                                       ? profile.vetted_role
                                       : "Unknown"}
                                 </Label>
                              </div>
                           </Col>
                           <Col span={24}>
                              <div className={style.sub_content}>
                                 <span>
                                    Your personal rating of a fund will be
                                    included in the overall rating
                                 </span>
                                 <br />
                                 <span>
                                    of the fund. And, you will receive a ranking
                                    for fund selection and asset allocation.
                                 </span>
                                 <br />
                                 <br />
                                 <span>
                                    Furthermore, you will be able to provide a
                                    commentary and see
                                 </span>
                                 <br />
                                 <span>
                                    other members who have also rated the fund.
                                 </span>
                              </div>
                           </Col>
                        </Row>
                     </div>

                     <div className={style.divider} />

                     <FormGroup className={style.formGroup}>
                        <Label className={style.key} for="self_role">
                           <div style={{ fontSize: "22px" }}>
                              {" "}
                              Unregister from Magnifi
                           </div>
                        </Label>
                        <div
                           className={`${style.cursorPointer} ${style.value}`}
                        >
                           <button
                              type="button"
                              onClick={() => this.handleAccountDelete()}
                              className={
                                 this.checkdeleteReqStatus()
                                    ? style.delete_disable
                                    : style.delete
                              }
                              disabled={this.checkdeleteReqStatus()}
                           >
                              Unregister
                           </button>
                        </div>
                        <Modal
                           centered={true}
                           closeIcon={
                              <img
                                 src={"/assets/images/icons/Group 4050.svg"}
                                 className={style.Group_4050}
                                 alt="icon"
                              />
                           }
                           wrapClassName={style.modal}
                           onCancel={() => {
                              this.setState({ showDeleteAccountModal: false });
                           }}
                           destroyOnClose={true}
                           style={{ borderRadius: `25px` }}
                           width={858}
                           visible={this.state.showDeleteAccountModal}
                           footer={null}
                        >
                           <DeleteAccountModalMessage
                              showDeleteGoodbyeModal={
                                 this.state.showDeleteGoodbyeModal
                              }
                              deleteAccountLoading={deleteAccountLoading}
                              closeAccountModal={() => {
                                 addEventToAnalytics(
                                    "Keep My Account",
                                    "Keep My Account",
                                    "KEEP_MY_ACCOUNT",
                                    { email: this.props.user.email },
                                    true
                                 );
                                 this.setState({
                                    showDeleteAccountModal: false,
                                 });
                              }}
                              deleteAccountConfirm={this.deleteAccountConfirm}
                           />
                        </Modal>
                     </FormGroup>
                  </Form>
               )}
            />
            {!isSSOUser && (
               <Modal
                  title={null}
                  centered={true}
                  className={"antModal"}
                  footer={null}
                  width={`470px`}
                  destroyOnClose={true}
                  style={{ borderRadius: `30px` }}
                  visible={this.state.showChangePassword}
                  onCancel={this.togglePasswordChangeModal}
               >
                  <ChangePassword
                     onCancel={this.handleCancelPassword}
                     handleChangePasswordSubmit={
                        this.handleChangePasswordSubmit
                     }
                  />
               </Modal>
            )}
            {/*<ProfilePlanDetails premiumUser={user.premiumUser} />*/}
         </div>
      );
   }
}

const DeleteAccountModalMessage = ({
   showDeleteGoodbyeModal,
   closeAccountModal,
   deleteAccountConfirm,
   // deleteAccountLoading
}) => {
   return (
      <div className={style.goodbyemodal}>
         {/* <UserDeleteOutlined className={style.icon} /> */}
         <img
            src={"/assets/images/icons/noun_Account Delete_3579198.svg"}
            alt="delete"
            className={style.noun_Account_Delete}
         />
         {/* <Spinner isFetching={deleteAccountLoading} /> */}
         <span className={style.title}>Unregister from Magnifi</span>
         <span className={style.subtitle}>We're sorry to see you go</span>
         <div className={style.body}>
            {!showDeleteGoodbyeModal && (
               <>
                  <span className={style.main}>
                     Are you sure you don't want to reconsider?{" "}
                     <b>Help us to improve.</b>
                  </span>
                  <span className={style.main}>
                     Account deletion is final. There will be no way to restore
                     your account
                  </span>
               </>
            )}
            {showDeleteGoodbyeModal && (
               <>
                  <p className={style.mainP}>
                     We have sent you an Email for closing your account. Please
                     click on link in the email to proceed.
                  </p>
               </>
            )}
         </div>
         <div className={style.actions}>
            {!showDeleteGoodbyeModal && (
               <>
                  <button
                     className={style.ok}
                     type="button"
                     onClick={closeAccountModal}
                  >
                     <span>Keep My Account</span>
                  </button>
                  <button
                     className={style.cancel}
                     type="button"
                     onClick={deleteAccountConfirm}
                  >
                     <span>Delete Account</span>
                  </button>
               </>
            )}
            {showDeleteGoodbyeModal && (
               <>
                  <span className={style.message}>
                     Link will be active for 1 hour.
                  </span>
               </>
            )}
         </div>
      </div>
   );
};

const mapStateToProps = state => ({
   profilePictureData: state.profile.profilePictureData,
   update: state.profile.update,
   profile: state.profile.profile,
   user: state.auth.user,
   auth: state.auth,
   isSSOUser: Boolean(state.auth.isGoogleLogin),
   profileUpdateSuccess: state.profile.profileUpdateSuccess,
   profileUpdateFailed: state.profile.profileUpdateFailed,
   isFetching: createLoadingSelector([
      "PROFILE",
      "CHANGE_PASSWORD",
      "PROFILE_UPDATE",
   ])(state),
   deleteAccountLoading: createLoadingSelector(["EMAIL_DELETE_ACCOUNT"])(state),
   changePasswordSuccess: state.profile.changePasswordSuccess,
   changePasswordFailed: state.profile.changePasswordFailed,
   portfolioValue: state.profile.portfolioValue,
});

const mapDispatchToProps = {
   fetchProfile,
   updateProfile,
   profileChangePasswordRequest,
   deleteAccountEmailRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
