import zipObject from "lodash/zipObject";

const actions = [
   "GET_ALERTS",
   "GET_ALERTS_LOADING",
   "GET_ALERTS_SUCCESS",
   "GET_ALERTS_FAILURE",

   "UPDATE_ALERT",
   "UPDATE_ALERT_LOADING",
   "UPDATE_ALERT_SUCCESS",
   "UPDATE_ALERT_FAILURE",
];

export default zipObject(actions, actions);
