import zipObject from "lodash/zipObject";

const actions = [
   "DYM_LOGS",
   "UPDATE_FUNDS_DATA",
   "SEARCH_QUERY",
   "REFRESH_FUNDS_STATE",
   "SET_FUNDS_STATE",
   "FUNDS_DATA",
   "FUNDS_QUERY",
   "SET_UNIVERSE",
   "SEARCH_ANALYTICS_API",
   "SET_THEME_VALUE",
   "SET_SPONSRED_VALUE",

   "TRENDING_QUERIES_REQUEST",
   "TRENDING_QUERIES_FETCH",
   "TRENDING_QUERIES_SUCCESS",

   "GET_CHART_DATA_FOR_ALL_FUNDS",
   "UPDATE_SEL_FUNDS_IN_CRD",
   "UPDATE_CHART_REPORT_DATA",

   "DOWNLOAD_SEARCHED_RESULTS_REQUEST",
   "DOWNLOAD_SEARCHED_RESULTS_FETCH",
   "DOWNLOAD_SEARCHED_RESULTS_SUCCESS",

   "SELECTOR_WITH_TIME_FRAME_REQUEST",
   "SELECTOR_WITH_TIME_FRAME_FETCH",
   "SELECTOR_WITH_TIME_FRAME_SUCCESS",

   "UPDATE_FUNDS_SELECTOR_STATE",
   "REMOVE_FUNDS_SELECTOR_STATE",
   "REMOVE_ALL_FUNDS_SELECTOR_STATE",

   "RESET_FUNDS_UNIVERSE_STATE",

   // 'EXECUTE_MAGNIFI_BROKER_ORDERS_REQUEST',
   // 'EXECUTE_MAGNIFI_BROKER_ORDERS_FETCH',
   // 'EXECUTE_MAGNIFI_BROKER_ORDERS_SUCCESS',

   "SAVE_POPOVER_OPTION",
];

export default zipObject(actions, actions);
