import {
   takeEvery,
   fork,
   call,
   put,
   all,
   select,
   takeLatest,
} from "redux-saga/effects";
import _ from "lodash";

import ActionTypes from "action-types";
import * as actions from "actions/community";
import * as globalModalActions from "actions/globalModal";

import * as discoveryAPI from "apis/discovery";
import * as accountsAPI from "apis/account";
import * as communityAPI from "apis/community";

import { chartDataForPortfolio } from "selectors/community";
import { openNotificationWithIcon } from "layouts/utils";

import { GlobalUniverse } from "DataSet";

export function* getPortfolio(action) {
   const { payload } = action;
   try {
      const { portfolioName } = payload;
      if (portfolioName) {
         let portfolioData = {};
         yield put(actions.getYourPortfolioFetch());
         let [portfolioFunds] = yield all([
            call(accountsAPI.getPortfolioResultAPI, {
               file_name: portfolioName,
               market: "international",
            }),
         ]);
         if (portfolioFunds && portfolioFunds.length > 0) {
            portfolioFunds
               .filter(
                  k =>
                     k.source === "SHARINGALPHA" &&
                     k.portfolio_name === "Ranked portfolio"
               )
               .forEach(item => {
                  item.price = item.nav;
                  item.ticker = item.ticker_short || item.script || item.ticker;
               });
            // const isScale1 = portfolioFunds.reduce((acc, cur) => acc + cur.weight, 0)
            // Show Original Weight only until invalid tickers are cleaned by Data team
            // portfolioFunds.map(item=>{
            //   console.log("ITEMM",item.weight,item.original_weight)
            //   item.weight=Number(item.weight||0)
            //   return item;
            // })
            portfolioFunds = _.orderBy(portfolioFunds, ["weight"], ["desc"]);
            portfolioData = chartDataForPortfolio(portfolioFunds);
         }

         yield put(
            actions.getYourPortfolioSuccess({
               ...payload,
               ...portfolioData,
            })
         );
      } else {
         throw new Error("-- Missing payload data --");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.getYourPortfolioSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* getDiscoverFundSearch(action) {
   const { payload } = action;
   // const globalState = yield select(state => state.global);
   // const {
   //   selectedRegionData: { code: selectedRegionCode = 'USA' } = {}
   // } = globalState
   try {
      if (payload.query) {
         let requestPayload = {
            q: payload.query,
            universe: GlobalUniverse,
            market: "ALL",
         };
         const query = payload.query;
         yield put(actions.getFundDiscoverySearchFetch({ query }));
         let response = yield call(discoveryAPI.discoverSearch, requestPayload);
         yield put(
            actions.getFundDiscoverySearchSuccess({
               response,
               query,
            })
         );
      }
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.getFundDiscoverySearchSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

// export function* getTopRankMembers(action) {
//   const { payload } = action
//   try {
//     yield put(actions.getTopRankedMembersFetch(payload))
//     let [response] = yield all([
//       call(communityAPI.getTopRankedMembers, payload)
//     ])
//     yield put(
//       actions.getTopRankedMembersSuccess({
//         topRankedMembers: response.result
//       })
//     )
//   } catch (error) {
//     console.log(error)
//     const errorDetails = _.get(error, 'response.data', error.stack)
//     console.log(errorDetails)
//     yield put(
//       actions.getTopRankedMembersSuccess({
//         data: [],
//         pageCrash: errorDetails
//       })
//     )
//   }
// }

export function* getTopRankedMembers2(action) {
   const { payload } = action;
   const _topRankedMembersState = yield select(
      state => state.community.topRankedMembers
   );
   const topRankedMemberTypes = {
      fundSelection: "selection",
      assetAllocation: "allocation",
      commentary: "commentary",
   };
   const { expandKeys, ...tempPayload } = payload;
   try {
      yield put(actions.getTopRankedMembersFetch(payload));
      if (payload.type) {
         const response = yield call(communityAPI.getTopRankedMembers, {
            ...(payload.isRegion
               ? { regionId: tempPayload.regionId }
               : { countryId: tempPayload.countryId }),
            type: topRankedMemberTypes[payload.type],
         });
         response.selectedRegion = payload.isRegion
            ? payload.regionId
            : payload.countryId;
         yield put(
            actions.getTopRankedMembersSuccess({
               topRankedMembers: {
                  ..._topRankedMembersState,
                  [payload.type]: response,
               },
            })
         );
      } else {
         let [fundSelection, assetAllocation, commentary] = yield all(
            Object.values(topRankedMemberTypes).map(type =>
               call(communityAPI.getTopRankedMembers, {
                  ...tempPayload,
                  type,
               })
            )
         );
         yield put(
            actions.getTopRankedMembersSuccess({
               topRankedMembers: { fundSelection, assetAllocation, commentary },
            })
         );
      }
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.getTopRankedMembersSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* getUserRankingHistory(action) {
   const { payload } = action;
   // const userIdFromParam = yield select(state => state.profile.profile.user)
   try {
      let result = [],
         regionData = [
            {
               region_code: "----",
               name: "Global",
               selected: true,
            },
         ],
         year;
      if (payload) {
         const userIdFromParam = yield select(
            state => state.profile.profile.user
         );
         yield put(actions.getUserRankingHistoryFetch());
         let [response] = yield all([
            call(communityAPI.getUserRankingHistory, {
               ...payload,
               userId: userIdFromParam,
            }),
         ]);

         if (response) {
            if (
               typeof response.result !== "undefined" &&
               typeof response.result !== "string" &&
               Array.isArray(response.result) &&
               response.result.length > 0
            ) {
               result = response.result;
            }
            year = response.year;

            if (!_.isEmpty(response.region)) {
               regionData.push(response.region);
            }

            yield put(
               actions.getUserRankingHistorySuccess({
                  result,
                  regionData,
                  year,
               })
            );
         } else {
            throw new Error("-- API failed --");
         }
      }
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.getUserRankingHistorySuccess({
            result: [],
            regionData: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* getYourRankData(action) {
   const { payload } = action;
   try {
      yield put(actions.getYourRankDataFetch({ rankingDataLoaded: false }));
      let [response] = yield all([
         call(communityAPI.getYourRankingData, payload),
      ]);
      yield put(
         actions.getYourRankDataSuccess({
            ...response.result[0],
            rankingDataLoaded: true,
         })
      );
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.getYourRankDataSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* addNewFundRating(action) {
   const { payload, callback = () => {} } = action;
   try {
      if (payload) {
         console.log(payload);
         yield put(actions.addNewFundRatingFetch());
         const response = yield call(communityAPI.addNewFundRating, payload);
         if (response && response.result) {
            // response.result -> "Already Rated!"
            openNotificationWithIcon({
               duration: 5,
               type: "success",
               message: "Success",
               className: "api-response-notification-class",
               description: `${response.result}`,
            });
            yield put(
               actions.addNewFundRatingSuccess({
                  result: response.result,
                  success: true,
               })
            );
            callback();
            yield put(
               actions.getAssetSponsorOptRequest({
                  tickers: [
                     { ticker: payload.ticker, sponsor: payload.sponsor },
                  ],
                  source: "fundRating",
               })
            );
            yield put(actions.getFundDiscoverySearchReset());
         } else {
            throw new Error("-- API FAILED --");
         }
      } else {
         throw new Error("-- Missing Payload --");
      }
   } catch (error) {
      const errorDetails = _.get(error, "response.data", error.stack);
      yield put(
         actions.addNewFundRatingSuccess({
            result: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* getUserFundRating(action) {
   const { payload } = action;
   try {
      const userIdFromParam = yield select(state => state.profile.profile.user);
      yield put(actions.getUserFundRatingFetch());
      const response = yield call(communityAPI.getUserFundRating, {
         ...payload,
         userId: userIdFromParam,
      });
      yield put(actions.getUserFundRatingSuccess(response.result));
   } catch (error) {
      const errorDetails = _.get(error, "response.data", error.stack);
      yield put(
         actions.getUserFundRatingSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* updateUserFundRating(action) {
   const { payload } = action;
   try {
      const userIdFromParam = yield select(state => state.profile.profile.user);
      yield put(actions.updateUserFundRatingFetch());
      let response = yield call(communityAPI.updateUserFundRating, {
         ...payload,
         userId: userIdFromParam,
      });
      yield put(
         actions.updateUserFundRatingSuccess({ ...response, success: true })
      );

      yield put(actions.getFundDiscoverySearchReset());
      openNotificationWithIcon({
         duration: 5,
         type: "success",
         message: "Success",
         className: "api-response-notification-class",
         description: "Updated rating successfully.",
      });

      yield put(
         actions.getAssetSponsorOptRequest({
            tickers: [{ ticker: payload.ticker, sponsor: payload.sponsor }],
            source: "fundRating",
         })
      );
   } catch (error) {
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.updateUserFundRatingSuccess({
            error: errorDetails,
         })
      );
   }
}

export function* removeUserFundRating(action) {
   const { payload } = action;
   const userIdFromParam = yield select(state => state.profile.profile.user);
   try {
      yield put(actions.removeUserFundRatingFetch());
      let response = yield call(communityAPI.removeUserFundRating, {
         ...payload,
         userId: userIdFromParam,
      });
      yield put(
         actions.removeUserFundRatingSuccess({ ...response, success: true })
      );
      openNotificationWithIcon({
         duration: 5,
         type: "success",
         message: "Success",
         className: "api-response-notification-class",
         description: "Removed rating successfully.",
      });
   } catch (error) {
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.removeUserFundRatingSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* getUserRatings(action) {
   const { payload } = action;
   const userIdFromParam = yield select(state => state.profile.profile.user);
   try {
      if (payload) {
         let [response] = yield all([
            call(communityAPI.getUserRatings, {
               ...payload.userRatingsPayload,
               userId: userIdFromParam,
            }),
            // call(communityAPI.getUserRankingHistory, {
            //   ...payload.historyPayload,
            //   userId: userIdFromParam
            // })
         ]);
         if (response && typeof response.result !== "undefined") {
            yield put(
               actions.getUserRatingsSuccess({
                  ...response,
                  ...payload.userRatingsPayload,
               })
            );
         }

         // if (
         //   userRankingHistory &&
         //   typeof userRankingHistory.result !== 'undefined' &&
         //   userRankingHistory.result.length > 0
         // ) {
         //   rankingHistoryData = userRankingHistory.result
         // }
         // if (userRankingHistory && typeof userRankingHistory.region !== 'undefined' && userRankingHistory.region !== {}) {
         //   regionData = userRankingHistory.region
         // }
      }
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.getUserRatingsSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* getUserComments(action) {
   const { payload } = action;
   const userIdFromParam = yield select(state => state.profile.profile.user);
   try {
      yield put(actions.getUserCommentsFetch());
      const response = yield call(communityAPI.getUserCommentsApi, {
         ...payload,
         userId: userIdFromParam,
      });
      if (
         response &&
         response.result &&
         Array.isArray(response.result) &&
         response.result.length > 0
      ) {
         yield put(actions.getUserCommentsSuccess(response));
      } else {
         throw new Error("--- Empty Response ---");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.getUserCommentsSuccess({
            commentary: {},
            pageCrash: errorDetails,
         })
      );
   }
}

export function* getFundCommentsData(action) {
   const { payload } = action;
   try {
      if (payload) {
         yield put(actions.getFundCommentDataFetch(payload));
         let [response] = yield all([
            call(communityAPI.getFundCommentsData, payload),
         ]);
         yield put(actions.getFundCommentDataSuccess(response));
      }
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.getFundCommentDataSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* voteFundComment(action) {
   const { payload, errorCallback = () => {} } = action;
   try {
      if (payload) {
         yield put(actions.voteFundCommentFetch(payload));
         let [response] = yield all([
            call(communityAPI.voteFundCommentApi, payload),
         ]);
         if (response.result)
            yield put(actions.voteFundCommentSuccess(payload));
         else throw new Error("Error");
      }
   } catch (error) {
      console.log(error);
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(actions.voteFundCommentError(payload));
      errorCallback();
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Error",
         className: "api-response-notification-class",
         description: `Failed to Post a Vote. Please try again.`,
      });
   }
}

export function* updateCommunityPortfolio(action) {
   const { payload, callback = () => {} } = action;
   const { portfolio_name, portfolio_id } = payload;
   try {
      if (portfolio_id && portfolio_name) {
         yield put(actions.setUpdatedPortfolioDataSubmit());
         let response = yield call(
            communityAPI.updateCommunityPortfolio,
            payload
         );
         yield put(actions.setUpdatedPortfolioDataSuccess(response));
         callback();
         openNotificationWithIcon({
            duration: 5,
            type: "success",
            message: "Success",
            className: "api-response-notification-class",
            description: `Portfolio updated successfully`,
         });
         yield put(
            actions.getAssetSponsorOptRequest({
               tickers: payload.data.map(portfolio => ({
                  ticker: portfolio.ticker,
                  sponsor: portfolio.sponsor || "",
               })),
               source: "portfolio",
            })
         );
         yield put(
            actions.getYourPortfolioRequest({
               portfolioId: portfolio_id,
               portfolioName: portfolio_name,
            })
         );
      }
   } catch (error) {
      console.log(error);
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Error",
         className: "api-response-notification-class",
         description: `Portfolio update failed`,
      });
      const errorDetails = _.get(error, "response.data", error.stack);
      console.log(errorDetails);
      yield put(
         actions.setUpdatedPortfolioDataSuccess({
            data: [],
            pageCrash: errorDetails,
         })
      );
   }
}

export function* getUserSearchFundRating(action) {
   const { payload, callback } = action;
   try {
      if (payload) {
         yield put(actions.getUserSearchFundRatingFetch(payload));
         const response = yield call(
            communityAPI.getUserSearchFundsRating,
            payload
         );
         if (response.result) {
            yield put(actions.getUserSearchFundRatingSuccess(response.result));
            if (callback && typeof callback === "function") {
               callback(
                  response.result,
                  payload.assetOverviewId,
                  payload.ticker
               );
            }
         } else {
            yield put(actions.getUserSearchFundRatingSuccess([]));
         }
      }
   } catch (error) {
      const errorDetails = _.get(error, "response.data", error.stack);
      if (errorDetails && errorDetails.result) {
         yield put(actions.getUserSearchFundRatingSuccess(errorDetails.result));
      } else {
         yield put(
            actions.getUserFundRatingSuccess({
               data: [],
               pageCrash: errorDetails,
            })
         );
      }
   }
}

export function* getAssetSponsorOpt(action) {
   const { payload: { tickers = [], source } = {}, callback = () => {} } =
      action;
   const tickersPayload = tickers.map(({ ticker }) => ticker);

   try {
      if (tickersPayload.length > 0) {
         yield put(actions.getAssetSponsorOptFetch());
         const response = yield call(communityAPI.getAssetSponsorOptApi, {
            tickers: tickersPayload,
         });

         if (response.is_sponsor && response.result) {
            const filteredSponsorOptTickers = response.result.filter(
               ({ is_consent }) =>
                  is_consent === null ||
                  is_consent === false ||
                  source === "discoverySearch"
            );
            console.log(
               "TICKERSSS filteredSponsorOptTickers",
               filteredSponsorOptTickers
            );
            yield put(actions.getAssetSponsorOptSuccess());
            callback(filteredSponsorOptTickers);
            if (filteredSponsorOptTickers.length > 0) {
               yield put(
                  globalModalActions.setGlobalModalState({
                     show: true,
                     modalType: "AssetSubscriptionModal",
                     modalProps: {
                        source,
                        tickers: filteredSponsorOptTickers,
                     },
                  })
               );
            }
         }
      }
   } catch (error) {
      const errorDetails = _.get(error, "response.data", error.stack);
      callback();
      if (errorDetails && errorDetails.result) {
         yield put(
            actions.getAssetSponsorOptRequest({ error: errorDetails.result })
         );
      } else {
         yield put(
            actions.getAssetSponsorOptSuccess({ error: errorDetails.result })
         );
      }
   }
}
export function* updateAssetSponsorOpt(action) {
   const { payload } = action;
   const postSponsorOptUpdateUrl = yield select(
      state => state.community.sponsorOptData.postSponsorOptUpdateUrl
   );
   try {
      yield put(actions.updateAssetSponsorOptFetch());
      const response = yield call(communityAPI.postAssetSponsorOpt, payload);
      if (response.result) {
         openNotificationWithIcon({
            duration: 5,
            type: "success",
            message: "Success",
            className: "api-response-notification-class",
            description: `${response.result}`,
         });
         if (postSponsorOptUpdateUrl) {
            window.open(postSponsorOptUpdateUrl, "_blank");
         }
      }
   } catch (error) {
      const errorDetails = _.get(error, "response.data", error.stack);
      yield put(
         actions.updateAssetSponsorOptSuccess({ error: errorDetails.result })
      );
      openNotificationWithIcon({
         duration: 5,
         type: "error",
         message: "Error",
         className: "api-response-notification-class",
         description: `Failed. Please try again`,
      });
   }
}

function* communitySaga() {
   yield all([
      fork(
         takeEvery,
         ActionTypes.GET_FUND_DISCOVERY_SEARCH_REQUEST,
         getDiscoverFundSearch
      ),
      fork(takeEvery, ActionTypes.GET_YOUR_PORFOLIO_REQUEST, getPortfolio),
      fork(
         takeEvery,
         ActionTypes.GET_TOP_RANKED_MEMBERS_REQUEST,
         getTopRankedMembers2
      ),
      fork(
         takeEvery,
         ActionTypes.GET_USER_RANKING_HISTORY_REQUEST,
         getUserRankingHistory
      ),
      fork(takeEvery, ActionTypes.GET_USER_RATINGS_REQUEST, getUserRatings),
      fork(
         takeEvery,
         ActionTypes.GET_USER_RANKING_DATA_REQUEST,
         getYourRankData
      ),
      fork(
         takeEvery,
         ActionTypes.ADD_NEW_FUND_RATING_REQUEST,
         addNewFundRating
      ),
      fork(
         takeEvery,
         ActionTypes.GET_USER_FUND_RATING_REQUEST,
         getUserFundRating
      ),
      fork(
         takeEvery,
         ActionTypes.UPDATE_USER_FUND_RATING_REQUEST,
         updateUserFundRating
      ),
      fork(
         takeEvery,
         ActionTypes.REMOVE_USER_FUND_RATING_REQUEST,
         removeUserFundRating
      ),
      fork(
         takeEvery,
         ActionTypes.GET_USER_SEARCH_FUND_RATING_REQUEST,
         getUserSearchFundRating
      ),
      fork(
         takeLatest,
         ActionTypes.SET_UPDATED_PORTFOLIO_DATA_REQUEST,
         updateCommunityPortfolio
      ),
      fork(takeEvery, ActionTypes.GET_USER_COMMENTS_REQUEST, getUserComments),
      fork(
         takeLatest,
         ActionTypes.GET_FUND_COMMENTS_DATA_REQUEST,
         getFundCommentsData
      ),
      fork(
         takeLatest,
         ActionTypes.ADD_FUND_COMMENT_VOTE_REQUEST,
         voteFundComment
      ),
      fork(takeLatest, ActionTypes.GET_SPONSOR_OPT_REQUEST, getAssetSponsorOpt),
      fork(
         takeLatest,
         ActionTypes.POST_SPONSOR_OPT_REQUEST,
         updateAssetSponsorOpt
      ),
   ]);
}

export default communitySaga;
